// import { Button, Toggle, IconButton, Input, Dropdown, Prompt } from 'Components'
import React, { useState, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
    AppMode,
    CustomEventType,
    EmailType,
    EmailOptions,
    FloorplanData,
    MenuData,
    MenuOption,
    ModelhomeData,
    PageData,
    PageType,
    PromptOptions,
    RootState,
    Theme,
} from 'app/types'
import {
    navigateAsync,
    showPrompt,
    sendEmail,
} from 'actions/appActions'
import {
    logoutUser,
} from 'actions/userActions'
import {
    IconButton,
    DropdownMenu,
    Button,
    FavouriteNavButton,
} from 'components'
import { getAppMode } from 'helpers/config'
import { logger } from 'helpers/logger'
import { useSelector } from 'react-redux'
import * as fnc from 'helpers/fnc'
import { icons } from 'app/constants'

interface NavBarProps {
    menu: MenuData,
    selected?: string,
    className?: string,
    clickToExpand?: boolean,
    clickToExpandChevron?: boolean,

}

interface OptionProps {
    item: MenuOption,
    navigate: () => void,
    selected?: string
    className?: string,
    clickToExpand?: boolean,
    clickToExpandChevron?: boolean,
}

function sortOptions(a: { order: string}, b: { order: string }) {
    const orderA = (a && a.order) ? parseInt(a.order, 10) : 0
    const orderB = (b && b.order) ? parseInt(b.order, 10) : 0
    return orderA - orderB
}
const isTouch = fnc.isTouchDevice()

export function NavBarOption(props:OptionProps) {
    const pages = useAppSelector((state:RootState) => state.app.current.pages)
    const floorplans = useAppSelector((state:RootState) => state.app.current.floorplans)
    const modelhomes = useAppSelector((state:RootState) => state.app.current.modelhomes)
    const theme = useAppSelector((state:RootState) => state.app.config.theme)
    const {
        item,
        navigate,
        selected,
        className,
        clickToExpand,
        clickToExpandChevron,
    } = props
    const {
        id,
        name,
        pageId,
        floorplanId,
        modelhomeId,
        subOptions,
    } = item
    const page = pages.find((x) => x.id === pageId)
    if (!page && pageId != null) {
        logger.error(`Page ${pageId} not found`)
        return <div className="navbar-item"><span>Not found</span></div>
    }
    const pageName = page ? page.link : name

    const [hover, setHover] = useState(false)

    const [floorplanLookup, setFloorplanLookup] = useState<{[key:string]: FloorplanData}>({})
    const [modelhomeLookup, setModelhomeLookup] = useState<{[key:string]: ModelhomeData}>({})
    const [hoverTime, setHoverTime] = useState<Date>(null)

    useEffect(() => {
        const newFloorplanLookup = {}
        if (Object.keys(floorplanLookup).length === 0) {
            floorplans.forEach((x) => { newFloorplanLookup[x.id] = x })
        }
        setFloorplanLookup(newFloorplanLookup)
        const newModelHomeLookup = {}
        if (Object.keys(modelhomeLookup).length === 0) {
            modelhomes.forEach((x) => { newModelHomeLookup[x.id] = x })
        }
        setModelhomeLookup(newModelHomeLookup)
    }, [floorplans, modelhomes])

    useEffect(() => {
        if (subOptions.length > 0) {
            window.addEventListener(CustomEventType.CloseAllMenus, closeMenu)
        }
        return () => {
            if (subOptions.length > 0) {
                window.removeEventListener(CustomEventType.CloseAllMenus, closeMenu)
            }
        }
    }, [hover])

    function closeMenu() {
        if (hover) {
            setHover(false)
        }
    }

    function handleMouseEnter() {
        if (clickToExpand) {
            return
        }
        setHoverTime(new Date())
        setHover(true)
    }
    function handleMouseLeave() {
        if (clickToExpand) {
            return
        }
        setHover(false)
    }

    function handleClick(e: Event,
        newPageId: string,
        subLink:string,
        newFloorplanId: string,
        newModelhomeId: string,
        sub = false) {
        e.stopPropagation()
        if (subOptions.length > 0 && !sub) {
            let dif = 0
            if (hoverTime != null) {
                dif = (new Date()).getTime() - hoverTime.getTime()
            }
            if (!hoverTime || dif > 500) {
                setHover(!hover)
            }
        } else {
            const optionPage = pages.find((x) => x.id === newPageId)
            if (optionPage && optionPage.link != null) {
                navigate(optionPage, {
                    floorplanId: newFloorplanId,
                    modelhomeId: newModelhomeId,
                }, !sub)
            }
            window.dispatchEvent(new Event(CustomEventType.CloseAllMenus))
        }
    }

    // Get the menu item div
    function getOptionDiv() {
        switch (theme) {
        default:
            return <span className={`noselect color-${pageName}`}>
                {name}
                {clickToExpand && clickToExpandChevron && subOptions.length > 0 && <i className={hover ? icons.chevronDown : icons.chevronUp}/>}
            </span>
        case Theme.Theme3:
            return <span className={`noselect color-${pageName}`}>
                {name}
                <div className="underline"/>
                {clickToExpand && clickToExpandChevron && subOptions.length > 0 && <i className={hover ? icons.chevronDown : icons.chevronUp}/>}
            </span>
        }
    }

    let selectedOption = null
    let selectedSubOption = null
    if (selected) {
        const selectedSplit = selected.split('/')
        if (selectedSplit.length > 1) {
            [selectedOption, selectedSubOption] = selectedSplit
        } else {
            selectedOption = selected
        }
    }
    const subSelected = subOptions.find((x) => {
        const subPage:PageData = pages.find((y) => y.id === x.pageId)
        return subPage && subPage.link === selectedOption
    })

    const attrs = `${className != null ? ` ${className}` : ''}`
    if (subOptions !== null && subOptions !== undefined && subOptions.length > 0) {
        return <div className={`navbar-item${attrs} ${(selectedOption === pageName || subSelected) ? 'selected' : ''}`}
            key={id}
            onClick={(e) => handleClick(e, pageId, null, floorplanId, modelhomeId)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            {getOptionDiv()}
            { (hover)
                && <div className="navbar-item-dropdown fadeIn">
                    {[...subOptions].sort(sortOptions).map((x) => {
                        let link:string = null
                        if (x.floorplanId != null && x.floorPlanId in floorplanLookup) {
                            link = floorplanLookup[x.floorplanId].link
                        } else if (x.modelhomeId != null && x.modelhomeId in modelhomeLookup) {
                            link = modelhomeLookup[x.modelhomeId].link
                        } else if (x.modelhomeId == null && x.floorplanId == null) {
                            const subPage:PageData = pages.find((y) => y.id === x.pageId)
                            if (subPage) {
                                link = subPage.link
                            }
                        } else {
                            logger.error('Failed to find link for suboption ', x, modelhomeLookup)
                        }

                        return <div
                            className={`navbar-item ${((selectedOption === pageName && selectedSubOption === link) || selectedOption === link) ? 'selected' : ''}`}
                            key={x.id}
                            onClick={(e) => handleClick(
                                e,
                                x.pageId,
                                link,
                                x.floorplanId,
                                x.modelhomeId,
                                true,
                            )}>
                            <span className={`noselect color-${pageName}`}>
                                {x.name}
                            </span>
                        </div>
                    })}
                </div>
            }
        </div>
    }

    return <div className={`navbar-item${selectedOption === pageName ? ' selected' : ''}${attrs}`} key={id} 
        onClick={(e) => handleClick(e, pageId, floorplanId, modelhomeId)}>
        {getOptionDiv()}
    </div>
}

export function NavBar(props:NavBarProps) {
    const {
        menu,
        selected,
    } = props

    const dispatch = useAppDispatch()
    const loggedIn = useAppSelector((state:RootState) => state.user.loggedIn)
    const navigation = useAppSelector((state:RootState) => state.app.navigation)
    const current = useAppSelector((state:RootState) => state.app.current)
    const currentPage = useAppSelector((state:RootState) => {
        if (state.app.navigation[0] != null) {
            const nav: string = state.app.navigation[0].split('/')[0]
            const page = state.app.current.pages.find((x) => x.link === nav)
            if (page) {
                return page
            }
        }
        return null
    })
    const salescenter = useAppSelector((state:RootState) => state.app.salescenter)
    const registerLink = useAppSelector((state: RootState) => state.app.current.meta.registerLink)

    function logout() {
        window.dispatchEvent(new CustomEvent(CustomEventType.LogOut))
    }

    function goToAdmin() {
        dispatch(navigateAsync({ app: current, pageType: PageType.Admin }))
            .catch((e:Error) => logger.error(e))
    }

    function handleNavigate(page, options, closeAll = true) {
        if (closeAll) {
            window.dispatchEvent(new CustomEvent(CustomEventType.CloseAllMenus))
        }
        dispatch(navigateAsync({ app:current, pageType: page.link, options }))
            .catch((e:Error) => logger.error(e))
    }

    function handleEmail() {
        const onFavourites = navigation[0].includes('favourites')
        dispatch(showPrompt(
            onFavourites ? PromptOptions.EmailFavourites : PromptOptions.EmailGeneral,
        )).then((x: { payload: EmailOptions }) => {
            if (x.payload && x.payload.value) {
                return Promise.all([
                    dispatch(showPrompt(PromptOptions.EmailSending)),
                    dispatch(sendEmail({
                        emailType: onFavourites ? EmailType.Favourites : EmailType.General,
                        email: x.payload.value,
                        page: onFavourites ? null : PageType.Floorplan,
                        options: { optIn: x.payload.optIn },
                    })),
                ])
            }
            throw x
        })
            .catch(logger.error)
    }

    function handleRegister() {
        window.open(registerLink, '_blank').focus()
    }


    function getAdminButtons() {
        if (getAppMode() === AppMode.Local) {
            return null
        }
        return <div className="navbar-button">
            { loggedIn && currentPage && currentPage.link !== 'admin' && <IconButton noBg alt icon={icons.userCircle} onClick={goToAdmin}/> }
            { loggedIn && currentPage && currentPage.link === 'admin' && <Button noBg alt onClick={logout}>Logout</Button> }
            {/* { !loggedIn && currentPage 
                && currentPage.link !== 'admin' 
                && <Button onClick={goToAdmin}>Login</Button> } */}
        </div>
    }


    return <div id="navbar" onClick={(e) => e.stopPropagation()}>
        {menu.options.map((item) => <NavBarOption key={item.id} className="only-big" item={item} navigate={handleNavigate} selected={selected} clickToExpand={isTouch}/>)}
        <div className="navbar-options">
            <FavouriteNavButton/>
            <div className="navbar-button">
                {salescenter
                    && <IconButton alt noBg icon={icons.envelope} onClick={handleEmail}/>}
                {!salescenter && registerLink
                    && <Button alt noBg onClick={handleRegister}>Register</Button>}
            </div>
            { getAdminButtons() }
            <DropdownMenu icon={icons.hamburger} openIcon={icons.times} className="only-small navbar-button" right contentAnimation="fadeIn" items={{
                element: <div className="mobile-nav">
                    {[...menu.options].sort(sortOptions).map((x) => <NavBarOption className="animate__animated animate__faster animate__fadeInDown" key={x.id} item={x} selected={selected} navigate={handleNavigate} clickToExpand clickToExpandChevron/>)}
                </div>,
            }}/>
        </div>
    </div>
}

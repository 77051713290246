import {
    createSlice,
    // PayloadAction,
} from '@reduxjs/toolkit'
import { saveState, loadState } from 'app/state'
import { SpinQuality, SpinData, ViewData, SpinState } from 'app/types'
import history from 'helpers/routerHistory'
import { logger } from 'helpers/logger'
import * as fnc from 'helpers/fnc'
import { getAppPath } from 'helpers/config'

const isMobile = fnc.isMobileDevice()

function calculateQuality() {
    let quality = SpinQuality.Low
    if (isMobile) {
        if (navigator.deviceMemory && navigator.deviceMemory >= 4) {
            quality = SpinQuality.Med
        } else {
            quality = SpinQuality.Low
        }
    } else if (navigator.deviceMemory) {
        if (navigator.deviceMemory < 8) {
            switch (navigator.deviceMemory) {
                case 0.5:
                case 1:
                default:
                    quality = SpinQuality.Low
                    break
                case 2:
                case 4:
                    quality = SpinQuality.Med
                    break
            }
        } else {
            quality = SpinQuality.High
        }
    }
    return quality
}
const defaultSpin: SpinData = {
    views: {},
    currentView: null,
    initialized: 0,
    error: null,
}

const defaultView: SpinViewData = {
    initialized: 0,
    rotation: 0,
    quality: null,

    maxPower: 0,
    minPower: 0,
    targetPower: 0,
    currentPower: 0,

    // totalImages: 256,
    defaultIndex: 0,

    focusUnit: null,
    splitView: false,

    rotation: 0,
    totalImages: 256,
    rotationOffset: 0,
    scale: 1,
    type: 'spin',
    mesh: null,

    fov: 90,
    cameraPitch: 30,
    cameraDistance: 10,
    position: [0, 0, 0],
    icon: null,
    north: 0,
}

/*const inchesToMM = 2.54
const sheppardScale = 0.01
const testData = {
    '105sheppard': {
        'site': {
            rotation: 0,
            mesh: 'site.obj',
            type: 'spin',
            scale: 10,//1000*sheppardScale,,
            icons: {
                lobby: {
                    opacity: 'lobby.png',
                    diffuse: 'lobby.png',
                    size: 30,
                    offset: [0, 20, -100],
                    // position: [-300, 0, -350],
                    placement: 'center',
                },
                retail: {
                    opacity: 'cafe.png',
                    diffuse: 'cafe.png',
                    size: 30,
                    offset: [-50, 20, -100],
                    // position: [-300, 0, -350],
                    placement: 'center',
                },
                terrace: {
                    opacity: 'terrace.png',
                    diffuse: 'terrace.png',
                    size: 30,
                    offset: [0, 10, 50],
                    // position: [-300, 0, -350],
                    placement: 'center',
                },
                party_room: {
                    opacity: 'party_room.png',
                    diffuse: 'party_room.png',
                    size: 30,
                    offset: [0, 10, 100],
                    // position: [-300, 0, -350],
                    placement: 'center',
                },
                exercise_room: {
                    opacity: 'exercise_room.png',
                    diffuse: 'exercise_room.png',
                    size: 30,
                    offset: [0, 10, 100],
                    // position: [-300, 0, -350],
                    placement: 'center',
                }
            },
            flip: true,
            defs: {
                exercise_room: {
                    mediaId: ['105shepmedia1'],
                },
                party_room: {
                    mediaId: ['105shepmedia6', '105shepmedia7'],
                },
                lobby: {
                    mediaId: '105shepmedia2',
                },
                retail: {
                    mediaId: '105shepmedia3',
                },
                terrace: {
                    mediaId: '105shepmedia13',
                },
                exterior: {
                    position: [-400, 130, -350],
                    icon: {
                        opacity: 'exterior.png',
                        diffuse: 'exterior.png',
                        size: 30,
                        // position: [-300, 0, -350],
                        placement: 'center',
                    },
                    mediaId: '105shepmedia4',
                }
            },

            // Camera a
            // fov: 65,
            // cameraPitch: 8,
            // cameraDistance: 72671 * sheppardScale,
            // position: [-19613.75 * sheppardScale, 15973.211 * sheppardScale, -10968.21 * sheppardScale],

            // Camera b
            fov: 75,
            cameraPitch: 7,
            cameraDistance: 62511 * sheppardScale,
            position: [-19613.75 * sheppardScale, 15973.211 * sheppardScale, -10968.21 * sheppardScale],
            position: [-199.33750000000003, 106.13211000000044, -153.6821],
            rotationOffset: -3.14 / 2.0,
            north: 3.14
        },
    },
    'americanvillage': {
        /*'site': {
            rotation: 0,
            mesh: 'site.obj',
            type: 'spin',
            cameraPitch: 36,
            // cameraDistance:9379.018/10,
            // cameraDistance: 877.9018,
            cameraDistance: 10,
            // position: [-983.781 / 10, -158.713 / 10, 773.009 / 10],
            position: [0, 0, 0],
        },*

        site: {
            cameraPitch: 36.926,
            cameraDistance: 17340 * inchesToMM / 1000,
            position: [358.948 * inchesToMM / 1000, -36.926 * inchesToMM / 1000, 193.189 * inchesToMM / 1000],
            defs: {
                tour: {
                    icon: { opacity: 'icon_amenity.png', size: 2 },
                    position: [0, 0, 0],
                    size: [2, 1, 2],
                }
            },
            // meshMap: siteMesh,
            highlightAlpha: 0.5,
            rotationOffset: 3.14 / 2.0,
            scale: 0.128,
            mesh: 'site.obj',
            // cameraDistance: 10,
            // position: [1, 5, 5],
        },


        condo_1: {
            // type: 'spin',
            cameraPitch: 10,
            fov: 0.8888,
            position: [3609.55 * inchesToMM / 1000, 2500 * inchesToMM / 1000, -10188.95 * inchesToMM / 1000],
            position: [22.168256999999997, 16.35, -60.879932999999994],
            // cameraDistance:37340*inchesToMM,
            cameraDistance: 51644 * inchesToMM / 1000,
            rotationOffset: 3.14 / 2.0,
            defaultRotation: 0,
            // scale: 0.444*0.001,
            scale: 0.003,
            scale: 0.0024,
            // suites: condoSuites,
            // floors: condoFloors,
            icon: { opacity: 'icon_1.png' },
            mesh: 'condo_1.obj',
            flip: true,
        },

        condo_2: {
            type: 'spin',
            cameraPitch: 10,
            fov: 0.8888,
            position: [29148.48 * inchesToMM / 1000, 2500 * inchesToMM / 1000, 7518.71 * inchesToMM / 1000],
            position: [179.0371392, 15.35, 45.0975234],
            // position:[74635,7170,19884],
            // cameraDistance:18600*inchesToMM,
            cameraDistance: 51644 * inchesToMM / 1000,
            // cameraDistance:37340*inchesToMM,
            rotationOffset: 3.14 / 2.0,
            // scale:0.268 ,
            // scale:0.229,
            scale: 0.444,
            scale: 0.0024,
            // suites: condoSuites,
            // floors: condoFloors,
            icon: { opacity: 'icon_2.png' },
            mesh: 'condo_2.obj',
            flip: true,
        },

        condo_3: {
            type: 'spin',
            cameraPitch: 10,
            fov: 0.8888,
            // position:[9154,7170,25940],
            // position: [-24601 * inchesToMM / 1000, 2500 * inchesToMM / 1000, 617.4 * inchesToMM / 1000],
            position: [-149.48654, 16.35, 3.5681960000000004],
            cameraDistance: 51644 * inchesToMM / 1000,
            // cameraDistance:37340*inchesToMM,
            rotationOffset: 3.14 / 2.0,
            defaultRotation: 0,
            scale: 0.444,
            scale: 0.0024,
            // suites: condoSuites,
            // floors: condoFloors,
            icon: { opacity: 'icon_3.png' },
            mesh: 'condo_3.obj',
            flip: true,
        },


        singlefamilyhome_1: {
            type: 'static',
            image: 'assets/singlefamilyhome_frontofhouse.jpg',
        },
        singlefamilyhome_2: {
            type: 'static',
            image: 'assets/singlefamilyhome_frontofhouse.jpg',
        },
        singlefamilyhome_3: {
            type: 'static',
            image: 'assets/singlefamilyhome_frontofhouse.jpg',
        },
        singlefamilyhome_4: {
            type: 'static',
            image: 'assets/singlefamilyhome_frontofhouse.jpg',
        },
        singlefamilyhome_5: {
            type: 'static',
            image: 'assets/singlefamilyhome_frontofhouse.jpg',
        }
    }
}*/

/**
 * Initial state
 */
const initialState: SpinState = {
    spinState: [{}, {}],
    viewState: [{}, {}],
    ...loadState('spin', true, true),
}

export function setHash(hashCode) {
    const currentState = { ...history.location }
    currentState.hash = hashCode
    history.replace(currentState)
}

/**
 * Helpers
 */
function setupView(view: ViewData, quality = null) {
    if (quality != null) {
        view.quality = quality//+ (view.splitView ? 0 : -1)
    } else if (view.quality == null) {
        view.quality = calculateQuality()
        if (view.totalImages == 256 && view.splitView && view.quality > SpinQuality.Low) {
            view.quality = view.quality - 1
        }
    } else if (view.totalImages == 256 && view.splitView && view.quality > SpinQuality.Low) {
        view.quality = view.quality - 1
    }

    view.maxPower = view.targetPower = 5
    // view.minPower = (isMobile || splitIndex != null) ? 4 : 1//view.currentPower = settings[1]
    switch (view.quality) {
        case SpinQuality.Low:
            view.minPower = 4
            break
        case SpinQuality.Med:
            view.minPower = isMobile ? 4 : 2
            break
        case SpinQuality.High:
            view.minPower = isMobile ? 2 : 1
            break
    }
    view.currentPower = null
}

/**
 * Async actions
 */

/**
 * Slice +  sync actions/reducers
 */
const qualityKey = `spin-quality-${getAppPath()}`
const spinSlice = createSlice({
    name: 'spin',
    initialState,
    reducers: {
        setupSpin(state: SpinState, action) {
            const { app, media, spinLink, viewLink, splitIndex, splitView, fromView, source } = action.payload
            const sIdx = splitIndex != null ? splitIndex : 0
            const spin = app.spins.find((x) => x.link == spinLink)
            if (!spin) {
                logger.error('Missing spin', spinLink, app, spin)
                return
            }

            if (!(spinLink in state.spinState[sIdx])) {
                state.spinState[sIdx][spinLink] = { initialized: 1, currentView: viewLink }
            } else {
                state.spinState[sIdx][spinLink].currentView = viewLink
            }

            const cachedQuality = localStorage.getItem(qualityKey)
            const viewMap = spin.views.reduce((acc, x) => ({ ...acc, [x.id]: x }), {})
            spin.views.forEach((x) => {
                const spinMedia = media[x.mediaId]
                if (!spinMedia) {
                    return
                }
                const totalImages = spinMedia.fileCount
                let view = null
                if (!(x.link in state.viewState[sIdx])) {
                    state.viewState[sIdx][x.link] = {
                        initialized: x.link == viewLink,
                        splitView,
                        focusUnit: null,
                        rotation: 0,
                        quality: cachedQuality ? parseInt(cachedQuality) : null
                    }
                    view = state.viewState[sIdx][x.link]

                    // If initializing with source
                    if (x.link == viewLink) {
                        view.source = source
                        view.prevView = fromView
                    }

                    setupView(view)

                    // Initial processing
                    // Determine first image based on default rotation
                    const rot = x.defaultRotation * 100 / 360
                    view.rotation = rot
                    // const span = parseInt(Math.pow(2, view.maxPower))
                    let seek = 1 - rot / 100.0
                    if (x.reverse) {
                        seek = 1 - seek
                    }
                    // let defaultIndex = Math.round(Math.round(seek * totalImages) / span) * span
                    let defaultIndex = Math.round(seek * totalImages)
                    // Clamp
                    while (defaultIndex >= totalImages) {
                        defaultIndex -= totalImages
                    }
                    while (defaultIndex < 0) {
                        defaultIndex += totalImages
                    }
                    view.defaultIndex = defaultIndex
                    view.width = spinMedia.width
                    view.height = spinMedia.height
                    view.aspect = spinMedia.width / spinMedia.height
                    view.elementMap = x.elements.reduce((acc, x) => ({ ...acc, [x.link]: x }), {})
                    view.totalImages = totalImages
                } else {
                    // Update view and quality in case its changed
                    const view = state.viewState[sIdx][x.link]
                    view.splitView = splitView
                    view.quality = cachedQuality ? parseInt(cachedQuality) : null
                    setupView(view)
                }
            })
        },
        setView(state: SpinState, action) {
            const { spinLink, viewLink, splitIndex, isBack, source, fromView, focusUnit } = action.payload
            const sIdx = splitIndex != null ? splitIndex : 0
            if (viewLink in state.viewState[sIdx]) {

                // Only reset view if different that current
                const current = state.spinState[sIdx][spinLink].currentView
                if (current != viewLink) {
                    setupView(state.viewState[sIdx][viewLink])
                }
                state.viewState[sIdx][viewLink].initialized = 1
                state.viewState[sIdx][viewLink].focusUnit = null

                // Setup back 
                const prevView = fromView ? fromView : state.spinState[sIdx][spinLink].currentView
                if (prevView != viewLink && !isBack) {
                    state.viewState[sIdx][viewLink].prevView = prevView
                }
                if (source != null) {
                    state.viewState[sIdx][viewLink].source = source
                }
                if (focusUnit != null) {
                    state.viewState[sIdx][viewLink].focusUnit = focusUnit
                }

                state.spinState[sIdx][spinLink].currentView = viewLink
            }
        },
        setQuality(state: SpinState, action) {
            const { spinLink, viewLink, splitIndex, quality } = action.payload
            const sIdx = splitIndex != null ? splitIndex : 0
            const view = state.viewState[sIdx][viewLink]
            setupView(view, quality)
            view.rotation = view.defaultRotation * 100 / 360

            localStorage.setItem(qualityKey, quality)
        },
        setFocusUnit(state: SpinState, action) {
            const { spinLink, viewLink, splitIndex, focusUnit } = action.payload
            const sIdx = splitIndex != null ? splitIndex : 0
            const view = state.viewState[sIdx][viewLink]
            view.focusUnit = focusUnit
        },
        setRotation(state: SpinState, action) {
            const { spinLink, viewLink, rotation, splitIndex } = action.payload
            const sIdx = splitIndex != null ? splitIndex : 0
            const view = state.viewState[sIdx][viewLink]
            view.rotation = rotation
        },
        setTargetQuality(state: SpinState, action) {
            const { spinLink, viewLink, quality, splitIndex } = action.payload
            const sIdx = splitIndex != null ? splitIndex : 0
            const view = state.viewState[sIdx][viewLink]
            view.targetQuality = quality
        },
        setPower(state: SpinState, action) {
            const { spinLink, viewLink, power, splitIndex } = action.payload
            const sIdx = splitIndex != null ? splitIndex : 0
            const view = state.viewState[sIdx][viewLink]
            if (view) {
                view.currentPower = power
            }
        },
        setSpinError(state: SpinState, action) {
            const { id, error, splitIndex } = action.payload
            const sIdx = splitIndex != null ? splitIndex : 0
            state.spinState[sIdx][id] = { ...state.spinState[sIdx][id], error }
        },
    },
    extraReducers: (builder) => {
        /*builder.addCase(retrieveConfig.pending, (state: AppState) => {
            state.initialized = 0
            state.error = null
        })*/
    },
})

export const {
    setupSpin,
    setView,
    setRotation,
    setQuality,
    setTargetQuality,
    setSpinError,
    setPower,
    // setConfig,
    setFocusUnit,
} = spinSlice.actions

export default spinSlice.reducer

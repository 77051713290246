/* eslint-disable @typescript-eslint/no-explicit-any */
// import winston from "winston"
// import BrowserConsole from 'winston-transport-browserconsole'
import { postError } from 'services/appServices'
import * as Sentry from "@sentry/browser";

/*const level = "debug";
winston.configure({
    transports: [
        new BrowserConsole(
            {
                format: winston.format.simple(),
                level,
            },
        ),
        // Uncomment to compare with default Console transport
        // new winston.transports.Console({
        //     format: winston.format.simple(),
        //     level,
        // }),
    ],
})
*/

enum LogLevel {
    Info = 0,
    Debug = 1,
    Warn = 2,
    Error = 3
}

const currentLevel = LogLevel.Error

function debug(...x: any[]): void {
    if (currentLevel < LogLevel.Debug) {
        return
    }
    // winston.debug("DEBUG", x)
    if (x.length > 1) {
        console.debug('DEBUG', x) // eslint-disable-line no-console
    } else if (x.length === 1) {
        console.debug('DEBUG', x[0]) // eslint-disable-line no-console
    }
}

function error(...x: any[]): void {
    if (currentLevel < LogLevel.Error) {
        return
    }
    // winston.error("ERROR", x)
    let err = null
    if (x.length > 1) {
        console.error('ERROR', x) // eslint-disable-line no-console
        err = x.map((x) => {
            if (x instanceof Error) {
                const errorInfo = {
                    name: x.name,
                    message: x.message,
                    stack: x.stack,
                    timestamp: new Date().toISOString()
                }
                return JSON.stringify(errorInfo, null, 2)
            } else if (typeof x == 'string') {
                return x
            } else if (typeof x == 'object') {
                return JSON.stringify(x)
            }
        }).join(', ')
    } else if (x.length === 1) {
        console.error('ERROR', x[0]) // eslint-disable-line no-console
        err = JSON.stringify(x[0])
    }
}

function errorWithReport(...x: any[]): void {
    if (currentLevel < LogLevel.Error) {
        return
    }
    // winston.error("ERROR", x)
    let err = null
    if (x.length > 1) {
        console.error('ERROR', x) // eslint-disable-line no-console
        err = x.map((x) => {
            if (x instanceof Error) {
                const errorInfo = {
                    name: x.name,
                    message: x.message,
                    stack: x.stack,
                    timestamp: new Date().toISOString()
                }
                return JSON.stringify(errorInfo, null, 2)
            } else if (typeof x == 'string') {
                return x
            } else if (typeof x == 'object') {
                return JSON.stringify(x)
            }
        }).join(', ')
    } else if (x.length === 1) {
        console.error('ERROR', x[0]) // eslint-disable-line no-console
        err = JSON.stringify(x[0])
    }

    captureException(x)

    return postError(err)
        .then((x) => {
            if (x && x.id) {
                return x.id
            }
        })
}

function warn(...x: any[]): void {
    if (currentLevel < LogLevel.Warn) {
        return
    }
    // winston.WARN("WARN", x)
    if (x.length > 1) {
        console.warn('WARN', x) // eslint-disable-line no-console
    } else if (x.length === 1) {
        console.warn('WARN', x[0]) // eslint-disable-line no-console
    }
}

function info(...x: any[]): void {
    if (currentLevel < LogLevel.Info) {
        return
    }
    // winston.info("INFO", x)
    if (x.length > 1) {
        console.info('INFO', x) // eslint-disable-line no-console
    } else if (x.length === 1) {
        console.info('INFO', x[0]) // eslint-disable-line no-console
    }
}

function setSessionCache(x, replace = true): void {
    // Save data to session storage
    if (replace) {
        sessionStorage.setItem('logger_session', JSON.stringify(x))
    } else {
        const existing = getSessionCache()
        if (existing) {
            sessionStorage.setItem('logger_session', JSON.stringify({ ...existing, ...x }))
        } else {
            sessionStorage.setItem('logger_session', JSON.stringify(x))
        }
    }
}

function getSessionCache(): any {
    // Retrieve data from session storage
    const cache = sessionStorage.getItem('logger_session')
    let json = {}
    if (cache) {
        try {
            json = JSON.parse(cache)
        } catch (e) {
        }
    }
    return json
}

function captureException(e) {
    Sentry.captureException(e);

}

export const logger = {
    debug,
    error,
    errorWithReport,
    warn,
    info,
    setSessionCache,
    getSessionCache,
    captureException,
}

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'components'
import { icons } from 'app/constants';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
// import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
// 
// import './styles.css';

const containerStyle = { 
    position: 'absolute', 
    zIndex: 1000 
};

/*const ZoomOutButton = ({ disabled, onClick }) => (
    <button className='iconButton zoom-button' style={{ margin: '10px' }} onClick={onClick} disabled={disabled}>
        <FontAwesomeIcon icon={faMinus} fixedWidth />
    </button>
);

const ZoomInButton = ({ disabled, onClick }) => (
    <button className='iconButton zoom-button' style={{ margin: '10px', marginLeft: '0px' }} onClick={onClick} disabled={disabled}>
        <FontAwesomeIcon icon={faPlus} fixedWidth />
    </button>
);*/

const ZoomButtons = ({scale, minScale, maxScale, onZoomInClick, onZoomOutClick, zoomedIn, options}) => (
    <div className='zoom-container' style={containerStyle}>
        <IconButton icon={zoomedIn ? icons.searchMinus : icons.searchPlus} onClick={() => {zoomedIn ? onZoomOutClick() : onZoomInClick()}}/>
        {options}
        {/* <ZoomOutButton onClick={onZoomOutClick} disabled={scale <= minScale} /> */}
        {/* <ZoomInButton onClick={onZoomInClick} disabled={scale >= maxScale} /> */}
    </div>
);

ZoomButtons.propTypes = {
    options: PropTypes.element.isRequired,
    scale: PropTypes.number.isRequired,
    minScale: PropTypes.number.isRequired,
    maxScale: PropTypes.number.isRequired,
    onZoomInClick: PropTypes.func.isRequired,
    onZoomOutClick: PropTypes.func.isRequired,
    zoomedIn: PropTypes.bool.isRequired,
};

export default ZoomButtons;
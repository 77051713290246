import React, { useState, useEffect, RefObject } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
    Button,
    FavouriteButton,
    Media,
    IconButton,
    CloseButton,
    Icon,
} from 'components'
import {
    RootState,
    AppData,
    ListStyle,
    CustomEventType,
    PromptOptions,
} from 'app/types'
import {
    showPrompt,
    initializeProject,
} from 'actions/appActions'
import { ProjectDetails } from './ProjectDetails'
import { Grid } from 'react-babylonjs'
import { LocationLink } from './LocationLink'

export interface ProjectTileProps {
    app: AppData,
    favourite: boolean,
    minimal: boolean,
    focus: boolean,
    onClick: () => void,
    onApp: () => void,
    onOrganization: () => void,
    onClose: () => void,
    onLocation: () => void,
    tileStyle: ListStyle,
    hoverEvents: boolean,
    className: string,
    title?: boolean,
    onlyFavourites?: boolean,
    visit?: boolean,
    children: JSX.Element[],
    forwardRef: React.RefObject,
    splitIndex: splitIndex,
}
const ProjectLink = React.forwardRef(({ navigate, ...props }, ref) => {
    return (
        <a ref={ref} {...props}>{props.children}</a>
    )
})

export function ProjectTile(props: ProjectTileProps) {
    const {
        app,
        favourite,
        onClick,
        onLocation,
        minimal,
        focus,
        tileStyle,
        hoverEvents,
        className,
        children,
        title,
        onlyFavourites,
        visit,
        onClose,
        forwardRef,
        splitIndex,
    } = { title: true, hoverEvents: true, tileStyle: ListStyle.Grid, ...props }

    const dispatch = useAppDispatch()
    const allMedia = useAppSelector((state: RootState) => state.app.media)
    const media = app ? allMedia[app.meta.mediaId] : null
    const projectPath = useAppSelector((state: RootState) => state.app.projectPath)
    const standaloneOrg = useAppSelector((state: RootState) => state.app.standaloneOrg)

    const config = useAppSelector((state: RootState) => state.app.config)
    const favourites = useAppSelector((state: RootState) => state.user.favourites)
    const organizations = (config && app && app.meta.organizations) ?
        app.meta.organizations.map((x) => config.organizations.find((y) => y.id == x))
            .filter((x) => x != null)
        : null

    function handleMouseEnter() {
        window.dispatchEvent(new CustomEvent(CustomEventType.ShowMapPopup, { detail: { id: app.meta.id } }))
    }

    function handleMouseLeave() {
        setTimeout(() => {
            window.dispatchEvent(new CustomEvent(CustomEventType.HideMapPopup, { detail: { id: app.meta.id } }))
        }, 10)
    }

    function handleBooking(e) {
        e.stopPropagation()
        e.preventDefault()
        dispatch(showPrompt({ ...PromptOptions.Booking, app }))
        return false
    }

    function handleRequestInfo(e) {
        e.stopPropagation()
        e.preventDefault()
        window.dispatchEvent(new CustomEvent(CustomEventType.RequestInfo, { detail: { app } }))
    }

    function handleClick(e: Event) {
        if (splitIndex != null && onlyFavourites) {
            return null
        }
        e.stopPropagation()
        if (onClick) {
            onClick(app)
        }
    }

    function getFavouriteBadge() {
        return null
    }


    let attrs = `noselect${focus ? ' focus' : ''}${minimal ? ' minimal' : ''}${className ? ` ${className}` : ''}`

    // Show 1st variation
    let style = { opacity: 1 }
    if (!app) {
        switch (tileStyle) {
            case ListStyle.List:
                break
            case ListStyle.Grid:
                return <div
                    ref={forwardRef}
                    className={`tile with-media project-tile placeholder ${attrs}`}
                    style={style}>
                    <div className="row project-tile-title" />
                    <div className="row project-tile-preview" />
                    <div className="row project-details" />
                    <div className="row project-details-alt" />
                    <div className="row project-tile-options" />
                </div>

                break
        }
    } else {
        let salesStatus = null
        if (app && app.meta.projectStatusId != null) {
            salesStatus = config.projectStatuses[app.meta.projectStatusId].name
        }
        switch (tileStyle) {
            case ListStyle.List:
                switch (config.theme) {
                    case 'thepropertylinq':
                        return <tr key={app.meta.id} ref={forwardRef} className={`tile item-list-item project-tile ${attrs}`} onClick={onlyFavourites && onClick != null ? null : handleClick} style={style}>
                            <td>
                                <div className="row project-tile-preview">
                                    {media && <Media className="nodrag" app={app} mediaId={media.id} fadeIn />}
                                    {salesStatus && <Button className="project-tile-status" onClick={handleRequestInfo}>{salesStatus}</Button>}
                                    {visit && <CloseButton onClick={onClose} />}
                                    {!visit && <IconButton tertiary className="bottom-right booking" icon="far fa-paper-plane" onClick={handleRequestInfo} />}
                                </div>
                            </td>
                            <td>
                                <div className="row project-tile-title">
                                    <div className="column">
                                        {title && <h4>{app.meta.name}</h4>}
                                        {app.meta.location && <div className="row location-wrapper">
                                            <Icon noBg icon="fas fa-map-marker-alt" />
                                            <LocationLink location={app.meta.location} minimal={true} />
                                        </div>}
                                    </div>
                                </div>
                                <ProjectDetails app={app} config={config} minimal={true} onLocation={onLocation} />
                                <div className="row project-tile-options">
                                    <Button tertiary onClick={onlyFavourites && onClick != null ? handleClick : null}>Browse Community</Button>
                                </div>
                            </td>
                        </tr>
                        break
                    default:
                        return <tr key={app.meta.id} ref={forwardRef} className={`tile item-list-item ${attrs}`} onClick={onlyFavourites && onClick != null ? null : handleClick} style={style}>
                            {favourite && <td data-field="favourite">
                                <FavouriteButton
                                    alt
                                    noBg
                                    app={app} />
                            </td>}

                            {media && !(standaloneOrg && onlyFavourites && splitIndex != null) && <td data-field="preview" className="plan-tile-preview" onClick={onlyFavourites && onClick != null ? handleClick : null}>
                                <Media app={app} mediaId={media.id} fadeIn />
                                {onlyFavourites && <IconButton icon="fas fa-arrow-circle-right" />}
                                {/* <ProjectDetails app={app} config={config} minimal={true}/> */}
                            </td>}
                            <td data-field="details" className="plan-tile-detail">
                                <h3>{app.meta.name}</h3>
                                <ProjectDetails app={app} config={config} minimal={true} />
                                {/* {!(splitIndex != null && onlyFavourites) && <Button tertiary>View Project</Button>} */}
                            </td>
                        </tr>
                        break
                }
            case ListStyle.Grid:
                let tileContents = null
                switch (config.theme) {
                    case 'thepropertylinq':
                        tileContents = <React.Fragment>
                            <div className="row project-tile-title">
                                <div className="column">
                                    {title && <h4>{app.meta.name}</h4>}
                                    {app.meta.location && <div className="row location-wrapper">
                                        <Icon noBg icon="fas fa-map-marker-alt" />
                                        <LocationLink location={app.meta.location} minimal={true} />
                                    </div>}
                                </div>
                            </div>
                            <div className="row project-tile-preview">
                                {media && <Media className="nodrag" app={app} mediaId={media.id} fadeIn />}
                                {salesStatus && <Button className="project-tile-status" onClick={handleRequestInfo}>{salesStatus}</Button>}
                                {visit && <CloseButton onClick={onClose} />}
                                {!visit && <IconButton tertiary className="bottom-right booking" icon="far fa-paper-plane" onClick={handleRequestInfo} />}
                            </div>
                            <ProjectDetails app={app} config={config} minimal={true} onLocation={onLocation} />
                            <div className="row project-tile-options">
                                <Button tertiary>Browse Community</Button>
                            </div>
                        </React.Fragment>
                        break
                    default:
                        tileContents = <React.Fragment>
                            <div className="row project-tile-preview">
                                {media && <Media className="nodrag" app={app} mediaId={media.id} fadeIn />}
                                {title && <h4>{app.meta.name}</h4>}
                                {salesStatus && <Button className="project-tile-status" onClick={handleBooking}>{salesStatus}</Button>}
                                {visit && <CloseButton onClick={onClose} />}
                                {!visit && <IconButton className="top-right booking" icon="fas fa-calendar-alt" onClick={handleBooking} />}
                            </div>
                            <div className="row project-tile-options">
                                {favourite
                                    && <FavouriteButton
                                        alt
                                        noBg
                                        app={app} />}
                                {visit && <Button tertiary>View Project</Button>}
                            </div>
                            {/* <ProjectDetails app={app} config={config} minimal={true} onLocation={onLocation}/> */}
                            <ProjectDetails app={app} config={config} minimal={true} onLocation={onLocation} />
                            {onlyFavourites && getFavouriteBadge()}
                        </React.Fragment>
                        break
                }

                if (app.meta.altAppLink) {
                    return <a href={app.meta.altAppLink} target="_blank"
                        key={app.meta.id}
                        ref={forwardRef}
                        className={`tile with-media project-tile ${attrs}`}
                        style={style}
                        onMouseEnter={hoverEvents ? handleMouseEnter : null}
                        onMouseLeave={hoverEvents ? handleMouseLeave : null}>
                        {children}
                        {tileContents}
                    </a>
                }

                if (onClick) {
                    return <div
                        key={app.meta.id}
                        ref={forwardRef}
                        className={`tile with-media project-tile ${attrs}`}
                        style={style}
                        onClick={handleClick}
                        onMouseEnter={hoverEvents ? handleMouseEnter : null}
                        onMouseLeave={hoverEvents ? handleMouseLeave : null}>
                        {children}
                        {tileContents}
                    </div>
                }

                return <Link to={`${projectPath}${app.meta.link}`}
                    key={app.meta.id}
                    ref={forwardRef}
                    className={`tile with-media project-tile ${attrs}`}
                    style={style}
                    onMouseEnter={hoverEvents ? handleMouseEnter : null}
                    onMouseLeave={hoverEvents ? handleMouseLeave : null}>
                    {children}
                    {tileContents}
                </Link>
        }
    }
    return null
}

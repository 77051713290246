import React, { useEffect, useState, useRef } from 'react'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
    RootState,
    CustomEventType,
    QueryType,
    ListStyle,
    Dict,
    ScreenSize,
    ScreenOrientation,
    Filter,
} from 'app/types'
import {
    FilterOptions,
    Spinner,
    IconButton,
    Icon,
    TopBar,
    Message,
    FilterList,
    BackButton,
    BreadCrumbs,
    ScrollHelper,
    OptionBar,
    Toggle,
} from 'components'

export type ItemHeaderDef = {
    name: string,
    key: string,
    sortable: boolean,
}

interface ItemListProps {
    id: string,
    app: AppData,
    items: T[],
    splitIndex: number,
    scrollReturn: boolean,
    titleText: string,
    refreshKey: string,
    filters: Filter[],
    filter: () => boolean,
    sort: () => number,
    map: () => T,
    headers: ItemHeaderDef[],
    queryType: QueryType,
    filterRanges: Dict,
    beforeOptions: JSX.Element[],
    leftOptions: JSX.Element[],
    rightOptions: JSX.Element[],
    afterOptions: JSX.Element[],
    onBack: () => void,
    missing: JSX.Element[],
    columns: number,
    onFilter: () => T[],
    breadCrumbs: boolean,
    expanded: boolean,
    onExpand: () => void,
    className: string,
}


const scrollPosition = {}
const itemRefs = {}
export function ItemList<T>(props: ItemListProps) {
    const {
        id,
        app,
        items,
        splitIndex,
        titleText,
        scrollReturn,
        refreshKey,
        filters,
        filter,
        sort,
        map,
        headers,
        queryType,
        filterRanges,
        beforeOptions,
        leftOptions,
        rightOptions,
        afterOptions,
        onBack,
        missing,
        columns,
        onFilter,
        breadCrumbs,
        expanded,
        onExpand,
        className,
    } = {
        id: 'list',
        scrollReturn: true,
        columns: 4,
        ...props,
    }

    const dispatch = useAppDispatch()
    const fullscreen = useAppSelector((state: RootState) => state.app.fullscreen)
    const navigation = useAppSelector((state: RootState) => state.app.navigation)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const links = useAppSelector((state: RootState) => state.app.links)
    const prevLinks = useAppSelector((state: RootState) => state.app.prevLinks)
    // const query = useAppSelector((state:RootState) => state.app.queries[QueryType.Floorplans])
    // const allFavourites = useAppSelector((state:RootState) => state.user.favourites)
    // const favourites = useAppSelector((state:RootState) => app ? state.user.favourites[app.meta.id] : null)
    // const favouriteCount = favourites ? Object.keys(favourites).length : 0

    const [itemLimit, setItemLimit] = useState(0)
    const [filteredItems, setFilteredItems] = useState<T[]>([])
    const [finalItems, setFinalItems] = useState<T[]>([])
    const [initialized, setInitialized] = useState(false)
    const [sortKey, setSortKey] = useState(0)
    const forceGrid = splitIndex != null && screen.size == ScreenSize.Mobile && screen.orientation == ScreenOrientation.Portrait

    const [listStyle, setListStyle] = useState((() => {
        if (forceGrid) {
            return ListStyle.Grid
        }
        let cached = localStorage.getItem('listStyle')
        if (cached) {
            return cached
        } else {
            return ListStyle.Grid
        }
    })())

    // [sortField, isDescending]
    const [listOrder, setListOrder] = useState(['name', true])
    const [loadTime, setLoadTime] = useState(0)

    const scrollRef = useRef()
    const scrollBodyRef = useRef()
    const barRef = useRef()
    const [focusId, setFocusId] = useState(null)

    useEffect(() => {
        window.addEventListener(CustomEventType.ResetScroll, resetScroll)

        return () => {
            window.removeEventListener(CustomEventType.ResetScroll, resetScroll)
        }
    }, [])

    useEffect(() => {
        if (splitIndex == null) {
            return
        }
        window.addEventListener(CustomEventType.ToggleListStyle, windowToggleListStyle)
        return () => {
            window.removeEventListener(CustomEventType.ToggleListStyle, windowToggleListStyle)
        }
    }, [listStyle])

    useEffect(() => {
        setInitialized(false)

        let scrollTarget = 0
        // If going from related page, get cache
        if (prevLinks && prevLinks.pageLink == links.pageLink) {
            scrollTarget = getScrollCache()
        }
        let tempItemLimit = 0
        let initLimit = 3000
        let timeElapsed = 0
        let isInitialized = false
        let batchInterval = null
        const batchSize = 8
        const batchDelay = 100
        if (items) {
            if (listStyle == ListStyle.List) {
                setItemLimit(items.length)
                setInitialized(true)
                return
            }
            // First batch
            tempItemLimit += batchSize
            setItemLimit(Math.min(tempItemLimit, items.length))
            // Show right away if not attempting to return to scrolled position
            if (!scrollReturn || scrollTarget == 0) {
                isInitialized = true
                setInitialized(true)
            }

            batchInterval = setInterval(() => {
                timeElapsed += batchDelay
                setLoadTime(timeElapsed)
                // Took to long for scrollReturn
                if (timeElapsed > initLimit && !isInitialized) {
                    isInitialized = true
                    setInitialized(true)
                }

                tempItemLimit += batchSize
                setItemLimit(Math.min(tempItemLimit, items.length))

                if (scrollReturn && !isInitialized
                    && scrollRef.current && scrollBodyRef.current && scrollBodyRef.current.clientHeight >= scrollTarget) {
                    isInitialized = true
                    setTimeout(() => {
                        setInitialized(true)
                        scrollRef.current.scrollTop = scrollTarget - 4
                    }, 100)
                }

                // If we've reached our limit clear
                if (tempItemLimit >= items.length) {
                    if (!isInitialized) {
                        setInitialized(true)
                    }
                    clearInterval(batchInterval)
                }

            }, batchDelay)
        } else {
            setInitialized(true)
        }
        return () => {
            clearInterval(batchInterval)
        }
    }, [items, listStyle])

    useEffect(() => {
        if (!items) {
            return
        }
        let newItems = items
        if (filter) {
            newItems = newItems.filter(filter)
        }
        if (onFilter) {
            onFilter(newItems)
        }
        setFilteredItems(newItems)
    }, [items, refreshKey])

    useEffect(() => {
        let newFinal = null
        if (sort) {
            newFinal = filteredItems.sort((a, b) => sort(a, b, listStyle, listOrder))
        } else {
            newFinal = filteredItems
        }
        setFinalItems(newFinal)
        setSortKey(Date.now())
    }, [filteredItems, listOrder])

    useEffect(() => {
        resetScroll()
    }, [refreshKey])

    useEffect(() => {
        window.addEventListener(CustomEventType.FocusList, handleFocus)
        return () => {
            window.removeEventListener(CustomEventType.FocusList, handleFocus)
        }
    }, [finalItems])

    function handleFocus(e) {
        if (splitIndex != null) {
            return
        }
        if (e.detail && 'id' in e.detail) {
            const id = e.detail.id
            setFocusId(id)
            if (id in itemRefs && itemRefs[id]) {
                itemRefs[id].scrollIntoView({ behavior: 'smooth' })
            }
        }
    }

    function windowToggleListStyle(e) {
        if (e.detail && e.detail.splitIndex != splitIndex) {
            toggleListStyle(false)
        }
    }

    function toggleListStyle(windowEvent = true) {
        setScrollCache(0)
        setInitialized(false)
        setItemLimit(0)
        if (listStyle === ListStyle.Grid) {
            setListStyle(ListStyle.List)
            setListOrder(['name', true])
            localStorage.setItem('listStyle', ListStyle.List)
        } else {
            setListStyle(ListStyle.Grid)
            localStorage.setItem('listStyle', ListStyle.Grid)
        }
        if (windowEvent) {
            window.dispatchEvent(new CustomEvent(CustomEventType.ToggleListStyle, { detail: { splitIndex } }))
        }
    }

    function handleScroll(e: Event) {
        setScrollCache(e.target.scrollTop)
    }

    function setScrollCache(val) {
        scrollPosition[id] = val
        // localStorage.setItem(`scroll-${id}`, val)
    }
    function getScrollCache() {
        if (id in scrollPosition) {
            return scrollPosition[id]
        }
        return 0
    }

    function resetScroll(e) {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0
            setScrollCache(0)
        }
    }

    function toggleSort(key: string) {
        if (listOrder[0] === key) {
            setListOrder([listOrder[0], !listOrder[1]])
        } else {
            setListOrder([key, true])
        }
        resetScroll()
    }

    function getSortHeader(key: string, label, style: Dict) {
        let downIcon = 'fas fa-chevron-down'
        let upIcon = 'fas fa-chevron-up'
        if (listOrder[0] === key) {
            if (listOrder[1]) {
                downIcon = 'fas fa-chevron-circle-down'
            } else {
                upIcon = 'fas fa-chevron-circle-up'
            }
        }
        return <th key={key} field={key} onClick={() => toggleSort(key)} style={style} className="sort-header noselect" data-field={key}>
            <div className="sort-wrapper">
                <span className="sort-label">{label}</span>
                <div className="sort-buttons">
                    <Icon noBg icon={upIcon} />
                    <Icon noBg icon={downIcon} />
                </div>
            </div>
        </th>
    }

    function getItems() {
        if (finalItems.length === 0) {
            return null
        }

        let top = 0
        if (barRef.current) {
            top = barRef.current.clientHeight
        }
        const headerStyle = {
            // top: `${top}px`
        }

        if (listStyle === ListStyle.Grid) {
            return finalItems.slice(0, itemLimit).map((x, ix) => {
                if (x.meta && splitIndex == null) {
                    const elem = map(x, ix, listStyle, focusId == x.meta.id, x.meta ? (elem) => {
                        itemRefs[x.meta.id] = elem
                    }
                        : null)
                    return elem
                } else {
                    return map(x, ix, listStyle)
                }
            })
        }
        return <table ref={scrollBodyRef}>
            <thead>
                <tr>
                    {headers.map((x: ItemHeaderDef) => {
                        if (x.sortable) {
                            return getSortHeader(x.key, x.name, headerStyle)
                        }
                        return <th key={x.key} data-field={x.key} className={x.className ? x.className : ''} style={headerStyle}>{x.name}</th>
                    })}
                </tr>
            </thead>
            <tbody key={sortKey}>
                {finalItems.slice(0, itemLimit).map((x, ix) => map(x, ix, listStyle, x.meta && focusId == x.meta.id, (elem) => {
                    itemRefs[x.meta.id] = elem
                }))}
            </tbody>
        </table>
    }

    function getBefore() {
        let rightElement = rightOptions
        // If using element map
        if (rightOptions && typeof rightOptions == 'function') {
            rightElement = rightOptions(listStyle)
        }
        const right = <React.Fragment>
            {beforeOptions}
            {rightElement}
            {!forceGrid && <Toggle items={[
                {
                    icon: 'fas fa-list-ul',
                    value: ListStyle.List,
                },
                {
                    icon: 'fas fa-th-large',
                    value: ListStyle.Grid,
                }
            ]}
                value={listStyle}
                onChange={toggleListStyle} />}
            {onExpand && <IconButton icon={expanded ? 'fas fa-compress-alt' : 'fas fa-expand-alt'} onClick={onExpand}/>}

            {/* {<IconButton className={`list-style-button${listStyle == ListStyle.List ? ' selected' : ''}`} icon="fas fa-list-ul" onClick={listStyle != ListStyle.List ? toggleListStyle : null} />} */}
            {/* {<IconButton className={`list-style-button${listStyle == ListStyle.Grid ? ' selected' : ''}`} icon="fas fa-th-large" onClick={listStyle != ListStyle.Grid ? toggleListStyle : null} />} */}
        </React.Fragment>

        let left = null
        if (queryType) {
            left = <React.Fragment>
                {leftOptions}
                {filters && <FilterOptions
                    app={app}
                    filters={filters}
                    queryType={queryType}
                    ranges={filterRanges}
                    // heightRef={barRef}
                    splitIndex={splitIndex}
                    expandedAsDropdown
                    maps={app?.maps}
                    listSelected>

                </FilterOptions>}
            </React.Fragment>
        }

        return <OptionBar
            key="item-list"
            className="animate__animated animate__fadeIn animate__faster"
            splitIndex={splitIndex}
            leftOptions={left}
            rightOptions={right}
            titleText={titleText}
            compare={items.length > 1}
            app={app} />
    }

    function getAfter() {
        if (!initialized || itemLimit === 0) {
            return null
        }
        if (afterOptions) {
            return <TopBar className="item-list-options after fadeIn">
                {afterOptions}
            </TopBar>
        }
        return null
    }

    function getMissing() {
        if (missing) {
            return missing
        }
        if (queryType) {
            <Message info="No results found matching these filters. Please try another filter." />
        }
        return null
    }

    const style = {
        opacity: initialized ? 1 : 0,
    }

    return <React.Fragment>
        {getBefore()}
        {onBack && <BackButton style={{ marginTop: barRef.current.clientHeight }} sticky fixed onClick={onBack} />}
        <div id={id} className={`content scrollable ${fullscreen ? ' fullscreen' : ''}${className ? ` ${className}` : ''}`}
            onScroll={handleScroll}
            ref={scrollRef}>
            {breadCrumbs && <BreadCrumbs app={app} splitIndex={splitIndex} />}
            {!initialized && <Spinner overlay show={loadTime > 500} />}
            <div className="content-wrapper" ref={true || listStyle === ListStyle.Grid ? scrollBodyRef : null}>
                <div id="items"
                    key={listStyle}
                    className={`tile-list by-${columns} ${listStyle === ListStyle.List ? 'item-list' : ''}`}
                    style={style}>
                    {queryType && <FilterList app={app} type={queryType} maps={app?.maps}/>}
                    {finalItems.length === 0 && getMissing()}
                    {getItems()}
                    {initialized && items && itemLimit != items.length && <div className="row" style={{ minHeight: '200px' }}><Spinner overlay show={loadTime > 500} quotes={false} /></div>}
                </div>
                {getAfter()}
            </div>
            {initialized && <ScrollHelper scrollElem={scrollRef.current} />}
        </div>
    </React.Fragment>
}

import React from 'react'
import { logger } from 'helpers/logger'
import { Message, TopBar, Logo, Button } from 'components'
import { ErrorMessage } from 'app/types'
import { getWindowLocation } from 'helpers/config'
import { reportError } from 'services/appServices'

const parseErrorString = (input) => {
    if (!input || !(typeof input === 'string' || input instanceof String)) {
        return null
    }
    const lines = input.split('\n')
    const output = []
    lines.forEach((d, i) => {
        if (i > 0) {
            output.push(<br />)
        }
        output.push(d)
    })
    return output
}

const isChunkLoadError = (error) => {
    return error && error.name == "ChunkLoadError"
}

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { location: null, hasError: false, error: null, initialized: 0, errorId: null, reported: false }
        this.handleBack = this.handleBack.bind(this)
        this.handleReport = this.handleReport.bind(this)

    }
    static getDerivedStateFromProps(nextProps, prevState) {
        const newState = {}
        if (nextProps.error) {
            newState.reported = false
            newState.hasError = true
            newState.error = nextProps.error
        }
        if (nextProps.initialized != null) {
            newState.initialized = nextProps.initialized
        }
        return newState
    }


    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error, reported: false, errorId: null, location: getWindowLocation(), chunk: isChunkLoadError(error) }
    }

    handleBack() {
        this.props.onBack(this.state.location)
        this.setState({ hasError: false, error: null, errorId: null, location: null, reported: false })
    }

    handleRefresh() {
        getWindowLocation().reload()
        this.setState({ hasError: false, error: null, errorId: null, location: null, reported: false })
    }

    handleReport() {
        this.setState({ reported: true })
        reportError(this.state.errorId)
        // this.props.onReport()
    }


    componentDidCatch(error, errorInfo) {
        // If error is of type ChunkLoadError, prompt user to refresh
        if (isChunkLoadError(error)) {
            logger.info("Skip reporting chunk error")
            return
        }
        // You can also log the error to an error reporting service
        logger.errorWithReport(error, errorInfo, getWindowLocation(), logger.getSessionCache())
            .then((x) => {
                // this.setState({ errorId: x })
                this.setState({ errorId: x })
                // reportError(x)
            })
        // logErrorToMyService(error, errorInfo)
    }


    render() {
        if (this.state.hasError && this.state.error != null) {
            // You can render any custom fallback UI
            let msg = null
            let msgElem = null
            if (process.env.DEBUG == 1) {
                // logger.error(this.state.error)
                if (this.state.error && this.state.error.message && this.state.error.stack) {
                    msg = `ERROR: ${this.state.error.message}\nSTACK: ${this.state.error.stack}`
                } else {
                    msg = this.state.error.toString()
                }
            }

            if (this.props.renderNull) {
                return null
            }

            if (this.state.chunk) {
                msg = msgElem = ErrorMessage.ApplicationUpdated
            } else if (!msg || msg.length == 0) {
                // msg = ErrorMessage.LoadError
                msg = msgElem = ErrorMessage.General
            } else if (msg) {
                msgElem = parseErrorString(msg)
            }

            // if (this.state.reported) {
            // msgElem = ErrorMessage.Reported
            // }

            return <div className="error-boundary row" style={{ flexDirection: 'column' }}>
                {this.state.initialized >= 1 && <TopBar className="mainbar">
                    <div className="row">
                        <Logo fixed />
                    </div>
                </TopBar>}
                <Message info={msg ? msgElem : ErrorMessage.Unknown} />
                <div className="row" style={{ justifyContent: 'center', marginTop: '20px' }}>
                    {
                        !msg || msg.includes('refresh') || this.state.chunk
                            ? <Button tertiary large onClick={this.handleRefresh}>Reload Page</Button>
                            : <Button tertiary large onClick={this.handleBack}>Return to Application</Button>
                    }
                    {/* {!this.state.reported && this.state.error != null && this.state.errorId != null && <Button tertiary large onClick={this.handleReport}>Report</Button>} */}
                </div>
            </div>
        }

        return this.props.children
    }
}
import React from 'react'
import { Dict } from 'app/types'

interface IconProps {
    icon: string,
    className?: string,
    large?: boolean,
    small?: boolean,
    noBg?: boolean,
    style?: Dict,
}
export function Icon(props: IconProps) {
    const {
        icon,
        className,
        large,
        small,
        noBg,
        style,
    } = props
    const attrs = [
        className,
        noBg ? 'no-bg' : null,
        large ? 'button-large' : null,
        small ? 'button-small' : null,
    ].filter((x) => x !== null).join(' ')

    return <div className={`icon noselect ${attrs}`} style={style}>
        <i className={icon} />
    </div>
}

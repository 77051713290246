import React, { useEffect } from 'react'
import {
    AnalyticsEvent,
    AppData, CustomContentType, CustomEventType, FloorplanFilter, FormFieldType, ProjectFilter, PromptOptions, ThumbSize,
} from 'app/types'
import {
    Media, SlideShow, WaterMark, Button, ScrollHelper,
} from 'components'
import { IntersectionHelper } from 'views/DynamicPage/IntersectionHelper'
import { Link } from 'react-router-dom'
import { showPrompt } from 'actions/appActions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RequestInfo } from 'views/AppPage/RequestInfo'
import { CustomContent } from './CustomContent'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { LocationLink } from 'views/HomePage/LocationLink'

export const ThePropertyLinqContentData = {
    home: [
        {
            type: CustomContentType.Splash,
            title: 'Connect with your\n\ndream home in Georgian Bay',
            body: "Welcome to The Property LinQ, where you can find the latest new build homes in one of Ontario's most inspiring areas. Immerse yourself in resort-inspired living and explore our top collection of Georgian Bay properties from the best builders within the region. Browse virtual interior renderings using the latest technologies, with the help of our knowledgeable sales staff for a homebuying journey that's simple and convenient.",
            links: [
                {
                    label: 'Shop New Homes',
                    link: 'developments',
                },
                /* {
                    label: 'Shop Resale',
                    link: 'search',
                }, */
            ],
            media: { galleryLink: 'tpl-landing-splash' },
        },
        {
            type: CustomContentType.Latest,
            title: 'The latest developments in Georgian Bay',
            body: "New projects are breaking ground all over Georgian Bay. and we're here to help you Connect with yours — whether it's a place to retire, a vacation-style rental, or a home to raise your family. Discover a few new build developments below, or use our community map to explore your preferred areas with a bird's eye view.",
            links: [{ label: 'View Community Map', link: 'developments' }],
        },
        /*{
            type: CustomContentType.Events,
            title: 'Upcoming Events',
            body: 'Join us at The Property LinQ sales office for the following upcoming events!',
            link: 'events',
            elements: [
                // {
                //     title: 'The Summit by Primont Grand Opening!',
                //     date: 'Saturday, June 29 and Sunday, June 30 from 12 pm - 3 pm both days',
                //     link: 'summit/grand-opening',
                //     location: {
                //         name: 'The Property LinQ',
                //         numberAndStreet: '11352 ON-26',
                //         city: 'Collingwood',
                //         provinceState: 'ON',
                //     },
                //     media: {
                //         galleryLink: 'tpl-landing-events',
                //         index: 0,
                //         link: 'summit/grand-opening'
                //     },
                //     body: 'The Property LinQ proudly announces the grand opening of The Summit and The Summit 2 by Primont!\n\nJoin us at our Collingwood location on the Canada Day long weekend for a complimentary artisan BBQ by Road Runner Foods of Collingwood, accompanied by craft brew by Black Bellows Brewing Company.\n\nRegister today to secure your spot!',
                //     links: [
                //         {
                //             label: 'Learn More',
                //             link: 'summit/grand-opening'
                //         }
                //     ]
                // },
                // {
                //     title: 'March Break Event',
                //     date: 'March 9th and March 10th, 2024\n\n11 am to 6 pm',
                //     link: 'gatesofmeaford/event',
                //     location: {
                //         numberAndStreet: '206105 Hwy 26',
                //         city: 'Meaford',
                //         provinceState: 'ON',
                //     },
                //     media: {
                //         galleryLink: 'tpl-landing-events',
                //         index: 0,
                //         link: 'gatesofmeaford/event'
                //     },
                //     body: 'Enjoy:\n\n* Free pizza!\n* Bouncy house for the kids\n* Our virtual reality experience',
                //     links: [
                //         {
                //             label: 'Learn More',
                //             link: 'gatesofmeaford/event'
                //         }
                //     ]
                // },
                // {
                //     title: 'Bungalow Launch Event on Victoria Day Weekend!',
                //     date: 'May 18 + 19 from 12pm-3pm',
                //     link: 'bungalow-launch-event',
                //     location: {
                //         numberAndStreet: '11352 ON-26',
                //         city: 'Collingwood',
                //         provinceState: 'ON',
                //     },
                //     media: {
                //         galleryLink: 'tpl-landing-events',
                //         index: 3,
                //         link: 'gatesofmeaford/bungalow-launch-event',
                //     },
                //     body: 'Register for our FREE event this Victoria Day long weekend! Enjoy a complimentary BBQ lunch and get VIP access to exclusive incentives when you purchase a new bungalow or bungaloft model.',
                //     links: [
                //         {
                //             label: 'Learn More',
                //             link: 'gatesofmeaford/bungalow-launch-event',
                //         }
                //     ]
                // }
                {
                    title: 'The Summit Mortgage Buydown Event',
                    date: 'August 3 - 5, 2024, 12-3pm',
                    link: 'summit/buydown-event',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                    media: {
                        galleryLink: 'tpl-landing-events',
                        index: 0,
                        link: 'summit/buydown-event',
                    },
                    body: "Join us to learn more about The Summit's Mortgage Buy Down and take advantage of reduced interest rates and low monthly mortgage payments, easier qualification criteria and greater long-term savings.",
                    links: [
                        {
                            label: 'Learn More',
                            link: 'summit/buydown-event',
                        }
                    ]
                },
                {
                    title: 'Gates of Meaford Bungalow Promotion Event',
                    date: 'August 24-25, 2024, 12-3pm',
                    link: 'gatesofmeaford/bungalow-promo-event',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                    media: {
                        galleryLink: 'tpl-landing-events',
                        index: 1,
                        link: 'gatesofmeaford/bungalow-promo-event',
                    },
                    body: "Join us for our bungalow promotion event! Don't wait to discover your dream home at the Gates of Meaford by Nortterra.",
                    links: [
                        {
                            label: 'Learn More',
                            link: 'gatesofmeaford/bungalow-promo-event',
                        }
                    ]
                },
                {
                    title: 'Primont Labour Day Event',
                    date: 'August 31st - September 2rd, 2024, 12-3pm',
                    link: 'summit/primont-labour-day-event',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                    media: {
                        galleryLink: 'tpl-landing-events',
                        index: 0,
                        link: 'summit/primont-labour-day-event',
                    },
                    body: "Join us to learn more about Primonts Labour Day Event.",
                    links: [
                        {
                            label: 'Learn More',
                            link: 'summit/primont-labour-day-event',
                        }
                    ]
                },
            ]
        },*/
        {
            type: CustomContentType.Search,
            title: 'Begin your search your way',
            body: "Already know just what you're looking for? Easily tailor your search by using our helpful filters below, or connect with a member of our sales team who can help guide you through the process.",
            elements: [
                {
                    label: 'Home Type',
                    field: ProjectFilter.ProjectType,
                },
                {
                    label: 'Square Feet',
                    field: FloorplanFilter.Sqft,
                }, /* ,
                {
                    label: 'Neighbourhood',
                    field: 'neighbourhood'
                }, */
            ],
            dark: true,
        },
        {
            type: CustomContentType.Numbered,
            title: 'Changing the way to find your new home',
            elements: [
                {
                    title: 'All of Georgian Bay at your fingertips',
                    body: "Our one-stop-shop for finding your next home in one of Ontario's most inspiring areas. Be the first to access exclusive development projects with the help of our innovative search tools and established partner network.",
                    media: { galleryLink: 'tpl-landing-numbered', index: 0 },
                    links: [{
                        label: 'Browse Communities',
                        link: 'developments',
                        alt: true,
                    }],
                },

                {
                    title: 'Browse home exterior and Interiors Virtually',
                    body: 'From ground-breaking virtual tours with VR technology to design software that help you choose your floorplans and finishes, we are transforming the homebuying process from start to finish.',
                    media: { galleryLink: 'tpl-landing-numbered', index: 1 },
                    links: [{
                        label: 'How It Works',
                        link: 'aboutus',
                        alt: true,
                    }],
                },
                {
                    title: 'Simple and easy homebuying process',
                    body: "Our team of sales professionals have deep roots in Georgian Bay and can give you an insider's perspective on the area. That way, you can trust in our expertise and support throughout your journey.",
                    media: { galleryLink: 'tpl-landing-numbered', index: 2 },
                    links: [{
                        label: 'Request Info',
                        link: '#request',
                        alt: true,
                    }],
                },
            ],
        },
        {
            type: CustomContentType.Developers,
            title: 'Featured developers',
            body: "We work with some of Ontario's best builders to bring you spaces that are truly stunning. Meet some of our partners below, and start searching for your new home by connecting with us today.",
            alt: true,
        },
        {
            type: CustomContentType.RequestInfo,
        },
        {
            type: CustomContentType.Map,
        },

    ],
    discover: [
        {
            type: CustomContentType.Splash,
            title: 'Experience the beauty\n\nof Georgian Bay',
            body: 'Whether you prefer to play at the beach, fish on the water, hit the links, ski through the mountains, or peruse local shopping and restaurants, Georgian Bay is the ideal location for your next great adventure.',
            media: { galleryLink: 'tpl-discover-splash' },
        },
        {
            type: CustomContentType.Summary,
            title: 'Composed of an eclectic group of tight-knit communities surrounded by an abundance of natural beauty, we can help make finding your dream home a reality.',
            body: "Learn more about one of Ontario's most sought-after areas below, or connect with our team for an insider's point of view.",
            links: [{ label: 'Get In Touch', link: '#request' }],
            alt: true,
        },
        {
            type: CustomContentType.Tabbed,
            elements: [
                {
                    title: 'The great outdoors\n\nat your doorstep',
                    body: "There's no shortage of things to do for those who are active by nature and love the great outdoors. From Sunset Point Beach and Georgian Bay Islands National Park to license-free family fishing and myriad walking trails, you and your loved ones will have no problem keeping busy.",
                    media: { galleryLink: 'tpl-discover-tabbed-1' },
                },
                {
                    title: 'Endless\n\nentertainment',
                    body: 'With a number of local venues like the King’s Wharf Theatre, you’ll never be bored in Georgian Bay. And if you’re in the area in September, don’t miss out on the Georgian Bay Festival – which features musical acts, pop-up markets, and even the Full Harvest Ride bike rally!',
                    media: { galleryLink: 'tpl-discover-tabbed-2' },
                },
                {
                    title: 'Eats\n\nand treats',
                    body: 'Foodies beware, this can be a dangerous place for those who love to eat! Georgian Bay features a diverse mix of cuisines hosted by a variety of charming locales. If you love calming cafes, delightful diners, and family-friendly restaurants, don’t worry – you’re in the right place.',
                    media: { galleryLink: 'tpl-discover-tabbed-3' },
                },

            ],
        },
        {
            type: CustomContentType.Grid,
            title: 'For those who pursue big adventures,\n\nGeorgian Bay needs to be on your bucket list.',
            subtitle: "From the grandeur of Killarney Provincial Park to the snow-filled fun of Blue Mountain Ski Resort,\n\nyou're always just a stone's throw from making new memories.",
            elements: [
                {
                    title: 'Blue Mountain\n\nSki Resort',
                    body: "Ski and snowboard to your heart's content at one of Canada's most premier ski resorts.  Trails and activities are available for all skill levels and ages.",
                    media: { galleryLink: 'tpl-discover-grid', index: 0 },
                },
                {
                    title: 'Killarny\n\nProvincial Park',
                    body: 'For grand adventurers who truly appreciate all that nature has to offer, Killarney has it all. Hiking, camping, and canoeing opportunities abound!.',
                    media: { galleryLink: 'tpl-discover-grid', index: 1 },
                },
                {
                    title: 'Sunset Point Beach Mountain',
                    body: 'An iconic Collingwood waterfront, Sunset Point Beach offers a remarkable sunset view that will please your soul and photo albums in equal measure.',
                    media: { galleryLink: 'tpl-discover-grid', index: 2 },
                },
                {
                    title: 'Georgian Bay Islands\n\nNational Park',
                    body: "The world's largest freshwater archipelago could be right at your front door. This awe-inspiring park consists of 63 small islands and offers ample opportunity to get in touch with nature by land and sea.",
                    media: { galleryLink: 'tpl-discover-grid', index: 3 },
                },
                {
                    title: "King's Warf Theatre",
                    body: 'An iconic Collingwood waterfront, Sunset Point Beach offers a remarkable sunset view that will please your soul and photo albums in equal measure.',
                    body: 'Take in the local culture of Georgian Bay at this 385-seat venue and enjoy family-friendly shows backdropped by historic buildings of a nearby 19th century naval and military base.',
                    media: { galleryLink: 'tpl-discover-grid', index: 4 },
                },
                {
                    title: 'Georgian Bay Festival',
                    body: 'Meet the people of Georgian Bay and experience good fun, food, and music from the endlessly charming Tiny Township. The festival also showcases and celebrates local, artists, craftspeople, and entrepreneurs.',
                    media: { galleryLink: 'tpl-discover-grid', index: 5 },

                },
            ],
        },
        {
            type: CustomContentType.Neighbourhood,
            title: 'Explore exciting\n\nattractions',
            elements: [
                {
                    title: 'Parks and Recreation',
                    elements: [
                        {
                            title: 'Meaford Memorial Park',
                            location: {
                                numberAndStreet: '179 Grant Ave.',
                                city: 'Meaford',
                                provinceState: 'ON',
                                postalZipCode: 'N4L 1A8',
                                latLng: '44.60206734711976, -80.56565211103042',
                            },
                        },
                        {
                            title: 'Meaford Museum',
                            location: {
                                numberAndStreet: '111 Bayfield St.',
                                city: 'Meaford',
                                provinceState: 'ON',
                                postalZipCode: 'N4L 1N4',
                                latLng: '44.6099662925822, -80.58971189371138',
                            },
                        },
                        {
                            title: 'Webwood Falls Nature Reserve',
                            location: {
                                numberAndStreet: 'Sideroad 25',
                                city: 'Kimberley',
                                provinceState: 'ON',
                                postalZipCode: 'N0C 1G0',
                                latLng: '44.476709623664114, -80.62685325435132',
                            },
                        },
                        {
                            title: 'Meaford Golf',
                            location: {
                                numberAndStreet: '408 Ridge Rd.',
                                city: 'Meaford',
                                provinceState: 'ON',
                                postalZipCode: 'N4L 1L8',
                                latLng: '44.609983023558556, -80.60963179804519',
                            },
                        },
                        {
                            title: 'Story Book Park',
                            location: {
                                numberAndStreet: '123221 Story Book Park Rd.',
                                city: 'Owen Sound',
                                provinceState: 'ON',
                                postalZipCode: 'N4K 5N3',
                                latLng: '44.542619492018545, -80.89937609861802',
                            },
                        },
                        {
                            title: 'Irish Mountain Scenic Lookout',
                            location: {
                                numberAndStreet: '265351, 265669 Side Rd 25',
                                city: 'Annan Sound',
                                provinceState: 'ON',
                                postalZipCode: 'N0H 1B0',
                                latLng: '44.63462589685079, -80.69559579890718',
                            },
                        },
                    ],
                },
                {
                    title: 'School and Services',
                    elements: [
                        {
                            heading: 'Schools',
                            title: 'Georgian Bay Community School',
                            location: {
                                numberAndStreet: '197799 Grey Rd. 7',
                                city: 'Meaford',
                                provinceState: 'ON',
                                postalZipCode: 'N4L 1W7',
                                latLng: '44.594116498071614, -80.58322176703467',
                            },
                        },
                        {
                            title: 'Beaver Valley Community School',
                            location: {
                                numberAndStreet: '187 Bruce St. S',
                                city: 'Thornbury',
                                provinceState: 'ON',
                                postalZipCode: 'N0H 2P0',
                                latLng: '44.55329142009288, -80.4606273656958',
                            },
                        },
                        {
                            title: 'Notre Dame Catholic School',
                            location: {
                                numberAndStreet: '885 2th St. E.',
                                city: 'Owen Sound',
                                provinceState: 'ON',
                                postalZipCode: 'N4K 6X6',
                                latLng: '44.58841874279688, -80.92151921144159',
                            },
                        },
                        {
                            title: "St. Mark's High School",
                            location: {
                                numberAndStreet: '555 15th St. E',
                                city: 'Owen Sound',
                                provinceState: 'ON',
                                postalZipCode: 'N4K 1X2',
                                latLng: '44.573174348082354, -80.93293748908303',
                            },
                        },
                        {
                            heading: 'Churches',
                            title: 'Meaford Bible Baptist Church',
                            location: {
                                numberAndStreet: '158182 7th Line',
                                city: 'Meaford',
                                provinceState: 'ON',
                                postalZipCode: 'N4L 1W5',
                                latLng: '44.614985391109556, -80.62318752499269',
                            },
                        },
                        {
                            title: "St Vincent's Church",
                            location: {
                                numberAndStreet: '70 Collingwood St. W',
                                city: 'Meaford',
                                provinceState: 'ON',
                                postalZipCode: 'N4L 1H2',
                                latLng: '44.60881643410055, -80.59525377062569',
                            },
                        },
                        {
                            heading: 'Vets',
                            title: 'Ardiel Animal Hospital',
                            location: {
                                numberAndStreet: '206626 ON-26',
                                city: 'Meaford',
                                provinceState: 'ON',
                                postalZipCode: 'N4L 1W7',
                                latLng: '44.58968793648778, -80.56628260092005',
                            },
                        },
                        {
                            heading: 'Grocery Stores',
                            title: 'Canex Meaford SuperMart',
                            location: {
                                numberAndStreet: '1 Grey Road 112N BLDG M150',
                                city: 'Meaford',
                                provinceState: 'ON',
                                postalZipCode: 'N4L 0A1',
                                latLng: '44.664502881338834, -80.66708352777002',
                            },
                        },
                        {
                            title: 'Walmart Supercentre',
                            location: {
                                numberAndStreet: '1555 18th Ave. E',
                                city: 'Owen Sound',
                                provinceState: 'ON',
                                postalZipCode: 'N4K 6Y3',
                                latLng: '44.57352773098144, -80.91160068531417',
                            },
                        },
                    ],
                },
                {
                    title: 'Health and Wellness',
                    elements: [
                        {
                            heading: 'Community & Recreational Centers',
                            title: 'Meaford and St. Vincent Community Centre',
                            location: {
                                numberAndStreet: '151 Collingwood St. W',
                                city: 'Meaford',
                                provinceState: 'ON',
                                postalZipCode: 'N4L 1K5',
                                latLng: '44.6074753582013, -80.59817080005674',
                            },
                        },
                        {
                            title: 'Meaford Hall - Arts and Cultural Centre',
                            location: {
                                numberAndStreet: '12 Nelson St. E',
                                city: 'Meaford',
                                provinceState: 'ON',
                                postalZipCode: 'N4L 1N6',
                                latLng: '44.6083526819701, -80.59179420063262',
                            },
                        },
                        {
                            heading: 'Hospital',
                            title: 'Meaford General Hospital',
                            location: {
                                numberAndStreet: '12 Nelson St. E',
                                city: 'Meaford',
                                provinceState: 'ON',
                                postalZipCode: 'N4L 1N6',
                                latLng: '44.60584138963135, -80.60019830615757',
                            },
                        },
                        {
                            heading: 'Family Doctors',
                            title: 'Don Bumstead & Family Medical Centre',
                            location: {
                                numberAndStreet: '206106 ON-26',
                                city: 'Meaford',
                                provinceState: 'ON',
                                postalZipCode: 'N4L 1L9',
                                latLng: '44.617773317000676, -80.60961662617835',
                            },
                        },
                    ],
                },
                {
                    title: 'Retail',
                    elements: [
                        {
                            title: 'Clothing Boutique',
                            location: {
                                numberAndStreet: '38 N Sykes St.',
                                city: 'Meaford',
                                provinceState: 'ON',
                                postalZipCode: 'N4L 1V9',
                                latLng: '44.60738117295401, -80.59213859919264',
                            },
                        },
                        {
                            title: 'Blue Mountain Mall',
                            location: {
                                numberAndStreet: '55 Mountain Rd. #5',
                                city: 'Collingwood',
                                provinceState: 'ON',
                                postalZipCode: 'L9Y 4M2',
                                latLng: '44.50363257682604, -80.23787348073812',
                            },
                        },
                        {
                            title: 'Little Brothers Inc.',
                            location: {
                                numberAndStreet: '317714 3 Line',
                                city: 'Meaford',
                                provinceState: 'ON',
                                postalZipCode: 'N4L 1W7',
                                latLng: '44.587687717571924, -80.55616242920146',
                            },
                        },
                        {
                            title: 'Tiny Oak Boutique',
                            location: {
                                numberAndStreet: '#173',
                                city: 'Meaford',
                                provinceState: 'ON',
                                postalZipCode: 'N4L 1W7',
                                latLng: '44.576549165135575, -80.58434798421271',
                            },
                        },
                    ],
                },
            ],
        },
        {
            type: CustomContentType.RequestInfo,
        },
    ],
    aboutus: [
        {
            type: CustomContentType.Splash,
            title: 'The Property LinQ Promise',
            body: "The Property LinQ makes it simple, seamless, and fun to find your next home in Southern Georgian Bay. From our convenient online house hunting tools to our innovative showcase experience centre, we've created a buyer-friendly process that will connect you with your dream home on your terms.",
            media: { galleryLink: 'tpl-whoweare-splash' },
        },
        {
            type: CustomContentType.Steps,
            title: 'How it works',
            elements: [
                {
                    title: 'Start your\n\nsearch online',
                    body: 'Begin by exploring a variety of new build homes online from our trusted development partners.',
                    media: { galleryLink: 'tpl-whoweare-steps', index: 0 },
                },
                {
                    title: 'Take a\n\nFuturistic Virtual Tour',
                    body: 'Use VR technology to virtually walk through your next home with custom layouts, finishes, and furnishings.',
                    media: { galleryLink: 'tpl-whoweare-steps', index: 1 },
                },
                {
                    title: 'Connect with\n\nYour Developer',
                    body: 'Our helpful sales staff will connect you with your preferred developer to help make your dream home a reality.',
                    media: { galleryLink: 'tpl-whoweare-steps', index: 2 },
                },
            ],
        },
        {
            type: CustomContentType.Items,
            title: 'Meet the partners',
            body: "We work with a team of industry-leading partners who care about matching you with the perfect home. With decades of experience in real estate and cutting-edge technology you won't find anywhere else, we've created a homebuying process that puts you at the centre of it all.",
            elements: [
                {
                    title: 'EDEV',
                    body: 'For more than 25 years, EDEV Inc. has been a trusted real estate development manager and investment partner in Toronto. With an extensive partner network that includes pension fund asset managers, private equity, and REITs, they have developed a wealth of experience across a variety of asset classes, including residential, commercial, and self-storage, with an increased focus on purpose-built and transit-oriented multi-family communities.',
                    logo: { galleryLink: 'tpl-whoweare-partners', index: 0 },
                    media: { galleryLink: 'tpl-whoweare-partners', index: 1 },
                    expandable: true,
                },
                {
                    title: 'Bosley Real Estate',
                    body: 'Bosley Real Estate is a fourth generation, Canadian, family-owned business operating in Ontario since 1928. With such great longevity comes knowledge, brand recognition, and an unparalleled level of consumer trust. Bosley’s experience covers all aspects of real estate spanning land assembly, urban, and rural development, appraisal, retail and commercial, residential sales, international relocation, and so much more. The highly trained team at Bosley Real Estate has our customers’ best interests in mind with a clear focus on a smooth, effective, and enjoyable sales experience.',
                    logo: { galleryLink: 'tpl-whoweare-partners', index: 2 },
                    media: { galleryLink: 'tpl-whoweare-partners', index: 3 },
                    expandable: true,
                },
                {
                    title: 'INVENT DEV',
                    body: 'INVENT DEV has been helping best-in-class builders sell homes using powerful visualization marketing and sales tools for over 10 years. INVENT DEV has won many awards, including the NAHB Global Innovation Award for the impact of its virtual model homes in helping builders sell projects without constructing a physical model home. INVENT DEV has been assisting builders pre-sell homes at higher prices on an international scale, using highly engaging marketing and sales tools.',
                    logo: { galleryLink: 'tpl-whoweare-partners', index: 4 },
                    media: { galleryLink: 'tpl-whoweare-partners', index: 5 },
                    expandable: true,
                },
            ],
            alt: true,
        },
        {
            type: CustomContentType.Blurb,
            title: 'Our business is built on relationships',
            body: 'We’ve partnered with some of Canada’s most trusted and experienced developers who are bringing beautiful new build homes to Southern Georgian Bay. These are builders who care about quality, integrity, and helping buyers find homes that transform their lives.',
            links: [
                {
                    label: 'Benefits of Being A Partner',
                    link: 'partnerships',
                },
            ],
            media: {
                galleryLink: 'tpl-whoweare-clients', index: 0, size: 8, position: 0,
            },
        },
        {
            type: CustomContentType.RequestInfo,
        },
    ],

    partnerships: [
        {
            type: CustomContentType.Splash,
            supertitle: 'INNOVATING THE WAY HOME™',
            title: "Showcase your homes in Georgian Bay's most innovative presentation centre",
            media: { galleryLink: 'tpl-partnerships-splash' },
            links: [{ label: 'Become A Partner', link: '#request' }],
            elements: [{
                type: CustomContentType.RequestInfo,
                title: 'Schedule Your Consultation',
                body: 'Our sales team will demonstrate how our process is\n\ndesigned to help you maximize your revenue, develop\n\nnew leads, and reduce costs.',
                data: {
                    message: ['I am interested in your partnerships program'],
                    tags: ['partnerships'],
                },
                fields: [
                    {
                        formFieldTypeId: FormFieldType.Text,
                        id: 'name',
                        label: 'Full Name',
                        required: true,
                    },
                    {
                        formFieldTypeId: FormFieldType.Text,
                        id: 'company',
                        label: 'Company',
                        required: true,
                    },
                    {
                        formFieldTypeId: FormFieldType.Email,
                        id: 'email',
                        label: 'Email',
                        required: true,
                    },
                    {
                        formFieldTypeId: FormFieldType.Phone,
                        id: 'phone',
                        label: 'Phone Number',
                        required: true,
                        inputProps: { phone: true },
                    },
                    /* {
                        id: 'comments',
                        label: 'Comments',
                        required: false,
                        inputProps: { textarea: true },
                    }, */
                ],
            }],
        },
        {
            type: CustomContentType.Features,
            elements: [
                {
                    title: 'All You Offer Under\n\nOne Roof',
                    body: 'As one of our trusted development partners\n\nyour customers can explore your communities\n\nand product online',
                    links: [{ label: 'Become A Partner', link: '#request', alt: true }],
                    media: { galleryLink: 'tpl-partnerships-features', index: 0 },
                },
                {
                    title: 'Expert\n\nSales Staff',
                    body: 'Our team has the training and tools to walk your\n\ncustomers through the their options on their\n\npreferred schedule',
                    links: [{ label: 'Contact Us', link: '#request', alt: true }],
                    media: { galleryLink: 'tpl-partnerships-features', index: 1 },
                },
                {
                    title: 'Cutting Edge\n\nTechnology',
                    body: 'An elevated experience with in-person\n\ntouchscreens and iPads, or virtually with VR and\n\nour HOME GYDE platform',
                    links: [{ label: 'Explore Home Gyde', link: 'developments', alt: true }],
                    media: { galleryLink: 'tpl-partnerships-features', index: 2 },
                },
            ],
        },
        {
            type: CustomContentType.Blurb,
            title: 'Schedule\n\nappointments\n\non your schedule',
            body: 'Take the pair out of appointment making and offer your clients the best possible experience with our convenient, easy-to-use online scheduling platform.',
            links: [{ label: 'Find Out How', link: '#request' }],
            alt: true,
            media: { galleryLink: 'tpl-partnerships-blurb', index: 0, size: 6, position: 1 },
        },
        {
            type: CustomContentType.Items,
            title: 'The Property LinQ™ Difference',
            body: "There's nothing quite like The Property LinQ™\n\nContact us to learn how we can help you sell more, for less",
            links: [{ label: 'Book A Consultation', link: '#request' }],
            elements: [
                {
                    title: 'Better returns on each\n\nhome development',
                    body: 'Maximize your ROI by upselling a\nwider variety of features and finishes\ndigitally using iPads touchscreens,\nand virtual reality technology.',
                    media: { galleryLink: 'tpl-partnerships-items', index: 0 },
                },
                {
                    title: 'Maximize your\n\nmarketing budget',
                    body: 'Dramatically reduce costs without\n the need for sales office, model\nhomes, extra staffing, scale models,\nmarketing materials, and more.',
                    media: { galleryLink: 'tpl-partnerships-items', index: 1 },
                },
                {
                    title: 'Integrated lead\n\ngeneration',
                    body: 'With new home sales, re-sales,\nleasing, and events all under one\nroof, you can take advantage of\ntremendous shared traffic\nopportunities.',
                    media: { galleryLink: 'tpl-partnerships-items', index: 2 },
                },
                {
                    title: 'A seamless\n\ncustomer experience',
                    body: 'With a space inspired by high-end\nretail stores and our expert\nconcierge staff, we provide an\nunforgettable buying experience.',
                    media: { galleryLink: 'tpl-partnerships-items', index: 3 },
                },
            ],
            alt: true,
        },
        {
            type: CustomContentType.Items,
            title: 'A collaboration of development and technology experts',
            align: 'center',
            elements: [
                { logo: { galleryLink: 'tpl-partnerships-collab', index: 0, link: 'https://www.edevinc.com/' } },
                { logo: { galleryLink: 'tpl-partnerships-collab', index: 1, link: 'https://www.pattymac.ca/' } },
                { logo: { galleryLink: 'tpl-partnerships-collab', index: 2, link: 'https://inventdev.com/' } },
                { logo: { galleryLink: 'tpl-partnerships-collab', index: 3, link: 'https://www.bosleyrealestate.com/' } },
            ],
        },
        {
            type: CustomContentType.RequestInfo,
        },
    ],
    contact: [
        {
            type: CustomContentType.Splash,
            title: "Interested in living in Georgian Bay,\n\nwe're here to help.",
            media: { galleryLink: 'tpl-contact-splash' },
            elements: [{
                type: CustomContentType.RequestInfo,
                title: 'Contact Us',
                body: 'Our team of experts want to help you find your dream home in one of Ontarios most insipiring areas. Fill out the form below to kickstart your new life in Georgian Bay.',
                fields: [
                    {
                        id: 'firstname',
                        label: 'First Name',
                        required: true,
                        size: 2,
                        formFieldTypeId: FormFieldType.Text,
                    },
                    {
                        id: 'lastname',
                        label: 'Last Name',
                        size: 2,
                        formFieldTypeId: FormFieldType.Text,
                    },
                    {
                        id: 'email',
                        label: 'Email',
                        required: true,
                        size: 2,
                        formFieldTypeId: FormFieldType.Email,
                    },
                    {
                        id: 'phone',
                        label: 'Phone Number',
                        required: true,
                        inputProps: { phone: true },
                        size: 2,
                        formFieldTypeId: FormFieldType.Phone,
                    },
                    {
                        id: 'iaminterestedin',
                        label: 'I am interested in',
                        required: false,
                        other: true,
                        select: 'I am interested in',
                        dropdown: ['The Gates of Meaford by Nortterra', 'The Summit by Primont Homes', 'General Inquiry'],
                        size: 2,
                    },
                    {
                        id: 'message',
                        label: 'Comments / Questions / Inquiries',
                        fullWidth: true,
                        required: false,
                        inputProps: { textarea: true },
                        formFieldTypeId: FormFieldType.Text,
                    },
                ],
                requestType: AnalyticsEvent.RequestInformation,
                formOptions: { maxColumns: 2 },
            }],
        },
        // {
        //     type: CustomContentType.Blurb,
        //     title: 'Sell more, for less',
        //     body: 'Get involved with the Property LinQ to showcase your homes in Georgian Bay. Contact us today to learn how we can help you sell more, for less.',

        // },
        {
            type: CustomContentType.Numbered,
            title: 'Changing the way to find your new home',
            elements: [
                {
                    title: 'All of Georgian Bay at your fingertips',
                    body: "Our one-stop-shop for finding your next home in one of Ontario's most inspiring areas. Be the first to access exclusive development projects with the help of our innovative search tools and established partner network.",
                    media: { galleryLink: 'tpl-landing-numbered', index: 0 },
                    links: [{
                        label: 'Browse Communities',
                        link: 'developments',
                        alt: true,
                    }],
                },

                {
                    title: 'Browse home exterior and interiors virtually',
                    body: 'From ground-breaking virtual tours with VR technology to design software that help you choose your floorplans and finishes, we are transforming the homebuying process from start to finish.',
                    media: { galleryLink: 'tpl-landing-numbered', index: 1 },
                    links: [{
                        label: 'How It Works',
                        link: 'aboutus',
                        alt: true,
                    }],
                },
                {
                    title: 'Simple and easy homebuying process',
                    body: "Our team of sales professionals have deep roots in Georgian Bay and can give you an insider's perspective on the area. That way, you can trust in our expertise and support throughout your journey.",
                    media: { galleryLink: 'tpl-landing-numbered', index: 2 },
                    links: [{
                        label: 'Request Info',
                        link: '#request',
                        alt: true,
                    }],
                },
            ],
        },
    ],
    'georgian-communities-lunch-learn-event': [
        {
            type: CustomContentType.Header,
            media: [{
                galleryLink: 'tpl-landing-events', index: 1
            }]
        },
        {
            type: CustomContentType.Event,
            title: 'Lunch and Learn Session',
            media: [{ galleryLink: 'tpl-landing-events', index: 2 }],
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    subtitle: 'Wednesday, March 20 11:30 am to 1:30 pm',
                }
            ],
            dividerAfter: true,
        },
        {
            type: CustomContentType.Form,
            body: "Join us for an exclusive Lunch and Learn session with Georgian Communities",
            subtitle: "Fields marked with a * are required",
            requestType: AnalyticsEvent.EventRegistration,
            redirect: '#thankyou-georgianlunchandlearn',
            formOptions: {
                submitMessage: 'Register',
                tags: ['georgianlunchandlearn'],
            },
            fields: [
                {
                    id: 'firstname',
                    label: 'FIRST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'lastname',
                    label: 'LAST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'email',
                    label: 'EMAIL',
                    required: true,
                    size: 2,
                },
                {
                    id: 'phone',
                    label: 'PHONE',
                    required: true,
                    inputProps: { phone: true },
                    size: 2,
                },
            ],
        },
    ],
    'thankyou-georgianlunchandlearn': [
        {
            type: CustomContentType.Header,
            media: [{
                galleryLink: 'tpl-landing-events', index: 1
            }]
        },
        {
            type: CustomContentType.Event,
            title: 'Lunch and Learn Session',
            media: [{ galleryLink: 'tpl-landing-events', index: 2 }],
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    subtitle: 'Wednesday, March 20 11:30 am to 1:30 pm',
                }
            ],
            dividerAfter: true,
        },
        {
            type: CustomContentType.Blurb,
            body: `Thank you for registering for the Georgian Communities Lunch and Learn Session!`,
            animated: false
        },
    ],
    'victoria-day-long-weekend': [
        {
            type: CustomContentType.Header,
            media: [{
                galleryLink: 'tpl-landing-events', index: 3
            }]
        },
        {
            type: CustomContentType.Event,
            title: 'Victoria Day Long Weekend Event',
            media: [{ galleryLink: 'tpl-landing-events', index: 3 }],
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    subtitle: 'May 18 + 19 from 12pm-3pm',
                }
            ],
            dividerAfter: true,
        },
        {
            type: CustomContentType.Form,
            body: "Register for our FREE event this Victoria Day long weekend! Enjoy a complimentary BBQ lunch and get VIP access to exclusive incentives when you purchase a new bungalow or bungaloft model.",
            subtitle: "Fields marked with a * are required",
            requestType: AnalyticsEvent.EventRegistration,
            redirect: '#thankyou-victoria-day-long-weekend',
            formOptions: {
                submitMessage: 'Register',
                tags: ['victoriadaylongweekend'],
            },
            fields: [
                {
                    id: 'firstname',
                    label: 'FIRST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'lastname',
                    label: 'LAST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'email',
                    label: 'EMAIL',
                    required: true,
                    size: 2,
                },
                {
                    id: 'phone',
                    label: 'PHONE',
                    required: true,
                    inputProps: { phone: true },
                    size: 2,
                },
            ],
        },
    ],
    'thankyou-victoria-day-long-weekend': [
        {
            type: CustomContentType.Header,
            media: [{
                galleryLink: 'tpl-landing-events', index: 3
            }]
        },
        {
            type: CustomContentType.Event,
            title: 'Victoria Day Long Weekend Event',
            media: [{ galleryLink: 'tpl-landing-events', index: 3 }],
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    subtitle: 'May 18 + 19 from 12pm-3pm',
                }
            ],
            dividerAfter: true,
        },
        {
            type: CustomContentType.Blurb,
            body: `Thank you for registering for the Victoria Day Long Weekend Event!`,
            animated: false
        },
    ],
    'footer': [
        {
            type: CustomContentType.Footer,
            title: 'COME VISIT US',
            subtitle: 'The Property LinQ\nGeorgian Bay Presentation Centre',
            elements: [
                {
                    title: 'Address',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    }
                },
                {
                    title: 'Hours',
                    body: "| | |\n| -- | -- |\n| Monday | 11 a.m. - 6 p.m. |\n| Tuesday | 11 a.m. - 6 p.m. |\n| Wednesday | By appointment only |\n| Thursday | By appointment only |\n| Friday | 11 a.m. - 6 p.m. |\n| Saturday | 11 a.m. - 6 p.m. |\n| Sunday | 11 a.m. - 6 p.m. |",
                }
            ]
        },
        {
            type: CustomContentType.Footer,
            title: 'FIND YOUR DREAM HOME',
            subtitle: 'Communities',
            links: [
                {
                    label: 'Browse Communities',
                    link: 'developments'
                },
                {
                    label: 'Request Info',
                    link: '#request'
                }
            ]
        },
        {
            type: CustomContentType.Footer,
            title: 'JOIN THE PROPERTY LINQ',
            subtitle: 'Partnerships',
            links: [
                {
                    label: 'About Us',
                    link: 'aboutus'
                },
                {
                    label: 'Partnerships',
                    link: 'partnerships'
                },
                {
                    label: 'Contact Us',
                    link: 'contact'
                }
            ]
        }
    ]
}

import React, { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
    useParams,
} from 'react-router-dom'
import {
    Button,
    Message,
} from 'components'
import {
    showPrompt,
    initializeDefault,
} from 'actions/appActions'
import {
    PromptOptions,
    ErrorMessage,
    PageType,
    CustomEventType,
    RootState,
} from 'app/types'
import { logger } from 'helpers/logger'
import {
    retrieveTemporaryToken,
    resetPassword,
} from 'actions/userActions'

export function PasswordResetView() {
    const { link } = useParams()
    const dispatch = useAppDispatch()
    const temporary = useAppSelector((state: RootState) => state.user.temporary)
    const error = useAppSelector((state: RootState) => state.user.error)
    const prompt = useAppSelector((state: RootState) => state.app.prompt)
    const initialized = useAppSelector((state: RootState) => state.app.initialized)
    const [success, setSuccess] = useState(0)

    useEffect(() => {
        const asyncRetrieve = async () => {
            const ret = await dispatch(retrieveTemporaryToken({ link, logOut: true }))
            if (ret.payload.success === 0) {
                throw new Error('Failed to retrieve temporary')
            }
        }
        asyncRetrieve().catch((e) => {
            logger.error(e)
            setSuccess(-1)
        })
        // dispatch(initializeDefault())
    }, [link])

    useEffect(() => {
        if (temporary != null) {
            showReset()
        }
    }, [temporary])

    function showReset(e, error) {
        dispatch(showPrompt({
            ...PromptOptions.ResetPasswordTemporary,
            underBar: true,
            error: error,
        }))
            .then((x) => {
                if (x.payload && Array.isArray(x.payload) && x.payload.length === 2) {
                    return Promise.all([
                        dispatch(showPrompt(PromptOptions.ResettingPassword)),
                        dispatch(resetPassword({ newPassword: x.payload[0], temporary: true })),
                    ])
                }
                throw x
            }).then((x) => {
                if (x[1] && x[1].payload) {
                    if (x[1].payload.success) {
                        setSuccess(1)
                    } else {
                        showReset(e, ErrorMessage.ResetError)
                    }
                }
            }).catch((e) => {
                // setSuccess(-1)
            })
    }

    function handleLogin() {
        window.dispatchEvent(new CustomEvent(
            CustomEventType.LogIn,
            { detail: { redirect: PageType.Home } },
        ))
    }

    return <div className="content alt">
        <div className="content-wrapper">
            {initialized === 2 && <React.Fragment>
                {!prompt && success !== 1 && <Message error={error} info="Use this page to reset your password">
                    {temporary != null && success != -1 && <Button tertiary large onClick={showReset}>Reset Password</Button>}
                </Message>}
                {!prompt && success === 1 && <Message error={error} info="Password successfully reset! Please log in">
                    <Button tertiary large onClick={handleLogin}>Log In</Button>
                </Message>}
            </React.Fragment>}
        </div>
    </div>
}


import React, { useEffect, useState, useRef } from 'react'
import { CustomEventType, PageType, PromptOptions, PromptType, UpgradeSpaceData, ThumbSize, MediaType, Visibility } from 'app/types'
import { useAppDispatch, useAppSelector, useIsVisible } from 'app/hooks'
import { Message, Button, BackButton, Spinner, Split, Media, Icon, IconButton, TourIFrame, SlideShow, CloseButton, FlipNumbers, Input } from 'components'
import { FloorplanPage } from 'views/FloorplanPage'
import * as fnc from 'helpers/fnc'
import { setFocusUnit } from 'actions/spinActions'
import { showPrompt } from 'actions/appActions'
import { selectionKey, getConfig, getOptionName } from './upgrades'
import { UpgradeProduct } from './UpgradeProduct'
import { UpgradeTour } from './UpgradeTour'
import { UpgradePackage } from './UpgradePackage'
import { getOptionString } from 'helpers/requestHandler'
import { Toggle } from 'components/Core/Toggle'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

const PRODUCT_COLLAPSE_LIMIT = 4

function orderSort(a, b) {
    return a.order - b.order
}

interface UpgradeOptionProps {
    app,
    option,
    optionElem,
    group,
    maps: Dict,
    onPackage: () => void,
    onPackageReset: () => void,
    onProduct: () => void,
    onVariation: () => void,
    onProductValue: () => void,
    clearEditOption: () => void,
    onInfo: () => void,
    selections: Dict,
    customizations: Dict,
    spec: Dict,
    onChange: () => void,
    showPricing: boolean,
    admin: boolean,
    depth: number,
    options: Dict,
    idx: number,
    isEdit: boolean,
    editPackage: Dict,
    editOption: Dict,
    editComponent: Dict,
    getSelectedProducts: () => void,
    pckge: Dict,
    selectOption: () => void,
    selectPackage: () => void,
    disableChanges: boolean,
}

export function UpgradeOption(props: UpgradeOptionProps) {
    const {
        app,
        className,
        group,
        option,
        optionElem,
        viewRef,
        maps,
        onPackage,
        onPackageReset,
        onProduct,
        onVariation,
        onProductValue,
        clearEditOption,
        onInfo,
        selections,
        customizations,
        spec,
        focus,
        style,
        mobileView,
        onChange,
        showPricing,
        admin,
        independent,
        depth,
        options,
        idx,
        isEdit,
        editPackage,
        editOption,
        editComponent,
        getSelectedProducts,
        pckge,
        selectOption,
        selectPackage,
        disableChanges,
    } = props

    const [variation, setVariation] = useState(null)
    const [groupFilter, setGroupFilter] = useState({})
    const readOnly = optionElem.visibilityId == Visibility.ReadOnly

    function handleVariation(x) {
        setVariation(x)
        if (onVariation) {
            onVariation(group, option, x, pckge)
        }
    }

    useEffect(() => {
        let newFilter = {}
        if (group.products.length > 0) {
            group.products.forEach((x) => {
                if (x.upgradeOptionId != option.id) {
                    return
                }
                newFilter[x.upgradeProductId || x.upgradePackageId] = 1
            })
        }
        setGroupFilter(newFilter)
    }, [])

    useEffect(() => {
        // Either the variation is set from the selections, or we set the default
        if (option.variations.length > 1) {
            let newVariation = null
            Object.keys(selections).forEach((x) => {
                if (newVariation != null) {
                    return
                }
                selections[x].forEach((y) => {
                    if ((y.group?.id == group.id || option.global) && y.option.id == option.id && !newVariation) {
                        newVariation = y.variation
                    }
                })
            })

            // Multi variation
            const resetVariation = () => {
                handleVariation(option.variations[0])
            }
            if (newVariation && newVariation.id != variation?.id) {
                setVariation(newVariation)
            } else if (!variation) {
                resetVariation()
            }

            window.addEventListener(CustomEventType.ClearSelection, resetVariation)
            return () => {
                window.removeEventListener(CustomEventType.ClearSelection, resetVariation)
            }
        } else {
            setVariation(option.variations[0])
        }
    }, [variation, selections])

    function clearCustomization() {
        if (!pckge) {
            return
        }
        const defaultProduct = pckge.products.find((x) => x.upgradeOptionId = option.id)
        onProduct(group, option, variation, variation.components[0], maps.product[defaultProduct.upgradeProductId])
    }


    function handleOrder(arr, elem, order) {
        let A = null
        let B = null
        // Fix ordering
        const tempArr = [...arr]
        tempArr.sort(orderSort).forEach((x, ix) => {
            x.order = ix
        })


        const targetProd = maps.product[elem.upgradeProductId]
        arr.forEach((x, ix) => {
            if (x == elem) {
                B = x
            } else if (x.order == order) {
                A = x
            }
        })
        if (A && B) {
            const temp = A.order
            A.order = B.order
            B.order = temp
        }
        onChange()
    }

    // Determine if anything configured
    let isConfigured = false
    let shouldDrill = false
    if (variation) {
        for (let i = 0; i < variation.components.length; i += 1) {
            if (option.collapse) {//variation.components[i].products.length > PRODUCT_COLLAPSE_LIMIT) {
                shouldDrill = true
                isConfigured = true
                break
            }
            if (variation.components[i].products.length > 0) {
                isConfigured = true
                break
            }
        }
    }
    if (shouldDrill && disableChanges && variation.components.length == 1) {
        shouldDrill = false
    }

    let selector = null
    if (option.variations.length > 1) {
        if (readOnly && variation) {
            // selector = <Button className="selected">{variation.name}</Button>
            selector = <Toggle items={[{ value: variation, text: variation.name }]} value={variation} />
        } else {
            selector = <Toggle items={option.variations.map((x) => ({ value: x, text: x.name }))} value={variation} onChange={handleVariation} />
        }
    }

    let optionName = getOptionName(maps, pckge, option)
    if (option.id in maps.variationOptions && maps.variationOptions[option.id].name) {
        optionName = maps.variationOptions[option.id].name
    }

    if (depth == 0 && shouldDrill) {
        return <div className="row upgrade-option drillable" key={option.id} data-id={option.id}>
            <div className="option-title">
                <span>{`${optionName.capitalize()}${!isConfigured ? ' (Not Configured)' : ''}`}</span>
                {onChange && options && <div className="top-right order-wrapper">
                    {idx > 0 && <IconButton className="order-button" icon="fas fa-chevron-up" onClick={() => handleOrder(options, optionElem, idx - 1)} />}
                    {idx < options.length - 1 && <IconButton className="order-button" icon="fas fa-chevron-down" onClick={() => handleOrder(options, optionElem, idx + 1)} />}
                </div>}
            </div>
            {option.areas.length > 0 && <div className="row option-areas"><span>Areas: {option.areas.map((x) => maps.area[x]?.name).join(', ')}</span></div>}
            {selector}
            {option.variations.length > 1 && (() => {
                let customized = false
                if (pckge && variation) {
                    customized = variation.components.find((y) => {
                        const key = selectionKey(group, option, variation, y)
                        return pckge.id in customizations && key in customizations[pckge.id]
                    })
                }

                let media = null
                const sortedComponents = variation.components.sort((a, b) => a.order - b.order)
                for (let i = 0; i < sortedComponents.length; i += 1) {
                    const key = selectionKey(group, option, variation, sortedComponents[i])
                    if (key in selections) {
                        const { product } = selections[key][0]
                        if (product && product.media.length > 0) {
                            media = product.media[0]
                            break
                        }
                    }
                }

                return <div className="row upgrade-component">
                    <div className={`upgrade-product drillable${customized ? ' customized' : ''}`} onClick={() => selectOption(option, null)}>
                        <div className="row upgrade-product-info">
                            <div className="upgrade-product-wrapper clickable">
                                {media && <div className="upgrade-product-media">
                                    <Media thumb={true} thumbSize={128} fadeIn app={app} mediaId={media} />
                                </div>}
                                <div className="upgrade-product-details">
                                    <span>{variation ? `${variation.name} Configuration` : `Configure ${optionName}`}</span>
                                </div>
                            </div>
                        </div>
                        <div className="arrow-row" onClick={null/*() => selectOption(option, component)*/}>
                            <Button icon="fas fa-chevron-right">Customize</Button>
                        </div>
                    </div>
                </div>
            })()}
            {variation && option.variations.length == 1 && variation.components.map((y, iy) => {
                const selectedProducts = getSelectedProducts(option, variation, y)

                let productElem = null
                let product = null
                if (Object.keys(selectedProducts).length > 0) {
                    product = maps.product[Object.keys(selectedProducts)[0]]
                }

                // If is package option, show
                let customized = false
                let packageDefault = false
                if (pckge && product) {
                    const key = selectionKey(group, option, variation, y)
                    customized = pckge.id in customizations && key in customizations[pckge.id]
                    packageDefault = pckge.products.find((x) => x.upgradeProductId == product.id) != null
                }

                productElem = <UpgradeProduct
                    key={product?.id}
                    app={app}
                    maps={maps}
                    spec={{ ...spec, option, optionVariation: variation, component: y, product, pckge: editPackage }}
                    group={group}
                    selection={selectedProducts}
                    customized={customized}
                    packageDefault={packageDefault}
                    drillable={true}
                    handleOrder={handleOrder}
                    selectOption={selectOption}
                    onChange={onChange}
                    onProduct={onProduct}
                    onProductValue={onProductValue}
                    onInfo={onInfo}
                    showPricing={showPricing}
                    admin={admin}
                    disableChanges={disableChanges} />

                return <div className="row upgrade-component" key={y.id} data-id={y.id}>
                    {variation.components.length > 1 && <div className="component-title"><span>{y.name}</span></div>}
                    {productElem}
                </div>
            })}
        </div>
    }

    // Short circuit rendering if disabled and not selected
    if (disableChanges && variation) {
        let selected = variation.components.find((x) => {
            const key = selectionKey(group, option, variation, x)
            return key in selections
        })
        if (!selected) {
            return null
        }
    }

    let filtered = null
    if (pckge && group
        && group.id in maps.groupPackageOptionFilter
        && pckge.id in maps.groupPackageOptionFilter[group.id]
        && option.id in maps.optionInGroup) {
        const groups = Object.keys(maps.optionInGroup[option.id]).map((x) => maps.optionGroups[x].name)
        if (groups.length > 1) {
            filtered = groups.join(', ').replace(/,([^,]*)$/, ' and$1')
        }
    }

    return <div className={`row upgrade-option ${!isConfigured ? ' not-configured' : ''}`} key={option.id} data-id={option.id}>
        <div className={`option-title${depth > 0 ? ' nested' : ''}`} onClick={depth > 0 ? clearEditOption : null}>
            {depth > 0 && <BackButton onClick={clearEditOption} />}
            <span>{`${editPackage && editOption && depth > 0 && !optionName.includes('Package') ? `${editPackage.name} - ` : ''}${optionName.capitalize()}${!isConfigured ? ' (Not Configured)' : ''}`}</span>
            {onChange && options && <div className="top-right order-wrapper">
                {idx > 0 && <IconButton className="order-button" icon="fas fa-chevron-up" onClick={() => handleOrder(options, optionElem, idx - 1)} />}
                {idx < options.length - 1 && <IconButton className="order-button" icon="fas fa-chevron-down" onClick={() => handleOrder(options, optionElem, idx + 1)} />}
            </div>}
        </div>
        {option.areas.length > 0 && <div className="row option-areas"><span>Areas: {option.areas.map((x) => maps.area[x]?.name).join(', ')}</span></div>}
        {depth == 0 && selector}
        {filtered && <span style={{ padding: '0 10px' }}><ReactMarkdown>{`Please Note: Changing this option will affect the ${filtered}`}</ReactMarkdown></span>}
        {isConfigured && variation && [...variation.components].sort(orderSort).map((component, iy) => {
            if (isEdit && editOption && editComponent && component.id != editComponent.id) {
                return null
            }

            const selectedProducts = getSelectedProducts(option, variation, component)
            // if (y.products.length == 0) {
            // return null
            // }

            // Check if product has been customized
            let optionCustomization = false
            if (pckge) {
                const key = selectionKey(group, option, variation, component)
                optionCustomization = pckge.id in customizations && key in customizations[pckge.id] ? customizations[pckge.id][key] : false
            }


            // Fix to get up to date edit data
            const o = maps.option[option.id]
            const v = o.variations.find((x) => x.id == variation.id)
            const c = v.components.find((x) => x.id == component.id)
            const y = c

            return <div className="row upgrade-component" key={y.id} data-id={y.id}>
                {variation.components.length > 1 && <div className="component-title">
                    <span>{y.name}</span>
                    {onChange && <div className="top-right order-wrapper">
                        {iy > 0 && <IconButton className="order-button" icon="fas fa-chevron-up" onClick={() => handleOrder(variation.components, y, iy - 1)} />}
                        {iy < variation.components.length - 1 && <IconButton className="order-button" icon="fas fa-chevron-down" onClick={() => handleOrder(variation.components, y, iy + 1)} />}
                    </div>}
                </div>}
                {/* {customized && <Button secondary onClick={clearCustomization}>Clear Customization</Button>} */}
                {[...y.products].sort(orderSort).map((z, iz) => {
                    if (z.upgradePackageId != null) {
                        if (Object.keys(groupFilter).length > 0 && !(z.upgradePackageId in groupFilter)) {
                            return null
                        }
                        const pckge = maps.package[z.upgradePackageId]
                        if (pckge.adminOnly && !admin) {
                            return null
                        }

                        // Filter out certain packages

                        return <UpgradePackage
                            key={pckge.id}
                            app={app}
                            group={group}
                            maps={maps}
                            ix={iz}
                            // selection={selectedProducts}
                            orderPackage={z}
                            orderPackages={y.products}
                            handleOrder={handleOrder}
                            pckge={pckge}
                            selectOption={selectPackage}
                            selection={selections}
                            customizations={customizations}
                            onChange={onChange}
                            onPackage={onPackage}
                            onPackageReset={onPackageReset}
                            onProduct={onProduct}
                            onProductValue={onProductValue}
                            onInfo={onInfo}
                            showPricing={showPricing}
                            admin={admin}
                            disableChanges={disableChanges}
                            readOnly={readOnly}

                            spec={{ ...spec, option, optionVariation: variation, component: y, pckge: pckge }} />

                    } else {
                        if (Object.keys(groupFilter).length > 0 && !(z.upgradeProductId in groupFilter)) {
                            return null
                        }
                        const product = maps.product[z.upgradeProductId]
                        const dependent = y.dependencyUpgradeComponentId
                        let packageDefault = false
                        let productCustomized = false
                        if (pckge) {
                            packageDefault = pckge.products.find((x) => x.upgradeProductId == product.id) != null
                            productCustomized = optionCustomization && product.id == optionCustomization
                        }
                        return <UpgradeProduct
                            key={product.id}
                            app={app}
                            maps={maps}
                            spec={{ ...spec, pckge: editPackage, option, optionVariation: variation, component: y, product }}
                            group={group}
                            selection={selectedProducts}
                            customized={productCustomized}
                            packageDefault={packageDefault}
                            drillable={false}
                            ix={iz}
                            orderProduct={z}
                            orderProducts={y.products}
                            handleOrder={handleOrder}
                            selectOption={selectOption}
                            onChange={onChange}
                            onProduct={onProduct}
                            onProductValue={onProductValue}
                            onInfo={onInfo}
                            showPricing={showPricing}
                            admin={admin}
                            disableChanges={disableChanges}
                            readOnly={readOnly || dependent} />
                    }

                })}
            </div>
        })}
    </div>
}
import React, { useEffect, useRef, useState } from 'react'
import { ProjectFeatures } from 'views/HomePage/ProjectFeatures'
import { ShareButton, Media, Icon, WaterMark } from 'components'
import { CustomEventType, PageType, ScreenOrientation, ScreenSize } from 'app/types'
import { navigateAsync } from 'actions/appActions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { getAppPath } from 'helpers/config'
import * as fnc from 'helpers/fnc'
import { icons } from 'app/constants'

interface SideNavBarProps {
    app: AppData,
    apps: AppData[],
    selected: string,
    onlyFavourites: boolean,
    topOptions: JSX.Element
    bottomOptions: JSX.Element,
    forwardRef: Ref.Object,
    isScreenSaver: boolean,
}

export function SideNavBar(props: SideNavBarProps) {
    const { app, apps, selected, onlyFavourites, topOptions, bottomOptions, forwardRef, isScreenSaver } = props
    const compareCommunities = useAppSelector((state: RootState) => state.app.compareCommunities)
    const config = useAppSelector((state: RootState) => state.app.config)
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const links = useAppSelector((state: RootState) => state.app.links)
    const media = useAppSelector((state: RootState) => state.app.media)
    const dispatch = useAppDispatch()

    const wrapperRef = useRef()
    const navRef = useRef()
    const transitionTimeout = useRef()
    const arrowTimeout = useRef()
    const featuresRef = useRef()
    const endOptionsRef = useRef()
    const startOptionsRef = useRef()

    // const [transitionTime, setTransitionTime] = useState(isScreenSaver ? 1000 : 250)
    const [transitionTime, setTransitionTime] = useState(isScreenSaver ? 1000 : 250)
    const [showArrow, setShowArrow] = useState(false)
    const [expanded, setExpanded] = useState(isScreenSaver)
    const [animating, setAnimating] = useState(false)
    const [navCollapsed, setNavCollapsed] = useState(true)
    const [updateKey, setUpdateKey] = useState('') // Dummy key for force refresh
    const [featuresWidth, setFeaturesWidth] = useState(0)
    const minimalLogo = screen.isMobile && !expanded //&& (screen.size > ScreenSize.Desktop || screen.orientation == ScreenOrientation.Portrait)
    const smallScreen = !screen.isMobile && window.innerHeight < 900
    const [collapsible, setCollapsible] = useState(screen.isMobile)
    const [shake, setShake] = useState(null)

    const showArrowStyle = {}
    if (wrapperRef.current) {
        showArrowStyle.position = 'absolute'
        showArrowStyle.right = wrapperRef.current.clientWidth + 80
        showArrowStyle.top = wrapperRef.current.clientHeight - 50 
    }

    // Ensure slow transition when leaving isScreenSaver
    useEffect(() => {
        clearTimeout(transitionTimeout.current)
        clearTimeout(arrowTimeout.current)
        if (expanded != isScreenSaver) {
            setAnimating(true)
        }
        setExpanded(isScreenSaver == true)
        // setTimeout(() => {
        // setAnimating(true)
        if (!isScreenSaver) {
            transitionTimeout.current = setTimeout(() => {
                setTransitionTime(250)
                setAnimating(false)
            }, 1000)
        } else {
            // Update constantly until animation over
            setTransitionTime(1000)
            transitionTimeout.current = setTimeout(() => {
                setAnimating(false)
            }, 1000)
        }

        if (isScreenSaver && !showArrow) {
            arrowTimeout.current = setTimeout(() => {
                setShowArrow(true)
            }, 2000)
        } else {
            setShowArrow(isScreenSaver)
        }
        // }, 0)

        return () => {
            clearTimeout(transitionTimeout.current)
            clearTimeout(arrowTimeout.current)
        }
    }, [isScreenSaver])

    useEffect(() => {
        window.addEventListener(CustomEventType.FocusMenu, shakeMenu)
        return () => {
            window.removeEventListener(CustomEventType.FocusMenu, shakeMenu)
        }
    })

    useEffect(() => {
        if (!featuresRef.current || featuresWidth > 0) {
            return
        }
        const children = featuresRef.current.childNodes
        let maxWidth = 0
        for (let i = 0; i < children.length; i += 1) {
            const width = children[i].clientWidth
            maxWidth = Math.max(width, maxWidth)
        }
        setFeaturesWidth(Math.max(maxWidth, featuresWidth))

        // Determine if we've run out of space (navBarRef)
        let collapsibleTimeout = null
        if (!screen.isMobile) {
            collapsibleTimeout = setTimeout(() => {
                checkCollapsible()
            }, animating ? 1000 : 0)
        }
        return () => {
            clearTimeout(collapsibleTimeout)
        }
    }, [featuresRef.current, isScreenSaver, collapsible, expanded, screen])

    function checkCollapsible() {
        if (!isScreenSaver && !collapsible && !screen.isMobile && navRef.current && navRef.current.parentNode.offsetHeight > 0) {
            let limit = navRef.current.parentNode.offsetHeight
            if (startOptionsRef.current) {
                limit -= startOptionsRef.current.offsetHeight
            }
            if (endOptionsRef.current) {
                limit -= endOptionsRef.current.offsetHeight
            }
            setCollapsible(featuresRef.current.offsetHeight > limit)
        }
    }

    function handleApp() {
        dispatch(navigateAsync({ app }))
    }

    function handleForwardRef(x) {
        if (forwardRef) {
            if (typeof forwardRef == 'function') {
                forwardRef(x)
            } else {
                forwardRef.current = x
            }
        }
        navRef.current = x
    }

    function getAppLogo() {
        if (!app) {
            return
        }
        let appMedia = null
        let minimal = false
        if (minimalLogo && navCollapsed && (app.meta.logoSidebarMediaId || app.meta.logoSidebarSmallMediaId)) {
            appMedia = app.meta.logoSidebarSmallMediaId ? app.meta.logoSidebarSmallMediaId : app.meta.logoSidebarMediaId
            minimal = true
        } else if  (app.meta.logoSidebarMediaId) {
            appMedia = app.meta.logoSidebarMediaId
        } else {
            appMedia = app.meta.logoMediaId
        }

        // Calculate aspect ratio
        const logoMedia = media[appMedia]
        if (logoMedia) {
            const style = { paddingBottom: `${100 * logoMedia.height / logoMedia.width}%` }
            return <div className={`app-logo fadeIn${minimal ? ' minimal' : ''}`} style={style} onClick={handleApp}><Media app={app} mediaId={appMedia} fadeIn/></div>
        }
        return null
    }

    function shakeMenu(e) {
        if (e.detail) {
            setShake(e.detail.split("#")[0])
        } else {
            setShake('all')
        }
        setTimeout(() => {
            setShake(null)
        }, 500)
    }

    let style = { opacity: 0, height: expanded ? 'auto' : '100%' }

    // Enables slide in behaviour
    if (!expanded) {
        style.transform = 'translateX(100%)'
    }

    if (navRef.current && wrapperRef.current) {
        const newHeight = expanded ? (screen.isMobile ? 'auto' : `${navRef.current.clientHeight}px`) : (animating ? `${wrapperRef.current.parentNode.clientHeight}px` : '100%')
        style = {
            opacity: 1,
            height: newHeight,
            transitionDuration: `${expanded ? 1000 : transitionTime}ms`,
            overflow: showArrow ? 'visible' : 'hidden',
        }
    }
    // Determine screensaver scale manually
    let wrap = false
    if (isScreenSaver) {
        if (screen.isMobile) {
            style.transform = screen.size == ScreenSize.Mobile ? 'scale(1)' : 'scale(1.3)'
            if (screen.size == ScreenSize.Mobile && screen.orientation == ScreenOrientation.Landscape) {
                style.transform = 'scale(0.8)'
                wrap = true
            }
        } else if (smallScreen) {
            style.transform = 'scale(1)'
        }
    }


    // Determine if there is any shareable media
    let floorplan = null
    let variation = null
    if (links.pageLink == PageType.Floorplan && links.dataLink != null) {
        floorplan = app.floorplans.find((x) => x.link == links.dataLink)
        if (floorplan && floorplan.variations.length > 0) {
            variation = floorplan.variations[0]
        }
    }

    // animate__animated ${animation} animate__faster`
    return <React.Fragment>
        <div className={`column nav-column${animating ? ' animating' : ''}${expanded ? ' expanded' : ''}${!navCollapsed ? ' uncollapsed' : ''}${wrap ? ' wrap' : ''}`} ref={wrapperRef} style={style} key={screen.isMobile ? expanded : 0}>
            <div className="side-nav-bar" ref={handleForwardRef}>
                {(topOptions || (app && getAppLogo())) && (true || !collapsible || navCollapsed) && <div className={`side-nav-options ${!apps ? ' logo-wrapper' : ''}`} style={{}/*{ display: !topOptions && screen.isMobile && expanded ? 'none' : 'inherit' }*/} ref={startOptionsRef}>
                    {topOptions}
                    {!apps && getAppLogo()}
                </div>}
                {!onlyFavourites && (!apps || compareCommunities) && <div className={`project-feature-bar ${!navCollapsed ? ' scrollable show-scroll' : ''}`} style={(!collapsible || !screen.isMobile) ? { minWidth: `${featuresWidth}px` } : null} ref={featuresRef}>
                    <ProjectFeatures app={!apps ? app : null} apps={apps} selected={selected} expanded={expanded} shake={shake} collapsible={collapsible} onResize={() => setUpdateKey(Date.now())} onCollapse={setNavCollapsed}>
                    </ProjectFeatures>
                </div>}
                {(navCollapsed || (screen.isMobile && screen.orientation == ScreenOrientation.Portrait)) && <div className="side-nav-options end fadeIn" style={{ transitionDuration: expanded ? '200ms' : '1000ms', transitionDelay: expanded ? '0ms' : '1000ms' }} ref={endOptionsRef}>
                    {bottomOptions}
                    {/* {!expanded && <ShareButton app={!apps ? app : null} floorplan={floorplan} variation={variation} text="Share" />} */}
                    {/* <WaterMark notext={minimalLogo} /> */}
                </div>}
            </div>
        </div>
        {expanded && showArrow && <div className="tap-to-begin animate__animated animate__fadeInUp noselect" style={showArrowStyle} onClick={shakeMenu} ><span>Select an option</span><Icon noBg icon={icons.chevronRight} className="bounce" /></div>}
    </React.Fragment>
}

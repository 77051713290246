import React, { useEffect, useState } from 'react'
import { Dict } from 'app/types'
import { Button, Icon, IconButton } from 'components'
import { icons } from 'app/constants'

interface ToggleProps {
    id?: string,
    items: {
        icon?: string,
        text?: string
        value?: string
    }[],
    value: string,
    className?: string,
    large?: boolean,
    style?: Dict,
    onChange: () => void,
    toggleOff: boolean,
    arrowFocus: boolean,
    selectedClass: string,
}

export function Toggle(props: ToggleProps) {
    const {
        items,
        className,
        large,
        onChange,
        value,
        toggleOff,
        arrowFocus,
        selectedClass,
        ...buttonProps
    } = props

    const attrs = `${large ? ' button-large' : ''}${className ? ` ${className}` : ''}`
    const [selectedElem, setSelectedElem] = useState(null)
    const [toggleElem, setToggleElem] = useState(null)

    function handleSelect(x) {
        if (onChange) {
            if (x.value == value && toggleOff) {
                onChange(null)
            } else {
                onChange(x.value)
            }
        }
    }


    function getItem(x, ix) {
        const itemClass = `${value == x.value ? 'selected' : ''}`
        const refSelector = value == x.value ? setSelectedElem : null

        if (x.text) {
            return <Button key={ix} forwardRef={refSelector} className={itemClass} onClick={(e) => { e.stopPropagation(); handleSelect(x) }} icon={x.icon} counter={x.counter}>{x.text}</Button>
        } else if (x.icon) {
            return <IconButton key={ix} className={itemClass} onClick={(e) => { e.stopPropagation(); handleSelect(x) }} icon={x.icon} counter={x.counter} />
        }
    }
    if (!items) {
        return null
    }

    let arrowElem = null
    if(arrowFocus && selectedElem && toggleElem) {
        const selectedBounds = selectedElem.getBoundingClientRect()
        const parentBounds = toggleElem.getBoundingClientRect()
        const relativeBounds = {
            top: selectedBounds.top - parentBounds.top + 10,
            left: selectedBounds.left - parentBounds.left,
            width: selectedBounds.width,
            height: selectedBounds.height,
        }

        // Get local bounds
        const arrowFocusStyle = {
            position: 'absolute',
            left: relativeBounds.left + relativeBounds.width / 2,
            top: relativeBounds.top + relativeBounds.height,
            width:0,
            pointerEvents: 'none',
        }
        arrowElem = <IconButton noBg icon={icons.chevronUp} style={arrowFocusStyle} className="bounce-vertical"/>
    }

    return (
        <div ref={setToggleElem} className={`toggle ${attrs}`} {...buttonProps}>
            {items.map(getItem)}
            {arrowElem}
        </div>
    )
}

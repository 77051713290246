import React from 'react'
import { useAppSelector } from 'app/hooks'
import { Button, BackButton } from 'components'

interface AdminTrayProps {
    message: string,
    onBack:() => void
    onSave:() => void,
}

export function AdminTray(props: AdminTrayProps) {
    const { message, onBack, onSave, onClick } = { message: 'Save Changes', ...props }
    const changePending = useAppSelector((state:RootState) => state.admin.changePending)
    const savePending = useAppSelector((state:RootState) => state.admin.savePending)

    return <div id="admin-topbar">
        {onBack && <BackButton fixed alt onClick={onBack}/>}
        {changePending && !savePending
            && <Button
                tertiary
                className="save-changes animate__animated animate__fadeInRight animate__fastest" 
                onClick={onSave}>
                {message}
            </Button>}
        {props.children}
    </div>
}
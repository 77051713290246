import React, { useState, useEffect } from 'react'
import { Icon } from 'components'
import { icons } from 'app/constants'

interface TimerProps {
    duration: number,
    loop?: boolean,
    index?: boolean,
    onComplete: () => void,
}

const FULL_DASH_ARRAY = 283
const UPDATE_INTERVAL = 100
export function Timer(props: TimerProps) {
    const { duration, index, loop, onComplete } = props
    const [circleDashArray, setCircleDashArray] = useState(FULL_DASH_ARRAY)
    const [loopCount, setLoopCount] = useState(0)
    const [paused, setPaused] = useState(false)
    const [timerInterval, setTimerInterval] = useState(null)
    const [refreshKey, setRefreshKey] = useState(0)

    function calculateTimeFraction(timeLeft) {
        const rawTimeFraction = timeLeft / duration
        return rawTimeFraction - (1 / duration) * (1 - rawTimeFraction)
    }

    function setCircle(timeLeft) {
        const newCircleDashArray = `${(
            calculateTimeFraction(timeLeft) * FULL_DASH_ARRAY
        ).toFixed(0)} 283`
        setCircleDashArray(newCircleDashArray)
    }

    function handlePause() {
        // Resume
        if (paused) {
            setLoopCount(loopCount + 1)
        } else {
            // Pause
            clearInterval(timerInterval)
            setCircle(duration)
        }
        setPaused(!paused)
    }

    useEffect(() => {
        if (paused) {
            return
        }
        let timePassed = loopCount > 0 ? UPDATE_INTERVAL : 0
        // Loop animation compensation
        setCircle(duration)
        // if (loopCount > 0) {
        setTimeout(() => {
            setCircle(duration - timePassed)
        }, 0)
        // }
        let newTimerInterval = setInterval(() => {
            if (paused) {
                return
            }
            timePassed += UPDATE_INTERVAL
            const timeLeft = duration - timePassed
            setCircle(timeLeft)

            if (timeLeft <= 0) {
                clearInterval(newTimerInterval)

                setTimeout(() => {
                    if (onComplete) {
                        onComplete()
                    }
                    if (loop) {
                        setCircle(duration)
                        setLoopCount(loopCount + 1)
                    }
                }, UPDATE_INTERVAL)
            }
        }, UPDATE_INTERVAL)
        setTimerInterval(newTimerInterval)
        return () => {
            if (newTimerInterval) {
                clearInterval(newTimerInterval)
            }
        }
    }, [loopCount, index])

    useEffect(() => {
        if (!paused) {
            setRefreshKey(refreshKey + 1)
        }
    }, [index])

    return <div className="base-timer fadeIn" key={refreshKey} onClick={handlePause}>
        {paused && <Icon icon={icons.pause} noBg className="fadeIn" />}
        <svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g className="base-timer__circle">
                {/* <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle> */}
                <path
                    id="base-timer-path-remaining"
                    strokeDasharray={circleDashArray}
                    className="base-timer__path-remaining"
                    d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
              "
                ></path>
            </g>
        </svg>
    </div>
}
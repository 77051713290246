import React, { useEffect, useState } from 'react'
import {
    Spinner,
    BackButton,
} from 'components'
import { RootState, ScreenOrientation, ScreenSize } from 'app/types'
import PinchZoomPan from 'components/PinchZoomPan/PinchZoomPan'
import { useAppSelector } from 'app/hooks'

interface ContentViewProps {
    id: string,
    className: string,
    splitIndex: number,
}

export function ContentView(props: ContentViewProps) {
    const {
        id,
        className,
        splitIndex,
    } = props

    const [contentRef, setContentRef] = useState(null)
    const [style, setStyle] = useState({})

    function handleResize() {
        const newStyle = {}
        return
        // The following code will center to screen, rather than div

        // Determine margin to center in screen
        const bounding = contentRef.getBoundingClientRect()
        const parentBounding = contentRef.parentNode.getBoundingClientRect()
        if (bounding.width == parentBounding.width) {
            setStyle(newStyle)
            return
        }

        // Find dif with window width 
        const documentDif = document.body.clientWidth - bounding.width
        if (documentDif > 0) {
            if ((documentDif / 2) + bounding.width + 100 < parentBounding.width) {
                newStyle.marginLeft = `${documentDif / 2}px`
            } else {
                newStyle.marginRight = 0
            }
        }
        setStyle(newStyle)
    }

    useEffect(() => {
        if (!contentRef) {
            return
        }
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [contentRef])


    return <div id={id} className={`content-view animate__animated animate__fadeIn${className ? ` ${className}` : ''}`} style={style} ref={setContentRef}>
        {props.children}
    </div>
}
import React, { useEffect, useState, useRef } from 'react'
import { PageType, PromptOptions, PromptType, UpgradeSpaceData } from 'app/types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Message, Button, BackButton, Spinner, Split, Media, Icon, IconButton, TourIFrame, SlideShow, CloseButton } from 'components'
import { FloorplanPage } from 'views/FloorplanPage'
import * as fnc from 'helpers/fnc'
import { setFocusUnit } from 'actions/spinActions'
import { showPrompt } from 'actions/appActions'

function FlipNumber(props) {
    const [animation, setAnimation] = useState(null)
    const [character, setCharacter] = useState(null)
    const animationTimeout = useRef()


    useEffect(() => {
        if (animation != null) {
            setAnimation('animate__animated animate__fadeOutUp animate__fastest')
            clearTimeout(animationTimeout.current)
            animationTimeout.current = setTimeout(() => {
                setCharacter(props.character)
                setAnimation('animate__animated animate__fadeInUp animate__fastest')
            }, 100)
        } else {
            setAnimation('animate__animated animate__fadeInUp animate__fastest')
            setCharacter(props.character)
        }
    }, [props.character])

    if (!character) {
        return null
    }
    return <span className={animation}>{character}</span>
}

export function FlipNumbers(props) {
    const money = fnc.toMoney(props.value)
    const characters = money.split('')

    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }
    return <div className="flip-number" style={props.style}>
        {characters.map((x, ix) => {
            const key = isNumeric(x) ? characters.length - 1 - ix : x
            return <FlipNumber key={`${key}-${ix}`} character={x} />
        })}
    </div>
}
import { MediaData } from 'app/types'
import React, { useEffect, useState, useRef } from 'react'
import { getMediaLinkSelector } from 'app/hooks'
import * as fnc from 'helpers/fnc'
import { Media } from 'components'
import { time } from 'console'

interface Photo {
    image: HTMLImageElement
    x: number
    y: number
    angle: number
}


interface UpgradeCollageMediaProps {
    media: MediaData,
    ix: number,
    ixB: number,
    total: number,
    bounds: DOMRect,
    app: AppData
}

function UpgradeCollageMedia(props: UpgradeCollageMediaProps) {
    const { media, ix, ixB, total, bounds, app } = props
    const [loaded, setLoaded] = useState(false)
    const [style, setStyle] = useState({})

    function handleLoad() {
        setLoaded(true)
    }

    useEffect(() => {
        // Create the dynamic animation style
        let newStyle = {}
        if (!loaded) {
            newStyle.opacity = 0
        } else {
            // Create an animation for this media to slide in from the edge of the screen
            const getRandomValue = (min, max) => Math.random() * (max - min) + min

            // Generate random values for starting position, angle, and margin
            const startPositionX = getRandomValue(0, bounds.width)
            const startPositionY = getRandomValue(0, bounds.height)
            const startAngle = getRandomValue(-15, 15)
            const endAngle = getRandomValue(-15, 15)
            const spreadFactor = 180 / total// Adjust for even spread
            const endPositionX = bounds.width / 2 + Math.cos((ix * spreadFactor) * (Math.PI / 180)) * bounds.width / 3
            const endPositionY = bounds.height / 2 + Math.cos((ixB * spreadFactor) * (Math.PI / 180)) * bounds.height / 3
            const marginX = getRandomValue(-1, 1) * bounds.width * 0.05
            const marginY = getRandomValue(-1, 1) * bounds.height * 0.05
            const size = Math.sqrt((bounds.height * bounds.width) / total) * getRandomValue(0.8, 1.2) * 0.8

            newStyle = {
                position: 'absolute',
                top: `${startPositionY}px`,
                left: `${startPositionX}px`,
                transform: `translate(-50%, -50%) rotate(${startAngle}deg)`,
                opacity: 1,
                width: `${size}px`,
                height: `${size}px`,
                '--start-x': `${startPositionX}px`,
                '--start-y': `${startPositionY}px`,
                '--end-x': `${endPositionX + marginX}px`,
                '--end-y': `${endPositionY + marginY}px`,
                '--start-angle': `${startAngle}deg`,
                '--end-angle': `${endAngle}deg`,
                animation: 'collage-slide 1s forwards',
            }
        }
        setStyle(newStyle)
    }, [loaded])

    return <Media key={media.id} mediaId={media.id} app={app} style={style} onLoad={handleLoad} fadeIn />
}

interface UpgradeCollageProps {
    media: MediaData[]
    app: AppData
}

export function UpgradeCollage(props: UpgradeCollageProps) {
    const { media, app } = props
    const getImage = getMediaLinkSelector()
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const [collageRef, setCollageRef] = useState<HTMLDivElement | null>(null)
    const [mode, setMode] = useState(1)
    const [elem, setElem] = useState(null)
    const [className, setClassName] = useState('')
    useEffect(() => {
        if (!collageRef) {
            return
        }
        // Delay to ensure correct rendering
        setTimeout(() => {
            if (mode == 0) {
                let collageCount = 1
                if (media.length >= 9) {
                    collageCount = 9
                } else if (media.length >= 4) {
                    collageCount = 4
                } else if (media.length >= 2) {
                    collageCount = 2
                }
                setClassName(`by-${collageCount}`)
                setElem([...media].slice(0, collageCount).map((x, ix) => <Media key={ix} mediaId={x.id} app={app} />))
                return

            }
            if (mode == 1) {
                setClassName('slide-in animate__animated animate__zoomIn')
                // Create an array of indicies for media
                let indicies = media.map((x, ix) => ix)
                let indiciesB = media.map((x, ix) => ix)
                fnc.shuffle(indicies)
                fnc.shuffle(indiciesB)

                setElem(media.map((x, _ix) => {
                    const ix = indicies[_ix]
                    const ixB = indiciesB[_ix]
                    return <UpgradeCollageMedia ix={ix} ixB={ixB} key={ix} total={media.length} media={x} bounds={collageRef.getBoundingClientRect()} app={app} />
                }))
                return
            }
            setElem(<canvas ref={canvasRef} style={{ display: 'block' }} />)
        }, 100)
    }, [mode, collageRef])

    return <div className={`upgrade-collage ${className}`} ref={setCollageRef}>
        {elem}
    </div>
}
import { getWindowLocation } from 'helpers/config'
import React, { useEffect } from 'react'

interface TourIFrameProps {
    src: string,
}

const iFrameProperties = {
    allow: 'xr-spatial-tracking',
    allowFullScreen: true,
    allowvr: 'yes',
}

export function TourIFrame(props: TourIFrameProps) {
    const { src } = props

    // Ensure the origin of the src matches the domain of the window location
    let origin = getWindowLocation().origin
    let finalSrc = src

    return <iframe {...iFrameProperties} scrolling="no" frameBorder="0" src={src} onScroll={(e) => e.preventDefault()} onWheel={(e) => e.preventDefault()} />
}
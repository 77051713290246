import React, { useState, useRef, RefObject, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { IMaskInput } from 'react-imask'
import { IconButton } from './IconButton'
import { Icon } from './Icon'
import { Button } from './Button'
import { Checkbox } from './Checkbox'
import { Dict, CustomEventType, PromptType } from 'app/types'
import * as fnc from 'helpers/fnc'
import ReactPinField from 'react-pin-field'
import { showPrompt } from 'actions/appActions'
import { useAppDispatch } from 'app/hooks'
import { icons } from 'app/constants'

interface InputProps {
    id?: string,
    icon?: string,
    label?: string,
    name?: string,
    value?: number | string,
    className?: string,
    large?: boolean,
    style?: Dict,
    placeholder?: string,
    clear?: boolean,
    undo?: boolean,
    submit?: boolean,
    submitText?: string,
    submitAfter?: boolean,
    onChange: () => void,
    onSubmit: () => void,
    onUndo?: () => void,
    onEvent?: () => void,
    password?: boolean,
    forgot?: boolean,
    generatePassword?: boolean,
    generateKey?: string,
    showPasswordToggle?: boolean,
    autoFocus?: boolean,
    price?: boolean,
    phone?: boolean,
    clearRef?: RefObject,
    search?: boolean,
    remember?: boolean,
    textarea?: boolean,
    pin?: boolean,
    clearOnSubmit: boolean,
    integer: boolean,
    undoValue?: number | string,
    forceUpdate: string,
    autoComplete: string[],
    fixed: boolean,
    undoPrompt: boolean,
    changeOnConfirm: boolean,
    iconButton: string,
    iconButtonProps: () => void,
    note: string,
    selectOnFocus: boolean,
}

export function Input(props: InputProps) {
    const {
        id,
        className,
        label,
        icon,
        large,
        placeholder,
        clear,
        name,
        undo,
        onChange,
        onSubmit,
        submit,
        submitText,
        submitAfter,
        password,
        forgot,
        onEvent,
        generatePassword,
        generateKey,
        showPasswordToggle,
        autoFocus,
        price,
        phone,
        onUndo,
        clearRef,
        search,
        remember,
        textarea,
        pin,
        clearOnSubmit,
        integer,
        undoValue,
        forceUpdate,
        autoComplete,
        fixed,
        undoPrompt,
        changeOnConfirm,
        iconButton,
        iconButtonProps,
        note,
        selectOnFocus,
        ...inputProps
    } = { undoValue: props.value ? props.value.toString() : null, showPasswordToggle: true, ...props }

    // Nullify incoming original

    const dispatch = useAppDispatch()
    const rememberKey = `remember-${name}`
    const [originalValue, setOriginalValue] = useState(undoValue)
    const [value, setValue] = useState(null)
    const [showPassword, setShowPassword] = useState(false)
    const [rememberMe, setRememberMe] = useState(localStorage.getItem(rememberKey) != null)
    const inputRef = useRef()
    const [isFocus, setIsFocus] = useState(false)
    const [autoCompleteIndex, setAutoCompleteIndex] = useState(-1)
    const [autoCompleteResults, setAutoCompleteResults] = useState([])
    const [autoCompleteValue, setAutoCompleteValue] = useState(null)
    const [changePending, setChangePending] = useState(false)
    const attrs = `${large ? ' input-large' : ''}${className ? ` ${className}` : ''}${textarea ? ' textarea' : ''}${fixed ? ' fixed' : ''}${changePending ? ' pending' : ''}`
    const pendingValue = useRef()

    useEffect(() => {
        if (remember) {
            let rememberValue = localStorage.getItem(rememberKey)
            if (rememberValue != null) {
                setValue(rememberValue.toString())
                // if (props.undoValue == null) {
                // setOriginalValue(rememberValue.toString())
                // }
                if (onChange) {
                    onChange(rememberValue.toString())
                }
                return
            }
        }
        if (props.value != null) {
            setValue(props.value.toString())
            // if (props.undoValue == null) {
            // setOriginalValue(props.value.toString())
            // }
            if (onChange && props.value != props.value.toString()) {
                onChange(props.value.toString())
            }
        } else {
            setValue('')
        }
    }, [])

    useEffect(() => {
        if (forceUpdate) {
            if (props.value) {
                setValue(props.value.toString())
            } else {
                setValue('')
            }
        }
    }, [props.value])

    useEffect(() => {
        // Set autocomplete
        if (autoComplete && Array.isArray(autoComplete)) {
            setAutoCompleteIndex(-1)
            setAutoCompleteResults(autoComplete.filter((x) => autoCompleteValue == null || x.includes(autoCompleteValue)))
        }
    }, [autoCompleteValue])

    useEffect(() => {
        if (undoValue == null) {
            setOriginalValue(null)
        } else {
            setOriginalValue(undoValue.toString())
        }
    }, [undoValue, undoValue == null])

    useEffect(() => {
        if (pin && inputRef.current && autoFocus && !isFocus) {
            setIsFocus(true)
            inputRef.current.inputs[0].focus()
        }
    }, [inputRef.current])

    useEffect(() => {
        if (password) {//} && !showPasswordToggle) {
            window.addEventListener(CustomEventType.ShowPassword, handleWindowShow)
            return () => {
                window.removeEventListener(CustomEventType.ShowPassword, handleWindowShow)
            }
        }
    }, [])

    useEffect(() => {
        if (password && !generatePassword) {
            window.addEventListener(CustomEventType.GeneratePassword, handleWindowGenerate)
            return () => {
                window.removeEventListener(CustomEventType.GeneratePassword, handleWindowGenerate)
            }
        }
    }, [generateKey])

    useEffect(() => {
        if (!isFocus && changeOnConfirm && changePending) {
            handleComplete()
            return
        }
    }, [isFocus, changePending])

    function handleComplete(val) {
        onChange(val != null ? val : pendingValue.current)
        setChangePending(false)
        pendingValue.current = null
    }

    function handleFocus() {
        setIsFocus(true)
        if (phone && inputRef.current && inputRef.current.maskRef) {
            let mask = inputRef.current.maskRef
            mask.updateOptions({ lazy: true })
        }
        if (selectOnFocus) {
            if (inputRef.current.element) {
                // inputRef.current.maskRef._unsafeSelect(0, inputRef.current.element.value.length)
                // inputRef.current.element.setSelectionRange(0, inputRef.current.element.value.length)
            } else {
                inputRef.current.select()
            }
        }
    }

    function blur() {
        inputRef.current.blur()
    }

    function handleBlur() {
        setIsFocus(false)
        if (phone && inputRef.current && inputRef.current.maskRef) {
            let mask = inputRef.current.maskRef
            mask.updateOptions({ lazy: true })
            // NEXT IS OPTIONAL
            if (!mask.masked.rawInputValue) {
                mask.value = ''
            }
        }
    }

    function handleToggleShow() {
        window.dispatchEvent(new CustomEvent(CustomEventType.ShowPassword, { detail: { showPassword: !showPassword } }))
        setShowPassword(!showPassword)
    }

    function rememberValue(rv = null, force = false) {
        if (rememberMe || force) {
            if (rv == null) {
                localStorage.removeItem(rememberKey)
            } else {
                localStorage.setItem(rememberKey, rv)
            }
        }
    }

    function handleToggleRememberMe() {
        if (rememberMe) {
            rememberValue(null, true)
        } else {
            rememberValue(value, true)
        }
        setRememberMe(!rememberMe)
    }

    function handleWindowShow(e: Event) {
        setShowPassword(e.detail.showPassword)
    }

    function handleGenerate() {
        const password = fnc.generatePassword()
        fnc.copyToClipboard(password)
        handleChange({ target: { value: password } })
        setShowPassword(true)
        setTimeout(() => {
            window.dispatchEvent(new CustomEvent(CustomEventType.GeneratePassword, { detail: { password } }))
        }, 0)
    }

    function handleWindowGenerate(e: Event) {
        setShowPassword(true)
        handleChange({ target: { value: e.detail.password } })
    }

    function handleForgot() {
        if (onEvent) {
            onEvent(CustomEventType.ForgotPassword)
        }
    }

    function handleChange(e: Event) {
        if (!e || fixed) {
            return
        }
        let val = e.target ? e.target.value : e
        if (integer) {
            val = val.toNumeric()
        }

        if (e.target && autoComplete) {
            setAutoCompleteValue(val)
        }

        handleValue(val)
    }

    function handleValue(val) {
        setValue(val)
        rememberValue(val)
        applyValue(val)
    }

    function applyValue(val) {
        if (onChange) {
            if (changeOnConfirm) {
                setChangePending(true)
                pendingValue.current = val
            } else {
                handleComplete(val)
            }
        }
    }


    function handleAccept(x, mask) {
        const finalValue = mask._unmaskedValue
        if (finalValue != value) {
            setValue(finalValue)
            applyValue(finalValue)
        }
    }

    function handleSubmit(e: Event) {
        if (e && e.stopPropagation) {
            e.stopPropagation()
        }
        if (onSubmit) {
            onSubmit(value)
            if (clearOnSubmit) {
                setValue('')
            }
        }

        if (changeOnConfirm) {
            handleComplete()
        }
    }

    function handleKeyPress(e: Event) {
        if (e.key === 'Enter') {
            handleSubmit()
        }
    }

    function handleKeyDown(e: Event) {
        if (autoComplete) {
            let newIndex = null
            switch (e.key) {
                case 'Tab':
                    e.preventDefault();
                    if (e.shiftKey) {
                        newIndex = Math.max(autoCompleteIndex - 1, 0)
                    } else {
                        newIndex = Math.min(autoCompleteIndex + 1, autoCompleteResults.length - 1)
                    }
                    break;
                case 'ArrowDown':
                    e.preventDefault();
                    newIndex = Math.min(autoCompleteIndex + 1, autoCompleteResults.length - 1)
                    break
                case 'ArrowUp':
                    e.preventDefault();
                    newIndex = Math.max(autoCompleteIndex - 1, 0)
                    break
                case 'Enter':
                    e.preventDefault()
                    blur()
                    break
            }
            if (newIndex != null) {
                setAutoCompleteIndex(newIndex)
                handleChange(autoCompleteResults[newIndex])
            }
        }
    }

    function handleClear() {
        setValue('')
        if (onChange) {
            onChange('')
        }
    }

    if (clearRef) {
        clearRef.current = { clear: handleClear }
    }

    async function handleUndo() {
        if (undoPrompt) {
            let resetValue = originalValue
            if (price && resetValue) {
                resetValue = fnc.toMoney(resetValue)
            } else {
                resetValue = 'None'
            }
            let message = `Are you sure you want to reset this value?\n (${price ? fnc.toMoney(parseFloat(value)) : value} -> ${resetValue})`
            if (typeof undoPrompt == 'string') {
                message = undoPrompt

            }
            const ret = await dispatch(showPrompt({ type: PromptType.Confirm, title: 'Reset', message }))
            if (!ret.payload) {
                return
            }
        }
        setValue(originalValue == null ? '' : originalValue)
        if (onChange) {
            onChange(originalValue)
        }
        if (onUndo) {
            onUndo()
        }
    }
    function getAutoComplete() {
        if (!isFocus || autoCompleteResults.length == 0) {
            return
        }
        return <div className="input-autocomplete">
            {autoCompleteResults.map((x, ix) =>
                <Button noBg className={ix == autoCompleteIndex ? 'focus' : null}
                    onMouseDown={() => handleChange(x)}>
                    {x}
                </Button>)
            }
        </div>
    }


    function getInputElement() {
        let inputType = 'text'
        if (password) {
            inputType = showPassword ? 'text' : 'password'
        }

        let inputEl = null
        // TODO: field type enum
        if (price) {
            inputEl = <React.Fragment><IMaskInput
                id={id}
                mask='$num'
                lazy={false}
                blocks={{
                    num: {
                        mask: Number,
                        max: 99999999,
                        thousandsSeparator: ',',
                        radix: '.',
                        digits: 2,
                        signed: true,
                    },
                }}
                className="input"
                ref={inputRef}
                name={name}
                autoFocus={autoFocus}
                type={inputType}
                value={value}
                placeholder={placeholder}
                onAccept={handleAccept}
                onKeyPress={handleKeyPress}
                onFocus={handleFocus}
                onBlur={handleBlur} />
                {placeholder != null && (value == null || value == "") && <div className="placeholder" style={isNaN(placeholder) ? { left: '30px' } : null}>
                    {fnc.toMoney(placeholder, true, false)}
                </div>}
            </React.Fragment>
        } else if (phone) {
            inputEl = <IMaskInput
                id={id}
                mask='+{1}(000) 000-0000'
                lazy={true}
                className="input"
                ref={inputRef}
                name={name}
                autoFocus={autoFocus}
                type={inputType}
                value={value}
                placeholder={placeholder}
                onAccept={handleAccept}
                onKeyPress={handleKeyPress}
                onFocus={handleFocus}
                onBlur={handleBlur} />
        } else if (textarea) {
            inputEl = <textarea
                id={id}
                rows="4"
                className="input"
                ref={inputRef}
                autoFocus={autoFocus}
                value={value}
                name={name}
                placeholder={placeholder}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                onFocus={handleFocus}
                onBlur={handleBlur}>
            </textarea>
        } else if (pin) {
            inputEl = <ReactPinField
                className="pin-field"
                validate="0123456789"
                ref={inputRef}
                autoFocus={true}
                length={4}
                onChange={handleChange}
                onComplete={handleSubmit} />
        } else {
            inputEl = <input
                id={id}
                className="input"
                ref={inputRef}
                autoFocus={autoFocus}
                type={inputType}
                value={value}
                name={name}
                placeholder={autoComplete && isFocus ? autoCompleteValue : placeholder}
                autoComplete={autoComplete && typeof autoComplete == 'string' ? autoComplete : null}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                onBlur={handleBlur}>
            </input>
        }

        return <React.Fragment>
            <div className={`input-element${isFocus ? ' focus' : ''}${label ? ' with-label' : ''}${pin ? ' pin-element' : ''}`}>
                {label && <span className="label">{label}</span>}
                {inputEl}
                {password && showPasswordToggle && <Button noTab noBg alt className="show-password" name="Show password" onClick={handleToggleShow}>{showPassword ? 'HIDE' : 'SHOW'}</Button>}
                {/* {placeholder && <label className={`placeholder${isFocus || value.length > 0 ? ' minimize' : ''}`}>{placeholder}</label>} */}
                {clear && value.length > 0 && <IconButton className="clear" noBg icon={icons.times} onClick={handleClear} />}
                {undo && value != null && value.length > 0 && value !== originalValue && <IconButton className="undo animate__animated animate__fastest animate__zoomIn" alt noBg icon={icons.undo} onClick={handleUndo} />}
                {iconButton && <IconButton style={undo ? { right: '30px' } : {}} className="undo animate__animated animate__fastest animate__zomIn" alt noBg icon={iconButton} {...iconButtonProps} />}
            </div>
            {search && <IconButton className="search" noBg icon={icons.search} onClick={handleSubmit} />}
            {props.children ? (typeof props.children == 'function' ? props.children(handleValue) : props.children) : null}
            {icon && <Icon className="input-icon" noBg icon={icon} />}
            {note && <div className="input-note bottom-right"><span>{note}</span></div>}
        </React.Fragment>
    }

    if (value == null) {
        return null
    }

    if (password || submit) {
        return <div className={`input-wrapper${attrs}`} {...inputProps} style={{ flexDirection: 'column' }}>
            <div className={`row${submit && !submitAfter ? ' horizontal' : ''}`}>
                {getInputElement()}
                {submit && !submitAfter && !pin
                    && <Button className="submit" tertiary style={{ marginLeft: '10px' }} large={large} onClick={handleSubmit}>{submitText ? submitText : 'Submit'}</Button>}
            </div>

            {password && forgot && <div className="row" style={{ flexDirection: 'row' }}>
                <div className="forgot-password noselect"><span><a onClick={handleForgot}>I forgot my password</a></span></div>
            </div>}

            {password && generatePassword && <div className="row" style={{ flexDirection: 'row' }}>
                <Button className="generate" alt onClick={handleGenerate}>Generate</Button>
            </div>}

            {remember && <div className="row" style={{ flexDirection: 'row' }}>
                <Checkbox value={rememberMe} className="remember" noBg alt name="Remember" onChange={handleToggleRememberMe}>remember me</Checkbox>
            </div>}
            {/*password && (showPasswordToggle || generatePassword) && <div className="row" style={{ flexDirection: 'row' }}>
                {showPasswordToggle && <Checkbox value={showPassword} noBg alt name="Show password" onChange={handleToggleShow} style={!generatePassword ? { marginTop: 0 } : {}}>Show Password</Checkbox>}
                {generatePassword && <Button className="generate" alt onClick={handleGenerate}>Generate</Button>}
                </div>*/}
            {submit && submitAfter
                && <div className="row"><Button className="submit" tertiary large={large} onClick={handleSubmit}>{submitText ? submitText : 'Submit'}</Button></div>}
            {autoComplete && getAutoComplete()}
        </div>
    }

    return <div className={`input-wrapper${attrs}`} onMouseDown={(e) => e.stopPropagation()} {...inputProps}>
        {getInputElement()}
        {remember && <div className="row" style={{ flexDirection: 'row' }}>
            <Checkbox value={rememberMe} className="remember" noBg alt name="Remember" onChange={handleToggleRememberMe}>Remember Me</Checkbox>
        </div>}
        {autoComplete && getAutoComplete()}
    </div>
}

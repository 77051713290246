import React, {
    useRef,
    useState,
    useEffect,
    RefObject,
} from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { PromptOptions, EmailType, EmailOptions } from 'app/types'
import * as fnc from 'helpers/fnc'
import {
    pushNotification,
    showPrompt,
    sendEmail,
} from 'actions/appActions'
import {
    CloseButton,
    Input,
    Button,
} from 'components'
import { FileUploader } from 'react-drag-drop-files'

interface ImportDialogProps {
    title: string,
    description: string,
    onClose: () => void,
    onResult: () => void,
    animation: string,
    message: string,
}

export function ImportDialog(props: ImportDialogProps) {
    const {
        title,
        description,
        media,
        onClose,
        onResult,
        animation,
        message,
    } = props

    const dispatch = useAppDispatch()
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const [textfield, setTextfield] = useState<string>("")
    const [error, setError] = useState<string>("")



    function handleImport(x) {
        // Load contents of csv file x
        const file = x[0]
        var reader = new FileReader();
        reader.readAsText(file, "UTF-8")
        reader.onload = function (evt) {
            handleTextImport(evt.target.result)
        }
        reader.onerror = function (evt) {
            setError("error reading file")
        }
    }

    function handleTextImport(csv) {
        // Parse csv into array of objects
        const result = fnc.parseCSV(csv)
        onResult(result)
    }

    return <div className={`dialog ${animation}`} onClick={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
        <div className="title">
            <h2> Import </h2>
            {title && <h4 className="noselect">{message}</h4>}
        </div>
        <div className="dialog-wrapper">
            <CloseButton onClick={onClose} />
            {error && <h4 className="error">{error}</h4>}
            <div className="file-picker csv-picker">
                <FileUploader classes="drag-and-drop" multiple handleChange={handleImport} name="file" types={["csv", "tsv"]}>
                    <h3> Click or drag to upload csv</h3>
                </FileUploader>
            </div>
            <Input textarea value={textfield} onChange={setTextfield} placeholder="Paste csv" />
            <Button onClick={() => handleTextImport(textfield)}>Submit</Button>
        </div>
    </div>
}

import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { showPrompt } from 'actions/appActions'
import { toggleFavourite } from 'actions/userActions'
import {
    IconButton,
    Button,
} from 'components'
import {
    RootState,
    FloorplanData,
    FloorplanVariationData,
    PromptOptions,
    CustomEventType,
} from 'app/types'
import { logger } from 'helpers/logger'

interface FavouriteButtonProps {
    app: AppData,
    floorplan: FloorplanData,
    variation: FloorplanVariationData,
    noBg?: boolean,
    alt?: boolean,
    text?: boolean,
}
export function FavouriteButton(props: FavouriteButtonProps) {
    const dispatch = useAppDispatch()
    const {
        app,
        floorplan,
        variation,
        noBg,
        alt,
        text,
    } = props
    if (!app) {
        return null
    }
    const { meta } = app

    const favourited = useAppSelector((state:RootState) => meta.id in state.user.favourites && floorplan.id in state.user.favourites[meta.id])
    const favouriteIcon = meta.favouriteIcon
    const loggedIn = useAppSelector((state:RootState) => state.user.loggedIn)
    const salescenter = useAppSelector((state:RootState) => state.app.salescenter)
    let animateTimeout = null

    const [className, setClassName] = useState('')
    function handleClick(e:Event) {
        e.stopPropagation()
        if (loggedIn || salescenter) {
            if (!favourited && !text) {
                setClassName(' animate__animated animate_faster animate__heartBeat')
                clearTimeout(animateTimeout)
                animateTimeout = setTimeout(() => {
                    setClassName('')
                }, 1000)
            }
            dispatch(toggleFavourite({ app: app, floorplanId: floorplan.id, variationId: variation.id }))
        } else {
            // Can't favourite, prompt
            if (!salescenter && !loggedIn) {
                window.dispatchEvent(new CustomEvent(CustomEventType.Register, { detail: { title: 'Sign up to save plans' } }))
            }
        }
    }
    if (text) {
        return <Button
            className={`${favourited ? ' favourite selected' : ''}${className}`}
            alt
            onClick={handleClick}>
            {favourited ? 'Unsave' : 'Save'}
        </Button>
    }

    return <IconButton
        noBg={noBg}
        alt={alt}
        icon={`${favourited ? 'fas' : 'far'} ${favouriteIcon}`}
        className={`favourite-button${favourited ? ' favourite' : ''}${className}`}
        onClick={handleClick}/>
}
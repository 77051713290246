import React from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
    RootState,
} from 'app/types'
import { Footer } from './Footer'

export default function QueryView() {
    const organization = useAppSelector((state:RootState) => state.app.organization)

    function getCopy() {
        const organizationLink = organization ? organization.link : ''
        switch (organizationLink) {
        case 'thepropertylinq':
            return <h2>We are The Property LinQ</h2>
        default:
            return <h2>We are Home Gyde</h2>
        }
    }

    return <div id="about-us-view" className="content">
        <div className="content-wrapper">
            {getCopy()}
        </div>
        <Footer/>
    </div>
}

import React from 'react'

interface MessageProps {
    title?: string,
    info?: string,
    error?: string,
    className?: string,
    children: JSX.Element[],
    onClick: () => void,
    onMoueDown: () => void,
}
export function Message(props: MessageProps) {
    const { title, info, error, children, className, onMouseDown, onClick } = props

    return <div className={`message fadeIn${className ? ` ${className}` : ''}`} onMouseDown={onMouseDown} onClick={onClick}>
        {title && <h3>{title}</h3>}
        {info && <h4>{info}</h4>}
        {error && <h4 className="error">{error}</h4>}
        {children}
    </div>
}
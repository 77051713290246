import React from 'react'
import { CustomEventType, QueryType, } from 'app/types'
import {
    CloseButton,
    Button,
    Filters,
} from 'components'

interface FilterDialogProps {
    app: AppData,
    filters: Dict[],
    floorplans: boolean,
    onClose: () => void,
    animation?: string,
    ranges?: {[key: number[]]},
    queryType: QueryType,
    expandedAsDropdown?: boolean,
    maps?: Dict,
}

export function FilterDialog(props:FilterDialogProps) {
    const {
        app,
        filters,
        floorplans,
        onClose,
        animation,
        ranges,
        queryType,
        expandedAsDropdown,
        maps,
    } = props

    function handleClose() {
        onClose()
    }

    return <div className={`dialog ${animation}`} onClick={(e) => {
        e.stopPropagation()
        window.dispatchEvent(new Event(CustomEventType.CloseAllMenus))
    }} onMouseDown={(e) => e.stopPropagation()}>
        <div className="title">
            <h3>Filters</h3>
        </div>
        <CloseButton onClick={handleClose}/>
        <div className="dialog-wrapper scrollable show-scroll">
            <Filters
                app={app}
                filters={filters}
                floorplans={floorplans}
                ranges={ranges}
                search
                fullscreen
                onClose={handleClose}
                queryType={queryType}
                expandedAsDropdown={expandedAsDropdown}
                maps={maps}
                expanded/>
        </div>
    </div>
}

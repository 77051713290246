import React, { useEffect, useState, useRef } from 'react'
import { CustomEventType } from 'app/types'

interface SpinnerProps {
    type: string,
    overlay?: boolean,
    show?: boolean,
    style: Dict,
    loading: boolean,
    showAfter: number,
    className: string,
    quotes: boolean,
    quotesAfter: number,
    invert: boolean,
    debug: boolean,
    small: boolean,
    medium: boolean,
    fixed: boolean,
}

function Quotes(props) {
    const [quote, setQuote] = useState(null)
    const [changing, setChanging] = useState(false)
    const quotes = [
        {
            quote: 'Be faithful to your own taste, because nothing you really like is ever out of style.',
            author: 'Billy Baldwin',
        },
        {
            quote: 'The details are not the details. They make the design.',
            author: 'Charles Eames',
        },
        {
            quote: 'I am going to make everything around me beautiful—that will be my life.',
            author: 'Elsie De Wolfe',
        },
        {
            quote: 'Architecture should speak of its time and place, but yearn for timelessness.',
            author: 'Frank Gehry',
        },
        {
            quote: 'Form follows function—that has been misunderstood. Form and function should be one, joined in a spiritual union.',
            author: 'Frank Lloyd Wright',
        },
        {
            quote: 'A room should never allow the eye to settle in one place. It should smile at you and create fantasy.',
            author: 'Juan Montoya',
        },
        {
            quote: 'Architecture is really about well-being. I think that people want to feel good in a space.',
            author: 'Zaha Hadid',
        },
    ]

    function changeQuote(tries = 5) {
        const index = Math.floor(Math.random() * quotes.length)
        if (quotes[index] != quote || tries > 0) {
            setQuote(quotes[index])
        } else {
            changeQuote(tries - 1)
        }
    }

    // useEffect(() => {
    // changeQuote()
    // }, [])

    useEffect(() => {
        changeQuote()

        let intervalA = null
        let intervalB = setInterval(() => {
            setChanging(true)
            intervalA = setTimeout(() => {
                changeQuote()
                setChanging(false)
            }, 250)
        }, 6000)
        return () => {
            if (intervalA) {
                clearTimeout(intervalA)
            }
            clearInterval(intervalB)
        }
    }, [props.show])

    if (!quote) {
        return null
    }

    return <div className="quotes" style={{ opacity: !changing && props.show ? 1 : 0 }}>
        <div className="divider" />
        <span>{`"${quote.quote}"`}</span>
        <div className="divider" />
        <h5>{quote.author}</h5>
    </div>
}

export function Spinner(props: SpinnerProps) {
    const { className, overlay, type, show, style, loading, showAfter, quotes, quotesAfter, invert, debug, small, medium, fixed } = { type: 'circle', invert: false, showAfter: 1000, quotes: true, quotesAfter: 1000, ...props }
    const finalStyle = { ...style, opacity: 1 }
    const spinnerStyle = { opacity: 1 }
    const [delayShow, setDelayShow] = useState(showAfter != null && showAfter > 0 ? true : null)
    const [quotesShow, setQuotesShow] = useState(false)
    const delayTimeout = useRef()
    const quotesTimeout = useRef()
    const spinRef = useRef()

    const attr = `${fixed ? ' fixed' : ''}${overlay ? ' overlay' : ''}${loading ? ' loading' : ''}${invert ? ' invert' : ''}${small ? ' small' : ''}${medium ? ' medium' : ''}${className != null ? ` ${className}` : ''}`
    useEffect(() => {
        /*if (spinRef.current && debug) {
            window.dispatchEvent(new CustomEvent(CustomEventType.DebugMessage, { detail: spinRef.current.clientWidth }))
        }*/
    }, [spinRef])

    useEffect(() => {
        if (showAfter != null && showAfter > 0) {
            delayTimeout.current = setTimeout(() => {
                setDelayShow(false)
            }, showAfter)
        }

        if (quotes && quotesAfter >= 0) {
            quotesTimeout.current = setTimeout(() => {
                setQuotesShow(true)
            }, quotesAfter)
        }

        return () => {
            if (delayTimeout.current) {
                clearTimeout(delayTimeout.current)
            }
            if (quotesTimeout.current) {
                clearTimeout(quotesTimeout.current)
            }
        }
    }, [])

    useEffect(() => {
        if (!show) {
            // clearTimeout(delayTimeout.current)
        }
    }, [show])

    if (show != null) {
        finalStyle.opacity = show ? 1 : 0
    }
    if (delayShow != null) {
        spinnerStyle.opacity = delayShow ? 0 : 1
    }

    function getSpinner() {
        switch (type) {
            default:
            case 'circle':
                return <div className="circle-spinner" style={spinnerStyle}></div>

            case 'cube':
                return <div className="sk-folding-cube" style={spinnerStyle}>
                    <div className="sk-cube1 sk-cube"></div>
                    <div className="sk-cube2 sk-cube"></div>
                    <div className="sk-cube4 sk-cube"></div>
                    <div className="sk-cube3 sk-cube"></div>
                </div>
        }
    }
    return <div className={`spinner-wrap${attr}`} style={finalStyle} ref={spinRef}>
        {quotes && overlay && <Quotes show={quotesShow} />}
        {getSpinner()}
        {props.children}
    </div>
}

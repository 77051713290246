import { float } from "@babylonjs/core"
import * as fnc from 'helpers/fnc'

/**
 * Generic
 */
export interface UrlOption {
    [key: string]: string | boolean | string[]
}

export type MinMax = {
    min: number,
    max: number,
}

export type Range = {
    [key: string]: MinMax | string[]
}

export type Dict = {
    [key: string]: number | string
}

export type NestedDict = {
    [key: string]: NestedDict | number | string
}

export type Query = {
    [key: FloorplanFilter | ProjectFilter | UnitFilter | AnalyticsFilter | UpgradeFilter]:
    number[] | string[] | MinMax,
}

export type Position = {
    x: number,
    y: number,
}

export type Style = {
}

export type FieldDef = {
    type: FieldType,
    label: string,
    text: string,
    validation: ValidateType,
    options: { id: string, name: string }[]
    element: JSX.Element,
    selector: () => JSX.Element, // Find id in data if not data itself
    map: (data: Dict, onChange: (key: string, value: string) => void) => JSX.Element,
    disable: () => string // If string returned show message instead
    fixedOnUpdate: boolean // Whether to display as un-editable text once created
    list: boolean // Whether to include/exclude from table listing
    edit: boolean // Whether to include/exclude from editor listing
    file: () => boolean // Filter out field options
}

export type Location = {
    id: string,
    name: string,
    numberAndStreet: string,
    unit: string,
    country: string,
    provinceState: string,
    city: string,
    postalZipCode: string,
    latLng: string,
    note: string,
}

export type ScreenProperties = {
    size: ScreenSize,
    orientation: ScreenOrientation
    isMobile: boolean,
    isTouch: boolean,
    userAgent: string,
    fullscreenSupport: boolean,
    focus: boolean,
}

export type Filter = {
    filter: string,
    expanded: boolean,
}

/**
 * Interfaces
 */
declare global {
    interface Array<T> {
        find(predicate: (search: T) => boolean): T;
    }
}

/**
 * Data
 */
export type ColorScheme = {
    id: string,
    name: string,
    primaryColor: string,
    secondaryColor: string,
    tertiaryColor: string,
}
export type ThemeData = {
    id: string,
    name: string,
    link: string,
    baseThemeId: string,
}

export type AppMetaData = {
    id: string,
    name: string,
    link: string,
    builderId: string,
    dateCreated: Date,
    altAppLink: string,
    registerLink: string,
    summary: string,
    statusUpdate: string,
    description: string,
    estCompletion: string,
    bookingLaunch: string,
    bookingEmail: string,
    bookingLink: string,
    bookingPhone: string,
    termsAndConditions: string,
    pricing: string,
    hotjarId: string,
    googleTagId: string,
    facebookPixelId: string,
    hubspotId: string,

    mediaId: string,
    logoMediaId: string,
    logoNotextMediaId: string,
    logoTopbarMediaId: string,
    logoSidebarMediaId: string,
    logoSidebarSmallMediaId: string,
    faviconMediaId: string,
    favouriteIcon: string,
    filters?: Dict,
    stats?: Dict,

    projectStatusId: string,
    published: boolean,
    maintenanceMode: boolean,
    showPricing: boolean,
    showPricingSalescenter: boolean,
    requireLoginSalescenter: boolean,
    enableAvailability: boolean,
    enablePricing: boolean,
    enableSharing: boolean,
    enableAnalytics: boolean,
    enableSales: boolean,
    enableNewsletter: boolean,
    enableRequestInfo: boolean,
    favouriteIcon: string,

    // Aggregations
    projectTypes: string[],
    filters: string[],
    filterRanges: { [key: string]: string },
    agents: string[]

    availabilityStates: AvailabilityStateData[],
    stateMap: { [key: string]: AvailabilityStateData },
    location: Location,

}

export type Config = {
    // App data
    apps: AppMetaData[],
    favouriteApps: AppMetaData[], // Redunant but easier
    builders: BuilderData[],
    organizations: OrganizationData

    // UI
    colorScheme: ColorScheme,
    baseColorScheme: ColorScheme,
    theme: string,
    baseTheme: string,
    colorSchemes: ColorScheme[],
    themes: ThemeData[],

    // Enumerations
    projectStatuses: { id: number, name: string }[],
    projectTypes: { id: number, name: string }[],
    pageTypes: { id: number, name: string }[],
    menuTypes: { id: number, name: string }[],
    mediaTags: { id: number, name: string }[],

    // Aggregations
    filterRanges: { [key: string]: string },

    // Helper maps to find configs
    maps: Dict[],
}

export type OrganizationData = {
    id: string,
    name: string,
    link: string,
    phone: string,
    email: string,
    tagline: string,
    subtagline: string,
    missionStatement: string,
    published: boolean,
    hotjarId: string,
    googleTagId: string,
    facebookPixelId: string,
    analyticsEvents: AnalyticsEventData[],
    mediaId: string
    logoMediaId: string,
    logoNotextMediaId: string,
    logoTopbarMediaId: string,
    splashMediaId: string,
    twitterLink: string,
    facebookLink: string,
    instagramLink: string,
    linkedinLink: string,
    hashtag: string,
    bookingEmail: string,
    consent: string,
    galleries: GalleryData[],
    mediaTags: MediaTagData[],
}

export type BuilderData = {
    id: string,
    name: string,
    link: string,
    site: string,
    mediaId: string,
    logoMediaId: string,
    logoNotextMediaId: string,
    logoTopbarMediaId: string,
    galleries: GalleryData[],
    mediaTags: MediaTagData[],
}

export interface SegmentOptions extends UrlOption {
    app: AppData,
    builder: BuilderData,
    organization: OrganizationData,
}

export type FloorplanVariationData = {
    id: string,
    floorplanId: string,
    name: string,
    sqft: number,
    beds: number,
    baths: number,
    garage: number,
    price: number,
    modelhomeId: string,
    spinId: string,
    mediaId: string,
    balconySqft: number,

    media: { mediaId: string, name: string, order: number }[],
    floorplans: { mediaId: string, order: number }[],
    unitPriceOverrides: { unitId: string, price: number }[],
}

export type FloorplanData = {
    id: string,
    name: string,
    link: string,
    variations: FloorplanVariationData[],
    appId: string,
    dateCreated: Date,
    media: MediaData[],
    units: UnitLookup[],
}

export type UnitLookup = {
    ix: number,
}

export type UnitData = {
    id: string,
    buildingId: string,
    availabilityStateId: string,
    name: string,
    floor: string,
    exposure: string,
    floorplans: string[],
}

export type ModelhomeData = {
    id: string,
    name: string,
    link: string,
    disclaimer: string,
    showDisclaimerAlways: boolean,
    mediaId: string,
    dateCreated: Date
}
export type Link = {
    pageId: string,
    floorplanId: string,
    modelhomeId: string,
}

export type MenuOption = Link & {
    id: string,
    name: string,
    subOptions: SubOption[]
}

export type SubOption = Link & {
    id: string,
    name: string,
}

export type MenuData = {
    id: string,
    type: string,
    options: MenuOption[]
}

export type PageData = {
    id: string,
    name: string,
    link: string,
    pageType: string,
    pageTypeId: number,
    order: number,
    icon: string,
    helper: boolean,
    helperText: string,
    draft: boolean,
    exclusive: boolean,
    salescenterOnly: boolean,
    showInMenu: boolean,
}

export type PageOptions = {
    splitIndex: number,
    dataLink: string,
    extraLink: string,
    floorplanId: string,
    modelhomeId: string,
    path: { baseRoute: string }, // Override path parameters
    useCompareMode: boolean, // Go to whatever mode is currently being compared
    replaceHistory: boolean, // Replace instead of push on history
}

export type EmailOptions = {
    value: string,
    appId: string,
    organizationId: string,
    optIn: boolean,

}

export type MediaData = {
    id: string,
    appId: string,
    mediaTypeId: string,
    name: string,
    link: string,
    additional: string,
}

export type MediaTagData = {
    id: string,
    name: string,
    appId: string,
}

export type MediaOptions = {
    global: boolean,
    thumb: boolean,
    thumbSize: number,
    appLink: string,

}

export type GalleryMediaData = {
    mediaId: string,
    tags: string[],
}

export type GalleryData = {
    id: string,
    name: string,
    link: string,
    media: GalleryMediaData[]
    showTags: boolean,
    tags: GalleryTagData[],
}

export type GalleryTagData = {
    id: string,
    name: string
}

export type PromptData = {
    type: PromptType
    title: string,
    subtitle: string,
    placeholder: string,
    validate: string,
    inAnimation: string,
    outAnimation: string,
    pendingMessage: string,
    successMessage: string,
    failureMessage: string,
    result: boolean | string,
    pendingResult: boolen | string | { success: boolean },
    noDismiss: boolean,
    carefulClose: boolean, // Must press x
    underBar: boolean,
    style: Dict,
}

export type NotificationData = {
    status: NotificationStatus,
    message: string,
    progress: number,
}

export type AvailabilityStateData = {
    id: string,
    name: string,
    color: string,
}

export type AvailabilityAnalytics = {
    userId: string,
    unitId: string,
    availabilityStateId: string,
    prevAvailabilityStateId: string,
    dateChanged: Date,
}
export type EmailAnalytics = {
    email: string,
    source: string,
    dateSent: Date,
}

export type FloorplanAnalytics = {
    userId: string,
    // sessionId: string
    // clientId: string
    floorplanId: string,
    variationId: string,
    source: AnalyticsSource,
    favourite: boolean,
    dateVisited: Date,
}

export type PageAnalytics = {
    userId: string,
    // sessionId: string
    // clientId: string
    appLink: string,
    pageLink: string,
    dataLink: string,
    source: AnalyticsSource,
    dateVisited: Date,
}

export type UpgradesAnalytics = {
    userId: string,
    appId: string,
    sessionId: string
    clientId: string
    upgradeOrderId: string,
    event: string,
    source: AnalyticsSource,
    dateCreated: Date,
}

export type TrackingAnalytics = {
    hubspotTrackingId: string
}

export type UserData = {
    id: string,
    token: string,
    name: string,
    email: string,
    password: string,
    passwordConfirm: string,
    dateCreated: Date,
    dateModified: Date,
    groups: UserGroup[],
    roles: UserRole[],
    apps: string[],
    orders: UserOrderRecordData[],
    upgradeOrders: UpgradeOrderRecordData[],
    admin: boolean,
}

export type Group = {
    id: string,
    name: string,
    roles: number[]
}
export type Role = {
    id: string,
    name: string,
}

// Upgrades
export type UpgradeSettingsData = {
    id: string,
    appId: string,
    areaWaste: number,
    areaUncertainty: number,
    profitMargin: number,
}

export type UpgradeAreaData = {
    id: string,
    appId: string,
    name: string,
}

export type UpgradeViewData = {
    id: string,
    appId: string,
    name: string,
    order: number,
    published: boolean,
    hideDefault: boolean,
    upgradePricingModeId: int,
    planIndependent: boolean,
    briefMediaId: string,
    finalizeMediaId: string,
}

export type UpgradeOptionData = {
    id: string,
    upgradeOptionTypeId: number,
    upgradeOptionGroupId: number,
    name: string,
    link: string,
    multiSelect: boolean,
    variations: UpgradeOptionVariationData[],
    locations: number,
    collapse: boolean,
    rotation: number[],
    global: boolean,
}

export type UpgradeOptionVariationData = {
    id: string,
    upgradeOptionId: string,
    upgradeOptionVariationTypeId: string,
    components: UpgradeComponentData[],
}

export type UpgradeOptionConfiguration = {
    id: string,
    appId: string,
    upgradeSpaceId: string,
    upgradeSpaceVariationId: string,
    upgradeOptionId: string,
    upgradeOptionVariationId: string,
    upgradeOptionComponentId: string,
    upgradeProductId: string,
    upgradePackageId: string,
    sqft: number,
    sqin: number,
    quantity: number,
    price: number,
    locations: UpgradeAreaData[],
    areaWaste: number,
    areaUncertainty: number,
}

export type UpgradeComponentData = {
    id: string,
    upgradeOptionVariationId: string,
    upgradeComponentTypeId: string,
    sqft: number,
    sqin: number,
    areaWaste: number,
    areaUncertainty: number,
    products: UpgradeComponentProductData[],
    configureQuantity: boolean,
}

export type UpgradeComponentProductData = {
    id: string,
    upgradeComponentId: string,
    upgradeProductId: string,
    upgradePackageId: string,
    priceCost: number,
    profitMargin: number,
    isDefault: boolean,
}

export type UpgradeProductData = {
    id: string,
    appId: string,
    name: string,
    upgradeDepartmentId: string,
    sku: string,
    description: string,
    priceSqft: number,
    priceSqin: number,
    priceCost: number,
    manufacturer: string,
    material: string,
    url: string,
    finish: string,
    style: string,
    colour: string,
    colourNo: string,
    thickness: string,
    dimensions: string,
    available: boolean,
    cancelled: boolean,
    media: string[],
    featureMediaId: string,
}

export type UpgradeOptionProductRecordData = {
    id: string,
    upgradeOrderRecordId: string,
    upgradeSpaceId: string,
    upgradeSpaceVariationId: string,
    upgradeOptionId: string,
    upgradeOptionVariationId: string,
    upgradeComponentId: string,
    upgradeProductId: string,
    sqft: number,
    sqin: number,
    areaWaste: number,
    areaUncertainty: number,
    profitMargin: number,
    priceCost: number,
    priceSqft: number,
    priceSqin: number,
    priceCalc: number,

}

export type UpgradeOrderRecordData = {
    id: string,
    userId: string,
    appId: string,
    upgradeOrderStatusId: number,
    upgradeOptionProductRecords: UpgradeOptionProductRecordData[]
    upgradeViewId: string,
    floorplanVariationId: string,
    userOrderRecordId: number,
}

export type UserOrderRecordData = {
    id: string,
    userId: string,
    appId: string,
    floorplanVariationId: number,
    unitId: number,
    userOrderStatusId: number,
    complete: boolean,
}

export type UpgradeDocumentData = {
    upgradeOrderRecordId: string,
    mediaId: string,
    envelopeId: string,
    signed: boolean,
}

export type UpgradeLogData = {
    appId: string,
    submitUserId: string,
    userId: string,
    floorplanId: number,
    floorplanVariationId: string,
    unitId: string,
    upgradeViewId: string,
    csv: string,
}

export type PointData = {
    x: number,
    y: number,
    size: number,
    label: string,
}
export type FormContentData = {
    submitMessage: string,
    tags: string,
    fields: { label: string, key: string, required: boolean, inputProps: Dict, size: number }[],
}

export type CustomContentData = {
    type: CustomContentType,
    dynamicContentTypeId: CustomContentType,
    title: string,
    subtitle: string,
    header: string,
    footer: string,
    body: string,
    media: { galleryLink: string, index: number, clickable: boolean, position: number, points: PointData[], }[]
    link: string,
    links: { label: string, link: string, externalLink: string }[],
    elements: CustomContentData[],
    requestType: string,
    date: Date,
    time: string,
    dividerBefore: boolean,
    dividerAfter: boolean,
    focus: boolean,
    alt: boolean,
    full: boolean,
    dark: boolean,
    location: Location,
    locations: Location[],
    align: string,
    animated: boolean,
    forms: FormContentData[],
    redirect: string,
    data: Dict,
    expand: Dict,
    large: boolean,
    split: string,
    points: PointData[],
    hubspotOptions: Dict,
    size: float,
    submit: string,
}

export type MapPlaceData = {
    id: string,
    mapId: string,
    link: string,
    name: string,
    formattedAddress: string,
    url: string,
    website: string,
    icon: string,
    iconBackgroundColor: string,
    iconMaskBaseUri: string,
    photos: string[],
}

export type MapData = {
    id: string,
    appId: string,
    mediaId: string,
    places: MapPlaceData[],
}

// Spins
export type SpinData = {
    appId: string,
    id: string,
    name: string,
    link: string,
    disclaimer: string,
    views: { [key: string]: SpinViewData }
    published: boolean,
    previewMediaId: string,
}

export type SpinStateData = {
    currentView: string,
    initialized: number,
    error: string,
}

// Immutable
export type SpinViewData = {
    id: string,
    name: string,
    link: string,
    totalImages: 256,
    cameraDistance: number,
    cameraPitch: number,
    rotationOffset: number,
    defaultRotation: number,
    flipNormals: boolean,
    reverse: boolean,
    fov: number,
    mesh: string,
    north: number,
    position: string,
    scale: number,
    cropFactor: number,
    elements: SpinElementData[],
}

export type SpinElementData = {
    id: string,
    spinElementTypeId: SpinElementType,
    name: string,
    link: string,
    position: number[],
    rotation: number[],
    size: number[],
    radius: number,
    iconDiffuseMediaId: string,
    iconOpacityMediaId: string,
    iconSize: number
    iconOffset: number[],
    iconPlacement: string,
    mapCoordinates: string,
}

// Mutable
export type SpinViewStateData = {
    initialized: number,
    initialQuality: SpinQuality,
    targetQuality: SpinQuality,
    currentQuality: SpinQuality,
    defaultIndex: number,
    rotation: number,
}

export type SitemapData = {
    id: string,
    appId: string,
    mediaId: string,
    spinId: string,
    name: string,
    link: string,
    iconSize: number,
    maxZoom: number,
    order: number,
    isDefault: boolean,
    dateCreated: Date,
}

export type SitemapUnitData = {
    id: string,
    sitemapId: string,
    unitId: string,
    position: string,
    size: 1,
}

export type PhaseData = {
    id: string,
    name: string,
    appId: string,
    worksheets: number,
}

export type BuildingTypeData = {
    id: string,
    appId: string,
    name: string,
}

export type AnalyticsEventData = {
    analyticsEventTypeId: AnalyticsEvent,
    organizationId: string,
    googleTagId: string,
    googleConversionId: string,

}

/**
 * Aggregate app data
 */
export type AppData = {
    meta: AppMetaData,
    builder: BuilderData,
    floorplans: FloorplanData[]
    units: UnitData[],
    modelhomes: ModelhomeData[],
    menus: MenuData,
    pages: PageData[],
    media: MediaData[],
    galleries: GalleryData[],
    mediaTags: MediaTagData[],
    organizations: string[],
    dynamicContent: CustomContentData[],
    locations: Location[],
    upgrades: UpgradeData[],
    spins: SpinData[],
    phases: PhaseData[],
    fullPage: boolean,
}

export type AdminDelta = Dict[] | Dict

/**
 * State
 */
export type AppState = {
    initialized: int,
    queries: { [key: QueryType]: Query },
    fixedQueries: { [key: QueryType]: Query },
    current: AppData
    projectCurrent: AppData[],
    organization: OrganizationData,
    media: { [mediaId: string]: MediaData },
    error: string,
    fullscreen: boolean,
    prompt: PromptData,
    favourites: { [key: string]: string },
    cookies: boolean,
    history: string[],
    popState: string,
    compareCheckpoint: string,
    appPath: string, // Includes any app specifications parameters and options
    rootPath: string, // Primary root, may include organization
    compare: boolean,
    compareCommunities: boolean,
    compareMode: PageType,
    mobileMobe: ListStyle,
    navigation: string[],
    screen: ScreenProperties,
    clientId: string,
    sessionId: string,
    salescenter: boolean,
    config: Config,
    version: number,
    links: Dict,
    standaloneApp: boolean,
    standaloneOrg: boolean,
    showBuilding: boolean,
    showSitemap: boolean,
    notifications: NotificationData[],
    facebookId: string,
    googleId: string,
    organizationVisited: boolean,
    customDomain: { app: string, organization: string }
    exclusive: boolean,
}

export type UserState = {
    loggedIn: boolean,
    data: UserData,
    token: string,
    error: string,
    guestGroup: UserGroup,
    humanConfidence: number,
    confidenceRefresh: number,
}

export type AdminState = {
    userApps: AppMetaData[],
    apps: AppMetaData[],
    builders: BuilderData[],
    organizations: OrganizationData[],
    organization: OrganizationData,
    user: {
        users: UserData[],
        groups: Group[],
        roles: Role[],
    }
    editPending: boolean,
    changePending: boolean,
    delta: AdminDelta,
    analytics: Dict,
    sales: AvailabilityAnalytics[],
    newsletter: EmailAnalytics[],
    error: string,
    lastSave: Date,
    editInline: boolean,
    editContent: CustomContentData,
}

export type SpinState = {
    spinState: { [key: string]: SpinStateData },
    viewState: { [key: string]: SpinViewStateData },
}

// Fix for TS not inferring the root state from the ReturnType<typeof rootReducer>
export type RootState = {
    app: AppState,
    user: UserState,
}

/**
 * Enums
 */
export enum ScreenSize {
    Desktop = 0,
    Tablet = 1,
    Mobile = 2,
}
export enum ScreenOrientation {
    Landscape = 0,
    Portrait = 1,
}

export enum PromptType {
    Share = 'share',
    Input = 'input',
    Confirm = 'confirm',
    Pending = 'pending',
    Lightbox = 'lightbox',
    Filters = 'filters',
    Booking = 'booking',
    Project = 'project',
    Media = 'media',
    Gallery = 'gallery',
    Tutorial = 'tutorial',
    Form = 'form',
    Map = 'map',
    Import = 'import',
    Location = 'location'
}

export enum ValidateType {
    Email = 'email',
    Phone = 'phone',
    Name = 'name',
    Password = 'password',
    PasswordMatch = 'passwordMatch',
}

export enum ProjectType {
    Detached = 1,
    TownHome = 2,
    StackedTownHome = 3,
    Condo = 4,
    SemiDetached = 5,
}

export enum ProjectStatus {
    InDevelopment = 1,
    PreSale = 2,
    UpcomingLaunch = 3,
    ForSale = 4,
    SoldOut = 5,
}

export enum MediaType {
    Image = 1,
    Tour = 2,
    Model = 3,
    Spin = 4,
    Video = 5,
    File = 6,
    EmailResource = 7,
}

export enum SpinElementType {
    None = 0,
    Unit = 1,
    Feature = 2,
    View = 3,
}

const contactMessage = 'Please contact Invent Dev support at info@inventdev.com. Thank you.'
const contactMessageB = 'please contact Invent Dev support at info@inventdev.com. Thank you.'
export enum ErrorMessage {
    General = `We have detected an issue. We will investigate this issue promptly. Thank you for your patience.`,
    ApplicationUpdated = `The application has been updated. Please reload the page to see the changes to continue.`,
    Unknown = `We have detected an issue. We will investigate this issue promptly. Thank you for your patience.`,
    Reported = `Thank you for reporting this error. We will look into it shortly.`,
    LoadError = `There was a problem loading this page. ${contactMessage}`,
    // LoadError = 'We are currently having intermittent issues with the web hosting provider.\n\n Please refresh this web page periodically if you see this message.',
    DeleteError = `There was a problem deleting this data. ${contactMessage}`,
    DataError = `There was a problem loading this data. ${contactMessage}`,
    UpdateError = `There was a problem updating the app. ${contactMessage}`,
    ResetError = `There was a problem reseting your password. ${contactMessage}`,
    ActivateError = `There was a problem activating your account. ${contactMessage}`,
    LoginError = `There was a problem logging in. ${contactMessage}`,
    SignupError = `There was a problem signing up in. ${contactMessage}`,
    UserError = `There was a problem editing this user. ${contactMessage}`,
    SaveError = `There was a problem saving your changes. ${contactMessage}`,
    UserRetrieveError = `There was a problem retrieving your user information. ${contactMessage}`,
    GalleryError = `There was a problem loading the gallery. ${contactMessage}`,
    FavouritesError = `There was a problem clearing your favourites. ${contactMessage}`,
    EmailError = `There was a problem sending the email. ${contactMessage}`,
    BookingError = `There was a problem submiting your information. ${contactMessage}`,
    NotOnline = 'This feature is not availabile offline.',
    PageNotFound = `This page could not be located. ${contactMessage}`,
    NoAdminFunctions = `This function is unavailable for this app. ${contactMessage}`,
    MapError = `There was a problem loading the map. ${contactMessage}`,
    MaintenanceModeApp = `This project is undergoing scheduled maintenance. Service will be restored shortly. Feel free to ${contactMessageB}`,
    MaintenanceModePage = `This page is undergoing scheduled maintenance. Service will be restored shortly. Feel free to ${contactMessageB}`,
    ExclusivePage = `Thanks for visiting! This page is reserved for VIP members only. Register online today to become a VIP member. After registering you will gain exclusive access to this page and other exclusive content.`,
    GoogleError = `There was a problem contacting google. ${contactMessage}`,
    SitemapError = `There was a problem loading sitemap. ${contactMessage}`,
    MediaUploadError = `There was a problem uploading this media. ${contactMessage}`,
}

export enum UserRole {
    OrganizationsEdit = 1,
    BuildersEdit = 2,
    AppsEdit = 3,
    UsersEdit = 4,
    AvailabilityEdit = 5,
    AvailabilityView = 6,
    PricingEdit = 7,
    PricingView = 8,
    AnalyticsView = 9,
    SalesHistoryView = 10,
    NewsletterView = 11,
    PMEmailsEdit = 12,
    PMTasksEdit = 13,
    PMView = 14,
    LibraryView = 15,
    LibraryEdit = 16,
    AdminBackup = 17,
    Pricing = 18,
    Worksheet = 19,
    WorksheetEdit = 20,
    WorksheetView = 21,
    UpgradesEdit = 22,
    UpgradesView = 23,
    PagesEdit = 24,
    ContentEdit = 25
}

export enum UserGroup {
    BuilderFinance = 1,
    BuilderRealtor = 2,
    BuilderPM = 3,
    Agency = 4,
    InventDevView = 5,
    InventDevEdit = 6,
    InventDevAdmin = 7,
    InteriorDesigner = 8,
    InventDevArtist = 9,
    ListingAgent = 10,
    BuilderAgent = 11,
}

export enum PromptOptions {
    EmailGeneral = {
        type: PromptType.Input,
        id: 'email-prompt',
        title: 'View Our Plans Online',
        placeholder: 'Enter email address',
        validate: ValidateType.Email,
        optIn: 'I want to subscribe to the {{app_name}} newsletter.',
    },
    EmailShare = {
        type: PromptType.Input,
        id: 'email-prompt',
        title: 'Email this page',
        placeholder: 'Enter email address',
        validate: ValidateType.Email,
        optIn: 'I want to subscribe to the {{app_name}} newsletter.',
    },
    EmailFavourites = {
        type: PromptType.Input,
        id: 'email-prompt',
        title: 'Email My Favourites',
        placeholder: 'Enter email address',
        validate: ValidateType.Email,
        optIn: 'I want to subscribe to the {{app_name}} newsletter.',
    },
    EmailSending = {
        type: PromptType.Pending,
        title: 'Preparing Email',
        successMessage: 'Email sent!',
        failureMessage: ErrorMessage.EmailError,
        confirm: true,
    },
    NotifySending = {
        type: PromptType.Pending,
        title: 'Emailing User',
        successMessage: 'User Emailed!',
        failureMessage: ErrorMessage.EmailError,
        confirm: true,
    },
    CompleteSending = {
        type: PromptType.Pending,
        title: 'Completing',
        successMessage: 'Selections Complete!',
        failureMessage: ErrorMessage.EmailError,
        confirm: true,
    },
    ActionEmailSending = {
        type: PromptType.Pending,
        title: 'Preparing Email',
        successMessage: 'Email sent! Check your mailbox.',
        failureMessage: ErrorMessage.EmailError,
        confirm: true,
    },
    LoginAppAdmin = {
        id: 'dialog-login-app',
        type: PromptType.Input,
        title: 'Login',
        subtitle: 'Enter your password',
        fields: [
            {
                key: 'password',
                placeholder: 'Password',
                validation: ValidateType.Password,
                inputOptions: {
                    password: true,
                    forgot: true,
                },
            },
        ],
    },
    LoginAdmin = {
        id: 'dialog-login',
        type: PromptType.Input,
        title: 'Login',
        subtitle: 'Enter your credentials',
        fields: [
            {
                key: 'google',
                text: 'Log in with google',
            },
            {
                key: 'email',
                placeholder: 'Email',
                validation: ValidateType.Email,
            },
            {
                key: 'password',
                placeholder: 'Password',
                validation: ValidateType.Password,
                inputOptions: {
                    password: true,
                    forgot: true,
                },
            },
        ],
    },
    LoginUser = {
        id: 'dialog-login',
        type: PromptType.Input,
        title: 'Log In',
        google: true,
        googleText: 'Log in with google',
        register: true,
        registerText: 'New to Home Gyde? ',
        fields: [
            {
                key: 'email',
                placeholder: 'Email',
                validation: ValidateType.Email,
                inputOptions: {
                    name: 'signin',
                    remember: true,
                },
            },
            {
                key: 'password',
                placeholder: 'Password',
                validation: ValidateType.Password,
                inputOptions: {
                    submitText: 'Log in',
                    password: true,
                    forgot: true,
                },
            },
        ],

    },
    LoginUserRequiredPrompt = {
        id: 'dialog-login',
        type: PromptType.Input,
        title: 'Welcome!',
        noDismiss: true,
        options: [
            {
                key: 'register',
                label: 'Register to Begin',
                button: true,
                buttonProps: {
                    large: true,
                    tertiary: true,
                },
            },
            {
                key: 'login',
                label: "(I have already registered)"
            }
        ]

    },
    LoginUserRequiredLogin = {
        id: 'dialog-login',
        type: PromptType.Input,
        title: 'Log In',
        subtitle: 'Continue where you left off',
        noDismiss: true,
        fields: [
            {
                key: 'email',
                placeholder: 'Email',
                validation: ValidateType.Email,
                inputOptions: {
                    name: 'signin',
                    autocomplete: "off",
                    submitText: 'Start',
                },
            }
        ],
        options: [
            {
                key: 'register',
                label: "(I have not registered yet)",
            }
        ]
    },
    LoginUserRequiredRegister = {
        id: 'dialog-login',
        type: PromptType.Input,
        title: 'Register',
        subtitle: 'Please enter your information to begin',
        noDismiss: true,
        fields: [
            {
                key: 'email',
                label: 'Email',
                // placeholder: 'Email',
                validation: ValidateType.Email,
                inputOptions: {
                    name: 'email',
                    autoComplete: "off",
                }
            },
            {
                key: 'firstname',
                label: 'First Name',
                // placeholder: 'First Name',
                validation: ValidateType.Name,
                inputOptions: {
                    name: 'firstname',
                    autoComplete: "off",
                }
            },
            {
                key: 'lastname',
                label: 'Last Name',
                // placeholder: 'Last Name',
                validation: ValidateType.Name,
                inputOptions: {
                    name: 'lastname',
                    autoComplete: "off",
                }
            },
            {
                key: 'phone',
                label: 'Phone',
                // placeholder: 'Phone',
                validation: ValidateType.Phone,
                inputOptions: {
                    name: 'phone',
                    phone: true,
                    autoComplete: "off",
                    submitText: 'Start',
                },
            },
        ],
        options: [
            {
                key: 'submit',
                submit: true,
                label: 'Submit',
                buttonProps: {
                    tertiary: true
                }
            },
            {
                key: 'login',
                label: '(I have already registered)'
            }
        ]
    },
    RequestPIN = {
        id: 'dialog-login',
        type: PromptType.Input,
        title: 'Enter Email',
        subtitle: 'A PIN will be sent to your email address',
        // register: true,
        // registerText: 'New to Home Gyde? ',
        fields: [
            {
                key: 'email',
                placeholder: 'Email',
                validation: ValidateType.Email,
                inputOptions: {
                    name: 'signin',
                },
            },
        ],
        options: [
            {
                key: 'register',
                label: "(I have not registered yet)",
            }
        ]
    },
    RequestingPIN = {
        type: PromptType.Pending,
        title: 'Sending PIN email',
        successMessage: 'Check your mailbox!',
        failureMessage: ErrorMessage.EmailError,
        // confirm: true,
    },
    LoginPIN = {
        id: 'dialog-login',
        type: PromptType.Input,
        title: 'Enter PIN',
        subtitle: 'Check your inbox for your pin',
        fields: [
            {
                key: 'pin',
                resend: true,
                resendText: 'Resend PIN',
                inputOptions: {
                    pin: true,
                },
            },
        ],
        carefulClose: true,
    },
    LoggingIn = {
        type: PromptType.Pending,
        title: 'Logging in',
        successMessage: 'Welcome!',
        failureMessage: ErrorMessage.LoginError,
    },
    RegisterUser = {
        id: 'dialog-login',
        type: PromptType.Input,
        title: 'Sign Up',
        google: true,
        googleText: 'Sign up with google',
        login: true,
        loginText: 'Already signed up? ',
        agreement: true,
        fields: [
            {
                key: 'name',
                placeholder: 'Full Name*',
                validation: ValidateType.Name,
            },
            {
                key: 'email',
                placeholder: 'Email*',
                validation: ValidateType.Email,
            },
            {
                key: 'phone',
                placeholder: 'Phone',
                validation: ValidateType.Phone,
                inputOptions: {
                    phone: true,
                },
            },
            {
                key: 'password',
                placeholder: 'Password',
                validation: ValidateType.Password,
                inputOptions: {
                    // showPasswordToggle: false,
                    password: true,
                },
            },
            {
                key: 'passwordConfirm',
                placeholder: 'Re-enter Password',
                validation: ValidateType.PasswordMatch,
                inputOptions: {
                    submitText: 'Sign Up',
                    password: true,
                },
            },
        ],
    },
    RegisterFavourite = {
        type: PromptType.Input,
        title: 'Sign in or register to save plans',
        google: true,
        googleText: 'Sign up with google',
        login: true,
        loginText: 'Already signed up? ',
        agreement: true,
        fields: [
            {
                key: 'name',
                placeholder: 'Full Name',
                validation: ValidateType.Name,
            },
            {
                key: 'email',
                placeholder: 'Email',
                validation: ValidateType.Email,
            },
            {
                key: 'password',
                placeholder: 'Password',
                validation: ValidateType.Password,
                inputOptions: {
                    // showPasswordToggle: false,
                    password: true,
                },
            },
            {
                key: 'passwordConfirm',
                placeholder: 'Re-enter Password',
                validation: ValidateType.PasswordMatch,
                inputOptions: {
                    submitText: 'Sign Up',
                    password: true,
                },
            },
        ],
    },
    Registering = {
        type: PromptType.Pending,
        title: 'Just a moment...',
        successTitle: 'Registration successful!',
        successMessage: 'Please check your email to complete your registration.',
        failureMessage: ErrorMessage.SignupError,
        confirm: true,
    },
    RegisteringRequired = {
        type: PromptType.Pending,
        title: 'Just a moment...',
        // confirm: false,
        skipResult: true,
        // successTitle: 'Registration Submitted',
        // successMessage: 'Please check your email to complete your registration.',
        failureMessage: ErrorMessage.SignupError
        // confirm: true,
    },
    RegisteringPIN = {
        type: PromptType.Pending,
        title: 'Just a moment...',
        successTitle: 'Thank you!!',
        successMessage: 'You have successfully registered',
        failureMessage: ErrorMessage.SignupError,
        confirm: true,
    },
    RegisterSuccessful = {
        type: PromptType.Confirm,
        title: 'Registration successful!',
        message: 'Please check your email to complete your registration.',
        cancelMessage: null,
    },
    ResetPasswordTemporary = {
        type: PromptType.Input,
        title: 'Reset Password',
        subtitle: 'Enter new password',
        fields: [
            {
                key: 'password',
                placeholder: 'Enter Password',
                validation: ValidateType.Password,
                inputOptions: {
                    password: true,
                },
            },
            {
                key: 'password',
                placeholder: 'Re-enter Password',
                validation: ValidateType.PasswordMatch,
                inputOptions: {
                    password: true,
                    // generatePassword: true,
                },
            },
        ],
    },
    ResetPassword = {
        type: PromptType.Input,
        title: 'Reset Password',
        subtitle: 'Enter new password',
        fields: [
            {
                key: 'password',
                placeholder: 'Enter Current Password',
                validation: ValidateType.Password,
                inputOptions: {
                    password: true,
                },
            },
            {
                key: 'password',
                placeholder: 'Enter New Password',
                validation: ValidateType.Password,
                inputOptions: {
                    password: true,
                },
            },
            {
                key: 'password',
                placeholder: 'Re-enter New Password',
                validation: ValidateType.PasswordMatch,
                inputOptions: {
                    password: true,
                    generatePassword: true,
                },
            },
        ],
    },
    ResettingPassword = {
        type: PromptType.Pending,
        title: 'Resetting Password',
        successMessage: 'Password reset successfull!',
        failureMessage: ErrorMessage.ResetError,
        confirm: true,
    },
    ForgotPassword = {
        type: PromptType.Input,
        title: 'You forgot your password',
        subtitle: 'Enter your email to reset your password',
        placeholder: 'Enter email address',
    },
    SendActivation = {
        type: PromptType.Confirm,
        title: 'Activate your account',
        confirmMessage: 'Re-send activation',
    },
    DeleteUser = {
        type: PromptType.Confirm,
        title: 'Delete this user?',
        subtitle: 'This action cannot be undone.',
    },
    DeletingUser = {
        type: PromptType.Pending,
        title: 'Deleting user',
        successMessage: 'User deleted!',
        failureMessage: ErrorMessage.DeleteError,
        confirm: true,
    },
    DeleteOrganization = {
        type: PromptType.Confirm,
        title: 'Delete this organization?',
        subtitle: 'This action cannot be undone.',
    },
    DeletingOrganization = {
        type: PromptType.Pending,
        title: 'Deleting organization',
        successMessage: 'Organization deleted!',
        failureMessage: ErrorMessage.DeleteError,
        confirm: true,
    },
    DeleteBuilder = {
        type: PromptType.Confirm,
        title: 'Delete this builder?',
        subtitle: 'This action cannot be undone.',
    },
    DeletingBuilder = {
        type: PromptType.Pending,
        title: 'Deleting builder',
        successMessage: 'Builder deleted!',
        failureMessage: ErrorMessage.DeleteError,
        confirm: true,
    },
    DeleteApp = {
        type: PromptType.Confirm,
        title: 'Delete this app?',
        subtitle: 'This action cannot be undone.',
    },
    DeletingApp = {
        type: PromptType.Pending,
        title: 'Deleting app',
        successMessage: 'App deleted!',
        failureMessage: ErrorMessage.DeleteError,
        confirm: true,
    },
    DeleteData = {
        type: PromptType.Confirm,
        title: 'Delete this data?',
        subtitle: 'This action cannot be undone.',
    },
    DeletingData = {
        type: PromptType.Pending,
        title: 'Deleting data',
        successMessage: 'Data deleted!',
        failureMessage: ErrorMessage.DeleteError,
        confirm: true,
    },
    ClearFavourites = {
        type: PromptType.Confirm,
        title: 'Clear all favourites?',
        subtitle: 'This action cannot be undone.',
    },
    ClearingFavourites = {
        type: PromptType.Pending,
        title: 'Clearing favourites',
        successMessage: 'Favourites cleared!',
        failureMessage: ErrorMessage.FavouritesError,
        confirm: true,
    },
    ChangePending = {
        type: PromptType.Confirm,
        title: 'Leave without saving?',
        subtitle: 'This will discard any changes you haven\'t saved',
    },
    UnsavedChanges = {
        type: PromptType.Confirm,
        title: 'Unsaved changes',
        subtitle: 'You have unsaved changes. Are you sure you want to close the editor?',
    },
    EditPending = {
        type: PromptType.Pending,
        title: 'Saving changes...',
        successMessage: 'Changes saved!',
        failureMessage: ErrorMessage.SaveError,
        confirm: true,
    },
    CreateUserPending = {
        type: PromptType.Pending,
        title: 'Creating user...',
        successMessage: 'User created!',
        failureMessage: ErrorMessage.SaveError,
        confirm: true,
    },
    CreateAppPending = {
        type: PromptType.Pending,
        title: 'Creating app...',
        successMessage: 'App created!',
        failureMessage: ErrorMessage.SaveError,
        confirm: true,
    },
    CreatePagePending = {
        type: PromptType.Pending,
        title: 'Creating page...',
        successMessage: 'Page created!',
        failureMessage: ErrorMessage.SaveError,
        confirm: true,
    },
    CreateBuilderPending = {
        type: PromptType.Pending,
        title: 'Creating builder...',
        successMessage: 'Builder created!',
        failureMessage: ErrorMessage.SaveError,
        confirm: true,
    },
    CreateOrganizationPending = {
        type: PromptType.Pending,
        title: 'Creating organization...',
        successMessage: 'Organization created!',
        failureMessage: ErrorMessage.SaveError,
        confirm: true,
    },
    AdminViewUnavailable = {
        type: PromptType.Confirm,
        message: `To enable this feature ${contactMessageB}`,
        cancelMessage: null,
    },
    AdminViewEnabling = {
        type: PromptType.Pending,
        title: 'Enabling feature...',
        successMessage: 'Feature enabled!',
        failureMessage: ErrorMessage.SaveError,
        confirm: true,
    },
    AdminViewEnable = {
        type: PromptType.Confirm,
        cancelMessage: null,
    },
    Support = {
        type: PromptType.Confirm,
        title: 'Support',
        message: `If you require assistance ${contactMessageB}`,
        cancelMessage: null,
    },
    Booking = {
        type: PromptType.Booking,
        title: 'Schedule a consultation',
    },
    SubmitBooking = {
        type: PromptType.Pending,
        title: 'Submitting information',
        successTitle: 'Information Submitted',
        successMessage: 'Information sent! Someone will be in touch with you shortly.',
        failureMessage: ErrorMessage.BookingError,
        confirm: true,
    },
    SubmitForm = {
        type: PromptType.Pending,
        title: 'Submitting information',
        successTitle: 'Information Submitted',
        successMessage: 'Information sent! Thank you.',
        failureMessage: ErrorMessage.BookingError,
        confirm: true,
    },
    RetrievingFavourites = {
        type: PromptType.Pending,
        title: 'Retrieving Favourites',
        successMessage: 'Success!',
        confirm: true,
    },
    Inactive = {
        type: PromptType.Confirm,
        title: 'Session about to expire',
        subtitle: 'Do you want to continue?',
        confirmMessage: 'Yes',
        cancelMessage: 'No',
        autoCancel: 10000,
        noDismiss: true,
        buttonProps: { large: true },
        className: 'inactive',
    },
    MediaUploading = {
        type: PromptType.Pending,
        title: 'Adding media',
        successMessage: 'Media added!',
        failureMessage: ErrorMessage.MediaUploadError,
        confirm: true,
    },
    ModelHomeDisclaimer = {
        type: PromptType.Confirm,
        title: 'Disclaimer',
        message: 'The following is an artistic rendering that shows many upgrade options and a generic view. Speak to a sales rep to discuss the standard finishes that are included. E. &. O. E.',
        confirmMessage: 'Okay',
        cancelMessage: null,
    },
    UpgradeTourDisclaimer = {
        type: PromptType.Confirm,
        title: 'Disclaimer',
        message: "This is an artist's concept of our upgraded kitchen finishes. The finishes shown are virtual and may differ from physical finishes. Actual finishes may vary, and we have more options available. Speak to our sales representative to learn more about upgrade finish options. E. & O. E.",
        confirmMessage: 'Okay',
        cancelMessage: null,
    },
    SpinDisclaimer = {
        type: PromptType.Confirm,
        title: 'Disclaimer',
        message: "The following is an artistic rendering only that provides an artist's concept of what the home may look like on {lot} and anything shown is subject to change. Please speak to your sales representative for more information. E. & O. E",
        confirmMessage: 'Okay',
        cancelMessage: null,
    },
    ErrorOccurred = {
        type: PromptType.Pending,
        title: 'Error Occured',
        message: ErrorMessage.LoginError,
        confirm: true,
    },
    RequestInfo = {
        type: PromptType.Form,
        title: 'Request Information',
    }
}

export enum CustomEventType {
    LogIn = 'logIn',
    LogInRequire = 'logInRequire',
    LogInPIN = 'logInPIN',
    LogOut = 'logOut',
    CloseAllMenus = 'closeAllMenus',
    ResetScroll = 'resetScroll',
    ShowPasswordToggle = 'showPasswordToggle',
    GeneratePassword = 'generatePassword',
    ForgotPassword = 'forgotPassword',
    Register = 'register',
    FocusMarker = 'focusMarker',
    ShowMapPopup = 'showMapPopup',
    HideMapPopup = 'hideMapPopup',
    NavigateProject = 'navigateProject',
    GoogleLogIn = 'googleLogin',
    FocusList = 'focusList',
    Resend = 'resend',
    HoverPlan = 'hoverPlan',
    RotateTo = 'rotateTo',
    FocusUnit = 'focusUnit',
    DebugMessage = 'debugMessage',
    ToggleListStyle = 'toggleListStyle',
    ResizeSidebar = 'resizeSidebar',
    SelectGeneric = 'selectGeneric',
    FocusUpgradeOption = 'focusUpgradeOption',
    RequestInfo = 'requestInfo',
    ScrollUp = 'scrollUp',
    ScrollDown = 'scrollDown',
    ScrollAway = 'scrollAway',
    ZoomIn = 'zoomIn',
    ZoomOut = 'zoomOut',
    CollapseMenus = 'collapseMenus',
    FocusMenu = 'focusMenu',
    MenuTutorial = 'menuTutorial',
    HistoryPush = 'historyPush',
    HistoryReplace = 'historyReplace',
    HistoryBack = 'historyBack',
    PauseVideo = 'pauseVideo',
    ClearSelection = 'clearSelection',
    ShowRequestInfo = 'showRequestInfo',
    SetField = 'setField',
    FilterApplied = 'filterApplied',
    ShowInfo = 'showInfo',
    ContentAction = 'contentAction',
    EditPoint = 'editPoint',
}

export enum ThumbSize {
    ExtraLarge = 1024,
    Large = 512,
    Medium = 256,
    Small = 128,
}

export enum PageType {
    Home = '',
    Info = 'info',
    About = 'about',
    AboutUs = 'about-us',
    Search = 'search',
    ScreenSaver = 'landing',
    Floorplan = 'floorplans',
    Favourite = 'favourites',
    Modelhome = 'modelhomes',
    Gallery = 'gallery',
    Sitemap = 'sitemap',
    Building = 'building',
    Upgrades = 'upgrades',
    Amenities = 'amenities',
    Neighbourhood = 'neighbourhood',
    Compare = 'compare',
    Admin = 'admin',
    Custom = 'custom',
    External = 'external',
    Launch = 'launch',
    Developments = 'developments',
    Tours = 'tours',
}
export enum AdminView {
    Profile = 'profile',
    Availability = 'availability',
    Pricing = 'pricing',
    Worksheets = 'worksheets',
    GeneralAnalytics = 'general-analytics',
    FloorplansAnalytics = 'floorplans-analytics',
    PagesAnalytics = 'pages-analytics',
    UsersAnalytics = 'users-analytics',
    BookingAnalytics = 'booking-analytics',
    SalesAnalytics = 'sales-analytics',
    NewsletterAnalytics = 'newsletter-analytics',
    Users = 'users',
    Builders = 'builders',
    Apps = 'apps',
    Organizations = 'organizations',
    Sitemap = 'sitemap',
    Spin = 'spin',
    Upgrades = 'upgrades',
    UpgradesConfig = 'upgrades-config',
    Units = 'units',
    Floorplans = 'floorplans',
    Modelhomes = 'modelhomes',
    Media = 'media',
    Gallery = 'gallery',
    General = 'general',
    Emails = 'emails',
    Logs = 'logs',
    Operations = 'operations',
}

export enum Analytics {
    Maps = 'maps',
    Availability = 'availability',
    General = 'general',
    Email = 'email',
    Floorplans = 'floorplans',
    Favourites = 'favourites',
    Pages = 'pages',
    Users = 'users',
    Leads = 'leads',
    Booking = 'booking',
    Sales = 'sales',
    Newsletter = 'newsletter',
    Upgrades = 'upgrades',
    Tracking = 'tracking',
}

export enum Theme {
    Theme1 = 'theme1',
    Theme2 = 'theme2',
    Theme3 = 'theme3',
    Homegyde = 'homegyde',
    ThePropertyLinq = 'thepropertylinq',
    WelcomeHome = 'welcomehome',
}

export enum AppMode {
    Online = 'online',
    Local = 'local',
}

export enum Images {
    Missing = 'missing'
}

export enum Availability {
    Available = 'Available',
    Unavailable = 'Unavailable',
    Sold = 'Sold',
}

export enum QueryType {
    Projects = 'projects',
    Floorplans = 'floorplans',
    Analytics = 'analytics',
    Upgrades = 'upgrades',
}

export enum ProjectFilter {
    Search = 'SearchProject',
    Builder = 'Builder',
    ProjectType = 'ProjectType',
    ProjectStatus = 'ProjectStatus',
    Organization = 'Organization',
}

export enum FloorplanFilter {
    BuildingType = 'buildingType',
    Beds = 'Beds',
    Baths = 'Baths',
    Sqft = 'Sqft',
    Price = 'Price',
    Search = 'Search',
    Favourites = 'Favourites',
    Unit = 'Unit',
    Building = 'Building',
    Series = 'series',
    Garage = 'Garage',
    Availability = 'Availability',
}

export enum UnitFilter {
    Exposure = 'Exposure',
    Floor = 'Floor',
    HideUnavailable = 'Hide Unavailable Plans',
}

export enum AnalyticsFilter {
    User = 'userId',
    Email = 'email',
    Name = 'name',
    Date = 'date',
    Session = 'sessionId',
    Client = 'clientId',
    Floorplan = 'floorplanId',
    Source = 'source',
    Device = 'device',
    DeviceType = 'deviceType',
    Resolution = 'resolution',
    Project = 'appId',
    Page = 'pageLink',
    Data = 'dataLink',
    Team = 'teamId',
    Referral = 'referralId',
    Hotjar = 'hotjarId',
    Facebook = 'facebookId',
    Google = 'googleId',
    Hubspot = 'hubspotId',
    EventType = 'analyticsEventTypeId',
    OptIn = 'optIn',
    Browser = 'browser',
    OS = 'os',
    DataEmail = 'dataEmail',
    DataName = 'dataName',
    DataMessage = 'dataMessage',
    UtmCampaign = 'utmCampaign',
    UtmSource = 'utmSource',
    UtmMedium = 'utmMedium',
    UtmContent = 'utmContent',
    UtmTerm = 'utmTerm',
    Organization = 'organizationId',
}

export const UserFilters = [
    AnalyticsFilter.User,
    AnalyticsFilter.Email,
    AnalyticsFilter.Name,
    AnalyticsFilter.Date,
    AnalyticsFilter.Floorplan,
]

export const SessionFilters = [
    AnalyticsFilter.Session,
    AnalyticsFilter.Client,
    AnalyticsFilter.Source,
    AnalyticsFilter.Device,
    AnalyticsFilter.DeviceType,
    AnalyticsFilter.Resolution,
    AnalyticsFilter.Team,
    AnalyticsFilter.Referral,
    AnalyticsFilter.Facebook,
    AnalyticsFilter.Google,
    AnalyticsFilter.Browser,
    AnalyticsFilter.OS,
    AnalyticsFilter.Hubspot,
    AnalyticsFilter.UtmCampaign,
    AnalyticsFilter.UtmSource,
    AnalyticsFilter.UtmMedium,
    AnalyticsFilter.UtmContent,
    AnalyticsFilter.UtmTerm,
]

export const SearchFilters = [
    AnalyticsFilter.Email,
    AnalyticsFilter.Name,
    AnalyticsFilter.Date,
    AnalyticsFilter.Hubspot,
    AnalyticsFilter.Team,
    AnalyticsFilter.Referral,
    AnalyticsFilter.Facebook,
    AnalyticsFilter.Google,

]

export const SessionAnalyticsFields = Object.keys(AnalyticsFilter).filter((x) => isNaN(x) && SessionFilters.includes(AnalyticsFilter[x])).map((key) => {
    if(key == AnalyticsFilter.UtmSource) {
        return { key: AnalyticsFilter[key], label: key?.toReadable(), map: 'sessionId', placeholder: 'Online' }

    } else {
        return { key: AnalyticsFilter[key], label: key?.toReadable(), map: 'sessionId' }
    }
})

export enum UpgradeFilter {
    BuildingType = 'buildingType',
    Unit = 'unit',
    Floorplan = 'floorplan',
    FloorplanVariation = 'floorplanVariation',
    Option = 'option',
    Component = 'component',
    Package = 'package',
    Tag = 'tag',
    Area = 'area',
    Department = 'department',
    Category = 'category',
    Subcategory = 'subcategory',
    Product = 'product',
    Tier = 'tier',
    OnlyChanged = 'onlyChanged',
    BedBath = 'bedBath',
    User = 'user',
    Field = 'field',
    From = 'from',
    To = 'to'
}

export enum Show {
    All = 'All',
    Available = 'Available',
}

export enum EmailType {
    General = 'general',
    Floorplan = 'floorplan',
    Favourites = 'favourites',
}

export enum Aggregation {
    Total = 'total',
    Average = 'average',
}

export enum AnalyticsSource {
    SalesCenter = 'Sales Center',
    Website = 'Website',
    ModelHome = 'Model Home',
    // SalesCenter = 'Sales Center',
    // Desktop = 'Desktop',
    // Mobile = 'Mobile',
    // VirtualReality = 'Virtual Reality',
}

export enum ListStyle {
    Grid = 'grid',
    List = 'list',
    Map = 'map',
}

export enum FieldType {
    String = 'string',
    Toggle = 'toggle',
    List = 'list',
    Option = 'option',
    Date = 'date',
    Media = 'media',
    Gallery = 'gallery',
    Compound = 'compound',
    Location = 'location'
}

export enum MarkerType {
    App = 'app',
    Organization = 'organization',
    Neighbourhood = 'neighbourhood',
    Circle = 'circle',
}

export enum FormType {
    Booking = 'booking',
}

export enum SpinQuality {
    None = -1,
    Still = 0,
    Low = 1,
    Med = 2,
    High = 3,
}

export const QualitySettings = {
    [SpinQuality.Low]: [5, 5],
    [SpinQuality.Med]: [4, 1],
    [SpinQuality.High]: [3, 1],
}

export enum ViewType {
    Spin = 'spin',
    Media = 'media',
}

export enum AnalyticsEvent {
    RequestInformation = 1,
    EventRegistration = 2,
    FillBooking = 3,
    ProjectRegistration = 4,
    UserRegister = 5,
    UserLogin = 6,
    UserLogout = 7,
}

export enum AnalyticsUpgradesEvent {
    OpenEmail = 1,
    ViewOrder = 2,
    BeginOrder = 3,
    AlterOrder = 4,
    CompleteOrder = 5,
}

export enum EditorFieldType {
    Fixed = 0,
    String = 1,
    Integer = 2,
    Float = 3,
    Checkbox = 4,
    Array = 5,
    Multi = 6,
    Media = 7,
    Custom = 8,
    Enum = 9,
    Price = 10,
    Vector = 11,
    TextField = 12,
    Location = 13,
    Gallery = 14,
    Icon = 15,
}

export enum FormFieldType {
    Text = 1,
    Number = 2,
    Phone = 3,
    Email = 4,
    Select = 5,
    Checkbox = 6,
    Radio = 7,
    Textarea = 8,
    Date = 9,
    Consent = 10
}

export enum NotificationStatus {
    Info = 'info',
    Pending = 'pending',
    Complete = 'complete',
    Error = 'error',
    Archive = 'archive',
}

export enum UpgradeOrderStatus {
    Ready = 0,
    Pending = 1,
    Progress = 2,
    Restricted = 3,
    Complete = 4,
    Signed = 5,
}

export enum UserOrderStatus {
    Ready = 0,
    Progress = 1,
    Complete = 2,
}

export enum CustomContentType {
    Blurb = 1,
    Accordian = 2,
    Features = 3,
    Event = 4,
    Highlight = 5,
    Form = 6,
    Header = 7,
    Splash = 8,
    Latest = 9,
    Search = 10,
    Numbered = 11,
    Developers = 12,
    Map = 13,
    RequestInfo = 14,
    Summary = 15,
    Tabbed = 16,
    Grid = 17,
    Neighbourhood = 18,
    Steps = 19,
    Items = 20,
    Media = 21,
    ProjectInfo = 22,
    Events = 23,
    Quotes = 24,
    Points = 25,
    Element = 26,
    ProjectInfo = 27,
    ScreenSaver = 28,
    Footer = 29
}


export enum Environment {
    Production = 'production',
    Development = 'development',
    Test = 'test',
}

export enum UpgradePricingMode {
    Hidden = 1,
    AdminOnly = 2,
    Public = 3
}

export enum Visibility {
    Hidden = 0,
    ReadOnly = 1,
    ReadWrite = 2,
    Filter = 3,
}

export enum UpgradeTier {
    Standard = 1,
    Upgrade = 2,
    Premium = 3,
}

export enum FAQType {
    General = 1,
    Upgrades = 2,
    UpgradesEmail = 3
}

export enum Alignment {
    Left = 'left',
    Right = 'right',
    Center = 'center',
    Justify = 'justify',
}

export enum LogType {
    Info = 'info',
    Error = 'error',
    Query = 'query',
}

export enum DocumentFormat {
    Letter = 1,
    Legal = 2,
}

export enum VideoSource {
    Vimeo = 0,
    YouTube = 1,
}

export enum AdminOperation {
    Backup = 'backup',
    Fix = 'fix',
    Break = 'break',
    HubspotSync = 'hubspotSync',
}

export enum ReCaptchaThreshold {
    Human = 0.7,
    Suspicious = 0.5,
    Bot = 0
}

export enum LocationType {
    Primary = 1,
    Neighbourhood = 2,
}
import React from 'react'
import { useAppSelector } from 'app/hooks'
import { RootState } from 'app/types'
import GalleryView from './GalleryView'
import { OptionBar } from 'components'

interface GalleryProps {
    app: AppData,
    dataLink: string,
}

export function GalleryPage(props: GalleryProps) {
    const { app, dataLink, splitIndex, ...otherProps } = props
    const { galleries } = app

    if (galleries.length === 0) {
        return <h3>No galleries found</h3>
    }
    let gallery = null
    if (dataLink) {
        gallery = galleries.find((x) => x.link == dataLink)
    }
    if (gallery == null) {
        gallery = galleries.find((x) => x.link == 'gallery')
    }
    if (gallery == null) {
        gallery = galleries[0]
    }
    return <React.Fragment>
        <OptionBar app={app} splitIndex={splitIndex} titleText={app.meta.name}/>
        <GalleryView app={app} gallery={gallery} {...otherProps} />
    </React.Fragment>
}

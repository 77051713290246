import React, { useState, useEffect, Suspense } from 'react'
import { useAppDispatch, useAppSelector, usePreviewMedia } from 'app/hooks'
import {
    Swipe,
    Media,
    IconButton,
    Timer,
    ZoomPanPinch,
    Spinner,
    Icon,
    MediaIcon,
} from 'components'

import PinchZoomPan from 'components/PinchZoomPan/PinchZoomPan'
import { AppData, CustomEventType, MediaType, PromptType, ScreenOrientation } from 'app/types'
import Spin from './Spin/Spin'
import { ModelhomeView } from 'views/ModelhomePage/ModelhomeView'
import { showPrompt } from 'actions/appActions'
import { Prompt } from 'react-router-dom'

interface SlideShowProps {
    app: AppData,
    organization: AppData,
    media: string[],
    startIndex?: number,
    mediaOptions?: { [key: string]: string },
    autoScroll?: number,
    padding?: number,
    cover?: boolean,
    showIndex?: boolean,
    onIndex: () => void,
    zoomable: boolean,
    showDots: boolean,
    showArrows: boolean,
    onClick: () => void,
    alt: boolean,
}

const SWIPE_TOLERANCE = 50
const CLICK_TOLERANCE = 25

export function SlideShow(props: SlideShowProps) {

    const { app, organization, media, startIndex, mediaOptions, autoScroll, padding, cover, showIndex, onIndex, zoomable, showDots, showArrows, alt, onClick, onAltMedia } = { zoomable: true, showIndex: true, showArrows: true, ...props }

    const dispatch = useAppDispatch()
    const [index, setIndex] = useState(startIndex != null ? startIndex : 0)
    const [swipe, setSwipe] = useState(0)
    const [zooming, setZooming] = useState(false)
    const [canSwipe, setCanSwipe] = useState(true)
    const [hasVideo, setHasVideo] = useState(false)
    const mediaLib = useAppSelector((state: RootState) => state.app.media)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const previewMedia = usePreviewMedia()

    useEffect(() => {
        setHasVideo(media.find((x) => {
            const m = mediaLib[x]
            return m && m.mediaTypeId == MediaType.Video
        }) != null)

        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    function getFixedIndex(idx) {
        const len = media ? media.length : 1
        let fixedIndex = idx % len
        if (fixedIndex < 0) {
            fixedIndex += Math.ceil(-fixedIndex / len) * len
        }
        return fixedIndex
    }

    function handleIndex(x) {
        setIndex(x)
        if (onIndex) {
            onIndex(x)
        }
    }

    function disableClick(e) {
        e.stopPropagation()
    }

    function handleClick(e) {
        if (e) {
            e.stopPropagation()
        }
        if (onClick) {
            onClick(media[getFixedIndex(index)], index)
        }
    }

    /*useEffect(() => {
        handleAutoScroll()
    }, [index])

    function handleAutoScroll() {
        if (!autoScroll) {
            return
        }
        clearTimeout(loopTimeout)
        setLoopTimeout(setTimeout(() => {
            handleNext()
        }, autoScroll))
    }*/

    function handleKeyDown(e) {
        switch (e.key) {
            default:
                break
            case 'ArrowLeft':
                handlePrev(true)
                break
            case 'ArrowRight':
                handleNext(true)
                break
        }
    }

    function stopAllVideos() {
        window.dispatchEvent(new CustomEvent(CustomEventType.PauseVideo))
    }

    function handleNext(force = false, e = null) {
        if (e) {
            e.stopPropagation()
        }
        if (!force && (zooming || !canSwipe)) {
            return
        }
        handleIndex(index + 1)
        if (hasVideo) {
            stopAllVideos()
        }
    }

    function handlePrev(force = false, e = null) {
        if (e) {
            e.stopPropagation()
        }
        if (!force && (zooming || !canSwipe)) {
            return
        }
        handleIndex(index - 1)
        if (hasVideo) {
            stopAllVideos()
        }
    }

    function handleSwipeMove(position: Position) {
        if (zooming || !canSwipe) {
            return
        }

        setSwipe(position.x)
    }

    function handleSwipeStart() {
        setCanSwipe(!zooming)
    }

    function handleSwipeEnd() {
        if (Math.abs(swipe) < CLICK_TOLERANCE) {
            handleClick()
        }
        setSwipe(0)
    }

    function handleZoom(zoom) {
        setZooming(zoom)
        setCanSwipe(false)
    }

    function handleAltMedia(e, m) {
        e.stopPropagation()
        if (onAltMedia) {
            onAltMedia(m)
        }
    }

    function getDots(focusIndex) {
        return <div className="slideshow-dots">
            {media.map((x, ix) => <IconButton className={`dot${ix == focusIndex ? ' selected' : ''}`} key={ix} icon={(ix == focusIndex || !alt) ? "fas fa-circle" : "far fa-circle"} onClick={() => handleIndex(ix)} />)}
        </div>
    }


    if (!media) {
        return null
    }

    let fixedIndex = getFixedIndex(index)
    const currentMedia = mediaLib[media[fixedIndex]]

    const nextIndex = index + 1//index < media.length - 1 ? index + 1 : 0
    const prevIndex = index - 1//index > 0 ? index - 1 : media.length - 1
    let nextMediaIndex = getFixedIndex(nextIndex)
    let prevMediaIndex = getFixedIndex(prevIndex)

    const percentSwipe = 100 * (swipe / window.innerWidth)
    const stylePrev = { transform: `translateX(${-100 + percentSwipe}%)` }
    const styleCurrent = { transform: `translateX(${0 + percentSwipe}%)` }
    const styleNext = { transform: `translateX(${100 + percentSwipe}%)` }

    if (swipe !== 0) {
        stylePrev.transition = 'none'
        styleCurrent.transition = 'none'
        styleNext.transition = 'none'
    }

    const maxZoom = 3
    let autoFitBuffer = padding
    if (autoFitBuffer == null) {
        autoFitBuffer = screen.orientation === ScreenOrientation.Portrait ? 20 : 100
    }

    const forceStatic = false

    let wrapperClass = 'slideshow-wrapper'
    if (cover) {
        wrapperClass = `${wrapperClass} cover`
    }

    function getElement(_index, mediaIndex, style, className, zoom = false, swipe = true) {
        const m = mediaLib[media[mediaIndex]]
        if (!zoom || (m && m.mediaTypeId == MediaType.Video)) {
            return <div key={_index} className={className} style={style}>
                <Media
                    app={app}
                    mediaId={media[mediaIndex]}
                    organization={organization}
                    // onClick={disableClick}
                    onMouseDown={media.length == 1 ? (e) => e.stopPropagation() : null}
                    spinner
                    fadeIn
                    focus={index == _index}
                    {...mediaOptions}
                />
            </div>
        }
        if (m && (m.mediaTypeId == MediaType.Spin || m.mediaTypeId == MediaType.Tour)) {
            const pMedia = previewMedia(app, m)
            let mediaId = pMedia.id
            let subtitle = "Click to view"
            if (m.mediaTypeId == MediaType.Spin) {
                const spin = app.spins.find((x) => x.views.find((y) => y.mediaId == m.id))
                if (spin) {
                    subtitle = `${subtitle} 360 spin of ${spin.name}`
                }
            } else if (m.mediaTypeId == MediaType.Tour) {
                const modelhome = app.modelhomes.find((x) => x.mediaId == m.id)
                if (modelhome) {
                    subtitle = `${subtitle} virtual tour of the ${modelhome.name}`
                }
            }
            return <div key={_index} className={`${className} slideshow-preview`} style={style}>
                <div className="center">
                    <MediaIcon media={m} />
                    <h3 className="animate__animated animate__fadeInUp">{subtitle}</h3>
                    <Media
                        app={app}
                        mediaId={mediaId}
                        organization={organization}
                        onClick={(e) => handleAltMedia(e, m)}
                        // onMouseDown={disableClick}
                        // onMouseUp={disableClick}
                        // onClick={disableClick}
                        spinner
                        fadeIn />
                </div>
            </div>
        }
        // if (m && m.mediaTypeId == MediaType.Spin) {
        //     if(index != _index) {
        //         return null
        //     }
        //     const spin = app.spins.find((x) => x.views.find((y) => y.mediaId == m.id))
        //     if (spin) {

        //         return <Suspense fallback={<Spinner />}>
        //             <Spin
        //                 app={app}
        //                 spinLink={spin.link}
        //                 viewLink={spin.views.find((x) => x.mediaId == m.id)?.link}
        //                 disclaimer={true}
        //                 inPrompt />
        //         </Suspense>
        //     }
        //     return null
        // }
        // if (m && m.mediaTypeId == MediaType.Tour) {
        //     if(index != _index) {
        //         return null
        //     }
        //     let modelhome = app.modelhomes.find((x) => {
        //         if (m.id) {
        //             return x.mediaId == m.id
        //         } else {
        //             return x.link == m.link
        //         }
        //     })
        //     if (modelhome) {
        //         return <ModelhomeView app={app} dataLink={modelhome.link} inPrompt />
        //     }
        //     return null
        // }
        return <PinchZoomPan
            key={_index}
            options={[]}
            className={className}
            style={style}
            position='center'
            maxZoom={maxZoom}
            autoFitBuffer={padding}
            zoomButtons={false}
            onZoom={handleZoom}
            cancelMouseDown={!swipe}
            cover={cover}>
            <Media
                app={app}
                organization={organization}
                mediaId={media[mediaIndex]}
                onClick={disableClick}
                spinner
                fadeIn
                {...mediaOptions} />
        </PinchZoomPan>
    }

    // Slideshow
    if (media.length > 1) {
        if (zoomable && !forceStatic) {
            return <React.Fragment>
                <Swipe className="slideshow" onSwipeLeft={() => handleNext(false)} onSwipeRight={() => handlePrev(false)} onSwipeStart={handleSwipeStart} onSwipeEnd={handleSwipeEnd} onSwipeMove={handleSwipeMove} allowMouseEvents tolerance={SWIPE_TOLERANCE}>
                    {getElement(prevIndex, prevMediaIndex, stylePrev, wrapperClass, true)}
                    {getElement(index, fixedIndex, styleCurrent, wrapperClass, true)}
                    {getElement(nextIndex, nextMediaIndex, styleNext, wrapperClass, true)}
                    {showIndex && <div key="index" className="slideshow-index noselect"><span>{fixedIndex + 1} / {media.length}</span></div>}
                    {showArrows && <IconButton key="next" noBg onClick={(e) => handleNext(true, e)} onMouseDown={(e) => e.stopPropagation()} onTouchEnd={(e) => e.stopPropagation()} className="slideshow-arrow-next" icon="fas fa-chevron-right" />}
                    {showArrows && <IconButton key="prev" noBg onClick={(e) => handlePrev(true, e)} onMouseDown={(e) => e.stopPropagation()} onTouchEnd={(e) => e.stopPropagation()} className="slideshow-arrow-prev" icon="fas fa-chevron-left" />}
                </Swipe>
                {autoScroll && <Timer index={fixedIndex} duration={autoScroll} loop onComplete={handleNext} />}
            </React.Fragment>
        }
        return <React.Fragment>
            <Swipe className="slideshow" onSwipeLeft={() => handleNext(false)} onSwipeRight={() => handlePrev(false)} onSwipeStart={handleSwipeStart} onSwipeEnd={handleSwipeEnd} onSwipeMove={handleSwipeMove} allowMouseEvents>
                {getElement(prevIndex, prevMediaIndex, stylePrev, `${wrapperClass} static`, false)}
                {getElement(index, fixedIndex, styleCurrent, `${wrapperClass} static`, false)}
                {getElement(nextIndex, nextMediaIndex, styleNext, `${wrapperClass} static`, false)}
                {showIndex && <div key="index" className="slideshow-index noselect"><span>{fixedIndex + 1} / {media.length}</span></div>}
                {showArrows && <IconButton key="next" noBg onClick={(e) => handleNext(true, e)} onMouseDown={(e) => e.stopPropagation()} onTouchEnd={(e) => e.stopPropagation()} className="slideshow-arrow-next" icon="fas fa-chevron-right" />}
                {showArrows && <IconButton key="prev" noBg onClick={(e) => handlePrev(true, e)} onMouseDown={(e) => e.stopPropagation()} onTouchEnd={(e) => e.stopPropagation()} className="slideshow-arrow-prev" icon="fas fa-chevron-left" />}
            </Swipe>
            {autoScroll && <Timer index={fixedIndex} duration={autoScroll} loop onComplete={handleNext} />}
            {showDots && getDots(fixedIndex)}
        </React.Fragment>
    }


    if (!currentMedia) {
        return <h3>Missing Media</h3>
    }
    if (zoomable && !forceStatic) {
        return <div className="slideshow" onClick={handleClick}>
            {getElement(index, fixedIndex, styleCurrent, wrapperClass, true, false)}
        </div>
    }

    return <div className="slideshow" onClick={handleClick}>
        {getElement(index, fixedIndex, { ...styleCurrent, justifyContent: 'center' }, wrapperClass, false, false)}
    </div>
}
import React, { useEffect, useState, useRef, Suspense } from 'react'
import {
    showPrompt,
    addProjectMedia,
    resolvePrompt,
} from 'actions/appActions'
import {
    CloseButton,
    Media,
    TopBar,
    Button,
    IconButton,
    Spinner,
    Input,
    DropdownMenu,
    MediaTile,
    Message,
} from 'components'
import {
    ErrorMessage,
    PromptOptions,
    PromptType,
    MediaType,
    ThumbSize,
    MediaTagData,
    AdminView,
    FieldType,
    LocationType,
} from 'app/types'
import { createLocation, addMedia, addMediaFiles, removeMedia, pollJob, startJob, updateMedia, addMediaTag, removeMediaTag, generateMediaThumbnails, exportMediaFile, removeLocation } from 'actions/adminActions'
import { useAppDispatch, useAppSelector, useLocalStorage } from 'app/hooks'
import { getSetCached } from 'app/state'
import { FileUploader } from 'react-drag-drop-files'
import { parseData } from 'helpers/requestHandler'
import { getMediaLink } from 'helpers/media'
import SpinPreview from 'components/Spin/SpinPreview'
import * as fnc from 'helpers/fnc'
import { logger } from 'helpers/logger'
import { ModelhomeView } from 'views/ModelhomePage/ModelhomeView'
import { icons, Provinces, States } from 'app/constants'
import { AdminEditor } from 'views/AdminPage/AdminEditor'
import { setSpinError } from 'actions/spinActions'

const locationFields = (editData: Dict) => ({
    name: {
        type: FieldType.String,
        label: 'Name',
        name: 'name',
    },
    country: {
        type: FieldType.Option,
        label: 'Country Code',
        options: ['CA', 'US'],
    },
    numberAndStreet: {
        type: FieldType.String,
        label: 'Street and Number',
        name: 'address-line-1',
    },
    unit: {
        type: FieldType.String,
        label: 'Unit',
        name: 'address-line-2',
    },
    city: {
        type: FieldType.String,
        label: 'City',
        name: 'address-line-3',
    },
    provinceState: {
        type: FieldType.Option,
        label: 'Province / State',
        options: !editData || editData['country'] === 'CA' ? Provinces : States,
    },
    postalZipCode: {
        type: FieldType.String,
        label: 'Postal / Zip Code',
        name: 'postal-code',
    },
    note: {
        type: FieldType.String,
        label: 'Note',
        name: 'note',
    },
    latLng: {
        type: FieldType.String,
        label: 'Latitude and Longitude',
    },
})

export const defaultLocation = {
    name: null,
    numberAndStreet: null,
    unit: null,
    city: null,
    provinceState: null,
    country: null,
    postalZipCode: null,
    latLng: null,
    note: null,
    locationTypeId: LocationType.Primary
}


interface LocationDialogProps {
    // app: AppData,
    // builder: BuilderData,
    // organization: OrganizationData,
    onClose?: () => void,
    onSelect: () => void,
    onDisableClose: () => void,
    animation?: string,
    selected: string,
    locationTypeId: number,
    select: boolean,
}

export function LocationDialog(props: LocationDialogProps) {
    const {
        app,
        builder,
        // organization,
        onClose,
        onSelect,
        onDisableClose,
        animation,
        // selected,
        select,
        locationTypeId,
    } = { select: true, locationTypeId: LocationType.Primary, ...props }
    const config = useAppSelector((state: RootState) => state.admin.config)
    const dispatch = useAppDispatch()
    const [data, setData] = useState({ ...defaultLocation })
    const [addingLocation, setAddingLocation] = useState(false)
    const [error, setError] = useState(null)
    const [locations, setLocations] = useState([])
    const organization = useAppSelector((state: RootState) => props.organization ? (state.app.organization || props.organization) : null)
    const project = useAppSelector((state: RootState) => state.app.projectCurrent[0])
    const source = project ? project : organization
    const [selected, setSelected] = useState(props.selected ? source.locations.find((x) => x.id == props.selected) : null)

    const fields = locationFields(data)

    useEffect(() => {
        if (source) {
            if (locationTypeId) {
                setLocations(source.locations.filter((x) => {
                    if (Array.isArray(locationTypeId)) {
                        return locationTypeId.includes(x.locationTypeId)
                    } else {
                        return x.locationTypeId == locationTypeId
                    }
                }))
            } else {
                setLocations(source.locations)
            }
        }
    }, [source])

    function handleClose() {
        onClose()
    }

    function handleAdd() {
        const newLocation = { ...defaultLocation }
        if (locationTypeId) {
            if (Array.isArray(locationTypeId)) {
                // Set to max type
                newLocation.locationTypeId = locationTypeId.reduce((a, b) => Math.max(a, b))
            } else {
                newLocation.locationTypeId = locationTypeId
            }
        }
        setData(newLocation)
        setAddingLocation(true)
        setError(null)
    }

    function handleChange(field: string, value: any) {
        setData({ ...data, [field]: value })
    }
    function handleToggle(x) {
        if (selected && selected.id == x.id) {
            setSelected(null)
        } else {
            setSelected(x)
        }
    }

    function handleOperation(location, op) {
        if (op == 'edit') {
            setData(location)
            setAddingLocation(true)
        } else if (op == 'delete') {
            dispatch(showPrompt({
                type: PromptType.Confirm,
                message: `Are you sure you want to delete ${location.name}?`,
            })).then((x) => {
                if (x.payload) {
                    dispatch(removeLocation({ app, organization, data: location }))

                }
            })
        }
    }

    function handleSelect() {
        dispatch(resolvePrompt(selected?.id))
    }

    function handleSubmit() {
        dispatch(createLocation({ app, organization, data }))
            .then((x) => {
                if (x.meta.requestStatus === 'fulfilled') {
                    setAddingLocation(false)
                    setData({})
                    setSelected(x.payload.id)
                } else {
                    setError(x.payload.error)
                }
            })
    }

    return <React.Fragment>
        <div className={`location-browser ${animation ? animation : ''}`} onMouseDown={(e) => e.stopPropagation()}>
            <TopBar>
                <div className="options left">
                    <h4>{app ? `${app.meta.name} Locations` : `Locations`}</h4>
                </div>
                <div className="options right">
                    <Button alt icon={icons.plus} onClick={handleAdd}>Add Location</Button>
                    {onClose && <CloseButton fadeIn onClick={handleClose} />}
                </div>
            </TopBar>
            <div className="row locations scrollable">
                <div className="column">
                    {addingLocation && <form className="form">
                        <table className="admin-table">
                            <tbody>
                                {error && <tr>
                                    <td colSpan={2}>
                                        <Message type="error" error={error} />
                                    </td>
                                </tr>}
                                {Object.keys(fields).map((key, index) => {
                                    const field = fields[key]
                                    let inputElem = null
                                    if (field.type == FieldType.Option) {
                                        inputElem = <DropdownMenu key={index} placeholder="Select" items={field.options.map((x) => ({ text: x.toReadable(), value: x }))} value={data[key]} onChange={(x) => handleChange(key, x)} />
                                    } else {
                                        inputElem = <Input key={index} value={data[key]} onChange={(x) => handleChange(key, x)} />
                                    }
                                    return <tr key={index}>
                                        <th>{field.label}</th>
                                        <td>{inputElem}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <Button tertiary onClick={handleSubmit}>Submit</Button>
                    </form>}
                    {!addingLocation && locations?.map((location, index) => {
                        const isSelected = selected?.id == location.id
                        return <span className="row location-item">
                            <Button icon={select ? (isSelected ? icons.check : icons.circle) : null} noBg key={index} className={isSelected ? 'selected' : ''} disabled={!select} onClick={select ? () => handleToggle(location) : null}>{location.name}</Button>
                            <DropdownMenu icon={icons.ellipsisV} buttonClass="no-bg" items={[
                                { text: 'Edit', value: 'edit' },
                                { text: 'Delete', value: 'delete' },
                            ]} onChange={(x) => handleOperation(location, x)} />
                        </span>
                    })}
                    {select && !addingLocation && locations?.length > 0 && <Button icon={icons.check} className="select-button" tertiary onClick={() => handleSelect()}>Select</Button>}
                    {select && !addingLocation && locations?.length > 0 && !selected && <h3>Select a location</h3>}
                    {!addingLocation && locations?.length == 0 && <Message>
                        <Button icon={icons.plus} tertiary onClick={handleAdd}>Add Location</Button>
                    </Message>}
                </div>
            </div>
        </div>
    </React.Fragment>
}

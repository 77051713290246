import React from 'react'
import {
    useRouteMatch,
    Route,
    Switch,
} from 'react-router-dom'
import { PageType, RootState, ScreenOrientation } from 'app/types'
import { navigateAsync, navigateBack } from 'actions/appActions'
import { Media, Tile, OptionBar } from 'components'

import { ModelhomeList } from './ModelhomeList'
import { ModelhomeView } from './ModelhomeView'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { logger } from 'helpers/logger'
import * as fnc from 'helpers/fnc'
import { projectContent } from 'app/transformers'

interface ModelhomePageProps {
    app: AppData,
    onlyFavourites: boolean,
    dataLink: string,
    splitIndex: number,
    breadCrumbs?: boolean,
}
export function ModelhomePage(props: ModelhomePageProps) {
    const { app, pageLink, dataLink, extraLink, splitIndex } = props
    const dispatch = useAppDispatch()
    const media = useAppSelector((state: RootState) => state.app.media)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const tags = [...new Set(app.modelhomes.map((x) => x.tag).filter((x) => x))].sort((a, b) => a.localeCompare(b))
    const links = useAppSelector((state: RootState) => state.app.links)

    function handleModelhome(x) {
        dispatch(navigateAsync({
            app: app,
            pageType: PageType.Modelhome,
            options: { modelhomeId: x.id, splitIndex },
        })).catch(logger.error)
    }
    function handleBack() {
        dispatch(navigateBack())
        /*dispatch(navigateAsync({
            app: app,
            pageType: PageType.Modelhome,
            options: { splitIndex },
        })).catch(logger.error)*/
    }

    function handleFullscreen(x) {
        if (screen.fullscreenSupport) {
            fnc.toggleFullscreen(x)
        } else {
            dispatch(toggleFullscreen())
        }
    }

    function getCustomContent() {
        const Content = projectContent(app)
        if (Content) {
            return <Content app={app} link={links.pageLink} />
        }
    }

    let tiling = 4
    if (screen.isMobile) {
        tiling = screen.orientation == ScreenOrientation.Portrait ? 2 : 4
    }

    if (dataLink) {
        return <ModelhomeView app={app} dataLink={dataLink} splitIndex={splitIndex} onClose={app.modelhomes.length > 1 ? handleBack : null} onFullscreen={handleFullscreen} />
    } else if (app.modelhomes.length == 1) {
        return <ModelhomeView app={app} dataLink={app.modelhomes[0].link} splitIndex={splitIndex} onFullscreen={handleFullscreen} />
    } else if (app.modelhomes.length > 1) {
        return <React.Fragment>
            <OptionBar app={app} splitIndex={splitIndex} titleText={"Select a Virtual Tour"} />
            <div id="modelhomes-view" className="content scrollable">
                <div className={`content-wrapper modelhome-list tile-list by-${tiling}`}>
                    {tags.length < 2 && [...app.modelhomes].sort((a, b) => a.link.localeCompare(b.link)).map((x) => {
                        return <Tile square onClick={() => handleModelhome(x)} app={app} media={media[x.previewMediaId]} title={x.name} subtitle={x.subtitle} splitTitle />
                    })}
                    {tags.length >= 2 && tags.map((tag) => {
                        const matchingModelhomes = app.modelhomes.filter((x) => x.tag == tag)
                        let fixedTag = tag
                        if (parseInt(tag[0]) > 0) {
                            // Remove first character
                            fixedTag = tag.slice(1, tag.length)
                        }
                        return <div className="modelhome-group column">
                            <div className="row">
                                <h3><p>{fixedTag}</p></h3>
                            </div>
                            {/* <div className="divider" /> */}
                            <div className="row">
                                {matchingModelhomes.sort((a, b) => a.link.localeCompare(b.link)).map((x) => {
                                    return <Tile square onClick={() => handleModelhome(x)} app={app} media={media[x.previewMediaId]} title={x.name} subtitle={x.subtitle} splitTitle />
                                })}
                            </div>
                        </div>
                    })}
                </div>
                {getCustomContent()}
            </div>
        </React.Fragment>

    }

    return <h3>No model homes found</h3>
}

import React, { useEffect, useState, useRef } from 'react'

import { AppData, CustomEventType, SpinViewStateData, SpinElementType, Dict } from 'app/types'
import * as fnc from 'helpers/fnc'
// import { setConfig } from 'actioinetActions'
import { Model, useEngine } from 'react-babylonjs'
import { Vector3, Color3, StandardMaterial } from '@babylonjs/core'

import { SceneLoader } from '@babylonjs/core/Loading'

import { getMediaUrl } from 'helpers/config'
import { registerMeshInput, getElementIcon } from './babylon'
import { SpinConstants } from './SpinConstants'
import { SpinIcon } from './SpinIcon'

interface SpinModelProps {
    app: AppData,
    view: React.RefObject,
    viewState: SpinViewStateData,
    index: number,
    focusSet: string,
    focusUnit: string,
    highlightLayer,
    disableHover: boolean,
    forceHighlight: boolean,
    showUnits: boolean,
    showFeatures: boolean,
    favourites: Dict,
    filteredUnits: Dict,
    media: Dict,
    updateKey: string,
    admin: boolean,
    onLoaded: () => void,
    interactive: boolean,
    building: string,
}

export function SpinModel(props: SpinModelProps) {
    const {
        app,
        view,
        viewState,
        index,
        focusSet,
        focusUnit,
        highlightLayer,
        disableHover,
        forceHighlight,
        favourites,
        filteredUnits,
        media,
        updateKey,
        admin,
        interactive,
        building,
    } = { forceHighlight: false, ...props }
    const [meshes, setMeshes] = useState(null)
    const [initialized, setInitialized] = useState(false)
    const [prevFocus, setPrevFocus] = useState(null)
    const meshesAlt = useRef([])
    const engine = useEngine()
    function getMeshPath() {
        // Transitioning to media objects
        if (view.current.meshMediaId in media) {
            const meshUrl = `${getMediaUrl(app)}/`
            return { url: meshUrl, name: media[view.current.meshMediaId].link.trim() }
        }
        return { url: null, name: '' }
    }

    let { url, name } = getMeshPath()

    function excludeMesh(x) {
        x.onBeforeRenderObservable.add(() => {
            engine.setColorWrite(false)
        })
        x.onAfterRenderObservable.add(() => {
            engine.setColorWrite(true)
        })
    }

    function includeMesh(x) {
        x.onBeforeRenderObservable.clear()
        x.onAfterRenderObservable.clear()
    }

    useEffect(() => {
        return () => {
            // Dispose of meshes
            meshesAlt.current.forEach((x) => {
                x.dispose()
            })
            meshesAlt.current = null
        }
    }, [])
    useEffect(() => {
        if (window) {
            window.addEventListener(CustomEventType.HoverPlan, handleEventHover)
        }

        return () => {
            if (window) {
                window.removeEventListener(CustomEventType.HoverPlan, handleEventHover)
            }
        }
    }, [view])


    /**
     * Update rendering
     */
    useEffect(() => {
        if (!meshes || !view || !highlightLayer) {
            return
        }
        const createClone = (x, group) => {
            const clone = x.clone(`${x.name}_occlude`)
            clone.isPickable = false
            clone.renderingGroupId = group
            const cloneMat = new StandardMaterial(`mat_${x.name}_clone`, x.getScene())
            cloneMat.backFaceCulling = true
            cloneMat.alpha = 1
            clone.material = cloneMat
            meshesAlt.current.push(clone)
            excludeMesh(clone)
            x.occlude[group] = clone
        }

        function setupElement(x) {
            x.spinElementTypeId = SpinElementType.None

            let element = null
            // See if it is part of the spin definitions
            if (x.elementId in viewState.elementMap) {
                element = viewState.elementMap[x.elementId]
                x.unitId = x.elementId
                // Extract mesh information from the element definition
                x.spinElementTypeId = element.spinElementTypeId

                if (x.spinElementTypeId == SpinElementType.Unit) {
                    x.unitId = element && element.linkOverride ? element.linkOverride : x.elementId
                    if (building) {
                        x.building = building
                        x.unitId = `${building}-${x.unitId}`
                    }
                }
            }

            x.isPickable = true
            x.focus = x.focusUnit && x.focusUnit == x.unitId
            x.material.alpha = x.alpha = admin || x.spinElementTypeId == SpinElementType.Unit || x.spinElementTypeId == SpinElementType.View || x.spinElementTypeId == SpinElementType.None ? 1 : 0
            x.visibility = 1
            let hide = !admin && (x.spinElementTypeId == SpinElementType.None || (x.spinElementTypeId == SpinElementType.Unit && !getUnitAvailable(x)))

            // Create dead unit
            if (hide) {
                x.isPickable = false
                x.available = false
                x.renderingGroupId = SpinConstants.RENDERING_GROUP_DEFAULT
                x.material.emissiveColor = Color3.Red()
                x.material.alpha = 1
                x.visibility = 0
            } else if (element) {
                // Live units have icons
                x.icon = getElementIcon(element, x, media)
            }

            if (x.spinElementTypeId == SpinElementType.None && !admin) {
                x.isPickable = false
                x.disabled = true
            }

            switch (x.spinElementTypeId) {
                case SpinElementType.Unit:
                    x.statusColor = x.color = getUnitStatusColor(x)
                    break
                case SpinElementType.Feature:
                    x.color = Color3.Blue()
                    break
                default:
                case SpinElementType.View:
                case SpinElementType.None:
                    x.color = new Color3(1, 1, 1)
                    break
            }
            x.material.diffuseColor = x.color
            x.material.emissiveColor = x.color
        }

        function setupInput(x) {
            if (x.isPickable && interactive) {
                x.interactive = true
                if (x.cleanupAction) {
                    x.cleanupAction()
                }
                const cleanUp = registerMeshInput(x,
                    (_) => {
                        hoverMesh(x, true, false, true)
                    },
                    (_) => {
                        hoverMesh(x, false, false, false)
                    },
                )
                x.cleanupAction = cleanUp
            } else {
                x.interactive = false
            }
        }


        meshes.forEach((x, ix) => {
            x.scaling = (new Vector3(-view.current.scale, view.current.scale, view.current.scale))
            // One time pre-setup
            if (!x.initialized) {
                // All common element standard
                x.elementId = x.unitId = x.id
                x.renderingGroupId = SpinConstants.RENDERING_GROUP_DEFAULT
                x.available = true
                x.isPickable = true
                x.building = null
                x.hover = false
                x.directHover = false
                x.disabled = false
                x.interactive = false

                if (view.current.flipNormals) {
                    x.flipFaces()
                }

                const mat = new StandardMaterial(`mat_${x.name}`, x.getScene())
                mat.backFaceCulling = true
                x.material = mat

                // Create clone for occluding
                x.occlude = {}
                createClone(x, SpinConstants.RENDERING_GROUP_ICON)
                createClone(x, SpinConstants.RENDERING_GROUP_HIGHLIGHT)
            }

            // Element specific setup
            if (!x.initialized || x.building != building || admin) {
                setupElement(x)
            }
            if (x.interactive != interactive || admin) {
                setupInput(x)
            }

            // Post setup
            if (!x.initialized) {
                hoverMesh(x, true, true, false)
            }

            x.initialized = true
        })

        if (!initialized) {
            setTimeout(() => {
                setInitialized(true)
            }, 100)
        }
    }, [meshes, view, highlightLayer, disableHover, forceHighlight, focusSet, favourites, updateKey, interactive, building])

    /**
     * Update focus
     */
    useEffect(() => {
        if (!meshes || (!focusUnit && !focusSet)) {
            return
        }

        let mesh = null
        if (focusUnit) {
            // Find mesh that matches
            if (focusUnit != prevFocus) {
                mesh = meshes.find((x) => x.unitId == focusUnit)
            }
        } else if (focusSet) {
            mesh = meshes.find((x) => {
                if (x.spinElementTypeId == SpinElementType.Unit) {
                    const unit = app.maps.unitName[x.unitName]
                    if (unit && focusSet.has(x.elementId)) {
                        const availability = app.meta.availabilityStates.find((x) => x.id == unit.availabilityStateId)
                        if (availability && availability.name.toLowerCase().includes('available')) {
                            return true
                        }
                    }
                }
                return false
            })
        }
        setPrevFocus(focusUnit)

        if (mesh && !admin && !viewState.focusUnit) {
            const bounds = mesh.getBoundingInfo().boundingBox
            window.dispatchEvent(new CustomEvent(CustomEventType.RotateTo, { detail: { position: bounds.centerWorld } }))
        }

    }, [meshes, focusSet, focusUnit])

    if (view == null) {
        return null
    }

    function handleEventHover(e) {
        if (!meshes) {
            return
        }
        const { hover, floorplanLink } = e.detail

        // Use mapping to determine
        const floorplan = app.maps.floorplanLink[floorplanLink]
        if (floorplan) {
            let unitSet = new Set(floorplan.units.map((x) => x.id))
            meshes.filter((x) => unitSet.has(x.elementId)).forEach((x) => {
                hoverMesh(x, hover)
            })
        }
    }

    function getUnitStatusColor(x) {
        const unit = app.maps.unitName[x.unitId]
        if (unit) {
            const floorplanLink = unit.floorplans[0]
            const floorplan = app.maps.floorplanLink[floorplanLink]
            const availability = app.meta.availabilityStates.find((x) => x.id == unit.availabilityStateId)
            if (availability) {
                const rgb = fnc.hexToRgb(availability.color)
                const color = new Color3(rgb.r / 255.0, rgb.g / 255.0, rgb.b / 255.0)
                return color
            } else {
                return Color3.Red();
            }
        }
        return Color3.Red()
    }

    function getUnitAvailable(x) {
        const unit = app.maps.unitName[x.unitId]
        if (unit) {
            const availability = app.meta.availabilityStates.find((x) => x.id == unit.availabilityStateId)
            if (availability && availability.name.toLowerCase() == 'available') {
                return true
            }
            return false
        }
        return true
    }

    function hoverMesh(x, hover, force = false, direct = false) {
        x.directHover = direct
        if (hover && (!disableHover || force)) {
            if (!x.hover && x.available) {

                // Add clone mesh to highlight layer
                const occ = x.occlude[SpinConstants.RENDERING_GROUP_HIGHLIGHT]
                if (occ) {
                    occ.material.alpha = 1
                    occ.visibility = 1
                    highlightLayer.addMesh(occ, Color3.White())
                }
                x.hover = hover
            } else if (x.focus && !x.prevFocus) {
                x.hover = hover
            }
        } else if (!hover && !forceHighlight) {
            if (x.hover && !x.focus) {

                // Remove clone mesh from highlight layer
                const occ = x.occlude[SpinConstants.RENDERING_GROUP_HIGHLIGHT]
                if (occ) {
                    highlightLayer.removeMesh(occ)
                    occ.material.alpha = 1
                }
                x.hover = hover
            }
        }
    }

    useEffect(() => {
        if (!meshes || !initialized) {
            return
        }
        meshes.filter((x) => x.available && x.initialized).forEach((x, ix) => {
            const newFocus = (x.unitId == focusUnit)
            if (newFocus != x.focus) {
                x.prevFocus = x.focus
                x.focus = newFocus
            }

            // If force highlight, show meshes
            // x.material.alpha = forceHighlight ? 1 : 0

            if (!x.directHover) { // Don't override mouse hover
                hoverMesh(x, x.focus || forceHighlight, true)
            }

            const useObservable = false

            // Filter
            const filterOut = filteredUnits && x.spinElementTypeId == SpinElementType.Unit && !filteredUnits.has(x.unitId)


            if (filterOut || (focusSet && focusSet.size > 0)) {
                const show = !filterOut && focusSet.has(x.unitId)
                x.material.alpha = show ? 1 : 0
                x.visibility = show ? 1 : 0
                // x.isPickable = show && !x.disabled
                // x.visibility = show ? 1 : 0
                /*if (x.available && useObservable) {
                    if (!show) {
                        x.onBeforeRenderObservable.add(() => engine.setColorWrite(false))
                        x.onAfterRenderObservable.add(() => engine.setColorWrite(true))
                    } else {
                        x.onBeforeRenderObservable.clear()
                        x.onAfterRenderObservable.clear()
                    }
                }*/
            } else {
                x.isPickable = !x.disabled
                x.visibility = 1
                x.material.alpha = x.alpha
                /*if (x.available && useObservable) {
                    x.onBeforeRenderObservable.clear()
                    x.onAfterRenderObservable.clear()
                }*/
            }
        })
    }, [initialized, meshes, focusSet ? Array.from(focusSet).join(',') : null, forceHighlight, filteredUnits ? Array.from(filteredUnits).join(',') : null, focusUnit])

    function loadComplete(x) {
        setMeshes(x.meshes)
        if (!x.meshes) {
            return null
        }

        // One more loop for mesh initialization on meshes-useEffect hook
        setTimeout(() => {
            if (props.onLoaded) {
                props.onLoaded(x.meshes)
            }
        }, 0)
        meshesAlt.current = [...meshesAlt.current, ...x.meshes]
    }

    function getIcons() {
        if (!meshes) {
            return null
        }
        let icons = meshes.filter((x) => x.icon != null)
            .map((x) => {
                return <SpinIcon
                    app={app}
                    key={x.id}
                    id={x.id}
                    scale={view.current.scale}
                    hover={x.hover && x.directHover}
                    onHover={(hover) => hoverMesh(x, hover)}
                    {...x.icon} />
            })
        return icons

    }

    if (index == null) {
        return null
    }

    return <React.Fragment>
        <Model key="highlight" rootUrl={url} sceneFilename={name} onModelLoaded={loadComplete}/>
        {getIcons()}
    </React.Fragment>
}

import React, { Suspense, useState } from 'react'
import { useAppDispatch, useAppSelector, useUserPermissions } from 'app/hooks'
import { FloorplanList } from './FloorplanList'
import { FloorplanView } from './FloorplanView'
import { PageType, UserRole } from 'app/types'
import { BuildingPage } from 'views/BuildingPage/BuildingPage'
import { ModelhomeView } from 'views/ModelhomePage/ModelhomeView'
const Spin = React.lazy(() => import('components/Spin/Spin'))
import * as fnc from 'helpers/fnc'
import { Spinner } from 'components'
import { toggleFullscreen } from 'actions/appActions'


interface FloorplanPageProps {
    app: AppData,
    onlyFavourites: boolean,
    dataLink: string,
    splitIndex: number,
    breadCrumbs?: boolean,
}
export function FloorplanPage(props: FloorplanPageProps) {
    const { app, pageLink, dataLink, extraLink, splitIndex, onlyFavourites } = props
    const userPermissions = useUserPermissions()
    const dispatch = useAppDispatch()
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const [showModelhome, setShowModelhome] = useState(null)
    const [showSpin, setShowSpin] = useState(null)

    function handleModelhome(x) {
        setShowModelhome(x)
        setShowSpin(null)
    }
    function handleSpin(x) {
        setShowSpin(x)
        setShowModelhome(null)
    }

    function handleFullscreen(x) {
        if (screen.fullscreenSupport) {
            fnc.toggleFullscreen(x)
        } else {
            dispatch(toggleFullscreen())
        }
    }

    // Only render spin if its published
    const spin = app.spins.find((x) => x.link == 'site')
    if (spin != null && (spin.published || userPermissions(UserRole.AppsEdit)) && !onlyFavourites) {
        return <BuildingPage pageType={PageType.Floorplan} {...props} />
    }

    if (showSpin) {
        const spin = app.spins.find((x) => x.id == showSpin)
        if (spin) {
            return <Suspense fallback={<Spinner overlay invert />}>
                <Spin
                    app={app}
                    spinLink={spin.link}
                    viewLink={spin.views[0].link}
                    splitIndex={null}
                    // focusSet={focusSet}
                    // splitView={true}
                    // filteredUnits={filteredUnits}
                    pageType={PageType.Floorplan}
                    onFullscreen={handleFullscreen}
                    disclaimer={true}
                    onClose={() => setShowSpin(null)}/>
            </Suspense>
        }
    } else if (showModelhome) {
        return <ModelhomeView app={app} dataLink={showModelhome} splitIndex={splitIndex} onFullscreen={handleFullscreen} onClose={() => setShowModelhome(null)} />
    } else if (props.dataLink) {
        return <FloorplanView key={0} onModelhome={handleModelhome} onSpin={handleSpin} {...props} />
    }
    return <FloorplanList key={0} onModelhome={handleModelhome} onSpin={handleSpin} {...props} />
}

import React from 'react'
import { Button, Message } from 'components'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { navigateAsync } from 'actions/appActions'
import { PageType } from 'app/types'

export function NoFavourites() {
    const dispatch = useAppDispatch()
    const standaloneOrg = useAppSelector((state: RootState) => state.app.standaloneOrg)
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const apps = useAppSelector((state: RootState) => state.app.config.apps)

    function handleProjects() {
        dispatch(navigateAsync({ options: { path: { baseRoute: PageType.Developments } } }))
    }

    function handleFloorplans() {
        const app = apps.find((x) => x.meta.id == organization.apps[0])
        if (app) {
            dispatch(navigateAsync({ app, pageType: PageType.Floorplan, options: { path: { baseRoute: null } } }))
        }
    }

    // TODO, better way to differentiate organization that has been configured to only show one app and one that only has one app
    if ((standaloneOrg && organization.link != 'thepropertylinq') || standaloneApp) {
        return <Message className="no-favourites">
            <div className="row">
                <h4>You haven&apos;t saved any floor plans yet!</h4>
            </div>
            <div className="row" style={{ justifyContent: 'center' }}>
                <div className="column col-4 fadeIn">
                    <h4>1</h4>
                    <h5>View floor plans
                        <i className="icon no-bg fas fa-vector-square" />
                    </h5>
                </div>
                <div className="column col-4 info-save fadeIn">
                    <h4>2</h4>
                    <h5>Press the
                        <i className="icon no-bg far fa-heart" />
                        {/*<span>
                            <i class="icon no-bg far fa-hand-point-up"></i>
                            <i className="icon no-bg far fa-heart" />
                            <i className="icon no-bg fas fa-arrow-right" />
                            <i className="icon no-bg fas fa-heart" style={{ color: 'var(--secondary-mid)' }} />
                        </span>*/}
                    </h5>
                </div>
            </div>
            <Button large tertiary onClick={handleFloorplans}>View Floorplans</Button>
        </Message>
    }

    return <Message className="no-favourites">
        <div className="row">
            <h4>You haven&apos;t saved any floor plans yet!</h4>
        </div>
        <div className="row">
            <div className="column col-4 fadeIn">
                <h4>1</h4>
                <h5>View a community
                    <i className="icon no-bg fas fa-map-marked" />
                </h5>
            </div>
            <div className="column col-4 fadeIn">
                <h4>2</h4>
                <h5>View floor plans
                    <i className="icon no-bg fas fa-vector-square" />
                </h5>
            </div>
            <div className="column col-4 info-save fadeIn">
                <h4>3</h4>
                <h5>Press the
                    <i className="icon no-bg far fa-heart" />
                    {/*<span>
                        <i class="icon no-bg far fa-hand-point-up"></i>
                        <i className="icon no-bg far fa-heart" />
                        <i className="icon no-bg fas fa-arrow-right" />
                        <i className="icon no-bg fas fa-heart" style={{ color: 'var(--secondary-mid)' }} />
                    </span>*/}
                </h5>
            </div>
        </div>
        <Button icon="fas fa-map-marked" large tertiary onClick={handleProjects}>Browse Communities</Button>
    </Message>
}
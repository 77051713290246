import React, { useEffect } from 'react'
import {
    AnalyticsEvent,
    AppData, CustomContentType, CustomEventType, FloorplanFilter, ProjectFilter, PromptOptions, ThumbSize,
} from 'app/types'
import {
    Media, SlideShow, WaterMark, Button, ScrollHelper,
} from 'components'
import { IntersectionHelper } from 'views/DynamicPage/IntersectionHelper'
import { Link } from 'react-router-dom'
import { showPrompt } from 'actions/appActions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RequestInfo } from 'views/AppPage/RequestInfo'
import { CustomContent } from './CustomContent'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { LocationLink } from 'views/HomePage/LocationLink'
import { referralSources } from 'app/constants'


export const TheSummitContentData = {
    'register': [
        {
            type: CustomContentType.Header,
            alt: true,
            // media: [{
                // galleryLink: 'highlight', index: 1
            // }]
            media: [{
                galleryLink: 'register',
            }]
        },
        {
            type: CustomContentType.Event,
            title: 'Register for The Summit or The Summit 2',
            dividerAfter: true,
        },
        {
            type: CustomContentType.Form,
            body: "Register below and we will send you more information about The Summit and The Summit 2",
            subtitle: "Fields marked with a * are required",
            requestType: AnalyticsEvent.EventRegistration,
            redirect: '#thankyou-register',
            formOptions: {
                submitMessage: 'Register',
                tags: ['register'],
            },
            fields: [
                {
                    id: 'firstname',
                    label: 'FIRST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'lastname',
                    label: 'LAST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'email',
                    label: 'EMAIL',
                    required: true,
                    size: 4,
                },
                {
                    id: 'phone',
                    label: 'PHONE',
                    required: true,
                    inputProps: { phone: true },
                    size: 2,
                },
                {
                    id: 'postalcode',
                    label: 'Postal Code',
                    required: false,
                    size: 1,
                    breakAfter: true,
                },
                {
                    id: 'howdidyouhear',
                    label: 'HOW DID YOU HEAR ABOUT US?',
                    dropdown: referralSources,
                    required: false,
                    other: true,
                    select: 'Select one option',
                    extra: 'How did you hear about us',
                    size: 1,
                },

                {
                    id: 'realtor',
                    label: 'ARE YOU A REALTOR?',
                    choice: ['Yes', 'No'],
                    required: false,
                    initial: 'No',
                    select: 'Select one option',
                    size: 1,
                },
                // {
                //     id: 'series',
                //     label: 'WHICH PROJECT ARE YOU INTERESTED IN?',
                //     dropdown: ['Both Projects', 'The Summit', 'The Summit 2', 'Not Sure'],
                //     required: false,
                //     other: true,
                //     initial: 'Both Projects',
                //     select: 'Select one option',
                //     size: 1,
                // },
                {
                    id: 'consent',
                    label: "By clicking this box or by completing this Registration Form for Primont, I expressly provide my consent to receive electronic messages from Primont and its affiliated companies retroactively, today and in the future for any projects by Primont. I understand that I may withdraw my consent by unsubscribing anytime.",
                    required: true,
                    size: 4,
                }
            ],
        },
    ],
    'thankyou-register': [
        {
            type: CustomContentType.Event,
            title: 'Register for The Summit or The Summit 2',
            dividerAfter: true,
        },
        {
            type: CustomContentType.Blurb,
            body: `Thank you for registering for the The Summit and The Summit 2!`,
            animated: false
        },
    ],
    'grand-opening': [
        {
            type: CustomContentType.Header,
            alt: true,
            // media: [{
                // galleryLink: 'highlight', index: 1
            // }]
            media: [{
                galleryLink: 'event',
            }]
        },
        {
            type: CustomContentType.Event,
            title: 'The Summit by Primont Grand Opening!',
            dividerAfter: true,
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    body: 'Saturday, June 29\n\n and Sunday, June 30',
                },
                {
                    title: 'TIME',
                    body: 'From 12 pm - 3 pm both days',
                }
            ],
        },
        {
            type: CustomContentType.Form,
            body: "The Property LinQ proudly announces the grand opening of The Summit and The Summit 2 by Primont!\n\nJoin us at our Collingwood location on the Canada Day long weekend for a complimentary artisan BBQ by Road Runner Foods of Collingwood, accompanied by craft brew by Black Bellows Brewing Company.\n\nRegister today to secure your spot! ",
            subtitle: "Fields marked with a * are required",
            requestType: AnalyticsEvent.EventRegistration,
            redirect: '#thankyou-grand-opening',
            formOptions: {
                submitMessage: 'Register',
                tags: ['summit-grand-opening-canadaday'],
            },
            fields: [
                {
                    id: 'firstname',
                    label: 'FIRST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'lastname',
                    label: 'LAST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'email',
                    label: 'EMAIL',
                    required: true,
                    size: 4,
                },
                {
                    id: 'phone',
                    label: 'PHONE',
                    required: true,
                    inputProps: { phone: true },
                    size: 2,
                },
                {
                    id: 'postalcode',
                    label: 'Postal Code',
                    required: false,
                    size: 1,
                    breakAfter: true,
                },
                {
                    id: 'howdidyouhear',
                    label: 'HOW DID YOU HEAR ABOUT US?',
                    dropdown: referralSources,
                    required: false,
                    other: true,
                    select: 'Select one option',
                    extra: 'How did you hear about us',
                    size: 1,
                },

                {
                    id: 'realtor',
                    label: 'ARE YOU A REALTOR?',
                    choice: ['Yes', 'No'],
                    required: false,
                    initial: 'No',
                    select: 'Select one option',
                    size: 1,
                },

                // {
                //     id: 'series',
                //     label: 'WHICH PROJECT ARE YOU INTERESTED IN?',
                //     dropdown: ['Both Projects', 'The Summit', 'The Summit 2', 'Not Sure'],
                //     required: false,
                //     other: true,
                //     initial: 'Both Projects',
                //     select: 'Select one option',
                //     size: 1,
                // },
                {
                    id: 'consent',
                    label: "By clicking this box or by completing this Registration Form for Primont, I expressly provide my consent to receive electronic messages from Primont and its affiliated companies retroactively, today and in the future for any projects by Primont. I understand that I may withdraw my consent by unsubscribing anytime.",
                    required: true,
                    size: 4,
                }
            ],
        },
    ],
    'thankyou-grand-opening': [
        {
            type: CustomContentType.Event,
            title: 'The Summit by Primont Grand Opening!',
            dividerAfter: true,
        },
        {
            type: CustomContentType.Blurb,
            body: `Thank you for registering for the The Summit by Primont Grand Opening!`,
            animated: false
        },
    ],
    
    'buydown-event': [
        {
            type: CustomContentType.Header,
            alt: true,
            // media: [{
                // galleryLink: 'highlight', index: 1
            // }]
            media: [{
                galleryLink: 'buydown',
                format: 1,
            }]
        },
        {
            type: CustomContentType.Event,
            title: 'The Summit Mortgage Buydown Event',
            dividerAfter: true,
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    body: 'August 3 - 5, 2024',
                },
                {
                    title: 'TIME',
                    body: '12 - 3pm',
                }
            ],
        },
        // {
        //     type: CustomContentType.Blurb,
        //     body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec',
        //     media: [{ galleryLink: 'buydown', index: 1}]
        // },
        // {
        //     type: CustomContentType.Blurb,
        //     body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam auctor, nunc nec',
        //     media: [{ galleryLink: 'buydown', index: 2, position: 1 }]
        // },
        {
            type: CustomContentType.Blurb,
            body: "With the launch of the community's mortgage buy down at a rate of 2.99% you can take advantage of reduced interest rates and low monthly mortgage payments, easier qualification criteria and greater long-term savings. It's the opportunity to build home equity quicker, to reach financial stability faster, and to own to your very own luxury aprés-ski home sooner.\n\nJoin us to learn more about The Summit's Mortgage Buy Down and take advantage of reduced interest rates and low monthly mortgage payments, easier qualification criteria and greater long-term savings.",
            media: [
                { galleryLink: 'buydown-additional', index: 0 },
            ]
        },
        {
            type: CustomContentType.Form,
            // body: "",
            // body: "2.99% Mortgage rate buydown incentive, exclusive VIP pricing, exclusivity of the neighbourhood, steps to georgian bay, georgian trail , bruce trail, georgian peaks, development at the top of the escarpment.\n\nSponsored by Road runner foods, Georgian vineyards, Primont, and Bosley Georgian Bay",
            subtitle: "Fields marked with a * are required",
            requestType: AnalyticsEvent.EventRegistration,
            redirect: '#thankyou-buydown-event',
            formOptions: {
                submitMessage: 'Register',
                tags: ['summit-buydown-event'],
            },
            fields: [
                {
                    id: 'firstname',
                    label: 'FIRST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'lastname',
                    label: 'LAST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'email',
                    label: 'EMAIL',
                    required: true,
                    size: 4,
                },
                {
                    id: 'phone',
                    label: 'PHONE',
                    required: true,
                    inputProps: { phone: true },
                    size: 2,
                },
                {
                    id: 'postalcode',
                    label: 'Postal Code',
                    required: false,
                    size: 1,
                    breakAfter: true,
                },
                {
                    id: 'howdidyouhear',
                    label: 'HOW DID YOU HEAR ABOUT US?',
                    dropdown: referralSources,
                    required: false,
                    other: true,
                    select: 'Select one option',
                    extra: 'How did you hear about us',
                    size: 1,
                },

                {
                    id: 'realtor',
                    label: 'ARE YOU A REALTOR?',
                    choice: ['Yes', 'No'],
                    required: false,
                    initial: 'No',
                    select: 'Select one option',
                    size: 1,
                },

                // {
                //     id: 'series',
                //     label: 'WHICH PROJECT ARE YOU INTERESTED IN?',
                //     dropdown: ['Both Projects', 'The Summit', 'The Summit 2', 'Not Sure'],
                //     required: false,
                //     other: true,
                //     initial: 'Both Projects',
                //     select: 'Select one option',
                //     size: 1,
                // },
                {
                    id: 'consent',
                    label: "By clicking this box or by completing this Registration Form for Primont, I expressly provide my consent to receive electronic messages from Primont and its affiliated companies retroactively, today and in the future for any projects by Primont. I understand that I may withdraw my consent by unsubscribing anytime.",
                    required: true,
                    size: 4,
                }
            ],
        },
        {
            type: CustomContentType.Blurb,
            body: "*Maximum mortgage amount up to $1 Million. Based on 40% down payment and subject to Lender's approval, additional terms and conditions apply. Cannot be combined with other promotions. Pricing and specifications subject to change without notice. Brokers protected. See Sales Representative for further details. E.&O.E."
        }
    ],
    'thankyou-buydown-event': [
        {
            type: CustomContentType.Event,
            title: 'The Summit Mortgage Buydown Event',
            dividerAfter: true,
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    subtitle: 'August 3 - 5, 2024',
                },
                {
                    title: 'TIME',
                    subtitle: '12 - 3pm',
                }
            ],
        },
        {
            type: CustomContentType.Blurb,
            body: `Thank you for registering for the The Summit Buydown Event`,
            animated: false
        },
    ],
    'primont-labour-day-event': [
        {
            type: CustomContentType.Header,
            alt: true,
            // media: [{
                // galleryLink: 'highlight', index: 1
            // }]
            media: [{
                galleryLink: 'labourday',
                format: 1,
            }]
        },
        {
            type: CustomContentType.Event,
            title: 'Primont Labour Day Event',
            dividerAfter: true,
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    body: 'August 31st - September 2nd, 2024',
                },
                {
                    title: 'TIME',
                    body: '12 - 3pm',
                }
            ],
        },
        {
            type: CustomContentType.Blurb,
            body: "With a mortgage buy down at a rate of 2.99% you can take advantage of reduced interest rates and low monthly mortgage payments, easier qualification criteria and greater long-term savings. It's the opportunity to build home equity quicker, to reach financial stability faster, and to own to your very own luxury aprés-ski home sooner.\n\nJoin us to learn more about The Summit's Mortgage Buy Down and take advantage of reduced interest rates and low monthly mortgage payments, easier qualification criteria and greater long-term savings.",
            media: [
                { galleryLink: 'buydown-additional', index: 0 },
            ]
        },
        {
            type: CustomContentType.Form,
            // body: "",
            // body: "2.99% Mortgage rate buydown incentive, exclusive VIP pricing, exclusivity of the neighbourhood, steps to georgian bay, georgian trail , bruce trail, georgian peaks, development at the top of the escarpment.\n\nSponsored by Road runner foods, Georgian vineyards, Primont, and Bosley Georgian Bay",
            subtitle: "Fields marked with a * are required",
            requestType: AnalyticsEvent.EventRegistration,
            redirect: '#thankyou-primont-labour-day-event',
            formOptions: {
                submitMessage: 'Register',
                tags: ['primont-labour-day-event'],
            },
            fields: [
                {
                    id: 'firstname',
                    label: 'FIRST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'lastname',
                    label: 'LAST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'email',
                    label: 'EMAIL',
                    required: true,
                    size: 4,
                },
                {
                    id: 'phone',
                    label: 'PHONE',
                    required: true,
                    inputProps: { phone: true },
                    size: 2,
                },
                {
                    id: 'postalcode',
                    label: 'Postal Code',
                    required: false,
                    size: 1,
                    breakAfter: true,
                },
                {
                    id: 'howdidyouhear',
                    label: 'HOW DID YOU HEAR ABOUT US?',
                    dropdown: referralSources,
                    required: false,
                    other: true,
                    select: 'Select one option',
                    extra: 'How did you hear about us',
                    size: 1,
                },

                {
                    id: 'realtor',
                    label: 'ARE YOU A REALTOR?',
                    choice: ['Yes', 'No'],
                    required: false,
                    initial: 'No',
                    select: 'Select one option',
                    size: 1,
                },

                // {
                //     id: 'series',
                //     label: 'WHICH PROJECT ARE YOU INTERESTED IN?',
                //     dropdown: ['Both Projects', 'The Summit', 'The Summit 2', 'Not Sure'],
                //     required: false,
                //     other: true,
                //     initial: 'Both Projects',
                //     select: 'Select one option',
                //     size: 1,
                // },
                {
                    id: 'consent',
                    label: "By clicking this box or by completing this Registration Form for Primont, I expressly provide my consent to receive electronic messages from Primont and its affiliated companies retroactively, today and in the future for any projects by Primont. I understand that I may withdraw my consent by unsubscribing anytime.",
                    required: true,
                    size: 4,
                }
            ],
        },
        {
            type: CustomContentType.Blurb,
            body: "*Maximum mortgage amount up to $1 Million. Based on 40% down payment and subject to Lender's approval, additional terms and conditions apply. Cannot be combined with other promotions. Pricing and specifications subject to change without notice. Brokers protected. See Sales Representative for further details. E.&O.E."
        }
    ],
    'thankyou-primont-labour-day-event': [
        {
            type: CustomContentType.Event,
            title: 'Primont Labour Day Event',
            dividerAfter: true,
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    subtitle: 'August 31st - September 2nd, 2024',
                },
                {
                    title: 'TIME',
                    subtitle: '12 - 3pm',
                }
            ],
        },
        {
            type: CustomContentType.Blurb,
            body: `Thank you for registering for the The Primont Labour Day Event`,
            animated: false
        },
    ],
}
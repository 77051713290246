import React, { useState, useEffect, useRef } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { RootState, PromptOptions } from 'app/types'
import { Spinner, TourIFrame, BackButton, IconButton, Prompt } from 'components'
import { showPrompt, navigateBackAsync } from 'actions/appActions'
import { getTourUrl } from 'helpers/config'
import { showDisclaimer } from 'app/state'
import * as fnc from 'helpers/fnc'

interface ModelhomeViewProps {
    app: AppData,
    splitIndex: number,
    dataLink: string,
    disclaimer: boolean,
    onClose: () => void
    onFullscreen: () => void
    fullscreen: boolean,
    inPrompt: boolean,
}
export function ModelhomeView(props: ModelhomeViewProps) {
    const { app, dataLink, mediaId, splitIndex, disclaimer, onClose, onFullscreen, fullscreen, inPrompt } = { disclaimer: true, ...props }
    const dispatch = useAppDispatch()
    const media = useAppSelector((state: RootState) => state.app.media)
    const [url, setUrl] = useState(null)
    const [viewRef, setViewRef] = useState(null)
    const [loading, setLoading] = useState(0)
    const [showInlineDisclaimer, setShowInlineDisclaimer] = useState(false)
    const [disclaimerOptions, setDisclaimerOptions] = useState({ ...PromptOptions.ModelHomeDisclaimer })
    const [isExternal, setIsExternal] = useState(false)
    const [initialized, setInitialized] = useState(false)

    const isFullscreen = fullscreen || (viewRef != null && fnc.isFullscreen() == viewRef)

    useEffect(() => {
        let m = null
        let newUrl = null
        let newDisclaimerOptions = { ...disclaimerOptions }
        if (dataLink && app) {
            const modelhome = app.modelhomes.find((x) => x.link === dataLink || x.id == dataLink)
            if (modelhome?.disclaimer) {
                newDisclaimerOptions.message = modelhome.disclaimer
                newDisclaimerOptions.id = modelhome.id
                newDisclaimerOptions.alwaysShow = modelhome.showDisclaimerAlways
            }
            m = media[modelhome.mediaId]
        } else if (mediaId && mediaId in media) {
            m = media[mediaId]
            newDisclaimerOptions.id = mediaId
        }
        if (m) {
            if (m.link.includes('http')) {
                newUrl = m.link
            } else {
                newUrl = `${getTourUrl()}/${m.link}`
            }
        }
        setUrl(newUrl)
        setDisclaimerOptions(newDisclaimerOptions)
        // setIsExternal(!newUrl?.includes(window.origin) && !window.origin.includes('homegyde'))
        setIsExternal(!newUrl?.includes('homegyde'))
        setInitialized(true)
    }, [app, dataLink])

    useEffect(() => {
        window.addEventListener('message', handleWindowEvent)
        return () => {
            window.removeEventListener('message', handleWindowEvent)
        }
    }, [])

    useEffect(() => {
        if (!initialized) {
            return
        }
        if (loading == 2 || isExternal) {
            if (disclaimer && (showDisclaimer('modelhome_' + disclaimerOptions.id) || disclaimerOptions?.alwaysShow)) {
                if (inPrompt) {
                    setShowInlineDisclaimer(true)
                } else {
                    dispatch(showPrompt(disclaimerOptions))
                }
            } else {
            }
        }
    }, [initialized, loading, isExternal, disclaimerOptions])

    function handleWindowEvent(e) {
        if (e.data == 'tourLoaded') {
            setTimeout(() => {
                setLoading(1)
                setTimeout(() => {
                    setLoading(2)
                }, 250)
            }, 700)
        }
    }
    function handleClose() {
        if (onClose) {
            onClose()
        }
    }
    function handleFullscreen() {
        if (onFullscreen && viewRef) {
            onFullscreen(viewRef)
        }
    }

    if (!url) {
        return null
    }
    return <div id="modelhome-view" ref={setViewRef}>
        {showInlineDisclaimer && <Prompt prompt={disclaimerOptions} onDismiss={() => setShowInlineDisclaimer(false)} />}
        {!isExternal && loading < 2 && <Spinner invert overlay show={loading == 0} showAfter={500} quotesAfter={500} />}
        <TourIFrame src={url} />
        {(onClose || onFullscreen) && <div className="top-right">
            {onFullscreen && <IconButton icon={isFullscreen ? "fas fa-compress-alt" : "fas fa-expand-alt"} onClick={handleFullscreen} />}
            {onClose && <IconButton icon="fas fa-times" onClick={handleClose} />}
        </div>}
    </div>
    /*return <div id="modelhome-view">
        <h1>{`Couldn't find model home ${dataLink}`}</h1>
    </div>*/
}

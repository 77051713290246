import { combineReducers } from '@reduxjs/toolkit'
import appReducer from 'actions/appActions'
import adminReducer from 'actions/adminActions'
import userReducer from 'actions/userActions'
import spinReducer from 'actions/spinActions'

const rootReducer = combineReducers({
    app: appReducer,
    admin: adminReducer,
    user: userReducer,
    spin: spinReducer,
})

export default rootReducer

import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'app/hooks'
import {
    Icon,
    Spinner,
    Button,
} from 'components'
import { RootState } from 'app/types'
import { icons } from 'app/constants'

interface PendingDialogProps {
    title: string,
    subtitle: string,
    animation?: string,
    pendingMessage: string,
    successMessage: string,
    successTitle: string,
    failureMessage: string,
    confirm?: boolean,
    confirmText?: string,
    onClose: () => void,
    showResult?: boolean,
}

export function PendingDialog(props: PendingDialogProps) {
    const {
        title,
        subtitle,
        animation,
        pendingMessage,
        successMessage,
        successTitle,
        failureMessage,
        confirm,
        confirmText,
        onClose,
        skipResult,
    } = props

    const [dialogTitle, setDialogTitle] = useState(title)
    const result = useAppSelector((state: RootState) => state.app.prompt.pendingResult)

    useEffect(() => {
        if (result) {
            if (skipResult) {
                onClose()
                return
            }
            let holdTime = 1500
            if (result && !result.success && result.status == 422 && result.message) {
                holdTime += 1000 * (result.message.length / 75.0)
            } else if (result && successMessage) {
                holdTime += 1000 * (successMessage.length / 75.0)
            } else if (!result && failureMessage) {
                holdTime += 1000 * (failureMessage.length / 75.0)
            } else {
                // holdTime = 1000
            }

            if (result && (result.success || result == true) && successTitle) {
                setDialogTitle(successTitle)
            }

            if (holdTime != null) {
                holdTime = Math.min(holdTime, 4000)
            }

            if (!confirm && !(result == false || result.success == 0)) {
                onClose(null, holdTime)
            }
        }
    }, [result])

    function getSpinner() {
        if (pendingMessage) {
            return <div className="dialog-message"><Spinner showAfter={0} />{pendingMessage ? <h3>{pendingMessage}</h3> : null}</div>
        }
        return <div className="dialog-message only-spinner"><Spinner showAfter={0} debug /></div>
    }


    function getResult() {
        let confirmElement = null
        if (confirm || (result != null && (result == false || result.success == 0))) {
            confirmElement = <div className="row buttons"><Button onClick={onClose}>{confirmText ? confirmText : 'Okay'}</Button></div>
        }

        if ((result.success !== null && result.success) || (result === true || result.message?.includes('Success'))) {
            return <div className="dialog-message noselect animate__animated animate__headShake">
                <div className="row">
                    <Icon large noBg className="dialog-icon" icon={icons.check} style={{ color: 'var(--confirm)' }} />
                    <h5>{successMessage}</h5>
                </div>
                {confirmElement}
            </div>
        }
        if (!result.success && result.status == 422 && result.message && result.message?.includes('Success')) {
            return <div className="dialog-message noselect animate__animated animate__headShake">
                <div className="row">
                    <Icon large noBg className="dialog-icon" icon={icons.exclamationCircle} style={{ color: 'var(--error)' }} />
                    <h5>{result.message}</h5>
                </div>
                {confirmElement}
            </div>
        }
        let finalFailureMessage = failureMessage
        if(result.message) {
            finalFailureMessage = result.message
        } else if (!finalFailureMessage) {
            finalFailureMessage = 'An error occurred'
            return null
        }
        return <div className="dialog-message noselect animate__animated animate__headShake">
            <div className="row">
                <Icon large noBg className="dialog-icon" icon={icons.exclamationCircle} style={{ color: 'var(--error)' }} />
                <h5>{finalFailureMessage}</h5>
            </div>
            {confirmElement}
        </div>
    }

    return <div className={`dialog ${animation}`} onClick={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
        {(dialogTitle || subtitle) && <div className="title">
            {dialogTitle && <h3>{dialogTitle}</h3>}
            {subtitle && <h4>{subtitle}</h4>}
        </div>}
        <div className="dialog-wrapper">
            {result == null && getSpinner()}
            {result != null && getResult()}
        </div>
    </div>
}

import React, { useEffect, Suspense, useState } from 'react'
import {
    Switch,
    Route,
    useRouteMatch,
    useHistory,
} from 'react-router-dom'
import { useAppSelector, useAppDispatch, useOrganization, useDetermineOrganization } from 'app/hooks'
import {
    RootState,
    PageType,
} from 'app/types'
import {
    initializeHome, rebuildHistory, setOrganizationVisited,
} from 'actions/appActions'
import {
    HomeBar,
    Spinner,
} from 'components'
import * as fnc from 'helpers/fnc'
import AboutUsView from './AboutUsView'
import SummaryView from './SummaryView'
import ProjectView from './ProjectView'
import { PasswordResetView } from './PasswordResetView'
import { ActivateAccountView } from './ActivateAccountView'
import { FavouritesView } from './FavouritesView'
import { PrivacyPolicyView } from './PrivacyPolicyView'
import { organizationRoutes, organizationContent } from 'app/transformers'
import { DynamicPage } from 'views/DynamicPage'
import { logger } from 'helpers/logger'
import { getHost, getWindowLocation } from 'helpers/config'
import { hubspotTrackPageView } from 'helpers/scripts'

interface HomePageProps {
    organizationLink: string,
    loading: boolean,
}
export function HomePage(props: HomePageProps) {
    const { organizationLink, match, loading } = props

    const { url } = useRouteMatch()
    const dispatch = useAppDispatch()
    const initialized = useAppSelector((state: RootState) => state.app.initialized)
    const error = useAppSelector((state: RootState) => state.app.error)
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const links = useAppSelector((state: RootState) => state.app.links)
    const organizationVisited = useAppSelector((state: RootState) => state.app.organizationVisited)
    const config = useAppSelector((state: RootState) => state.app.config)
    const history = useHistory()
    const fullscreen = useAppSelector((state: RootState) => state.app.fullscreen)
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)
    const standaloneOrg = useAppSelector((state: RootState) => state.app.standaloneOrg)
    const customDomain = useAppSelector((state: RootState) => state.app.customDomain)
    const determineOrganization = useDetermineOrganization()
    const [test, setTest] = useState(Date.now())

    useEffect(() => {
        const host = getHost()
        const newOrganization = determineOrganization(organizationLink)
        // Validate organization exists
        if (newOrganization && (!organization || newOrganization?.link != organization.link)) {
            dispatch(initializeHome({ organization: newOrganization.link, domain: host }))
        } else {
            // throw new Error("Failed to initialize home")
        }
    }, [])

    useEffect(() => {
        hubspotTrackPageView()
    }, [links])

    useEffect(() => {
        // Whether we've visited an organization root page
        if (organizationVisited) {
            return
        }
        if (links.organizationLink != null && links.appLink == null) {
            dispatch(setOrganizationVisited(true))
        }

    }, [links])


    function getContent() {
        // Determine if using custom domain
        let routes = null
        // let Content = null
        if (organization && !standaloneApp) {
            routes = organizationRoutes(organization)
            // Content = organizationContent(organization)
            /*if (routes) {
                Content = organizationContent(organization)
            }*/
        }

        const prefix = (!customDomain.organization && organizationLink) ? `/${organizationLink}` : ''
        return <Suspense fallback={<Spinner />}>
            <Switch>
                {!(standaloneApp && customDomain.app) && <Route path={`${prefix}/search/:appLink?/:pageLink?/:dataLink?/:extraLink?`} render={(props) => {
                    return <ProjectView path={{ baseRoute: PageType.Developments }} {...props} />
                }} />
                }
                {!(standaloneApp && customDomain.app) && <Route path={`${prefix}/developments/:appLink?/:pageLink?/:dataLink?/:extraLink?`} render={(props) => {
                    return <ProjectView path={{ baseRoute: PageType.Developments }} {...props} />
                }} />
                }
                <Route path={`${match.path}/favourites/:appLink?/:pageLink?/:dataLink?`} component={FavouritesView} />
                <Route path={`${match.path}/about-us`} component={AboutUsView} />
                <Route path={`${match.path}/privacy-policy`} component={PrivacyPolicyView} />
                <Route path={`${match.path}/activate-account/:link`} component={ActivateAccountView} />
                <Route path={`${match.path}/reset-password/:link`} component={PasswordResetView} />
                {routes && routes.map((x) => {
                    return <Route key={x.link} path={`${prefix}/${x.link}`} render={() => {
                        return <DynamicPage organization={organization} pageLink={x.link} />
                        {/*return <Suspense fallback={<Spinner />}>
                            <div className=""
                            <Content link={x} />
                    </Suspense>*/}
                    }} />
                })}
                {!(standaloneApp && customDomain.app) && <Route path={`${prefix}/:appLink/:pageLink?/:dataLink?/:extraLink?`} render={(props) => {
                    return <ProjectView organizationLink={organizationLink} {...props} />
                }} />}
                {standaloneApp && customDomain.app && <Route path={`${prefix}/:pageLink?/:dataLink?/:extraLink?`} render={(props) => {
                    const finalProps = props
                    finalProps.match.params.appLink = customDomain.app
                    return <ProjectView organizationLink={organizationLink} {...finalProps} />
                }} />}
                {(!standaloneApp || standaloneOrg) && <Route path={`${match.path}/`} component={SummaryView} />}
            </Switch>
        </Suspense>
    }

    if (initialized >= 2) {
        let redirect = null
        if (url.includes('activate-account') || url.includes('reset-password')) {
            redirect = PageType.Home
        }
        return <div id="home-page" className={`page-wrapper fadeIn${fullscreen ? ' fullscreen' : ''}${standaloneApp ? ' standalone' : ''}`}>
            {!loading && <HomeBar redirect={redirect} />}
            {getContent()}
            <div style={{ visibility: 'hidden', position: 'absolute', pointerEvents: 'none' }}>Site Loaded</div>
        </div>
    }
    return null
}

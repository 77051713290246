import { configureStore, ThunkAction } from '@reduxjs/toolkit'
import { logger } from 'redux-logger'
import rootReducer from './reducer'
import { RootState } from './types'

const preloadedState = {}
export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => {
        if (process.env.NODE_ENV !== 'production') {
            return getDefaultMiddleware().concat(logger)
        }
        return getDefaultMiddleware()
    },
    preloadedState,
})

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

import React, { useRef, useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
    AnalyticsFilter,
    CustomEventType,
    FloorplanFilter,
    MinMax,
    QueryType,
    RootState,
    UnitFilter,
    ProjectFilter,
    Range,
    UpgradeFilter,
} from 'app/types'
import {
    applyQuery,
} from 'actions/appActions'

import {
    resetQueryHelper,
    setQueryHelper,
    toggleQueryHelper,
} from 'helpers/query'
import {
    DropdownMenu,
    Icon,
    Checkbox,
    Slider,
    Button,
    Input,
    IconButton,
    DateRange,
} from 'components'
import * as fnc from 'helpers/fnc'
import { logger } from 'helpers/logger'
import { transformGarage } from 'app/transformers'

interface FilterProps {
    app: AppData,
    queryType: QueryType,
    ranges: { [key: string]: string[] | number[] | MinMax },
    filters?: Filter[],
    filterSubset?: string[],
    search?: boolean,
    onClose?: () => void,
    onExpand?: () => void,
    expanded: boolean,
    expandedAsDropdown?: boolean,
    listSelected?: boolean,
    splitIndex?: number,
    maps: Dict,
    fullscreen: boolean,
}

let searchTimeout = null

// Filters that by default go to extended section
const defaultFilters = [
    { filter: FloorplanFilter.Beds },
    { filter: FloorplanFilter.Baths },
    { filter: FloorplanFilter.Favourites },
    { filter: ProjectFilter.Organization, expanded: true },
    { filter: ProjectFilter.Builder, expanded: true },
    { filter: ProjectFilter.ProjectStatus, expanded: true },
    { filter: FloorplanFilter.Price, expanded: true },
    { filter: FloorplanFilter.Sqft, expanded: true },
    { filter: FloorplanFilter.Search, expanded: true },
    { filter: FloorplanFilter.Availability, expanded: true },
    { filter: UnitFilter.Exposure, expanded: true },
    { filter: UnitFilter.Floor, expanded: true },
    { filter: UnitFilter.HideUnavailable, expanded: true },
]

export function Filters(props: FilterProps) {
    const {
        app,
        queryType,
        ranges,
        filters,
        filterSubset,
        search,
        onClose,
        onExpand,
        expanded,
        expandedAsDropdown,
        listSelected,
        splitIndex,
        maps,
        fullscreen,
    } = { filters: defaultFilters, ...props }

    const expandedFilterSet = new Set(filters.filter((x) => x.expanded).map((x) => x.filter))

    if (!queryType) {
        logger.error('Missing query type for filter')
        return null
    }
    const dispatch = useAppDispatch()
    const appliedQuery = useAppSelector((state: RootState) => state.app.queries[queryType])
    const config = useAppSelector((state: RootState) => state.app.config)
    const { builders, organizations } = config
    let availableFilters = []
    if (filters != null) {
        availableFilters = filters.map((x) => {
            if (typeof x == 'object' && x.filter) {
                return x.filter
            } else {
                return x
            }
        })
    } else if (app && app.meta && app.meta.filters) {
        availableFilters = app.meta.filters
    }

    const [currentQuery, setCurrentQuery] = useState(null)
    const searchRef = useRef()

    useEffect(() => {
        setCurrentQuery(fnc.copyObj(appliedQuery))
    }, [appliedQuery])

    function getFilterDepth(filterName) {
        if (expandedAsDropdown && (splitIndex != null || expandedFilterSet.has(filterName) || fullscreen)) {
            return 2
        }
        return 1
    }

    function applyFilter(query) {
        dispatch(applyQuery({ query: query ? query : currentQuery, type: queryType }))
        if (fullscreen && onClose) {
            onClose()
        }
    }

    function resetFilter() {
        const newQuery = resetQueryHelper(queryType)
        if (searchRef.current && searchRef.current.clear) {
            searchRef.current.clear()
        }
        setCurrentQuery(newQuery)
        applyFilter(newQuery)

        window.dispatchEvent(new Event(CustomEventType.ResetScroll))
    }

    function clearFilter(key: string) {
        const newQuery = resetQueryHelper(queryType, currentQuery, key)
        if (!fullscreen) {
            applyFilter(newQuery)
        }
        setCurrentQuery(newQuery)
    }

    function setFilter(q: { [key: string]: string }) {
        const newQuery = setQueryHelper(queryType, currentQuery, q)
        if (!fullscreen) {
            applyFilter(newQuery)
        }
        setCurrentQuery(newQuery)
    }

    function toggleFilter(q: { [key: string]: string }) {
        const newQuery = toggleQueryHelper(queryType, currentQuery, q)
        if (!fullscreen) {
            applyFilter(newQuery)
        }
        setCurrentQuery(newQuery)
    }

    function handleSearch(value: string) {
        if (searchTimeout) {
            clearTimeout(searchTimeout)
        }

        if (currentQuery[FloorplanFilter.Search] && value === '') {
            clearFilter(FloorplanFilter.Search)
        } else if (value !== '') {
            searchTimeout = setTimeout(() => {
                const filterObj = {}
                filterObj[FloorplanFilter.Search] = value.toLowerCase()
                setFilter(filterObj)
            }, 500)
        }
    }

    function getSelectFilter<Type>(
        filterName: string,
        array: Type[] | { id: string, name: string },
        selector: () => (string | MinMax)[],
        title: string,
        selected: Type,
        prefix = false,
        suffix = false,
        abbrev: string = null,
    ) {
        const items = []
        for (let i = 0; i < array.length; i += 1) {
            const filterObj = {}
            const [id, range, name] = selector ? selector(array[i]) : [array[i], null]
            if (range && range.min && range.max) {
                filterObj[filterName] = []
                for (let j = range.min; j <= range.max; j += 1) {
                    filterObj[filterName].push(j)
                }
            } else {
                filterObj[filterName] = id
            }
            items.push({ text: name || id, value: id })
        }
        const depth = getFilterDepth(filterName)
        let text = ''
        let menuClass = ''
        /*if (selected) {
            if (selector) {
                text = selector[selected]
            } else {
                text = selected
            }
        } else */
        if (fullscreen) {
            text = `Select ${title}`
        } else {
            text = title
        }

        const stickyItems: JSX.Element[] = []
        if (filterName in currentQuery) {
            stickyItems.push(<Button
                key="reset"
                onClick={(e: Event) => { e.stopPropagation(); clearFilter(filterName) }}>
                Reset
            </Button>)
        }

        if (fullscreen) {
            return <div className="row filter-row" key={`${filterName}Filter`}>
                <div className="column">
                    <h5>{title}</h5>
                    <DropdownMenu
                        scrollable
                        key={`${filterName}Filter`}
                        className={menuClass}
                        value={selected}
                        items={items}
                        closeItems={stickyItems}
                        depth={getFilterDepth(filterName)}
                        close
                        onChange={(x) => setFilter({ [filterName]: [x] })}
                    />
                </div>
            </div>
        }

        // If depth > 1, display as list instead of dropdown
        if (depth > 1) {
            return <div className="row filter-row" key={`${filterName}Filter`}>
                <div className="column">
                    <h5>{text}</h5>
                    {items.map((x) => x.element)}
                </div>
            </div>
        }
        return <DropdownMenu
            scrollable
            key={`${filterName}Filter`}
            className={menuClass}
            text={text}
            value={selected}
            items={items}
            closeItems={stickyItems}
            depth={getFilterDepth(filterName)}
            onChange={(x) => setFilter({ [filterName]: [x] })}
            close
        />
    }

    function getCheckFilter<Type>(
        filterName: string,
        array: Type[] | { id: string, name: string },
        selector: () => (string | MinMax)[],
        title: string,
        selected: Type[],
        prefix = false,
        suffix = false,
        abbrev: string = null,
    ) {
        const items = []
        for (let i = 0; i < array.length; i += 1) {
            const filterObj = {}
            const [id, range, name] = selector ? selector(array[i]) : [array[i], null]
            if (range && range.min && range.max) {
                filterObj[filterName] = []
                for (let j = range.min; j <= range.max; j += 1) {
                    filterObj[filterName].push(j)
                }
            } else {
                filterObj[filterName] = id
            }
            items.push({
                element: <Checkbox
                    // key={`${id}-${i}`}
                    key={i}
                    onChange={(x) => toggleFilter(filterObj)}
                    className={range ? 'checkbox-group' : ''}
                    value={array
                        && selected
                        && Array.isArray(selected)
                        && selected.find((x) => x === id || (x?.group && id === x?.group)) != null}
                    name={filterName}>
                    {name || id}
                </Checkbox>,
            })
        }
        const depth = getFilterDepth(filterName)
        let text = ''
        let menuClass = ''
        if (selected && Array.isArray(selected) && selected.length > 0) {
            menuClass = 'filtered'
            if (depth > 1) {
                text = `${title} (${selected.length})`
            } else if (listSelected) {
                let titleText = abbrev ? abbrev : title
                const basicSelected = selected.filter((x) => typeof x !== 'object').map((x) => {
                    // TODO, something not n^2
                    let elem = array.find((y) => (selector ? selector(y)[0] : y) == x)
                    if (typeof elem == 'object' && 'name' in elem) {
                        return elem.name
                    }
                    return elem
                })
                if (basicSelected.length > 2) {
                    text = `${title} (${basicSelected.length})`
                    // text = title
                } else if (suffix) {
                    text = `${titleText} ${basicSelected.join(', ')}`
                } else if (prefix) {
                    let fixedTitle = titleText
                    if (basicSelected.length == 1 && fixedTitle[fixedTitle.length - 1] == 's') {
                        fixedTitle = fixedTitle.substring(0, fixedTitle.length - 1)
                    }
                    text = `${basicSelected.join(', ')} ${fixedTitle}`
                } else {
                    text = basicSelected.join(', ')
                }
            } else if (fullscreen) {
                text = `Select ${title}`
            } else {
                text = title
            }
        } else if (fullscreen) {
            text = `Select ${title}`
        } else {
            text = title
        }

        const stickyItems: JSX.Element[] = []
        if (filterName in currentQuery) {
            stickyItems.push(<Button
                key="reset"
                onClick={(e: Event) => { e.stopPropagation(); clearFilter(filterName) }}>
                Reset
            </Button>)
        }

        if (fullscreen) {
            return <div className="row filter-row" key={`${filterName}Filter`}>
                <div className="column">
                    <h5>{title}</h5>
                    {items.length > 8 && <DropdownMenu
                        scrollable
                        key={`${filterName}Filter`}
                        className={menuClass}
                        text={text}
                        items={items}
                        closeItems={stickyItems}
                        depth={getFilterDepth(filterName)}
                        close
                    />}
                    {items.length <= 8 && items.map((x) => x.element)}
                </div>
            </div>
        }

        // If depth > 1, display as list instead of dropdown
        if (depth > 1) {
            return <div className="row filter-row" key={`${filterName}Filter`}>
                <div className="column">
                    <h5>{text}</h5>
                    {items.map((x) => x.element)}
                </div>
            </div>
        }
        return <DropdownMenu
            scrollable
            key={`${filterName}Filter`}
            className={menuClass}
            text={text}
            items={items}
            closeItems={stickyItems}
            depth={getFilterDepth(filterName)}
            close
        />
    }

    function getSliderFilter(filterName: string, min: number, max: number, title: string, prefix = '', suffix = '', step = 100, ranges: number[number[]] = null, formatString: (x: string) => string = null) {
        const items = []
        const currentMin: number = currentQuery[filterName] ? currentQuery[filterName].min : min
        const currentMax: number = currentQuery[filterName] ? currentQuery[filterName].max : max

        if (ranges != null) {
            for (let i = 0; i < ranges.length; i += 1) {
                const [rMin, rMax] = ranges[i]
                items.push({
                    element: <Checkbox
                        key={i}
                        onChange={(x) => {
                            if (rMin === currentMin && rMax === currentMax) {
                                clearFilter(filterName)
                            } else {
                                const filterObj = {}
                                filterObj[filterName] = { min: rMin, max: rMax }
                                setFilter(filterObj)
                            }
                        }}
                        value={rMin === currentMin && rMax === currentMax}
                        name={`${filterName}-i`}>
                        {`${prefix}${rMin}${suffix} - ${prefix}${rMax}${suffix}`}
                    </Checkbox>,

                })
            }
        }

        items.push({
            element: <Slider
                key={items.length}
                onChange={(mn: number, mx: number) => {
                    const filterObj = {}
                    filterObj[filterName] = { min: mn, max: mx }
                    if (mn === min && mx === max) {
                        clearFilter(filterName)
                    } else {
                        setFilter(filterObj)
                    }
                }}
                key={`slider-${title}`}
                min={min}
                startMin={currentMin}
                startMax={currentMax}
                max={max}
                name={filterName}
                prefix={prefix}
                suffix={suffix}
                step={step}
            />,
        })

        let text = ''
        let menuClass = ''
        if (min === currentMin && max === currentMax) {
            text = title
        } else {
            menuClass = 'filtered'
            if (formatString) {
                text = `${title} (${formatString(currentMin)} - ${formatString(currentMax)})`
            } else {
                text = `${title} (${prefix}${fnc.numberWithCommas(currentMin)}${suffix} - ${prefix}${fnc.numberWithCommas(currentMax)}${suffix})`
            }
        }

        const depth = getFilterDepth(filterName)
        // If depth > 1, display as list instead of dropdown
        if (depth > 1 || fullscreen) {
            return <div className="row filter-row" key={`${filterName}Filter`}>
                <div className="column">
                    <h5>{text}</h5>
                    {items.map((x) => x.element)}
                </div>
            </div>
        }

        return <DropdownMenu
            key={`${filterName}Filter`}
            className={menuClass}
            text={text}
            items={items}
            depth={depth}
            close
        />
    }

    function getDateRange(filterName: string, min: Date, max: Date) {
        const currentSelected: Range = currentQuery[filterName]

        function handleChange(val) {
            const filterObj = {}
            filterObj[filterName] = val ? { startDate: val.startDate.toString(), endDate: val.endDate.toString() } : null
            setFilter(filterObj)
        }

        return <DateRange key={filterName} className={currentSelected ? 'filtered' : ''} selected={currentSelected} range={{ min, max }} onChange={handleChange} initialize={false}/>
    }

    function getToggleFilter<Type>(
        filterName: string,
        title: string,
        optionName: string,
        element: JSX.Element,
        selected: boolean,
    ) {
        function handleClick() {
            if (!selected) {
                const filterObj = {}
                filterObj[filterName] = !selected
                setFilter(filterObj)
            } else {
                clearFilter(filterName)
            }
        }

        if (fullscreen) {
            return <div className="row filter-row" key={`${filterName}Filter`}>
                <div className="column">
                    <h5>{title}</h5>
                    <Checkbox
                        key={`${filterName}Filter`}
                        name={`${filterName}Filter`}
                        className={`${selected ? 'filtered' : ''}${element ? ' with-icon' : ''}`}
                        onChange={handleClick}
                        value={selected}>
                        {optionName}
                        {element}
                    </Checkbox>
                </div>
            </div>
        }


        return <Checkbox
            key={`${filterName}Filter`}
            name={`${filterName}Filter`}
            className={`${selected ? 'filtered' : ''}${element ? ' with-icon' : ''}`}
            onChange={handleClick}
            value={selected}>
            {title}
            {element}
        </Checkbox>
    }

    if (currentQuery == null) {
        return null
    }

    const finalFilters = []
    const finalExpandedFilters = []
    const filterOptions = []
    const finalRanges = ranges ? ranges : {}

    // Only apply filters in available filters
    for (let i = 0; availableFilters && i < availableFilters.length; i += 1) {
        const filter = availableFilters[i]
        const filterSet = (!fullscreen && (splitIndex != null || expandedFilterSet.has(filter))) ? finalExpandedFilters : finalFilters
        // const filterSet = (splitIndex != null || expandedFilterSet.has(filter)) ? expandedFilters : filters
        switch (filter) {
            default:
                break
            case ProjectFilter.Organization:
                filterSet.push(getCheckFilter<OrganizationData>(filter, organizations.filter((x) => x.link != 'homegyde'), (x: OrganizationData) => [x.id, null, x.name], 'Organization', currentQuery[ProjectFilter.Organization]))
                break
            case ProjectFilter.Builder:
                let builderSet: string[] = []
                if (finalRanges[filter]) {
                    builderSet = finalRanges[filter]
                } else {
                    builderSet = builders
                }
                filterSet.push(getCheckFilter<BuilderData>(filter, builderSet, (x: BuilderData) => [x.id, null, x.name], 'Builder', currentQuery[ProjectFilter.Builder]))
                break
            case ProjectFilter.ProjectType:
                let types: string[] = []
                if (finalRanges[filter]) {
                    types = finalRanges[filter]
                } else {
                    types = config.projectTypes
                }
                filterSet.push(getCheckFilter<string>(filter, types, (x) => [x.id, null, x.name], 'Home Type', currentQuery[ProjectFilter.ProjectType]))
                break
            case ProjectFilter.ProjectStatus:
                let statuses: string[] = []
                if (finalRanges[filter]) {
                    statuses = finalRanges[filter]
                } else {
                    statuses = config.projectStatuses
                }
                filterSet.push(getCheckFilter<string>(filter, statuses, (x) => [x.id, null, x.name], 'Sales Status', currentQuery[ProjectFilter.ProjectStatus]))

                break

            case ProjectFilter.Search: {
                filterSet.push(<Input key="search" value={currentQuery[filter]} clearRef={searchRef} placeholder="Search by location" onChange={handleSearch} search />)
                break
            }
            case FloorplanFilter.Beds:
                let beds: number[] = []
                if (finalRanges && finalRanges[filter]) {
                    beds = finalRanges[filter]
                } else {
                    beds = [1, 2, 3, 4, 5, 6]
                }
                filterSet.push(getCheckFilter<number>(
                    filter, beds, null, filter, currentQuery ? currentQuery[filter] : {}, true, false,
                ))
                break
            case FloorplanFilter.Baths:
                let baths: number[] = []
                if (finalRanges && finalRanges[filter]) {
                    baths = finalRanges[filter]
                } else {
                    baths = [1, 2, 3, 4, 5, 6]
                }
                filterSet.push(getCheckFilter<number>(
                    filter, baths, null, filter, currentQuery ? currentQuery[filter] : {}, true, false,
                ))
                break
            case FloorplanFilter.Garage:
                let garages = []
                if (finalRanges && finalRanges[filter]) {
                    garages = finalRanges[filter]
                } else {
                    garages = [0, 1, 2, 3]
                }
                filterSet.push(getCheckFilter<number>(
                    filter, garages.map((x) => transformGarage(app, x)), null, filter, currentQuery ? currentQuery[filter] : {}, true, false,
                ))
                break
            case FloorplanFilter.Sqft:
                let minSqft = 0
                let maxSqft = 10000
                if (finalRanges && finalRanges[filter] && finalRanges[filter].min) {
                    minSqft = finalRanges[filter].min
                }
                if (finalRanges[filter] && finalRanges[filter].max) {
                    maxSqft = finalRanges[filter].max
                }
                filterSet.push(getSliderFilter(filter, minSqft, maxSqft, filter, '', ''))
                break
            case FloorplanFilter.Availability:
                let availabilities = []
                if (finalRanges && finalRanges[filter]) {
                    availabilities = finalRanges[filter]
                }
                if (availabilities.length > 0) {
                    filterSet.push(getCheckFilter<string>(filter, availabilities, null, filter, currentQuery ? currentQuery[filter] : {}, true, false))
                }
                break
            case UnitFilter.Floor:
                let floors: number[] = []
                if (finalRanges && finalRanges[filter]) {
                    floors = finalRanges[filter]
                } else {
                    floors = [1, 2, 3, 4, 5, 6]
                }
                if (floors.length == 0) {
                    break
                }
                const selected = currentQuery[filter] ? [...currentQuery[filter]] : []
                // Check for ranges
                const subRanges = floors.filter((x) => typeof x === "object" && x.range)
                if (selected && subRanges.length > 0) {
                    const selectedMap = selected.reduce((acc, val) => {
                        acc[val] = 1
                        return acc
                    }, {})
                    for (let j = 0; j < subRanges.length; j += 1) {
                        const { min, max } = subRanges[j].range
                        let rangeMatch = true
                        for (let k = min; k <= max; k += 1) {
                            if (!(k in selectedMap)) {
                                rangeMatch = false
                                break
                            }
                        }
                        if (rangeMatch) {
                            selected.push({ group: subRanges[j].text })
                        }
                    }
                }
                filterSet.push(getCheckFilter<number>(filter, floors, (x) => {
                    if (typeof x === 'object' && x.range) {
                        return [x.text, x.range]
                    }
                    return [x, null]
                }, filter, selected, false, true))//, 'Fl.'))
                break
            case UnitFilter.Exposure: {
                let exposure: string[] = []
                if (finalRanges[filter]) {
                    exposure = finalRanges[filter]
                } else {
                    exposure = ['N', 'S', 'E', 'W']
                }
                if (exposure.length == 0) {
                    break
                }
                filterSet.push(getCheckFilter<number>(
                    filter, exposure, null, filter, currentQuery[filter], true, false,
                ))
                break
            }
            case FloorplanFilter.Price: {
                let minPrice = 0
                let maxPrice = 5000000
                if (finalRanges.price && finalRanges.price.min) {
                    minPrice = finalRanges.price.min
                }
                if (finalRanges.price && finalRanges.price.max) {
                    maxPrice = finalRanges.price.max
                }

                filterSet.push(getSliderFilter(filter, minPrice, maxPrice, 'Price', '$', '', 10000, null, (x) => {
                    if (x > 1000000) {
                        return `$${x / 1000000} mil`
                    }
                    return `$${x / 1000}k`
                }))
                break
            }
            case FloorplanFilter.Series: {
                filterSet.push(getCheckFilter<string>(filter, finalRanges[filter], (x) => [x.id, null, x.name], 'Series', Array.isArray(currentQuery[filter]) ? currentQuery[filter] : [], true, false))
                break
            }
            case UnitFilter.HideUnavailable: {
                filterSet.push(getToggleFilter(filter, filter, filter, null, currentQuery[filter]))
                break
            }
            case FloorplanFilter.Search: {
                filterSet.push(<Input key="search" value={currentQuery[filter]} clearRef={searchRef} placeholder="Search Plan / Unit" onChange={handleSearch} clear />)
                break
            }
            case FloorplanFilter.Favourites: {
                filterSet.push(getToggleFilter(filter, 'Favourites', 'Only Favourites', null, currentQuery[filter]))
                break
            }
            case AnalyticsFilter.Date: {
                let minDate = (new Date().addDays(-30))
                let maxDate = new Date()
                if (finalRanges.date && finalRanges.date.min) {
                    minDate = finalRanges.date.min
                }
                if (finalRanges.date && finalRanges.date.max) {
                    maxDate = finalRanges.date.max
                }
                filterSet.push(getDateRange(filter, minDate, maxDate))
                break
            }
            case UpgradeFilter.BuildingType: {
                filterSet.push(getSelectFilter(filter, finalRanges[filter], maps ? (x: string) => [x, null, maps[filter][x].name] : null, 'Building Type', currentQuery[filter]))
                break
            }
            case FloorplanFilter.BuildingType: {
                filterSet.push(getSelectFilter(filter, finalRanges[filter], maps ? (x: string) => [x, null, maps.buildingType[x].name] : null, 'Building Type', currentQuery[filter]))
                break
            }
        }
    }

    if (onExpand && !expanded && finalExpandedFilters.length > 0) {
        //TODO: create "expanded set"
        let expandedFiltered = 0
        expandedFilterSet.forEach((x) => {
            if (currentQuery[x]) {
                if (Array.isArray(currentQuery[x])) {
                    expandedFiltered += currentQuery[x].length
                } else {
                    expandedFiltered += 1
                }
            }
        })
        const etcIcon = splitIndex == null ? 'fas fa-ellipsis-h' : 'fas fa-filter'
        if (expandedAsDropdown) {
            const items = finalExpandedFilters.map((x) => ({ element: x }))
            finalFilters.push(<DropdownMenu
                scrollable
                className="nested"
                key="expand"
                buttonClass={expandedFiltered ? 'etc filtered' : ' etc'}
                icon={etcIcon}
                text={listSelected && expandedFiltered > 0 ? `(${expandedFiltered})` : ''}
                items={items}
                close />)
        } else {
            finalFilters.push(<IconButton key="expand" className={expandedFiltered ? 'filtered' : ''} icon={etcIcon} onClick={onExpand} />)

        }
    }

    if (fullscreen && JSON.stringify(appliedQuery) != JSON.stringify(currentQuery)) {
        filterOptions.push(<Button key="apply" className="apply" onClick={() => applyFilter()} tertiary>Apply</Button>)
    }

    if (Object.keys(currentQuery).length > 0) {
        // If subset given, only reset when currentQuery is in subset
        if (!filterSubset || Array.from(new Set(Object.keys(currentQuery)).intersection(new Set(filterSubset))).length > 0) {
            if (fullscreen || true) {
                filterOptions.push(<Button key="reset" className="reset" onClick={resetFilter}>Reset</Button>)
            } else {
                filterOptions.push(<IconButton icon="fas fa-times" key="reset" className="reset" onClick={resetFilter} />)
            }
        }
    }

    if (onClose) {
        filterOptions.push(<Button key="close" className="close" onClick={onClose}>Close</Button>)
    }

    // if (finalFilters.length == 0) {
    // return null
    // }

    if (expanded) {
        return <div className="filters">
            <div className="row">
                {finalFilters.length == 0 && expandedFilterSet?.length == 0 && <h3>No filters to apply</h3>}
                {finalFilters}
                {finalExpandedFilters}
            </div>
            <div className="row">
                {filterOptions}
            </div>
        </div>
    }

    return <React.Fragment>
        {splitIndex == null && <IconButton className="filter-button" noBg icon="fas fa-filter" onClick={onExpand} />}
        <div className="filters">
            {finalFilters.length == 0 && expandedFilterSet?.length == 0 && <h3>No filters to apply</h3>}
            {finalFilters}
            {filterOptions}
        </div>
    </React.Fragment>
}

import React, { useEffect, useState, useRef } from 'react'

import { Icon } from 'components'
import { pushNotification, removeNotification } from 'actions/appActions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { NotificationData, NotificationStatus } from 'app/types'
import { Spinner } from './Spinner'

const SHOW_TIME = 3000

interface NotificationProps {
    notification: NotificationData,
    index: number,
}

function Notification(props: NotificationProps) {
    const { notification, index } = props
    const { id, message, status } = notification
    const dispatch = useAppDispatch()
    const [show, setShow] = useState(true)

    useEffect(() => {
        let timeout = null
        switch (status) {
            case NotificationStatus.Pending:
                setShow(true)
                break
            default:
            case NotificationStatus.Info:
            case NotificationStatus.Complete:
            case NotificationStatus.Error:
                setShow(true)
                timeout = setTimeout(() => {
                    setShow(false)
                    timeout = setTimeout(() => {
                        dispatch(removeNotification(id))
                        // dispatch(pushNotification({ id, status: NotificationStatus.Archive }))
                        // removeNotification(id)
                    }, 250)
                }, SHOW_TIME)
                break
        }

    }, [status])

    function getStatus() {
        let icon = null
        switch (notification.status) {
            case NotificationStatus.Info:
                icon = <Icon noBg icon="fas fa-info" />
                break
            case NotificationStatus.Pending:
                icon = <Spinner showAfter={0} />
                break
            case NotificationStatus.Complete:
                icon = <Icon noBg icon="fas fa-check" className="animate__animated animate__bounceIn" />
                break
            case NotificationStatus.Error:
                icon = <Icon noBg icon="fas fa-times" className="animate__animated animate__headShake" />
                break
            default:
                break
        }

        return <div className="notification-status">
            {icon}
        </div>
    }

    function handleDismiss() {
        setShow(false)
        setTimeout(() => {
            dispatch(removeNotification(id))
        }, 250)
    }

    const notificationStyle = {
        bottom: `${index * 60}px`,
    }

    return <div className={`notification animate__animated ${show ? 'animate__fadeInRight' : 'animate__fadeOutRight'} animate__fastest`}
        style={notificationStyle}
        onClick={handleDismiss}
        data-status={status}>
        {getStatus()}
        {notification.message && <span>{message}</span>}
    </div>
}


export function Notifications() {
    const notifications = useAppSelector((state: RootState) => state.app.notifications)
    const visibleNotifications = notifications.filter((x) => x.status != NotificationStatus.Archive)

    return <div id="notifications">
        {visibleNotifications.map((x, ix) => {
            return <Notification key={x.id} notification={x} index={visibleNotifications.length - 1 - ix} />
        })}
    </div>
}

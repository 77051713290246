import React from 'react'
import { logger } from 'helpers/logger'
import { IconButton } from './IconButton'
import { icons } from 'app/constants'

interface CloseButtonProps {
    onClick: () => void,
    fadeIn?: boolean,
    large, 
    noBg,
    alt,
    className?: string,
}
export function CloseButton(props:CloseButtonProps) {
    const { onClick, fadeIn, className, large, noBg, alt } = { noBg: true, alt: true, large: false, ...props}

    function handleClick(e:Event) {
        e.stopPropagation()
        if (onClick) {
            onClick()
        } else {
            logger.error('Close button is missing click handler')
        }
    }
    return <IconButton
        className={`close-button top-right${fadeIn ? ' fadeIn' : ''}${className != null ? ` ${className}` : ''}`}
        icon={icons.times}
        noBg={noBg}
        alt={alt}
        large={large}
        onMouseDown={e => e.stopPropagation()}
        onClick={handleClick}/>
}

import React, { useState, useEffect, Suspense } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { navigateAsync } from 'actions/appActions'
import { RootState, Theme, PageData, PageType } from 'app/types'
import { Button, Logo, SlideShow, WaterMark, Spinner } from 'components'
import { logger } from 'helpers/logger'
import { getMediaLink } from 'helpers/media'
import { projectContent } from 'app/transformers'

interface ScreenSaverPageProps {
    app: AppData,
}

export function ScreenSaverPage(props: ScreenSaverPageProps) {
    const { app } = props
    const dispatch = useAppDispatch()
    const menu = app.menus.find((x) => x.menuType === 'screensaver')
    const theme = app.meta.themeId
    // const { theme } = useAppSelector((state: RootState) => state.app.config)
    const config = useAppSelector((state: RootState) => state.app.config)
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const media = useAppSelector((state: RootState) => state.app.media)
    const [backgroundLink, setBackgroundLink] = useState<string>(null)

    useEffect(() => {
        let backgroundId = null
        switch (theme) {
            default:
                break
            case Theme.Theme3:
                backgroundId = 'kingsleysquarehero'
                break
        }

        if (backgroundId == null || !media[backgroundId]) {
            setBackgroundLink('')
            return
        }

        const newBackgroundLink = getMediaLink(media[backgroundId].link, { app })
        const load = (url: string) => new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', resolve)
            image.addEventListener('error', reject)
            image.src = url
        })

        load(newBackgroundLink).then(() => {
            setBackgroundLink(newBackgroundLink)
        }).catch(() => {
            setBackgroundLink('')
        })
    }, [])

    function goToPage(page: PageData, options: { [key: string]: string }) {
        if (page.pageType == PageType.External) {
            window.open(page.link, '_blank')
        } else {
            dispatch(navigateAsync({ app, pageType: page.link, options }))
                .catch(logger.error)
        }
    }

    switch (app.meta.link) {
        case 'test':
        case 'casavilla':
        case 'thewoods':
        case 'coppercreek':
        default:
            const ContentElement = projectContent(app)
            if (ContentElement) {
                return <Suspense fallback={<Spinner />}><ContentElement app={app} link="screensaver" /></Suspense>
            }
            break
    }

    const gallery = app.galleries.find((x) => x.link == 'landing')
    if (!gallery) {
        return null
    }
    const pageAnimation = 'animate__animated animate__fadeIn'

    return <div id="screensaver-page" className={pageAnimation}>
        <SlideShow app={app} media={gallery.media.map((x) => x.mediaId)} zoomable={false} autoScroll={8000} showIndex={false} />
        <WaterMark />
    </div>
    return <div id="custom-view" className="content-wrapper" data-link="screensaver" key="screensaver">
        <div id="screensaver-page" className={pageAnimation}>
            <SlideShow app={app} media={gallery.media.map((x) => x.mediaId)} zoomable={false} autoScroll={8000} showIndex={false} />
            <WaterMark />
        </div>
    </div>

    switch (theme) {
        case Theme.Theme1:
        case Theme.Theme2:
        default:
            return <div id="screensaver-page">
                <h1>{app.meta.name}</h1>
                <div className="buttons">
                    {menu && menu.options.map((x) => <Button
                        key={x.id}
                        onClick={() => goToPage(x, {
                            floorplanId: x.floorplanId,
                            modelhomeId: x.modelhomeId,
                        })}>
                        {x.name}
                    </Button>)}
                </div>
                <div className="bottom-left">
                    <Logo small />
                </div>
            </div>

        case Theme.Theme3: {
            // TODO: Generalize
            if (backgroundLink == null) {
                return null
            }

            const pageStyle = { backgroundImage: `url("${backgroundLink}")` }
            const pageAnimation = 'animate__animated animate__fadeIn'

            if (!backgroundLink) {
                return null
            }

            return <div id="screensaver-page" className={pageAnimation} style={pageStyle}>
                <div className="buttons alt animate__animated animate__slideInLeft animate__delay-1s">
                    {/*menu && [...menu.options].sort(sortOptions).map((x) => <NavBarOption
                        key={x.id}
                        item={x}
                        navigate={goToPage} />)
        */}
                </div>
            </div>
        }
        case Theme.WelcomeHome: {
            // const media = app.galleries
            const gallery = organization.galleries.find((x) => x.link == 'welcomehome')
            if (!gallery) {
                return null
            }
            const pageAnimation = 'animate__animated animate__fadeIn'
            return <div id="screensaver-page" className={`${theme} ${pageAnimation}`}>
                <SlideShow media={gallery.media.map((x) => x.mediaId)} zoomable={false} autoScroll={8000} showIndex={false} />
                <WaterMark />
            </div>
        }
    }
}

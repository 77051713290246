import React, { useEffect, useState } from 'react'
import {
    BackButton, SideNavBar, Message, Button, OptionBar, CloseButton, Icon, Spinner,
} from 'components'
import {
    useAdminPermissions,
    useAppDispatch, useAppSelector, useExclusivePermissions, useLocalStorage, useUserPermissions, useValidPages,
} from 'app/hooks'
import {
    AppData, RootState, PageType, CustomEventType, ErrorMessage, UserRole, PromptType, PromptOptions, MediaType, ScreenSize, ScreenOrientation, AdminOperation
} from 'app/types'
import { navigateAsync, showPrompt } from 'actions/appActions'
import { ComparePage } from 'views/ComparePage'
import { FloorplanPage, FavouritesPage } from 'views/FloorplanPage'
import { GalleryPage } from 'views/GalleryPage'
import { ScreenSaverPage } from 'views/ScreenSaverPage'
import { NeighbourhoodPage } from 'views/NeighbourhoodPage'
import { ModelhomePage } from 'views/ModelhomePage/ModelhomePage'
import { DynamicPage } from 'views/DynamicPage/DynamicPage'
import { ProjectInfoPage } from 'views/HomePage/ProjectInfoPage'
import { InfoPage } from 'views/InfoPage/InfoPage'
import { SitemapPage } from 'views/SitemapPage/SitemapPage'
import { BuildingPage } from 'views/BuildingPage/BuildingPage'
import { AmenitiesPage } from 'views/AmenitiesPage/AmenitiesPage'
import { UpgradePage } from 'views/UpgradePage/UpgradePage'
import { LaunchPage } from 'views/LaunchPage/LaunchPage'
import { logger } from 'helpers/logger'
import * as fnc from 'helpers/fnc'
import { tutorial } from 'app/constants'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { NotFound } from './NotFound'
import { RequestInfo } from './RequestInfo'
import { CustomContent } from 'src/content/CustomContent'

const pageMap = []
pageMap[PageType.Info] = DynamicPage//InfoPage
pageMap[PageType.ScreenSaver] = ScreenSaverPage
pageMap[PageType.Sitemap] = SitemapPage
pageMap[PageType.Building] = BuildingPage
pageMap[PageType.Amenities] = AmenitiesPage
pageMap[PageType.Floorplan] = FloorplanPage
pageMap[PageType.Favourite] = FavouritesPage
pageMap[PageType.Modelhome] = ModelhomePage
pageMap[PageType.Gallery] = GalleryPage
pageMap[PageType.Neighbourhood] = NeighbourhoodPage
pageMap[PageType.Compare] = ComparePage
pageMap[PageType.Upgrades] = UpgradePage
pageMap[PageType.Custom] = DynamicPage
pageMap[PageType.Launch] = LaunchPage

interface AppPageProps {
    app: AppData,
    pageLink: string,
    dataLink: string,
    extraLink: string,
    splitIndex: number,
    pages: PageType,
    topbarHeight?: number,
    historyLimit?: number,
    onlyFavourites?: boolean,
}

export function AppPage(props: AppPageProps) {
    const {
        app,
        pageLink,
        dataLink,
        extraLink,
        splitIndex,
        pages,
        topbarHeight,
        enableBack,
        historyLimit,
        onlyFavourites,
        root: string,
        ...otherProps
    } = { topbarHeight: 0, ...props }
    const dispatch = useAppDispatch()
    const userPermissions = useUserPermissions()
    const adminPermissions = useAdminPermissions()
    const exclusivePermissions = useExclusivePermissions()
    const validPages = useValidPages()

    const organization = useAppSelector((state: RootState) => state.app.organization)
    const historyCount = useAppSelector((state: RootState) => state.app.history.length)
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)
    const customDomain = useAppSelector((state: RootState) => state.app.customDomain)
    const salescenter = useAppSelector((state: RootState) => state.app.salescenter)
    const loggedIn = useAppSelector((state: RootState) => state.app.loggedIn)
    const prompt = useAppSelector((state: RootState) => state.app.prompt)
    const config = useAppSelector((state: RootState) => state.app.config)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const initialized = useAppSelector((state: RootState) => state.app.initialized)
    const user = useAppSelector((state: RootState) => state.user.data)
    const exclusive = useAppSelector((state: RootState) => state.app.exclusive)

    const requireLogin = app?.meta.requireLoginSalescenter

    const [navCount, setNavCount] = useState(-1)
    const [sidebarElem, setSidebarElem] = useState(null)
    const [sidebarSize, setSidebarSize] = useState(0)
    const [initialTutorial, setInitialTutorial] = useLocalStorage(`initialTutorial${salescenter ? 'salescenter' : ''}${user?.id}`, false)
    const [navTutorial, setNavTutorial] = useLocalStorage('navTutorial', false)
    const [redirectInitialized, setRedirectInitialized] = useState(0)

    if (!app || (!app.pages && !pages)) {
        logger.error('Missing app or pages')
        return null
    }

    const page = app.pages.find((x) => x.link == (pageLink || 'info'))
    const mobilePortrait = screen.isMobile && screen.size == ScreenSize.Mobile && screen.orientation == ScreenOrientation.Portrait
    const allPages = validPages(app)

    useEffect(() => {
        if ((requireLogin && salescenter && !loggedIn) || prompt) {// || !screen.focus) {
            window.dispatchEvent(new CustomEvent(CustomEventType.ShowRequestInfo))
            return
        }

        const timeout = setTimeout((e) => {
            showTutorial(e, true)
        }, 1000)
        return () => {
            clearTimeout(timeout)
        }
    }, [requireLogin, salescenter, loggedIn, prompt, screen.focus])

    useEffect(() => {
        window.addEventListener('resize', recalculateSidebar)
        window.addEventListener(CustomEventType.MenuTutorial, showTutorial)
        window.addEventListener(CustomEventType.ResizeSidebar, recalculateSidebar)
        return () => {
            window.removeEventListener('resize', recalculateSidebar)
            window.removeEventListener(CustomEventType.MenuTutorial, showTutorial)
            window.removeEventListener(CustomEventType.ResizeSidebar, recalculateSidebar)
        }
    }, [sidebarElem, exclusive])

    useEffect(() => {
        if (pageLink && redirectInitialized > 0) {
            return
        }
        if (splitIndex == null && (pageLink == null || pageLink.length == 0) && allPages.length > 0) {
            // Redirect to first page if no screensaver
            if (!allPages.find((x) => x.pageType == PageType.ScreenSaver)) {
                const firstPage = [...allPages].sort((a, b) => a.order - b.order)[0]
                logger.info('Redirecting to first page')
                dispatch(navigateAsync({ app, pageType: firstPage.pageType, options: { replaceHistory: true } }))
            }
        }
        if (pageLink == null) {
            setRedirectInitialized(1)
            const timeout = setTimeout(() => {
                setRedirectInitialized(2)
            }, 100)
        } else if (redirectInitialized == 0) {
            setRedirectInitialized(2)
        }
    }, [pageLink, redirectInitialized])

    useEffect(() => {
        setNavCount(navCount + 1)
        recalculateSidebar()

        // Double check the sidebar was accounted for correctly
        const recalcInterval = setInterval(recalculateSidebar, 100)
        setTimeout(() => {
            clearInterval(recalcInterval)
        }, 500)
        return () => {
            clearInterval(recalcInterval)
        }
    }, [pageLink, sidebarElem])

    useEffect(() => {
        recalculateSidebar()
    }, [sidebarElem ? sidebarElem.clientWidth : 0, screen.orientation])

    function showTutorial(e, initial = false) {
        if (((initial && initialTutorial) || (!initial && navTutorial)) || allPages.length < 2) {
            window.dispatchEvent(new CustomEvent(CustomEventType.ShowRequestInfo))
            return
        }

        // Get tutorial options
        const tutorialItems = []
        const tutorialPages = new Set()
        if (e && e.detail) {
            const { link, title } = e.detail
            const detailPage = allPages.find((x) => x.link == link)
            if (detailPage && detailPage.showInMenu) {
                tutorialItems.push({ id: `nav-${detailPage.link}`, title: title ? title : 'You are here' })
                tutorialPages.add(detailPage.id)
            }
        }

        if (tutorialItems.length == 0 && page && !page.draft && page.showInMenu && allPages.length > 0) {
            tutorialItems.push({ id: `nav-${page.link}`, title: 'You are here' })
            tutorialPages.add(page.id)
            /*const reqElem = document.getElementById('request-info')
            if (reqElem) {
                tutorialItems.push({
                    id: 'request-info',
                    title: 'Have questions?',
                    body: 'Request information here',
                    position: mobilePortrait ? 'above' : 'left bottom',
                    narrow: true,
                })
            }*/
        }

        // Add other configured helper items
        allPages.filter((x) => x.helper && !tutorialPages.has(x.id)).forEach((x) => {
            tutorialItems.push({ id: `nav-${x.link}`, title: x.helperText })
        })

        dispatch(showPrompt({ type: PromptType.Tutorial, items: tutorialItems }))
            .then((x) => {
                window.dispatchEvent(new CustomEvent(CustomEventType.ShowRequestInfo))
            })
        if (initial) {
            setInitialTutorial(true)
        } else {
            setNavTutorial(true)
        }
    }

    function recalculateSidebar() {
        if (sidebarElem) {
            if (screen.isMobile) {
                setSidebarSize(parseInt(fnc.getRootProperty('--mobile-nav-width').toAlphanum()))
            } else {
                setSidebarSize(sidebarElem.clientWidth)
            }
        }
    }

    function getBackButtons() {
        if (!enableBack || (historyLimit != null && historyCount <= historyLimit) || (historyLimit == null && historyCount <= 1)) {
            return null
        }
        return <React.Fragment>
            <BackButton className="only-small" fixed splitIndex={splitIndex} appLink={app.meta.link} />
            <BackButton className="only-big" fixed style={{ marginTop: `${topbarHeight}px` }} splitIndex={splitIndex} appLink={app.meta.link} />
        </React.Fragment>
    }

    function handleSearch() {
        dispatch(navigateAsync({ pageType: '' }))
    }

    // Maintenance mode
    const hasAdminPermissions = adminPermissions(app, organization)
    if (app && app.meta.maintenanceMode && !hasAdminPermissions) {
        return <Message info={ErrorMessage.MaintenanceModeApp}><Button icon="fas fa-map-marked" onClick={handleSearch} secondary large>Browse Communities</Button></Message>
    }

    let pageKey = `${pageLink}`
    let pageElem = null
    if (pageLink != null && pageLink.length > 0) {
        let pageArr = []
        let validPages = []
        if (pages) {
            pageArr = pages.map((x) => ({ pageType: x, link: x }))
            validPages = pages.filter((x) => hasAdminPermissions || !x.draft)
        } else if (app.pages) {
            validPages = allPages
            pageArr = app.pages.map((x) => {
                if ('pageType' in x) {
                    return x
                }
                return { pageType: config.pageTypes[x.pageTypeId].name, ...x }
            })
        } else {
            pageArr = [PageType.Floorplan, PageType.Modelhome].map((x) => ({ pageType: x, link: x }))
        }
        const page = pageArr.find((x) => x.link == pageLink)

        if (page) {
            // Page is in maintenance mode
            if (page.draft && !hasAdminPermissions) {
                pageElem = <Message info={ErrorMessage.MaintenanceModePage}><Button icon="fas fa-map-marked" onClick={handleSearch} secondary large>Browse Communites</Button></Message>
            } else if (page.exclusive && !exclusivePermissions(app) && !adminPermissions(app)) {
                pageElem = <Message info={ErrorMessage.ExclusivePage}></Message>
            } else {
                const PageComponent = pageMap[page.pageType]
                pageElem = <PageComponent
                    key={pageKey}
                    app={app}
                    onlyFavourites={onlyFavourites}
                    pageLink={pageLink}
                    dataLink={dataLink}
                    extraLink={extraLink}
                    splitIndex={splitIndex}
                    {...otherProps} />
            }

            // Add sidebar
            if (splitIndex == null && !onlyFavourites && validPages.length > 1) {//} && (!standaloneApp || customDomain.app)) {
                const style = { maxWidth: `calc(100% - ${sidebarSize}px)` }
                pageElem = <div className="row">
                    <div className="column content-column" style={style}>
                        {pageElem}
                    </div>
                    {allPages.length > 1 && <SideNavBar app={app} onlyFavourites={onlyFavourites} selected={pageLink} forwardRef={setSidebarElem} />}
                </div>
            }
        } else {
            // Not found
            if (splitIndex == null && !onlyFavourites && (!standaloneApp || customDomain.app)) {
                const style = { maxWidth: `calc(100% - ${sidebarSize}px)` }
                pageElem = <div className="row">
                    <div className="column content-column" style={style}>
                        <OptionBar splitIndex={splitIndex} app={app} />
                        <NotFound />
                    </div>
                    <SideNavBar app={app} onlyFavourites={onlyFavourites} selected={pageLink} forwardRef={setSidebarElem} />
                </div>
            } else if (splitIndex != null) {
                // Default to info page in compare
                // pageElem = <InfoPage
                pageElem = <DynamicPage
                    key={pageKey}
                    app={app}
                    onlyFavourites={onlyFavourites}
                    pageLink={pageLink}
                    dataLink={dataLink}
                    extraLink={extraLink}
                    splitIndex={splitIndex}
                    {...otherProps} />
            } else {
                pageElem = <React.Fragment>
                    <OptionBar splitIndex={splitIndex} app={app} />
                    <NotFound />
                </React.Fragment>
            }
        }
    } else if (standaloneApp && false) {
        pageElem = <ScreenSaverPage app={app} />
    } else {
        // Root page, determine if screensaver page or info page
        const hasScreenSaver = allPages.find((x) => x.pageType == PageType.ScreenSaver) != null
        const hasInfo = allPages.find((x) => x.pageType == PageType.Info)
        if (splitIndex == null && hasScreenSaver) {
            pageElem = <ScreenSaverPage app={app} />
        } else if (hasInfo || splitIndex != null) {
            pageElem = <DynamicPage key={pageKey} pageLink={hasInfo.link} app={app} onlyFavourites={onlyFavourites} splitIndex={splitIndex} {...otherProps} />
            // pageElem = <InfoPage app={app} onlyFavourites={onlyFavourites} splitIndex={splitIndex} {...otherProps} />
        }

        const style = { maxWidth: hasScreenSaver ? '100%' : `calc(100% - ${sidebarSize}px)` }
        if (splitIndex == null) {
            pageElem = <div className="row">
                <div className={`column content-column ${hasScreenSaver ? ' full' : ''}`} style={style}>
                    {pageElem}
                </div>
                {allPages.length > 1 && <SideNavBar app={app} onlyFavourites={onlyFavourites} selected={''} isScreenSaver={hasScreenSaver} forwardRef={setSidebarElem} />}
                {/* {!(isScreenSaver && navCount == 0) && <SideNavBar app={app} onlyFavourites={onlyFavourites} selected={''} isScreenSaver={isScreenSaver}/>} */}
            </div>
        }
    }

    if (pageElem == null) {
        pageElem = <React.Fragment>
            <OptionBar splitIndex={splitIndex} app={app} />
            <NotFound />
        </React.Fragment>
    }

    if (redirectInitialized != 2) {
        return null
    }

    return <React.Fragment>
        {getBackButtons()}
        {pageElem}
        {/* {showRequest && (!standaloneApp || customDomain.app) && splitIndex == null && !(app && (!app.meta.bookingEmail || app.meta.bookingEmail.length == 0)) && <RequestInfo app={app} />} */}
    </React.Fragment>
}

import React, { useRef } from 'react'
import {
    CloseButton,
} from 'components'
import { useAppSelector } from 'app/hooks'
import { AppPage } from 'views/AppPage/AppPage'
import { AppData, CustomEventType } from 'app/types'
import { ProjectFeatures } from 'views/HomePage/ProjectFeatures'

interface ProjectDialogProps {
    app: AppData,
    basePath: string
    pageLink: string
    onClose?: () => void,
    animation?: string,
}

export function ProjectDialog(props:ProjectDialogProps) {
    const {
        app,
        onClose,
        pageLink,
        dataLink,
        animation,
    } = props

    const current = useAppSelector((state:RootState) => state.app.projectCurrent[0])
    const page = app.pages.find((x) => x.link === pageLink)

    function handleClose() {
        onClose()
    }

    function handleMouseDown(e) {
        e.stopPropagation()
        window.dispatchEvent(new CustomEvent(CustomEventType.CloseAllMenus))
    }

    function getProjectNavigation() {
        if (!current || !current.pages) {
            return null
        }
        return <div className="project-navigation">
            <ProjectFeatures app={current} selected={pageLink}/>
        </div>
    }

    const attrs = [animation].filter((x) => x != null).map((x: string) => ` ${x}`).join()

    return <div className={`dialog${attrs}`}
        // onClick={(e) => e.stopPropagation()}
        onMouseDown={handleMouseDown}>
        <div className="dialog-wrapper">
            <div className="row project-title">
                <h4>{page.name}</h4>
                <CloseButton onClick={handleClose}/>
            </div>
            <div className="row project-content">
                <AppPage app={app} pageLink={pageLink} dataLink={dataLink} enableBack historyLimit={4}/>
            </div>
        </div>
        {getProjectNavigation()}
    </div>
}

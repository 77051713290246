import React from 'react'
import {
    Location,
} from 'app/types'

export function formatLocation(location: Location, minimal: boolean, alt: boolean) {
    let googleString = [location.numberAndStreet, location.unit, location.city, location.provinceState, location.postalZipCode]
        .filter((x) => x != null && x.length > 0).join(', ')
    let text = ''
    if (minimal) {
        text = [location.numberAndStreet, location.unit, location.city, `${location.provinceState}`, location.note]
            .filter((x) => x != null && x.length > 0).join(', ')
    } else if (alt) {
        text = [
            [location.numberAndStreet, location.unit].filter((x) => x != null && x.length > 0).join(', '),
            [location.city, location.provinceState, location.postalZipCode].filter((x) => x != null && x.length > 0).join(', '),
            [location.note],
        ]
    } else {
        text = [location.numberAndStreet, location.unit, location.city, location.provinceState, location.postalZipCode, location.note]
            .filter((x) => x != null && x.length > 0).join(', ')

    }
    // const googleLink = `https://maps.google.com/search?/api=1&query=${encodeURI(locationString)}`
    // if (location.latLng) {
    // googleString += `@${location.latLng}`
    // }
    const googleLink = `https://maps.google.com/?q=${encodeURIComponent(googleString)}`
    return [text, googleLink]
}
export function formatAddress(address: string, minimal: boolean, alt: boolean) {
    const googleLink = `https://maps.google.com/?q=${encodeURIComponent(address)}`
    return [address, googleLink]
}

interface LocationLinkProps {
    location: Location,
    address: string,
    minimal: boolean,
    onClick: () => void,
    inline: boolean,
    alt: boolean,
    altB: boolean,
    altC: boolean,
    noClick: boolean,
}

export function LocationLink(props: LocationLinkProps) {
    const { address, location, minimal, onClick, inline, alt, altB, altC, noClick } = props
    if (!location && !address) {
        return null
    }
    const [locationString, googleLink] = address ? formatAddress(address, minimal, alt || altB) : formatLocation(location, minimal, alt || altB)
    if (onClick) {
        return <div className="contact-info location" onClick={onClick}>
            <span>
                {locationString}
            </span>
        </div>
    }

    if (minimal) {
        if (noClick) {
            return <div className="contact-info location no-click">
                <span>
                    {locationString}
                </span>
            </div>
        }

        return <a className="contact-info location" href={googleLink} rel="noreferrer" target="_blank" onClick={(e) => e.stopPropagation()}>
            <span>
                {locationString}
            </span>
        </a>
    }

    if (inline) {
        return <a className="contact-info location" href={googleLink} rel="noreferrer" target="_blank" onClick={(e) => e.stopPropagation()}>
            {locationString}
        </a>
    }

    if (alt) {
        if (noClick) {
            return <span className="contact-info location alt">
                <span>{locationString.map((x, ix) => {
                    return <React.Fragment key={ix}>
                        {x}
                        {ix < locationString.length - 1 && <br />}
                    </React.Fragment>
                })}
                </span>
                <a href={googleLink} rel="noreferrer" target="_blank">(map)</a>
            </span>
        } else {
            return <a href={googleLink} rel="noreferrer" target="_blank" className="contact-info location alt">
                <span>{locationString.map((x, ix) => {
                    return <React.Fragment key={ix}>
                        {x}
                        {ix < locationString.length - 1 && <br />}
                    </React.Fragment>
                })}
                </span>
            </a>
        }

    }

    if (altB) {
        return <a className="contact-info location alt" href={googleLink} rel="noreferrer" target="_blank" onClick={(e) => e.stopPropagation()}>
            <span>{locationString.map((x, ix) => {
                return <React.Fragment key={ix}>
                    {x}
                    {ix < locationString.length - 1 && <br />}
                </React.Fragment>
            })}
            </span>
        </a>

    }

    if (altC) {
        return <a className="contact-info location alt" href={googleLink} rel="noreferrer" target="_blank" onClick={(e) => e.stopPropagation()}>
            <span style={{ textDecoration: 'underline', marginLeft: '5px' }}>(map)</span>
        </a>
    }

    return <a className="contact-info location" href={googleLink} rel="noreferrer" target="_blank" onClick={(e) => e.stopPropagation()}>
        <span>
            {locationString}
        </span>
    </a>
}

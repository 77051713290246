import React, { useState } from 'react'
import { MediaData, Dict, AppData } from 'app/types'
import { Media } from 'components'

interface TileProps extends React.FC {
    app: AppData,
    className?: string,
    media?: MediaData,
    mediaOptions?: Dict,
    title?: string,
    subtitle?: string,
    square?: boolean,
    aspect?: boolean,
    group?: boolean,
    subtile?: boolean,
    small?: boolean,
    fadeIn?: boolean,
    onClick?: () => void,
    forwardRef: React.RefObject,
    style: Dict,
    splitTitle: boolean,
}

export function Tile(props: TileProps) {
    const {
        app,
        media,
        mediaOptions,
        title,
        subtitle,
        onClick,
        className,
        square,
        aspect,
        group,
        subtile,
        children,
        small,
        fadeIn,
        forwardRef,
        style,
        splitTitle,
    } = props

    const [loaded, setLoaded] = useState(false)

    const attrs = `${className ? ` ${className}` : ''}${square ? ' square' : ''}${aspect ? ' aspect' : ''}${group ? ' group' : ''}${small ? ' small' : ''}`

    function handleLoad() {
        setTimeout(() => setLoaded(true), 200)
    }
    function getTitle() {
        if (!title) {
            return
        }
        if (splitTitle) {
            const tokens = title.split('-').map((x) => x.trim())
            if (tokens.length == 2) {
                return <h3 style={{
                    lineHeight: '25px',
                    padding: '10px 5px 3px 5px',
                }}>{tokens[0]}<br /><span className="subtitle">{tokens[1]}</span></h3>

            } else {
                if (subtitle) {
                    return <h3>
                        <span>{title}</span>
                        <span className="subtitle">{subtitle}</span>
                    </h3>
                } else {
                    return <h3> {title} </h3>
                }
            }
        }
        if (subtile) {
            return <h4>
                <span>{title}</span>
            </h4>
        } else {
            if (subtitle) {
                return <h3>
                    <span>{title}</span>
                    <span className="subtitle">{subtitle}</span>
                </h3>
            } else {
                return <h3> {title} </h3>

            }
        }
    }

    if (media != null) {
        return <div className={`tile with-media${attrs}${loaded ? ' show' : ''}`} onClick={onClick} ref={forwardRef} style={style}>
            <Media app={app} mediaId={media.id} onLoad={handleLoad} {...mediaOptions} />
            {getTitle()}
        </div>
    }

    if (group && children != null) {
        return <div className={`tile show${attrs}${fadeIn ? ' fadeIn' : ''}`} onClick={onClick} ref={forwardRef} style={style}>
            <div className="tile-wrapper">
                {children}
            </div>
        </div>
    }

    return <div className={`tile show${attrs}${fadeIn ? ' fadeIn' : ''}`} onClick={onClick} ref={forwardRef} style={style}>
        {subtile ? <h4>{title}</h4> : (title ? <h4> {title} </h4> : null)}
        {children}
    </div>
}
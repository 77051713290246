import React, { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
    useParams,
} from 'react-router-dom'
import {
    Spinner,
    Input,
    Button,
    Prompt,
    HomeBar,
    Message,
} from 'components'
import {
    showPrompt,
    navigate,
    initializeDefault,
} from 'actions/appActions'
import {
    PromptOptions,
    ErrorMessage,
    PageType,
    CustomEventType,
} from 'app/types'
import { logger } from 'helpers/logger'
import {
    retrieveTemporaryToken,
    activateAccount,
} from 'actions/userActions'

export function ActivateAccountView() {
    const { link } = useParams()
    const dispatch = useAppDispatch()
    const temporary = useAppSelector((state: RootState) => state.user.temporary)
    const error = useAppSelector((state: RootState) => state.user.error)
    const initialized = useAppSelector((state: RootState) => state.app.initialized)
    // const theme = useAppSelector((state:RootState) => state.app.config.theme)
    const [success, setSuccess] = useState(0)

    // const [initialized, setInitialized] = useState(0)

    useEffect(() => {
        const asyncActivate = async () => {
            const ret = await dispatch(retrieveTemporaryToken({ link, logOut: true }))
            try {
                if (ret.payload.success == 0) {
                    throw new Error('Failed to retrieve temporary')
                }
            } catch (e) {
                logger.error(e)
                setSuccess(-1)
            }
        }
        asyncActivate().catch(logger.error)
    }, [])

    useEffect(() => {
        const asyncActivate = async () => {
            if (temporary != null) {
                const ret = await dispatch(activateAccount())
                try {
                    if (ret.payload.success = 0) {
                        throw new Error('Failed to activate account')
                    } else {
                        setSuccess(1)
                    }
                } catch (e) {
                    logger.error(e)
                    setSuccess(-1)
                }
            }
        }
        asyncActivate().catch(logger.error)
    }, [temporary])

    function handleLogin() {
        window.dispatchEvent(new CustomEvent(CustomEventType.LogIn, { detail: { redirect: PageType.Home } }))
    }

    return <div className="content alt">
        <div className="content-wrapper">
            {initialized >= 2 && <React.Fragment>
                {error && <Message error={error} />}
                {success == 1 && <Message info="Account successfully activated! Please log in">
                    <Button tertiary large onClick={handleLogin}>Log In</Button>
                </Message>}
            </React.Fragment>}
        </div>
    </div>
}
import { getAxiosInstance, handleResponse, handleError } from 'helpers/requestHandler'
import { authHeader } from 'helpers/authHeader'

export async function postUserLogin(email: string, password: string, data: Dict) {
    return getAxiosInstance().post<string>('/user/login', { email, password, ...data })
        .then(handleResponse)
        .catch(handleError)
}

export async function postUserLogout(data: Dict) {
    return getAxiosInstance().post<string>('/user/logout', data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}


export async function postPINLogin(token: string) {
    return getAxiosInstance().post<string>('/user/login-pin', null, authHeader(token))
        .then(handleResponse)
        .catch(handleError)
}

export async function postGoogleLogin(token: string, data: Dict) {
    return getAxiosInstance().post<string>('/user/login-google', data, authHeader(token))
        .then(handleResponse)
        .catch(handleError)
}

export async function postUserRegister(email: string, name: string, phone: string, password: string, activateEmail: boolean, data: Dict) {
    return getAxiosInstance().post<string>('/user', { email, name, phone, password, activateEmail, ...data })
        .then(handleResponse)
        .catch(handleError)
}

export async function postUserRegisterPin(data: Dict) {
    return getAxiosInstance().post<string>('/user/register-pin', data)
        .then(handleResponse)
        .catch(handleError)
}

export async function putUser(userId: string, data: Dict) {
    return getAxiosInstance().post<string>(`/user/${userId}`, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postPasswordReset(oldPassword = null, newPassword = null, organizationId = null, token = null) {
    return getAxiosInstance().post('/user/reset-password', { oldPassword, newPassword, organizationId }, authHeader(token))
        .then(handleResponse)
        .catch(handleError)
}

export async function postActivateAccount(token) {
    return getAxiosInstance().post('/user/activate-account', {}, authHeader(token))
        .then(handleResponse)
        .catch(handleError)
}


export async function postPasswordForgot(email, organizationId) {
    return getAxiosInstance().post('/user/forgot-password', { email, organizationId })
        .then(handleResponse)
        .catch(handleError)
}

export async function postActivationEmail(email, organizationId) {
    return getAxiosInstance().post('/user/activate-email', { email, organizationId }, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postWelcomeEmail(email) {
    return getAxiosInstance().post('/user/welcome-email', { email }, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function getUser(token = null) {
    return getAxiosInstance().get('/user', authHeader(token))
        .then(handleResponse)
        .catch(handleError)
}

export async function getTemporaryToken(link) {
    return getAxiosInstance().get(`/temporary/${link}`)
        .then(handleResponse)
        .catch(handleError)
}

export async function getPINToken(pin, email, phone, data) {
    return getAxiosInstance().post(`/temporary/pin/${pin}`, { email, phone, ...data })
        .then(handleResponse)
        .catch(handleError)
}

export async function getPINTokenRegister(pin, email, phone, data) {
    return getAxiosInstance().post(`/temporary/pin-register/${pin}`, { email, phone, ...data })
        .then(handleResponse)
        .catch(handleError)
}

export async function postFavourite(app: AppData, floorplanId: string, variationId: string) {
    return getAxiosInstance().post(`/user/favourite`, { appId: app.meta.id, floorplanId, variationId }, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function deleteFavourite() {
    return getAxiosInstance().delete(`/user/favourite`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postRequestPIN(email: string, phone: string, key: string, organizationId: string) {
    return getAxiosInstance().post<string>('/user/pin', { email, phone, key, organizationId })
        .then(handleResponse)
        .catch(handleError)
}

export async function getUserOrderRecords(appId: string) {
    return getAxiosInstance().get<string>(`/user/order-record?appId=${appId}`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postUserOrderRecord(record) {
    return getAxiosInstance().post<string>('/user/order-record', { records: Array.isArray(record) ? record : [record] }, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function deleteUserOrderRecord(record) {
    return getAxiosInstance().delete<string>(`/user/order-record/${record.id}`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postUpgradeOrderRecord(data: Dict, token: string = null) {
    return getAxiosInstance().post<string>('/user/upgrade-order-record', data, authHeader(token))
        .then(handleResponse)
        .catch(handleError)
}

export async function postReCaptchaScore(token) {
    return getAxiosInstance().post<string>(`/user/recaptcha`, { token })
        .then(handleResponse)
        .catch(handleError)
}
import React from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
    RootState,
    PromptType,
    FloorplanData,
    FloorplanVariationData,
    PromptOptions,
    EmailType,
    AppData,
    PageType,
} from 'app/types'
import { showPrompt, sendEmail, getStateLink } from 'actions/appActions'
import {
    IconButton,
    Button,
} from 'components'
import { getMediaLink } from 'helpers/media'
import { logger } from 'helpers/logger'
import { getOptionString } from 'helpers/requestHandler'
import { getApiUrl } from 'helpers/config'
import { icons } from 'app/constants'

interface ShareButtonProps {
    app: AppData,
    floorplan: FloorplanData,
    variation: FloorplanVariationData,
    noBg?: boolean,
    alt?: boolean,
    text?: boolean,
    onlyEmail?: boolean,
}
export function ShareButton(props: ShareButtonProps) {
    const dispatch = useAppDispatch()
    const {
        app,
        floorplan,
        variation,
        noBg,
        alt,
        text,
        onlyEmail,
    } = props
    const meta = app ? app.meta : null
    const media = useAppSelector((state: RootState) => state.app.media)
    const links = useAppSelector((state: RootState) => state.app.links)
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const customDomain = useAppSelector((state: RootState) => state.app.customDomain)

    function handleClick(e) {
        e.stopPropagation()
        e.preventDefault()
        let mediaUrl = null
        let args = {}
        let title = ''
        let description = ''
        let message = ''
        let emailType = EmailType.General
        const org = organization ? organization.name : 'Home Gyde'

        if (app) {
            args.app = app.meta.link
        }

        if (organization) {
            args.organization = organization.link
        }

        if (customDomain.app) {
            args.domain = customDomain.app
        } else if (customDomain.organization) {
            args.domain = customDomain.organization
        }

        if (app && floorplan && variation) {
            // Find first relevant media
            let mediaId = null
            if (variation.floorplans.length > 0) {
                mediaId = variation.floorplans[0].mediaId
            } else if (variation.media.length > 0) {
                mediaId = variation.media[0].mediaId
            }
            if (floorplan) {
                args.floorplan = floorplan.link
            }
            if (variation) {
                args.variation = variation.id
            }

            if (mediaId) {
                mediaUrl = getMediaLink(media[mediaId].link, { appLink: meta.id })
            } else {
                logger.error('Failed to share media')
            }

            title = `${meta.name} - ${floorplan.name}`
            description = `Take a look at this floorplan from ${meta.name}`
            message = 'How would you like to share this plan?'
            emailType = EmailType.Floorplan
        } else {
            if (app) {
                title = `${app.meta.name}`
            } else {
                title = `${org} Projects`
            }
            description = `Take a look at this from ${org}`
            message = 'How would you like to share?'
            args.page = links.pageLink
            if (links.dataLink) {
                args.data = links.dataLink
            }
            if (links.appLink == 'compare') {
                args.page = links.appLink
            }

            if (links.pageLink == PageType.Favourite || links.appLink == PageType.Compare || links.pageLink == PageType.Compare || links.pageLink == PageType.Floorplan) {

                // Get relevant states
                const ret = dispatch(getStateLink({ fixFavourites: true }))


                const hash = ret.payload.split('#')
                if (hash.length > 1) {
                    args.hash = encodeURIComponent(hash[hash.length - 1])
                }
            }
        }

        const url = `${getApiUrl()}/share${getOptionString(args)}`

        if (onlyEmail) {
            dispatch(showPrompt(PromptOptions.EmailShare))
                .then((x: { payload: EmailOptions }) => {
                    if (x.payload && x.payload.value) {
                        return Promise.all([
                            dispatch(showPrompt(PromptOptions.EmailSending)),
                            dispatch(sendEmail({
                                emailType,
                                email: x.payload.value,
                                url,
                                options: { optIn: x.payload.optIn },
                            })),
                        ])
                    }
                    throw x
                })
                .catch((e) => {
                    throw e
                })
        } else {
            dispatch(showPrompt({
                type: PromptType.Share,
                title,
                description,
                url,
                media: mediaUrl,
                message,
                emailType,
            })).catch((err: Error) => logger.error(err))
        }
    }

    function handleEmail(e) {
        e.stopPropagation()
        e.preventDefault()
    }

    if (onlyEmail) {
        return <IconButton
            noBg={noBg}
            alt={alt}
            icon={icons.envelope}
            className="share-button"
            onClick={handleClick} />
    }

    if (text) {
        return <Button
            className="share-button"
            alt={alt}
            icon={icons.shareAlt}
            onClick={handleClick}>
            {text ? text : 'Share'}
        </Button>
    }

    return <IconButton
        noBg={noBg}
        alt={alt}
        icon={icons.shareAlt}
        className="share-button"
        onClick={handleClick} />
}

import React, { useState, useRef, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
    RootState,
    PromptType,
    ErrorMessage,
    GalleryMediaData,
} from 'app/types'
import {
    Media,
    BreadCrumbs,
    CompareButton,
    EmailFavouritesButton,
    ScrollHelper,
    OptionBar,
    Button,
} from 'components'
import { showPrompt } from 'actions/appActions'
import Masonry from 'react-masonry-component'
import { logger } from 'helpers/logger'
import { appendFile } from 'fs'
import * as fnc from 'helpers/fnc'
import { getWindowLocation } from 'helpers/config'

interface GalleryViewProps {
    app: AppData,
    gallery: Gallery,
    breadCrumbs?: boolean,
    splitIndex: number,
    showFilters?: boolean,
}
export default function GalleryView(props: GalleryViewProps) {
    const {
        app, gallery, breadCrumbs, splitIndex, showFilters,
    } = { showFilters: true, ...props }
    const dispatch = useAppDispatch()
    const [scrollElem, setScrollElem] = useState()
    const [refreshKey, setRefreshKey] = useState(Date.now())
    const [tags, setTags] = useState([])
    const [filter, setFilter] = useState('all')
    const masonryRef = useRef()

    if (!app || !gallery) {
        return <div className="error-message"><h2>{ErrorMessage.GalleryError}</h2></div>
    }

    useEffect(() => {
        const tagMap = fnc.objIdMap(gallery.tags)
        const newTags = [{ text: 'All', value: 'all' }, ...Object.values(gallery.media.reduce((set, x) => {
            for (let i = 0; i < x.tags.length; i += 1) {
                const tag = tagMap[x.tags[i].galleryTagId]
                set[x.tags[i].galleryTagId] = { text: tag.name.toTitleCase(), value: tag.id }
            }
            return set
        }, {}))]
        setTags(newTags)

        if (getWindowLocation().hash) {
            const hash = getWindowLocation().hash.substring(1, getWindowLocation().hash.length)
            const match = newTags.find((x) => x.text.toLowerCase() == hash)
            if (match) {
                setFilter(match.value)
            }
        }
    }, [])

    function handleFilter(x) {
        if (scrollElem) {
            scrollElem.scrollTop = 0
        }
        setFilter(x)
        setTimeout(() => {
            masonryRef.current.performLayout()
            setRefreshKey(Date.now())
        }, 0)
    }

    function getBefore() {
        if (!gallery.showTags || tags.length == 1) {
            return null
        }
        return <div className="gallery-filters topbar">
            <div className="topbar-wrapper">
                {tags.map((x) => <Button key={x.value} className={`gallery-filter${filter == x.value ? ' selected' : ''}`} onClick={() => handleFilter(x.value)}>{x.text}</Button>)}
            </div>
        </div>
    }

    function handleMedia(mediaId: string) {
        // Start lightbox gallery
        const mediaList = gallery.media.filter(filterMedia).map((x) => x.mediaId)
        const startIndex = mediaList.findIndex((x) => x === mediaId)
        dispatch(showPrompt({
            type: PromptType.Lightbox,
            app,
            media: mediaList,
            startIndex,
            mediaOptions: { autoplay: mediaId },
        })).catch(logger.error)
        // mediaOptions: { thumb: true, thumbSize: 1024 },
    }

    function filterMedia(media: GalleryMediaData) {
        if (filter == null || filter === 'all') {
            return true
        }
        return media.tags.findIndex((x) => x.galleryTagId === filter) !== -1
    }

    function getGallery() {
        const masonryOptions = {
            // itemSelector: '.media',
            itemSelector: '.media-wrapper',
            hiddenStyle: {
                transform: 'none',
            },
            visibleStyle: {
                transform: 'none',
            },
        }
        return <Masonry
            columns={4}
            options={masonryOptions}
            updateOnEachImageLoad
            ref={masonryRef}
            className="masonry">
            {gallery.media.filter(filterMedia).map((x) => <div className="media-wrapper"><Media
                app={app}
                key={x.mediaId}
                thumb
                thumbSize={1024}
                mediaId={x.mediaId}
                // fadeIn
                fadeIn
                fadeInDelay={200}
                onClick={() => handleMedia(x.mediaId)} />
            </div>)}
        </Masonry>
    }

    if (gallery) {
        return <React.Fragment>
            {getBefore()}
            <div id="gallery-view" className="content scrollable" ref={setScrollElem}>
                {breadCrumbs && <BreadCrumbs app={app} splitIndex={splitIndex} />}
                {/* {<OptionBar app={app} splitIndex={splitIndex} titleText={app.meta.name}/>} */}
                <div className="content-wrapper">
                    {getGallery()}
                </div>
                <ScrollHelper scrollElem={scrollElem} key={refreshKey} />
            </div>
        </React.Fragment>
    }
    return <div id="gallery-view" className="content scrollable">
        <div className="content-wrapper">
            <h1>{`Couldn't find gallery ${galleryLink}`}</h1>
        </div>
    </div>
}

import React, { useState, useEffect, useRef } from 'react'
import { Input, DateSelector, Button, Media, BackButton, CloseButton } from 'components'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { PromptOptions, ErrorMessage, ThumbSize, RootState, AnalyticsEvent } from 'app/types'
import { submitBooking, showPrompt, analyticsEvent } from 'actions/appActions'
import * as fnc from 'helpers/fnc'
import { validateEmail, validatePhone } from 'helpers/authHeader'
import { contactText } from 'app/constants'
import { googleTagReportConversion } from 'helpers/scripts'

interface BookingTileProps {
    app: AppData,
    title?: string,
    submitText?: string,
    launch?: string,
    calendar?: boolean,
    compact: boolean
}
export function BookingTile(props: BookingTileProps) {
    const { app, title, submitText, launch, calendar, compact } = { title: 'Request Information', ...props }
    const dispatch = useAppDispatch()
    const user = useAppSelector((state: RootState) => state.user.data)
    const config = useAppSelector((state: RootState) => state.app.config)
    const media = useAppSelector((state: RootState) => state.app.media)
    const teamId = useAppSelector((state: RootState) => state.app.teamId)
    if (!app) {
        return null
    }
    const organizations = (app && config && app.meta.organizations) ?
        app.meta.organizations.map((x) => config.organizations.find((y) => y.id == x))
            .filter((x) => x != null)
        : null

    const [name, setName] = useState((user && user.name) ? user.name : '')
    const [phone, setPhone] = useState((user && user.phone) ? user.phone : '')
    const [email, setEmail] = useState((user && user.email) ? user.email : '')
    const defaultMessage = `Please send me information about ${app.meta.name}.`
    const [message, setMessage] = useState(defaultMessage)
    const [error, setError] = useState(null)
    const [formKey, setFormKey] = useState(0)

    const [index, setIndex] = useState(0)
    const [bookingElements, setBookingElements] = useState([])

    const [agents, setAgents] = useState([])
    const [bookingAgent, setBookingAgent] = useState(null)
    const [formComplete, setFormComplete] = useState(false)


    const contactInfoRef = useRef()

    useEffect(() => {
        const agentSet = new Set(app.meta.agents)
        const newAgents = []
        let newBookingAgent = null
        organizations.forEach((x) => {
            x.agents.forEach((y) => {
                // If global agent in use, idenitify member in organization
                if (teamId && !newBookingAgent && y.teamId == teamId) {
                    newBookingAgent = y
                }
                if (agentSet.has(y.id)) {
                    newAgents.push(y)
                }
            })
        })
        setAgents(newAgents)
        setBookingAgent(newBookingAgent)
        setIndex(0)
    }, [teamId])

    useEffect(() => {
        if (formComplete) {
            return
        }
        if (name && name.length > 0
            && email && email.length > 0
            && phone && phone.length > 0
            && message && message.length > 0) {
            dispatch(analyticsEvent(AnalyticsEvent.FillBooking))
            setFormComplete(true)
        }

    }, [name, email, phone, message])



    function handleSubmit(e) {
        setError(null)
        e.preventDefault()
        // Client side validation
        if (name.length == 0 || phone.length == 0) {
            setError('Please fill out all fields')
            return
        }
        if (validateEmail(email) != null) {
            setError('Invalid email')
            return
        }
        if (validatePhone(phone) != null) {
            setError('Invalid phone number')
            return
        }
        const bookingData = { name, phone, email, message }
        if (bookingAgent) {
            bookingData.agentId = bookingAgent.id
        }

        bookingData.analyticsEventTypeId = AnalyticsEvent.RequestInformation
        dispatch(analyticsEvent(bookingData.analyticsEventTypeId))

        Promise.all([
            dispatch(showPrompt(PromptOptions.SubmitBooking)),
            dispatch(submitBooking({ app, data: bookingData }))
        ])
            .then(([a, b]) => {
                try {
                    if (!b.payload.success) {
                        setError(b.payload.message)
                    } else {
                        setIndex(0)
                        setName('')
                        setPhone('')
                        setEmail('')
                        setMessage(defaultMessage)
                        setFormKey(formKey + 1)
                    }
                } catch (e) {
                    throw e
                }
            }).catch((e) => {
                setError(ErrorMessage.BookingError)
            })
    }

    const bookingStyle = {
        opacity: 1,
    }

    function getAgent() {
        if (agents.length > 0) {
            const agent = agents[0]
            return <div className="column">
                <a href={`phone:${agent.phone}`}><span><i className="fas fa-phone" />{fnc.phoneNumber(agent.phone)}</span></a>
                <a href={`mailto:${agent.email}`}><span><i className="fas fa-envelope" />{agent.email}</span></a>
            </div>
        }
        return null
        /*return <div className="column agents">
            {agents.map((agent) => {
                return <div className="column agent">
                    <span>{agent.name}</span>
                    <div className="row">
                        <a href={`phone:${agent.phone}`}><span><i className="fas fa-phone"/>{agent.phone}</span></a>
                        <a href={`mailto:${agent.email}`}><span><i className="fas fa-envelope"/>{agent.email}</span></a>
                    </div>
                </div>
            })}
        </div>*/
    }


    const newBookingElements = []
    if (bookingAgent) {
        let logoStyle = {}
        if (contactInfoRef.current) {
            logoStyle.width = `${contactInfoRef.current.clientHeight}px`
            logoStyle.height = `${contactInfoRef.current.clientHeight}px`
        }

        newBookingElements.push({
            style: {},
            element: <React.Fragment>
                <div className="row" style={{ flexDirection: 'column' }}>
                    {bookingAgent.photoMediaId && <div className="row agent-photo">
                        <Media mediaId={bookingAgent.photoMediaId} thumb thumbSize={ThumbSize.Large} />
                    </div>}
                    <div className="row agent-info">
                        {bookingAgent.logoMediaId && <div className="agent-logo" style={logoStyle}>
                            <Media mediaId={bookingAgent.logoMediaId} thumb thumbSize={ThumbSize.Small} />
                        </div>}
                        <div className="agent-contact-info" ref={contactInfoRef}>
                            {bookingAgent.name && <span className="agent-name">{bookingAgent.name}{bookingAgent.designation ? `, ${bookingAgent.designation}` : ''}</span>}
                            {bookingAgent.credentials && <span className="agent-profession">{bookingAgent.credentials}</span>}
                            {bookingAgent.phone && <a className="agent-phone" href={`phone:${bookingAgent.phone}`}><span>{fnc.phoneNumber(bookingAgent.phone)}</span></a>}
                            {bookingAgent.email && <a className="agent-email" href={`mailto:${bookingAgent.email}`}><span>{bookingAgent.email}</span></a>}
                        </div>
                    </div>
                </div>
                {compact && <div className="row">
                    <Button tertiary onClick={() => setIndex(index + 1)}>{contactText}</Button>
                </div>}
            </React.Fragment>
        })
    }

    if (app.meta.bookingEmail) {
        newBookingElements.push({
            style: { backgroundColor: '#e9e9e9' },
            element: <React.Fragment>
                {newBookingElements.length > 0 && compact && <CloseButton onClick={() => setIndex(index - 1)} />}
                {title && <h4>{title}</h4>}
                <div className="divider" />
                {launch && app.meta.bookingLaunch && <span>{launch}{app.meta.bookingLaunch}</span>}
                {error && <span className="error">{error}</span>}
                <form onSubmit={handleSubmit} key={formKey}>
                    <Input placeholder="Name" value={name} onChange={setName} />
                    <Input phone placeholder="Phone" value={phone} onChange={setPhone} />
                    <Input placeholder="Email" value={email} onChange={setEmail} />
                    <Input textarea value={message} onChange={setMessage} />
                    {calendar && <DateSelector />}
                    <Button tertiary>{submitText ? submitText : contactText}</Button>
                </form>
            </React.Fragment>
        })
    } else {
        newBookingElements.push({
            style: {},
            element: <React.Fragment>
                <div className="divider"></div>
                <h4>This project has no contact information at this time</h4>
                <div className="divider"></div>
            </React.Fragment>,
        })
    }

    return <div className="contact-info-group" style={bookingStyle}>
        {newBookingElements.map((x, ix) => {
            const tileStyle = { opacity: 1, ...x.style }
            if (compact) {
                // tileStyle.transform = `translateX(${(ix - index) * 100}%)`
                tileStyle.transform = `translateX(${index * -100}%)`
                if (ix == index) {
                    // tileStyle.position = 'relative'
                } else {
                    // tileStyle.position = 'absolute'
                }
            }
            return <div className="tile contact-tile scrollable" style={tileStyle} key={ix}>
                {x.element}
            </div>
        })}
        {/*agents.length > 0 && <React.Fragment><div className="divider large"/>
        <div className="row">
            <div className="column builder-contact">
                <h5>Contact Builder</h5>
                {getAgent()}
            </div>
        </div></React.Fragment>*/}
    </div>
}
import React from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { Button } from 'components'
import { PageType } from 'app/types'
import { navigateAsync } from 'actions/appActions'
import { logger } from 'helpers/logger'

interface CompareButtonProps {
    text?: string,
}

export function CompareButton(props: CompareButtonProps) {
    const current = useAppSelector((state: RootState) => state.app.projectCurrent[0])
    const links = useAppSelector((state:RootState) => state.app.links)
    const dispatch = useAppDispatch()

    const handleCompare = () => {
        dispatch(navigateAsync({ app: current && current.meta.link == links.appLink ? current : null, pageType: PageType.Compare }))
            .catch(logger.error)
    }

    return <Button onClick={handleCompare} {...props}>{props.text ? props.text : 'Compare'}</Button>
}
import React, { useState, useEffect, useRef } from 'react'
import { logger } from 'helpers/logger'
import {
    CloseButton,
    Input,
    Checkbox,
    Button,
    DropdownMenu,
} from 'components'
import { Link } from 'react-router-dom'
import { validateEmail, validatePassword, validateUsername, validatePasswordConfirm, validatePhone } from 'helpers/authHeader'
import { RootState, ValidateType, NestedDict, CustomEventType } from 'app/types'
import { useAppSelector } from 'app/hooks'
import * as fnc from 'helpers/fnc'

interface InputDialogProps {
    id?: string,
    title: string,
    subtitle: string,
    placeholder: string,
    onSubmit: (x: string) => void,
    onClose?: () => void,
    animation?: string,
    validation?: ValidateType,
    inputOptions?: { [key: string]: string },
    error?: string,
    optIn?: boolean
    fields: NestedDict[],
    options: NestedDict[],
    noDismiss?: boolean,
    extra?: JSX.Element,
    agreement?: boolean,
    login?: boolean,
    loginText?: string,
    register?: boolean,
    registerText?: string,
    google?: boolean,
    googleText?: string,
    resend?: boolean,
    resendText: string,
}

const validationOperations: { [key: string]: (x: string) => boolean } = {}
validationOperations[ValidateType.Email] = validateEmail
validationOperations[ValidateType.Name] = validateUsername
validationOperations[ValidateType.Phone] = validatePhone
validationOperations[ValidateType.Password] = validatePassword
validationOperations[ValidateType.PasswordMatch] = validatePasswordConfirm

export function InputDialog(props: InputDialogProps) {
    const {
        id,
        title,
        subtitle,
        placeholder,
        onSubmit,
        onClose,
        animation,
        validation,
        inputOptions,
        optIn,
        fields,
        options,
        extra,
        noDismiss,
        agreement,
        login,
        loginText,
        register,
        registerText,
        google,
        googleText,
        resend,
        resendText,
    } = props

    const [value, setValue] = useState<string | string[]>(fields ? fields.map((x) => {
        if ('inputOptions' in x && 'value' in x.inputOptions) return x?.inputOptions?.value
        return ''
    }) : [''])
    const [error, setError] = useState(props.error ? props.error : '')
    const [optInValue, setOptInValue] = useState(false)
    const rootPath = useAppSelector((state: RootState) => state.app.rootPath)
    const organization = useAppSelector((state: RootState) => state.app.organization || state.admin.organization)
    const valueProxy = useRef(null)

    const current = useAppSelector((state: RootState) => state.app.current)
    const appName = current ? current.meta.name : 'Home Gyde'
    const enableNewsletter = current ? current.meta.enableNewsletter : false

    useEffect(() => {
        const timeouts = []
        if (google) {
            const elem = document.getElementById("google-login")
            if (elem?.childNodes.length == 0) {
                window.google.accounts.id.renderButton(
                    elem,
                    { theme: "outline", size: "large" }  // customization attributes
                )
            }
            if (!elem?.classList.contains('show')) {
                // Delay prevents flicker
                timeouts.push(setTimeout(() => {
                    elem.classList.add('show')
                }, 300))
            }
        }
        return () => {
            timeouts.forEach(clearTimeout)
        }
    }, [])

    function handleValueChange(ix: number, x: string) {
        const newValues = [...value]
        newValues[ix] = x
        valueProxy.current = newValues
        setValue(newValues)
    }

    function handleSubmit() {
        // Apply validation operation
        const finalValue = valueProxy.current || value// Context hack
        for (let i = 0; i < finalValue.length; i += 1) {
            let val = (fields && i < fields.length ? fields[i].validation : null) || validation
            if (val) {
                if (val in validationOperations) {
                    switch (val) {
                        case ValidateType.PasswordMatch:
                            if (finalValue.length > 1 && i > 0) {
                                const validate = validationOperations[val](finalValue[i], finalValue[i - 1])
                                if (validate != null) {
                                    setError(validate)
                                    return
                                }
                            }
                            break
                        default:
                            const validate = validationOperations[val](finalValue[i])
                            if (validate != null) {
                                setError(validate)
                                return
                            }
                            break
                    }
                } else {
                    logger.error(`Missing validation function ${validation}`)
                }
            }
        }
        setError('')
        if (onSubmit) {
            if (optIn) {
                onSubmit({ value: finalValue.length === 1 ? finalValue[0] : finalValue, optIn: optInValue })
            } else {
                onSubmit(finalValue.length === 1 ? finalValue[0] : finalValue)
            }
        }
    }

    function handleEvent(event: string) {
        if (onSubmit) {
            onSubmit({ event })
        }
    }

    function handleRegister() {
        handleEvent(CustomEventType.Register)
    }

    function handleLogin() {
        handleEvent(CustomEventType.LogIn)
    }

    function handleResend() {
        handleEvent(CustomEventType.Resend)
    }

    function handleGoogle() {
        handleEvent(CustomEventType.GoogleLogIn)
    }

    function handleClose() {
        onClose()
    }


    let prefix = ''
    if (rootPath.length > 1) {
        prefix += fnc.trimTrailingSlash(rootPath)
    }


    return <div id={id} className={`dialog ${animation}`} onClick={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
        {extra}
        <div className="title">
            <h3>{title}</h3>
            {subtitle && <h4>{subtitle}</h4>}
            {error && <h5 className="error">{error}</h5>}
        </div>
        <div className="dialog-wrapper">
            {!noDismiss && <CloseButton alt onClick={handleClose} />}
            <div className="row dialog-body">
                {!fields && !options && <Input
                    autoFocus
                    placeholder={placeholder}
                    onSubmit={handleSubmit}
                    onChange={(x) => handleValueChange(0, x)}
                    onEvent={handleEvent}
                    submit
                    submitAfter={fields && fields.length > 1}
                    {...inputOptions} />
                }
                {google && <div className="google-wrapper" id="google-login">
                    {/*<Button className="google" onClick={handleGoogle}><img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNS4yNzggMTMuMjljMC0uOTA2LS4wODEtMS43NzgtLjIzMy0yLjYxNUgxM3Y0Ljk0N2g2Ljg4M2E1Ljg4NCA1Ljg4NCAwIDAxLTIuNTUyIDMuODZ2My4yMDloNC4xMzNjMi40MTktMi4yMjcgMy44MTQtNS41MDUgMy44MTQtOS40eiIgZmlsbD0iIzQyODVGNCIvPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTMgMjUuNzljMy40NTMgMCA2LjM0OC0xLjE0NiA4LjQ2NC0zLjFsLTQuMTMzLTMuMjA4Yy0xLjE0NS43NjctMi42MSAxLjIyLTQuMzMxIDEuMjItMy4zMzEgMC02LjE1LTIuMjQ5LTcuMTU2LTUuMjcySDEuNTd2My4zMTRBMTIuNzg1IDEyLjc4NSAwIDAwMTMgMjUuNzl6IiBmaWxsPSIjMzRBODUzIi8+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01Ljg0NCAxNS40M0E3LjY4OCA3LjY4OCAwIDAxNS40NDMgMTNjMC0uODQzLjE0NS0xLjY2My40LTIuNDNWNy4yNTZIMS41NzJBMTIuNzg0IDEyLjc4NCAwIDAwLjIxIDEzYzAgMi4wNjQuNDk0IDQuMDE3IDEuMzYgNS43NDRsNC4yNzMtMy4zMTR6IiBmaWxsPSIjRkJCQzA1Ii8+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMyA1LjI5N2MxLjg3OCAwIDMuNTY0LjY0NiA0Ljg5IDEuOTEzbDMuNjY3LTMuNjY4QzE5LjM0MiAxLjQ3OCAxNi40NDcuMjEgMTMgLjIxYy01IDAtOS4zMjUgMi44NjYtMTEuNDMgNy4wNDVsNC4yNzQgMy4zMTRDNi44NDkgNy41NDcgOS42NjkgNS4yOTcgMTMgNS4yOTd6IiBmaWxsPSIjRUE0MzM1Ii8+PC9zdmc+" />
                        {googleText}
            </Button>*/}
                </div>}
                {fields && fields.map((x, ix) => {
                    let inputElem = null
                    if (x.enum) {
                        inputElem = <DropdownMenu
                            key={ix}
                            items={x.enum}
                            value={value[ix]}
                            onChange={(x) => handleValueChange(ix, x)}
                            text={!value[ix] ? (x.select ? x.select : null) : null}
                            {...x.inputOptions} />
                    } else {
                        inputElem = <Input
                            key={ix}
                            autoFocus={ix == 0}
                            placeholder={x.placeholder}
                            onSubmit={handleSubmit}
                            onChange={(x) => handleValueChange(ix, x)}
                            onEvent={handleEvent}
                            submit={ix == (fields.length - 1)}
                            submitAfter={fields && fields.length > 1}
                            {...(x.inputOptions)} />
                    }
                    if (x.label) {
                        return <div className="column">
                            <div className="row">
                                <label><span>{x.label}</span></label>
                            </div>
                            <div className="row">
                                {inputElem}
                            </div>
                        </div>
                    } else {
                        return inputElem
                    }
                }
                )}
                {options && options.map((x, ix) => {
                    if (x.button) {
                        return <div className="row">
                            <Button onClick={() => handleEvent(x.key)} {...x.buttonProps}>{x.label}</Button>
                        </div>
                    } else if (x.submit) {
                        return <div className="row">
                            <Button onClick={handleSubmit} {...x.buttonProps}>{x.label}</Button>
                        </div>
                    } else {
                        return <div className="row" style={{ justifyContent: 'center' }}>
                            <a style={{ cursor: 'pointer' }} onClick={() => handleEvent(x.key)}><span>{x.label}</span></a>
                        </div>
                    }

                })}
                {/* Misc input options */}
                {optIn && (!optIn.includes('newsletter') || enableNewsletter) && <Checkbox name="optIn" value={optInValue} onChange={setOptInValue}>{optIn.replace('{{app_name}}', appName)}</Checkbox>}
                {register && <div className="row">
                    <div className="register noselect"><span>{registerText}<a onClick={handleRegister}>Sign up now!</a></span></div>
                </div>}
                {login && <div className="row">
                    <div className="login noselect"><span>{loginText}<a onClick={handleLogin}>Log in.</a></span></div>
                </div>}
                {resend && <div className="row">
                    <div className="resend noselect"><span><a onClick={handleResend}>{resendText}</a></span></div>
                </div>}
                {agreement && organization && <div className="row">
                    {/* <div className="agreement noselect"><span>By joining Home Gyde you agree to our <Link to="/legal/terms-of-use">Terms of Use</Link> and <Link to="/legal/privacy-policy">Privacy Policy</Link></span></div> */}
                    <div className="agreement noselect"><span>By joining {organization.name} you agree to our <a href={`${prefix}/privacy-policy`} target="_blank"><span>Privacy Policy</span></a></span></div>


                </div>}
            </div>
        </div>
    </div >
}

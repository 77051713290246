import { FloorplanFilter, UnitFilter } from 'app/types'
import * as fnc from 'helpers/fnc'
import { logger } from 'helpers/logger'

export const initialRanges: Range = {
    [FloorplanFilter.Beds]: [],
    [FloorplanFilter.Garage]: [],
    [FloorplanFilter.Baths]: [],
    [UnitFilter.Floor]: [],
    [UnitFilter.Exposure]: [],
    [FloorplanFilter.Sqft]: { min: 10000, max: 0 },
    [FloorplanFilter.Price]: { min: 500000, max: 0 },
}

/**
 * Parse app floorplans and create unit map
 */
export function setupAppMaps(app: AppData) {
    app.maps = { floorplanLink: {}, floorplan: {}, floorplanVariation: {}, floorplanVariationFloorplan: {}, floorplanVariationLink: {}, unitName: {}, unit: {}, availabilityState: {} }
    for (let i = 0; i < app.floorplans.length; i += 1) {
        app.maps.floorplanLink[app.floorplans[i].link] = app.floorplans[i]
        app.maps.floorplan[app.floorplans[i].id] = app.floorplans[i]
        app.floorplans[i].units = []

        app.floorplans[i].variations.forEach((x) => {
            app.maps.floorplanVariation[x.id] = x
            app.maps.floorplanVariationLink[x.link] = x
            app.maps.floorplanVariationFloorplan[x.id] = app.floorplans[i]
        })
    }
    for (let i = 0; i < app.units.length; i += 1) {
        const unit = app.units[i]
        app.maps.unitName[unit.name] = unit
        app.maps.unit[unit.id] = unit

        for (let j = 0; j < unit.floorplans.length; j += 1) {
            const { floorplanId, floorplanVariationId } = unit.floorplans[j]
            const floorplan = app.maps.floorplan[floorplanId]
            if (!floorplan) {
                // logger.info(`Missing floorplan for ${unit.floorplans[j]}`)
                continue
            }
            if (!floorplan.units) {
                floorplan.units = []
            }
            floorplan.units.push({ id: unit.id, name: unit.name, ix: i, floorplanVariationId })
        }
    }

    app.maps.site = app.sites ? app.sites.reduce((a, x, ix) => ({ ...a, [x.id]: x }), {}) : {}
    app.maps.building = app.buildings ? app.buildings.reduce((a, x, ix) => ({ ...a, [x.id]: x }), {}) : {}
    app.maps.buildingType = app.buildingTypes ? fnc.objIdMap(app.buildingTypes) : {}
    app.maps.availabilityState = app.meta.availabilityStates ? app.meta.availabilityStates.reduce((a, x, ix) => ({ ...a, [x.id]: x }), {}) : {}
    app.maps.series = app.meta.floorplanSeries ? app.meta.floorplanSeries.reduce((a, x, ix) => ({ ...a, [x.id]: x }), {}) : {}
}

/**
 *  Setup pages
 */
export function setupPages(pages) {
    if (pages) {
        return pages.map((x) => {
            return { ...x, name: x.name.replace(/&amp;/g, '&') }
        })
    } else {
        return pages
    }
}

/**
 * Parse app meta
 */
export function setupAppMeta(app: AppData) {
    app.meta.favouriteIcon = 'fa-heart'
    app.meta.stateMap = {}
    for (let i = 0; i < app.meta.availabilityStates.length; i += 1) {
        const avail = app.meta.availabilityStates[i]
        app.meta.stateMap[avail.id] = avail
    }

    // Generate filter ranges
    const bedSet = new Set()
    const bathSet = new Set()
    const garageSet = new Set()
    const floorSet = new Set()
    const exposureSet = new Set()
    const newRange: Range = fnc.copyObj<Range>(initialRanges)
    const { floorplans, units } = app
    for (let i = 0; i < floorplans.length; i += 1) {
        // Pull data from variations
        for (let j = 0; j < floorplans[i].variations.length; j += 1) {
            const variation = floorplans[i].variations[j]
            if (variation.beds != null) {
                bedSet.add(Math.floor(variation.beds).toString())
                if (variation.den) {
                    bedSet.add(`${Math.floor(variation.beds)} + D`)
                }
            }
            if (variation.baths != null) {
                bathSet.add(Math.floor(variation.baths).toString())
            }
            if (variation.garage != null) {
                garageSet.add(variation.garage.toString())
            }
            if (variation.sqft != null) {
                newRange[FloorplanFilter.Sqft].min = Math.min(
                    newRange[FloorplanFilter.Sqft].min, variation.sqft,
                )
                newRange[FloorplanFilter.Sqft].max = Math.max(
                    newRange[FloorplanFilter.Sqft].max, variation.sqft,
                )
            }
            if (variation.price != null) {
                newRange[FloorplanFilter.Price].min = Math.min(
                    newRange[FloorplanFilter.Price].min, variation.price,
                )
                newRange[FloorplanFilter.Price].max = Math.max(
                    newRange[FloorplanFilter.Price].max, variation.price,
                )
            }
        }
    }


    // Pull data from units
    app.meta.stats.units = units.length
    // Generate by-building stats
    for (let i = 0; i < units.length; i += 1) {
        const unit = units[i]

        if (unit.exposure) {
            exposureSet.add(unit.exposure[0].toUpperCase())
        }

        if (unit.floor) {
            let floors = []
            if (unit.floor.includes('-')) {
                const split = unit.floor.split('-').map((x) => parseInt(x))
                if (split[0] < split[1]) {
                    for (let k = split[0]; k <= split[1]; k += 1) {
                        floors.push(k)
                    }
                }
            } else {
                floors = [parseInt(unit.floor)]
            }
            floors.forEach((x) => {
                if (!floorSet.has(x)) {
                    floorSet.add(x)
                }
            })
        }
    }

    const collectStats = (phase = null) => {
        const buildingPlans = {}
        const buildingTypeStats = {}
        let phaseBuildingSet = null
        if (phase) {
            phaseBuildingSet = new Set(phase.building)
        }
        for (let i = 0; i < units.length; i += 1) {
            const unit = units[i]
            // Determine building type
            const building = app.maps.building[unit.buildingId]

            if (!building || (phaseBuildingSet && phaseBuildingSet.has(building.id))) {
                continue
            }

            // Grab sqft, beds, and baths from related floorplans
            if (!(building.buildingTypeId in buildingPlans)) {
                buildingPlans[building.buildingTypeId] = {}
            }

            if (!(building.buildingTypeId in buildingTypeStats)) {
                buildingTypeStats[building.buildingTypeId] = {
                    type: building.buildingTypeId,
                    units: 0,
                    sqft: new Set(),
                    beds: new Set(),
                    baths: new Set(),
                }
            }
            buildingTypeStats[building.buildingTypeId].units += 1
            unit.floorplans.forEach((x) => {
                if (!(x.floorplanId in buildingPlans[building.buildingTypeId])) {

                    const plan = app.maps.floorplan[x.floorplanId]
                    if (plan) {
                        buildingPlans[building.buildingTypeId][x.floorplanId] = plan
                    }
                }
            })
        }
        // Building plans are collected, generate stats
        Object.keys(buildingPlans).forEach((x) => {
            const fields = ['sqft', 'beds', 'baths']
            Object.keys(buildingPlans[x]).forEach((y) => {
                const plan = buildingPlans[x][y]
                plan.variations.forEach((variation) => {
                    fields.forEach((z) => {
                        if (variation[z]) {
                            buildingTypeStats[x][z].add(variation[z])
                            buildingTypeStats[x][z].add(variation[z])
                        }
                    })
                })
            })
            fields.forEach((y) => {
                buildingTypeStats[x][y] = Array.from(buildingTypeStats[x][y]).sort()
            })
        })
        return buildingTypeStats
    }

    app.meta.stats.buildingType = collectStats()
    app.meta.stats.phase = app.phases ? app.phases.reduce((acc, x) => ({ ...acc, [x.id]: collectStats(x) }), {}) : {}
    app.meta.stats.floors = floorSet.size

    newRange[FloorplanFilter.Series] = app.meta.floorplanSeries
    newRange[FloorplanFilter.Beds] = Array.from(bedSet).sort((a, b) => a.toNumeric() - b.toNumeric())
    newRange[FloorplanFilter.Baths] = Array.from(bathSet).sort((a, b) => a.toNumeric() - b.toNumeric())
    newRange[FloorplanFilter.Garage] = Array.from(garageSet).sort((a, b) => a.toNumeric() - b.toNumeric())
    newRange[UnitFilter.Floor] = Array.from(floorSet).sort((a, b) => a - b)
    newRange[FloorplanFilter.BuildingType] = Object.keys(app.maps.buildingType)
    newRange[FloorplanFilter.Availability] = app.meta.availabilityStates.map((x) => x.name)

    for (let i = 0; i < app.meta.floorplanGroupings.length; i += 1) {
        const { name, min, max } = app.meta.floorplanGroupings[i]
        // Find min index
        const minIndex = newRange[UnitFilter.Floor].findIndex((x) => x === min)
        if (minIndex !== -1) {
            newRange[UnitFilter.Floor].splice(minIndex, 0, { text: name, range: { min, max } })
        }
    }

    const exposureSort = {
        N: 1,
        S: 2,
        E: 3,
        W: 4,
    }

    newRange[UnitFilter.Exposure] = Array
        .from(exposureSet)
        .sort((a: string, b: string) => exposureSort[a[0]] - exposureSort[b[0]])

    app.meta.filterRanges = newRange
}

/**
 * Parse a database app response into the app state
 */
export function setupApp(payload: AppData, fullPage = true, config = null) {
    // Manually build data
    let newApp = {}
    newApp.meta = payload.meta
    newApp.fullPage = fullPage

    // Create stateMap
    newApp.floorplans = payload.floorplans
    newApp.units = payload.units.sort((a, b) => {
        const orderA = a.name.toString().toNumeric()
        const orderB = b.name.toString().toNumeric()
        return orderA - orderB
    })
    newApp.buildings = payload.buildings.sort((a, b) => a.name.localeCompare(b.name))
    newApp.sites = payload.sites
    newApp.upgrades = payload.upgrades
    newApp.sitemaps = payload.sitemaps
    newApp.phases = payload.phases
    newApp.dynamicContent = payload.dynamicContent
    newApp.locations = payload.locations
    // newApp.rooms = payload.rooms
    newApp.buildingTypes = payload.buildingTypes
    // Process certain fields in sitemaps
    const unitMap = newApp.units.reduce((acc, x) => ({ ...acc, [x.id]: x }), {})
    /*newApp.sitemaps.forEach((x) => {
        x.units.forEach((y) => {
            y.unitName = unitMap[y.unitId].name
        })
    })*/

    newApp.spins = payload.spins
    // Process certain fields in spin
    /*newApp.spins.forEach((x) => {
        x.views.forEach((y) => {
            y.elements.forEach((z) => {
                Object.keys(z).forEach((a) => {
                    if (a.includes('icon')) {
                        let key = a.replace('icon', '').toString()
                        key = key.charAt(0).toLowerCase() + key.slice(1);
                        if (!z.icon) {
                            z.icon = {}
                        }
                        z.icon[key] = z[a]
                        delete z[a]
                    }
                })
            })
        })
    })*/

    // Create history
    // if (initial) {
    // setupHistory(state)
    // }

    // Add units to floorplans
    setupAppMaps(newApp)
    setupAppMeta(newApp, true)


    const applyConfigType = (x, typeName) => {
        const y = { ...x }
        const map = config[`${typeName}s`]
        const idName = `${typeName}Id`
        const id = y[idName]
        y[typeName] = map[id].name
        // delete y[idName]
        return y
    }

    // Non critical update
    if (!payload.critical) {
        newApp.builder = payload.builder
        newApp.menus = payload.menus.map((x) => applyConfigType(x, 'menuType'))
        newApp.pages = payload.pages.map((x, ix) => { x.order = ix; return applyConfigType(x, 'pageType') })
        newApp.pages = setupPages(newApp.pages)
        newApp.modelhomes = payload.modelhomes
        newApp.galleries = payload.galleries
    }
    return newApp
}

/// https://stackoverflow.com/questions/38213668/promise-retry-design-patterns
const RETRY_INTERVAL = process.env.RETRY_INTERVAL
const RETRY_COUNT = process.env.RETRY_COUNT

const wait = ms => new Promise(r => setTimeout(r, ms))
export function retryOperation(operation, delay = RETRY_INTERVAL, retries = RETRY_COUNT) {
    return new Promise((resolve, reject) => {
        return operation()
            .then(resolve)
            .catch((reason) => {
                logger.info("Operation failed trying again", operation.name, delay, retries, reason)
                if (retries > 0 && !(reason && reason.status && reason.status != 500)) {
                    return wait(delay)
                        .then(retryOperation.bind(null, operation, delay, retries - 1))
                        .then(resolve)
                        .catch(reject)
                }
                logger.error("Operation failed after full retry", operation.name, delay, retries, reason)
                return reject(reason)
            })
    })
}

export function getBuildingTypes(app: AppData) {
    const buildingTypeFloorplansMap = {}
    const buildingTypeUnits = {}
    const buildingMap = {}

    app.buildingTypes.forEach((x) => {
        buildingTypeFloorplansMap[x.id] = []
        buildingTypeUnits[x.id] = []
    })

    app.buildings.forEach((x) => {
        buildingMap[x.id] = x
    })

    app.units.forEach((x) => {
        const buildingTypeId = buildingMap[x.buildingId]?.buildingTypeId
        if (!(buildingTypeId in buildingTypeUnits)) {
            buildingTypeUnits[buildingTypeId] = []
        }
        buildingTypeUnits[buildingTypeId].push(x)
    })

    app.buildings.forEach((x) => {
        const units = buildingTypeUnits[x.buildingTypeId]
        // Go through each unit and aggregate
        if (units && x.buildingTypeId != null) {
            const floorplans = units.reduce((acc, x) => ([...acc, ...x.floorplans]), [])
            buildingTypeFloorplansMap[x.buildingTypeId] = [...buildingTypeFloorplansMap[x.buildingTypeId], ...floorplans]
        }
    })

    // Final output is a unique list of floorplans for this building type
    const floorplans = fnc.objIdMap(app.floorplans)
    const map = app.buildingTypes.map((x) => {
        return {
            id: x.id,
            name: x.name,
            floorplans: Array.from(new Set(buildingTypeFloorplansMap[x.id])).map((y) => floorplans[y.floorplanId]),
            units: buildingTypeUnits[x.id]
        }
    })
    return map
}

export function setupUpgrades(upgrades: Dict) {
    if (upgrades.optionConfigurations) {
        upgrades.optionConfigurations = upgrades.optionConfigurations.map((x) => {
            if (x.buildingTypeId == null) {
                return { ...x, buildingTypeId: 'global' }
            } else {
                return x
            }
        })
    }

    return upgrades
}

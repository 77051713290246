
import { transformGarage, transformUnitBuildingKey } from "app/transformers"
import { Query, QueryType, FloorplanFilter, ProjectFilter, UnitFilter } from "app/types"

// Query operations
export function resetQueryHelper(type: QueryType, current: Query = {}, key: string = null, value: string | number = null) {
    let newQuery = {}
    if (key == null) {
        newQuery = {}
    } else {
        if (key in current) {
            newQuery = { ...current }
            if (value == null) {
                delete newQuery[key]
            } else {
                newQuery[key] = newQuery[key].filter((x) => x !== value)
                if (newQuery[key].length === 0) {
                    delete newQuery[key]
                }
            }
        }
    }
    return newQuery
}

export function setQueryHelper(type: QueryType, current: Query = {}, query: Query = {}) {
    const newQuery = current ? { ...current } : {}
    Object.keys(query).forEach((x) => {
        newQuery[x] = query[x]
    })
    return newQuery
}

export function toggleQueryHelper(type: QueryType, current: Query = {}, query: Query = {}) {
    const newQuery = { ...current }
    const toggleValue = (key, value) => {
        if (key in newQuery) {
            const index = newQuery[key].findIndex((y) => y === value)
            if (index === -1) {
                newQuery[key] = [...newQuery[key], value]
                return true
            }
            if (newQuery[key].length === 1) {
                delete newQuery[key]
            } else {
                newQuery[key] = [...newQuery[key]]
                newQuery[key].splice(index, 1)
            }
            return false
        }
        newQuery[key] = [value]
        return true
    }

    const checkToggled = (key, value) =>
        (key in newQuery) && newQuery[key].findIndex((y) => y === value) !== -1

    Object.keys(query).forEach((x) => {
        if (Array.isArray(query[x])) {
            // Check if all are toggled, if not, we want to toggle
            let allToggled = true
            for (let i = 0; i < query[x].length; i += 1) {
                const toggleState = checkToggled(x, query[x][i])
                if (!toggleState) {
                    allToggled = false
                    break
                }
            }
            for (let i = 0; i < query[x].length; i += 1) {
                const toggleState = checkToggled(x, query[x][i])
                if (allToggled || !toggleState) {
                    toggleValue(x, query[x][i])
                }
            }
        } else {
            toggleValue(x, query[x])
        }
    })
    return newQuery
}

export function filterFloorplan(app: AppData, floorplan: FloorplanData, query: Query, options: Dict = {}) {
    const { splitIndex, onlyFavourites, favourites } = options
    const matchField = (arr: string[], field: string[]) => {
        let match = false
        for (let i = 0; i < arr.length; i += 1) {
            if (Array.isArray(field)) {
                for (let j = 0; j < field.length; j += 1) {
                    if (arr[i]?.toString() === field[j].toString()) {
                        match = true
                        break
                    }
                }
                if (match) {
                    break
                }
            } else if (arr[i]?.toString() === field.toString()) {
                match = true
                break
            }
        }
        return match
    }

    if (onlyFavourites) {
        if (!favourites) {
            return false
        }
        return floorplan.id in favourites
    }

    // Check if being compared
    /*if (splitIndex != null) {
        const otherIndex = 1 - splitIndex
        const otherNavigation = navigation[otherIndex].split('/')
        if (otherNavigation.length === 2 && otherNavigation[1] === floorplan.link) {
            return false
        }
    }*/

    if (query && Object.keys(query).length > 0) {
        let match = true
        let matchCount = 0//floorplan.variations.length
        for (let i = 0; i < floorplan.variations.length; i += 1) {
            const variation = floorplan.variations[i]
            const filters = Object.keys(query)
            let matchVariation = true
            for (let j = 0; j < filters.length; j += 1) {
                const filter = filters[j]
                switch (filter) {
                    default:
                        break
                    case FloorplanFilter.Favourites: {
                        if (!(favourites && floorplan.id in favourites)) {
                            match = false
                        }
                        break
                    }
                    case FloorplanFilter.Beds: {
                        // Bed
                        const codes = [Math.floor(variation.beds).toString()]
                        // Bed + den
                        if (variation.den) {
                            codes.push(`${codes[0]} + D`)
                        }
                        if (!matchField(query[filter], codes)) {
                            if (floorplan.variations.length > 1) {
                                matchVariation = false
                            } else {
                                match = false
                            }
                        }
                        break
                    }
                    case FloorplanFilter.Baths: {
                        const codes = [Math.floor(variation.baths).toString()]
                        if (!matchField(query[filter], codes)) {
                            if (floorplan.variations.length > 1) {
                                matchVariation = false
                            } else {
                                match = false
                            }
                        }
                        break
                    }
                    case FloorplanFilter.Garage: {
                        // const codes = [Math.floor(variation.garage).toString()]
                        const codes = variation.garage ? [transformGarage(app, variation.garage)?.toString()] : null
                        if (!codes || !matchField(query[filter], codes)) {
                            if (floorplan.variations.length > 1) {
                                matchVariation = false
                            } else {
                                match = false
                            }
                        }
                        break
                    }
                    case FloorplanFilter.Unit: {
                        const unitSet = new Set(query[filter])
                        let unitMatch = false
                        for (let k = 0; floorplan.units && k < floorplan.units.length; k += 1) {
                            const unit = app.units[floorplan.units[k].ix]
                            if ((floorplan.units[k].floorplanVariationId == null || floorplan.units[k].floorplanVariationId == variation.id) && unitSet.has(unit.name)) {
                                unitMatch = true
                                break
                            }
                        }
                        if (!unitMatch) {
                            // match = false
                            matchVariation = false
                        }
                        break
                    }
                    case FloorplanFilter.Availability: {
                        const availabilitySet = new Set(query[filter])
                        let unitMatch = false
                        for (let k = 0; floorplan.units && k < floorplan.units.length; k += 1) {
                            const unit = app.units[floorplan.units[k].ix]
                            const stateName = app.maps.availabilityState[unit.availabilityStateId]?.name
                            if ((floorplan.units[k].floorplanVariationId == null || floorplan.units[k].floorplanVariationId == variation.id) && availabilitySet.has(stateName)) {
                                unitMatch = true
                                break
                            }
                        }
                        if (!unitMatch) {
                            // match = false
                            matchVariation = false
                        }
                        break
                    }
                    case FloorplanFilter.Building: {
                        const buildingSet = new Set(query[filter])
                        let inBuilding = false
                        for (let k = 0; floorplan.units && k < floorplan.units.length; k += 1) {
                            const unit = app.units[floorplan.units[k].ix]
                            const buildingKey = transformUnitBuildingKey(app, unit)
                            if (buildingSet.has(buildingKey)) {
                                inBuilding = true
                                break
                            }
                        }
                        if (!inBuilding) {
                            return false
                        }
                        break
                    }
                    case FloorplanFilter.BuildingType: {
                        const buildingSet = new Set(query[filter])
                        let inBuildingType = false
                        for (let k = 0; floorplan.units && k < floorplan.units.length; k += 1) {
                            const unit = app.units[floorplan.units[k].ix]
                            const building = app.maps.building[unit.buildingId]
                            if (building && buildingSet.has(building.buildingTypeId)) {
                                inBuildingType = true
                                break
                            }
                        }
                        if (!inBuildingType) {
                            return false
                        }
                        break
                    }
                    case FloorplanFilter.Series: {
                        if (!matchField(query[filter], floorplan.floorplanSeriesId ? [floorplan.floorplanSeriesId] : [])) {
                            match = false
                        }
                        break
                    }
                    case UnitFilter.Floor: {
                        // Only consider units for first variation
                        if (i > 0) {
                            break
                        }

                        // Gather floors from units
                        const floorCodes = new Set()
                        for (let k = 0; floorplan.units && k < floorplan.units.length; k += 1) {
                            const unit = app.units[floorplan.units[k].ix]
                            if (!unit.floor) {
                                continue
                            }
                            if (unit.floor.includes('-')) {
                                const split = unit.floor.split('-').map((x) => parseInt(x))
                                for (let m = split[0]; m <= split[1]; m += 1) {
                                    floorCodes.add(m)
                                }
                            } else {
                                floorCodes.add(parseInt(unit.floor))
                            }
                        }
                        if (!matchField(query[filter], Array.from(floorCodes))) {
                            match = false
                        }
                        break
                    }
                    case UnitFilter.Exposure: {
                        // Only consider units for first variation
                        if (i > 0) {
                            break
                        }

                        // Gather floors from units
                        const exposureCodes = new Set()
                        for (let k = 0; floorplan.units && k < floorplan.units.length; k += 1) {
                            const unit = app.units[floorplan.units[k].ix]
                            if (unit.exposure) {
                                if (unit.exposure.length > 1) {
                                    for (let l = 0; l < unit.exposure.length; l += 1) {
                                        exposureCodes.add(unit.exposure[l].toUpperCase())
                                    }
                                }
                                exposureCodes.add(unit.exposure.toUpperCase())
                            }
                        }
                        if (!matchField(query[filter], Array.from(exposureCodes))) {
                            match = false
                        }
                        break
                    }
                    case FloorplanFilter.Sqft: {
                        if (query[filter] &&
                            (variation.sqft < query[filter].min
                                || variation.sqft > query[filter].max)) {
                            match = false
                        }
                        break
                    }
                    case FloorplanFilter.Price: {
                        if (query[filter] &&
                            (variation.price < query[filter].min
                                || variation.price > query[filter].max
                                || !variation.price)) {
                            match = false
                        }
                        break
                    }
                    case UnitFilter.HideUnavailable: {
                        if (query[filter]) {
                            let anyAvailable = false
                            // Check all units and make sure available
                            for (let k = 0; k < floorplan.units.length; k += 1) {
                                const unit = app.units[floorplan.units[k].ix]
                                const stateName = app.meta.stateMap[unit.availabilityStateId].name
                                if (stateName != null && stateName === Availability.Available) {
                                    anyAvailable = true
                                    break
                                }
                            }
                            match = match && anyAvailable
                        }
                        break
                    }
                }

                if (!match) {
                    break
                }
            }

            if (matchVariation) {
                matchCount += 1
                continue
            }
            // if (!match) {
                // break
            // }

            if (query[FloorplanFilter.Search]) {
                const ldThreshold = 2
                let searchMatch = false
                const searchTerm: string = (Array.isArray(query[FloorplanFilter.Search]) ? query[FloorplanFilter.Search][0] : query[FloorplanFilter.Search] )?.simplify()
                // Search for var
                // Search for units
                if (i === 0) {
                    for (let k = 0; k < floorplan.units.length; k += 1) {
                        const unit = app.units[floorplan.units[k].ix]
                        const unitTerm: string = unit.name.simplify()
                        const ld = fnc.levDist(unitTerm, searchTerm)
                        if (ld <= ldThreshold && unitTerm.includes(searchTerm)) {
                            searchMatch = true
                            break
                        }
                    }
                }

                if (!searchMatch) {
                    const floorplanTerm: string = floorplan.name.simplify()
                    const floorplanLd = fnc.levDist(floorplanTerm, searchTerm)
                    if (floorplanTerm.includes(searchTerm)) {
                        // if (floorplanLd <= ldThreshold*2 && floorplanTerm.includes(searchTerm)) {
                        searchMatch = true
                    }
                }

                if (!searchMatch) {
                    match = false
                    break
                }
            }
        }
        return match && matchCount > 0
    }

    return true
}

export function filterUnit(app: AppData, unit: UnitData, query: Query) {
    if (query[UnitFilter.Floor]
        && !query[UnitFilter.Floor].find((x) => x === unit.floor)) {
        return false
    }

    if (query[UnitFilter.Exposure]
        && !query[UnitFilter.Exposure].find(
            (x: string) => unit.exposure?.includes(x.toLowerCase()),
        )) {
        return false
    }

    if (query[FloorplanFilter.Search]) {
        const searchTerm = (Array.isArray(query[FloorplanFilter.Search]) ? query[FloorplanFilter.Search][0] : query[FloorplanFilter.Search]).simplify()
        const unitTerm = unit.name.simplify()
        if (unitTerm !== searchTerm) {
            return false
        }
    }

    if (query[FloorplanFilter.Beds]) {
        let match = false
        const fpMatches = new Set()
        for (let j = 0; j < unit.floorplans.length; j += 1) {
            const floorplan = app.maps.floorplan[unit.floorplans[j].floorplanId]
            if (fpMatches.has(floorplan.id)) {
                match = true
                break
            }
            for (let k = 0; k < floorplan.variations.length; k += 1) {
                const variation = floorplan.variations[k]
                if(!variation.beds) {
                    break
                }
                const codes = [Math.floor(variation.beds).toString()]
                // Bed + den
                if (variation.den) {
                    codes.push(`${codes[0]} + D`)
                }
                // Get intersection of codes and query
                if (query[FloorplanFilter.Beds].filter((x) => codes.includes(x)).length > 0) {
                    match = true
                    fpMatches.add(floorplan.id)
                    break
                }
                /*if (query[FloorplanFilter.Beds].includes(variation.beds?.toString())) {
                    match = true
                    fpMatches.add(floorplan.id)
                    break
                }*/
            }
            if (match) {
                break
            }
        }
        if (!match) {
            return false
        }
    }

    if(query[FloorplanFilter.Availability]) {
        const availabilityName = app.maps.availabilityState[unit.availabilityStateId]?.name
        if (!query[FloorplanFilter.Availability].includes(availabilityName)) {
            return false
        }
    }
    return true
}
import React, { useEffect, useState } from 'react'
import { Dict } from 'app/types'
import * as fnc from 'helpers/fnc'

interface IconButtonProps {
    icon: string,
    className?: string,
    large?: boolean,
    small?: boolean,
    noBg?: boolean,
    alt?: boolean,
    style?: Dict,
    onClick?: () => void,
    children?: JSX.Element,
    animation?: string,
    otherProps: void,
    secondary: boolean,
    tertiary: boolean,
    tooltip: string,
    tooltipClass: string,
}
export function IconButton(props: IconButtonProps) {
    const {
        icon,
        className,
        large,
        small,
        noBg,
        style,
        children,
        animation,
        alt,
        secondary,
        tertiary,
        tooltip,
        tooltipClass,
        ...otherProps
    } = props

    const [elem, setElem] = useState(null)

    useEffect(() => {
        if (elem && tooltip) {
            const detach = fnc.attachTooltip(elem, tooltip, tooltipClass)
            return () => detach()
        }
    }, [elem, tooltip])

    function onClick(e: Event) {
        e.stopPropagation()
        if (props.onClick) {
            props.onClick(e)
        }
    }

    const attrs = [className, alt ? 'alt' : null, noBg ? 'no-bg' : null, large ? 'button-large' : null, small ? 'button-small' : null, secondary ? 'button-secondary' : null, tertiary ? 'button-tertiary' : null].filter((x) => x !== null).join(' ')

    return <div className={`icon-button noselect ${attrs}`} style={style} onClick={onClick} ref={setElem} {...otherProps}>
        <i className={`${icon}${animation ? ` ${animation}` : ''}`} />
        {children}
    </div>
}

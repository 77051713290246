import React, { useState, useEffect, Suspense } from 'react'
import {
    Media,
    OptionBar,
    ScrollHelper,
    SlideShow,
    Button,
    Input,
} from 'components'
import { projectContent } from 'app/transformers'
import * as fnc from 'helpers/fnc'
import { AnalyticsEvent, ErrorMessage, ProjectType, PromptOptions } from 'app/types'
import { Provinces } from 'app/constants'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { ContentForm, ContentView, NotFound } from 'views/AppPage'
import { validateEmail, validatePhone } from 'helpers/authHeader'
import { analyticsEvent, submitBooking, showPrompt } from 'actions/appActions'
import { Footer } from 'views/HomePage/Footer'
import { getAppConfig } from 'app/transformers'
import { LocationLink } from 'views/HomePage/LocationLink'


interface LaunchPageProps {
    app: AppData,
    splitIndex: number,
}

export function LaunchPage(props: LaunchPageProps) {
    const { app, splitIndex } = props
    const [scrollElem, setScrollElem] = useState()
    const config = useAppSelector((state: RootState) => state.app.config)
    const dispatch = useAppDispatch()

    const { eventConfig, formConfig } = getAppConfig(app, config)

    if (!app) {
        return <NotFound />
    }

    const builder = config.builders.find((x) => x.id == app?.meta?.builderId)

    useEffect(() => {
        if (!scrollElem) {
            return
        }
        if (window.location.hash == '#register') {
            const registerElem = document.getElementById('register')
            registerElem?.scrollIntoView()
        }

    }, [scrollElem])

    function getHeader() {
        const gallery = app.galleries.find((x) => x.link == 'launch')
        if (gallery) {
            return <div className="content-slideshow">
                <SlideShow app={app} media={gallery.media.map((x) => x.mediaId)} zoomable={false} autoScroll={8000} showIndex={false} />
            </div>
        }
        return null
    }

    function submitForm(formData) {
        const finalFormData = { ...formData }
        finalFormData['name'] = `${formData['firstname']} ${formData['lastname']}`
        delete finalFormData['firstname']
        delete finalFormData['lastname']
        finalFormData['address'] = [finalFormData['address'], finalFormData['city'], finalFormData['province'], finalFormData['postalcode']].filter((x) => x != null).join(',')
        let specs = []
        if (finalFormData.hometype) {
            specs.push(`Home type: ${finalFormData.hometype}<br>`)
        }
        if (finalFormData.sqft) {
            specs.push(`Sqft: ${finalFormData.sqft}<br>`)
        }
        if (finalFormData.bedrooms) {
            specs.push(`Bedrooms: ${finalFormData.bedrooms}<br>`)
        }
        if (finalFormData.bathrooms) {
            specs.push(`Bathrooms: ${finalFormData.bathrooms}<br>`)
        }
        if (specs.length > 0) {
            finalFormData.message = `I am interested in the following home specifications<br>${specs.join('')}`
        }
        finalFormData.analyticsEventTypeId = AnalyticsEvent.EventRegistration
        dispatch(analyticsEvent(finalFormData.analyticsEventTypeId))
        return Promise.all([
            dispatch(showPrompt(PromptOptions.SubmitForm)),
            dispatch(submitBooking({ app, data: finalFormData }))
        ])
            .then(([a, b]) => {
                try {
                    if (!b.payload.success) {
                        throw b.payload.message
                    } else {
                        return true
                    }
                } catch (e) {
                    throw e
                }
                return false
            }).catch((e) => {
                throw ErrorMessage.BookingError
            })
    }

    function getForm() {
        return <ContentForm fields={formConfig} onSubmit={submitForm} maxColumns={2}>
            <div className="row submit-row">
                <Button tertiary icon="far fa-paper-plane">Confirm RSVP</Button>
            </div>
        </ContentForm>
    }


    function getContent() {
        const launchDate = fnc.dateFriendly(eventConfig.date, { dayOfTheWeek: true })
        return <React.Fragment>
            <div className="row content-header">
                <div className="column col-shrink content-builder">
                    {builder && <Media mediaId={builder.logoMediaId} />}
                </div>
                <div className="column divider" />
                <div className="column content-title">
                    <div className="row">
                        <h1>{eventConfig.shortName}</h1>
                    </div>
                    <div className="row">
                        <h4>{launchDate}</h4>
                    </div>
                </div>
            </div>
            {eventConfig.description && <React.Fragment>
                <div className="row content-details">
                    <span>
                        {eventConfig.description}
                    </span>
                </div>
                <div className="row content-divider" />
            </React.Fragment>}
            <div className="row content-details">
                <div className="column col-4">
                    <h5>LOCATION:</h5>
                    <h3>{eventConfig.location.name.split('\n').map((x) => <React.Fragment>{x}</React.Fragment>).interleave((x) => <br key={`br${x}`} />)}</h3>
                    <LocationLink location={eventConfig.location.address} />
                </div>
                <div className="column col-4">
                    <h5>TIME:</h5>
                    <h3>{eventConfig.time}</h3>
                </div>
                {/*<div className="column col-4">
                    <h5>REGISTRATION CLOSES:</h5>
                    <h3>{fnc.dateFriendly(eventConfig.deadline, { dayOfTheWeek: true })}</h3>
                </div>*/}
            </div>
            <div className="row content-divider" style={{ marginBottom: 0 }} />
            <div id="register" className="row content-registration">
                <div className="column">
                    <div className="row">
                        <div className="column">
                            <h1>Register for {eventConfig.name}</h1>
                            <span>Fields marked with a * are required</span>
                        </div>
                    </div>
                    <div className="row">
                        {getForm()}
                    </div>
                    <div className="row">
                    </div>
                </div>
            </div>
        </React.Fragment >
    }

    return <React.Fragment>
        {/* <OptionBar app={app} splitIndex={splitIndex} titleText={app.meta.name}/> */}
        <div className="content scrollable" ref={setScrollElem}>
            {getHeader()}
            <ContentView id="launch-view">
                {getContent()}
            </ContentView>
            <Footer />
            <ScrollHelper scrollElem={scrollElem} />
        </div>
    </React.Fragment>
}
import React from 'react'
import { PromptOptions, ScreenSize } from 'app/types'
import { Button, IconButton } from 'components'
import { logger } from 'helpers/logger'
import { showPrompt } from 'actions/appActions'
import { clearFavourites } from 'actions/userActions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { icons } from 'app/constants'

export function ClearFavouritesButton() {
    const dispatch = useAppDispatch()
    const { size, orientation } = useAppSelector((state: RootState) => state.app.screen)
    const handleClear = () => {
        dispatch(showPrompt(PromptOptions.ClearFavourites))
            .then((x) => {
                if (x.payload) {
                    return Promise.all([
                        dispatch(showPrompt(PromptOptions.ClearingFavourites)),
                        dispatch(clearFavourites()),
                    ])
                }
                throw x
            })
            .catch(logger.error)
    }

    if (size == ScreenSize.Mobile) {
        return <IconButton key="clear" onClick={handleClear} icon={icons.trashAlt} />
    }

    return <Button key="clear" onClick={handleClear} icon={icons.trashAlt}>Clear My Favourites</Button>
}

import React, { useState, useRef, useEffect } from 'react'
import {
    showPrompt,
    navigateAsync,
} from 'actions/appActions'
import {
    Spinner,
    Media,
    BreadCrumbs,
    FilterOptions,
    OptionBar,
    Button,
    DropdownMenu,
    IconButton,
    Toggle,
} from 'components'
import {
    FloorplanTile,
} from 'views/FloorplanPage/FloorplanTile'
import {
    PromptType,
    FloorplanData,
    MediaData,
    CustomEventType,
    QueryType,
    UnitFilter,
    FloorplanVariationData,
    RootState,
    ScreenOrientation,
    PageType,
    ScreenSize,
    ThumbSize,
    FloorplanFilter,
    PromptOptions,
} from 'app/types'
import { logger } from 'helpers/logger'
import { useAppDispatch, useAppSelector, useLocalStorage } from 'app/hooks'
import * as fnc from 'helpers/fnc'
import { projectFilters } from 'app/transformers'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

interface ContentViewProps {
    app: AppData,
    floorplan: FloorplanData,
    variation: FloorplanVariationData,
    legacyFloorplanMedia: MediaData,
    legacyKeyplateMedia: MediaData,
    options: JSX.Element[],
    children: JSX.Element[],
    onBack: () => void,
    splitIndex: number,
    breadCrumbs: boolean,
    onlyFavourites: boolean,
    focusUnit: string,
    altMap: () => void,
    onVariation: () => void,
    onFocusUnit: () => void,
    onModelhome: () => void,
    onSpin: () => void,
    withBuilding: boolean,
    withSitemap: boolean,
}

export function FloorplanContentView(props: ContentViewProps) {
    const {
        app,
        floorplan,
        legacyFloorplanMedia,
        onBack,
        splitIndex,
        breadCrumbs,
        onlyFavourites,
        focusUnit,
        altMap,
        onVariation,
        onFocusUnit,
        onModelhome,
        onSpin,
        withBuilding,
        withSitemap,
        ...otherProps
    } = props

    const dispatch = useAppDispatch()
    const tileRowRef = useRef()
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)
    const showBuilding = useAppSelector((state: RootState) => state.app.showBuilding)
    const showSitemap = useAppSelector((state: RootState) => state.app.showSitemap)
    const variation = props.variation || (floorplan.variations && floorplan.variations[0])

    const [loading, setLoading] = useState(true)
    const [initialized, setInitialized] = useState(false)
    const [error, setError] = useState(null)
    const [currentFloorplan, setCurrentFloorplan] = useState(-1)
    const [mediaIndex, setMediaIndex] = useState(0)
    const [collapseOptions, setCollapseOptions] = useState(false)
    const [collapseDetails, setCollapseDetails] = useLocalStorage('collapseDetails', screen.isMobile)
    const [detailsKey, setDetailsKey] = useState(null)

    const [floorplanWrapperRef, setFloorplanWrapperRef] = useState(null)
    const [mediaWrapperRef, setMediaWrapperRef] = useState(null)
    const [filterApplied, setFilterApplied] = useState(null)

    useEffect(() => {
        const toggle = () => {
            if (!collapseDetails) {
                setDetailsKey(Date.now())
            } else {
                setCollapseDetails(!collapseDetails)
            }
        }
        window.addEventListener(CustomEventType.ShowInfo, toggle)
        return () => {
            window.removeEventListener(CustomEventType.ShowInfo, toggle)
        }
    }, [collapseDetails])

    useEffect(() => {
        const apply = (x) => {
            setFilterApplied(Date.now())
        }
        window.addEventListener(CustomEventType.FilterApplied, apply)
        return () => {
            window.removeEventListener(CustomEventType.FilterApplied, apply)
        }
    }, [])

    useEffect(() => {
        if (!variation) {
            return
        }

        if (currentFloorplan == -1) {
            let defaultFloorplanIndex = variation.floorplans.findIndex((x) => x.isDefault)
            if (defaultFloorplanIndex != -1) {
                setCurrentFloorplan(defaultFloorplanIndex)
            } else {
                setCurrentFloorplan(0)
            }
        } else if (currentFloorplan >= variation.floorplans.length) {
            setCurrentFloorplan(variation.floorplans.length - 1)
        }


        // Ensure media index and current floorplan within bounds
        if (mediaIndex >= variation.media.length) {
            setMediaIndex(variation.media.length - 1)
        }

    }, [variation])

    useEffect(() => {
        if (collapseOptions || (!floorplanWrapperRef && !mediaWrapperRef)) {
            return
        }

        const checkBounds = (ref) => {
            if (ref.clientWidth < ref.scrollWidth) {
                setCollapseOptions(true)
            }
        }
        // Delay to allow for DOM to update
        if (floorplanWrapperRef) {
            checkBounds(floorplanWrapperRef)
        }
        if (mediaWrapperRef) {
            checkBounds(mediaWrapperRef)
        }
    }, [floorplanWrapperRef, mediaWrapperRef])

    function handleNoteDismiss() {
        setShowUnitNote(false)
    }

    function handleLoaded() {
        if (initialized) {
            return
        }
        setInitialized(true)
        setTimeout(() => {
            setLoading(false)
        }, 200)
    }

    function handleLightbox(x, options) {
        if (Array.isArray(x)) {
            dispatch(showPrompt({ type: PromptType.Lightbox, app, media: x, ...options }))
        } else {
            dispatch(showPrompt({ type: PromptType.Lightbox, app, media: [x], ...options }))
                .catch(logger.error)
        }
    }

    function handleClick() {
        window.dispatchEvent(new Event(CustomEventType.CloseAllMenus))
    }

    function handleModelhome(e: Event) {
        e.stopPropagation()
        if (onModelhome) {
            onModelhome(variation.modelhomeId)
        }
    }
    function handleSpin(e: Event) {
        e.stopPropagation()
        if (onSpin) {
            onSpin(variation.spinId)
        }
    }

    function getFloorplan() {
        const contentStyle = {}
        if (tileRowRef.current != null) {
            contentStyle.height = `calc(100% - ${tileRowRef.current.offsetHeight}px)`
        }

        let altMedia = null
        if (altMap) {
            altMedia = floorplan.media.find(altMap)
        } else {
            altMedia = floorplan.media[0]
        }

        let floorplanMedia = null
        let featuredMedia = null
        let namedFeature = variation.media.find((x) => x.name != null && x.name.length > 0) != null
        let thumbSize = ThumbSize.ExtraLarge
        if ((screen.size == ScreenSize.Mobile && screen.orientation) || (withBuilding && showBuilding) || (withSitemap && showSitemap)) {
            thumbSize = ThumbSize.Large
        }

        if (variation.floorplans && variation.floorplans.length > 0 && currentFloorplan >= 0 && currentFloorplan < variation.floorplans.length) {
            const fp = variation.floorplans[currentFloorplan]
            if (fp) {
                floorplanMedia = <div className="floorplan-media" key={fp.mediaId}>
                    <Media
                        app={app}
                        mediaId={fp.mediaId}
                        onLoad={handleLoaded}
                        thumb
                        thumbSize={thumbSize}
                        fadeIn
                        onClick={() => handleLightbox(fp.mediaId)} />
                </div>
            }
        }
        if (variation.media && variation.media.length > 0 && mediaIndex < variation.media.length) {
            const vm = variation.media[mediaIndex]
            if (vm) {
                featuredMedia = <div className="elevation-media" key={vm.mediaId}>
                    <Media
                        app={app}
                        mediaId={vm.mediaId}
                        thumb
                        thumbSize={thumbSize}
                        fadeIn
                        onClick={() => handleLightbox(variation.media.map((x) => x.mediaId), { startIndex: mediaIndex })} />
                </div>
            }
        }

        const collapseMediaOptions = collapseOptions || ((screen.isMobile || splitIndex != null) && screen.size == ScreenSize.Mobile && variation.floorplans.length > 2);// || (variation.floorplans.length + variation?.media.length) > 4 //(screen.orientation == ScreenOrientation.Portrait || splitIndex != null)

        if (floorplanMedia) {
            const split = featuredMedia != null
            return <div className="row plan-row" style={contentStyle}>
                {floorplanMedia && <div className={`column ${split ? 'col-6' : ''} floorplan-media-wrapper`}>
                    {variation.floorplans.length > 1 && <div className="row media-options" ref={setFloorplanWrapperRef}>
                        {collapseMediaOptions && <DropdownMenu value={currentFloorplan} items={variation.floorplans.map((x, ix) => ({ value: ix, text: x.name ? x.name : ix + 1 }))} onChange={setCurrentFloorplan}/>}
                        {!collapseMediaOptions && <Toggle value={currentFloorplan} items={variation.floorplans.map((x, ix) => ({ text: x.name, value: ix }))} onChange={setCurrentFloorplan} />}
                        {/* {!collapseMediaOptions && variation.floorplans.map((x, ix) => <Button key={ix} alt className={`${currentFloorplan == ix ? 'selected' : ''}`} onClick={() => setCurrentFloorplan(ix)}>{x.name}</Button>)} */}
                    </div>}
                    <div className="row media-items">
                        {floorplanMedia}
                    </div>
                </div>}
                {featuredMedia && <div className={`column ${split ? 'col-6' : ''} floorplan-media-wrapper`}>
                    {/*floorplan.variations.length > 1 && <div className="row media-options">
                        {collapseMediaOptions && <DropdownMenu text={variation.name} value={variation.id} items={floorplan.variations.map((x, ix) => ({ value: x.id, text: x.name }))} onChange={onVariation} />}
                        {!collapseMediaOptions && floorplan.variations.map((x, ix) => <Button key={ix} alt className={`${variation.id == x.id ? 'selected' : ''}`} onClick={() => onVariation(x.id)}>{x.name ? x.name : (ix + 1)}</Button>)}
        </div>*/}
                    {variation.media.length > 1 && <div className="row media-options" ref={setMediaWrapperRef}>
                        {namedFeature && collapseMediaOptions && <DropdownMenu value={mediaIndex} items={variation.media.map((x, ix) => ({ value: ix, text: x.name }))} onChange={(x) => setMediaIndex(x)} />}
                        {namedFeature && !collapseMediaOptions && <Toggle value={mediaIndex} items={variation.media.map((x, ix) => ({ text: x.name, value: ix }))} onChange={setMediaIndex} />}
                        {/* {namedFeature && !collapseMediaOptions && variation.media.map((x, ix) => <Button key={ix} alt className={`${ix == mediaIndex ? 'selected' : ''}`} onClick={() => setMediaIndex(ix)}>{x.name ? x.name : (ix + 1)}</Button>)} */}
                    </div>}
                    <div className="row">
                        {featuredMedia}
                        {variation.media.length > 1 && !namedFeature && <div className="row variation-selector">
                            {variation.media.map((x, ix) => <IconButton key={ix} className={mediaIndex == ix ? 'selected' : ''} icon="fas fa-circle" onClick={(e) => { e.stopPropagation(); setMediaIndex(ix) }} />)}
                        </div>}
                    </div>
                </div>}
            </div>
        }

        return <div className="row plan-row" style={contentStyle}>
            {legacyFloorplanMedia && <div className="floorplan-media">
                <Media
                    app={app}
                    mediaId={legacyFloorplanMedia.id}
                    onLoad={handleLoaded}
                    thumb
                    thumbSize={1024}
                    onClick={() => handleLightbox(legacyFloorplanMedia.id)} />
            </div>}
            {altMedia && <div className="keyplate-media">
                <Media
                    app={app}
                    mediaId={altMedia}
                    thumb
                    thumbSize={512}
                    onClick={() => handleLightbox(altMedia)} />
            </div>}
        </div>
    }

    function getDetails() {
        if (!floorplan.details || floorplan.details.length == 0) {
            return null
        }
        let unitDetails = ''
        let unitData = null
        if (focusUnit) {
            unitData = app.maps.unitName[focusUnit]
            if (unitData.moveInDate) {
                unitDetails += `* Move-In Date: ${fnc.dateFriendly(fnc.toUtcDateString(unitData.moveInDate))}\n`
            }
            if (unitData.note) {
                unitDetails += `* ${unitData.note}\n`
            }
        }
        return <div key={detailsKey} className={`floorplan-details bottom-left ${collapseDetails ? ' collapsed' : ''}`}>
            <div className="row animate__animated animate__fadeInRight animate__fastest">
                <div className="column">
                    <h5>Floorplan Features:</h5>
                    <ReactMarkdown>{floorplan.details}</ReactMarkdown>
                    {focusUnit && unitData && unitDetails.length > 0 && <React.Fragment>
                        <h5>Unit {unitData.name} Details:</h5>
                        <ReactMarkdown>{unitDetails}</ReactMarkdown>
                    </React.Fragment>}
                </div>
                <IconButton alt noBg icon={collapseDetails ? 'fas fa-info-circle' : 'fas fa-chevron-left'} onClick={() => setCollapseDetails(!collapseDetails)} />
            </div>
        </div>
    }

    // function handleBack() {
    // dispatch(navigateAsync({ pageType: '', options: { splitIndex: splitIndex, path: { baseRoute: 'favourites' } } }))
    // }
    if (error) {
        return <h1>{error}</h1>
    }

    let leftOptions = <React.Fragment>
        {/*<FilterOptions
            app={app}
            filters={projectFilters(app)}
            key={filterApplied}
            className={filterApplied ? 'animate__animated animate__headShake' : ''}
            // filters={[UnitFilter.Exposure, UnitFilter.Floor]}
            queryType={QueryType.Floorplans}
            ranges={app.meta.filterRanges}
            // heightRef={barRef}
            splitIndex={splitIndex}
            expandedAsDropdown
            forceTile
            minimal
            readOnly
            listSelected>
        </FilterOptions>*/}
    </React.Fragment>

    let titleText = null
    if (onlyFavourites) {
        titleText = `My Favourites - ${app.meta.name}`
    } else if (splitIndex != null && !standaloneApp) {
        titleText = app.meta.name
    }

    return <React.Fragment>
        <OptionBar
            key="view"
            className="fixed"
            splitIndex={splitIndex}
            leftOptions={leftOptions}
            onlyFavourites={onlyFavourites}
            titleText={titleText}
            compare={true}
            app={app} />
        <div className="content">
            {breadCrumbs && !onlyFavourites && <BreadCrumbs app={app} splitIndex={splitIndex} fixed={splitIndex == null} />}
            {loading && <Spinner overlay show={!initialized} />}
            {/* <div className="top-left"> */}
            {/* </div> */}
            {/* <div className='option-container'> */}
            {/* {finalOptions} */}
            {/* </div> */}
            <div className="floorplan-content-view" style={{ opacity: initialized ? 1 : 0 }} onClick={handleClick}>
                {/* {onBack && <BackButton onClick={onBack} fixed splitIndex={splitIndex} showHint/>} */}

                <div className="row tile-row" ref={tileRowRef}>
                    <FloorplanTile app={app} floorplan={floorplan} variation={props.variation} showVariations disabled minimal favourite share filters={true} focusUnit={focusUnit} onFocusUnit={onFocusUnit} onFloorplanMedia={setCurrentFloorplan} onVariation={onVariation} currentFloorplan={currentFloorplan} {...otherProps} />
                </div>
                {getFloorplan()}
                {(variation.modelhomeId || variation.spinId) && <div className="floorplan-content-options">
                    {variation.modelhomeId && <Button alt icon="fas fa-walking" onClick={handleModelhome}>Tour</Button>}
                    {variation.spinId && <Button alt icon="fas fa-home" onClick={handleSpin}>Spin</Button>}
                </div>}
                {getDetails()}
            </div>
        </div>
    </React.Fragment>
}

import React, { useEffect, useState, useRef } from 'react'
import { useGoogleLogin } from 'react-google-login'
import { getApiUrl, getOAuthClientId, getSocialLogin, getWindowLocation } from 'helpers/config'
import { showPrompt, navigateAsync } from 'actions/appActions'
import { loginUser, logoutUser, registerUser, forgotPassword, resendActivationEmail, requestPIN, loginPIN, registerUserPin as registerUserPIN, registerPIN } from 'actions/userActions'
import { PromptOptions, CustomEventType, ErrorMessage, PageType, PromptType } from 'app/types'
import * as fnc from 'helpers/fnc'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { logger } from 'helpers/logger'
import { loadGoogleIdentityScripts, loadOAuthScripts } from 'helpers/scripts'
import { setChangePending } from 'actions/adminActions'

const GOOGLE_SCOPES = [
    'openid',
    'email',
    'profile',
].join(' ')

export function LoginHooks(props: LoginHooksProps) {
    const dispatch = useAppDispatch()
    const loggedIn = useAppSelector((state: RootState) => state.user.loggedIn)
    const token = useAppSelector((state: RootState) => state.user.token)
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)
    const project = useAppSelector((state: RootState) => state.app.projectCurrent?.length > 0 ? state.app.projectCurrent[0] : null)
    const prompt = useAppSelector((state: RootState) => state.app.prompt)
    const [googleAvailabile, setGoogleAvailable] = useState(true)
    const promptUsed = useRef(false)
    const salescenter = useAppSelector((state: RootState) => state.app.salescenter)
    const config = useAppSelector((state: RootState) => state.app.config)
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const initialized = useAppSelector((state: RootState) => state.app.initialized)
    const agents = useRef(null)

    const requireLogin = project?.meta.requireLoginSalescenter
    useEffect(() => {
        window.addEventListener(CustomEventType.LogIn, showLogIn)
        window.addEventListener(CustomEventType.LogInPIN, showRequestPIN)
        window.addEventListener(CustomEventType.LogOut, handleLogout)
        window.addEventListener(CustomEventType.Register, showSignUp)
        return () => {
            window.removeEventListener(CustomEventType.LogIn, showLogIn)
            window.removeEventListener(CustomEventType.LogInPIN, showRequestPIN)
            window.removeEventListener(CustomEventType.LogOut, handleLogout)
            window.removeEventListener(CustomEventType.Register, showSignUp)
        }
    }, [])

    useEffect(() => {
        if (!config || !config.referralSources || !project || !organization) {
            return
        }
        if (!loggedIn && salescenter && requireLogin && !prompt) {
            showRequired(null)
        }
    }, [loggedIn, requireLogin, salescenter, prompt, config, standaloneApp])

    useEffect(() => {
        loadGoogleIdentityScripts()
            .then((x) => {
                try {
                    google.accounts.id.initialize({
                        client_id: getOAuthClientId(),
                        // ux_mode: 'redirect',
                        callback: (x) => {
                            googleLoginRedirect(x.credential, !prompt && x.select_by != "btn")//!(promptUsed.current || x.select_by == "btn"))
                        }
                    });
                } catch (e) {
                    // logger.error("Missing google accounts", e)
                    return
                }
            })
    }, [])

    function handleRedirect(page: PageType) {
        if (page) {
            dispatch(navigateAsync({ pageType: page }))
        } else if (getWindowLocation().href.includes('reset-password') || getWindowLocation().href.includes('activate-account')) {
            dispatch(navigateAsync({ pageType: page, options: { path: { baseRoute: '' } } }))
        }
    }
    function handleLogout() {
        googleLogout()
        dispatch(setChangePending(false))
        dispatch(logoutUser())
    }

    function googleLogout() {
        google.accounts.id.disableAutoSelect()
    }

    function googleLoginRedirect(token, silent = false) {
        let prom = silent ? [dispatch(loginUser({ googleToken: token }))] : [dispatch(showPrompt(PromptOptions.LoggingIn)), dispatch(loginUser({ googleToken: token }))]
        return Promise.all(prom)
            .then((x) => {
                // On failure
                const loginResult = silent ? x[0] : x[1]
                if (loginResult && loginResult.payload
                    && !loginResult.payload.success && loginResult.payload.message) {
                    if (!silent) {
                        showLogIn(null, loginResult.payload.message)
                    }
                    googleLogout()
                } else if (!(loginResult && loginResult.payload && loginResult.payload.success)) {
                    if (!silent) {
                        showLogIn(null, ErrorMessage.LoginError)
                    }
                    googleLogout()
                }
            })
    }

    function showRequired(e: CustomEvent, loginError: string = null) {
        const options = fnc.copyObj(PromptOptions.LoginUserRequiredPrompt)
        const redirect = (e && e.detail) ? e.detail.redirect : null
        dispatch(showPrompt({ ...options, error: loginError }))
            .then((x) => {
                if (x.payload.event == 'login') {
                    showRequiredLogIn()
                } else if (x.payload.event == 'register') {
                    showRequiredRegister()
                }
            })
            .catch((e) => {
                logger.error('Login error', e)
            })
    }

    function showRequiredLogIn(e: CustomEvent, loginError: string = null, data = null) {
        showRequestPIN(e, loginError, data)
        /*const options = fnc.copyObj(PromptOptions.LoginUserRequiredLogin)
        const redirect = (e && e.detail) ? e.detail.redirect : null
        dispatch(showPrompt({ ...options, error: loginError }))
            .then((x) => {
                if (x.payload.event == 'register') {
                    showRequiredRegister()
                }

                return x
            })
            .catch((e) => {
                logger.error('Login error', e)
            })*/
    }

    function showRequiredRegister(e: CustomEvent, loginError: string = null, data = null) {
        const options = fnc.copyObj(PromptOptions.LoginUserRequiredRegister)
        const redirect = (e && e.detail) ? e.detail.redirect : null
        options.fields.push({
            key: 'referral_source_id',
            label: 'How did you hear about us?',
            select: 'Please select one',
            enum: config.referralSources?.map((x) => ({ value: x.id, text: x.name })),
            required: true,
        })

        let agents = null
        if (project) {
            let agentMap = fnc.objIdMap(organization.agents)
            agents = project.meta.agents?.map((x) => agentMap[x]).filter((x) => x != null)
        } else if (organization) {
            agents = organization.agents
        }

        if (agents && agents.length > 0) {
            options.fields.push({
                key: 'referrel_contact_id',
                label: 'Who did you speak with?',
                select: 'Plase select one',
                enum: agents.map((x) => ({ value: x.id, text: x.name })),
                required: false,
            })
        }

        if (data) {
            if (Array.isArray(data)) {
                data.forEach((x, ix) => {
                    options.fields[ix].inputOptions.value = x
                })
            } else if (typeof data == 'object') {
                Object.keys(data).forEach((x) => {
                    const fieldIdx = options.fields.findIndex((y) => y.key == x)
                    if (fieldIdx >= 0) {
                        options.fields[fieldIdx].inputOptions.value = data[x]
                    }
                })
            }
        }

        let submitData = null
        dispatch(showPrompt({ ...options, error: loginError }))
            .then((x) => {
                if (x.payload.event == 'login') {
                    showRequiredLogIn()
                } else {
                    const [email, firstname, lastname, phone, referralSourceId, referralContactInfoId] = x.payload
                    submitData = {
                        email,
                        firstname,
                        lastname,
                        phone,
                        referralSourceId,
                        referralContactInfoId,
                    }
                    const name = `${firstname} ${lastname}`
                    const data = { email, name, phone: phone.length > 0 ? phone : null, referralSourceId, referralContactInfoId, organization }
                    if (data.referralSourceId == '') {
                        data.referralSourceId = null
                    }
                    if (data.referralContactInfoId == '') {
                        data.referralContactInfoId = null
                    }

                    return Promise.all([
                        dispatch(showPrompt(PromptOptions.RegisteringRequired)),
                        dispatch(registerUserPIN(data))
                    ])
                }
                return x
            })
            .then((x) => {
                if (Array.isArray(x)) {
                    const loginResult = x[1]
                    let email = null
                    let name = null
                    try {
                        email = loginResult.meta.arg.email
                        name = loginResult.meta.arg.name
                    } catch { }
                    if (loginResult && loginResult.payload
                        && !loginResult.payload.success && loginResult.payload.message) {
                        if (loginResult.payload.message.includes('exists')) {
                            showRequiredLogIn(e, `${loginResult.payload.message}. Please log in.`, submitData)
                        } else {
                            showRequiredRegister(e, loginResult.payload.message, submitData)
                        }
                    } else if (loginResult && loginResult.payload && loginResult.payload.success) {
                        showRegisterPIN(e, null, { email, name }, { subtitle: 'Complete your registration by entering the PIN sent to your email.' })
                        // showLogIn(null, null, email)
                        // Show prompt indicating to wait for email
                        // dispatch(showPrompt(PromptOptions.RegisterSuccessful))
                    } else {
                        showSignUp(e, ErrorMessage.SignupError, submitData)
                    }
                }
            })
            .catch((e) => {
                logger.errorWithReport(e)
            })
    }

    // Login prompts
    function showLogIn(e: CustomEvent, loginError: string = null, email: string = null) {
        if (email == null) {
            email = localStorage.getItem('remember-me')
        }
        const options = fnc.copyObj(PromptOptions.LoginUser)
        if (email != null) {
            options.fields[0].inputOptions = { ...options.fields[0].inputOptions, value: email }
        }
        options.google = getSocialLogin().includes('google')

        const redirect = (e && e.detail) ? e.detail.redirect : null
        dispatch(showPrompt({ ...options, error: loginError }))
            .then((x) => {
                if (x.payload) {
                    if (x.payload.event) {
                        return x
                    } else {

                        let prom = [dispatch(showPrompt(PromptOptions.LoggingIn))]
                        if (Array.isArray(x.payload)) {
                            const [email, password] = x.payload
                            prom.push(dispatch(loginUser({ email, password })))
                        } else {
                            prom.push(dispatch(loginUser({ email: adminEmail, password: x.payload })))
                        }
                        return Promise.all(prom)
                    }
                }
                throw x
            })
            .then((x) => {
                if (!Array.isArray(x) && x.payload && x.payload.event) {
                    switch (x.payload.event) {
                        case CustomEventType.ForgotPassword:
                            showForgot()
                            break
                        case CustomEventType.Register:
                            showSignUp(e)
                            break
                        case CustomEventType.GoogleLogIn:
                            if (googleAvailabile) {
                                googleLogin(e)
                            } else {
                                showLogIn(e, ErrorMessage.GoogleError)
                            }
                            break
                    }
                } else {
                    const loginResult = x[1]
                    let email = null
                    try {
                        email = loginResult.meta.arg.email
                    } catch { }
                    if (loginResult && loginResult.payload
                        && !loginResult.payload.success && loginResult.payload.message) {
                        // If message indicates account is not active, show activate prompt instead
                        if (loginResult.payload.message.includes('not been activated')) {
                            showActivate(e, loginResult.payload.message, email)
                        } else {
                            showLogIn(e, loginResult.payload.message, email)
                        }
                    } else if (!(loginResult && loginResult.payload && loginResult.payload.success)) {
                        showLogIn(e, ErrorMessage.LoginError, email)
                    } else if (redirect != null) {
                        handleRedirect(redirect)
                    }
                }
            })
            .catch((e) => {
                logger.error('Login error', e)
            })
    }

    function showRequestPIN(e: CustomEvent, loginError: string = null, data) {
        const options = fnc.copyObj(PromptOptions.RequestPIN)
        if (data?.email != null) {
            options.fields[0].inputOptions = { ...options.fields[0].inputOptions, value: data.email }
        }
        if (data?.phone != null && options.fields.find((x) => x.key == 'phone')) {
            options.fields[1].inputOptions = { ...options.fields[1].inputOptions, value: data.phone }
        }

        dispatch(showPrompt({ ...options, error: loginError }))
            .then((x) => {
                if (x.payload.event == 'login') {
                    showRequiredLogIn()
                    return
                } else if (x.payload.event == 'register') {
                    showRequiredRegister()
                    return
                }

                if (x.payload) {
                    // TODO, also phone
                    let prom = [dispatch(showPrompt(PromptOptions.RequestingPIN))]
                    prom.push(dispatch(requestPIN({ email: x.payload, phone: null })))
                    return Promise.all(prom)
                }
                throw x
            })
            .then((x) => {
                const pinResult = x[1]
                let email = null
                let phone = null
                try {
                    email = pinResult.meta.arg.email
                    phone = pinResult.meta.arg.phone
                } catch { }
                if (pinResult && pinResult.payload
                    && !pinResult.payload.success && pinResult.payload.message) {
                    if (pinResult.payload.message.includes('No user found') && requireLogin && salescenter) {
                        showRequiredRegister(e, `${pinResult.payload.message}, please register`, { email, phone })
                    } else {
                        showRequestPIN(e, pinResult.payload.message, { email, phone })
                    }
                } else if (!(pinResult && pinResult.payload && pinResult.payload.success)) {
                    showRequestPIN(e, ErrorMessage.EmailError, { email, phone })
                } else {
                    showLoginPIN(null, null, { email, phone })
                }
            })
            .catch((e) => {
                logger.error('Request PIN error', e)
            })
    }

    function showLoginPIN(e: CustomEvent, loginError: string = null, data = null, _options = null) {
        const options = { ...fnc.copyObj(PromptOptions.LoginPIN), ..._options }
        const redirect = (e && e.detail) ? e.detail.redirect : null
        dispatch(showPrompt({ ...options, error: loginError }))
            .then((x) => {
                if (x.payload) {
                    let prom = [dispatch(showPrompt(PromptOptions.LoggingIn))]
                    prom.push(dispatch(loginPIN({ pin: x.payload, ...data })))
                    return Promise.all(prom)
                }
                throw x
            })
            .then((x) => {
                if (!Array.isArray(x) && x.payload && x.payload.event) {
                    switch (x.payload.event) {
                        case CustomEventType.Resend:
                            showRequestPIN()
                            break
                    }
                } else {
                    const pinResult = x[1]
                    if (pinResult && pinResult.payload
                        && !pinResult.payload.success && pinResult.payload.message) {
                        showLoginPIN(e, pinResult.payload.message, data)
                    } else if (!(pinResult && pinResult.payload && pinResult.payload.success)) {
                        showLoginPIN(e, ErrorMessage.LoginError, data)
                    } else {
                        handleRedirect(redirect)
                    }
                }
            })
            .catch((e) => {
                logger.error('Login error', e)
            })
    }

    function showRegisterPIN(e: CustomEvent, registerError: string = null, data = null, _options = null) {
        const options = { ...fnc.copyObj(PromptOptions.LoginPIN), ..._options }
        const redirect = (e && e.detail) ? e.detail.redirect : null
        dispatch(showPrompt({ ...options, error: registerError }))
            .then((x) => {
                if (x.payload) {
                    let prom = [dispatch(showPrompt(PromptOptions.RegisteringPIN))]
                    prom.push(dispatch(registerPIN({ pin: x.payload, ...data })))
                    return Promise.all(prom)
                }
                throw x
            })
            .then((x) => {
                if (!Array.isArray(x) && x.payload && x.payload.event) {
                    switch (x.payload.event) {
                        case CustomEventType.Resend:
                            showRequestPIN()
                            break
                    }
                } else {
                    const pinResult = x[1]
                    if (pinResult && pinResult.payload
                        && !pinResult.payload.success && pinResult.payload.message) {
                        showRegisterPIN(e, pinResult.payload.message, data)
                    } else if (!(pinResult && pinResult.payload && pinResult.payload.success)) {
                        showRegisterPIN(e, ErrorMessage.LoginError, data)
                    } else {
                        handleRedirect(redirect)
                    }
                }
            })
            .catch((e) => {
                logger.error('Login error', e)
            })
    }

    function showSignUp(e: Event, signupError: string = null, email: string = null, name: string = null) {
        const options = fnc.copyObj(PromptOptions.RegisterUser)
        if (e.detail && e.detail.title) {
            options.title = e.detail.title
        }
        if (email != null) {
            options.fields[1].inputOptions = { ...options.fields[1].inputOptions, value: email }
        }
        if (name != null) {
            options.fields[0].inputOptions = { ...options.fields[0].inputOptions, value: name }
        }
        const redirect = (e && e.detail) ? e.detail.redirect : null
        dispatch(showPrompt({ ...options, error: signupError }))
            .then((x) => {
                if (x.payload) {
                    if (x.payload.event) {
                        return x
                    } else {
                        const [name, email, phone, password, passwordConfirm] = x.payload
                        return Promise.all([
                            dispatch(showPrompt(PromptOptions.Registering)),
                            dispatch(registerUser({ email, name, phone: phone.length > 0 ? phone : null, password, activationEmail: true }))
                        ])
                    }
                }
                throw x
            })
            .then((x) => {
                if (!Array.isArray(x) && x.payload && x.payload.event) {
                    switch (x.payload.event) {
                        case CustomEventType.LogIn:
                            showLogIn(e)
                            break
                        case CustomEventType.GoogleLogIn:
                            if (googleAvailabile) {
                                googleLogin(e)
                            } else {
                                showLogIn(e, ErrorMessage.GoogleError)
                            }
                            break
                    }
                } else {
                    const loginResult = x[1]
                    let email = null
                    let name = null
                    try {
                        email = loginResult.meta.arg.email
                        name = loginResult.meta.arg.name
                    } catch { }
                    if (loginResult && loginResult.payload
                        && !loginResult.payload.success && loginResult.payload.message) {
                        showSignUp(e, loginResult.payload.message, email, name)
                    } else if (loginResult && loginResult.payload && loginResult.payload.success) {
                        // showLogIn(null, null, email)
                        // Show prompt indicating to wait for email
                        // dispatch(showPrompt(PromptOptions.RegisterSuccessful))
                    } else {
                        showSignUp(e, ErrorMessage.SignupError, email, name)
                    }
                }
            })
            .catch((e) => {
                logger.error('Login error', e)
            })
    }


    function showForgot(e: Event, forgotError: Error = null) {
        dispatch(showPrompt({ ...PromptOptions.ForgotPassword, error: forgotError }))
            .then((x) => {
                if (x.payload) {
                    return Promise.all([
                        dispatch(showPrompt(PromptOptions.ActionEmailSending)),
                        dispatch(forgotPassword(x.payload)),
                    ])
                }
                throw x
            })
            .then((x) => {
                const forgetResult = x[1]
                if (forgetResult && forgetResult.payload
                    && !forgetResult.payload.success && forgetResult.payload.message) {
                    showForgot(null, forgetResult.payload.message)
                } else if (!(forgetResult && forgetResult.payload && forgetResult.payload.success)) {
                    showForgot(null, ErrorMessage.EmailError)
                }
            })
            .catch((e) => {
                logger.error('Reset error', e)
            })
    }


    function showActivate(e: Event, error: Error = null, email: string = null) {
        dispatch(showPrompt({ ...PromptOptions.SendActivation, subtitle: email, error: error }))
            .then((x) => {
                if (x.payload) {
                    return Promise.all([
                        dispatch(showPrompt(PromptOptions.ActionEmailSending)),
                        dispatch(resendActivationEmail(email))
                    ])
                }
                throw x
            })
            .then((x) => {
                const activateResult = x[1]
                if (activateResult && activateResult.payload
                    && !activateResult.payload.success && activateResult.payload.message) {
                    showActivate(null, activateResult.payload.message, email)
                } else if (!(activateResult && activateResult.payload && activateResult.payload.success)) {
                    showActivate(null, ErrorMessage.EmailError, email)
                }
            })
            .catch((e) => {
                logger.error('Reset error', e)
            })
    }

    return null
}
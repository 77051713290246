import React from 'react'
import { Dict } from 'app/types'

interface CheckboxProps {
    icon?: string,
    className?: string,
    large?: boolean,
    name?: string,
    style?: Dict,
    onChange?: () => void,
    children?: JSX.Element[]
    checked?: boolean,
    noBg?: boolean,
    alt?: boolean,
    tertiary?: boolean,
    value?: boolean,
}

export function Checkbox(props: CheckboxProps) {
    const {
        className,
        large,
        onChange,
        name,
        noBg,
        alt,
        tertiary,
        ...buttonProps
    } = props

    let { value } = props
    if (value === undefined) {
        value = false
    }

    function handleClick(e: Event) {
        e.stopPropagation()
        if (onChange) {
            onChange(!value)
        }
    }

    const attrs = `${large ? ' button-large' : ''}${className ? ` ${className}` : ''}${noBg ? ' no-bg' : ''}${alt ? ' alt' : ''}${tertiary ? ' button-tertiary' : ''}`


    return (
        <button className={`button checkbox noselect${attrs}`} {...buttonProps} onClick={handleClick}>
            <input type="checkbox" name={`checkbox-${name}`} checked={value} readOnly />
            {props.children != null && <label htmlFor={`checkbox-${name}`}>
                {props.children}
            </label>}
        </button>
    )
}

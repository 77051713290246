import React, { Suspense, useEffect, useState } from 'react'
import {
    TopBar,
    Logo,
    Button,
    DropdownMenu,
    FavouriteNavButton,
    IconButton,
} from 'components'
import { Link, useLocation } from 'react-router-dom'
import {
    RootState,
    CustomEventType,
    PageType,
    ScreenSize,
    ScreenOrientation,
    UserRole,
    AdminView,
    PromptOptions,
    AnalyticsEvent,
    PromptType,
    FormFieldType,
} from 'app/types'
import {
    navigateAsync,
    clearCurrent,
    setPath,
    showPrompt,
} from 'actions/appActions'
import {
    logoutUser,
} from 'actions/userActions'
import { useAppSelector, useAppDispatch, useAdminPermissions, usePage } from 'app/hooks'
import * as fnc from 'helpers/fnc'
import { navKey } from 'views/AdminPage'
import { clearAdminProject, clearEditContent, setEditContent, setEditInline } from 'actions/adminActions'
import { contactText, icons, requestInfoFields } from 'app/constants'
import { organizationContent, organizationRoutes } from 'app/transformers'
import { ifError } from 'assert'
import { getWindowLocation } from 'helpers/config'
import { logger } from 'helpers/logger'

interface HomeBarProps {
    signUp: boolean,
    logIn: boolean,
    redirect: string,
}
export function HomeBar(props: HomeBarProps) {
    const { signUp, logIn, redirect } = { signUp: true, logIn: true, ...props }
    const dispatch = useAppDispatch()
    const loggedIn = useAppSelector((state: RootState) => state.user.loggedIn)
    const salescenter = useAppSelector((state: RootState) => state.app.salescenter)
    const rootPath = useAppSelector((state: RootState) => state.app.rootPath)
    const links = useAppSelector((state: RootState) => state.app.links)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const project = useAppSelector((state: RootState) => state.app.projectCurrent[0])
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const standaloneOrg = useAppSelector((state: RootState) => state.app.standaloneOrg)
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)
    const user = useAppSelector((state: RootState) => state.user.data)
    const config = useAppSelector((state: RootState) => state.app.config)
    const organizationVisited = useAppSelector((state: RootState) => state.app.organizationVisited)
    const customDomain = useAppSelector((state: RootState) => state.app.customDomain)
    const editInline = useAppSelector((state: RootState) => state.admin.editInline)

    const requireLogin = project?.meta.requireLoginSalescenter

    function hasRole(x: Role) {
        return user && user.roles && user.roles.findIndex((y) => y == x) != -1
    }

    const altTheme = organization && organization.link == 'thepropertylinq'

    const adminPermissions = useAdminPermissions()

    const [hasEditPermissions, setHasEditPermissions] = useState(false)
    const [hasAdminPermissions, setHasAdminPermissions] = useState(false)
    const [canExpand, setCanExpand] = useState(null)
    const [expand, setExpand] = useState(true)
    const [showOrganizationOptions, setShowOrganizationOptions] = useState(false)
    const [OrganizationContentElement, setOrganizationContentElement] = useState(null)
    const [logInTriggered, setLogInTriggered] = useState(false)
    const [showRequestInfo, setShowRequestInfo] = useState(false)
    const page = usePage()

    let collapseMenu = false
    if (screen.isMobile && screen.size == ScreenSize.Mobile && screen.orientation == ScreenOrientation.Portrait) {
        collapseMenu = true
    }
    const bigLogo = !screen.isMobile && expand && canExpand && !(collapseMenu && showOrganizationOptions) && (links.baseRoute == null || (links.appLink != null && links.appLink != PageType.Compare))

    useEffect(() => {
        if (salescenter || (project && !project.meta.enableRequestInfo)) {
            return
        }
        const toggleShowRequestInfo = () => {
            logger.info("Show request info")
            setShowRequestInfo(true)
        }
        window.addEventListener(CustomEventType.ShowRequestInfo, toggleShowRequestInfo)
        return () => {
            window.removeEventListener(CustomEventType.ShowRequestInfo, toggleShowRequestInfo)
        }
    }, [])

    useEffect(() => {
        window.addEventListener(CustomEventType.RequestInfo, handleRequestInfo)
        return () => {
            window.removeEventListener(CustomEventType.RequestInfo, handleRequestInfo)
        }
    }, [organization, project])

    useEffect(() => {
        window.addEventListener(CustomEventType.ScrollAway, handleScroll)
        return () => {
            window.removeEventListener(CustomEventType.ScrollAway, handleScroll)
        }
    }, [expand])

    useEffect(() => {
        if (organization && !standaloneApp) {
            // setOrganizationContentElement(organizationContent(organization))
        }
    }, [organization])

    useEffect(() => {
        const pageType = page ? page.pageType : PageType.Info
        const newExpand = (pageType == null || pageType == PageType.Info || pageType == PageType.About || pageType == PageType.Launch) && links.baseRoute != PageType.Favourite
        setCanExpand(newExpand)
        setShowOrganizationOptions(false)
    }, [links])


    useEffect(() => {
        setHasEditPermissions(adminPermissions(project, organization, [UserRole.AppsEdit]))
        setHasAdminPermissions(adminPermissions(project, null, [UserRole.PricingEdit, UserRole.PricingView, UserRole.AvailabilityEdit, UserRole.AvailabilityView]))
    }, [project, loggedIn])

    // Redirect after login
    useEffect(() => {
        if (logInTriggered && loggedIn && user && user.admin && links.pageLink != PageType.Admin) {
            // handleAdmin()
        }
    }, [loggedIn, logInTriggered])

    function handleScroll(e) {
        setExpand(e.detail)
    }

    function handleHome() {
        dispatch(navigateAsync({ pageType: PageType.Home, options: { path: { baseRoute: '' } } }))
    }

    function handleSearch() {
        dispatch(navigateAsync({ pageType: '', options: { path: { baseRoute: PageType.Developments } } }))
    }

    function handleAboutUs() {
        dispatch(navigateAsync({ pageType: PageType.AboutUs }))
    }

    function handleContact() {
        dispatch(showPrompt({ ...PromptOptions.Booking, app: project }))
    }
    function handleAdmin() {
        if (!standaloneApp) {
            dispatch(clearCurrent())
        }
        dispatch(navigateAsync({ pageType: PageType.Admin, options: { path: { baseRoute: '' } } }))
    }

    function handleLogIn() {
        setLogInTriggered(true)
        window.dispatchEvent(new CustomEvent(CustomEventType.LogIn, { detail: { redirect } }))
    }
    function handleLogInPIN() {
        setLogInTriggered(true)
        window.dispatchEvent(new CustomEvent(CustomEventType.LogInPIN, { detail: { redirect } }))
    }
    function handleSignUp() {
        setLogInTriggered(true)
        window.dispatchEvent(new CustomEvent(CustomEventType.Register, { detail: { redirect } }))
    }

    function handleUserSelect(x) {
        switch (x) {
            case 'logout':
                window.dispatchEvent(new CustomEvent(CustomEventType.LogOut))
                break
            case 'account':
                handleAdmin()
                break
            case 'edit':
                dispatch(setEditInline(!editInline))
            default:
                break
        }
    }

    function handleAppChange(x) {
        dispatch(navigateAsync({ app: { meta: x }, pageType: '' }))
        window.dispatchEvent(new CustomEvent(CustomEventType.CloseAllMenus))
    }

    function handleEditApp() {
        // if (Object.values(AdminView).includes(links.pageLink)) {
        // localStorage.setItem(navKey(links.appLink), links.pageLink)
        // }
        let extraLink = links.pageLink
        // TODO: fix this confusing s / no s situation
        if (extraLink == PageType.Floorplan) {
            extraLink = AdminView.Floorplans
        }
        // Transform
        if (extraLink == 'upgrades' && !hasRole(UserRole.AppsEdit)) {
            extraLink = 'upgrades-config'
        }

        dispatch(clearAdminProject())
        dispatch(navigateAsync({ pageType: PageType.Admin, options: { dataLink: links.appLink, extraLink, path: { baseRoute: '' } } }))
    }

    function handleLoginDropdown(x) {
        switch (x) {
            case 'login':
                handleLogIn()
                break
            case 'signup':
                handleSignUp()
                break
        }
    }

    function handleEndSession() {
        dispatch(showPrompt({ type: PromptType.Confirm, title: 'End Session', message: 'Are you sure you want to log out?', confirmMessage: 'Yes', cancelMessage: 'No' }))
            .then((x) => {
                if (x.payload) {
                    window.dispatchEvent(new CustomEvent(CustomEventType.LogOut))
                }
            })
    }

    function handleRequestInfo(e) {
        const detail = e?.detail ? e.detail : {}
        let options = {}

        let infoTarget = project ? project.meta.name : organization.name

        options = {
            ...options,
            ...PromptOptions.RequestInfo,
            app: project,
            organization,
            submitMessage: 'Request Info',
            formOptions: {
                submitToHubspot: project?.meta.hubspotTrackingId != null,
                analyticsEventTypeId: AnalyticsEvent.RequestInformation,
            },
            ...detail
        }
        options.fields = requestInfoFields(user, infoTarget)


        if (organization && organization.consent) {
            options.fields.push({
                id: 'consent',
                label: organizationg.consent,
            })
        }

        if (detail?.fields) {
            // Only include
            options.fields = options.fields.filter((x) => detail.fields.includes(x.id))
        }

        dispatch(showPrompt(options))

    }
    function getLoginButtons() {
        if (salescenter || loggedIn) {
            return null
        }
        if (screen.isMobile) {
            const items = []
            if (logIn) {
                items.push({ value: 'login', text: 'Sign In' })
            }
            // if (signUp) {
            // items.push({ value: 'signup', text: 'Sign Up' })
            // }
            return <DropdownMenu
                text={null}
                contentAnimation="fadeInUp"
                buttonClass={altTheme ? 'no-bg' : ''}
                icon={icons.user}
                asList={false}
                items={items}
                onChange={handleLoginDropdown} />
        } else {
            const elements = []
            if (logIn) {
                elements.push(<Button onClick={handleLogIn}>Sign In</Button>)
                // elements.push(<Button noBg onClick={handleLogIn}>Log In</Button>)
            }
            // if (signUp) {
            //     elements.push(<Button noBg={altTheme} onClick={handleSignUp}>Sign Up</Button>)
            // }
            return elements
        }
    }

    function getOrganizationOptions() {
        if (!organization) {
            return null
        }
        if (!standaloneApp) {//} && organizationVisited) {
            // if (OrganizationContentElement && !standaloneApp) {//} && organizationVisited) {
            //     return <Suspense fallback={null}>
            //         <OrganizationContentElement app={project} organization={organization} link="options" show={!collapseMenu || showOrganizationOptions} onSelect={() => setShowOrganizationOptions(false)} mobile={screen.isMobile}>
            //             <Logo onClick={handleHome} expanded={bigLogo} />
            //         </OrganizationContentElement>
            //     </Suspense>


            let prefix = '/'
            if (!customDomain.organization) {
                prefix += `${organization.link}/`
            }
            const orgPages = organizationRoutes(organization)

            return <React.Fragment>
                {canExpand != null && <Logo onClick={handleHome} expanded={bigLogo} />}
                {(!collapseMenu || showOrganizationOptions) &&
                    <div className={`column organization-options${screen.isMobile ? ' animate__animated animate__fadeInDown animate__fast' : ''}`}>
                        {orgPages.filter((x) => x.showInMenu).map((x) => {
                            return <Link to={`${prefix}${x.link}`} key={x.link}>
                                <Button className={(links.baseRoute == x.link || links.appLink == x.link || links.pageLink == x.link) ? 'selected' : ''} noBg onClick={() => setShowOrganizationOptions(false)}>{x.name}</Button>
                            </Link>
                        })}
                    </div>
                }
            </React.Fragment>
        } else {
            return <Logo onClick={handleHome} expanded={bigLogo} />
        }
        return null
    }


    const extraOptionsBefore = []
    const extraOptionsAfter = []

    if (showRequestInfo) {
        if (project?.meta.bookingPhone) {
            extraOptionsBefore.push(<a href={`tel:${project.meta.bookingPhone}`} key="call" className="animate__animated animate__fadeInUp animate__delay-2s animate__slow">
                <Button noBg rounded>{fnc.phoneNumber(project.meta.bookingPhone)}</Button>
            </a>)
        }
        extraOptionsBefore.push(<Button key="request" className="animate__animated animate__fadeInUp animate__delay-2s animate__slow" icon={icons.paperPlane} onClick={handleRequestInfo} tertiary rounded>Request Info</Button>)
    }

    if (loggedIn && salescenter) {
        extraOptionsBefore.push(<Button key="logout" className="animate__animated animate__fadeInUp animate__fast" icon={icons.signOut} onClick={handleEndSession} tertiary rounded>End Session</Button>)
    }

    if ((hasEditPermissions || hasAdminPermissions) && links.appLink != null && project && project.meta.link == links.appLink) {
        if (screen.isMobile) {
            extraOptionsBefore.push(<IconButton key="edit" icon={icons.pen} onClick={handleEditApp} />)
        } else {
            extraOptionsBefore.push(<Button key="edit" onClick={handleEditApp}>{hasEditPermissions ? 'Project Admin' : 'Administration'}</Button>)
        }
    }

    const split = getWindowLocation().pathname.split('/').filter((x) => x.length > 0)
    const lastLink = split.length > 0 ? split[split.length - 1] : null
    if (/*organizationVisited && getWindowLocation().pathname !== rootPath &&*/ !standaloneApp && lastLink !== PageType.Developments && !standaloneApp && (!(standaloneOrg && organization.link != 'thepropertylinq') || (links.baseRoute == PageType.Developments && links.appLink != null))) {
        extraOptionsBefore.push(<React.Fragment key="browse">
            {collapseMenu && <IconButton noBg={altTheme} icon={icons.map} onClick={handleSearch} />}
            {!collapseMenu && <Button noBg={altTheme} icon={icons.map} onClick={handleSearch}>Browse Communities</Button>}
        </React.Fragment>)
    }

    {/*if (standaloneOrg && !standaloneApp) {
        extraOptionsAfter.push(<Button key="request" tertiary onClick={handleContact}>{contactText}</Button>)
    }*/}

    if (collapseMenu && OrganizationContentElement) {
        extraOptionsAfter.push(<IconButton key="bars" noBg={altTheme} icon={icons.hamburger} onClick={() => setShowOrganizationOptions(!showOrganizationOptions)} />)
    }

    let menuItems = []
    if (loggedIn) {
        menuItems = [
            {
                element: <span className="heading row">
                    <div className="column">
                        <div className="row">
                            <span>{user.name}</span>
                        </div>
                        {salescenter && <div className="row">
                            <span>{user.email}</span>
                        </div>}
                        {salescenter && <div className="row">
                            <span>{fnc.phoneNumber(user.phone)}</span>
                        </div>}
                    </div>
                </span>
            },
            {
                text: 'Log Out',
                value: 'logout',
            },
            {
                text: user.admin ? 'Admin Panel' : 'Account',
                value: 'account',
            },

        ]
        if (user?.admin) {
            if (editInline) {
                menuItems.push({ value: 'edit', text: 'Stop Editing' })
            } else {
                menuItems.push({ value: 'edit', text: 'Edit Page' })
            }
        }
        if (user.admin) {
            let appItems = []
            user.apps.forEach((userApp) => {
                const selected = project && project.meta.id == userApp.id
                if (userApp) {
                    appItems.push({
                        element: <Button className={selected ? 'selected' : ''} onClick={!selected ? () => handleAppChange(userApp) : null}>{userApp.name}</Button>
                    })
                }
            })
            if (appItems.length > 0) {
                menuItems.push({
                    element: <span className="heading">Projects</span>
                })
                menuItems = menuItems.concat(appItems)
            }
        }
    }


    return <TopBar className={`mainbar animate__animated animate__fadeIn${bigLogo ? ' expanded' : ''}`}>
        <div className="row">
            <div className="options left">
                {getOrganizationOptions()}
            </div>
            <div className="options right">
                {extraOptionsBefore}
                {extraOptionsBefore.length > 0 && <div className="options-divider" />}
                {/* {!standaloneApp && <FavouriteNavButton noBg={altTheme} />} */}
                {<FavouriteNavButton noBg={altTheme} />}
                {getLoginButtons()}
                {salescenter && !loggedIn && !requireLogin && <Button noBg={altTheme} onClick={handleLogInPIN}>Login with PIN</Button>}
                {loggedIn && <DropdownMenu
                    icon={icons.user}
                    openIcon={icons.times}
                    contentAnimation="fadeInUp"
                    buttonClass={altTheme ? 'no-bg' : ''}
                    asList={false}
                    onChange={handleUserSelect}
                    items={menuItems}
                />}

                {extraOptionsAfter.length > 0 && <div className="options-divider" />}
                {extraOptionsAfter}
                {/* <Button noBg onClick={handleAboutUs}>About Us</Button> */}
            </div>
            {/*<div className="options left">
                {location.pathname.length > 1 && <BackButton onClick={handleHome}/>}
            </div>*/}
        </div>
    </TopBar>
}
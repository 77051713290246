import React, { useEffect, useState, useRef } from 'react'
import {
    PromptType, UpgradeSpaceData, CustomEventType, NotificationStatus, ListStyle, UserRole, UpgradeOrderStatus, ScreenOrientation, UpgradePricingMode, Visibility, ErrorMessage, UserOrderStatus, PromptOptions, ThumbSize, UpgradeTier, FAQType,
} from 'app/types'
import { useAppDispatch, useAppSelector, useLocalStorage, useSessionStorage } from 'app/hooks'
import {
    Message, Button, Spinner, Media, Icon, IconButton, SlideShow, CloseButton, FlipNumbers, Swipe, Tile, Input,
} from 'components'
import * as fnc from 'helpers/fnc'
import {
    completeUpgrade,
    logUpgrade, pushNotification, retrieveTemporaryUpgradeOrder, retrieveUpgradePdf, retrieveUpgrades, showPrompt, updateUpgradeProduct,
} from 'actions/appActions'
import userActions, {
    removeUserOrderRecord, retrieveTemporaryToken, retrieveUser, retrieveUserOrders, saveUpgradeOrderRecord, saveUserOrderRecord,
} from 'actions/userActions'
import { getAppPath, getAppUrl } from 'helpers/config'
import { logger } from 'helpers/logger'
import { getBuildingTypes } from 'actions/actionHelpers'
import { FloorplanTile } from 'views/FloorplanPage/FloorplanTile'
import { LocationLink } from 'views/HomePage/LocationLink'
import { downloadCSV } from 'helpers/csv'
import { upgradeSummaryCSV, upgradeSummaryPDF } from 'app/transformers'
import {
    selectionKey, configKey, getPrice, getConfig, clearGlobalChanges, getTourLink, getPackageName, getProductName, getOptionName,
} from './upgrades'
import { UpgradeNavGroup } from './UpgradeNavGroup'
import { UpgradeGroup } from './UpgradeGroup'
import { UpgradePopup } from './UpgradePopup'
import { ClientRow } from './ClientRow'
import { InfoBubble } from './InfoBubble'
import { getMediaLink } from 'helpers/media'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { GalleryTile } from 'components'
import Faq from 'react-faq-component'
import { faqStyles } from 'app/constants'


// const useNotification = false
interface FAQProps {
    faq: Dict,
    onClose: () => void,
}

export function FAQ(props: FAQProps) {
    const { faq, onClose } = props
    const [data, setData] = useState(null)
    useEffect(() => {

        let faqData = null
        if (faq) {
            faqData = {
                rows: faq.questions.map((x) => {
                    return {
                        title: x.title,
                        content: <ReactMarkdown>{x.content}</ReactMarkdown>
                    }
                })
            }
        } else {
            faqData = {}
        }
        setData(faqData)
    }, [faq])

    if (!data) {
        return
    }
    const titleStyle = {}
    if (faq?.titleAlignment != null) {
        titleStyle.textAlign = faq.titleAlignment
    }
    const introStyle = {}
    if (faq?.introAlignment != null) {
        introStyle.textAlign = faq.introAlignment
    }

    const isEmail = faq?.faqTypeId == FAQType.UpgradesEmail

    return <Message className={`faq-page${onClose ? ' scrollable show-scroll' : ''}${isEmail ? ' email-faq' : ''}`} onMouseDown={(e) => e.stopPropagation()}>
        {onClose && <CloseButton onClick={onClose} />}
        <h2 style={titleStyle}>{faq?.title || 'Not Configured'}</h2>
        <span style={introStyle}>{faq?.intro}</span>
        {!isEmail && <Faq data={data} styles={faqStyles} />}
        {isEmail && data.rows.map((x) => {
            return <div className="row faq-row">
                <h3>{x.title}</h3>
                {x.content}
            </div>
        })}
    </Message>
}

import React, { useEffect, useState, Suspense, useRef } from 'react'
import {
    BrowserRouter as Router,
    // Router,
    Route,
    Switch,
    useHistory,
    useLocation,
} from 'react-router-dom'
import {
    agreeToCookies,
    resize,
    retrieveConfig,
    showPrompt,
    navigateAsync,
    recordAnalytics,
    setIdle,
    applyTheming,
    initializeTheming,
    rebuildHistory,
    setScreenFocus,
    applyStateCookies,
} from 'actions/appActions'
import userActions, {
    refreshHumanConfidence,
    retrieveReCaptchaScore,
    retrieveUser,
    setHumanConfidence,
} from 'actions/userActions'
import {
    RootState,
    PromptOptions,
    CustomEventType,
    AdminView,
    PageType,
    Analytics,
    Environment,
    PromptType,
    UserRole,
} from 'app/types'
import { useAdminPermissions, useAppDispatch, useAppSelector } from 'app/hooks'
import { Button, Spinner, LoginHooks, Prompt, ErrorBoundary, Notifications, IconButton } from 'components'
import { HomePage } from 'views'
const AdminPage = React.lazy(() => import('views/AdminPage/AdminPage'))
import { logger } from 'helpers/logger'
import * as fnc from 'helpers/fnc'
import history from 'helpers/routerHistory'

import '../style/App.scss'
import '../style/animate.min.css'
import 'nouislider/dist/nouislider.css'
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-markdown-editor-lite/lib/index.css';
import { hubspotIdentifyUser, hubspotTrackPageView, loadReCaptcha, loadFacebookPixel, loadGoogleTag, loadHotjar, loadHubspotTracking, executeReCaptcha, initializeSentry } from 'helpers/scripts'
import { getAnalytics, getAppPath, getEnv, getHotjar, getWindowLocation, getHost, getAppUrl } from 'helpers/config'
import { HistoryManager } from './HistoryManager'
import { organizationRoutes, utilityRoutes } from './transformers'
import { applyCookies } from './state'
import { InlineContentEditor } from 'content/InlineContentEditor'
import { icons } from './constants'

const INACTIVITY_TIMEOUT = 10 * 60 * 1000 // 10 minutes
const IDLE_TIMEOUT = 3 * 60 * 1000 // 3 minutes

const isDebug = process.env.DEBUG == 1
let debugCount = 0
initializeSentry()

function getLinksKey(links) {
    return `${links.baseRoute}${links.organizationLink}${links.appLink}${links.pageLink}${links.dataLink}${links.extraLink}`
}

function Confirm(props) {
    const { setConfirm, confirmCallback } = props
    function allowTransition() {
        setConfirm(false)
        confirmCallback(true)
    }

    function blockTransition() {
        setConfirm(false)
        confirmCallback(false)
    }

    return <Prompt prompt={{ type: PromptType.Confirm, title: 'Unsaved Changes', message: 'Are you sure you want to leave? If you leave, your changes will be lost.', cancelMessage: 'Discard Changes', confirmMessage: 'Stay Here', onClose: blockTransition, onConfirm: blockTransition, onCancel: allowTransition }} onDismiss={blockTransition} />
}


export function App() {
    const cookies = useAppSelector((state: RootState) => state.app.cookies)
    const dispatch = useAppDispatch()

    const analytics = useAppSelector((state: RootState) => state.app.analytics)

    const editInline = useAppSelector((state: RootState) => state.admin.editInline)
    const user = useAppSelector((state: RootState) => state.user.data)
    const token = useAppSelector((state: RootState) => state.user.token)
    const loggedIn = useAppSelector((state: RootState) => state.user.loggedIn)

    const config = useAppSelector((state: RootState) => state.app.config)
    const initialized = useAppSelector((state: RootState) => state.app.initialized)
    const error = useAppSelector((state: RootState) => state.app.error)

    const salescenter = useAppSelector((state: RootState) => state.app.salescenter)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const idle = useAppSelector((state: RootState) => state.app.idle)
    const app = useAppSelector((state: RootState) => state.app.projectCurrent[0])
    const navigation = useAppSelector((state: RootState) => state.app.navigation)
    const links = useAppSelector((state: RootState) => state.app.links)

    const organization = useAppSelector((state: RootState) => state.app.organization)

    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)

    const [prevLinks, setPrevLinks] = useState(null)

    const [debugMessages, setDebugMessages] = useState([])
    const [showDebugMessages, setShowDebugMessages] = useState(true)
    const [loading, setLoading] = useState(2)


    const [confirm, setConfirm] = useState(false)
    const [confirmCallback, setConfirmCallback] = useState(null)

    const lastConfidenceCheck = useRef('initial')
    const confidenceRefresh = useAppSelector((state: RootState) => state.user.confidenceRefresh)
    const humanConfidence = useAppSelector((state: RootState) => state.user.humanConfidence)
    const analyticsInitialized = useRef(false)
    const isStaging = getAppUrl().includes('stage')
    const adminPage = navigation && navigation.length > 0 && navigation[0] && navigation[0].includes('admin')
    const adminPermissions = useAdminPermissions()
    const editAdmin = adminPermissions(app, organization, [UserRole.PagesEdit, UserRole.ContentEdit])
    const trackingInitialized = useRef(false)

    useEffect(() => {
        if (isDebug) {
            window.addEventListener(CustomEventType.DebugMessage, handleDebug)
        }
        return () => {
            if (isDebug) {
                window.removeEventListener(CustomEventType.DebugMessage, handleDebug)
            }
        }
    }, [debugMessages])

    useEffect(() => {
        window.addEventListener('focus', handleFocus)
        window.addEventListener('blur', handleBlur)
        return () => {
            window.removeEventListener('focus', handleFocus)
            window.removeEventListener('blur', handleBlur)
        }

    }, [screen.focus])

    useEffect(() => {
        handleResize()
        const asyncInit = async () => {
            await dispatch(retrieveConfig())
            await dispatch(initializeTheming())
        }
        asyncInit().catch(logger.error)

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        // logger.info("SKIP ANALYTICS TEST")
        // return

        if (analyticsInitialized.current) {
            return
        }
        if (!cookies) {
            logger.info("Cookies not accepted")
            return
        }
        // Wait until project initialized
        if (Object.keys(links).length == 0 || (!organization && !app) || (links.appLink && !app)) {
            return
        }

        if (!getAnalytics() && false) {
            logger.info('Skipping analytics')
            return
        }

        // Put on timeout to give routing change to load app page
        const timeout = setTimeout(() => {
            let hotjar = null
            let googleTag = null
            let facebookPixel = null
            let hubspot = null
            let proms = []

            if (analyticsInitialized.current) {
                return
            }


            // Prioritize per-app tracking
            // TODO, still not working
            if (app && standaloneApp) {
                logger.info('Using app analytics')
                const key = `app_${app.meta.link}`
                if (app.meta.hotjarId) {
                    hotjar = [key, app.meta.hotjarId]
                }
                if (app.meta.googleTagId) {
                    googleTag = [key, [app.meta.googleTagId]]
                }
                if (app.meta.facebookPixelId) {
                    facebookPixel = [key, app.meta.facebookPixelId]
                }
                if (app.meta.hubspotTrackingId) {
                    hubspot = [key, app.meta.hubspotTrackingId]
                }
            }
            let org = organization || config.organizations.find((x) => x.link == 'homegyde')
            if (org) {
                const key = `organization_${org.link}`
                if (!hotjar && org.hotjarId) {
                    hotjar = [key, org.hotjarId]
                }
                if (!googleTag && org.googleTagId) {
                    const tagIds = new Set([org.googleTagId])
                    org.analyticsEvents.forEach((x) => {
                        tagIds.add(x.googleTagId)
                    })
                    googleTag = [key, Array.from(tagIds)]
                }
                if (!facebookPixel && org.facebookPixelId) {
                    facebookPixel = [key, org.facebookPixelId]
                }
            }
            if (hotjar) {
                proms.push(loadHotjar(hotjar[0], hotjar[1]))
            }
            if (googleTag && googleTag.length > 0) {
                proms.push(loadGoogleTag(googleTag[0], googleTag[1]))
            }
            if (facebookPixel) {
                proms.push(loadFacebookPixel(facebookPixel[0], facebookPixel[1]))
            }
            if (hubspot) {
                proms.push(loadHubspotTracking(hubspot[0], hubspot[1]))
            }
            if (proms.length > 0) {
                analyticsInitialized.current = true
            }
            Promise.all(proms)
                .then((x) => {
                    let buttonExists = false
                    let checkCount = 100
                    const checkInterval = setInterval(() => {
                        if (buttonExists || checkCount-- <= 0) {
                            clearInterval(checkInterval)
                            return
                        }
                        document.getElementById('hs-eu-confirmation-button')?.addEventListener('click', () => {
                            buttonExists = true
                            initializeTracking(x)
                        })
                    }, 1000)
                    initializeTracking(x)
                })
        }, 100)
        return () => {
            clearTimeout(timeout)
        }
    }, [links, organization, app, cookies])

    useEffect(() => {
        if (Object.keys(links).length == 0) {
            return
        }
        const orgPage = organization && links.appLink && (organizationRoutes(organization)?.map((x) => x.link).includes(links.appLink) || utilityRoutes(organization)?.includes(links.appLink))
        // Page loaded, show
        if (loading == 2 && (((!links.appLink || orgPage || adminPage) && initialized == 2) || (links.appLink && initialized == 3))) {
            setTimeout(() => {
                setLoading(1)
                setTimeout(() => {
                    setLoading(0)
                }, 250)
            }, 250)
        }
    }, [initialized, links])

    useEffect(() => {
        if (token && !loggedIn && !window.location.pathname.includes('reset-password') && !window.location.pathname.includes('activate-account')) {
            dispatch(retrieveUser())
                /*.then((x) => {
                    if (!x.payload) {
                        dispatch(showPrompt(PromptOptions.LoginError))
                    }
                    return x
                })*/
                .catch(logger.error)
        }
    }, [token])

    // Poll for user activity
    useEffect(() => {
        if (!salescenter || !loggedIn) {
            return
        }

        let timeout = null
        let testing = false
        const inactivityTimer = () => {
            if (testing) {
                return
            }
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                testing = true
                dispatch(showPrompt(PromptOptions.Inactive))
                    .then((x) => {
                        if (x.payload) {
                            testing = false
                            inactivityTimer()
                        } else {
                            testing = false
                            window.dispatchEvent(new CustomEvent(CustomEventType.LogOut))
                            // dispatch(navigateAsync({ pageType: '', options: { path: { baseRoute: '' } } }))
                        }
                    })
            }, INACTIVITY_TIMEOUT)
        }

        document.addEventListener('mousemove', inactivityTimer)
        document.addEventListener('keypress', inactivityTimer)
        return () => {
            document.removeEventListener('mousemove', inactivityTimer)
            document.removeEventListener('keypress', inactivityTimer)
            clearTimeout(timeout)
        }
    }, [loggedIn])

    // Idle state
    useEffect(() => {
        let timeout = null
        let tempIdle = idle
        const activity = () => {
            clearTimeout(timeout)
            if (tempIdle) {
                dispatch(setIdle(false))
                tempIdle = false
            }
            timeout = setTimeout(() => {
                dispatch(setIdle(true))
            }, IDLE_TIMEOUT)
        }
        if (!idle) {
            activity()
        }

        window.addEventListener('mousemove', activity)
        window.addEventListener('keypress', activity)
        return () => {
            clearTimeout(timeout)
            window.removeEventListener('mousemove', activity)
            window.removeEventListener('keypress', activity)
        }
    }, [idle])

    // Analytics
    useEffect(() => {
        if (idle) {
            return
        }

        let data = {}
        let analyticsType = null
        // TODO, figure out how to avoid this hack
        if (links.appLink == PageType.Compare || links.appLink == PageType.Favourite) {
            data = {
                appLink: null,
                pageLink: links.appLink,
                organizationLink: links.organizationLink,
            }
        } else {
            data = {
                appLink: links.appLink,
                pageLink: links.pageLink,
                dataLink: links.dataLink,
                organizationLink: links.organizationLink,
            }
        }

        // Invalid links, still initializing
        if (!data.appLink && !data.pageLink || (data.appLink != null && (!app || app.meta.link != data.appLink))) {
            return
        }

        // No page = homepage for project
        if (data.appLink && !data.pageLink) {
            data.pageLink = 'home'
        }

        /*const acceptedPages = new Set([PageType.Floorplan, PageType.Modelhome])
        if (!acceptedPages.has(data.pageLink)) {
            // return
        }*/
        const linksKey = getLinksKey(links)//JSON.stringify(links)
        const sendAnalytics = (duration = null) => {
            // if ((data.appLink && data.appLink != 'compare') || data.pageLink) {
            if (data.appLink && data.appLink != 'compare') {
                dispatch(recordAnalytics({ type: Analytics.Pages, data: { app, ...data, duration } })).catch(logger.error)
            }
        }

        let recordTimeout = null
        let sinceLast = Date.now()
        if (linksKey !== prevLinks) {
            sendAnalytics()
            setPrevLinks(linksKey)

            const recordDuration = (x) => {
                recordTimeout = setTimeout(() => {
                    sendAnalytics(x * 1000)
                    sinceLast = Date.now()
                    recordDuration(x * 2)
                }, x * 1000)
            }
            recordDuration(10)
        }

        return () => {
            clearTimeout(recordTimeout)
            let duration = Date.now() - sinceLast
            if (duration > 0 && duration <= IDLE_TIMEOUT) {
                sendAnalytics(duration)
            }
        }
    }, [links, idle, app])

    // ReCaptcha
    useEffect(() => {
        loadReCaptcha()
            .then((x) => {
                dispatch(refreshHumanConfidence())
            })
    }, [])

    useEffect(() => {
        if (confidenceRefresh == null) {
            return
        }
        executeReCaptcha()
            .then((x) => {
                dispatch(retrieveReCaptchaScore(x))
                    .then((ret) => {
                        const { score } = ret.payload
                        logger.info("Human confidence: ", score)
                        dispatch(setHumanConfidence(score))
                    })
            })
            .catch(logger.info)

    }, [confidenceRefresh])

    function initializeTracking(x) {
        if (trackingInitialized.current) {
            return
        }
        trackingInitialized.current = true
        // logger.info("SKIP TRACKING TEST")
        // return
        logger.info("Initializing tracking", analytics)
        // Apply ingested cookies if they exist
        dispatch(applyStateCookies())

        // Check for cookie
        const data = {
            ...analytics,
            appId: app?.id,
            hubspotId: fnc.getCookie('hubspotutk'),
            hubspotTrackingId: fnc.getCookie('hubspotutk'),
            facebookTrackingId: fnc.getCookie('_fbp'),
            googleTrackingId: fnc.getCookie('_ga'),
            hotjarTrackingId: fnc.matchCookie('_hj').join(','),
            hubspotTrackingIdAlt: fnc.getCookie('_hsenc'),
        }
        // Identify user
        // hubspotIdentifyUser(null, analytics)

        setTimeout(() => {
            dispatch(recordAnalytics({ type: Analytics.Tracking, data }))
                .then((x) => {
                    // logger.info("Tracking user", x?.payload?.user)
                })
        }, 1)
    }


    function getConfirmation(message, callback) {
        setConfirmCallback(() => callback)
        setConfirm(true)
    }

    function handleFocus() {
        dispatch(setScreenFocus(true))
    }

    function handleBlur() {
        dispatch(setScreenFocus(false))
    }

    function handleHome() {
        dispatch(navigateAsync({ pageType: PageType.Home, options: { root: true, path: { baseRoute: '' } } }))
    }

    function handleBack(location = null) {
        if (location != null) {
            // window.location = location
            // return
        }
        if (adminPage) {
            dispatch(navigateAsync({ pageType: PageType.Admin, options: { dataLink: AdminView.Profile, path: { baseRoute: '' } } }))
        } else if (links && Object.keys(links).length == 0 || !(links && links.appLink)) {
            handleHome()
        } else if (links && links.appLink) {
            dispatch(navigateAsync({ pageType: PageType.Home, app, options: { path: { baseRoute: '' } } }))
        } else {
            dispatch(navigateAsync({ pageType: PageType.Home, options: { path: { baseRoute: '' } } }))
        }
    }

    function handleReport() {
        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Issue reported', message: 'Thank you for reporting this issue. We will look into it as soon as possible.' }))
    }

    function handleAgree() {
        dispatch(agreeToCookies())
    }

    // Monitor screen size
    function handleResize() {
        document.getElementById('app').setAttribute('data-device', screen.isMobile ? 'mobile' : 'desktop')
        dispatch(resize())
        // window.dispatchEvent(new CustomEvent(CustomEventType.DebugMessage, { detail: `Width: ${window.innerWidth}, Height: ${window.innerHeight} Size: ${ScreenSize[screen.size]}, Orientation: ${ScreenOrientation[screen.orientation]}, Mobile?: ${screen.isMobile} UserAgent: ${window.navigator.userAgent}` }))
        // window.dispatchEvent(new CustomEvent(CustomEventType.DebugMessage, { detail: `UserAgent: ${window.navigator.userAgent}` }))
        window.dispatchEvent(new CustomEvent(CustomEventType.DebugMessage, { detail: screen.isMobile }))
    }

    function handleDebug(x) {
        if (!x.detail) {
            return
        }
        const newDebug = [...debugMessages]
        newDebug.unshift(`(${debugCount}) ${x.detail}`)
        debugCount += 1
        setDebugMessages(newDebug.slice(0, 10))
    }

    // Debug keys
    /* useEffect(() => {
        setTimeout(() => {
            document.addEventListener('keydown', handleKeyPress, false)
            document.addEventListener('touchstart', tryCancelEvent, false)
            document.addEventListener( 'gesturestart', trySurpressEvent, false);
        })
        return () => {
            document.removeEventListener('keydown', handleKeyPress, false)
            document.removeEventListener('touchstart', tryCancelEvent, false)
            document.removeEventListener( 'gesturestart', trySurpressEvent, false);
        }
    }, [config])
    
    
    function handleKeyPress(e:Event) {
        if (Object.keys(config).length === 0) {
            return
        }
        for (let i = 0; i < config.themes.length; i += 1) {
            if (e.code === `Digit${i + 1}`) {
                dispatch(applyTheme(config.themes[i].id))
            }
        }
    
        for (let i = 0; i < config.colorSchemes.length; i += 1) {
            if (e.code === `Digit${i + config.themes.length + 1}`) {
                dispatch(applyColorScheme(config.colorSchemes[i].id))
            }
        }
    }*/

    // Get organization based on either custom domain or first routing parameter
    function getOrganization(linkA = null) {
        // Check if using custom domain
        const host = getHost()
        const domainMatch = host && host != 'homegyde' ? config.organizations.find((x) => x.link == host || x.domain == host) : null
        if (domainMatch) {
            return domainMatch
        } else if (linkA && config.organizations && config.organizations.length > 0) {
            return config.organizations.find((x) => x.link == linkA)
        }
    }

    const pageElem = // Three stage initialization, loading = 0, config loaded = 1 , page loaded = 2
        <React.Fragment key="app">
            <ErrorBoundary onBack={handleBack} onReport={handleReport} error={error} initialized={initialized}>
                <LoginHooks />
                {isStaging && <h3 className="stage-watermark">STAGING</h3>}
                {<Spinner show={loading == 2} overlay quotes={false} />}
                {/* {initialized <= 1 && error && <h3 className="error-message fadeIn">{error}</h3>} */}
                {initialized > 0 && config && <Router getUserConfirmation={getConfirmation} basename={getAppPath()}>
                    <HistoryManager>
                        {/* <Route exact path='/search' component={HomePage}/> */}
                        <Route path='/:organizationLink/admin/:linkA?/:linkB?' render={(renderProps) => {
                            const { organizationLink, linkA, linkB } = renderProps.match.params
                            let adminElem = null
                            if (Object.values(AdminView).includes(linkA)) {
                                adminElem = <AdminPage organizationLink={organizationLink} appLink={null} pageLink={linkA} {...renderProps} />
                            } else {
                                adminElem = <AdminPage organizationLink={organizationLink} appLink={linkA} pageLink={linkB} {...renderProps} />
                            }
                            return <Suspense fallback={<Spinner overlay />}>
                                {adminElem}
                            </Suspense>
                        }} />
                        <Route path='/admin/:linkA?/:linkB?' render={(renderProps) => {
                            const { linkA, linkB } = renderProps.match.params
                            let adminElem = null
                            const organization = getOrganization()
                            if (Object.values(AdminView).includes(linkA)) {
                                adminElem = <AdminPage organizationLink={organization?.link} appLink={null} pageLink={linkA} {...renderProps} />
                            } else {
                                adminElem = <AdminPage organizationLink={organization?.link} appLink={linkA} pageLink={linkB} {...renderProps} />
                            }
                            return <Suspense fallback={<Spinner overlay />}>
                                {adminElem}
                            </Suspense>
                        }} />
                        <Route path='/:linkA?/:linkB?/:linkC?/:linkD?/:linkE?' render={(renderProps) => {
                            const { linkA, linkB, linkC, linkD, linkE } = renderProps.match.params

                            // Dynamically choose what to render (organization / app)
                            const organization = getOrganization(linkA)

                            // Base home page
                            /*if (!linkA) {
                                return <HomePage organizationLink={...renderProps} />
                            }
                            // Organization Home page
                            if (organization && !linkB) {
                                return <HomePage organizationLink={linkA} {...renderProps} />
                            }*/
                            // Sub page
                            switch (organization && linkA == organization.link ? linkB : linkA) {
                                case 'admin':
                                    return null
                                case 'activate-account':
                                case 'reset-password':
                                case 'favourites':
                                case 'about-us':
                                case 'privacy-policy':
                                case PageType.Search:
                                case PageType.Developments:
                                default:
                                    return <HomePage organizationLink={organization?.link} loading={loading == 2} {...renderProps} />
                            }
                        }} />
                        {/* <Route exact path='/homegyde/:organization/:appLink' component={AppPage}/> */}
                        {/* <Route path='/:appLink' component={AppPage}/> */}
                    </HistoryManager>
                </Router>}
                <Notifications />
                {confirm && <Confirm confirmCallback={confirmCallback} setConfirm={setConfirm} />}
                <Prompt />
                {isDebug && <div className="debug-messages">
                    {showDebugMessages && debugMessages.map((x, ix) => <span key={ix}>{x}</span>)}
                    <div className="top-right" style={{ left: '100%', right: 'auto' }}>
                        {showDebugMessages && <IconButton onClick={() => setShowDebugMessages(false)} icon={icons.chevronLeft} />}
                        {!showDebugMessages && <IconButton onClick={() => setShowDebugMessages(true)} icon={icons.chevronRight} />}
                    </div>
                </div>}
                {!cookies && <div className="cookie-footer">
                    <div className="title">
                        <h3>Cookie Consent</h3>
                    </div>
                    <span>This site uses cookies to enhance functionality and for analytics, as explained in our<a href="/cookie-policy">cookie policy</a>.<br />If you agree to our use of cookies, please click this button to close this message and continue using the site.</span>
                    <Button onClick={handleAgree}>Accept and Close</Button>
                </div>}
            </ErrorBoundary>
        </React.Fragment >


    if (editAdmin && links?.appLink != PageType.Admin) {
        return <div className="row">
            {editInline && <InlineContentEditor />}
            <div className="column">
                {pageElem}
            </div>
        </div>
    }
    return pageElem
}

export default App

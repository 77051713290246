import React, { useState, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
    RootState,
    MinMax,
    PromptType,
    CustomEventType,
    QueryType,
    ScreenSize,
    ScreenOrientation,
} from 'app/types'
import {
    showPrompt,
} from 'actions/appActions'
import {
    TopBar,
    Icon,
    Button,
    CloseButton,
    Filters,
    FilterList,
    DropdownMenu,
} from 'components'
import * as fnc from 'helpers/fnc'
import { logger } from 'helpers/logger'
import { IconButton } from './Core/IconButton'

interface FilterOptionsProps {
    app: AppData,
    className: string,
    queryType: QueryType,
    ranges: { [key: string]: string[] | number[] | MinMax },
    filters?: Filter[],
    filterSubset?: string[],
    floorplans: FloorplanData[],
    children?: JSX.Element[],
    search?: boolean,
    forceTile?: boolean,
    heightRef: React.RefObject,
    expandedAsDropdown?: boolean,
    listSelected?: boolean,
    splitIndex?: number,
    maps: Dict,
    minimal: boolean,
    readOnly: boolean,
}

export function FilterOptions(props: FilterOptionsProps) {
    const dispatch = useAppDispatch()
    const query = useAppSelector((state: RootState) => state.app.queries[props.queryType])
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const filterCount = query ? Object.keys(query).length : 0
    const [showFilterTile, setShowFilterTile] = useState(false)

    function handleFullscreen() {
        dispatch(showPrompt({
            type: PromptType.Filters,
            app: props.app,
            filters: props.filters,
            floorplans: props.floorplans,
            ranges: props.ranges,
            queryType: props.queryType,
            expandedAsDropdown: props.expandedAsDropdown,
            maps: props.maps,
        })).catch(logger.error)
    }

    function handleTileClick(e: Event) {
        e.stopPropagation()
        window.dispatchEvent(new Event(CustomEventType.CloseAllMenus))
    }

    function getFilterTile() {
        const tileStyle = { opacity: 1 }
        if (props.forceTile) {
            tileStyle.position = 'absolute'
            tileStyle.top = 0
        }
        return <div className="tile filter-tile" style={tileStyle} onClick={handleTileClick}>
            {props.readOnly && <FilterList type={props.queryType} filters={props.filters?.map((x) => x.filter)} maps={app?.maps} readOnly={props.readOnly} />}
            {!props.readOnly && <Filters
                app={props.app}
                queryType={props.queryType}
                ranges={props.ranges}
                filters={props.filters}
                filterSubset={props.filterSubset}
                search={props.search}
                expanded={true}
                listSelected={props.listSelected}
                // readOnly={props.readOnly}
                maps={props.maps} />}
            {!props.readOnly && <CloseButton noBg className="alt" onClick={() => setShowFilterTile(false)} />}
        </div>
    }

    function closeMenu() {
        setShowFilterTile(false)
    }

    useEffect(() => {
        window.addEventListener('click', closeMenu)
        // window.addEventListener(CustomEventType.CloseAllMenus, closeMenu)
        return () => {
            window.removeEventListener('click', closeMenu)
            // window.removeEventListener(CustomEventType.CloseAllMenus, closeMenu)
        }
    }, [showFilterTile])

    function getFilterButton(callback) {
        if (screen.isMobile || props.splitIndex != null || props.minimal) {
            if (filterCount > 0) {
                return <Button
                    icon="fas fa-filter"
                    className="filtered"
                    tertiary
                    onClick={callback}>
                    {`(${filterCount})`}
                </Button>
            }
            if (props.readOnly) {
                return null
            }
            return <IconButton
                icon="fas fa-filter"
                tertiary
                onClick={callback} />
        }
        return <Button
            icon="fas fa-filter"
            className={filterCount > 0 ? 'filtered' : null}
            onClick={callback}>
            {filterCount > 0 ? `Filters (${filterCount})` : 'Filters'}
        </Button>
    }

    if (props.forceTile) {
        // if (props.minimal) {
        //     return <IconButton icon="fas fa-filter" tertiary onClick={(e) => {
        //         e.stopPropagation()
        //         setShowFilterTile(true)
        //     }} />
        // }

        // let buttonText = 'Filters'
        // if (filterCount > 0 && props.listSelected) {
        //     buttonText = `Filters (${filterCount})`
        // }
        if (props.minimal && props.readOnly) {
            if (filterCount == 0) {
                return null
            }
            if (filterCount == 1) {
                return <FilterList type={props.queryType} filters={props.filters?.map((x) => x.filter)} maps={app?.maps} canClear={false} readOnly={true} asList={false}/>
            }
            return <DropdownMenu
                className="filter-dropdown"
                buttonClass={props.className}
                icon="fas fa-filter"
                text={filterCount > 0 ? `( ${filterCount} )` : null}
                items={[{
                    element: <FilterList type={props.queryType} filters={props.filters?.map((x) => x.filter)} maps={app?.maps} readOnly={props.readOnly} asList={true} />
                }]} />
        }
        return <div className={`filter-options${showFilterTile ? ' expanded' : ''}${props.minimal ? ' minimal' : ''}`}>
            {getFilterButton((e) => {
                e.stopPropagation()
                setShowFilterTile(true)
            })}
            {/* <Button
                // large
                tertiary
                className={filterCount > 0 ? 'filtered' : ''}
                onClick={(e: Event) => { e.stopPropagation(); setShowFilterTile(true) }}>
                {buttonText}
            </Button> */}
            {showFilterTile && getFilterTile()}
        </div>
    }

    {/* return <TopBar sticky className="FilterOptions fadeIn" onClick={(e:Event) => e.stopPropagation()} forwardRef={props.heightRef}> */ }
    if (props.splitIndex != null || screen.isMobile || (screen.size == ScreenSize.Desktop && screen.orientation == ScreenOrientation.Portrait)) {
        return <div className={`filter-options ${showFilterTile ? ' expanded' : ''}`}>
            <div className="filters">
                {getFilterButton(handleFullscreen)}
            </div>
        </div>
    }

    let filterElement = <Filters
        app={props.app}
        queryType={props.queryType}
        ranges={props.ranges}
        filters={props.filters}
        filterSubset={props.filterSubset}
        search={props.search}
        // expandedAsDropdown={props.expandedAsDropdown}
        // onExpand={() => setShowFilterTile(true)}
        onExpand={handleFullscreen}
        listSelected={props.listSelected}
        splitIndex={props.splitIndex}
        minimal={props.minimal}
        maps={props.maps} />

    return <div className={`filter-options${showFilterTile ? ' expanded' : ''}${props.className ? ` ${props.className}` : ''}`}>
        {!showFilterTile && filterElement
            && <React.Fragment>
                {/* {props.splitIndex == null && <div className="options-divider"/>} */}
                {/* {props.splitIndex == null && <Icon icon="fas fa-filter" />} */}
                {filterElement}
            </React.Fragment>}
        {showFilterTile && getFilterTile()}
    </div>

    return <React.Fragment>
        <div className={`only-big only-desktop filter-options${showFilterTile ? ' expanded' : ''}`}>
            {!showFilterTile
                && <React.Fragment>
                    {/* {props.splitIndex == null && <div className="options-divider"/>} */}
                    {props.splitIndex == null && <Icon icon="fas fa-filter" />}
                    <Filters
                        app={props.app}
                        queryType={props.queryType}
                        ranges={props.ranges}
                        filters={props.filters}
                        filterSubset={props.filterSubset}
                        search={props.search}
                        expandedAsDropdown={props.expandedAsDropdown}
                        onExpand={handleFullscreen}
                        // onExpand={() => setShowFilterTile(true)}
                        listSelected={props.listSelected}
                        splitIndex={props.splitIndex}
                        maps={props.maps} />
                </React.Fragment>}
            {showFilterTile && getFilterTile()}
        </div>
        <div className={`only-small only-big only-mobile filter-options ${showFilterTile ? ' expanded' : ''}`}>
            <div className="filters">
                {filterCount > 0 && <Button
                    icon="fas fa-filter"
                    className="filtered"
                    onClick={() => handleFullscreen()}>
                    {`(${filterCount})`}
                </Button>}
                {filterCount == 0 && <IconButton
                    icon="fas fa-filter"
                    onClick={() => handleFullscreen()}>
                </IconButton>}
                {false && <Button
                    // large
                    className={filterCount > 0 ? 'filtered' : ''}
                    onClick={() => handleFullscreen()}>
                    {filterCount > 0 ? `Filters (${filterCount})` : 'Filters'}
                </Button>}
            </div>
        </div>
        <div className={`only-small only-desktop filter-options ${showFilterTile ? ' expanded' : ''}`}>
            {!showFilterTile && <Button className={filterCount > 0 ? 'filtered' : ''} onClick={(e) => { e.stopPropagation(); setShowFilterTile(true) }}>{filterCount > 0 ? `Filters (${filterCount})` : 'Filters'}</Button>}
            {showFilterTile && getFilterTile()}
        </div>
    </React.Fragment>
    {/* </TopBar> */ }
}

import React, { useEffect, useState } from 'react'
import { Button } from 'components'

interface InfoBubbleProps {
    animationIn: string,
    animationOut: string,
    delay: number,
    duration: number,
    chevron: string
    onDismiss: () => void
}

export function InfoBubble(props: InfoBubbleProps) {
    const { duration, delay, animationIn, animationOut, chevron, onDismiss } = props
    const [direction, setDirection] = useState(0)

    useEffect(() => {
        if (delay) {
            const timeout = setTimeout(() => {
                setDirection(1)
            }, delay)
            return () => {
                clearTimeout(timeout)
            }
        } else {
            setDirection(1)
        }
    }, [])

    useEffect(() => {
        if (direction == 1 && duration) {
            const timeout = setTimeout(() => {
                setDirection(-1)
            }, duration)
            return () => {
                clearTimeout(timeout)
            }
        } else if (direction == -1) {
            const timeout = setTimeout(() => {
                setDirection(0)
                if (onDismiss) {
                    onDismiss()
                }
            }, 1000)
            return () => {
                clearTimeout(timeout)
            }
        }

    }, [direction])

    function handleClick(e) {
        e.stopPropagation()
        setDirection(-1)
    }

    if (direction == 0) {
        return null
    }

    return <div className={`info-bubble${animationIn || animationOut ? ` animate__animated ${direction == 1 ? animationIn : animationOut}` : ''}${chevron ? ` chevron-${chevron}` : ''}`} onClick={handleClick}>
        {props.children}
    </div>
}
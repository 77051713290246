import React, { useState, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
    navigateAsync,
    setQuery,
    showPrompt,
    toggleShowBuilding,
    toggleShowSitemap,
} from 'actions/appActions'
import {
    ItemList,
    Message,
    Checkbox,
    Toggle,
    Button,
    IconButton,
} from 'components'
import {
    RootState,
    PageType,
    AppData,
    QueryType,
    FloorplanFilter,
    UnitFilter,
    ListStyle,
    Availability,
    ScreenSize,
    ScreenOrientation,
    CustomEventType,
    ProjectType,
    PromptOptions,
    PromptType,
} from 'app/types'
import { logger } from 'helpers/logger'
import * as fnc from 'helpers/fnc'
import { FloorplanTile } from 'views/FloorplanPage/FloorplanTile'
import { projectFilters, sortPlan } from 'app/transformers'
import { filterFloorplan, setQueryHelper } from 'helpers/query'

interface FloorplanListProps {
    app: AppData,
    onlyFavourites: boolean,
    columns: number,
    splitIndex: number,
    pageLink: string
    pageOptions: Dict,
    onFilter: () => void,
    withBuilding: boolean,
    withSitemap: boolean,
    showFeaturedMedia: boolean,
    onFocusUnit: () => void,
    onModelhome: () => void,
    onSpin: () => void,
    focusUnit: string,
    extraFilter: () => void,
}

export function FloorplanList(props: FloorplanListProps) {
    const {
        app,
        onlyFavourites,
        columns,
        splitIndex,
        onFilter,
        withBuilding,
        withSitemap,
        showFeaturedMedia,
        onFocusUnit,
        onModelhome,
        onSpin,
        focusUnit,
        extraFilter,
        ...otherProps
    } = {
        columns: 4,
        ...props,
    }

    const dispatch = useAppDispatch()

    const config = useAppSelector((state: RootState) => state.app.config)
    const { filterRanges, projectTypes } = app.meta
    const { floorplans, units } = app
    const query = useAppSelector((state: RootState) => state.app.queries[QueryType.Floorplans])
    const fixedQuery = useAppSelector((state: RootState) => state.app.fixedQueries[QueryType.Floorplans])
    const navigation = useAppSelector((state: RootState) => state.app.navigation)
    const favourites = useAppSelector((state: RootState) => state.user.favourites[app.meta.id])
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)
    const current = useAppSelector((state: RootState) => state.app.current)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const showBuilding = useAppSelector((state: RootState) => state.app.showBuilding)
    const showSitemap = useAppSelector((state: RootState) => state.app.showSitemap)
    const [useAlt, setUseAlt] = useState(false)//showFeaturedMedia ? true : false)
    const [floorplanHeaders, setFloorplanHeaders] = useState([])
    const [listInterior, setListInterior] = useState(false)


    useEffect(() => {
        let hasBalcony = false
        for (let i = 0; i < floorplans.length; i += 1) {
            const fp = floorplans[i]
            for (let j = 0; j < fp.variations.length; j += 1) {
                const variation = fp.variations[j]
                if (variation.balconySqft) {
                    hasBalcony = true
                    break
                }
            }
            if (hasBalcony) {
                break
            }
        }
        let newHeaders = []
        if (hasBalcony) {
            newHeaders = [
                { key: 'favourite', name: '', sortable: true },
                { key: 'name', name: 'Name', sortable: true },
                { key: 'sqft', name: 'Interior Sf', sortable: true },
                { key: 'totalSqft', name: 'Total Sf', sortable: true },
                { key: 'beds', name: 'Beds', sortable: true },
                { key: 'baths', name: 'Baths', sortable: true },
                { key: 'units', name: 'Units' },
                { key: 'buttons', name: '', className: 'plan-tile-buttons' },
            ]
        } else {
            newHeaders = [
                { key: 'favourite', name: '', sortable: true },
                { key: 'name', name: 'Name', sortable: true },
                { key: 'sqft', name: 'Total Sf', sortable: true },
                { key: 'beds', name: 'Beds', sortable: true },
                { key: 'baths', name: 'Baths', sortable: true },
                { key: 'units', name: 'Units' },
                { key: 'buttons', name: '', className: 'plan-tile-buttons' },
            ]
        }
        setFloorplanHeaders(newHeaders)
        setListInterior(hasBalcony)
    }, [])




    // Determine type, if condo, show floors
    let hasCondo = false
    projectTypes.forEach((x) => {
        if (x == ProjectType.Condo) {
            hasCondo = true
        }
    })


    let filters = []


    const customFilters = projectFilters(app)
    if (customFilters) {
        filters = customFilters
    } else if (hasCondo) {
        filters = [
            { filter: FloorplanFilter.Beds },
            { filter: FloorplanFilter.Baths },
            { filter: FloorplanFilter.Favourites },
            { filter: UnitFilter.Floor, expanded: true },
            { filter: UnitFilter.Exposure, expanded: true },
            { filter: FloorplanFilter.Sqft, expanded: true },
        ]
    } else {
        filters = [
            { filter: FloorplanFilter.Beds },
            { filter: FloorplanFilter.Baths },
            { filter: FloorplanFilter.BuildingType },
            { filter: FloorplanFilter.Favourites, expanded: true },
            { filter: UnitFilter.Floor, expanded: true },
            { filter: UnitFilter.Exposure, expanded: true },
            { filter: FloorplanFilter.Sqft, expanded: true },
        ]
    }

    useEffect(() => {
        queryFilter()
    }, [window.location.search])

    function queryFilter() {
        const queryString = window.location.search.replace('?', '').split('&')
        const queryObj = {}
        queryString.forEach((x) => {
            const key = x.split('=')[0]
            const value = x.split('=')[1]
            if(!(key in queryObj)) {
                queryObj[key] = []
            }
            queryObj[key].push(value)
        })
        if (Object.keys(queryObj).length > 0) {
            const newQuery = setQueryHelper(QueryType.Floorplans, query, queryObj)
            dispatch(setQuery({ query: newQuery, type: QueryType.Floorplans }))
        }
    }

    function floorplanFilter(floorplan: FloorplanData) {
        let show = filterFloorplan(app, floorplan, { ...query, ...fixedQuery }, { splitIndex, onlyFavourites, favourites })
        if (show && extraFilter && !extraFilter(floorplan)) {
            show = false
        }
        return show
    }

    function floorplanSort(a: FloorplanData, b: FloorplanData, listStyle: ListStyle, listOrder) {
        if (listStyle === ListStyle.Grid) {
            return sortPlan(app, a, b)
            // return a.name.localeCompare(b.name)
        }

        try {
            const [sortField, sortDirection] = listOrder
            switch (sortField) {
                default:
                    break
                case 'name':
                    if (sortDirection) {
                        return a.name.localeCompare(b.name)
                    }
                    return b.name.localeCompare(a.name)
                case 'favourite': {
                    let favA = favourtes && a.id in favourites ? 1 : 0
                    let favB = favourtes && b.id in favourites ? 1 : 0
                    if (favA === favB) {
                        return a.name.localeCompare(b.name)
                    }
                    return (favA > favB ? 1 : -1) * (sortDirection ? -1 : 1)
                }
                case 'sqft':
                case 'beds':
                case 'baths':
                    // Fallback to name compare on match
                    if (a.variations[0][sortField] == b.variations[0][sortField]) {
                        return a.name.localeCompare(b.name)
                    }
                    return (a.variations[0][sortField] > b.variations[0][sortField] ? 1 : -1)
                        * (sortDirection ? -1 : 1)
                case 'totalSqft':
                    return ((a.variations[0].sqft + (a.variations[0].balconySqft || 0))
                        - (b.variations[0].sqft + (b.variations[0].balconySqft || 0)))
                        * (sortDirection ? -1 : 1)
            }
        } catch (e) {
            logger.error('Failed to sort ', a, b, e)
        }
    }

    function handleFloorplan(floorplanId: string) {
        dispatch(navigateAsync({
            app: app,
            pageType: props.pageLink || PageType.Floorplan,
            options: { ...props.pageOptions, floorplanId, splitIndex },
        })).catch(logger.error)
    }

    function handleModelhome(modelhomeId: string) {
        if (onModelhome) {
            onModelhome(modelhomeId)
        }
    }
    function handleSpin(spinId: string) {
        if (onSpin) {
            onSpin(spinId)
        }
    }

    function handleMouseOver(x, e) {
        window.dispatchEvent(new CustomEvent(CustomEventType.HoverPlan, { detail: { hover: true, floorplanLink: x.link } }))
    }
    function handleMouseLeave(x, e) {
        window.dispatchEvent(new CustomEvent(CustomEventType.HoverPlan, { detail: { hover: false, floorplanLink: x.link } }))
    }

    function floorplanMap(x: FloorplanData, ix: number, listStyle: ListStyle) {
        return <FloorplanTile
            app={app}
            favourite
            key={`${x.id}`}
            tileStyle={listStyle}
            floorplan={x}
            filters
            onFloorplan={handleFloorplan}
            onModelhome={handleModelhome}
            onSpin={handleSpin}
            onFocusUnit={onFocusUnit}
            onMouseOver={(withBuilding || withSitemap) ? (e) => handleMouseOver(x, e) : null}
            onMouseLeave={(withBuilding || withSitemap) ? (e) => handleMouseLeave(x, e) : null}
            showFeaturedMedia={showFeaturedMedia && useAlt}
            focusUnit={focusUnit}
            listInterior={listInterior} />
    }

    function getLeft() {
        return null
    }

    function getRight() {
        if (withBuilding) {
            // return <React.Fragment>
            {/* <IconButton icon={showBuilding ? 'fas fa-expand-alt' : 'fas fa-compress-alt'} onClick={() => dispatch(toggleShowBuilding())} style={{ marginRight: '0px' }}/> */ }
            {/* <div className="options-divider" /> */ }
            {/* </React.Fragment> */ }
            // return <Checkbox value={showBuilding} onChange={() => dispatch(toggleShowBuilding())}>Show Building</Checkbox>
        }

        if (withSitemap) {
            /*return (listStyle) => {
                if (listStyle != ListStyle.Grid) {
                    return null
                }
                return <Toggle items={[
                    {
                        icon: 'fas fa-vector-square',
                        value: false,
                    },
                    {
                        icon: 'fas fa-image',
                        value: true,
                    }]}
                    value={useAlt}
                    onChange={setUseAlt} />
            }*/
        }
    }

    function handleExpand() {
        if (withBuilding) {
            dispatch(toggleShowBuilding())
        } else {
            dispatch(toggleShowSitemap())
        }
    }

    function getMissing() {
        /*if (onlyFavourites) {
            return <NoFavourites/>
        }*/
        return <Message info="No floorplans matching filters" />
    }

    let refreshKey = JSON.stringify({ ...query, ...fixedQuery })
    if (onlyFavourites && favourites) {
        refreshKey += Object.keys(favourites).length
    }

    const isSplit = splitIndex != null || (withBuilding && showBuilding) || (withSitemap && showSitemap)
    let finalColumns = !isSplit ? 3 : 2

    // let finalColumns = 4
    if (screen.size > ScreenSize.Desktop) {
        if (screen.orientation === ScreenOrientation.Landscape) {
            finalColumns = !isSplit ? (screen.size == ScreenSize.Tablet ? 4 : 3) : (screen.size == ScreenSize.Tablet ? 2 : 1)
        } else {
            finalColumns = (!isSplit || (withBuilding && showBuilding) || (withSitemap && showSitemap)) ? (screen.size == ScreenSize.Tablet ? 3 : 2) : 1
        }
    } else if (screen.size == ScreenSize.Desktop && screen.orientation == ScreenOrientation.Portrait) {
        finalColumns = isSplit ? 2 : 1
    }

    return <ItemList
        id={`plan-list-${app.meta.link}`}
        className="floorplan-list"
        app={app}
        items={app.floorplans}
        splitIndex={splitIndex}
        scrollReturn={true}
        refreshKey={refreshKey}
        filters={filters}
        filter={floorplanFilter}
        sort={floorplanSort}
        map={floorplanMap}
        headers={floorplanHeaders}
        queryType={onlyFavourites ? null : QueryType.Floorplans}
        filterRanges={filterRanges}
        leftOptions={getLeft()}
        rightOptions={getRight()}
        missing={getMissing()}
        columns={finalColumns}
        onFilter={onFilter}
        titleText={!standaloneApp && (!(withBuilding && showBuilding) && !(withSitemap && showSitemap)) ? app.meta.name : null}//splitIndex != null ? app.meta.name : ''}
        expanded={(!withBuilding || !showBuilding) && (!withSitemap || !showSitemap)}
        onExpand={(splitIndex == null && (withBuilding || withSitemap)) ? handleExpand : null}
        {...otherProps}
    />
}

import React, { Suspense } from 'react'
const Spin = React.lazy(() => import('components/Spin/Spin'))
import { useAppSelector, useUserPermissions } from 'app/hooks'
import { Spinner, Message } from 'components'
import { ErrorMessage, PageType, UserRole } from 'app/types'

interface AmenitiesPageProps {
    app: AppData,
    onlyFavourites: boolean,
    dataLink: string,
    splitIndex: number,
    breadCrumbs?: boolean,
}
export function AmenitiesPage(props: AmenitiesPageProps) {
    const { app, dataLink, extraLink, splitIndex } = props

    const userPermissions = useUserPermissions()
    const spinLink = 'site'
    const viewLink = 'amenities'
    // const viewLink = 'site'
    const spin = app.spins.find((x) => x.link == spinLink)
    const view = spin ? spin.views.find((x) => x.link == viewLink) : null
    const sIdx = splitIndex != null ? splitIndex : 0

    const spinState = useAppSelector((state: RootState) => spinLink in state.spin.spinState[sIdx] ? state.spin.spinState[sIdx][spinLink] : null)
    const viewState = useAppSelector((state: RootState) => viewLink in state.spin.viewState[sIdx] ? state.spin.viewState[sIdx][spinLink] : null)

    if (!(spin?.published || userPermissions(UserRole.AppsEdit))) {
        return <Message info={ErrorMessage.MaintenanceModePage} />
    }

    return <Suspense fallback={<Spinner overlay invert />}>
        <Spin
            app={app}
            spinLink={spinLink}
            viewLink={viewState && viewState.currentView ? viewState.currentView : viewLink}
            splitIndex={splitIndex}
            splitView={false}
            pageType={PageType.Amenities} />
    </Suspense>
}
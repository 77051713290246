import { ExecuteCodeAction, ActionManager } from '@babylonjs/core'
import {
    AssetsManager,
} from '@babylonjs/core/Misc/assetsManager.js'
import { Vector3 } from '@babylonjs/core'

export class AssetLoadHelper {
    constructor(view, viewState, scene) {
        this.loadQueue = []
        this.loadCount = 0
        this.view = view
        this.viewState = viewState
        this.scene = scene
        this.batchSize = 16
        this.batchDelay = 0
        this.textures = {}
        this.timeout = null
        this.shouldExit = false
    }

    queueTexture(url, name, high, backup, index) {
        this.loadQueue.push({ url, name, high, backup, index })
    }

    loadSuccess(tex, { high, backup, index }) {
        this.textures[index] = tex
        /*if (backup) {
            this.texturesB[index] = tex
        }
        if (high || !backup) {
            this.textures[index] = tex
        }*/

        // if (index == view.defaultIndex) {
        // updateRotation()
        // }
    }

    run(onUpdate) {
        return new Promise((resolve, reject) => {
            try {
                var loader = new AssetsManager(this.scene)
                loader.useDefaultLoadingScreen = false

                const loadBatch = () => {
                    if (this.loadQueue.length > 0) {
                        var limit = Math.min(this.batchSize, this.loadQueue.length)
                        for (let i = 0; i < limit; i += 1) {
                            let tex = this.loadQueue.shift()
                            let img = loader.addTextureTask(tex.name, tex.url)
                            img.onSuccess = (t) => {
                                this.loadSuccess(t, tex)
                            }
                        }
                        loader.load()

                    } else {
                        // reject("TEST")
                        resolve({ textures: this.textures })
                    }
                }

                loader.onFinish = (x) => {
                    if (this.shouldExit) {
                        resolve({ textures: this.textures })
                        return
                    }
                    setTimeout(() => {
                        loadBatch()
                    }, this.batchDelay)
                    // Check progress
                    var total = this.viewState.totalImages
                    var current = 0
                    for (let i = 0; i < this.viewState.totalImages; i++) {
                        if (i in this.textures) {
                            current += 1
                        }
                    }
                    onUpdate(current / total)
                    // updateLoadProgress(current / total)
                }

                loadBatch()
            } catch (e) {
                reject(e)
            }
        })
    }

    quit() {
        this.shouldExit = true
    }
}

export function registerMeshInput(mesh, over, out) {
    const registeredMeshActions: Nullable<IAction>[] = []
    if (!mesh.actionManager) {
        mesh.actionManager = new ActionManager(mesh.getScene())
    }

    const onPointerOverAction: Nullable<IAction> = mesh.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, (ev: any) => {
            over && (over as MeshEventType)(ev)
            // setValue(true)
        })
    )

    const onPointerOutAction: Nullable<IAction> = mesh.actionManager.registerAction(
        new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, (ev: any) => {
            out && (out as MeshEventType)(ev)
            // setValue(false)
        })
    )

    registeredMeshActions.push(onPointerOverAction)
    registeredMeshActions.push(onPointerOutAction)

    if (registeredMeshActions.length > 0) {
        return () => {
            registeredMeshActions.forEach((action: Nullable<IAction>) => {
                if (action !== null) {
                    mesh.actionManager?.unregisterAction(action)
                }
            })
            registeredMeshActions.splice(0, registeredMeshActions.length)
        }
    }
    return null
}

export function getElementIcon(element, mesh, media) {
    // Custom icon defined
    if (element.iconDiffuseMediaId) {
        const bounds = mesh.getBoundingInfo().boundingBox
        return {
            diffuse: media[element.iconDiffuseMediaId]?.link,
            opacity: media[element.iconOpacityMediaId]?.link,
            size: element.iconSize,
            offset: element.iconOffset,
            placement: element.iconPlacement,
            bounds,
            position: element.position ? new Vector3(element.position[0], element.position[1], element.position[2]) : new Vector3(bounds.centerWorld.x, bounds.centerWorld.y, bounds.centerWorld.z),
        }
    }
}
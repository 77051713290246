import React, { RefObject }  from 'react'

interface TopBarProps {
    id?: string,
    compact: boolean,
    children: JSX.Element[],
    className:string,
    style: {
        [key:string]: string | number
    },
    forwardRef: RefObject,
    sticky: boolean,
}

export function TopBar(props:TopBarProps) {
    const {
        id,
        children,
        compact,
        className,
        style,
        sticky,
        forwardRef,
    } = props

    const attrs = `${className ? ` ${className}` : ''}${compact ? ' compact' : ''}${sticky ? ' sticky' : ''}`
    return <div id={id} className={`topbar${attrs}`} style={style} ref={forwardRef}>
        <div className="topbar-wrapper">
            {children}
        </div>
    </div>
}
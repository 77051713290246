import React, { useEffect, useState, useRef } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { Media, Spinner, Button, OptionBar, Input, IconButton, Toggle, Checkbox } from 'components'
import PinchZoomPan from 'components/PinchZoomPan/PinchZoomPan'
import { navigateAsync, showPrompt, toggleShowSitemap } from 'actions/appActions'
import { CustomEventType, PageType, PromptType, ScreenOrientation } from 'app/types'
import { AdminTray } from 'views/AdminPage/AdminTray'
import { SitemapUnit } from './SitemapUnit'
import { getSetCached } from 'app/state'
import * as fnc from 'helpers/fnc'

enum SitemapEditMode {
    Info = 'info',
    Units = 'units'
}

interface SitemapProps {
    app: AppData,
    dataLink: string,
    onFocus: () => void,
    onChange: () => void,
    onSubmit: () => void,
    onDelete: () => void,
    onToggle: () => void,
    focusUnit: string,
    availability: boolean,
    editing: boolean,
    splitIndex: number,
    filteredUnits: Dict,
}

const UNIT_PAGE_LIMIT = 24

export function SitemapView(props: SitemapProps) {
    const { app, data, dataLink, onFocus, onChange, onSubmit, onDelete, focusUnit, availability, editing, splitIndex, filteredUnits, onToggle } = props
    const [loading, setLoading] = useState(0)
    const media = useAppSelector((state: RootState) => state.app.media)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const dispatch = useAppDispatch()
    const [sitemap, setSitemap] = useState(null)
    const [initialized, setInitialized] = useState(false)
    const [usePlacementWizard, setUsePlacementWizard] = useState(false)
    const [wizardUnit, setWizardUnit] = useState(false)
    const [unitMap, setUnitMap] = useState(false)
    const [editMode, setEditMode] = useState(null)
    const [hidePlaced, setHidePlaced] = useState(null)
    const [unitPage, setUnitPage] = useState(0)
    const editorRef = useRef()
    const editorUnitRef = useRef()
    const sitemapRef = useRef()
    const imageRef = useRef()
    const [sitemapToggleAttention, setSitemapToggleAttention] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setSitemapToggleAttention(false)
        }, 5000)
    }, [])

    useEffect(() => {
        if (!sitemap || !filteredUnits) {
            return
        }
        const bounds = { minX: 100000, minY: 10000, maxX: 0, maxY: 0 }
        const unitMap = sitemap.units.reduce((acc, x) => ({ ...acc, [x.unitId]: x }), {})
        sitemap.units.forEach((unit) => {
            const unitData = app.maps.unit[unit.unitId]
            if (!unitData || (filteredUnits && !(unitData.name in filteredUnits)) || focusUnit && focusUnit != unitData.name) {
                return
            }
            let [x, y] = unitMap[unitData.id].position.split(',').map((x) => parseFloat(x) / 100)
            bounds.minX = Math.min(bounds.minX, x)
            bounds.minY = Math.min(bounds.minY, y)
            bounds.maxX = Math.max(bounds.maxX, x)
            bounds.maxY = Math.max(bounds.maxY, y)

        })
        if (Object.keys(filteredUnits).length == sitemap.units.length) {
            window.dispatchEvent(new CustomEvent(CustomEventType.ZoomOut, { detail: bounds }))
        } else if (bounds.maxX != bounds.minX || bounds.maxY != bounds.minY) {
            window.dispatchEvent(new CustomEvent(CustomEventType.ZoomIn, { detail: { center: bounds } }))
        } else {
            window.dispatchEvent(new CustomEvent(CustomEventType.ZoomIn, { detail: { center: { x: bounds.minX, y: bounds.minY } } }))
        }
    }, [sitemap, focusUnit, filteredUnits])

    useEffect(() => {
        getSetCached('sitemap-hide-placed', hidePlaced, (x) => setHidePlaced(x == 'true'), true)
    }, [hidePlaced])

    useEffect(() => {
        getSetCached('sitemap-edit-mode', editMode, setEditMode, SitemapEditMode.Info)
    }, [editMode])

    useEffect(() => {
        if (dataLink && !data) {
            setSitemap(app.sitemaps.find((x) => x.link == dataLink))
        }
        setInitialized(true)
    }, [dataLink])

    useEffect(() => {
        if (data && !dataLink) {
            if(sitemap && sitemap.id != data.id) {
                setLoading(0)
            }
            setSitemap(data)
        }
    }, [data])

    useEffect(() => {
        if (!sitemap) {
            return
        }

        const unitMap = sitemap.units.reduce((acc, x) => ({ ...acc, [x.unitId]: x }), {})
        setUnitMap(unitMap)

    }, [sitemap, sitemap ? sitemap.units : null])

    function getNextUnplaced() {
        let unplaced = null
        if (wizardUnit) {
            unplaced = app.units.find((x) => x.id != wizardUnit && !(x.id in unitMap))
        } else {
            unplaced = app.units.find((x) => !(x.id in unitMap))
        }
        return unplaced ? unplaced.id : null
    }

    function handleLoaded() {
        setLoading(1)
        setTimeout(() => {
            setLoading(2)
        }, 250)
    }

    function getLegend() {
        return <div className="sitemap-legend noselect">
            {Object.keys(app.meta.stateMap).map((x, ix) => {
                const state = app.meta.stateMap[x]
                return <div className="sitemap-legend-entry" key={ix}>
                    <span style={{ backgroundColor: `#${state.color}` }} />
                    <span>{state.name}</span>
                </div>
            })}
        </div>
    }

    function handleUnfocus(e) {
        if (onFocus) {
            onFocus(null, e)
        }
    }

    function handleUnit(e, unit) {
        if (editing) {
            return
        }
        if (e) {
            e.stopPropagation()
        }
        if (unit && unit.name == focusUnit) {
            handleUnit(e, null)
            return
        }
        if (onFocus) {
            onFocus(unit ? unit.name : null, e)
        }
        // If from outside current filter set, go back to floorplan selection
        if (unit && unit.name && (filteredUnits && !(unit.name in filteredUnits))) {
            dispatch(navigateAsync({ app: app, pageType: PageType.Sitemap }))
        }
        /*if (splitIndex != null) {
            if (unit) {
                const fp = app.maps.floorplan[unit.floorplans[0]]
                dispatch(navigateAsync({ app: app, pageType: PageType.Sitemap, options: { floorplanId: fp.id } }))
            } else {
                dispatch(navigateAsync({ app: app, pageType: PageType.Sitemap }))
            }
        }*/
    }

    function handleMediaSelect() {
        dispatch(showPrompt({ type: PromptType.Media, app, multi: false, selected: sitemap.mediaId }))
            .then((x) => {
                if (x.payload && Array.isArray(x.payload) && x.payload.length > 0) {
                    const newSitemap = { ...sitemap }
                    newSitemap.mediaId = x.payload[0]
                    if (onChange) {
                        onChange(newSitemap)
                    }
                }
            })
    }

    function handleToggleSitemap() {
        dispatch(toggleShowSitemap())
    }

    function togglePlacementWizard() {
        if (!usePlacementWizard) {
            const firstUnplaced = getNextUnplaced()
            if (firstUnplaced) {
                setWizardUnit(firstUnplaced)
                setUsePlacementWizard(true)
            }
        } else {
            setWizardUnit(null)
            setUsePlacementWizard(false)
        }
    }

    function handlePlace(unit, x, y) {
        if (!sitemap || !sitemapRef.current) {
            return
        }
        const newSitemap = { ...sitemap, units: [...sitemap.units] }

        const newUnit = { sitemapId: sitemap.id, unitId: unit.id, position: `${x},${y}`, size: 1 }
        const unitIdx = sitemap.units.findIndex((x) => x.unitId == unit.id)
        if (unitIdx === -1) {
            newSitemap.units.push(newUnit)
        } else {
            newSitemap.units[unitIdx] = { ...newSitemap.units[unitIdx], ...newUnit }
        }

        if (usePlacementWizard) {
            const next = getNextUnplaced()
            if (next) {
                setWizardUnit(next)
            } else {
                togglePlacementWizard()
            }
        }
        onChange(newSitemap)
        // setCurrentSitemap(newSitemap)
    }

    function handleDelete(unit) {
        const unitIdx = sitemap.units.findIndex((x) => x.unitId == unit.id)
        if (unitIdx != -1) {
            const newSitemap = { ...sitemap, units: [...sitemap.units] }
            newSitemap.units.splice(unitIdx, 1)
            onChange(newSitemap)
        }
    }

    function handleResetUnits() {
        const newSitemap = { ...sitemap }
        newSitemap.units = []
        onChange(newSitemap)
    }

    function handleField(field, value) {
        if(field == 'order') {
            value = parseInt(value)
        }
        if (sitemap[field] != value) {
            const newSitemap = { ...sitemap, [field]: value }
            onChange(newSitemap)
        }
    }

    function handleName(x) {
        if (x != sitemap.name) {
            const newSitemap = { ...sitemap, name: x }
            onChange(newSitemap)
        }
    }

    function handleLink(x) {
        if (x != sitemap.link) {
            const newSitemap = { ...sitemap, link: x }
            onChange(newSitemap)
        }
    }

    function handleSize(x) {
        let newSize = fnc.clamp(parseInt(x), 50, 500)
        if (newSize != null && newSize != sitemap.iconSize) {
            const newSitemap = { ...sitemap, iconSize: newSize }
            onChange(newSitemap)
        }
    }
    function handleZoom(x) {
        let newZoom = fnc.clamp(parseFloat(x), 1, 10)
        if (newZoom != null && newZoom != sitemap.maxZoom) {
            const newSitemap = { ...sitemap, maxZoom: newZoom }
            onChange(newSitemap)
        }
    }

    function handleUnitStatus(status) {
        const newSitemap = { ...sitemap, showUnitStatus: status }
        onChange(newSitemap)
    }

    function getEditor() {
        if (!sitemap) {
            return null
        }
        const unitMap = sitemap.units.reduce((acc, x) => ({ ...acc, [x.unitId]: x }), {})

        const style = {}
        if (editorUnitRef.current) {
            style.maxWidth = editorUnitRef.current.clientWidth * 3
            style.minWidth = '250px'
        }

        let units = []
        if (editMode == SitemapEditMode.Units) {
            if (hidePlaced) {
                units = app.units.filter((x) => !(x.id in unitMap))
            } else {
                units = app.units
            }
        }

        let pages = Math.ceil(units.length / UNIT_PAGE_LIMIT)
        let currentPage = Math.max(Math.min(unitPage, pages - 1), 0)
        units = units.slice(currentPage * UNIT_PAGE_LIMIT, (currentPage + 1) * UNIT_PAGE_LIMIT)

        // Get list of units, cross reference with sitemap units
        return <div className="sidebar-editor sitemap-editor fadeIn" style={style}>
            <h4 className="noselect">{sitemap.name}</h4>
            <Toggle value={editMode} onChange={setEditMode} items={Object.keys(SitemapEditMode).map((x) => ({ value: SitemapEditMode[x], text: x }))} />
            {editMode == SitemapEditMode.Info && <React.Fragment>
                <div className="sidebar-editor-table">
                    <table>
                        <tbody>
                            {/*<tr>
                                <td>Name: </td>
                                <td><Input onChange={handleName} value={sitemap.name} /></td>
                            </tr>
                            <tr>
                                <td>Link: </td>
                                <td><Input onChange={handleLink} value={sitemap.link} /></td>
                            </tr>*/}
                            <tr>
                                <td>Size: </td>
                                <td><Input onSubmit={handleSize} value={sitemap.iconSize} number /></td>
                            </tr>
                            <tr>
                                <td>Zoom: </td>
                                <td><Input onSubmit={handleZoom} value={sitemap.maxZoom} number /></td>
                            </tr>
                            <tr>
                                <td>Show Unit Status: </td>
                                <td><Checkbox onChange={handleUnitStatus} value={sitemap.showUnitStatus} /></td>
                            </tr>
                            <tr>
                                <td>Order: </td>
                                <td><Input number onChange={(x) => handleField('order', x)} value={sitemap.order} /></td>
                            </tr>
                            <tr>
                                <td>Is Default: </td>
                                <td><Checkbox onChange={(x) => handleField('isDefault', x)} value={sitemap.isDefault} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* <h4>Image</h4> */}
                <Media key={sitemap.mediaId} className="sitemap-image" onClick={handleMediaSelect} app={app} mediaId={sitemap.mediaId} thumb thumbSize={256} />
                {/* {sitemap.id != null && <Button onClick={onDelete} icon="fas fa-trash" style={{ marginTop: 'auto' }}>Delete Sitemap</Button>} */}
            </React.Fragment>}
            {editMode == SitemapEditMode.Units && <React.Fragment>
                {/* <h4 className="noselect">Units</h4> */}
                <div className="sitemap-editor-units row" ref={editorRef}>
                    {units.length == 0 && <h5>All Units Placed</h5>}
                    {units.length > 0 && units.map((x, ix) => {
                        const color = x.availabilityStateId ? app.meta.stateMap[x.availabilityStateId].color : null
                        return <SitemapUnit
                            key={ix}
                            inTray={true}
                            forceDrag={usePlacementWizard && wizardUnit == x.id}
                            editing={true}
                            unit={unitMap[x.id]}
                            color={color}
                            data={x}
                            placed={x.id in unitMap}
                            parentRef={editorRef}
                            sitemapRef={sitemapRef}
                            forwardRef={ix == 0 ? editorUnitRef : null}
                            onPlace={handlePlace}
                            size={sitemap.iconSize} />
                    })}
                </div>
                {pages > 1 && <div className="button-group">
                    <IconButton className={`${currentPage == 0 ? 'disabled' : ''}`} icon="fa fa-chevron-left" onClick={() => setUnitPage(unitPage - 1)} />
                    <span> {currentPage + 1} / {pages} </span>
                    <IconButton className={`${currentPage == pages.length - 1 ? 'disabled' : ''}`} icon="fa fa-chevron-right" onClick={() => setUnitPage(unitPage + 1)} />
                </div>}

                <Button onClick={togglePlacementWizard}>{usePlacementWizard ? 'End Wizard' : 'Start Wizard'}</Button>
                <Button onClick={handleResetUnits}>Reset Units</Button>
                <Checkbox value={hidePlaced} onChange={setHidePlaced}>Hide Placed Units</Checkbox>
            </React.Fragment>}
        </div>
    }

    function getOptions() {
        if (editing || splitIndex == null) {
            return null
        }
        return null
        return <OptionBar
            app={app}
            splitIndex={-1}
            compare={false} />
        // rightOptions={<Icon icon="fas fa-expand" onClick={handleToggleSitemap}>Hide Sitemap</Button>} />
    }

    if (sitemap) {
        if (sitemap.mediaId in media) {
            let sitemapElement = null
            let isBasic = false
            // TODO: fix. For some reason portrait on mobile crashes the pinch zoom pan, so use static instead
            if (false && screen.isMobile && screen.orientation == ScreenOrientation.Portrait) {
                isBasic = true
                const { width, height } = media[sitemap.mediaId]
                let style = null
                if (sitemapRef.current && sitemapRef.current.offsetWidth > 0) {
                    let scale = Math.min(sitemapRef.current.offsetWidth / width, sitemapRef.current.offsetHeight / height)
                    style = { width: `${width * scale}px`, height: `${height * scale}px` }
                }
                sitemapElement = <div className="sitemap" ref={sitemapRef} style={style}>
                    <Media key={sitemap.mediaId} app={app} mediaId={sitemap.mediaId} onLoad={handleLoaded} ref={imageRef} style={{ aspectRatio: width / height }} />
                    {sitemap.units && sitemap.units.map((unit) => {
                        const unitData = app.maps.unit[unit.unitId]
                        const isFocused = focusUnit && unitData.name == focusUnit
                        const color = unitData.availabilityStateId ? app.meta.stateMap[unitData.availabilityStateId].color : null
                        const filtered = filteredUnits && Object.keys(filteredUnits).length != sitemap.units.length && unitData.name in filteredUnits
                        const show = (!focusUnit || isFocused || filtered) && (availability || editing || filteredUnits && unitData.name in filteredUnits)

                        return <SitemapUnit
                            className={!show ? 'disabled' : (filtered ? 'filtered' : '')}
                            key={unitData.id}
                            editing={editing}
                            sitemapRef={sitemapRef}
                            unit={unit}
                            data={unitData}
                            focused={isFocused}
                            color={color}
                            showColor={sitemap.showUnitStatus}
                            onClick={editing ? null : (e) => handleUnit(e, unitData)}
                            onPlace={handlePlace}
                            onDelete={handleDelete}
                            size={sitemap.iconSize} />
                    })}
                </div>
            } else {
                sitemapElement = <PinchZoomPan
                    key={sitemap.id}
                    options={[]}
                    position='center'
                    maxZoom={sitemap.maxZoom * (screen.isMobile ? 2 : 1)}
                    autoFitBuffer={0}
                    onClick={handleUnfocus}
                    zoomButtons={true}
                    cover={false}
                    tapToZoom={false}
                    forceZoomCenter={true}
                    // zoomBuffer={(screen.isMobile ? 100 : 400) * (focusUnit ? 0.5: 1)}>
                    zoomBuffer={(screen.isMobile ? 100 : 300)}>
                    <div className="sitemap" ref={sitemapRef}>
                        <Media key={sitemap.mediaId} app={app} mediaId={sitemap.mediaId} onLoad={handleLoaded} ref={imageRef} />
                        {sitemap.units && sitemap.units.map((unit) => {
                            const unitData = app.maps.unit[unit.unitId]
                            const isFocused = focusUnit && unitData.name == focusUnit
                            const color = unitData.availabilityStateId ? app.meta.stateMap[unitData.availabilityStateId].color : null
                            const filtered = filteredUnits && filteredUnits.size != sitemap.units.length && unitData.name in filteredUnits
                            const highlighted = availability || (filtered && filteredUnits[unitData.name].highlight)
                            const show = (!focusUnit || isFocused || filtered) && (availability || editing || (filteredUnits && unitData.name in filteredUnits) || filtered)

                            if (!editing && !availability && ((!show && !filtered) || !highlighted)) {
                                return null
                            }

                            return <SitemapUnit
                                // className={(!show && !filtered) ? 'disabled' : (filtered ? 'filtered' : '')}
                                className={`animate__animated animate__zoomIn animate__fastest filtered-unit${highlighted ? ' highlighted' : ''}`}
                                key={`${unitData.id}_${unitData.availabilityStateId}`}
                                editing={editing}
                                sitemapRef={sitemapRef}
                                unit={unit}
                                data={unitData}
                                focused={isFocused}
                                color={color}
                                sitemap={sitemap}
                                showColor={sitemap.showUnitStatus}
                                onClick={editing ? null : (e) => handleUnit(e, unitData)}
                                onPlace={handlePlace}
                                onDelete={handleDelete}
                                app={app}
                                size={sitemap.iconSize} />
                        })}
                    </div>
                </PinchZoomPan>
            }

            return <React.Fragment>
                {getOptions()}
                <div id="sitemap-view" className={editing ? 'admin-content' : ''} style={isBasic ? { justifyContent: 'center' } : null}>
                    {onToggle && app.sitemaps.length > 1 && <div className="top-center">
                        <Toggle
                            items={[...app.sitemaps].sort((a, b) => a.order - b.order).map((x) => ({ value: x.id, text: x.name }))}
                            value={sitemap.id}
                            arrowFocus={sitemapToggleAttention}
                            onChange={onToggle} />
                    </div>}
                    {(sitemap.showUnitStatus || availability) && getLegend()}
                    {loading < 2 && <Spinner overlay show={loading == 0} showAfter={500} quotesAfter={500} />}
                    {sitemapElement}
                    {editing && onSubmit && <AdminTray message="Save Changes" onSave={() => onSubmit(sitemap)} />}
                    {editing && getEditor()}
                </div>
            </React.Fragment>
        } else if (editing) {
            return <div id="sitemap-view" className={editing ? 'admin-content admin-message' : ''}>
                <h3>Start by selecting sitemap image</h3>
                <Button alt onClick={handleMediaSelect}>Select Sitemap Image</Button>
            </div>
        }
    }
    if (initialized && dataLink) {
        return <div id="sitemap-view">
            <h1>{`Couldn't find sitemap ${dataLink}`}</h1>
        </div>
    }
    return <Spinner showAfter={0} />
}
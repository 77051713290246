import React, { useEffect, useState, useRef } from 'react'
import { MediaData, MediaType } from "app/types"
import { Icon } from "components"

interface MediaIconProps {
    media: MediaData
    type: MediaType
    show: boolean,
}

export function MediaIcon(props: MediaIconProps) {
    const { media, type, show } = { show: true, ...props }
    if (!media && !type) return null
    let icon = ''
    switch (media ? media.mediaTypeId : type) {
        case MediaType.Image:
            return null
        case MediaType.Video:
            icon = 'fas fa-play'
            break
        case MediaType.Tour:
            icon = 'fas fa-walking'
            break
        case MediaType.Spin:
            icon = 'fas fa-cube'
            break
    }
    if (!show) {
        return
    }
    return <div className="media-icon animate__animated animate__zoomIn" onMouseDown={e => e.stopPropagation()}>
        <Icon icon={icon} noBg />
    </div>
}
import React from 'react'
import { getAppPath, getAppUrl } from "helpers/config"
import { Media } from 'components'
import { useAppSelector } from "app/hooks"
import { RootState } from "app/types"

interface WaterMarkProps {
    notext: boolean,
}

export function WaterMark(props: WaterMarkProps) {
    const { notext } = props
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const config = useAppSelector((state: RootState) => state.app.config)
    if (organization && organization.link != 'homegyde') {
        const homegyde = config.organizations.find((x) => x.link == 'homegyde')
        if (notext && homegyde.logoNotextMediaId) {
            return <a href={`${getAppUrl()}`} className="watermark disabled"><Media organization={homegyde} mediaId={homegyde.logoNotextMediaId} /></a>
        }
        return <a href={`${getAppUrl()}`} className="watermark disabled"><Media organization={homegyde} mediaId={homegyde.logoMediaId} /></a>
    }
    return null
}
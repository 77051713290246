import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
    Media,
    IconButton,
    Icon,
    MediaIcon,
} from 'components'
import {
    RootState,
    ListStyle,
    Config,
    CustomEventType,
    PromptType,
    MarkerType,
    Location,
    ThumbSize,
    AppMetaData,
    MediaType,
    ScreenSize,
    ScreenOrientation,
    CustomContentType,
} from 'app/types'
import {
    showPrompt,
    initializeProject,
} from 'actions/appActions'
import { logger } from 'helpers/logger'
import { LocationLink } from '../HomePage/LocationLink'
import { ProjectDetails } from '../HomePage/ProjectDetails'
import { useAppDispatch, useAppSelector, useExclusiveMediaPermissions, useHandleExclusive, usePreviewMedia } from 'app/hooks'


export interface ContentGalleryProps {
    app: AppData,
    className,
    visible: number,
    link: string
    splitIndex: number,
    format: number,
}
export function ContentGallery(props: ContentGalleryProps) {
    const { app, className, link, splitIndex, format } = { format: 0, ...props }
    const [galleryIndex, setGalleryIndex] = useState(0)
    const allMedia = useAppSelector((state: RootState) => state.app.media)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const [visible, setVisible] = useState(0)
    const [visibleMedia, setVisibleMedia] = useState([])
    const [availableMedia, setAvailableMedia] = useState([])
    const [tileStyle, setTileStyle] = useState({} as React.CSSProperties)
    const previewMedia = usePreviewMedia()
    const exclusiveMediaPermissions = useExclusiveMediaPermissions()
    const handleExclusive = useHandleExclusive()

    const mobilePortrait = screen.isMobile && screen.size == ScreenSize.Mobile && screen.orientation == ScreenOrientation.Portrait
    const dispatch = useAppDispatch()

    useEffect(() => {
        let newVisible = 0
        if (props.visible) {
            newVisible = props.visible
        } else if (format === 0) {
            if (screen.isMobile && screen.size > ScreenSize.Desktop && screen.orientation === ScreenOrientation.Portrait) {
                newVisible = (splitIndex == null) ? 3 : 1
            } else {
                newVisible = (splitIndex == null) ? 5 : 3
            }
        } else {
            if (screen.isMobile && screen.size > ScreenSize.Desktop && screen.orientation === ScreenOrientation.Portrait) {
                newVisible = (splitIndex == null) ? 2 : 1
            } else {
                newVisible = (splitIndex == null) ? 4 : 2
            }
        }

        // First the "preview" gallery
        let gallery = app.galleries.find((x) => {
            if (link) {
                return x.link == link
            }
            return x.link == 'preview'
        })
        // Then the default gallery
        if (gallery == null) {
            gallery = app.galleries.find((x) => x.link == 'gallery')
        }
        // Then whichever
        if (gallery == null) {
            gallery = app.galleries[0]
        }
        let media = [...gallery.media]
        let newAvailableMedia = media.map((x) => {
            const m = allMedia[x.mediaId]
            if (!m) {
                logger.error('Media not found', x)
                return null
            }
            return { media: m }
        }).filter((x) => x != null)


        newVisible = Math.min(newAvailableMedia.length, newVisible)

        let mediaSize = '100%'
        let mediaAspect = '50%'
        if (newVisible > 1 && (newVisible == 5 || (newVisible % 3) === 0)) {
            mediaSize = '33.33%'
            mediaAspect = '33.33%'
        }
        const newTileStyle = { minWidth: mediaSize } ///, paddingBottom: mediaAspect }
        setTileStyle(newTileStyle)
        setAvailableMedia(newAvailableMedia)
        setVisible(newVisible)
    }, [splitIndex, props.visible, screen.size, screen.orientation])

    useEffect(() => {
        if (availableMedia.length == 0) {
            return
        }
        let newVisibleMedia = []
        if (visible === 1) {
            const prevIndex = galleryIndex > 0 ? galleryIndex - 1 : limit
            const nextIndex = galleryIndex < limit ? galleryIndex + 1 : 0

            newVisibleMedia.push({ ...availableMedia[prevIndex], visible: false })
            newVisibleMedia.push({ ...availableMedia[galleryIndex], visible: true })
            newVisibleMedia.push({ ...availableMedia[nextIndex], visible: false })
        } else {
            newVisibleMedia = availableMedia.slice(galleryIndex * visible, Math.min((galleryIndex + 1) * visible, availableMedia.length)).map((x) => {
                return ({ ...x, visible: true })
            })
        }
        setVisibleMedia(newVisibleMedia)
    }, [availableMedia, visible, galleryIndex])

    if (!app || app.galleries.length == 0 || visible == 0) {
        return null
    }

    function handleGalleryClick(e, x) {
        e.stopPropagation()
        // Check if there is a permissions issue
        if (!exclusiveMediaPermissions(app, x)) {
            handleExclusive()
            return
        }
        if (false && x.mediaTypeId != MediaType.Image) {
            let media = x.id
            dispatch(showPrompt({ type: PromptType.Lightbox, app: app, media: media, mediaOptions: { autoplay: x.id } }))
        } else {
            const images = availableMedia.map((x) => x.media)
            dispatch(showPrompt({ type: PromptType.Lightbox, app, media: images.map((x) => x.id), startIndex: images.findIndex((y) => y.id == x.id), mediaOptions: { autoplay: x.id } }))
        }
    }


    if (availableMedia.length == 1 && visibleMedia.length > 0) {
        return <div className='row content-slideshow' data-type='header'>
            <div className="slideshow-wrapper">
                <Media app={app} organization={!app ? organization : null} mediaId={availableMedia[0].media.id} fadeIn />
            </div>
        </div>
    }

    const getTileStyle = (ix) => {
        let style = { ...tileStyle }
        let size = ThumbSize.Large
        if (format == 0) {
            if (visibleMedia.length == 5 && visibleMedia.length <= visible) {
                if (ix == 0) {
                    style = { minWidth: '50%', height: '100%' }
                    size = ThumbSize.ExtraLarge
                } else {
                    style = { minWidth: '25%', height: '50%' }
                }
            } else if (visibleMedia.length == 4 && visibleMedia.length <= visible) {
                style = { minWidth: '50%', height: '50%' }
            } else if (visibleMedia.length == 3 && visibleMedia.length <= visible) {
                if (ix == 0) {
                    style = { minWidth: '66.66%', height: '100%' }
                } else {
                    style = { minWidth: '33.33%', height: '50%' }
                }
            } else if (visibleMedia.length == 2 && visibleMedia.length <= visible) {
                style = { minWidth: '50%', height: '100%' }
                size = ThumbSize.ExtraLarge
            } else if (visibleMedia.length == 1 && visibleMedia.length <= visible) {
                style = { minWidth: '100%', height: '100%' }
                size = ThumbSize.ExtraLarge
            }
        } else {
            style = { minWidth: `calc(100% / ${visibleMedia.length})`, height: '100%' }
        }
        return { style, size }
    }

    const limit = Math.ceil(availableMedia.length / visible) - 1
    return <div className="row content-gallery" >
        {limit > 0 && <IconButton className="prev" icon="fas fa-chevron-left" onClick={() => {
            if (galleryIndex == 0) {
                setGalleryIndex(limit)
            } else {
                setGalleryIndex(galleryIndex - 1)
            }
        }} />}
        {limit > 0 && <IconButton className="next" icon="fas fa-chevron-right" onClick={() => {
            if (galleryIndex < limit) {
                setGalleryIndex(galleryIndex + 1)
            } else {
                setGalleryIndex(0)
            }
        }} />}
        {limit > 0 && false && <div className="bottom-center">
            <span className="content-gallery-index">
                {galleryIndex + 1}/{limit + 1}
            </span>
        </div>}

        {visibleMedia.map((x, ix) => {
            if (!x || !x.media) {
                logger.error("OOPS", x)
                return
            }

            let { style, size } = getTileStyle(ix)
            if (!x.visible) {
                style.position = 'absolute'
                style.top = '100%'
            }
            return <div key={`${ix}_${x.mediaId}`} className="content-gallery-item" style={style} data-format={format}>
                {/* <MediaIcon media={x.media} /> */}
                <Media
                    mediaId={x.media.id}
                    fadeIn
                    thumb={true}
                    thumbSize={size}
                    app={app}
                    onClick={(e) => handleGalleryClick(e, x.media)} />
            </div>
        })}
    </div>
    {/* </div> */ }
}
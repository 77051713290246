import { OrganizationData, BuilderData, AppData } from 'app/types'
import { getAppPath } from './config'

export function getJWTKey() {
    return `JWT/${getAppPath()}`
}

export function authHeader(_token = null): string {
    const token = _token != null ? _token : localStorage.getItem(getJWTKey())
    if (token) {
        return { headers: { Authorization: `Bearer ${token}` } }
    }
    return ''
}

export function authQuery(): string {
    const token = localStorage.getItem(getJWTKey())
    if (token) {
        return `token=${token}`
    }
    return ''
}
export function validateEmail(email: string): string {
    if (!email || email.length == 0) {
        return 'Email is required'
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        return 'Invalid email address'
    }
    return null
}

export function validatePhone(phone: string, required = false): string {
    if (required && (!phone || phone.length == 0)) {
        return 'Phone number is required'
    } else if (phone != null && phone.length > 0 && phone.length < 10) {
        return 'Phone number is invalid (must be at least 10 digits)'
    }
    return null
}

export function validateUsername(username: string): string {
    if (!username) {
        return 'Username is required'
    }
    if (username.length < 2 || username.length > 50) {
        return 'Username must be between 2 to 50 chars'
    }
    return null
}

export function validatePassword(password: string): string {
    if (!password) {
        return 'Password is required'
    }
    if (password.length < 6) {
        return 'Password must have at least 8 chars'
    }
    return null
}

export function validatePasswordConfirm(password: string, passwordConfirm: string): string {
    if (!passwordConfirm) {
        return 'Confirmation password is required'
    }
    if (password !== passwordConfirm) {
        return 'Passwords must match'
    }
    return null
}

interface UserDef {
    name: string,
    eamil: string,
    password: string,
    passwordConfirm: string,
}
export function validateUser(user: UserDef, requirePassword = true): string[] {
    const {
        name,
        email,
        password,
        passwordConfirm,
    } = user

    const v1 = validateUsername(name)
    const v2 = validateEmail(email)
    let v3: string = null
    let v4: string = null

    if (requirePassword || password != null || passwordConfirm != null) {
        v3 = validatePassword(password)
        v4 = validatePasswordConfirm(password, passwordConfirm)
    }
    if (v1 == null && v2 == null && v3 == null && v4 == null) {
        return null
    }

    return [v1, v2, v3, v4].filter((x) => x != null)
}

export function validateLogin(user: UserDef): string[] {
    const {
        username,
        password,
    } = user

    const v1 = validateUsername(username)
    const v2 = validatePassword(password)
    if (v1 == null && v2 == null) {
        return null
    }
    return [v1, v2].filter((x) => x != null)
}

export function validateOrganization(organization: OrganizationData) {
    const ret = []
    if (organization.name.length < 2) {
        ret.push('Name must be between 2 to 50 characters')
    }
    if (organization.link.length < 2) {
        ret.push('Link must be between 2 to 50 characters')
    }
    return ret.length > 0 ? ret : null
}

export function validateBuilder(builder: BuilderData) {
    const ret = []
    if (builder.name.length < 2) {
        ret.push('Name must be between 2 to 50 characters')
    }
    if (builder.link.length < 2) {
        ret.push('Link must be between 2 to 50 characters')
    }
    return ret.length > 0 ? ret : null
}

export function validateApp(app: AppData) {
    const ret = []
    if (app.name.length < 2) {
        ret.push('Name must be between 2 to 50 characters')
    }
    if (app.link.length < 2) {
        ret.push('Link must be between 2 to 50 characters')
    }
    return ret.length > 0 ? ret : null
}
import React, { useEffect, useState, useRef } from 'react'

import { useScene } from 'react-babylonjs'
import { Matrix, Vector3, Color3, Animation } from '@babylonjs/core'
import { AssetsManager } from '@babylonjs/core/Misc/assetsManager.js'
import '@babylonjs/loaders/OBJ'
import { getMediaUrl, getSpinIcon, getSpinUrl } from 'helpers/config'
import { registerMeshInput } from './babylon'
import { logger } from 'helpers/logger'

interface SpinIconProps {
    app: AppData,
    id: string,
    position: Array,
    bounds: Dict,
    offset: Array,
    name: string,
    opacity: string,
    diffuse: string,
    size: number,
    scale: number,
    placement: string,
    onHover: onHover,
    hover: boolean,
}
const GROW_SCALE = 1.1
const GROW_SPEED = 50

export function SpinIcon(props: SpinIconProps) {
    const { app, id, position, offset, name, opacity, size, scale, onHover, hover, placement, diffuse, bounds } = { size: 2, ...props }
    const [plane, setPlane] = useState(null)
    const [opacityTexture, setOpacityTexture] = useState(null)
    const [diffuseTexture, setDiffuseTexture] = useState(null)
    const loader = useRef()
    const texturesAlt = useRef([])
    const scene = useScene()

    useEffect(() => {
        loadTextures()

        return () => {
            // Clear textures
            Object.keys(texturesAlt.current).forEach((x) => {
                texturesAlt.current[x].dispose()
            })
            texturesAlt.current = {}
        }
    }, [])

    useEffect(() => {
        if (!plane) {
            return
        }
        if (!plane.initialized) {
            plane.hover = false
            plane.id = id
            if (placement !== 'center') {
                plane.setPivotMatrix(Matrix.Translation(0, 0.5, 0))
            }
            plane.initialized = true
        }
        plane.isPickable = !hover
        plane.directHover = !hover

        const cleanup = registerMeshInput(plane,
            (_) => {
                hoverIcon(true, true)
            },
            (_) => {
                hoverIcon(false, true)
            })
        return () => {
            cleanup()
        }
    }, [plane, hover])

    useEffect(() => {
        if (!plane) {
            return
        }
        hoverIcon(hover)
    }, [hover])

    function hoverIcon(_hover, direct = false) {
        if (direct && !plane.directHover) {
            return
        }
        if (plane.hover != _hover) {
            plane.hover = _hover
            if (onHover && direct) {
                onHover(_hover)
            }

            // Animation
            const speed = 8
            const ratio = (1 - (size * GROW_SCALE - plane.scaling.x))
            const growAnim = new Animation('iconGrow', 'scaling', 30, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CYCLE)
            const growKeys = [
                {
                    frame: 0,
                    value: Vector3.One().scale(size),
                },
                {
                    frame: 50,
                    value: Vector3.One().scale(size * GROW_SCALE),
                },
            ]
            growAnim.setKeys(growKeys)
            plane.animations = [growAnim]
            if (!_hover) {
                scene.beginAnimation(plane, GROW_SPEED * ratio, 0, false, speed, null, null, true)
            } else {
                scene.beginAnimation(plane, GROW_SPEED * ratio, GROW_SPEED, false, speed, null, null, true)
            }

            // Grow icon
        }
    }

    function loadComplete(x) {
        if (x.length == 0 || x[0] == null) {
            logger.error('Failed to load texture', props)
            return
        }
        const tex = x[0].texture
        setOpacityTexture(tex)
        texturesAlt.current = x.map((y) => y.texture)

        if (x.length > 1) {
            setDiffuseTexture(x[1].texture)
        }
    }

    function loadTextures() {
        loader.current = new AssetsManager(scene)
        loader.current.useDefaultLoadingScreen = false
        loader.current.onFinish = loadComplete

        let url = null
        let iconName = null
        let path = getMediaUrl(app)
        if (opacity) {
            url = `${path}/${opacity}`
            loader.current.addTextureTask(opacity, url)
        }

        // If requires diffuse
        if (diffuse) {
            url = `${path}/${diffuse}`
            loader.current.addTextureTask(diffuse, url)
        }

        loader.current.load()
        return loader
    }

    if (!opacityTexture) {
        return null
    }

    let finalPosition = position.add(Vector3.Zero())
    if (bounds && placement != 'center') {
        finalPosition.addInPlace(new Vector3(0, (bounds.maximum.y - bounds.minimum.y) / 2 + size / 2))
    }
    if (offset) {
        finalPosition.addInPlace(new Vector3(offset[0], offset[1], offset[2]))
    }
    let { width, height } = opacityTexture.getSize()
    // Normalize to height
    width /= height
    height /= height

    return <React.Fragment>
        <plane
            name="plane"
            ref={setPlane}
            renderingGroupId={4}
            billboardMode={7} // BILLBOARDMODE_ALL
            isPickable={true}
            width={width}
            height={height}
            scaling={(new Vector3(1, 1, 1)).scale(size)}
            position={finalPosition.scale(1/*scale*/)} // position
        >
            <standardMaterial name='test' emissiveColor={new Color3(1, 1, 1)} specularColor={new Color3(0, 0, 0)} alpha={0.99} backFaceCulling={false}>
                <texture key={opacityTexture != null ? 0 : 1} fromInstance={opacityTexture} assignTo="opacityTexture" />
                {diffuseTexture && <texture key="diffuse" fromInstance={diffuseTexture} assignTo="diffuseTexture" />}
            </standardMaterial>
        </plane>
    </React.Fragment>
}

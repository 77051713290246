import React, { useState, useEffect, useRef } from 'react'
import { MediaData, Dict } from 'app/types'
import { Media } from 'components'
import * as fnc from 'helpers/fnc'

const CLICK_THRESHOLD = 10

interface TileProps extends React.FC {
    className?: string,
    media?: MediaData,
    mediaOptions?: Dict,
    title?: string,
    square?: boolean,
    aspect?: boolean,
    group?: boolean,
    subtile?: boolean,
    small?: boolean,
    fadeIn?: boolean,
    onClick?: () => void,
    onDrag: () => void,
    order: number,
    onReorder: () => void,
    forwardRef: React.RefObject,
    style: Dict,
}

export function DraggableTile(props: TileProps) {
    const {
        title,
        onClick,
        onDrag, 
        className,
        square,
        aspect,
        group,
        subtile,
        children,
        small,
        fadeIn,
        order,
        onReorder,
        forwardRef,
        style,
    } = props

    const [loaded, setLoaded] = useState(false)
    const [dragging, setDragging] = useState(false)
    const [drag, setDrag] = useState(null)
    const [dragStart, setDragStart] = useState(null)
    const [tileOffset, setTileOsset] = useState(null)
    const [dragOffset, setDragOffset] = useState(null)
    const [reordering, setReordering] = useState(false)
    const dragRef = useRef()

    useEffect(() => {
        if (dragging) {
            document.addEventListener('mousemove', handleMouseMove)
            document.addEventListener('mouseup', handleMouseUp)

            return () => {
                document.removeEventListener('mousemove', handleMouseMove)
                document.removeEventListener('mouseup', handleMouseUp)
            }
        }
    }, [dragging, dragRef, reordering])

    const delayTimeout = useRef()

    useEffect(() => {
        if (dragging) {
            calculateOffset()
        }
    }, [order])

    const attrs = `${className ? ` ${className}` : ''}${square ? ' square' : ''}${aspect ? ' aspect' : ''}${group ? ' group' : ''}${small ? ' small' : ''}`

    function calculateOffset(e = null, initial = false) {
        const rect = dragRef.current.getBoundingClientRect()
        const parentRect = dragRef.current.parentElement.getBoundingClientRect()
        setTileOsset({ x: rect.x - parentRect.x, y: rect.y - parentRect.y })

        if (initial && e) {
            setDragOffset({ x: e.pageX - rect.x, y: e.pageY - rect.y })
        }

        // Recalculate start
        if (dragging) {
            setDragStart({ x: rect.x + dragOffset.x, y: rect.y + dragOffset.y })

        }
    }

    function handleDrag(x) {
        setDragging(x)
        if (onDrag) {
            onDrag(x)
        }
    }

    function handleLoad() {
        setTimeout(() => setLoaded(true), 200)
    }

    function handleMouseDown(e) {
        e.stopPropagation()
        const { pageX, pageY } = e
        setDragStart({ x: pageX, y: pageY })
        setDrag({ x: pageX, y: pageY })
        calculateOffset({ pageX, pageY }, true)
        delayTimeout.current = setTimeout(() => {
            handleDrag(true)
        }, onClick ? 200 : null)
    }

    function handleMouseMove(e) {
        if (!dragging) {
            return
        }

        e.stopPropagation()
        const newDrag = { x: e.pageX, y: e.pageY }
        setDrag(newDrag)

        // See if we are over any other tiles
        if (!reordering) {
            const childNodes = dragRef.current.parentElement.childNodes
            for (let i = 0; i < childNodes.length; i += 1) {
                const x = childNodes[i]
                const otherOrder = x.getAttribute('data-order')
                if (otherOrder != null && otherOrder != order) {
                    const rect = x.getBoundingClientRect()
                    if (fnc.rectContains(rect, newDrag)) {
                        setReordering(true)
                        onReorder(otherOrder)
                        setTimeout(() => {
                            setReordering(false)
                        }, 100)
                        break
                    }
                }
            }
        }
    }

    function handleMouseUp(e) {
        if (delayTimeout.current) {
            clearTimeout(delayTimeout.current)
        }
        if (!dragStart) {
            return
        }
        let dragX = e.pageX - dragStart.x
        let dragY = e.pageY - dragStart.y
        if (onClick && Math.abs(dragX) + Math.abs(dragX) < CLICK_THRESHOLD) {
            onClick(e)
        } else {
            e.stopPropagation()
        }
        handleDrag(false)
        setDrag(null)
    }

    function handleRef(x) {
        dragRef.current = x
        if (forwardRef) {
            forwardRef.current = x
        }
    }

    /*function handleClick(e) {
        if (!dragging && onClick) {
            onClick(e)
        }
    }*/
    const finalClass = `tile draggable show${attrs}${fadeIn ? ' fadeIn' : ''}`
    const styleA = { ...style }
    const styleB = {}
    let dragElem = null
    if (dragging) {
        const rect = dragRef.current.getBoundingClientRect()
        const parentRect = dragRef.current.parentElement.getBoundingClientRect()
        styleB.position = 'absolute'
        styleB.left = `${tileOffset.x + drag.x - dragStart.x}px`
        styleB.top = `${tileOffset.y + drag.y - dragStart.y}px`
        styleB.maxWidth = rect.width
        styleB.maxHeight = rect.height
        styleB.minWidth = rect.width
        styleB.minHeight = 0
        styleB.paddingBottom = rect.height
        styleA.opacity = 0.5
        const finalClassB = `tile draggable show${attrs}`

        dragElem = <div className={`${finalClassB}${dragging ? ' dragging' : ''}`}
            style={styleB}>
            {children}
        </div>
    }

    return <React.Fragment>
        {dragElem}
        <div className={finalClass}
            data-order={order}
            ref={handleRef}
            style={styleA}
            onTouchStart={handleMouseDown}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}>
            {children}
        </div>
    </React.Fragment>
}
import { CustomEventType, SpinQuality } from 'app/types'
import * as fnc from 'helpers/fnc'
import { Vector3 } from '@babylonjs/core'
const SPIN_INTERVAL = 1000 / 30.0
const SPIN_DAMPENING = 2
const SPIN_LIMIT = 100
const SPIN_SCALE = 0.5
const SPIN_STOP_THRESHOLD = 5
const SPIN_ROTATION_RATE = 0.1

export class SpinControl {
    constructor() {
        this.dragStart = 0
        this.dragPrev = 0
        this.dragTotal = 0
        this.timePrev = Date.now()
        this.dragging = false
        this.rotation = 0
        this.rotationPrev = 0
        this.spinSpeed = 0
        this.spinDir = 0
        this.spinInterval = null
        this.onUpdate = null
        this.onPick = null
        this.view = null
        this.viewState = null
        this.animating = false
        // this.handleMove.bind(this)
        // this.handleUp.bind(this)
        // this.handleDown.bind(this)
        // this.stopSpin.bind(this)
        this.handleRotateTo = this.handleRotateTo.bind(this)
    }

    registerEvents() {
        window.addEventListener(CustomEventType.RotateTo, this.handleRotateTo)
    }

    unregisterEvents() {
        window.removeEventListener(CustomEventType.RotateTo, this.handleRotateTo)
    }

    handleRotateTo(e) {
        if (!e) {
            return
        }

        // Calculate angle to center
        const viewCenter = new Vector3(this.view.position[0], this.view.position[1], this.view.position[2])
        const dif = e.detail.position.subtract(viewCenter)
        let angle = 0
        if(this.view.reverse) {
            angle = -Math.atan2(dif.z, -dif.x) - Math.PI / 2
        } else {
            angle = -Math.atan2(-dif.z, dif.x) - Math.PI / 2
        }
        const newRotation = (100 * angle / (Math.PI * 2))        // Make sure the difference is big enough
        let rotDif = this.rotation - newRotation
        rotDif = (rotDif + 50) % 100 - 50

        // return
        if (Math.abs(rotDif) > 25) {
            this.animating = true
            fnc.animateValue(this.rotation, newRotation, 500, (x) => {
                this.rotation = x
                if (this.onUpdate) {
                    this.onUpdate(Date.now())
                }
            }).then((x) => {
                this.animating = false
            })

        }
    }

    setView(view, viewState, onUpdate, onPick) {
        this.onUpdate = onUpdate
        this.onPick = onPick

        // Only reset rotation on change
        if (view && !(this.view && view && this.view.id == view.id)) {
            this.rotation = viewState.rotation
        }
        this.view = view
        this.viewState = viewState

        this.unregisterEvents()
        this.registerEvents()
    }

    resetRotation() {
        this.rotation = this.viewState.rotation
    }

    handleDown(e) {
        if (this.animating) {
            return
        }
        if (this.onUpdate) {
            this.onUpdate(Date.now())
        }
        if (!this.viewState || this.viewState.currentQuality == SpinQuality.None) {
            return
        }

        this.dragging = true
        this.dragPrev = this.dragStart = e.event.clientX
        this.dragTotal = 0
        this.spinSpeed = 0
        this.spinDir = 0
        this.timePrev = Date.now()

        clearInterval(this.spinInterval)
        this.spinInterval = null

        this.timePrev = Date.now()
    }

    handleUp(e) {
        if (this.onUpdate) {
            this.onUpdate(Date.now())
        }
        this.dragging = false

        if (!this.view || this.viewState.currentQuality == SpinQuality.None) {
            return
        }


        if (Math.abs(this.dragTotal) < 10 && e.pickInfo) {
            if (e.pickInfo.pickedMesh) {
                this.onPick(e.pickInfo.pickedMesh.id)
            } else {
                this.onPick(null)
            }
            return
        }
        const sinceLastMove = Date.now() - this.timePrev
        if (sinceLastMove > 100) {
            this.spinSpeed = 0
        } else {
            this.startSpin()
        }
    }

    handleMove(e) {
        if (this.onUpdate) {
            this.onUpdate(Date.now())
        }
        const pos = e.event.clientX
        if (this.dragging && this.dragPrev != null) {
            const drag = pos - this.dragPrev
            this.dragTotal += drag
            this.rotationPrev = this.rotation

            // Update rotation
            this.rotation = this.rotation - SPIN_ROTATION_RATE * drag

            const now = Date.now()
            const dt = (now - this.timePrev) / 1000
            if (dt != 0) {
                const newSpeed = Math.max(-SPIN_LIMIT, Math.min(SPIN_LIMIT, SPIN_SCALE * (this.rotation - this.rotationPrev) / dt))
                this.spinSpeed = this.spinSpeed * 0.9 + newSpeed * 0.1
            }

            this.spinDir = this.spinSpeed > 0 ? 1 : -1
            this.timePrev = now
            /*const delta = 100 * (e.event.clientX - this.dragPrev) / engine.canvas.clientWidth
            this.dragPrev = e.event.clientX

            // Set rotation ahead based on delta
            this.rotation = this.rotation + delta
            this.onUpdate(this.rotation.toString())*/
            // dispatch(setRotation({ spinLink: id, viewLink: currentView, rotation: this.rotation }))
        }
        this.dragPrev = pos
    }

    stopSpin() {
        clearInterval(this.spinInterval)
        this.spinSpeed = 0
    }

    startSpin() {
        clearInterval(this.spinInterval)
        this.spinInterval = null

        this.spinInterval = setInterval(() => {
            // Update spin
            const now = Date.now()
            const dt = (now - this.timePrev) / 1000

            var damp = fnc.clamp(1 - SPIN_DAMPENING * dt, 0, 1)
            this.spinSpeed = this.spinSpeed * damp

            var idx = -1

            // Automatically rotate
            this.rotation = (Math.round((this.rotation + this.spinSpeed * dt) * 100)) / 100
            if (this.onUpdate) {
                this.onUpdate(this.rotation)
            }

            this.timePrev = now

            if (Math.abs(this.spinSpeed) < SPIN_STOP_THRESHOLD) {// || (idx != -1 && view.imageIndex != idx)) {
                clearInterval(this.spinInterval)
                this.spinInterval = null
                this.spinSpeed = 0
            }
        }, SPIN_INTERVAL)


    }

    spin(e, dir = 1) {
        e.stopPropagation()
        this.stopSpin()
        this.rotation += SPIN_ROTATION_RATE * 100 * dir
        if (this.onUpdate) {
            this.onUpdate(Date.now())
        }
    }
}
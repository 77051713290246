import React from 'react'
import { Input } from 'components'

interface VectorInputProps {
    value: number[],
    onChange: () => void,
    fields: string[],
}

export function VectorInput(props: VectorInputProps) {
    const { value, onChange, fields } = { fields: ['x', 'y', 'z'], ...props }
    function handleChange(index, v) {
        const newValue = value ? [...value] : fields.map((x) => { return 0 })
        newValue[index] = parseFloat(v)
        if (newValue[index] != null && newValue[index] != NaN) {
            if (onChange) {
                onChange(newValue)
            }
        }
    }
    let fieldElements = []
    for (let i = 0; i < fields.length; i += 1) {
        fieldElements.push(<Input key={i} label={fields[i]} value={value ? value[i] : 0} onChange={(x) => handleChange(i, x)} />)
    }
    return <div className="vector-input">
        {fieldElements}
    </div>
}
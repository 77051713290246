import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { logger } from 'helpers/logger'
import { getLegacyMediaId, getMediaLink } from 'helpers/media'
import { AppDispatch } from './store'
import { RootState, Images, MediaOptions, UserRole, MediaType, PageType, CustomContentData, CustomContentType, FormFieldType, PromptType } from './types'
import { getAppConfig, pageRoles } from './transformers'
import * as fnc from 'helpers/fnc'
import { pageOrder } from './constants'
import { link } from 'fs'
import { navigate, showPrompt } from 'actions/appActions'
import { Console } from 'console'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): AppDispatch => {
    return useDispatch<AppDispatch>()
}
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export function getMediaSelector(): () => MediaData {
    return useAppSelector((state: RootState) => (key: string) => {
        if (key in state.app.media) {
            const media = state.app.media[key]
            return media
        }
        logger.info(`Failed to find media A ${JSON.stringify(key)}`)
        return state.app.media[Images.Missing]
    })
}

export function getMediaLinkSelector() {
    return useAppSelector((state: RootState) => (key: string, options: MediaOptions = {}) => {
        const finalOptions = { ...options }

        if (key in state.app.media) {
            const media = state.app.media[key]
            const vId = state.app.config.version ? `${state.app.config.version.major}.${state.app.config.version.minor}.${state.app.config.version.build}` : '0'
            finalOptions.mediaTypeId = media.mediaTypeId
            if (media.mediaTypeId == MediaType.EmailResource) {
                delete finalOptions.thumb
                delete finalOptions.thumbSize
            }
            switch (media.mediaTypeId) {
                case MediaType.EmailResource:
                case MediaType.Image:
                case MediaType.File: {
                    const link = getMediaLink(media.link, finalOptions)
                    return `${link}?v=${vId}`
                }
                case MediaType.Video: {
                    // Parse video type
                    if (media.link.includes('youtube')) {
                        const videoId = media.link.split('/').pop()?.split('?').shift()
                        const link = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
                        return `${link}?v=${vId}`
                    }
                    if (finalOptions.thumbMediaId) {
                        const thumbMedia = state.app.media[finalOptions.thumbMediaId]
                        if (thumbMedia) {
                            return getMediaLink(thumbMedia.link, finalOptions)
                        }
                    }
                    const videoId = media.link.split('/').pop()
                    const link = getMediaLink(`${videoId}.jpg`, finalOptions)
                    return `${link}?v=${vId}`
                }
                default:
                    break
            }
        }
        logger.error(`Failed to find media  B${key}`)
        const missingMedia = state.app.media[Images.Missing]
        if (missingMedia) {
            return getMediaLink(missingMedia.link)
        } else {
            return null
        }
    })
}

export function getLinkSelector() {
    return useAppSelector((state: RootState) => (app: AppData, pageType: PageType = null, pageData: string = null, baseRoute: string = '') => {
        const options = [pageType, pageData].filter((x) => x != null && x != undefined).join('/')
        if (app.meta.organizations.length > 0) {
            const org = state.app.config.organizations.find((x) => x.published == 0 && x.id == app.meta.organizations[0])
            if (org && !state.app.projectPath.includes(org.link)) {
                return `${baseRoute}/${org.link}${state.app.projectPath}${app.meta.link}/${options}`
            }
        }
        return `${baseRoute}${state.app.projectPath}${app.meta.link}/${options}`
    })
}

export function usePagePermissions() {
    return useAppSelector((state: RootState) => (app: AppData, page: PageData) => {
        if (app) {
            const requiredRoles = pageRoles(app, page)
            if (requiredRoles.length > 0) {
                const requiredSet = new Set(requiredRoles)
                const userSet = new Set(state.user.data && state.user.data.roles ? state.user.data.roles : [])
                const guestRoles = state.user.guestGroup ? state.app.config.groups.find((x) => x.id == state.user.guestGroup)?.roles : []
                const guestSet = new Set(guestRoles ? guestRoles : [])
                return requiredSet.intersection(userSet).size > 0 || requiredSet.intersection(guestSet).size > 0
            }
        }
        return true
    })
}

export function useUserPermissions() {
    return useAppSelector((state: RootState) => (...roles: UserRole) => {
        const user = state.user.data
        const guestRoles = state.user.guestGroup ? state.app.config.groups.find((x) => x.id == state.user.guestGroup)?.roles : []
        const roleSet = new Set(roles)
        return user && (
            (user.roles && (new Set(user.roles)).intersection(roleSet).size > 0) ||
            (guestRoles && (new Set(guestRoles)).intersection(roleSet).size > 0)
        )
    })
}

export function useAdminPermissions() {
    return useAppSelector((state: RootState) => (project: AppData, organization: OrganizationData, roles: UserRole[] = null) => {
        const user = state.user.data
        if (user && Object.keys(user).length === 0) {
            return false
        }
        if (project) {
            return user && project && user.apps && user.apps.find((x) => x.id == project.meta.id) &&
                (user.admin || (user.roles && (new Set(user.roles)).intersection(new Set(roles)).size > 0))
        } else {
            return user && ((organization && user.organizationId == organization.id) || user.email.includes('inventdev'))
        }
    })
}

export function useExclusivePermissions() {
    return useAppSelector((state: RootState) => (project: AppData, roles: UserRole[] = null) => {
        // const user = state.user.data
        const exclusive = state.app.exclusive || state.app.salescenter || state.user.loggedIn
        return exclusive
    })
}

export function useExclusiveMediaPermissions() {
    const exclusivePermissions = useExclusivePermissions()
    return (project: AppData, media: MediaData) => {

        if (exclusivePermissions(project)) {
            return true
        }
        // Check if media is a tour or a spin and those page types are exclusive
        if (media.mediaTypeId == MediaType.Tour) {
            const tourPage = project.pages.find((x) => x.pageType == PageType.Modelhome)
            if (tourPage && tourPage.exclusive) {
                return false
            }
        }
        return true
    }
}

export function useHandleExclusive() {
    const dispatch = useAppDispatch()
    return () => {
        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Please Register', message: 'Please register to gain access to exclusive content including virtual tours, the site map, and upcoming events. Thank you!', confirmMessage: 'Register', close: false }))
            .then((x) => {
                if (x.payload) {
                    dispatch(navigate('register'))
                }
            })
    }
}

export function useValidPages() {
    const adminPermissions = useAdminPermissions()
    const exclusivePermissions = useExclusivePermissions()
    const pagePermissions = usePagePermissions()
    const salescenter = useAppSelector((state: RootState) => state.app.salescenter)
    const pageSet = new Set(pageOrder)

    return (project, organization) => {
        if (project) {
            return project.pages.filter((x) => {
                // if (x.pageType == PageType.ScreenSaver && project.meta.logoMediaId) {
                // return false
                // }
                if (x.draft && !adminPermissions(project)) {
                    return false
                }
                if (x.exclusive && !exclusivePermissions(project) && !adminPermissions(project)) {
                    return false
                }

                if (x.salescenterOnly && !salescenter) {
                    return false
                }

                if (!pagePermissions(project, x)) {
                    return false
                }
                return pageSet.has(x.pageType)
            })
        }
    }
}

const memoryState = {}
export function useMemoryState(key, initialState) {
    const [state, setState] = useState(() => {
        const hasMemoryValue = Object.prototype.hasOwnProperty.call(memoryState, key)
        if (hasMemoryValue) {
            return memoryState[key]
        } else {
            return typeof initialState === 'function' ? initialState() : initialState
        }
    });

    function onChange(nextState) {
        memoryState[key] = nextState
        setState(nextState)
    }

    return [state, onChange]
}

const OPTIONS = {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 0,
}

export const useIsVisible = (elementRef) => {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        if (elementRef.current) {
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true)
                        observer.unobserve(elementRef.current)
                    }
                })
            }, OPTIONS)
            observer.observe(elementRef.current)
        }
    }, [elementRef])

    return isVisible
}

export const useLocalStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key)
            return item ? JSON.parse(item) : initialValue
        } catch (err) {
            console.error(err)
            return initialValue
        }
    })

    const setValue = value => {
        try {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value
            setStoredValue(valueToStore)
            window.localStorage.setItem(key, JSON.stringify(valueToStore))
        } catch (err) {
            console.error(err)
        }
    }
    return [storedValue, setValue]
}

export const useSessionStorage = (key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.sessionStorage.getItem(key)
            return item ? JSON.parse(item) : initialValue
        } catch (err) {
            console.error(err)
            return initialValue
        }
    })

    const setValue = value => {
        try {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value
            setStoredValue(valueToStore)
            window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
        } catch (err) {
            console.error(err)
        }
    }
    return [storedValue, setValue]
}

export function useDynamicContent() {
    const allMedia = useAppSelector((state: RootState) => state.app.media)
    const config = useAppSelector((state: RootState) => state.app.config)
    return (project: AppData, builder: BuilderData, organization: OrganizationContent, page: PageData, data: CustomContentData, allData: CustomContentData[]) => {
        const { type, dynamicContentTypeId, index } = data
        const pages = project ? project.pages : organization?.pages
        const locations = organization ? [...organization.locations] : []//project ? project.locations : organization?.locations
        const finalType = type != null ? type : dynamicContentTypeId
        if (project) {
            const dynamicContent = project.dynamicContent.find((x) => {
                return x.pageId == page?.id && x.dynamicContentTypeId == finalType && x.staticLink == `${page.link}-${index}`//&& x.order == index && x.id?.length == 3
            })
            if (dynamicContent) {
                return dynamicContent
            }
        } else if (organization) {
            const dynamicContent = organization.dynamicContent.find((x) => {
                return x.pageId == page?.id && x.dynamicContentTypeId == finalType && x.staticLink == `${page.link}-${index}`//&& x.order == index && x.id?.length == 3
            })
            if (dynamicContent) {
                return dynamicContent
            }
        }

        const getPosition = (x) => {
            if (x.position == 1) {
                return 'left'
            } else if (typeof x.position == 'string') {
                return x.position
            }
            return 'right'

        }

        const processContent = (x, type, index, depth = 0) => {
            // Static content, do some pre-processing
            const staticContent = { ...x, dynamicContentTypeId: type, staticLink: `${page.link}-${index}`, order: index }
            if (depth == 0 && staticContent.pageId == null) {
                staticContent.pageId = page.id
            }

            // Convert any new line characters in title into escaped new line string
            if (staticContent.title?.includes('\n')) {
                staticContent.title = staticContent.title.replace(/\n/g, '\\n')
            }


            // if(type == CustomContentType.ProjectInfo) {
            // let { eventConfig, formConfig, unitConfig } = getAppConfig(app, config)
            // }

            const processMedia = (x) => {
                const ret = { ...x }
                let { mediaId, galleryId } = getLegacyMediaId(x, project, builder, organization, allMedia, false)
                if (galleryId) {
                    ret.galleryId = galleryId
                } else if (x.type == MediaType.Spin) {
                    // Find the spin and get the media
                    const spin = project.spins.find((x) => x.id == mediaId)
                    if (spin && spin.views.length > 0) {
                        ret.mediaId = spin.views[0].mediaId
                    }
                } else {
                    ret.mediaId = mediaId
                }
                ret.position = getPosition(x)
                if ('type' in ret) {
                    ret.mediaTypeId = ret.type
                    delete ret.type
                }
                if (x.thumbnail) {
                    ret.thumbnailMediaId = getLegacyMediaId(x.thumbnail, project, builder, organization, allMedia, false).mediaId
                }
                if (ret.align) {
                    ret.alignment = ret.align
                    delete ret.align
                }
                return ret
            }

            // Do some media conversion from static
            if (staticContent.media && staticContent.staticLink) {
                if (Array.isArray(staticContent.media)) {
                    staticContent.media = staticContent.media.map(processMedia)
                } else {
                    staticContent.media = [processMedia(staticContent.media)]
                }
            }
            // Location
            if (staticContent.location) {
                // Find locations in project locations
                const location = locations.find((x) => x.numberAndStreet == staticContent.location.numberAndStreet)
                if (location) {
                    staticContent.locations = [{ locationId: location.id, dynamicContentId: null }]
                } else {
                    // staticContent.locations = [staticContent.location]
                    staticContent.locations = [{
                        ...staticContent.location,
                        name: staticContent.title,
                        organizationId: !project ? organization.id : null,
                        appId: project ? project.id : null,
                    }]
                }
                delete staticContent.location
            }

            // Process elements
            if (staticContent.elements) {
                staticContent.elements = staticContent.elements.map((x, ix) => {
                    if (typeof x == 'string') {
                        return { body: x }
                    } else {
                        const subData = processContent(x, x.type || CustomContentType.Element, ix, depth + 1)
                        return subData
                    }
                })
            }

            if (staticContent.expand) {
                delete staticContent.expand
            }

            // Form
            if (staticContent.type == CustomContentType.Form || staticContent.fields) {
                // Find success message in static content with matching id and prefix 'thankyou-'
                const redirectKey = data.redirect?.replace('#', '')
                let successMessage = "Thank you for your submission!"
                if (staticContent?.formOptions?.successMessage) {
                    successMessage = staticContent.formOptions.successMessage
                } else if (redirectKey in allData) {
                    const blurb = allData[redirectKey].find((x) => x.type == CustomContentType.Blurb)
                    if (blurb) {
                        successMessage = blurb.body
                    }
                }
                staticContent.forms = [{
                    ...staticContent.formOptions,
                    tags: staticContent.formOptions ? (Array.isArray(staticContent.formOptions?.tags) ? staticContent.formOptions?.tags?.join(',') : staticContent.formOptions.tags) : null,
                    analyticsEventTypeId: staticContent.requestType,
                    successMessage: successMessage,
                    hubspotFormId: staticContent.hubspotOptions?.formId,
                    fields: staticContent.fields?.map((x) => {
                        let obj = {
                            link: x.id,
                            label: x.label,
                            required: x.required,
                            isHubspot: x.isHubspot,
                            size: x.size,
                            breakAfter: x.breakAfter,
                            choices: [],
                            placeholder: x.select,
                            formFieldTypeId: FormFieldType.Text
                        }
                        if (x.id == 'consent') {
                            obj.formFieldTypeId = FormFieldType.Consent
                            obj.body = obj.label
                            obj.label = 'Data Consent'
                        } if (x.id == 'phone') {
                            obj.formFieldTypeId = FormFieldType.Phone
                        } else if (x.id == 'email') {
                            obj.formFieldTypeId = FormFieldType.Email
                        } else if (x.id.includes('date')) {
                            obj.formFieldTypeId = FormFieldType.Date
                        } else if (x.dropdown) {
                            obj.formFieldTypeId = FormFieldType.Select
                            obj.choices = x.dropdown?.map((x) => {
                                if (typeof x == 'object') {
                                    return { label: x.label, value: x.value }
                                }
                                return { label: x, value: x }
                            })
                            if (x.initial != null) {
                                obj.choices = obj.choices.map((y) => {
                                    if (y.label == x.initial) {
                                        y.isDefault = true
                                    }
                                    return y
                                })
                            }
                        } else if (x.choice) {
                            obj.formFieldTypeId = FormFieldType.Radio
                            obj.choices = x.choice.map((x) => ({ label: x }))
                            if (x.initial != null) {
                                obj.choices = obj.choices.map((y) => {
                                    if (y.label == x.initial) {
                                        y.isDefault = true
                                    }
                                    return y
                                })
                            }
                        }

                        if (obj.id) {
                            delete obj.id
                        }
                        return obj
                    }),
                }]
            }

            // Process misc fields
            if (staticContent.alt) {
                staticContent.contentStyle = 1
            }

            if (staticContent.points) {
                staticContent.points = staticContent.points.map((x) => {
                    return {
                        x: x.x,
                        y: x.y,
                        size: x.size,
                        label: x.number,
                        body: x.label
                    }
                })
            }

            // Process links
            staticContent.links = staticContent.links?.map((x) => {
                let pageId = null
                let mediaId = null
                let galleryId = null
                if (x.link) {
                    const page = pages.find((y) => y.link == x.link)
                    if (page) {
                        pageId = page.id
                    }
                } else if (x.pageType) {
                    const pageType = Object.values(config.pageTypes).find((y) => y.name == x.pageType)
                    if (pageType) {
                        const page = pages.find((y) => y.pageTypeId == pageType.id)
                        if (page) {
                            pageId = page.id
                        }
                    }
                } else if (x.media) {
                    const { mediaId: mId, galleryId: gId } = getLegacyMediaId(x.media, project, builder, organization, allMedia, true)
                    mediaId = mId
                    galleryId = gId

                }

                return {
                    ...x,
                    link: x.link && !pageId && !mediaId && !galleryId ? x.link : null,
                    pageId,
                    mediaId,
                    galleryId,
                    focusNavigation: x.focus,
                }
            })

            if (staticContent.large) {
                staticContent.size = 1
            }

            return staticContent
        }
        const ret = processContent(data, finalType, index)
        return ret
        // finalContent.push(staticContent)
        // return data
    }
}

export function useHybridContent() {
    const dynamicContent = useDynamicContent()
    return (project: AppData, builder: BuilderData, organization: OrganizationData, page: PageData, data: CustomContentData) => {
        const bodyComponents = data ? data[page?.link] : []//.filter((x) => x.type != CzstomContentType.Header)
        const dynamicComponents = project ? project.dynamicContent.filter((x) => x.pageId == page?.id) : organization?.dynamicContent.filter((x) => x.pageId == page?.id)

        if (!data && dynamicComponents.length == 0) {
            logger.info("No data found for hybrid content " + page?.link)
            return []
        }
        const dynamicIds = new Set(dynamicComponents.map((x) => x.id))

        let finalComponents = []
        bodyComponents?.forEach((x, ix) => {
            // if (x.type == CustomContentType.EventCard) 
            // return null
            // }
            // Do a lookup int he app to see if we have a dynamic component
            let finalData = dynamicContent(project, builder, organization, page, { index: ix, ...x }, data)
            if (finalData.id && dynamicIds.has(finalData.id)) {
                dynamicIds.delete(finalData.id)
            }
            // finalData = { ...finalData, order: ix, staticLink: `${page.link}-${ix}` }
            finalComponents.push(finalData)
        })
        // Add dynamic content not present in static
        dynamicComponents?.forEach((x) => {
            // if (x.dynamicContentTypeId == CustomContentType.EventCard) {
            // return null
            // }
            if (!dynamicIds.has(x.id)) {
                return
            }
            finalComponents.push(x)
        })
        // Sort by order
        finalComponents.sort((a, b) => a.order - b.order)
        // Noromalize order
        finalComponents = finalComponents.map((x, ix) => ({ ...x, order: ix }))
        return finalComponents
    }
}

export function usePage(pageId) {
    return useAppSelector((state: RootState) => {
        if (pageId) {
            if (state.app.projectCurrent[0]) {
                return state.app.projectCurrent[0]?.pages.find((x) => x.id == pageId)
            } else if (state.app.organization) {
                return state.app.organization.pages.find((x) => x.id == pageId)
            }
        } else {
            const links = state.app.links
            const pageLink = state.app.links.pageLink
            if (state.app.projectCurrent[0]) {
                if (pageLink) {
                    return state.app.projectCurrent[0]?.pages.find((x) => x.link == pageLink)
                } else {
                    return state.app.projectCurrent[0]?.pages.find((x) => x.pageType == PageType.ScreenSaver)
                }
            } else if (state.app.organization) {
                return state.app.organization.pages.find((x) => x.link == pageLink || (!pageLink && !links.baseRoute && x.link == 'home'))
            }
        }
        return null
    })
}

export function useFooter() {
    return (app: AppData, organization: OrganizationData) => {
        switch (organization.link) {

        }
    }
}

export function useDetermineOrganization() {
    const project = useAppSelector((state: RootState) => state.app.projectCurrent[0])
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const config = useAppSelector((state: RootState) => state.app.config)
    return (organizationLink: string) => {
        let newOrg = null
        if (organizationLink) {
            newOrg = config.organizations.find((x) => x.link == organizationLink)
        } else if (project && project.meta.organizations && project.meta.organizations.length > 0) {
            newOrg = config.organizations.find((x) => x.link == project.meta.organizations[0])
        } else {
            newOrg = config.organizations.find((x) => x.link == 'homegyde')
        }
        if (newOrg?.link == organization?.link) {
            return null
        }
        return newOrg
    }
}

export function usePreviewMedia(): MediaData {
    const allMedia = useAppSelector((state: RootState) => state.app.media)
    return (app: AppData, media: MediaData) => {
        if (!media) {
            logger.info("Invalid preview media", media)
            return media
        }
        if (media.mediaTypeId == MediaType.Spin) {
            const spin = app.spins.find((x) => x.views.find((y) => y.mediaId == media.id))
            if (spin) {
                return allMedia[spin.previewMediaId]
            }
        } else if (media.mediaTypeId == MediaType.Tour) {
            const modelhome = app.modelhomes.find((x) => x.mediaId == media.id)
            if (modelhome) {
                return allMedia[modelhome.previewMediaId]
            }
        }
        return media
    }
}
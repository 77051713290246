import React from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { RootState, PromptOptions, EmailType, PageType, ScreenSize } from 'app/types'
import { showPrompt, sendEmail } from 'actions/appActions'
import { Button, IconButton } from 'components'
import { logger } from 'helpers/logger'
import { icons } from 'app/constants'

export function EmailFavouritesButton() {
    const favourites = useAppSelector((state:RootState) => state.user.favourites)
    const { size, orientation } = useAppSelector((state: RootState) => state.app.screen)
    const dispatch = useAppDispatch()

    const handleEmail = () => {
        // Flatten favourites
        // let flatFavourites = []
        // Object.keys(favourites).forEach((x) => {
            // flatFavourites = flatFavourites.concat(Object.values(favourites[x]))
        // })
        dispatch(showPrompt(PromptOptions.EmailFavourites))
            .then((x: { payload: EmailOptions }) => {
                if (x.payload && x.payload.value) {
                    return Promise.all([
                        dispatch(showPrompt(PromptOptions.EmailSending)),
                        dispatch(sendEmail({
                            emailType: EmailType.Favourites,
                            email: x.payload.value,
                            page: PageType.Favourite,
                            hash: { favourites },
                            // options: { favourites: flatFavourites, optIn: x.payload.optIn },
                            options: { optIn: x.payload.optIn },
                        })),
                    ])
                }
                throw x
            })
            .catch(logger.error)
    }

    if (size == ScreenSize.Mobile) {
        return <IconButton key="email" onClick={handleEmail} icon={icons.envelope}/>
    }
    return <Button key="email" onClick={handleEmail} icon={icons.envelope}>Email My Favourites</Button>
}

import React, { useEffect, useState } from 'react'
import { Button, Icon, IconButton } from 'components'
import { Visibility } from 'app/types'

interface UpgradeNavGroupProps {
    group: Dict,
    focus: boolean,
    options: Dict[]
    onClick: () => void,
    complete: boolean,
    onPrev: () => void,
    onNext: () => void,
    mobileView: boolean,
    style: Dict,
    onNextOrder: () => void
    onPrevOrder: () => void
}

export function UpgradeNavGroup(props: UpgradeNavGroupProps) {
    const { group, summary, focus, options, onClick, complete, onPrev, onNext, mobileView, style, onNextOrder, onPrevOrder, maps } = props

    const [subHeight, setSubHeight] = useState(0)
    const [subRef, setSubRef] = useState(null)
    useEffect(() => {
        if (!subRef) {
            return
        }
        let height = 0
        subRef.childNodes.forEach((x) => {
            height += x.clientHeight
        })
        setSubHeight(height)
    }, [subRef])

    const subStyle = {
        height: `${focus ? subHeight : 0}px`,
        opacity: focus ? 1 : 0,
    }

    function handlePrev(e) {
        e.stopPropagation()
        onPrev()
    }
    function handleNext(e) {
        e.stopPropagation()
        onNext()
    }

    function handleClick(e) {
        e.stopPropagation()
        onClick(group)
    }
    let groupName = group.name
    if (group.id in maps.variationOptionGroups && maps.variationOptionGroups[group.id].name) {
        groupName = maps.variationOptionGroups[group.id].name
    }

    return <div className={`upgrade-nav-item scrollable${focus ? ' focus' : ''} row`} key={group.id} style={style}>
        {!mobileView && <div className='column'>
            <IconButton icon={complete ? 'fas fa-check' : (focus ? 'fas fa-chevron-right' : 'fas fa-circle')} onClick={() => onClick(group)} />
        </div>}
        <div className='column'>
            <Button noBg className="nav-group" onClick={handleClick}>{groupName}</Button>
            {options && !mobileView && <div className="sub-nav" style={subStyle} ref={setSubRef}>
                {options.map((y, iy) => {
                    let optionName = y.name
                    if (y.id in maps.variationOptions && maps.variationOptions[y.id].name) {
                        optionName = maps.variationOptions[y.id].name
                    }
                    return <Button key={`${y.id}${iy}`} className="nav-option" noBg onClick={(e) => { e.stopPropagation(); onClick(group, y) }}>
                        <Icon icon="fas fa-circle" noBg/>
                        {optionName}
                    </Button>
                })}
            </div>}
            {focus && !mobileView && <div className="row prev-next">
                {onPrev && <Button tertiary onClick={handlePrev}>Prev</Button>}
                {onNext && <Button tertiary onClick={handleNext}>Next</Button>}
                {onPrevOrder && <IconButton className="order-button" icon="fas fa-chevron-up" onClick={onPrevOrder} />}
                {onNextOrder && <IconButton className="order-button" icon="fas fa-chevron-down" onClick={onNextOrder} />}
            </div>}
        </div>
    </div>
}
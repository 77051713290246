import { useAppSelector } from 'app/hooks'
import { ScreenOrientation } from 'app/types'
import React, { useEffect, useState } from 'react'
import * as fnc from 'helpers/fnc'
import { is } from 'immer/dist/internal'

interface IntersectionHelperProps {
    options: Dict,
    key: string,
}
export function IntersectionHelper(props: IntersectionHelperProps) {
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const [initialized, setInitialized] = useState(false)
    /**
     * Handle custom element animate-in
     */
    const inViewport = (entries, obs) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setTimeout(() => {
                    entry.target.classList.add("visible")
                }, 200)
            }
        })
    }
    useEffect(() => {
        // Allows other components to have time to render
        setInitialized(true)
    }, [])
    useEffect(() => {
        let threshold = 0.25
        if (screen.isMobile) {
            if (screen.orientation == ScreenOrientation.Portrait) {
                threshold = 0.1
            } else {
                threshold = 0
            }
        }
        const obsOptions = { threshold, ...props.options }
        const observer = new IntersectionObserver(inViewport, obsOptions)
        const elements = document.querySelectorAll(`[${props.attr ? props.attr : 'data-inviewport'}]`)
        elements.forEach((x) => {
            observer.observe(x, obsOptions)
        })
        return () => {
            elements.forEach((x) => {
                observer.unobserve(x)
            })
        }
    }, [initialized])

    return null
}

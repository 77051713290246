import React, { Suspense, useEffect, useState, useRef } from 'react'
import { PageType, QueryType, FloorplanFilter, UnitFilter, CustomEventType, NotificationStatus } from 'app/types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Spinner, Split } from 'components'
import { navigateAsync, applyQuery, toggleShowSitemap, toggleFullscreen, pushNotification } from 'actions/appActions'
import { FloorplanList } from 'views/FloorplanPage/FloorplanList'
import { FloorplanView } from 'views/FloorplanPage/FloorplanView'
import { SitemapView } from './SitemapView'
import { setQueryHelper, resetQueryHelper, filterUnit } from 'helpers/query'
import { ModelhomesView } from 'views/AdminPage/Views/ModelhomesView'
import { ModelhomeView } from 'views/ModelhomePage/ModelhomeView'
import * as fnc from 'helpers/fnc'
import { transformGarage } from 'app/transformers'
const Spin = React.lazy(() => import('components/Spin/Spin'))

interface SitemapPageProps {
    app: AppData,
    dataLink: string,
    extraLink: string,
    splitIndex: number,
}

export function SitemapPage(props: SitemapPageProps) {
    const { app, dataLink, extraLink, splitIndex } = props

    const dispatch = useAppDispatch()

    const [sitemap, setSitemap] = useState((() => {
        if (app.sitemaps.length > 0) {
            const defaultSitemap = app.sitemaps.find((x) => x.isDefault)
            if (defaultSitemap) {
                return defaultSitemap
            }
            return [...app.sitemaps].sort((a, b) => a.order - b.order)[0]
        }
        return null
    })())
    const sitemapUnits = new Set(sitemap ? sitemap.units.map((x) => x.unitId) : [])
    const [filteredUnits, setFilteredUnits] = useState(null)
    const [focusUnit, setFocusUnit] = useState(null)
    const [showModelhome, setShowModelhome] = useState(null)
    const [showSpin, setShowSpin] = useState(null)
    const [variation, setVariation] = useState(null)
    const variationInitialized = useRef(false)

    // TODO, database chec
    const hasSitemap = splitIndex == null && sitemap != null && app.pages.find((x) => x.pageType == PageType.Sitemap) != null
    const showSitemap = useAppSelector((state: RootState) => state.app.showSitemap)
    const fullscreen = useAppSelector((state: RootState) => state.app.fullscreen)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    // const [showSitemap, setShowSitemap] = useState()
    const query = useAppSelector((state: RootState) => state.app.queries[QueryType.Floorplans])

    function handleModelhome(x) {
        setShowModelhome(x)
        setShowSpin(null)
    }

    function handleSpin(x) {
        setShowSpin(x)
        setShowModelhome(null)
    }

    function handleFullscreen(x) {
        if (screen.fullscreenSupport) {
            fnc.toggleFullscreen(x)
        } else {
            dispatch(toggleFullscreen())
        }
    }

    useEffect(() => {
        if (!dataLink) {
            variationInitialized.current = false
            return
        }

        const timeout = setTimeout(() => {
            variationInitialized.current = true
        }, 300)
        return () => {
            clearTimeout(timeout)
        }

    }, [dataLink, variation])

    useEffect(() => {
        // Deselect when returning to floorplan page
        if (!dataLink && focusUnit && !query[FloorplanFilter.Unit]) {
            setFocusUnit(null)
        }
        if (dataLink) {
            handleFiltered(app.floorplans.filter((x) => x.link == dataLink), variation)
        } else if (query[FloorplanFilter.Unit]) {
            const querySet = new Set(query[FloorplanFilter.Unit])
            handleFiltered(app.floorplans.filter((x) => {
                return new Set(x.units.map((x) => x.name)).intersection(querySet).size > 0
            }))
        }
        // handleFiltered(app.floorplans.filter((x) => !dataLink ||x.link == dataLink))
    }, [dataLink, variation])

    useEffect(() => {
        if (query[FloorplanFilter.Unit]) {
            setFocusUnit(query[FloorplanFilter.Unit][0])
        } else {
            setFocusUnit(null)
        }

    }, [query])

    
    function filterSitemapFloorplans(floorplan) {
        if(sitemapUnits.size == 0) {
            return true
        }
        // Check if floorplan has any units in the sitemap
        return floorplan.units.some((x) => sitemapUnits.has(x.id))
    }

    async function handleFocusUnit(floorplanId, focusUnit) {
        const newQuery = setQueryHelper(QueryType.Floorplans, query, { [FloorplanFilter.Unit]: [focusUnit] })
        dispatch(applyQuery({ query: newQuery, type: QueryType.Floorplans }))
    }

    function handleFocus(unitName) {
        if (!unitName) {
            const newQuery = resetQueryHelper(QueryType.Floorplans.queryType, query, FloorplanFilter.Unit)
            dispatch(applyQuery({ query: newQuery, type: QueryType.Floorplans }))
        } else {
            const newQuery = setQueryHelper(QueryType.Floorplans, query, { [FloorplanFilter.Unit]: [unitName] })
            dispatch(applyQuery({ query: newQuery, type: QueryType.Floorplans }))
        }
    }

    function handleVariation(floorplan, variation) {
        // If a variation was selected, check if the variation has unique stats compared to others, if so apply filter
        const bedSet = new Set()
        const bathSet = new Set()
        const garageSet = new Set()
        floorplan.variations.forEach((x) => {
            if (x.beds != null) {
                bedSet.add(x.beds)
            }
            if (x.baths != null) {
                bathSet.add(x.baths)
            }
            if (x.garage != null) {
                garageSet.add(x.garage)
            }
        })
        let newQuery = {}
        let messages = []
        if (bedSet.size > 1) {
            if (variation) {
                messages.push(`Filter by ${variation.beds} beds`)
                newQuery = setQueryHelper(QueryType.Floorplans, query, { [FloorplanFilter.Bed]: [variation.bed] })
            } else if (query[FloorplanFilter.Bed]) {
                messages.push(`Reset bed filter`)
                newQuery = resetQueryHelper(QueryType.Floorplans, query, FloorplanFilter.Bed)
            }
        }
        if (bathSet.size > 1) {
            if (variation) {
                messages.push(`Filter by ${variation.baths} baths`)
                newQuery = { ...newQuery, ...setQueryHelper(QueryType.Floorplans, query, { [FloorplanFilter.Bath]: [variation.bath] }) }
            } else {
                messages.push(`Reset bath filter`)
                newQuery = { ...newQuery, ...resetQueryHelper(QueryType.Floorplans, query, FloorplanFilter.Bath) }
            }
        }
        if (garageSet.size > 1) {
            if (variation) {
                messages.push(`Filter by ${transformGarage(app, variation.garage)} garage`)
                newQuery = { ...newQuery, ...setQueryHelper(QueryType.Floorplans, query, { [FloorplanFilter.Garage]: [transformGarage(app, variation.garage)] }) }
            } else {
                messages.push(`Reset garage filter`)
                newQuery = { ...newQuery, ...resetQueryHelper(QueryType.Floorplans, query, FloorplanFilter.Garage) }
            }
        }
        if (variationInitialized.current && messages.length > 0) {
            messages.forEach((x) => {
                // dispatch(pushNotification({ message: x, status: NotificationStatus.Info }))
            })
            window.dispatchEvent(new CustomEvent(CustomEventType.FilterApplied))
        }

        if (bedSet.size > 1 || bathSet.size > 1 || garageSet.size > 1) {
            dispatch(applyQuery({ query: newQuery, type: QueryType.Floorplans }))
        }

        setVariation(variation)
    }

    function handleToggleSitemap(sitemapId) {
        const idx = app.sitemaps.findIndex((x) => x.id == sitemapId)
        if (idx != -1) {
            setSitemap(app.sitemaps[idx])
        }
    }

    function handleFiltered(plans, planVariation = null) {
        // Get filterd units from filtered plans
        const filteredUnits = {}
        plans.forEach((x) => {
            if (dataLink && x.link != dataLink) {
                return
            }
            for (let i = 0; x.units && i < x.units.length; i += 1) {
                const unit = app.maps.unit[x.units[i].id]
                // if (unit.name in filteredUnits) {
                // return
                // }
                let highlight = true
                if (!unit) {
                    continue
                }
                const floorplanVariationId = x.units[i].floorplanVariationId

                if (planVariation?.id != null && floorplanVariationId != null && planVariation?.id != floorplanVariationId) {
                    // highlight = false
                    continue
                }
                if(!filterUnit(app, unit, query)) {
                    continue
                }

                if (sitemap.size > 0 && !sitemapUnits.has(unit.id)) {
                    continue
                }

                /*if (query[UnitFilter.Floor]
                    && !query[UnitFilter.Floor].find((x) => x === unit.floor)) {
                    continue
                }

                if (query[UnitFilter.Exposure]
                    && !query[UnitFilter.Exposure].find(
                        (x: string) => unit.exposure?.includes(x.toLowerCase()),
                    )) {
                    continue
                }

                if (query[FloorplanFilter.Search]) {
                    const searchTerm = query[FloorplanFilter.Search] ? query[FloorplanFilter.Search].simplify() : null
                    const unitTerm = unit.name.simplify()
                    if (unitTerm !== searchTerm) {
                        continue
                    }
                }

                if (query[FloorplanFilter.Beds]) {
                    let match = false
                    const fpMatches = new Set()
                    for (let j = 0; j < unit.floorplans.length; j += 1) {
                        const floorplan = app.maps.floorplan[unit.floorplans[j].floorplanId]
                        if (fpMatches.has(floorplan.id)) {
                            match = true
                            break
                        }
                        for (let k = 0; k < floorplan.variations.length; k += 1) {
                            const variation = floorplan.variations[k]
                            if(!variation.beds) {
                                break
                            }
                            const codes = [Math.floor(variation.beds).toString()]
                            // Bed + den
                            if (variation.den) {
                                codes.push(`${codes[0]} + D`)
                            }
                            // Get intersection of codes and query
                            if (query[FloorplanFilter.Beds].filter((x) => codes.includes(x)).length > 0) {
                                match = true
                                fpMatches.add(floorplan.id)
                                break
                            }
                        }
                        if (match) {
                            break
                        }
                    }
                    if (!match) {
                        continue
                    }
                }*/
                filteredUnits[unit.name] = { unit, highlight }
            }
        })
        // Dispatch focus event for floorplan
        setFilteredUnits(filteredUnits)
    }
    floorplanElement = <FloorplanView key={0} withSitemap={hasSitemap} focusUnit={focusUnit} {...props} altMap={(x) => x.includes('elm')} onFocusUnit={handleFocusUnit} onVariation={handleVariation} />

    function handleShowSitemap() {
        dispatch(toggleShowSitemap())
    }

    let sitemapElement = null
    if (hasSitemap && showSitemap) {
        sitemapElement = <SitemapView
            app={app}
            data={sitemap}
            // dataLink={sitemapLink}
            splitIndex={0}
            filteredUnits={filteredUnits}
            onFocus={handleFocus}
            onToggle={handleToggleSitemap}
            focusUnit={focusUnit}
            pageType={PageType.Floorplan} />

        if (fullscreen && !showModelhome & !showSpin) {
            return <div className="" style={{ width: '100%', height: '100%' }}>
                {sitemapElement}
            </div>
        }
    }


    let floorplanElement = null

    if (showSpin) {
        const spin = app.spins.find((x) => x.id == showSpin)
        if (spin) {
            return <Suspense fallback={<Spinner overlay invert />}>
                <Spin
                    app={app}
                    spinLink={spin.link}
                    viewLink={spin.views[0].link}
                    splitIndex={null}
                    // focusSet={focusSet}
                    // splitView={true}
                    // filteredUnits={filteredUnits}
                    pageType={PageType.Floorplan}
                    onFullscreen={handleFullscreen}
                    disclaimer={true}
                    onClose={() => setShowSpin(null)} />
            </Suspense>
        }
    } else if (showModelhome) {
        return <ModelhomeView app={app} dataLink={showModelhome} splitIndex={splitIndex} onClose={() => setShowModelhome(null)} fullscreen={fullscreen} onFullscreen={handleFullscreen} />
    } else if (props.dataLink) {
        floorplanElement = <FloorplanView key={0} withSitemap={hasSitemap} focusUnit={focusUnit} {...props} altMap={(x) => x.includes('elm')} onFocusUnit={handleFocusUnit} onModelhome={handleModelhome} onSpin={handleSpin} onVariation={handleVariation} />
    } else {
        floorplanElement = <FloorplanList
            key={sitemap?.id}
            withSitemap={hasSitemap}
            focusUnit={focusUnit}
            showFeaturedMedia={true}
            pageLink={PageType.Sitemap}
            onFilter={handleFiltered}
            onFocusUnit={handleFocusUnit}
            onModelhome={handleModelhome}
            onSpin={handleSpin}
            extraFilter={filterSitemapFloorplans}
            {...props} />
    }


    // Prevents model home re-load
    return <Split id="sitemap-split">
        {sitemapElement}
        {floorplanElement}
    </Split>
}

import React, { useEffect, useState } from 'react'
import { Button, Checkbox, CloseButton, DateRange, DateSelector, DropdownMenu, Icon, IconButton, Spinner } from 'components'
import { FAQ } from 'components/FAQ'
import { AppData, Dict, PromptType, MediaType, UpgradeOrderStatus } from 'app/types'
import { useAppDispatch, useAppSelector, useLocalStorage } from 'app/hooks'
import * as fnc from 'helpers/fnc'

import { ClientRow } from './ClientRow'
import { Toggle } from 'components/Core/Toggle'
import { createUpgradeDocument, createUpgradeDocumentDownload, showPrompt } from 'actions/appActions'
import { getMediaLink } from 'helpers/media'

enum DocumentTab {
    All = 0,
    Generated = 1,
    Uploaded = 2,
    Docusign = 3,
}

export enum UpgradePopupType {
    None = 'none',
    Nav = 'nav',
    Faq = 'faq',
    AddUser = 'add-user',
    EditUser = 'edit-user',
    Documents = 'documents',
    Download = 'download',
    Summary = 'summary',
    FocusProduct = 'focus-product',
    FocusPackage = 'focus-package',
}


interface UpgradePopupProps {
    type: UpgradePopupType,
    onClick: () => void,
    onClose: () => void,
    close: boolean,
    withPackage: boolean,
    withNav: boolean,
    className: string,
    style: {},
    showClose: boolean,
    // Dependent props
    faq: Dict,
    app: AppData,
    allClients: Dict[],
    upgrades: Dict,
    maps: Dict,
    popupData: Dict,
    handleAddUserOrders: () => void,
    handleUpdateUserOrder: () => void,
    handleUpdateUpgradeOrder: () => void,
}

export function UpgradePopup(props: UpgradePopupProps) {
    const {
        type,
        onClick,
        onClose,
        close,
        withPackage,
        withNav,
        className,
        style,
        showClose,
        faq,
        app,
        allClients,
        upgrades,
        maps,
        popupData,
        handleAddUserOrders,
        handleUpdateUserOrder,
        handleUpdateUpgradeOrder,
    } = { close: true, showClose: true, ...props }

    const dispatch = useAppDispatch()

    const media = useAppSelector((state: RootState) => state.app.media)

    const [data, setData] = useState(popupData)
    const [documentSort, setDocumentSort] = useLocalStorage('document-sort', 'filename')
    const [documentTab, setDocumentTab] = useLocalStorage('document-tab', DocumentTab.All)
    const [documents, setDocuments] = useState({
        [DocumentTab.All]: [],
        [DocumentTab.Generated]: [],
        [DocumentTab.Uploaded]: [],
        [DocumentTab.Docusign]: []
    })
    const [globalDocs, setGlobalDocs] = useState(false)
    const [documentDateRange, setDocumentDateRange] = useState(null)//useLocalStorage('document-date-range', null)
    const [generatingDownload, setGeneratingDownload] = useState(false)

    function sortDocument(a, b, signable) {
        const mA = media[a.mediaId]
        const mB = media[b.mediaId]
        if (documentSort.includes('name') && !documentSort.includes('filename')) {
            if (documentSort == 'name') {
                return a.user?.name.localeCompare(b.user?.name)
            }
            return b.user?.name.localeCompare(a.user?.name)
        } else if (documentSort.includes('email')) {
            if (documentSort == 'email') {
                return a.user?.email.localeCompare(b.user?.email)
            }
            return b.user?.email.localeCompare(a.user?.email)
        } else if (documentSort.includes('floorplan')) {
            const fa = app.maps.floorplanVariationFloorplan[a.userOrder.floorplanVariationId]
            const fB = app.maps.floorplanVariationFloorplan[b.userOrder.floorplanVariationId]
            if (documentSort == 'floorplan') {
                return fa.name.localeCompare(fB.name)
            }
            return fB.name.localeCompare(fa.name)
        } else if (documentSort.includes('unit')) {
            const uA = app.maps.unit[a.userOrder.unitId]
            const uB = app.maps.unit[b.userOrder.unitId]
            if (documentSort == 'unit') {
                return uA.name.localeCompare(uB.name)
            }
            return uB.name.localeCompare(uA.name)
        } else if (documentSort.includes('view')) {
            if (!a.upgradeOrder || !b.upgradeOrder) return 0
            const vA = maps.views[a.upgradeOrder.upgradeViewId]
            const vB = maps.views[b.upgradeOrder.upgradeViewId]
            if (documentSort == 'view') {
                return vA.name.localeCompare(vB.name)
            }
            return vB.name.localeCompare(vA.name)
        } else if (documentSort.includes('filename')) {
            if (documentSort == 'filename') {
                return mA.link.localeCompare(mB.link)
            } else {
                return mB.link.localeCompare(mA.link)
            }
        } else if (documentSort.includes('dateCreated') || (documentSort.includes('dateSigned') && !signable)) {
            if (documentSort == 'dateCreated') {
                return mA.dateCreated.localeCompare(mB.dateCreated)
            } else {
                return mB.dateCreated.localeCompare(mA.dateCreated)
            }
        } else if (documentSort.includes('dateSigned')) {
            if (documentSort == 'dateSigned') {
                return a.signedDate.localeCompare(b.signedDate)
            } else {
                return b.signedDate.localeCompare(a.signedDate)
            }
        }
    }

    function filterDocument(x, signable) {
        if (documentDateRange != null && documentDateRange.startDate != null && documentDateRange.endDate != null) {
            const m = media[x.mediaId]
            let compareDate = (signable && x.signedDate != null) ? new Date(x.signedDate) : new Date(m.dateCreated)
            return compareDate >= new Date(documentDateRange.startDate) && compareDate <= new Date(documentDateRange.endDate)
        }
        return true
    }

    useEffect(() => {
        setData(popupData)
    }, [popupData])

    useEffect(() => {
        if (type == UpgradePopupType.Documents) {
            if (data?.documents?.length > 0) {
                const newDocuments = {
                    [DocumentTab.All]: [],
                    [DocumentTab.Generated]: [],
                    [DocumentTab.Uploaded]: [],
                    [DocumentTab.Docusign]: []
                }
                data.documents.forEach((x) => {
                    if (x.envelopeId != null) {
                        newDocuments[DocumentTab.Docusign].push(x)
                    } else if (!x.generated) {
                        newDocuments[DocumentTab.Uploaded].push(x)
                    } else {
                        newDocuments[DocumentTab.Generated].push(x)
                    }
                    newDocuments[DocumentTab.All].push(x)
                })
                newDocuments[DocumentTab.All] = newDocuments[DocumentTab.All].filter((x) => filterDocument(x, false)).sort((a, b) => sortDocument(a, b, false))
                newDocuments[DocumentTab.Generated] = newDocuments[DocumentTab.Generated].filter((x) => filterDocument(x, false)).sort((a, b) => sortDocument(a, b, false))
                newDocuments[DocumentTab.Uploaded] = newDocuments[DocumentTab.Uploaded].filter((x) => filterDocument(x, true)).sort((a, b) => sortDocument(a, b, true))
                newDocuments[DocumentTab.Docusign] = newDocuments[DocumentTab.Docusign].filter((x) => filterDocument(x, false)).sort((a, b) => sortDocument(a, b, false))

                setDocuments(newDocuments)
                setGlobalDocs(data.documents.find((x) => x.user != null))
            }
        }
    }, [documentTab, documentDateRange, documentSort, data])

    function handleClick() {
        if (onClick) {
            onClick()
        }
    }
    function handleClose() {
        if (onClose) {
            onClose()
        }
    }

    function handleDownloadDocument(document) {
        const link = getMediaLink(document.link, { app })
        const filename = document.link.split('/').pop()

        // Download file at link
        fnc.openLinkInNewWindowWithDownload(link, filename)
    }

    async function handleDownloadAllDocuments() {
        const docs = documents[documentTab]
        if (docs.length == 0) {
            dispatch(showPrompt({ type: PromptType.Confirm, title: 'Empty Range', message: 'No documents found in date range', cancelMessage: null }))
        } else {
            setGeneratingDownload(true)
            dispatch(createUpgradeDocumentDownload({ app, documents: docs }))
                .then((x) => {
                    // Format base64 zip as download link
                    const zipLink = `data:application/zip;base64,${x.payload.zip}`
                    fnc.openLinkInNewWindowWithDownload(zipLink, `${app.meta.name} Signed Documents - ${fnc.timestamp()}.zip`)
                    setGeneratingDownload(false)
                })
        }
    }
    function handleAddDocument() {
        const record = data
        dispatch(showPrompt({ type: PromptType.Media, app, mediaTypeId: MediaType.File, multi: true, upload: true }))
            .then((x) => {
                if (x.payload && x.payload.length > 0) {
                    const docs = x.payload.map((x) => {
                        let signedDate = null
                        const m = media[x]
                        if (m) {
                            // Check media link for a date with regex
                            signedDate = fnc.extractDateFromFilename(m.link)
                        }
                        return {
                            upgradeOrderRecordId: record?.id,
                            mediaId: x,
                            generated: false,
                            signedDate,
                        }
                    })
                    // Add documents
                    dispatch(createUpgradeDocument({ app, documents: docs }))
                        .then((x) => {
                            const newRecord = { ...record, upgradeOrderStatusId: UpgradeOrderStatus.Signed }
                            x.payload.documents.forEach((x) => {
                                const idx = newRecord.documents.findIndex((y) => {
                                    y.upgradeOrderRecordId == x.upgradeOrderRecordId && y.mediaId == x.mediaId
                                })
                                if (idx == -1) {
                                    newRecord.documents.push(x)
                                } else {
                                    newRecord.documents[idx] = x
                                }

                            })
                            handleUpdateUpgradeOrder(newRecord)
                            setData(newRecord)
                        })
                }
            })
    }

    function handleDocumentDelete(document) {
        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Delete Document', message: 'Are you sure you want to delete this document?' }))
            .then((x) => {
                if (x.payload) {
                    const newRecord = { ...data }
                    newRecord.documents = newRecord.documents.filter((x) => !(x.mediaId == document.mediaId && x.generated == document.generated))
                    handleUpdateUpgradeOrder(newRecord)
                    setData(newRecord)
                }
            })
    }

    function handleDocumentDateChange(document, date) {
        const newRecord = { ...data }
        const idx = newRecord.documents.findIndex((x) => x.mediaId == document.mediaId && x.generated == document.generated)
        if (idx != -1) {
            newRecord.documents[idx].signedDate = date
            handleUpdateUpgradeOrder(newRecord)
            setData(newRecord)
        }

    }

    function toggleDocumentSort(x) {
        if (documentSort == x) {
            setDocumentSort(`-${x}`)
        } else {
            setDocumentSort(x)
        }
    }
    function getPopup() {
        switch (type) {
            case UpgradePopupType.Nav:
                break
            case UpgradePopupType.Faq:
                return <FAQ faq={faq} onClose={handleClose} />
            case UpgradePopupType.AddUser:
                return <ClientRow allClients={allClients} editing app={app} upgrades={upgrades} maps={maps} onAdd={async (x) => {
                    const ret = await handleAddUserOrders(x)
                    if (ret.payload.success) {
                        handleClose()
                        // setShowAddUser(false)
                    }
                    return ret
                }} >
                    {/* <CloseButton onClick={() => setShowAddUser(false)} /> */}
                    <CloseButton onClick={handleClose} />
                </ClientRow>
                break
            case UpgradePopupType.EditUser:
                return <ClientRow {...data} app={app} editing upgrades={upgrades} maps={maps}
                    onEditUserOrder={async (x) => {
                        const ret = await handleUpdateUserOrder(x)
                        if (ret.payload.success) {
                            handleClose()
                            // setShowEditUser(null)
                        }
                        return ret
                    }}
                    onEditUpgradeOrder={handleUpdateUpgradeOrder}
                >
                    <CloseButton onClick={handleClose} />
                    {/* <CloseButton onClick={() => setShowEditUser(null)} /> */}
                </ClientRow>
                break
            case UpgradePopupType.Documents:

                const getHeader = (title, key) => {
                    return <th onClick={() => toggleDocumentSort(key)} className="noselect clickable">
                        {title}
                        {documentSort.includes(key) && <Icon noBg icon={documentSort == key ? 'fas fa-chevron-down' : 'fas fa-chevron-up'} />}
                    </th>
                }

                const documentTable = (title, x, signable = false) => {
                    return <div className="row document-table">
                        <div className="column">
                            {x.length == 0 && <h5>No Documents</h5>}
                            {x.length > 0 && <table>
                                <thead>
                                    {globalDocs && getHeader('User', 'user')}
                                    {globalDocs && getHeader('Email', 'email')}
                                    {globalDocs && getHeader('Floorplan', 'floorplan')}
                                    {globalDocs && getHeader('Unit', 'unit')}
                                    {globalDocs && getHeader('View', 'view')}
                                    {getHeader('Filename', 'filename')}
                                    {getHeader('DateCreated', 'dateCreated')}
                                    {signable && getHeader('DateSigned', 'dateSigned')}
                                    <th></th>
                                </thead>
                                <tbody>
                                    {x.sort((a, b) => sortDocument(a, b, signable)).map((y) => {
                                        const m = media[y.mediaId]
                                        let dateCreated = fnc.dateFriendly(new Date(m.dateCreated))
                                        let filename = m.link
                                        let floorplan = null
                                        let unit = null
                                        let view = null
                                        if (globalDocs) {
                                            const variation = app.maps.floorplanVariation[y.userOrder.floorplanVariationId]
                                            if (variation) {
                                                floorplan = app.maps.floorplan[variation.floorplanId]
                                            }
                                            unit = app.maps.unit[y.userOrder.unitId]
                                            view = maps.views[y.upgradeOrder.upgradeViewId]
                                        }
                                        return <tr>
                                            {globalDocs && <td>{y.user.name}</td>}
                                            {globalDocs && <td>{y.user.email}</td>}
                                            {globalDocs && <td>{floorplan?.name}</td>}
                                            {globalDocs && <td>{unit?.name}</td>}
                                            {globalDocs && <td>{view?.name}</td>}
                                            <td>
                                                {filename}
                                            </td>
                                            <td>
                                                {dateCreated}
                                            </td>
                                            {signable && <td>
                                                <DropdownMenu text={fnc.dateFriendly(new Date(y.signedDate))} items={[
                                                    { element: <DateSelector onChange={(x) => handleDocumentDateChange(y, x)} selected={y.signedDate ? new Date(y.signedDate) : null} /> }
                                                ]} />
                                            </td>}
                                            <td>
                                                <div className="row">
                                                    <IconButton onClick={() => handleDownloadDocument(m)} icon="fas fa-download" tertiary />
                                                    <IconButton onClick={() => handleDocumentDelete(y)} icon="fas fa-trash" />
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>}
                        </div>
                    </div>
                }
                return <div className={`row upgrade-popup-content document-popup${generatingDownload ? ' disabled' : ''}`}>
                    {generatingDownload && <Spinner overlay invert quotes={false} />}
                    <CloseButton onClick={handleClose} />
                    <div className="column">
                        <div className="row">
                            <h3>{data.title ? data.title : 'Documents'}</h3>
                        </div>
                        <div className="row  document-options">
                            <Toggle items={Object.keys(DocumentTab).filter((x) => isNaN(parseInt(x))).map((x) => {
                                return { value: DocumentTab[x], text: x, counter: documents[DocumentTab[x]].length }
                            })} value={documentTab} onChange={setDocumentTab} />
                            <div className="button-group">
                                {/* <DateRange onChange={(x) => setDocumentDateRange(x)} selected={documentDateRange} /> */}
                                <Button onClick={handleDownloadAllDocuments} tertiary icon="fas fa-download">Download All</Button>
                                {documentTab == 2 && <Button onClick={handleAddDocument} tertiary icon="fas fa-plus">Upload</Button>}
                            </div>
                        </div>
                        {documentTable(DocumentTab[documentTab], documents[documentTab], documentTab == DocumentTab.Uploaded)}
                    </div>
                </div>
                break
            /*case UpgradePopupType.Download:
                return <div className={`row upgrade-popup-content download-popup${generatingDownload ? ' disabled' : ''}`}>
                    <CloseButton onClick={handleClose} />
                    <div className="column">
                        <h3>Download Documents</h3>
                        <Checkbox value={!downloadGenerated} onChange={(x) => setDownloadGenerated(!downloadGenerated)} tertiary>Only Uploaded Documents</Checkbox>
                        <DateRange onChange={setDocumentDateRange} selected={documentDateRange} inline />
                        <div className="row button-row">
                            <Button onClick={handleDownloadOrders} icon="fas fa-download" tertiary>Download</Button>
                        </div>
                    </div>
                </div>*/
            case UpgradePopupType.Summary:
            case UpgradePopupType.FocusProduct:
            case UpgradePopupType.FocusPackage:
            default:
                break

        }
    }
    // return <div className={`upgrade-popup fadeIn${withNav ? ' with-nav' : ''}${withPackage ? ' with-package' : ''}${className ? ` ${className}` : ''}`} onMouseDown={handleClick} style={style} data-type={type}>
    return <div className={`upgrade-popup fadeIn${className ? ` ${className}` : ''}`} onMouseDown={handleClick} style={style} data-type={type}>
        {/* {close && showClose && <CloseButton onClick={handleClose} />} */}
        {getPopup()}
        {props.children}
    </div>
}
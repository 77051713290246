import React, { useRef, useState, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
    navigateAsync, toggleMobileMode,
} from 'actions/appActions'
import {
    ItemList,
    ClearFavouritesButton,
    Message,
    Toggle,
    FilterOptions,
    CloseButton,
    OptionBar,
} from 'components'
import {
    RootState,
    AppData,
    QueryType,
    ProjectFilter,
    FloorplanFilter,
    ListStyle,
    CustomEventType,
    MarkerType,
    ScreenSize,
    ScreenOrientation,
} from 'app/types'
import { ProjectTile } from 'views/HomePage/ProjectTile'
import { ProjectMap } from 'views/HomePage/ProjectMap'
import { NoFavourites } from 'views/HomePage/NoFavourites'

interface ProjectListProps {
    apps: AppData[],
    onlyFavourites: boolean,
    onFilter: () => void,
    onProject: () => void,
    splitIndex,
    options?: JSX.Element[],
}

const projectHeaders = [

]

export function ProjectList(props: ProjectListProps) {
    const {
        items,
        onlyFavourites,
        onFilter,
        splitIndex,
        onProject,
        options,
        breadCrumbs,
        ...otherProps
    } = {
        ...props,
    }

    const dispatch = useAppDispatch()

    const config = useAppSelector((state: RootState) => state.app.config)
    const { /*apps, favouriteApps,*/ filterRanges } = config
    const query = useAppSelector((state: RootState) => state.app.queries[QueryType.Projects])

    const favourites = useAppSelector((state: RootState) => state.user.favourites)
    const favouriteCount = Object.keys(favourites).length
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const mobileMode = useAppSelector((state: RootState) => state.app.mobileMode)

    const organization = useAppSelector((state: RootState) => state.app.organization)
    const [filteredItems, setFilteredItems] = useState(null)
    const [sortedItems, setSortedItems] = useState([])
    const [filters, setFilters] = useState([])
    const barRef = useRef()

    const mobileView = screen.isMobile || splitIndex != null || (screen.size == ScreenSize.Desktop && screen.orientation == ScreenOrientation.Portrait)
    const [focusApp, setFocusApp] = useState(null)
    const [focusOrg, setFocusOrg] = useState(null)

    useEffect(() => {
        let newSortedItems = (items && items.length > -1) ? [...items].sort((a, b) => {
            const x = a.meta.altAppLink == null || a.meta.altAppLink.length == 0
            const y = b.meta.altAppLink == null || b.meta.altAppLink.length == 0
            return (x === y) ? -1 : x ? -1 : 1;
        }) : null
        setSortedItems(newSortedItems)
    }, [items])

    useEffect(() => {
        if (focusApp != null || filteredItems == null) {
            return
        }
        const prevFocus = localStorage.getItem(`focus-${splitIndex != null ? splitIndex : 'all'}`)
        if (prevFocus != null) {
            const app = filteredItems.find((x) => x.meta.id == prevFocus)
            if (app) {
                handleProjectFocus(app)
            }
        }
    }, [filteredItems])

    useEffect(() => {
        if (filteredItems == null) {
            // setFilteredItems(items)
        }
    }, [items])

    useEffect(() => {
        let newFilters = []
        newFilters = [
            // ProjectFilter.Search,
            { filter: ProjectFilter.Builder, expanded: true },
            { filter: ProjectFilter.ProjectType },
            { filter: ProjectFilter.ProjectStatus, expanded: true },
            { filter: FloorplanFilter.Beds },
            { filter: FloorplanFilter.Sqft, expanded: true },
            { filter: FloorplanFilter.Price, expanded: true },
        ]

        if (organization == null) {
            newFilters.unshift({ type: ProjectFilter.Organization })
        }
        setFilters(newFilters)
    }, [organization])

    function handleFilter(x) {
        setFilteredItems(x)
    }

    function handleProject(x: AppData) {
        // If alt link, navigate away
        if (x.meta.altAppLink != null && x.meta.altAppLink.length > 0) {
            window.open(x.meta.altAppLink, '_blank').focus()
            return
        }

        if (splitIndex != null) {
            dispatch(navigateAsync({ app: x, options: { useCompareMode: true, splitIndex: splitIndex } }))
        } else {
            dispatch(navigateAsync({ app: x, pageType: '', options: { splitIndex: splitIndex } }))
        }
        handleProjectFocus(x, false)
    }

    function handleProjectFocus(x: AppData, dispatchEvent = true) {
        setFocusApp(x)
        localStorage.setItem(`focus-${splitIndex != null ? splitIndex : 'all'}`, x ? x.meta.id : null)

        // Scroll list
        if (!mobileView && dispatchEvent) {
            window.dispatchEvent(new CustomEvent(CustomEventType.FocusList, { detail: { id: x ? x.meta.id : null } }))
        }
        // return true
        // } else {
        // handleProject(x)
        // return false
    }

    function handleOrganizationFocus(x: OrganizationData) {
        setFocusOrg(x)
        // if (x) {
        window.dispatchEvent(new CustomEvent(CustomEventType.FocusMarker, { detail: { id: x.id, type: MarkerType.Organization } }))
        // }
    }

    function projectFilter(app: AppData) {
        if (!app) {
            return null
        }
        const matchField = (arr: string[] | number, field: string | number) => {
            let match = false
            for (let i = 0; i < arr.length; i += 1) {
                if (arr[i] === field) {
                    match = true
                    break
                }
            }
            return match
        }

        const filter = { ...query }
        const filterKeys = Object.keys(filter)
        for (let i = 0; i < filterKeys.length; i += 1) {
            const key = filterKeys[i]
            switch (key) {
                case ProjectFilter.Organization:
                    for (let j = 0; j < filter[ProjectFilter.Organization].length; j += 1) {
                        // TODO, cache this processing
                        const org = config.organizations.find((x) => x.id == filter[ProjectFilter.Organization][j])
                        if (org != null) {
                            const appSet = new Set(org.apps)
                            if (!appSet.has(app.meta.id)) {
                                return false
                            }
                        }
                    }
                    break
                case ProjectFilter.Builder:
                    if (!matchField(filter[ProjectFilter.Builder], app.meta.builderId)) {
                        return false
                    }
                    break
                case ProjectFilter.ProjectType: {
                    let match = false
                    for (let j = 0; j < app.meta.projectTypes.length; j += 1) {
                        match = match
                            || matchField(filter[ProjectFilter.ProjectType], app.meta.projectTypes[j])
                    }
                    if (!match) {
                        return false
                    }
                    break
                }
                case ProjectFilter.ProjectStatus:
                    if (!matchField(filter[ProjectFilter.ProjectStatus], app.meta.projectStatusId)) {
                        return false
                    }
                    break
                case FloorplanFilter.Beds:
                    // Check for intersection
                    if (!app.meta.stats || !app.meta.stats.beds) {
                        return false
                    }

                    const filterSet = new Set(filter[FloorplanFilter.Beds])
                    const appSet = new Set(app.meta.stats.beds)
                    const count = filterSet.intersection(appSet).size
                    if (count === 0) {
                        return false
                    }
                    break
                case FloorplanFilter.Sqft: {
                    if (!app.meta.stats || !app.meta.stats.sqft) {
                        return false
                    }

                    let inRange = false
                    for (let j = 0; j < app.meta.stats.sqft.length; j += 1) {
                        const val = app.meta.stats.sqft[j]
                        if (val >= filter[key].min && val <= filter[key].max) {
                            inRange = true
                            break
                        }
                    }

                    if (!inRange) {
                        return false
                    }
                    break
                }
                case FloorplanFilter.Price: {
                    if (!app.meta.stats || !app.meta.stats.price) {
                        return false
                    }

                    let inRange = false
                    for (let j = 0; j < app.meta.stats.price.length; j += 1) {
                        const val = app.meta.stats.price[j]
                        if (val >= filter[key].min && val <= filter[key].max) {
                            inRange = true
                            break
                        }
                    }

                    if (!inRange) {
                        return false
                    }
                    break
                }
                default:
                    break
            }
        }

        return true
    }

    function projectSort(a: AppData, b: AppData, listStyle: ListStyle, order) {
        /*if (listStyle === ListStyle.Grid) {
            return a.name.localeCompare(b.name)
        }

        try {
            const [sortField, sortDirection] = listOrder
            switch (sortField) {
            default:
                break
            case 'name':
                if (sortDirection) {
                    return a.name.localeCompare(b.name)
                }
                return b.name.localeCompare(a.name)
            case 'favourite': {
                let favA = a.id in favourites ? 1 : 0
                let favB = b.id in favourites ? 1 : 0
                if (favA === favB) {
                    return a.name.localeCompare(b.name)
                }
                return (favA > favB ? 1 : -1) * (sortDirection ? -1 : 1)
            }
            case 'sqft':
            case 'beds':
            case 'baths':
                // Fallback to name compare on match
                if (a.variations[0][sortField] == b.variations[0][sortField]) {
                    return a.name.localeCompare(b.name)
                }
                return (a.variations[0][sortField] > b.variations[0][sortField] ? 1 : -1)
                    * (sortDirection ? -1 : 1)
            case 'totalSqft':
                return ((a.variations[0].sqft + (a.variations[0].balconySqft || 0)) 
                    - (b.variations[0].sqft + (b.variations[0].balconySqft || 0))) 
                    * (sortDirection ? -1 : 1)
            }
        } catch (e) {
            logger.error('Failed to sort ', a, b)
        }*/
    }

    function handleMobileMode() {
        dispatch(toggleMobileMode())
    }

    function projectMap(x: AppMetaData, ix: number, listStyle: ListStyle, focus = false, ref = null) {
        if (!x || !x.meta) {
            return null
        }
        return <ProjectTile
            forwardRef={ref}
            key={x.meta.id}
            className={focus ? 'focus animate__animated animate__headShake' : ''}
            data-key={x.meta.id}
            app={x}
            media={x.meta.mediaId}
            square
            fadeIn
            tileStyle={listStyle}
            onClick={handleProject}
            onlyFavourites={onlyFavourites}
            minimal />
    }

    function getMap() {
        const focusIds = []
        if (focusApp) {
            focusIds.push(focusApp.meta.id)
        }
        if (focusOrg) {
            focusIds.push(focusOrg.id)
        }

        if (filteredItems == null) {
            return null
        }
        return <ProjectMap id={splitIndex ? splitIndex : 'all'} splitIndex={splitIndex} apps={filteredItems} focusIds={focusIds} onProjectFocus={handleProjectFocus} onProjectSelect={handleProject} onOrganization={handleOrganizationFocus}/>
    }

    function getFocus() {
        if (focusApp) {
            return <table className="project-focus animate__animated animate__slideInUp animate__fastest" key={focusApp.meta.id}>
                <tbody>
                    <tr className="options-row"><td colSpan={2}><CloseButton alt={false} onClick={() => handleProjectFocus(null)} /></td></tr>
                    <ProjectTile
                        key={focusApp.meta.id}
                        app={focusApp}
                        media={focusApp.meta.mediaId}
                        square
                        fadeIn
                        tileStyle={ListStyle.List}
                        onClick={handleProject}
                        onClose={() => handleProjectFocus(null)}
                        // onClick={onProject}
                        // onlyFavourites={onlyFavourites}
                        minimal
                        visit />
                </tbody>
            </table>
        }
    }

    function getBefore() {
        return <React.Fragment>
            {options}
            {mobileView && splitIndex == null && <Toggle items={[
                {
                    text: 'Map',
                    value: ListStyle.Map,
                },
                {
                    text: 'List',
                    value: ListStyle.Grid,
                },
            ]}
                value={mobileMode}
                onChange={handleMobileMode} />}
        </React.Fragment>
    }

    function getAfter() {
        if (onlyFavourites && splitIndex == null) {
            return <ClearFavouritesButton />
        }
        return null
    }

    function getMissing() {
        if (onlyFavourites) {
            return <NoFavourites />
        }
        return <Message info="No projects available" />
    }

    let refreshKey = JSON.stringify(query)
    if (onlyFavourites) {
        refreshKey += favouriteCount
    }


    let finalColumns = 2
    if (mobileView) {
        finalColumns = splitIndex == null ? 4 : 2
        // Full screen
        if (screen.size > ScreenSize.Desktop) {
            if (screen.orientation == ScreenOrientation.Landscape) {
                finalColumns = splitIndex == null ? 3 : 2
            } else {
                finalColumns = splitIndex == null ? (screen.size == ScreenSize.Tablet ? 3 : 2) : 1
            }
        } else if (screen.size == ScreenSize.Desktop && screen.orientation == ScreenOrientation.Portrait) {
            finalColumns = splitIndex == null ? 3 : 1
        }
    } else {
        // Sidebar
        if (screen.size == ScreenSize.Desktop && screen.orientation == ScreenOrientation.Portrait) {
            finalColumns = 1
        }
    }


    if (!sortedItems) {
        return null
    }

    let queryType = QueryType.Projects
    let listElem = <ItemList
        id="project-list"
        items={sortedItems}
        splitIndex={splitIndex}
        scrollReturn={false}
        title={null}
        refreshKey={refreshKey}
        filters={filters}
        filter={projectFilter}
        sort={projectSort}
        map={projectMap}
        headers={projectHeaders}
        queryType={queryType}
        filterRanges={filterRanges}
        beforeOptions={getBefore()}
        afterOptions={getAfter()}
        titleText={splitIndex != null && !screen.isMobile ? (splitIndex == 0 ? 'Select a community' : 'Select another community') : null}
        missing={getMissing()}
        columns={finalColumns}
        onFilter={handleFilter}
        breadCrumbs={false}
        {...otherProps}
    />
    // Project list with map
    // If split index or mobile, switch to Toggle view
    if (mobileView) {
        const mapStyle = {
            height: '100%'
        }
        if (barRef.current) {
            mapStyle.height = `calc(100% - ${barRef.current.clientHeight}px)`
        }

        const finalMode = (splitIndex != null && screen.size == ScreenSize.Mobile) ? ListStyle.Grid : mobileMode
        return <div className={`row project-wrapper ${mobileView ? 'mobile' : ''}`}>
            {finalMode == ListStyle.Map && <OptionBar
                app={app}
                forwardRef={barRef}
                splitIndex={splitIndex}
                compare={items.length > 1}
                titleText={splitIndex != null ? 'Select a community' : null}
                leftOptions={<FilterOptions
                    app={app}
                    filters={filters}
                    queryType={queryType}
                    ranges={filterRanges}
                    splitIndex={splitIndex}
                    expandedAsDropdown
                    listSelected />}
                rightOptions={getBefore()} />}
            {finalMode == ListStyle.Map && <div className="row" style={mapStyle}>
                {getMap(mapStyle)}
                {finalMode && (true || screen.isMobile) && <div id="project-list" className="row tile-list item-list">
                    {getFocus()}
                </div>}
                <div className="row" style={{ visibility: 'hidden' }}>
                    {listElem}
                </div>
            </div>}
            {finalMode == ListStyle.Grid && listElem}
        </div>
    }

    return <div className="row project-wrapper">
        <div className="column">
            <div className="row map">
                {getMap()}
            </div>
            {/* <div className="row tile-list item-list"> */}
            {/* {getFocus()} */}
            {/* </div> */}
        </div>
        <div className="column list-column">
            {listElem}
        </div>
    </div>
}

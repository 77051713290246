import React, { useEffect, useState, useRef } from 'react'
import {
    resolvePrompt, updateProjectCurrent,
} from 'actions/appActions'
import {
    CloseButton,
    Media,
    TopBar,
    Button,
    IconButton,
    Input,
    Tile,
    MediaTile,
    Icon,
    MediaIcon,
    GalleryTile,
} from 'components'
import {
    ErrorMessage,
    MediaType,
    ThumbSize,
} from 'app/types'
import { upsertGallery, removeGallery } from 'actions/adminActions'
import { useAppDispatch, useAppSelector, usePreviewMedia } from 'app/hooks'
import { MediaDialog } from './MediaDialog'
import * as fnc from 'helpers/fnc'
import { DropdownMenu } from 'components/Core/DropdownMenu'
import { Checkbox } from 'components/Core/Checkbox'
import { icons } from 'app/constants'

const defaultGallery = {
    media: [],
    name: 'Gallery',
    link: 'gallery',
    appId: null,
}

interface GalleryFieldEditorProps {
    name: string,
    value: string
    placeholder: string,
    onChange: () => void,
    onSelect: () => void,
}

export function GalleryFieldEditor(props: GalleryFieldEditorProps) {
    const { name, onChange, onSelect, placeholder } = props
    const [editing, setEditing] = useState(false)
    const [value, setValue] = useState(props.value)

    function handleEdit() {
        onChange(value)
        setEditing(false)
    }

    function handleCancel() {
        setValue(props.value)
        setEditing(false)
    }

    function handleSelect() {
        onSelect()
    }

    if (editing) {
        return <div className="media-field-editor editing">
            <Input value={value} placeholder={placeholder} onChange={setValue} onSubmit={handleEdit} />
            <IconButton icon={icons.check} onClick={handleEdit} />
            <IconButton icon={icons.times} onClick={handleCancel} />
        </div>
    }
    return <div className="media-field-editor" onClick={onSelect ? handleSelect : () => setEditing(true)}>
        {name && <h5>{name}:</h5>}
        <span>{value ? value : placeholder}</span>
        <IconButton icon={icons.pen} noBg onClick={(e) => { e.stopPropagation(); setEditing(true) }} />
    </div>
}


interface GalleryEditorProps {
    app: AppData,
    builder: BuilderData,
    organization: OrganizationData,
    gallery: GalleryData,
    onChange: () => void,
    onMedia: () => void,
    onDelete: () => void,
    isDialog: boolean,
}

function GalleryEditor(props: GalleryEditorProps) {
    const { app, builder, organization, gallery, onChange, onMedia, onDelete, isDialog } = props
    const [deleting, setDeleting] = useState(false)
    const [reordering, setReordering] = useState(false)
    const [tagging, setTagging] = useState(false)
    const [editData, setEditData] = useState(null)
    const dispatch = useAppDispatch()
    const media = useAppSelector((state: RootState) => state.app.media)

    if (!gallery) {
        return <h3>Missing Gallery</h3>
    }

    useEffect(() => {
        setEditData(gallery)
    }, [gallery])

    function handleDelete() {
        if (onDelete) {
            onDelete(gallery)
        }
    }

    function handleName(x) {
        const newGallery = { ...editData, name: x }
        onChange(newGallery)
    }
    function handleLink(x) {
        const newGallery = { ...editData, link: x }
        onChange(newGallery)
    }

    function handleMedia() {
        onMedia()
    }

    function handleApply() {
        setReordering(false)
        setTagging(false)
        onChange(editData)
    }

    function handleGalleryMediaReorder(fromIndex, toIndex) {
        const reordered = fnc.moveElement(editData.media, fromIndex, toIndex).map((x, ix) => ({ ...x, order: ix }))
        const newGallery = { ...editData, media: reordered }
        setEditData(newGallery)
    }

    function handleGalleryMediaTag(media, tag) {
        const idx = editData.media.findIndex((x) => x.id == media.id)
        if (idx == -1) {
            return
        }
        const newMedia = [...editData.media]
        newMedia[idx] = { ...newMedia[idx] }
        const tIdx = newMedia[idx].tags.findIndex((x) => x.galleryTagId == tag)
        newMedia[idx].tags = [...newMedia[idx].tags]
        if (tIdx != -1) {
            newMedia[idx].tags.splice(tIdx, 1)
        } else {
            newMedia[idx].tags.push({ galleryTagId: tag, galleryMediaId: newMedia[idx].id })
        }
        const newGallery = { ...editData, media: newMedia }
        setEditData(newGallery)
    }

    function handleGalleryAddTag(tag) {
        const exists = editData.tags.find((x) => x.name.toLowerCase() == tag.toLowerCase())
        if (exists) {
            return
        }
        const newTag = { id: null, name: tag }
        const newGallery = { ...editData, tags: [...editData.tags, newTag] }
        setEditData(newGallery)
    }

    function handleGalleryRemoveTag(tag) {
        const idx = editData.tags.findIndex((x) => x.name.toLowerCase() == tag.name.toLowerCase())
        if (idx == -1) {
            return
        }
        const newTags = [...editData.tags]
        newTags.splice(idx, 1)
        const newGallery = { ...editData, tags: newTags }
        setEditData(newGallery)
    }

    function handleGalleryShow(show) {
        const newGallery = { ...editData, showTags: show }
        setEditData(newGallery)
    }

    function getOptions() {
        return <React.Fragment>
            {deleting && <div className="row media-selected-item-prompt">
                <span>
                    Delete?
                    <Button onClick={handleDelete}>Yes</Button>
                    <Button onClick={() => setDeleting(false)}>No</Button>
                </span>
            </div>}
            {!deleting && <IconButton icon={icons.trash} onClick={() => setDeleting(true)} />}
            {reordering && <Button onClick={handleApply} tertiary>Save Order</Button>}
            {tagging && <Button onClick={handleApply} tertiary>Save Tags</Button>}
            {!reordering && !tagging && <div className="row">
                <Button onClick={() => setReordering(true)}>Reorder</Button>
                <Button onClick={() => setTagging(true)}>Tag</Button>
            </div>}
        </React.Fragment>
    }

    if (!editData) {
        return null
    }

    const aspect = false
    return <div className="media-selected-item">
        <div className="row media-selected-item-info">
            <div className="row" style={{ width: '100%' }}>
                <GalleryFieldEditor name="Name" value={editData.name} placeholder="Name" onSubmit={handleName} onChange={handleName} />
            </div>
            <div className="row" style={{ width: '100%' }}>
                <GalleryFieldEditor name="Link" value={editData.link} placeholder="Link" onSubmit={handleLink} onChange={handleLink} />
            </div>
            {tagging && <div className="row media-field-editor gallery-tags">
                <div className="column">
                    <div className="row">
                        <h5>Tags: </h5>
                        <Input placeholder="Add tag" onSubmit={handleGalleryAddTag} clearOnSubmit />
                        <Checkbox noBg value={editData.showTags} onChange={handleGalleryShow}>Show Tags</Checkbox>
                    </div>
                    <div className="row">
                        {editData.tags.map((x) => {
                            return <div className="gallery-tag" key={x.name}>
                                <span>{x.name}</span>
                                <DropdownMenu icon={icons.ellipsisH} buttonClass="no-bg" items={[{ text: 'Delete', value: 'delete' }]} onChange={() => handleGalleryRemoveTag(x)} />
                            </div>
                        })}
                    </div>
                </div>
            </div>}
        </div>
        <div className="media-selected-item-options">
            {getOptions()}
        </div>
        <div className={`tile-list ${reordering ? 'reordering' : ''}`}>
            {!reordering && <Tile square={!aspect} aspect={aspect} className="adder" onClick={handleMedia}><Button icon={icons.plus} >Select Media</Button></Tile>}
            {editData.media.map((x, ix) => {
                return <MediaTile app={app} builder={builder} organization={organization} square={!aspect} aspect={aspect} draggable={reordering} key={x.mediaId} order={ix} onReorder={(y) => handleGalleryMediaReorder(ix, y)} onClick={!reordering ? handleMedia : null} media={media[x.mediaId]} hoverShow={!reordering}>
                    {tagging && editData.tags.filter((x) => x.id != null).length > 0 && <DropdownMenu selectText="Tag" value={x.tags.map((y) => y.galleryTagId)} items={editData.tags.filter((x) => x.id != null).map((y) => ({ text: y.name, value: y.id }))} onChange={(y) => handleGalleryMediaTag(x, y)} />}
                </MediaTile>
            })}
        </div>
    </div>
}

interface GalleryDialogProps {
    app: AppData,
    builder: BuilderData,
    organization: OrganizationData,
    onClose?: () => void,
    onDisableClose: () => void,
    onResult: () => void,
    animation?: string,
    selected: string,
}

export function GalleryDialog(props: GalleryDialogProps) {
    const {
        builder,
        organization,
        onClose,
        onDisableClose,
        onResult,
        animation,
        selected,
        multi,
    } = {
        multi: false,
        ...props,
    }
    const app = useAppSelector((state: RootState) => state.admin.project?.id == props.app?.id ? state.admin.project : props.app)
    const allMedia = useAppSelector((state: RootState) => state.app.media)
    const config = useAppSelector((state: RootState) => state.admin.config)
    const media = useAppSelector((state: RootState) => state.app.media)
    const [editingGallery, setEditingGallery] = useState(null)
    const dispatch = useAppDispatch()
    let galleries = []
    if (app) {
        galleries = app.galleries
    } else if (builder) {
        galleries = builder.galleries
    } else if (organization) {
        galleries = organization.galleries
    }

    const [selectedGallery, setSelectedGallery] = useState(() => {
        // Find from id
        if (selected && galleries) {
            return galleries.find((x) => x.id == selected)
        }
        return null
    })
    const listRef = useRef()
    const isDialog = onClose != null

    useEffect(() => {
        if (selectedGallery != null) {
            const updatedSelected = galleries.find((x) => x.id == selectedGallery.id)
            setSelectedGallery(updatedSelected)
        }
    }, [galleries])

    if (editingGallery && selectedGallery) {
        return <MediaDialog multi selected={selectedGallery.media.map((x) => x.mediaId)} app={app} builder={builder} organization={organization} onSelect={handleGalleryMedia} onClose={() => setEditingGallery(false)} mediaTypeId={[MediaType.Image, MediaType.Video, MediaType.Tour, MediaType.Spin]} />
    }

    function handleClose() {
        onClose()
    }

    function handleSelectGallery(x, e, force) {
        if (!force && selectedGallery && selectedGallery.id == x.id) {
            setSelectedGallery(null)
        } else {
            setSelectedGallery(x)
            if (force) {
                handleSelect()
            }
        }
    }

    function handleSelect(x, e, force) {
        if (onResult) {
            onResult(selectedGallery ? selectedGallery.id : 'none')
        }
        // dispatch(resolvePrompt(selectedGallery ? selectedGallery.id : 'none'))
        // if (onClose) {
        // onClose()
        // }
    }

    function handleChange(x) {
        dispatch(upsertGallery({ data: x, options: { app, builder, organization } }))
    }

    function handleAddGallery() {
        const newGallery = { ...defaultGallery, name: `Gallery ${galleries.length + 1}` }
        handleChange(newGallery)
    }

    function handleDeleteGallery(x) {
        if (x == selectedGallery) {
            setSelectedGallery(null)
        }
        dispatch(removeGallery({ data: x, options: { app, builder, organization } }))
    }

    function handleSelectMedia() {
        setEditingGallery(true)
    }

    function handleGalleryMedia(mediaIds) {
        const existingMap = selectedGallery.media.reduce((acc, x) => ({ ...acc, [x.mediaId]: x }), {})
        const newMedia = []
        mediaIds.forEach((x, ix) => {
            if (x in existingMap) {
                // Get existing
                const elem = { ...existingMap[x], order: ix }
                newMedia.push(elem)
            } else {
                newMedia.push({ id: null, mediaId: x, tags: [], order: ix })
            }
        })
        // setEditingGallery(false)
        const newGallery = { ...selectedGallery, media: newMedia }
        setSelectedGallery(newGallery)
        handleChange(newGallery)
    }


    function getGalleries() {
        let scrolledTo = false
        return <div className="media-list scrollable" ref={listRef}>
            {galleries.map((x) => {
                return <GalleryTile key={x.id} app={app} builder={builder} organization={organization} gallery={x} isDialog={isDialog} selected={selectedGallery == x} listRef={listRef} onClick={handleSelectGallery} scrollTo={x.id == selected} />
            })}
        </div>
    }

    function getSelected() {
        return <React.Fragment>
            {selectedGallery && <GalleryEditor key={selectedGallery.id} app={app} builder={builder} organization={organization} gallery={selectedGallery} onMedia={handleSelectMedia} onDelete={handleDeleteGallery} onChange={handleChange} isDialog={isDialog} />}
            {!selectedGallery && <h4>Select Gallery</h4>}
            <div className="media-options">
                {isDialog && <Button icon={icons.check} secondary onClick={handleSelect}>Select Gallery</Button>}
            </div>
        </React.Fragment>
    }
    return <React.Fragment>
        <div className={`media-browser gallery-browser ${animation}`} onMouseDown={(e) => e.stopPropagation()}>
            <TopBar>
                <div className="options left">
                    <h4>{app ? `${app.meta.name} Galleries` : 'Galleries'}</h4>
                </div>
                <div className="options right">
                    <Button alt icon={icons.plus} onClick={handleAddGallery}>Add Gallery</Button>
                    {onClose && <CloseButton fadeIn onClick={handleClose} />}
                </div>
            </TopBar>
            {galleries.length > 0 && <div className="row">
                <div className="column col-6">
                    {getGalleries()}
                </div>
                <div className="column col-6 media-selected scrollable">
                    {getSelected()}
                </div>
            </div>}
            {galleries.length == 0 && <div className="row">
                <h4>No galleries added</h4>
            </div>}
            {/*<div className="gallery-options">
                {selectedGallery > 0 && <Button icon={icons.check} secondary onClick={handleSelect}>Select Media</Button>}
            </div>*/}
        </div>
    </React.Fragment>
}

import React, { useEffect, useState, useRef } from 'react'
import { IconButton, Icon } from 'components'
import { CustomEventType, SitemapUnitData } from 'app/types'
import { useAppSelector } from 'app/hooks'
import { transformUnitName } from 'app/transformers'

interface SitemapUnitProps {
    app: AppData,
    editing: boolean,
    inTray: boolean,
    unit: SitemapUnitData
    data: UnitData,
    color: string,
    placed: boolean,
    parentRef: React.RefObject,
    forwardRef: React.RefObject,
    sitemapRef: React.RefObject,
    focused: boolean,
    onPlace: () => void,
    onClick: () => void,
    onDelete: () => void,
    forceDrag: boolean,
    size: number,
    className: string,
    showColor: boolean,
    sitemap: SitemapData,
}

export function SitemapUnit(props: SitemapUnitProps) {
    const { inTray, editing, placed, unit, data, parentRef, forwardRef, sitemapRef, color, focused, onPlace, onClick, onDelete, forceDrag, size, className, showColor, sitemap, app } = props
    const [dragging, setDragging] = useState(false)
    const [drag, setDrag] = useState(null)
    const [dragOffset, setDragOffset] = useState(null)
    const [unitRef, setUnitRef] = useState(null)
    const [arrowPlacement, setArrowPlacement] = useState(null)
    const screen = useAppSelector((state: RootState) => state.app.screen)

    const snap = useRef(null)
    const lock = useRef(null)
    const clickTimeout = useRef(null)

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        document.addEventListener('keyup', handleKeyUp)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
            document.removeEventListener('keyup', handleKeyUp)
        }
    }, [])

    useEffect(() => {
        if (dragging || forceDrag) {
            document.addEventListener('mousemove', handleMouseMove)
            document.addEventListener('mouseup', handleMouseUp)

            return () => {
                document.removeEventListener('mousemove', handleMouseMove)
                document.removeEventListener('mouseup', handleMouseUp)
            }
        }
    }, [dragging, forceDrag, unitRef])

    useEffect(() => {
        if (!focused || arrowPlacement != null) {
            return
        }
        // Determine position focus arrow can be placed

        // Check all other units, see if any are immediately left / right / above / below
        const sizePercentage = 1
        const width = sizePercentage * size / 100

        const threshold = (10 * width)
        let closest = { below: 100000, right: 100000, above: 100000, left: 100000 }
        const [x, y] = unit.position.split(',').map(parseFloat)
        sitemap?.units.forEach((z) => {
            if (z.unitId == unit.unitId) {
                return
            }
            const [xB, yB] = z.position.split(',').map(parseFloat)
            const difX = xB - x
            const difY = yB - y
            if (Math.abs(difY) < threshold*0.25 && difX >= 0 && difX < closest.right) {
                closest.right = difX
            }
            if (Math.abs(difY) < threshold*0.25 && difX < 0 && -difX < closest.left) {
                closest.left = -difX
            }
            if (Math.abs(difX) < threshold*0.25 && difY >= 0 && difY < closest.below) {
                closest.below = difY
            }
            if (Math.abs(difX) < threshold*0.25 && difY < 0 && -difY < closest.above) {
                closest.above = -difY
            }
        })
        // Check below first
        let finalPlacement = 'below'
        if (closest.below > threshold) {
            finalPlacement = 'below'
        } else if (closest.right > threshold) {
            finalPlacement = 'right'
        } else if (closest.above > threshold) {
            finalPlacement = 'above'
        } else if (closest.left > threshold) {
            finalPlacement = 'left'
        }
        setArrowPlacement(finalPlacement)

    }, [focused, unit, sitemap, sitemapRef])

    function handleRef(x) {
        setUnitRef(x)
        if (forwardRef) {
            forwardRef.current = x
        }
    }

    function handleKeyDown(e) {
        if (e.key == 'x') {
            lock.current = 'x'
        } else if (e.key == 'y') {
            lock.current = 'y'
        } else if (e.key == 'Shift') {
            snap.current = true
        }
    }

    function handleKeyUp(e) {
        if (e.key == 'x' || e.key == 'y') {
            lock.current = null
        } else if (e.key == 'Shift') {
            snap.current = false
        }
    }

    function getSnapLockedPosition(newX, newY) {
        if (snap.current || lock.current) {
            const resolution = snap.current ? size / 7.5 : 1
            if (!lock.current || lock.current == 'x') {
                newX = Math.floor((newX - dragOffset.x) / resolution) * resolution + dragOffset.x
                if (lock.current == 'x') {
                    if (drag) {
                        newY = drag.y
                    }
                }
            }
            if (!lock.current || lock.current == 'y') {
                newY = Math.floor((newY - dragOffset.y) / resolution) * resolution + dragOffset.y
                if (lock.current == 'y') {
                    if (drag) {
                        newX = drag.x
                    }
                }
            }
        }
        return { x: newX, y: newY }
    }

    function transformToSitemap(pageX, pageY) {
        let rect = unitRef.getBoundingClientRect()
        let x = pageX - (dragOffset ? dragOffset.x : rect.width / 2) + rect.width / 2
        let y = pageY - (dragOffset ? dragOffset.y : rect.height / 2) + rect.height / 2
        rect = sitemapRef.current.getBoundingClientRect()
        x = Math.floor(100000 * (x - rect.x) / rect.width) / 1000.0
        y = Math.floor(100000 * (y - rect.y) / rect.height) / 1000.0

        return [x, y]
    }

    function handleMouseDown(e) {
        e.stopPropagation()
        if (e.button == 2) {
            return
        }

        if (clickTimeout.current) {
            return
        }

        // Prevent double click
        clickTimeout.current = setTimeout(() => {
            clickTimeout.current = null
        }, 100)

        if (!editing) {
            handleClick(e)
            return
        }

        setDragging(true)
        const rect = unitRef.getBoundingClientRect()
        setDragOffset({ x: e.pageX - rect.x, y: e.pageY - rect.y })
        handleMouseMove(e)
    }

    function handleMouseMove(e) {
        e.stopPropagation()
        let newX = 0
        let newY = 0
        if (parentRef && parentRef.current) {
            const rect = parentRef.current.getBoundingClientRect()
            newX = e.pageX - rect.x
            newY = e.pageY - rect.y
        } else {
            newX = e.pageX
            newY = e.pageY
        }

        setDrag(getSnapLockedPosition(newX, newY))
    }

    function handleMouseUp(e) {
        e.stopPropagation()
        setDragging(false)
        setDrag(null)
        if (onPlace && editing) {
            // Calculate center position of unit

            let newX = e.pageX
            let newY = e.pageY
            const pos = getSnapLockedPosition(newX, newY)
            const [x, y] = transformToSitemap(pos.x, pos.y)
            if (x > 0 && x < 100 && y > 0 && y < 100) {
                onPlace(data, x, y)
            }
        }
    }

    function handleClick(e) {
        if (onClick) {
            onClick(e)
        }
    }

    function handleDelete() {
        if (onDelete) {
            onDelete(data)
        }
    }

    // let styleB = { fontSize: `${size}%`, padding: `${15 * size / 200}px` }
    // let styleA = { transform: `translate(-50%, -50%) scale(${size / 200})` }
    let styleA = {}
    let labelStyle = {}
    let circleStyle = {}

    // Set style based on image width
    if (sitemapRef.current) {
        // As a percentage of the width?
        const sizePercentage = 0.025
        const fontPercentage = 0.7
        const scaleFix = 1
        const width = scaleFix * sitemapRef.current.offsetWidth * sizePercentage * size / 100
        styleA.width = `${width}px`
        styleA.maxWidth = `${width}px`
        styleA.minWidth = `${width}px`
        styleA.height = `${width}px`
        styleA.maxHeight = `${width}px`
        styleA.minHeight = `${width}px`

        // labelStyle.fontSize = `${width * fontPercentage}px`
        // Slightly scale text down on mobile
        labelStyle.fontSize = `${width * fontPercentage * (screen.isMobile ? 0.8 : 1)}px`
        // window.dispatchEvent(new CustomEvent(CustomEventType.DebugMessage, { detail: `Width ${width}` }))
        // labelStyle.zoom = 0.1
        // labelStyle.transform = 'translate(-50%, -50%, 0)'
        // labelStyle.textRendering = 'optimizeLegibility'
        if (scaleFix != 1) {
            labelStyle.transform = `scale(${1 / scaleFix})`
            circleStyle.transform = `scale(${1 / scaleFix})`
        }
        if (!showColor) {
            circleStyle.backgroundColor = 'var(--tertiary-mid)';
        } else {
            circleStyle.backgroundColor = `#${color}`
        }
    }

    if (inTray) {
        let width = parentRef.current ? parentRef.current.offsetWidth * 0.25 - 15 : 0
        let styleB = {
            width: `${width}px`,
            minWidth: `${width}px`,
            height: `${width}px`,
            minHeight: `${width}px`,
        }
        let fontStyle = { fontSize: `${200 * width / 60}%` }

        if ((dragging || forceDrag) && drag) {
            const style = {}
            style.position = 'absolute'
            let rect = unitRef.getBoundingClientRect()
            style.left = `${drag.x - (dragOffset ? dragOffset.x : rect.width / 2)}px`
            style.top = ` ${drag.y - (dragOffset ? dragOffset.y : rect.height / 2)}px`

            return <React.Fragment>
                <div className={`sitemap-unit${dragging || placed || forceDrag ? ' placed' : ''}`}
                    onTouchStart={handleMouseDown}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    ref={handleRef}
                    style={styleB}>
                    <span className="circle" style={{ backgroundColor: `#${color}` }} />
                    <span className="label noselect" style={fontStyle}>{data.name}</span>
                </div>
                <div className="sitemap-unit dragging"
                    onMouseUp={handleMouseUp}
                    style={{ ...style, ...styleB }}>
                    <span className="circle" style={{ backgroundColor: `#${color}` }} />
                    <span className="noselect label" style={fontStyle}>{data.name}</span>
                </div>
            </React.Fragment>
        }

        return <div className={`sitemap-unit${dragging || placed ? ' placed' : ''}`}
            onTouchStart={handleMouseDown}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            ref={handleRef}
            style={styleB}>
            <span className="circle" style={{ backgroundColor: `#${color}` }} />
            <span className="noselect label" style={fontStyle}>{data.name}</span>
        </div>
    }

    let x, y = null
    // Assumes parent is sitemap
    if (dragging && drag && dragOffset) {
        [x, y] = transformToSitemap(drag.x, drag.y)
    } else {
        [x, y] = unit.position.split(',').map(parseFloat)
    }
    const name = transformUnitName(app, data)
    return <div
        key={data.id}
        className={`sitemap-unit${focused ? ' selected' : ''}${className != null ? ` ${className}` : ''}`}
        style={{ left: `${x}%`, top: `${y}%`, ...styleA }}
        // onClick={handleClick}
        onClick={(e) => e.stopPropagation()}
        onTouchStart={handleMouseDown}
        onTouchEnd={(e) => e.stopPropagation()}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        ref={handleRef}>
        <span className={`circle${focused ? ' sitemap-blink' : ''}`} style={circleStyle} />
        <span className="noselect label" style={labelStyle}>{name}</span>
        {editing && onDelete && <IconButton icon="fas fa-times" style={{ transform: `scale(${size / 250})` }} onClick={handleDelete} />}
        {focused && <Icon className="close-button" icon="fas fa-times"/>}
        {focused && <div className={`focus-arrow fadeIn ${arrowPlacement != null ? arrowPlacement : 'below'}`}>
            {arrowPlacement == 'above' && <Icon className="bounce-vertical" icon="fas fa-arrow-down" noBg />}
            {(arrowPlacement == 'below' || arrowPlacement == null) && <Icon className="bounce-vertical" icon="fas fa-arrow-up" noBg />}
            {arrowPlacement == 'left' && <Icon className="bounce-horizontal" icon="fas fa-arrow-right" noBg />}
            {arrowPlacement == 'right' && <Icon className="bounce-horizontal" icon="fas fa-arrow-left" noBg />}
            {/* <Icon className="bounce-vertical" icon="fas fa-arrow-up" noBg /> */}
        </div>}
    </div>
}

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { IconButton } from 'components'
import warning from 'warning';

import ZoomButtons from './ZoomButtons'
import DebugView from './StateDebugView';

import { snapToTarget, negate, constrain, getPinchLength, getPinchMidpoint, getRelativePosition, setRef, isEqualDimensions, getDimensions, getContainerDimensions, isEqualTransform, getAutofitScale, getMinScale, tryCancelEvent, getImageOverflow, trySurpressEvent } from './Utils';
import { CustomEventType } from 'app/types';
import { logger } from 'helpers/logger';
import { icons } from 'app/constants';

const OVERZOOM_TOLERANCE = 0.05;
const DOUBLE_TAP_THRESHOLD = 250;
const SINGLE_TAP_THRESHOLD = 250;
const SINGLE_TAP_PAN_THRESHOLD = 20;
const ANIMATION_SPEED = 0.2;

const isInitialized = (top, left, scale) => scale !== undefined && left !== undefined && top !== undefined;

const imageStyle = createSelector(
    state => state.top,
    state => state.left,
    state => state.scale,
    (top, left, scale) => {
        const style = {
            cursor: 'pointer',
        };
        return isInitialized(top, left, scale)
            ? {
                ...style,
                // transform: `translate3d(${left}px, ${top}px, 0) scale(${scale})`,
                transform: `translate3d(${Math.round(left)}px, ${Math.round(top)}px, 0) scale(${scale})`,
                transformOrigin: '0 0',
            } : style;
    }
);

const imageOverflow = createSelector(
    state => state.top,
    state => state.left,
    state => state.scale,
    state => state.imageDimensions,
    state => state.containerDimensions,
    (top, left, scale, imageDimensions, containerDimensions) => {
        if (!isInitialized(top, left, scale)) {
            return '';
        }
        return getImageOverflow(top, left, scale, imageDimensions, containerDimensions);
    }
);

const browserPanActions = createSelector(
    imageOverflow,
    (imageOverflow) => {
        //Determine the panning directions where there is no image overflow and let
        //the browser handle those directions (e.g., scroll viewport if possible).
        //Need to replace 'pan-left pan-right' with 'pan-x', etc. otherwise 
        //it is rejected (o_O), therefore explicitly handle each combination.
        const browserPanX =
            !imageOverflow.left && !imageOverflow.right ? 'pan-x' //we can't pan the image horizontally, let the browser take it
                : !imageOverflow.left ? 'pan-left'
                    : !imageOverflow.right ? 'pan-right'
                        : '';
        const browserPanY =
            !imageOverflow.top && !imageOverflow.bottom ? 'pan-y'
                : !imageOverflow.top ? 'pan-up'
                    : !imageOverflow.bottom ? 'pan-down'
                        : '';
        return [browserPanX, browserPanY].join(' ').trim();
    }
);

//Ensure the image is not over-panned, and not over- or under-scaled.
//These constraints must be checked when image changes, and when container is resized.
export default class PinchZoomPan extends React.Component {

    lastPointerUpTimeStamp; //enables detecting double-tap
    pointerDownTimeStamp; //enables detecting single-tap
    lastPointerDownPosition; //helps determine how far to pan the image
    lastPanPointerPosition; //helps determine how far to pan the image
    lastPinchLength; //helps determine if we are pinching in or out
    animation; //current animation handle
    imageRef; //image element
    isImageLoaded; //permits initial transform
    originalOverscrollBehaviorY; //saves the original overscroll-behavior-y value while temporarily preventing pull down refresh

    zoomedIn = false;
    maxScale = 1;
    autoScale = 1;

    constructor(props) {
        super(props)
        this.state = {};
        this.toggleZoom = this.toggleZoom.bind(this)
        this.handleZoom = this.handleZoom.bind(this)
        this.handleZoomOut = this.handleZoomOut.bind(this)
    }


    //event handlers
    handleTouchStart = event => {
        this.cancelAnimation();
        this.down = true

        const touches = event.touches;
        if (touches.length === 2) {
            this.lastPinchLength = getPinchLength(touches);
            this.lastPanPointerPosition = null;
        }
        else if (touches.length === 1) {
            this.lastPinchLength = null;
            this.pointerDown(touches[0]);
            this.pointerDownTimeStamp = event.timeStamp
            tryCancelEvent(event); //suppress mouse events
        }
    }

    handleTouchMove = event => {
        const touches = event.touches;
        if (touches.length === 2) {
            this.pinchChange(touches);

            //suppress viewport scaling on iOS
            tryCancelEvent(event);
        }
        else if (touches.length === 1) {
            const requestedPan = this.pan(touches[0]);

            if (!this.controlOverscrollViaCss) {
                //let the browser handling panning if we are at the edge of the image in 
                //both pan directions, or if we are primarily panning in one direction
                //and are at the edge in that directino
                const overflow = imageOverflow(this.state);
                const hasOverflowX = (requestedPan.left && overflow.left > 0) || (requestedPan.right && overflow.right > 0);
                const hasOverflowY = (requestedPan.up && overflow.top > 0) || (requestedPan.down && overflow.bottom > 0);

                if (!hasOverflowX && !hasOverflowY) {
                    //no overflow in both directions
                    return;
                }

                const panX = requestedPan.left || requestedPan.right;
                const panY = requestedPan.up || requestedPan.down;
                if (panY > 2 * panX && !hasOverflowY) {
                    //primarily panning up or down and no overflow in the Y direction
                    return;
                }

                if (panX > 2 * panY && !hasOverflowX) {
                    //primarily panning left or right and no overflow in the X direction
                    return;
                }

                tryCancelEvent(event);
            }
        }
    }

    handleTouchEnd = event => {
        this.cancelAnimation();
        this.down = false
        if (event.touches.length === 0 && event.changedTouches.length === 1) {
            if (this.lastPointerUpTimeStamp && this.lastPointerUpTimeStamp + DOUBLE_TAP_THRESHOLD > event.timeStamp) {
                const pointerPosition = getRelativePosition(event.changedTouches[0], this.imageRef.parentNode);
                this.doubleClick(pointerPosition);
            } else if (this.pointerDownTimeStamp + SINGLE_TAP_THRESHOLD > event.timeStamp) {
                const pointerPosition = getRelativePosition(event.changedTouches[0], this.imageRef.parentNode);
                const pointerPositionScreen = { x: event.changedTouches[0].clientX, y: event.changedTouches[0].clientY }

                if (this.lastPointerDownPosition) {
                    const translateX = pointerPositionScreen.x - this.lastPointerDownPosition.x;
                    const translateY = pointerPositionScreen.y - this.lastPointerDownPosition.y;
                    if ((Math.abs(translateX) + Math.abs(translateY)) < SINGLE_TAP_PAN_THRESHOLD) {
                        this.singleClick(pointerPosition)
                    }
                }
            }
            this.lastPointerUpTimeStamp = event.timeStamp;
            tryCancelEvent(event); //suppress mouse events
        }

        //We allow transient +/-5% over-pinching.
        //Animate the bounce back to constraints if applicable.
        this.maybeAdjustCurrentTransform(ANIMATION_SPEED);
        return;
    }

    handleMouseDown = event => {
        this.cancelAnimation();
        this.pointerDown(event);
        this.pointerDownTimeStamp = event.timeStamp
        if (this.props.cancelMouseDown) {
            event.stopPropagation()
        }

        this.down = true
    }


    handleMouseUp = event => {
        this.down = false
        if (this.pointerDownTimeStamp + SINGLE_TAP_THRESHOLD > event.timeStamp) {
            const pointerPosition = getRelativePosition(event, this.imageRef.parentNode);
            const pointerPositionScreen = { x: event.clientX, y: event.clientY }

            if (this.lastPointerDownPosition) {
                const translateX = pointerPositionScreen.x - this.lastPointerDownPosition.x;
                const translateY = pointerPositionScreen.y - this.lastPointerDownPosition.y;
                if ((Math.abs(translateX) + Math.abs(translateY)) < SINGLE_TAP_PAN_THRESHOLD) {
                    this.singleClick(pointerPosition)
                }
            }
        }
    }

    handleMouseMove = event => {
        if (!event.buttons || !this.down) return null;
        this.pan(event)
    }

    handleMouseSingleClick = event => {
        trySurpressEvent(event)
        // this.cancelAnimation();
        var pointerPosition = getRelativePosition(event, this.imageRef.parentNode);
        this.doubleClick(pointerPosition);
    }

    handleMouseDoubleClick = event => {
        return
        this.cancelAnimation();
        var pointerPosition = getRelativePosition(event, this.imageRef.parentNode);
        this.doubleClick(pointerPosition);
    }

    handleMouseWheel = event => {
        this.cancelAnimation();
        const point = getRelativePosition(event, this.imageRef.parentNode);
        if (event.deltaY > 0) {
            if (this.state.scale > getMinScale(this.state, this.props)) {
                this.zoomOut(point);
                tryCancelEvent(event);
            }
        } else if (event.deltaY < 0) {
            if (this.state.scale < this.maxScale) {
                this.zoomIn(point);
                tryCancelEvent(event);
            }
        }

    }

    handleImageLoad = (event, skipPropagation = false) => {
        this.debug('handleImageLoad');
        this.isImageLoaded = true;
        this.maybeHandleDimensionsChanged();
        const { onLoad } = React.Children.only(this.props.children).props;
        if (typeof onLoad === 'function') {
            onLoad(event, false);
        }
    }

    handleZoomInClick = () => {

        this.cancelAnimation();
        this.zoomIn();
    }

    handleZoomOutClick = () => {
        this.cancelAnimation();
        this.zoomOut();
    }

    handleWindowResize = () => {
        this.maybeHandleDimensionsChanged();
    }

    handleZoom = (e) => {
        if (!this.state.containerDimensions) {
            return
        }
        let { center, force } = e.detail
        const { imageDimensions, containerDimensions } = this.state;
        if ('x' in center) {
            const dim = imageDimensions
            // TODO: Calculate center based on percentage center
            if (!this.zoomedIn) {
                const x = dim.width * center.x
                const y = dim.height * center.y
                this.zoomIn({ x, y }, 0.2, this.props.maxZoom / 4, this.props.forceZoomCenter)
            } else {
                // Pan to
                const x = dim.width * center.x
                const y = dim.height * center.y
                this.centerOn({ x, y }, force)
            }
        } else if ('minX' in center) {
            if (!this.zoomedIn) {
                let dim = imageDimensions
                const centerX = dim.width * (center.minX + center.maxX) / 2.0
                const centerY = dim.height * (center.minY + center.maxY) / 2.0
                // Calculate zoom factor that will frame the bounds
                const spanX = (center.maxX - center.minX) * dim.width + this.props.zoomBuffer
                const spanY = (center.maxY - center.minY) * dim.height + this.props.zoomBuffer
                const targetScaleX = dim.width / spanX
                const targetScaleY = dim.height / spanY
                const targetScale = Math.min(targetScaleX, targetScaleY)
                // this.zoom(this.state.scale * (1 + factor), midpoint, 0, speed);
                // targetScale = this.state.scale * (1 + factor), solve for factor
                // targetScale = this.state.scale + this.state.scale*factor
                const factor = (targetScale - this.state.scale) / this.state.scale
                this.zoomIn({ x: centerX, y: centerY }, 0.2, factor, this.props.forceZoomCenter)
            } else {
                let dim = imageDimensions
                const centerX = dim.width * (center.minX + center.maxX) / 2.0
                const centerY = dim.height * (center.minY + center.maxY) / 2.0
                this.centerOn({ x: centerX, y: centerY })
            }
        }
    }

    handleZoomOut() {
        if (!this.state.containerDimensions) {
            return
        }
        const midpoint = {
            x: this.state.containerDimensions.width / 2,
            y: this.state.containerDimensions.height / 2
        }
        this.zoom(this.autoScale, midpoint, 0, 0.5)
    }

    handleRefImage = ref => {
        if (this.imageRef) {
            return
        }

        if (this.imageRef && this.imageRef != ref) {
            this.cancelAnimation();
            this.imageRef.removeEventListener('touchmove', this.handleTouchMove);
        }

        this.imageRef = ref;
        if (ref) {
            this.imageRef.addEventListener('touchmove', this.handleTouchMove, { passive: false });
        }

        const { ref: imageRefProp } = React.Children.only(this.props.children);
        setRef(imageRefProp, ref);
    };

    //actions
    pointerDown(clientPosition) {
        this.lastPanPointerPosition = getRelativePosition(clientPosition, this.imageRef.parentNode);
        this.lastPointerDownPosition = { x: clientPosition.clientX, y: clientPosition.clientY }
    }

    pan(pointerClientPosition) {
        if (!this.isTransformInitialized) {
            return;
        }

        if (!this.lastPanPointerPosition) {
            //if we were pinching and lifted a finger
            this.pointerDown(pointerClientPosition);
            return 0;
        }

        const pointerPosition = getRelativePosition(pointerClientPosition, this.imageRef.parentNode);
        const translateX = pointerPosition.x - this.lastPanPointerPosition.x;
        const translateY = pointerPosition.y - this.lastPanPointerPosition.y;
        this.lastPanPointerPosition = pointerPosition;

        const top = this.state.top + translateY;
        const left = this.state.left + translateX;
        this.constrainAndApplyTransform(top, left, this.state.scale, 0, 0);

        return {
            up: translateY > 0 ? translateY : 0,
            down: translateY < 0 ? negate(translateY) : 0,
            right: translateX < 0 ? negate(translateX) : 0,
            left: translateX > 0 ? translateX : 0,
        };
    }

    singleClick(pointerPosition) {
        if (!this.props.tapToZoom) {
            if (this.props.onClick) {
                this.props.onClick()
            }
            return
        }
        if (this.props.onClick) {
            this.props.onClick()
            if (this.zoomedIn) {
                this.toggleZoom(pointerPosition, 0)
            }
        } else {
            this.toggleZoom(pointerPosition)
        }
    }

    doubleClick(pointerPosition) {
        // this.toggleZoom()
        /*if (String(this.props.doubleTapBehavior).toLowerCase() === 'zoom' && this.state.scale * (1 + OVERZOOM_TOLERANCE) < this.props.maxScale) {
            this.zoomIn(pointerPosition, ANIMATION_SPEED, 0.5);
        } else {
            //reset
            this.applyInitialTransform(ANIMATION_SPEED);
        }*/

    }

    toggleZoom(position = null, speed = 0.5) {
        // this.cancelAnimation();
        const midpoint = position ? position : {
            x: this.state.containerDimensions.width / 2,
            y: this.state.containerDimensions.height / 2
        };
        if (!this.zoomedIn) {
            const { imageDimensions, containerDimensions } = this.state;
            const newZoomScale = this.autoScale + (this.maxScale - this.autoScale) * this.props.zoomInc
            this.zoom(newZoomScale, midpoint, 0, speed)
        } else {
            const { imageDimensions, containerDimensions } = this.state;
            this.zoom(this.autoScale, midpoint, 0, speed)
        }
    }

    pinchChange(touches) {
        const length = getPinchLength(touches);
        const midpoint = getPinchMidpoint(touches);
        const scale = this.lastPinchLength
            ? this.state.scale * length / this.lastPinchLength //sometimes we get a touchchange before a touchstart when pinching
            : this.state.scale;

        this.zoom(scale, midpoint, OVERZOOM_TOLERANCE);

        this.lastPinchLength = length;
    }

    centerOn(position, force=false) {
        const { x, y } = position

        const { scale, top, left } = this.state;
        let actualLeft = -x * scale
        let actualTop = -y * scale
        let halfWidth = (this.state.containerDimensions.width / 2)
        let halfHeight = (this.state.containerDimensions.height / 2)
        const newTarget = {
            x: actualLeft + halfWidth,
            y: actualTop + halfHeight,
        }

        // Only center if far enough away
        if (force || Math.abs(newTarget.y - top) > halfHeight * 0.5 || Math.abs(newTarget.x - left) > halfWidth * 0.5) {
            this.constrainAndApplyTransform(newTarget.y, newTarget.x, scale, 0, 0.5);
        }
    }

    zoomIn(midpoint, speed = 0, factor = 0.2, forceZoomCenter = false) {
        midpoint = midpoint || {
            x: this.state.containerDimensions.width / 2,
            y: this.state.containerDimensions.height / 2
        };
        this.zoom(this.state.scale * (1 + factor), midpoint, 0, speed, forceZoomCenter);
    }

    zoomOut(midpoint) {
        midpoint = midpoint || {
            x: this.state.containerDimensions.width / 2,
            y: this.state.containerDimensions.height / 2
        };
        this.zoom(this.state.scale * 0.8, midpoint, 0);
    }

    zoom(requestedScale, containerRelativePoint, tolerance, speed = 0, forceZoomCenter = false) {
        if (!this.isTransformInitialized) {
            return;
        }

        // Old "center" calculation
        const { scale, top, left } = this.state;

        const nextScale = this.getConstrainedScale(requestedScale, tolerance);
        if (!forceZoomCenter) {
            const imageRelativePoint = {
                top: containerRelativePoint.y - top,
                left: containerRelativePoint.x - left,
            };

            const incrementalScalePercentage = (nextScale - scale) / scale;
            const translateY = imageRelativePoint.top * incrementalScalePercentage;
            const translateX = imageRelativePoint.left * incrementalScalePercentage;
            const nextTop = top - translateY;
            const nextLeft = left - translateX;
            this.constrainAndApplyTransform(nextTop, nextLeft, nextScale, tolerance, speed);
        } else {
            const actualLeft = -containerRelativePoint.x * nextScale
            const actualTop = -containerRelativePoint.y * nextScale
            const halfWidth = (this.state.containerDimensions.width / 2)
            const halfHeight = (this.state.containerDimensions.height / 2)
            const newTarget = {
                x: actualLeft + halfWidth,
                y: actualTop + halfHeight,
            }
            this.constrainAndApplyTransform(newTarget.y, newTarget.x, nextScale, tolerance, speed);
        }

        const minScale = getMinScale(this.state, this.props);
        this.zoomedIn = nextScale > minScale
        if (this.props.onZoom) {
            this.props.onZoom(this.zoomedIn)
        }
    }

    //compare stored dimensions to actual dimensions; capture actual dimensions if different
    maybeHandleDimensionsChanged(fromUpdate = false) {
        if (this.isImageReady) {
            const containerDimensions = getContainerDimensions(this.imageRef);
            let imageDimensions = getDimensions(this.imageRef);
            // Wait for image to initialize
            if (imageDimensions.width == 0 || imageDimensions.height == 0) {
                return
            }

            if (!isEqualDimensions(containerDimensions, getDimensions(this.state.containerDimensions)) ||
                !isEqualDimensions(imageDimensions, getDimensions(this.state.imageDimensions))) {
                this.cancelAnimation();

                //capture new dimensions
                let newState = { containerDimensions, imageDimensions }
                if (fromUpdate) {
                    newState.lastUpdate = Date.now()
                }

                this.setState(newState,
                    () => {
                        //When image loads and image dimensions are first established, apply initial transform.
                        //If dimensions change, constraints change; current transform may need to be adjusted.
                        //Transforms depend on state, so wait until state is updated.
                        if (!this.isTransformInitialized) {
                            this.applyInitialTransform();
                        } else {
                            this.maybeAdjustCurrentTransform();
                        }
                    }
                );
                this.debug(`Dimensions changed: Container: ${containerDimensions.width}, ${containerDimensions.height}, Image: ${imageDimensions.width}, ${imageDimensions.height}`);
            }
        }
        else {
            this.debug('Image not loaded');
        }
    }

    //transformation methods

    //Zooming and panning cause transform to be requested.
    constrainAndApplyTransform(requestedTop, requestedLeft, requestedScale, tolerance, speed = 0) {
        const requestedTransform = {
            top: requestedTop,
            left: requestedLeft,
            scale: requestedScale
        };
        this.debug(`Requesting transform: left ${requestedLeft}, top ${requestedTop}, scale ${requestedScale}`);

        //Correct the transform if needed to prevent overpanning and overzooming
        const transform = this.getCorrectedTransform(requestedTransform, tolerance) || requestedTransform;
        this.debug(`Applying transform: left ${transform.left}, top ${transform.top}, scale ${transform.scale}`);

        if (isEqualTransform(transform, this.state)) {
            return false;
        }

        this.applyTransform(transform, speed);
        return true;
    }

    applyTransform({ top, left, scale }, speed) {
        if (speed > 0) {
            const frame = () => {
                const translateY = top - this.state.top;
                const translateX = left - this.state.left;
                const translateScale = scale - this.state.scale;

                const nextTransform = {
                    top: snapToTarget(this.state.top + (speed * translateY), top, 1),
                    left: snapToTarget(this.state.left + (speed * translateX), left, 1),
                    scale: snapToTarget(this.state.scale + (speed * translateScale), scale, 0.001),
                };

                //animation runs until we reach the target
                if (!isEqualTransform(nextTransform, this.state)) {
                    this.setState(nextTransform, () => this.animation = requestAnimationFrame(frame));
                }
            };
            this.animation = requestAnimationFrame(frame);
        } else {
            this.setState({
                top,
                left,
                scale,
            });
        }
    }

    //Returns constrained scale when requested scale is outside min/max with tolerance, otherwise returns requested scale
    getConstrainedScale(requestedScale, tolerance) {
        const lowerBoundFactor = 1.0 - tolerance;
        const upperBoundFactor = 1.0 + tolerance;

        return constrain(
            getMinScale(this.state, this.props) * lowerBoundFactor,
            this.maxScale * upperBoundFactor,
            requestedScale
        );
    }

    //Returns constrained transform when requested transform is outside constraints with tolerance, otherwise returns null
    getCorrectedTransform(requestedTransform, tolerance) {
        const scale = this.getConstrainedScale(requestedTransform.scale, tolerance);

        //get dimensions by which scaled image overflows container
        const negativeSpace = this.calculateNegativeSpace(scale);
        const overflow = {
            width: Math.max(0, negate(negativeSpace.width)),
            height: Math.max(0, negate(negativeSpace.height)),
        };

        //if image overflows container, prevent moving by more than the overflow
        //example: overflow.height = 100, tolerance = 0.05 => top is constrained between -105 and +5
        const { position, initialTop, initialLeft } = this.props;
        const { imageDimensions, containerDimensions } = this.state;
        const upperBoundFactor = 1.0 + tolerance;
        let top =
            overflow.height && !this.props.cover ? constrain(negate(overflow.height) * upperBoundFactor, overflow.height * upperBoundFactor - overflow.height, requestedTransform.top)
                : position === 'center' ? (containerDimensions.height - (imageDimensions.height * scale)) / 2
                    : initialTop || 0;

        const left =
            overflow.width ? constrain(negate(overflow.width) * upperBoundFactor, overflow.width * upperBoundFactor - overflow.width, requestedTransform.left)
                : position === 'center' ? (containerDimensions.width - (imageDimensions.width * scale)) / 2
                    : initialLeft || 0;

        const constrainedTransform = {
            top,
            left,
            scale
        };

        return isEqualTransform(constrainedTransform, requestedTransform)
            ? null
            : constrainedTransform;
    }

    recalculateScale() {
        const { imageDimensions, containerDimensions } = this.state;
        const { position, initialScale, initialTop, initialLeft } = this.props;
        this.autoScale = getAutofitScale(containerDimensions, imageDimensions, this.props.autoFitBuffer, this.props.cover)
        this.maxScale = this.autoScale * this.props.maxZoom
    }

    //Ensure current transform is within constraints
    maybeAdjustCurrentTransform(speed = 0) {
        this.recalculateScale()

        let correctedTransform;
        if (correctedTransform = this.getCorrectedTransform(this.state, 0)) {
            this.applyTransform(correctedTransform, speed);
        }
    }

    applyInitialTransform(speed = 0) {
        const { imageDimensions, containerDimensions } = this.state;
        const { position, initialScale, initialTop, initialLeft } = this.props;

        this.recalculateScale()
        const minScale = getMinScale(this.state, this.props);
        const scale = String(initialScale).toLowerCase() === 'auto'
            ? this.autoScale
            : initialScale;

        if (minScale > this.maxScale) {
            warning(false, 'minScale cannot exceed maxScale.');
            return;
        }
        if (scale < minScale || scale > this.maxScale) {
            warning(false, 'initialScale must be between minScale and maxScale.');
            return;
        }

        let initialPosition;
        if (position === 'center') {
            warning(initialTop === undefined, 'initialTop prop should not be supplied with position=center. It was ignored.');
            warning(initialLeft === undefined, 'initialLeft prop should not be supplied with position=center. It was ignored.');
            initialPosition = {
                top: (containerDimensions.width - (imageDimensions.width * scale)) / 2,
                left: (containerDimensions.height - (imageDimensions.height * scale)) / 2,
            };
        } else {
            initialPosition = {
                top: initialTop || 0,
                left: initialLeft || 0,
            };
        }

        this.constrainAndApplyTransform(initialPosition.top, initialPosition.left, scale, 0, speed);
    }

    //lifecycle methods
    render() {
        const childElement = React.Children.only(this.props.children);
        const { zoomButtons, debug } = this.props;
        const { scale } = this.state;

        const touchAction = this.controlOverscrollViaCss
            ? browserPanActions(this.state) || 'none'
            : undefined;


        const containerStyle = {
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            touchAction: touchAction,
            ...this.props.style,
        };

        // Fix for centering display mode
        const { containerDimensions, imageDimensions } = this.state
        if (containerDimensions && imageDimensions) {
            const negativeSpace = this.calculateNegativeSpace(scale);
            if (negativeSpace.width > negativeSpace.height) {
                containerStyle.display = 'flex'
            }
        }

        return (
            <div
                className={`pinch-zoom-pan${this.props.className != null ? ` ${this.props.className}` : ''}`}
                style={containerStyle}

            /*onTouchStart={this.handleTouchStart}
            onTouchEnd= {this.handleTouchEnd}
            onMouseDown={this.handleMouseDown}
            onMouseUp={this.handleMouseUp}
            onMouseMove={this.handleMouseMove}
            onDoubleClick={this.handleMouseDoubleClick}
            onWheel={this.handleMouseWheel}
            onDragStart={tryCancelEvent}*/
            >
                {this.isImageReady && this.isTransformInitialized &&
                    <div className='option-container'
                        onMouseDown={trySurpressEvent}
                        onMouseUp={trySurpressEvent}
                        onTouchStart={trySurpressEvent}
                        onTouchEnd={trySurpressEvent}>
                        {this.props.options}
                        {this.props.zoomButtons && <IconButton icon={this.zoomedIn ? icons.searchMinus : icons.searchPlus} onClick={() => this.toggleZoom(null)} />}
                    </div>}

                {debug && <DebugView {...this.state} overflow={imageOverflow(this.state)} />}
                {React.cloneElement(childElement, {
                    onTouchStart: this.handleTouchStart,
                    onTouchEnd: this.handleTouchEnd,
                    onMouseDown: this.handleMouseDown,
                    onMouseUp: this.handleMouseUp,
                    onMouseMove: this.handleMouseMove,
                    onDoubleClick: this.handleMouseDoubleClick,
                    onClick: this.handleMouseSingleClick,
                    onWheel: this.handleMouseWheel,
                    onDragStart: tryCancelEvent,
                    onLoad: this.handleImageLoad,
                    // onContextMenu: tryCancelEvent,
                    ref: this.handleRefImage,
                    style: imageStyle(this.state)
                })}
            </div>
        );
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.initialTop !== prevState.initialTop ||
            nextProps.initialLeft !== prevState.initialLeft ||
            nextProps.initialScale !== prevState.initialScale ||
            nextProps.position !== prevState.position) {
            return {
                position: nextProps.position,
                initialScale: nextProps.initialScale,
                initialTop: nextProps.initialTop,
                initialLeft: nextProps.initialLeft,
            };
        } else {
            return null;
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleWindowResize);
        window.addEventListener(CustomEventType.ZoomIn, this.handleZoom);
        window.addEventListener(CustomEventType.ZoomOut, this.handleZoomOut);
        this.maybeHandleDimensionsChanged();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.lastUpdate) {
            const sinceLastDimensionChange = Date.now() - this.state.lastUpdate
            if (sinceLastDimensionChange < 200) {
                // return
            }
        }
        this.maybeHandleDimensionsChanged(true);
    }

    componentWillUnmount() {
        this.cancelAnimation();
        if (this.imageRef) {
            this.imageRef.removeEventListener('touchmove', this.handleTouchMove);
        }
        window.removeEventListener('resize', this.handleWindowResize);
        window.removeEventListener(CustomEventType.ZoomIn, this.handleZoom)
        window.removeEventListener(CustomEventType.ZoomOut, this.handleZoomOut)
    }

    get isImageReady() {
        return this.isImageLoaded || (this.imageRef && this.imageRef.tagName !== 'IMG');
    }

    get isTransformInitialized() {
        return isInitialized(this.state.top, this.state.left, this.state.scale);
    }

    get controlOverscrollViaCss() {
        return window.CSS && window.CSS.supports('touch-action', 'pan-up');
    }

    calculateNegativeSpace(scale) {
        //get difference in dimension between container and scaled image
        const { containerDimensions, imageDimensions } = this.state;
        const width = containerDimensions.width - (scale * imageDimensions.width);
        const height = containerDimensions.height - (scale * imageDimensions.height);
        return {
            width,
            height
        };
    }

    cancelAnimation() {
        if (this.animation) {
            cancelAnimationFrame(this.animation);
        }
    }

    debug(message) {
        if (this.props.debug) {
            logger.info(message)
        }
    }
}

PinchZoomPan.defaultProps = {
    initialScale: 'auto',
    minScale: 'auto',
    maxZoom: 2,
    position: 'topLeft',
    zoomButtons: true,
    doubleTapBehavior: 'reset',
    autoFitBuffer: 100,
    cover: false,
    zoomInc: 0.5,
    forceZoomCenter: false,
    tapToZoom: true,
    zoomBuffer: 200,
};

PinchZoomPan.propTypes = {
    children: PropTypes.element.isRequired,
    options: PropTypes.array.isRequired,
    initialScale: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    minScale: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    maxZoom: PropTypes.number,
    zoomInc: PropTypes.number,
    position: PropTypes.oneOf(['topLeft', 'center']),
    zoomButtons: PropTypes.bool,
    doubleTapBehavior: PropTypes.oneOf(['reset', 'zoom']),
    initialTop: PropTypes.number,
    initialLeft: PropTypes.number,
    autoFitBuffer: PropTypes.number,
    cover: PropTypes.bool,
    onZoom: PropTypes.func,
    onClick: PropTypes.func,
    className: PropTypes.string,
    cancelMouseDown: PropTypes.bool,
    tapToZoom: PropTypes.bool,
    forceZoomCenter: PropTypes.bool,
    zoomBuffer: PropTypes.number,
};

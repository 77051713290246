import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'app/hooks'
import { Media } from 'components'
import { getMediaUrl } from 'helpers/config'
import { RootState } from 'app/types'
import { Link } from 'react-router-dom'
import * as fnc from 'helpers/fnc'

interface LogoProps {
    app: AppData,
    className?: string,
    small?: boolean,
    admin: boolean,
    fixed: boolean,
    expanded: boolean,
}
export function Logo(props: LogoProps) {
    const { app, className, small, admin, fixed, expanded } = props
    const initialized = useAppSelector((state: RootState) => state.app.initialized)
    // const organization = useAppSelector((state:RootState) => state.app.organization)
    const rootPath = useAppSelector((state: RootState) => state.app.rootPath)
    const projectPath = useAppSelector((state: RootState) => state.app.projectPath)
    const projectCurrent = useAppSelector((state: RootState) => state.app.projectCurrent[0] || state.admin.project)
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)
    const standaloneOrg = useAppSelector((state: RootState) => state.app.standaloneOrg)
    const customDomain = useAppSelector((state: RootState) => state.app.customDomain)
    const config = useAppSelector((state: RootState) => state.app.config)
    const media = useAppSelector((state: RootState) => state.app.media)
    const organization = useAppSelector((state: RootState) => admin ? state.admin.organization : state.app.organization)
    const [key, setKey] = useState(null)
    const [mediaOptions, setMediaOptions] = useState({})

    let path = rootPath
    if (standaloneApp && projectCurrent) {
        if (customDomain.app) {
            path = projectPath
        } else {
            path = `${projectPath}${projectCurrent.meta.link}`
        }
    }

    const [logoMedia, setLogoMedia] = useState(null)

    useEffect(() => {
        if (!media) {
            return
        }

        let newKey = null
        const validMedia = (x) => {
            let ret = x.find((y) => y != null && y in media)
            if (ret) {
                return media[ret]
            }

        }

        let newMedia = null
        const newMediaOptions = {}
        if (!standaloneOrg && projectCurrent) {
            // if (!standaloneOrg && standaloneApp && projectCurrent) {
            // Use build media
            if (expanded) {
                newMedia = validMedia([projectCurrent.meta.logoMediaId, projectCurrent.meta.logoTopbarMediaId])
            } else {
                newMedia = validMedia([projectCurrent.meta.logoTopbarMediaId, projectCurrent.meta.logoMediaId])
            }
            newKey = projectCurrent.meta.id
            if (newMedia) {
                newMediaOptions.app = projectCurrent
            }

            if (!newMedia) {
                const builder = config.builders.find((x) => x.id == projectCurrent?.meta?.builderId)
                if (builder && (builder.logoMediaId || builder.logoTopbarMediaId)) {
                    if (expanded) {
                        newMedia = validMedia([builder.logoMediaId, builder.logoTopbarMediaId])
                    } else {
                        newMedia = validMedia([builder.logoTopbarMediaId, builder.logoMediaId])
                    }
                    newKey = builder.id
                    if (newMedia) {
                        newMediaOptions.builder = builder
                    }
                }
            }
        }

        if (!newMedia && organization != null) {
            newKey = organization.id
            if (expanded) {
                newMedia = validMedia([organization.logoMediaId, organization.logoTopbarMediaId])
            } else {
                newMedia = validMedia([organization.logoTopbarMediaId, organization.logoMediaId])
            }
            if (newMedia) {
                newMediaOptions.organization = organization
            }
        }

        if (!newMedia && config.organizations) {
            const homegyde = config.organizations.find((x) => x.link == 'homegyde')
            newMedia = media[homegyde.logoMediaId]
            newKey = 'homegyde'
            newMediaOptions.organization = homegyde
        }

        if (newMedia) {
            setKey(newKey)
            setLogoMedia(newMedia)
            setMediaOptions(newMediaOptions)
        }
    }, [media, config, organization, standaloneApp, expanded, projectCurrent])

    if (!logoMedia) {
        return null
    }

    const m = media[logoMedia.id]
    let logoStyle = {}
    if (expanded) {
        logoStyle = {
            width: 'var(--logo-size)',
            minWidth: 'var(--logo-size)',
            maxWidth: 'var(--logo-size)',
            height: 'var(--logo-size)',
            maxHeight: 'var(--logo-size)',
        }
    } else if (m && m.width && m.height) {
        logoStyle = {
            width: `calc((var(--topbar-height) - var(--logo-padding)*2)*${m.width / m.height})`,
            minWidth: `calc((var(--topbar-height) - var(--logo-padding)*2)*${m.width / m.height})`,
            maxWidth: `calc((var(--topbar-height) - var(--logo-padding)*2)*${m.width / m.height})`,
            height: 'var(--topbar-height)',
            maxHeight: 'var(--topbar-height)',
        }
    }

    if (fixed) {
        return <a key={key} href={path} className="logo-wrapper" style={logoStyle}><Media fadeIn className={`logo ${className != null ? className : ''}`} mediaId={logoMedia.id} style={logoStyle} {...mediaOptions} /></a>
    }
    return <Link key={key} to={path} className="logo-wrapper" style={logoStyle}><Media fadeIn className={`logo ${className != null ? className : ''}`} mediaId={logoMedia.id} style={logoStyle} {...mediaOptions} /></Link>
}

import React, { useState, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
    RootState,
    QueryType,
    PageType,
} from 'app/types'
import {
    rebuildHistory,
    setPath,
    navigateAsync,
    clearCurrent,
} from 'actions/appActions'
import {
    Spinner,
} from 'components'
import { ProjectPage } from 'views/HomePage/ProjectPage'
import { logger } from 'helpers/logger'

export interface ProjectViewProps {
    organizationLink: string,
    match: {
        params: {
            appLink: string,
            pageLink: string,
            dataLink: string
            extraLink: string,
        }
    }
}

export default function ProjectView(props: ProjectViewProps) {
    const { appLink, pageLink, dataLink, extraLink } = props.match.params
    const dispatch = useAppDispatch()
    const config = useAppSelector((state: RootState) => state.app.config)
    const query = useAppSelector((state: RootState) => state.app.queries[QueryType.Projects])
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const standaloneOrg = useAppSelector((state: RootState) => state.app.standaloneOrg)
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)
    const projectCurrent = useAppSelector((state: RootState) => state.app.projectCurrent[0])
    const compare = useAppSelector((state: RootState) => state.app.compare)

    const [loaded, setLoaded] = useState(0)

    useEffect(() => {
        // If organization only has on app, redirect to app page
        if (props.path && props.path.baseRoute == PageType.Developments && standaloneOrg && organization.link != 'thepropertylinq') {
            const app = config.apps.find((x) => x.meta.id == organization.apps[0])
            if (app) {
                dispatch(navigateAsync({ app, options: { path: { baseRoute: null }, replaceHistory: true } }))
            } else {
                logger.error('Missing app ' + organization.apps[0])
            }
        }

        if (props.path) {
            dispatch(setPath(props.path))
        } else {
            dispatch(setPath({ baseRoute: '' }))
        }

        setLoaded(1)
        let timeout = setTimeout(() => {
            // Layout delay
            setLoaded(2)
            // Spinner delay
            let timeout = setTimeout(() => {
                setLoaded(3)
            }, 500)
        }, 100)
        return () => {
            clearTimeout(timeout)
        }
    }, [])

    useEffect(() => {
        if (projectCurrent && projectCurrent.meta.name) {
            const page = projectCurrent.pages.find((x) => x.link == pageLink)
            let newTitle = projectCurrent.meta.name
            if (page) {
                newTitle = `${projectCurrent.meta.name} - ${page.name}`
            }
            if (dataLink) {
                newTitle = `${newTitle} - ${dataLink}`
            }
            if (extraLink) {
                newTitle = `${newTitle} - ${extraLink}`
            }
            document.title = newTitle
        } else {
            if (organization) {
                document.title = organization.name
            }
        }

    }, [appLink, pageLink, dataLink, extraLink])

    useEffect(() => {
        if (standaloneApp && projectCurrent && appLink == projectCurrent.meta.link && pageLink == null && projectCurrent.pages.length > 0) {
            // Only if no screensaver page
            const firstPage = projectCurrent.pages.find((x) => x.pageType == PageType.ScreenSaver)
            if (!firstPage) {
                logger.info("Redirect standalone app to first page")
                dispatch(navigateAsync({ app: projectCurrent, pageType: firstPage?.pageType, options: { path: { baseRoute: null }, replaceHistory: true } }))
            }
        }

    }, [projectCurrent, standaloneApp, pageLink])

    useEffect(() => {
        // dispatch(rebuildHistory())
        // Clear apps when navigating away from project page
        if (!compare && appLink != PageType.Compare && ((!appLink && projectCurrent) || (appLink && projectCurrent && projectCurrent.meta.link != appLink))) {
            dispatch(clearCurrent())
        }
    }, [appLink, pageLink, dataLink, extraLink, compare])

    return <React.Fragment>
        {loaded > 0 && <div id="project-view" className={`content scrollable${loaded < 2 ? ' loading' : ''}`}>
            {loaded < 3 && <Spinner overlay show={loaded === 0} />}
            <div className="content-wrapper">
                <ProjectPage organizationLink={props.organizationLink} appLink={appLink} pageLink={pageLink} dataLink={dataLink} extraLink={extraLink} />
            </div>
        </div>}
    </React.Fragment>
}

import React, { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { logger } from 'helpers/logger'
import {
    Spinner,
} from 'components'
import {
    retrieveFavourites,
    setPath,
    rebuildHistory,
} from 'actions/appActions'
import {
    RootState,
} from 'app/types'
import { ProjectPage } from './ProjectPage'

export function FavouritesView(props) {
    const { match } = props
    const { appLink, pageLink, dataLink, extraLink } = match.params
    const dispatch = useAppDispatch()
    const loggedIn = useAppSelector((state: RootState) => state.user.loggedIn)
    const favourites = useAppSelector((state: RootState) => state.user.favourites)
    const config = useAppSelector((state: RootState) => state.app.config)
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const [loaded, setLoaded] = useState(0)

    useEffect(() => {
        dispatch(setPath({ baseRoute: 'favourites' }))
    }, [])

    useEffect(() => {
        const asyncLoad = async () => {
            setLoaded(1)
            // Load floorplans
            if (loggedIn) {
                // Filter by config apps
                const appIds = config.apps.map((x) => x.meta.id)
                await dispatch(retrieveFavourites({ appIds }))
                setLoaded(2)
            } else if (favourites && Object.keys(favourites).length > 0) {
                // Get subset of favourites that intersect config
                const configSet = new Set(config.apps.map((x) => x.meta.id))
                await dispatch(retrieveFavourites({ appIds: Object.keys(favourites).filter((x) => configSet.has(x)), organization }))
                setLoaded(2)
            } else {
                setLoaded(2)
            }
        }
        asyncLoad().catch(logger.error)
    }, [loggedIn])

    useEffect(() => {
        dispatch(setPath({ appLink: appLink, baseRoute: 'favourites' }))
        // dispatch(rebuildHistory())
    }, [appLink, pageLink, dataLink, extraLink])

    return <React.Fragment>
        {loaded < 2 && <Spinner show={loaded === 0} />}
        {/* {loaded > 0 && getProject()} */}
        {loaded > 0 && <div id="favourites-view" className="content scrollable" style={{ opacity: loaded == 2 ? 1 : 0 }}>
            <ProjectPage onlyFavourites appLink={appLink} pageLink={pageLink} dataLink={dataLink} />
        </div>}
    </React.Fragment>
}

import React, { useEffect, useState, useRef } from 'react'
import { Prompt } from 'react-router'
import { validateEmail, validatePhone } from 'helpers/authHeader'
import { Checkbox, Input, DropdownMenu, Spinner } from 'components'
import { useAppSelector, useLocalStorage } from 'app/hooks'
import { logger } from 'helpers/logger'
import { CustomEventType } from 'app/types'
import * as fnc from 'helpers/fnc'

interface HubspotFormProps {
    formId: string,
    app: AppData,
    onSubmit: (data: Dict) => void,
}

const EMBED_SCRIPT = "https://js.hsforms.net/forms/embed/v2.js"

export function HubspotForm(props: HubspotFormProps) {
    const { app, formId, onSubmit } = props

    const [error, setError] = useState(null)
    const [initialized, setInitialized] = useState(false)

    const portalId = app?.meta.hubspotTrackingId

    useEffect(() => {
        if (!formId || !portalId) {
            setError("Missing hubspot options")
            return
        }
        const finalOptions = {
            formId,
            region: "na1",
            portalId,
            target: '.hubspot-form'
        }

        fnc.loadScript('hubspot_embed', EMBED_SCRIPT)
            .then((x) => {
                if (typeof hbsp) {
                    setTimeout(() => {
                        try {
                            hbspt.forms.create(finalOptions)
                            /*setTimeout(() => {
                                const formElem = document.querySelector('.hubspot-form form')
                                formElem?.addEventListener('submit', handleSubmit)
                            }, 5000)*/
                            setError(null)
                        } catch (e) {
                            logger.error(e)
                            setError("Error loading hubspot form")
                        }
                        setTimeout(() => {
                            setInitialized(true)
                        }, 1000)
                    }, 100)
                }
            }).catch((x) => {
                logger.error("Error loading hubspot form (A)", x)
                setError("Error loading hubspot form (A)")
            })
    }, [])

    useEffect(() => {
        // Look for form and loop in to submit event
    }, [initialized])

    return <div className="row hubspot-form-wrapper">
        {error && <h3 className="error">{error}</h3>}
        <div className="hubspot-form">
            {/* {!error && !initialized && <Spinner overlay/>} */}
        </div>
        {!error && <Spinner show={!initialized} quotes={false} overlay />}
    </div>
}
import { createBrowserHistory } from 'history'
import { getAppPath } from 'helpers/config'
import { CustomEventType } from 'app/types'

// const history = createBrowserHistory({ basename: getAppPath() })
// export default history
const history = {
    push: (path: string) => {
        window.dispatchEvent(new CustomEvent(CustomEventType.HistoryPush, { detail: path }))
    }, 
    replace: (path: string) => {
        window.dispatchEvent(new CustomEvent(CustomEventType.HistoryReplace, { detail: path }))
    },
}
export default history
import React from 'react'
import {
    CloseButton,
    Button,
} from 'components'
import { BookingTile } from 'views/HomePage/BookingTile'
import { AppData } from 'app/types'

interface BookingDialogProps {
    app: AppData,
    title:string,
    subtitle:string,
    error: string,
    message:string,
    onClose?: () => void,
    onConfirm: () => void,
    animation?: string,
    bookMessage?:string,
}

export function BookingDialog(props:BookingDialogProps) {
    const {
        app,
        title,
        subtitle,
        error,
        message,
        onClose,
        onConfirm,
        bookMessage,
        animation,
    } = props

    function handleConfirm() {
        if (onConfirm) {
            onConfirm()
        }
    }

    function handleClose() {
        onClose()
    }

    return <div className={`dialog ${animation}`} onClick={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
        <div className="title">
            <h3>{title}</h3>
            {subtitle && <h4>{subtitle}</h4>}
            {error && <h5 className="error">{error}</h5>}
        </div>
        <div className="dialog-wrapper">
            {message && <h4 className="message">{message}</h4>}
            <CloseButton large onClick={handleClose}/>
            <BookingTile submitText={bookMessage} launch="This project launches " app={app}/>
        </div>
    </div>
}


import React, { useEffect, useState } from 'react'
import {
    CustomEventType,
} from 'app/types'
import {
    Icon, IconButton,
} from 'components'
import { icons } from 'app/constants'


export function CollapsibleRow(props: { title, style: string, index: number, id: string, onOpen, forceOpen: boolean, children }) {
    const {
        title, style, index, id, onOpen, forceOpen, children,
    } = { style: 'chevron', ...props }
    const [open, setOpen] = useState(false)
    const [height, setHeight] = useState('auto')
    const [bodyElem, setBodyElem] = useState(null)

    useEffect(() => {
        if (forceOpen != null && forceOpen != open) {
            setOpen(forceOpen)
        }
    }, [forceOpen])
    useEffect(() => {
        if (!bodyElem) {
            return
        }
        setHeight(bodyElem.clientHeight)
    }, [bodyElem, open])

    useEffect(() => {
        const close = (e) => {
            if (e.detail && e.detail.id != null && e.detail.id != id) {
                return
            }
            setOpen(false)
        }
        window.addEventListener(CustomEventType.CollapseMenus, close)
        return () => {
            window.removeEventListener(CustomEventType.CollapseMenus, close)
        }
    }, [open])

    function handleOpen(x) {
        if (x) {
            window.dispatchEvent(new CustomEvent(CustomEventType.CollapseMenus, { detail: id }))
            setTimeout(() => {
                setOpen(true)
                if (onOpen) {
                    onOpen(true)
                }
            }, 0)
        } else {
            setOpen(false)
            if (onOpen) {
                onOpen(false)
            }
        }
    }

    return <div className={`content-collapsible row${open ? ' open' : ''}`} style={{ height: `${height}px`, minHeight: `${height}px` }} onClick={() => handleOpen(!open)}>
        {index != null && <div className="column circle-column">
            <span>{index}</span>
        </div>}
        {style == 'plus' && <div className="column circle-column">
            <Icon icon={open ? icons.minus : icons.plus} noBg />
        </div>}
        <div className="column">
            <div className="content-collapsible-body" ref={setBodyElem}>
                <span className="content-collapsible-title">
                    <h4 className="noselect">{title}</h4>
                    {style == 'chevron' && <IconButton icon={open ? icons.chevronUp : icons.chevronDown} noBg />}
                </span>
                {open && children}
            </div>
        </div>
    </div>
}

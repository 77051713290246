import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector, useLocalStorage } from 'app/hooks'
import { RootState, ProjectFilter, FloorplanFilter, UnitFilter, QueryType, AnalyticsFilter, UpgradeFilter, AnalyticsEvent, SessionFilters, UserFilters } from 'app/types'
import { Button, Icon } from 'components'
import { analyticsEvent, resetQuery, toggleQuery } from 'actions/appActions'
import * as fnc from 'helpers/fnc'
import { analyticsFieldValue, transformGarage, transformUnitName } from 'app/transformers'
import { logger } from 'helpers/logger'

interface FilterListProps {
    app: AppData,
    type: QueryType,
    maps: Dict,
    filters: string[],
    readOnly: boolean,
    className: string,
    asList: boolean,
    fields: Dict,
}

export function FilterList(props) {
    const { app, type, filters, maps, readOnly, className, asList, fields } = props
    const dispatch = useAppDispatch()
    const query = useAppSelector((state: RootState) => state.app.queries[type])
    const fixedQuery = useAppSelector((state: RootState) => state.app.fixedQueries[type])
    const config = useAppSelector((state: RootState) => state.app.config)
    const [cachedQuery, setCachedQuery] = useLocalStorage('query', {})
    const [initialized, setInitialized] = useState(false)
    const [highlightChanges, setHighlightChanges] = useState({})
    const { builders, organizations, projectTypes, projectStatuses } = config

    useEffect(() => {
        if (!initialized) {
            let changes = {}
            if (JSON.stringify(query) != JSON.stringify(cachedQuery)) {
                // Find whats changed
                Object.keys(query).forEach((key) => {
                    try {
                        if (key in cachedQuery) {
                            // Check what values have changed, value is array of strings
                            const diff = key in query ? query[key]?.filter((x) => !cachedQuery[key].includes(x)) : []
                            if (diff && diff.length > 0) {
                                changes[key] = diff
                            }
                        } else {
                            changes[key] = query[key]
                        }
                    } catch (e) {
                        logger.errorWithReport("Bug A", e, 'FilterList', 'useEffect', { key, query, cachedQuery })
                    }
                })
            }
            setInitialized(true)
            if (Object.keys(changes).length > 0) {
                // dispatch(analyticsEvent({ type: AnalyticsEvent.Filter, changes: changes }))
                setHighlightChanges(changes)
            }
        }
        setCachedQuery(query)
    }, [query])

    function handleClear(filter, value = null, childKey = null) {
        dispatch(resetQuery({ key: filter, type, value }))
        /*const newQuery = { ...query }
        switch(filter) {
            default:
                newQuery[filter] = newQuery[filter].filter((x) => x != value)
                if (newQuery[filter].length == 0) {
                    delete newQuery[filter]
                }
            break
            case FloorplanFilter.Sqft:
            case FloorplanFilter.Price:
                delete newQuery[filter]
            break
        }
        dispatch(applyQuery({ query: newQuery, type: type }))*/
    }

    function getFilter(_key, dict, canClear = true) {
        let key = _key
        let childKey = null
        const tokens = key.split('_')
        if (tokens.length > 1) {
            key = tokens[0]
            childKey = tokens[1]
        }
        if (filters && !filters.includes(key)) {
            return
        }

        if (type == QueryType.Analytics || type == QueryType.Upgrades) {
            if (!Array.isArray(dict[_key])) {
                return null
            }
            return dict[_key].map((y) => {
                let value = y
                // const field = fields?.find((x) => x.key == key)
                // if(field && field.valueMap) {
                    // value = analyticsFieldValue(value, field, { key: y }, maps, config)
                // } else if (maps && key in maps && value in maps[key]) {
                if (maps && key in maps && value in maps[key]) {
                    value = maps[key][value]
                    if (value && value.label) {
                        value = value.label
                    } else if (value && value.name) {
                        value = value.name
                    }
                } else if (value && typeof value == 'string') {
                    value = value.toReadable()
                }
                switch (key) {
                    case AnalyticsFilter.User:
                        value = `User: ${value}`
                        break
                    case AnalyticsFilter.Client:
                        value = `Client: ${value}`
                        break
                    case AnalyticsFilter.Session:
                        value = `Session: ${value?.sessionId || value}`
                        break
                    case AnalyticsFilter.Floorplan:
                        value = `Floorplan: ${value}`
                        break
                    case AnalyticsFilter.Project:
                        value = `Project: ${value}`
                        break
                    case AnalyticsFilter.Page:
                        value = `Page: ${value}`
                        break
                    case AnalyticsFilter.Data:
                        value = `Data: ${value}`
                        break
                    case AnalyticsFilter.OptIn:
                        value = `Opt In: ${value ? 'Yes' : 'No'}`
                        break
                    case AnalyticsFilter.Organization:
                        value = `Organization: ${value}`
                        break
                    case AnalyticsFilter.EventType:
                        if (value) {
                            if (value in AnalyticsEvent) {
                                value = `Event Type: ${AnalyticsEvent[value]?.toReadable()}`
                            } else {
                                value = `Event Type: ${value?.toReadable()}`
                            }
                        } else {
                            value = `Event Type: N/A`
                        }
                        break
                    case AnalyticsFilter.DataMessage:
                        value = `${childKey}: ${value}`
                        break
                    case UpgradeFilter.Floorplan:
                        value = `Plan: ${value}`
                        break
                    case UpgradeFilter.FloorplanVariation:
                        value = `Plan Variation: ${value}`
                        break
                    case UpgradeFilter.OnlyChanged:
                        value = "Only Changes"
                        break
                    case UpgradeFilter.BuildingType:
                        value = `Building Type: ${app?.maps.buildingType[value]?.name}`
                        break
                    case AnalyticsFilter.Name:
                        value = `Submitter: ${value ? value : 'Anonymous'}`
                        break
                    default:
                        value = `${key.toReadable()}: ${value}`
                        break
                }
                return <Button key={y} alt onClick={() => handleClear(_key, y)}>{value}<Icon noBg icon="fas fa-times" /></Button>
            })

            // Multi filters
        } else if (key != FloorplanFilter.Sqft
            && key != FloorplanFilter.Price
            && key != FloorplanFilter.Favourites
            && key != UnitFilter.HideUnavailable
            && key != FloorplanFilter.Search) {
            if (!Array.isArray(dict[key])) {
                return null
            }
            return dict[key].map((y) => {
                let value = y
                if (maps && key in maps) {
                    value = maps[key][value]
                    if (value && value.label) {
                        value = value.label
                    } else if (value && value.name) {
                        value = value.name
                    }
                }
                switch (key) {
                    case ProjectFilter.Organization:
                    case ProjectFilter.Builder:
                    case ProjectFilter.ProjectStatus:
                    case ProjectFilter.ProjectType: {
                        let arr = {
                            [ProjectFilter.Organization]: organizations,
                            [ProjectFilter.Builder]: builders,
                            [ProjectFilter.ProjectStatus]: Object.values(projectStatuses),
                            [ProjectFilter.ProjectType]: Object.values(projectTypes),
                        }[key]
                        const item = arr.find((z) => z.id == value)
                        if (item) {
                            value = item.name
                        } else {
                            value = null
                        }
                        break
                    }
                    case FloorplanFilter.Baths:
                        value = `${value} Bath`
                        break
                    case FloorplanFilter.Garage:
                        // value = `${transformGarage(app, value)} Garage`
                        value = `${value} Garage`
                        break
                    case FloorplanFilter.Availability:
                        value = `Availability: ${value}`
                        break
                    case FloorplanFilter.Unit:
                        value = `Unit ${transformUnitName(app, { name: value })}`
                        break
                    case FloorplanFilter.BuildingType:
                        value = `Building Type: ${value}`
                        break
                    case FloorplanFilter.Series:
                        value = `Series: ${value}`
                        break
                    case FloorplanFilter.Building:
                        value = `Building ${value}`
                        break
                    case FloorplanFilter.Beds:
                        value = `${value} Bed`
                        break
                    case UnitFilter.Exposure:
                        value = `${value} Exposure`
                        break
                    case UnitFilter.Floor:
                        value = `${fnc.numberWithOrdinalSuffix(parseInt(y))} Floor`
                        break
                    default:
                        break
                }
                const highlight = key in highlightChanges && highlightChanges[key].includes(y)
                const className = highlight ? 'animate__animated animate__flash animate__repeat-2 animate__delay-2s animate__slow' : ''
                if (value != null) {
                    // return <Button key={y} icon="fas fa-filter" hoverIcon="fa fa-times" onClick={() => handleClear(x, y)}>{value}</Button>
                    if (canClear) {
                        return <Button key={y} className={className} alt onClick={() => handleClear(key, y)}>{value}<Icon noBg icon="fas fa-times" /></Button>
                    } else if (readOnly && asList) {
                        return <li>{value}</li>
                    } else {
                        return <Button className={`fixed ${className}`} key={y} alt>{value}</Button>
                    }
                    // return <Button key={y} onClick={() => handleClear(x, y)}>{value}</Button>
                }
                return null
            })
        } else {
            let value = dict[key]
            switch (key) {
                case FloorplanFilter.Sqft:
                    if (value.max >= 100000) {
                        value = `${value.min}+ sq. ft.`
                    } else {
                        value = `${value.min} - ${value.max} sq. ft.`
                    }
                    break
                case FloorplanFilter.Price:
                    value = `$${fnc.numberWithCommas(value.min)} - $${fnc.numberWithCommas(value.max)}`
                    break
                case UnitFilter.HideUnavailable:
                    value = key
                    break
                case FloorplanFilter.Favourites:
                    value = 'Only Favs'
                    break
                case FloorplanFilter.Search:
                    value = `Search: ${query[key]}`
                    break
            }
            if (value != null) {
                // return <Button key={x} icon="fas fa-filter" hoverIcon="fa fa-times" onClick={() => handleClear(x)}>{value}</Button>
                if (canClear) {
                    return <Button alt onClick={() => handleClear(key)}>{value}<Icon noBg icon="fas fa-times" /></Button>
                } else if (readOnly && asList) {
                    return <li>{value}</li>
                } else {
                    return <Button icon={readOnly ? 'fas fa-circle' : ''} className="fixed" alt>{value}</Button>
                }
                // return <Button key={x} onClick={() => handleClear(x)}>{value}</Button>
            }
            return null
        }
    }


    if ((query && Object.keys(query).length > 0) || (fixedQuery && Object.keys(fixedQuery).length > 0)) {
        return <div className={`filter-list row${className ? ` ${className}` : ''}`}>
            {fixedQuery && Object.keys(fixedQuery).map((x) => getFilter(x, fixedQuery, false))}
            {asList && <h5>Filter By</h5>}
            {asList && <ul>
                {query && Object.keys(query).map((x) => getFilter(x, query, !readOnly))}
            </ul>}
            {!asList && query && Object.keys(query).map((x) => getFilter(x, query, !readOnly))}
        </div>
    }
    return null
}
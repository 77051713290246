import React, { useState } from 'react'
import {
    CloseButton,
    Button,
    Icon,
} from 'components'
import { useAppSelector } from 'app/hooks'
import * as fnc from 'helpers/fnc'
import { icons } from 'app/constants'

interface TutorialDialogProps {
    animation?: string,
    className: string,
    items: { id: string, title: string, body: string }[]
    onClose: () => void,
}

export function TutorialDialog(props: TutorialDialogProps) {
    const {
        animation,
        items,
        className,
        onClose,
    } = {
        ...props,
    }

    const screen = useAppSelector((state: RootState) => state.app.screen)
    const [index, setIndex] = useState(0)

    /*useEffect(() => {
        let timer = null
        if (autoCancel != null) {
            timer = setTimeout(() => {
                handleClose()
            }, autoCancel)
        }
        return () => clearTimeout(timer)
    }, [])*/

    function handleNext() {
        setIndex(index + 1)
    }

    function handlePrev() {
        setIndex(index - 1)
    }

    function handleClose() {
        onClose()
    }

    if (!items) {
        return
    }
    if (index < items.length) {
        const { id, title, body, icon, position, narrow } = items[index]
        const elem = document.getElementById(id)
        let style = {}
        let focusStyle = {}
        if (elem) {
            const bounds = elem?.getBoundingClientRect()
            const appElement = document.getElementById('app')
            let zoom = fnc.getRootProperty('--zoom', appElement)
            switch (position) {
                case 'above': {
                    let bottom = window.innerHeight / zoom - bounds.y + 50
                    style = {
                        position: 'absolute',
                        left: 'auto',
                        right: 'auto',
                        bottom: `${bottom}px`,
                        top: 'auto',
                    }
                    break
                }
                case 'left bottom': {
                    let right = window.innerWidth - bounds.x * zoom + 50 / zoom
                    let bottom = window.innerHeight - (bounds.y + bounds.height)
                    let top = bounds.y + bounds.height;
                    style = {
                        position: 'absolute',
                        top: top,
                        right: `${right}px`,
                        // top: 'auto',
                        // bottom: `${bottom}px`,
                        transform: 'translateY(-100%)',
                        transition: index > 0 ? 'all ease-in-out 500ms' : '',
                    }
                    break
                }
                case 'left':
                default: {
                    let right = window.innerWidth - bounds.x * zoom + 50 / zoom
                    let top = bounds.y
                    style = {
                        position: 'absolute',
                        right: `${right}px`,
                        top: `${top}px`,
                        bottom: 'auto',
                        transition: index > 0 ? 'all ease-in-out 500ms' : '',
                    }
                }
            }

            // bounds.x = Math.round(bounds.x)
            // bounds.y = Math.round(bounds.y)
            // bounds.width = Math.round(bounds.width)
            // bounds.height = Math.round(bounds.height)
            // focusStyle.clipPath = `polygon(0% 0%, 0% 100%, 25% 100%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 100%, 100% 100%, 100% 0%)`
            if (narrow) {
                bounds.x += bounds.height / 2
                bounds.width -= bounds.height
                focusStyle.clipPath = `polygon(0% 0%, 0% 100%, ${bounds.x}px 100%, ${bounds.x}px ${bounds.y}px, calc(${bounds.x}px + ${bounds.width}px) ${bounds.y}px, calc(${bounds.x}px + ${bounds.width}px) calc(${bounds.y}px + ${bounds.height}px), ${bounds.x}px calc(${bounds.y}px + ${bounds.height}px), ${bounds.x}px 100%, 100% 100%, 100% 0%)`
            } else {
                focusStyle.clipPath = `polygon(0% 0%, 0% 100%, ${bounds.x}px 100%, ${bounds.x}px ${bounds.y}px, calc(${bounds.x}px + ${bounds.width}px) ${bounds.y}px, calc(${bounds.x}px + ${bounds.width}px) calc(${bounds.y}px + ${bounds.height}px), ${bounds.x}px calc(${bounds.y}px + ${bounds.height}px), ${bounds.x}px 100%, 100% 100%, 100% 0%)`
            }
            focusStyle.transition = index > 0 ? 'all ease-in-out 500ms' : ''
        }
        return <React.Fragment>
            <div className="tutorial-focus" style={focusStyle}>
            </div>
            <div className="dialog-wrapper" style={style}>
                <div className={`dialog ${animation}${className ? ` ${className}` : ''}${position ? ` ${position}` : ''}`} onClick={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
                    {/* <CloseButton onClick={handleClose}/> */}
                    {title && <h4>{title}</h4>}
                    {body && <span className="row">{body}{icon && <Icon noBg icon={icon} />}</span>}
                    <div className="row">
                        {index > 0 && index < items.length - 2 && <Button onClick={handlePrev} className="prev" tertiary>Prev</Button>}
                        {index < items.length - 1 && <Button onClick={handleNext} className="next" tertiary>Next</Button>}
                        {index == items.length - 1 && <Button onClick={handleClose} className="done" tertiary>Got it</Button>}
                    </div>
                    <div className="tutorial-focus-arrow-wrapper">
                        <Icon className="tutorial-focus-arrow bounce" icon={icons.chevronRight} noBg />
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

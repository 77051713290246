import React, { useState, useEffect } from 'react'
import { useAppSelector, getLinkSelector, useUserPermissions, useAppDispatch, useLocalStorage } from 'app/hooks'
import {
    Icon,
    Button,
    FavouriteButton,
    ShareButton,
    Media,
    DropdownMenu,
    IconButton,
} from 'components'
import {
    RootState,
    FloorplanData,
    AppMode,
    Availability,
    QueryType,
    UnitData,
    UnitFilter,
    FloorplanFilter,
    ListStyle,
    PageType,
    ScreenSize,
    FloorplanVariationData,
    UserRole,
    ProjectType,
    NotificationStatus,
    CustomEventType,
    PromptType,
} from 'app/types'
import { logger } from 'helpers/logger'
import { getUnitDescriptor, sortUnitNumber, transformGarage, transformUnitBuildingKey, transformUnitName } from 'app/transformers'
import { getAppMode } from 'helpers/config'
import * as fnc from 'helpers/fnc'
import { Toggle } from 'components/Core/Toggle'
import { pushNotification, showPrompt } from 'actions/appActions'
import { load } from 'dotenv'

export interface FloorplanTileProps {
    app: AppData,
    floorplan: FloorplanData,
    variation: FloorplanVariationData
    favourite: boolean,
    filters: boolean,
    share: boolean,
    minimal: boolean,
    onFloorplan: () => void,
    onVariation: () => void,
    onModelhome: () => void,
    onSpin: () => void,
    tileStyle: ListStyle,
    focusUnit: string,
    altMap: () => void,
    editing: boolean,
    showFeaturedMedia: boolean,
    showVariations: boolean,
    listInterior: boolean,
    admin: boolean,
    hoverShow: boolean,
    onFocusUnit: () => void,
    onFloorplanMedia: () => void,
    currentFloorplan: number,
    className: string,
}

export function FloorplanTile(props: FloorplanTileProps) {
    const {
        app,
        floorplan,
        favourite,
        onFloorplan,
        onVariation,
        onModelhome,
        onSpin,
        minimal,
        filters,
        share,
        tileStyle,
        pageLink,
        focusUnit,
        altMap,
        editing,
        showFeaturedMedia,
        showVariations,
        listInterior,
        admin,
        hoverShow,
        onFocusUnit,
        variation: givenVariation,
        options,
        pageType,
        additionalQuery,
        onFloorplanMedia,
        currentFloorplan,
        className,
        ...otherProps
    } = props

    const dispatch = useAppDispatch()

    if (!app) {
        return <h3>Missing app</h3>
    }


    // const [_variation, setVariation] = useState(props.variation)
    const [_variation, setVariation] = useState(null)
    const variation = _variation || (floorplan.variations.length > 0 ? floorplan.variations[0] : null)
    // const variation = _variation

    const [overrideMap] = useState((() => {
        let map = {}
        for (let i = 0; i < floorplan.variations.length; i += 1) {
            let v = floorplan.variations[i]
            for (let j = 0; j < v.unitPriceOverrides.length; j += 1) {
                let o = v.unitPriceOverrides[j]
                if (!(o.unitId in map)) {
                    map[o.unitId] = { min: 100000000, max: 0 }
                }
                map[o.unitId].min = Math.min(map[o.unitId].min, o.price)
                map[o.unitId].max = Math.max(map[o.unitId].max, o.price)
            }
        }
        return map
    })()
    )

    const getLink = getLinkSelector()
    const theme = useAppSelector((state: RootState) => state.app.config.theme)
    const compare = useAppSelector((state: RootState) => state.app.compare)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const { size } = screen
    const allMedia = useAppSelector((state: RootState) => state.app.media)

    const userPermissions = useUserPermissions();
    const user = useAppSelector((state: RootState) => state.user.data)
    const guestGroup = useAppSelector((state: RootState) => state.user.guestGroup)
    const config = useAppSelector((state: RootState) => state.app.config)
    const [featuredMediaIndex, setFeaturedMediaIndex] = useState(0)
    const [media, setMedia] = useState(null)

    const guestRoles = guestGroup ? config.groups.find((x) => x.id == guestGroup)?.roles : []
    const mobileView = screen.isMobile

    // if (!variation) {
    // return <h3>Missing variation</h3>
    // }rue


    const [loaded, setLoaded] = useState(false)
    const allUnits = app.units
    const query = useAppSelector((state: RootState) => state.app.queries[QueryType.Floorplans])
    const fixedQuery = useAppSelector((state: RootState) => state.app.fixedQueries[QueryType.Floorplans])
    const salescenter = useAppSelector((state: RootState) => state.app.salescenter)

    const [units, setUnits] = useState<{ map: { [key: string]: UnitData }, count: number }>({})
    const [filteredUnits, setFilteredUnits] = useState<{ map: { [key: string]: UnitData }, count: number }>(null)

    const links = useAppSelector((state: RootState) => state.app.links)
    const floorplanUrl = getLink(app, links.pageLink, floorplan.link, admin ? '/search' : '')
    const unitDescriptor = getUnitDescriptor(app)

    useEffect(() => {
        if (props.variation) {
            setVariation(props.variation)
        } else if (floorplan.variations.length > 0) {
            setVariation(floorplan.variations[0])
        }
    }, [props.variation])

    useEffect(() => {
        let mediaId = null
        if (floorplan.variations.length > 0) {
            if (variation.floorplans && variation.floorplans.length > 0) {
                // Check for default
                const defaultFloorplan = variation.floorplans.find((x) => x.isDefault)
                if (defaultFloorplan) {
                    mediaId = defaultFloorplan.mediaId
                } else {
                    mediaId = variation.floorplans[0].mediaId
                }
            } else {
                mediaId = variation.mediaId
            }
        }
        let newMedia = mediaId ? allMedia[mediaId] : null
        if (showFeaturedMedia && variation.media.length > 0) {
            newMedia = allMedia[variation.media[featuredMediaIndex].mediaId]
        } else if (altMap) {
            const altMedia = floorplan.media.find(altMap)
            if (altMedia) {
                newMedia = allMedia[altMedia]
            }
        }
        setMedia(newMedia)

        if(!newMedia || minimal) {
            setLoaded(true)
        }
    }, [showFeaturedMedia, variation, featuredMediaIndex])

    useEffect(() => {
        if (floorplan.units) {
            const planUnits: { [key: string]: UnitData[] } = {}
            const planUnitsFiltered = {}
            let allCount = 0
            let totalCount = 0
            let availableCount = 0
            let filteredCount = 0
            let filterSet = false
            for (let i = 0; i < floorplan.units.length; i += 1) {
                let stateName = null
                const unit = allUnits[floorplan.units[i].ix]
                if (variation && floorplan.units[i].floorplanVariationId != null && floorplan.units[i].floorplanVariationId != variation.id) {
                    continue
                }

                if (!unit) {
                    logger.error('Missing unit', app, floorplan, floorplan.units[i].ix)
                    continue
                }

                if (false && getAppMode() === AppMode.Local) {
                    stateName = Availability.Unavailable
                } else {
                    const { availabilityStateId } = unit
                    if (availabilityStateId in app.meta.stateMap) {
                        stateName = app.meta.stateMap[availabilityStateId].name
                    } else {
                        stateName = Availability.Unavailable
                    }

                    if (stateName != Availability.Unavailable && stateName != Availability.Sold) {
                        availableCount += 1
                    }
                }
                if (!(stateName in planUnits)) {
                    planUnits[stateName] = []
                }


                // Fixed query is first filter
                if (fixedQuery && fixedQuery[FloorplanFilter.Building]) {
                    // See if unit name differs from building
                    const buildingKey = transformUnitBuildingKey(app, unit)
                    if (fixedQuery[FloorplanFilter.Building].find((x) => x == buildingKey) == null) {
                        continue
                    }
                }
                allCount += 1
                planUnits[stateName].push(unit)

                // Filtered
                if (!query[UnitFilter.Floor]
                    && !query[UnitFilter.Exposure]
                    && !query[FloorplanFilter.Search]
                    && !query[FloorplanFilter.Garage]) {
                    // && !query[FloorplanFilter.Unit]) {
                    continue
                }

                filterSet = true

                if (query[UnitFilter.Floor]) {
                    const floors = new Set()
                    if (unit.floor.includes('-')) {
                        const split = unit.floor.split('-').map((x) => parseInt(x))
                        for (let i = split[0]; i < split[1]; i += 1) {
                            floors.add(i)
                        }
                    } else {
                        floors.add(parseInt(unit.floor))
                    }
                    if (floors.intersection(new Set(query[UnitFilter.Floor])).size == 0) {
                        continue
                    }
                }

                if (query[UnitFilter.Exposure]
                    && !query[UnitFilter.Exposure].find(
                        (x: string) => unit.exposure.includes(x.toLowerCase()),
                    )) {
                    continue
                }

                if (query[FloorplanFilter.Search]) {
                    const searchTerm = (Array.isArray(query[FloorplanFilter.Search]) ? query[FloorplanFilter.Search][0] : query[FloorplanFilter.Search]).simplify()
                    const unitTerm = unit.name.simplify()
                    if (unitTerm !== searchTerm) {
                        continue
                    }
                }

                if (query[FloorplanFilter.Garage]) {
                    const { floorplanVariationId } = floorplan.units[i]
                    const matchingGarage = floorplan.variations.find((y) => (floorplanVariationId == null || floorplanVariationId == y.id) && transformGarage(app, y.garage.toString()) == query[FloorplanFilter.Garage])
                    if (!matchingGarage) {
                        continue
                    }
                }

                /*if (query[FloorplanFilter.Unit]) {
                    const unitSet = new Set(query[FloorplanFilter.Unit])
                    if (!unitSet.has(unit.name)) {
                        continue
                    }
                }*/

                filteredCount += 1
                if (!(stateName in planUnitsFiltered)) {
                    planUnitsFiltered[stateName] = []
                }
                planUnitsFiltered[stateName].push(allUnits[floorplan.units[i].ix])
            }

            // Sort units
            Object.keys(planUnits).forEach((x) => {
                planUnits[x] = planUnits[x].sort((a, b) => sortUnitNumber(app, a, b))
            })
            Object.keys(planUnitsFiltered).forEach((x) => {
                planUnitsFiltered[x] = planUnitsFiltered[x].sort((a, b) => sortUnitNumber(app, a, b))
            })
            setUnits({ map: planUnits, count: allCount, availableCount: availableCount })

            if (filters) {
                if (filteredCount === 0 && !filterSet || (filteredCount == allCount)) {
                    setFilteredUnits(null)
                } else if (filteredCount < allCount) {//} || (filteredUnits != null && filteredUnits?.count != filteredCount)) {
                    setFilteredUnits({ map: planUnitsFiltered, count: filteredCount })
                }
            }
        }
        if (!floorplan.units || floorplan.units.length === 0) {
            // logger.error('NO UNITS', floorplan)
        }
    }, [
        allUnits,
        query,
        // query[UnitFilter.Floor],
        // query[UnitFilter.Exposure],
        // query[FloorplanFilter.Search],
        // query[FloorplanFilter.Unit],
        // query[FloorplanFilter.Garage],
        // query[FloorplanFilter.Beds],
        // query[FloorplanFilter.Baths],
        fixedQuery,
        _variation])

    if (!floorplan.variations || floorplan.variations.length === 0) {
        if (editing) {
            return <div key={floorplan.id} className="tile plan-tile editing noselect" onClick={handleFloorplan}>
                <h3>{floorplan.name}</h3>
                <h5>Edit Floorplan</h5>
            </div>
        }
        return <div key={floorplan.id} className="tile plan-tile noselect">
            <h3>{floorplan.name}</h3>
            <h2>No Variations</h2>
        </div>
    }

    function getFractionString(val: number) {
        if (val % 1 !== 0) {
            return <React.Fragment>{Math.floor(val)} <span className="fraction"><sup>1</sup>/<sub>2</sub></span></React.Fragment>
        }
        return <React.Fragment> {val.toString()} </React.Fragment>
    }

    function handleFloorplan(e: Event) {
        e.stopPropagation()
        const floorplanId = floorplan.id
        if (onFloorplan) {
            onFloorplan(floorplanId)
        }
    }

    function handleModelhome(e: Event) {
        e.stopPropagation()
        if (onModelhome) {
            onModelhome(variation.modelhomeId)
        }
    }
    function handleSpin(e: Event) {
        e.stopPropagation()
        if (onSpin) {
            onSpin(variation.spinId)
        }
    }

    function handleLoaded() {
        setLoaded(true)
    }

    async function handleFocusUnit(x) {
        if (onFocusUnit) {
            onFocusUnit(floorplan.id, x)
        }
        // dispatch(pushNotification({ status: NotificationStatus.Info, message: 'Filtering on unit ' + x }))
        // window.dispatchEvent(new CustomEvent(CustomEventType.FocusUnit, { detail: { floorplanId: floorplan.id, focusUnit: x } }))
    }

    function handleVariation(x) {
        if (x == null) {
            setVariation(null)
            if (onVariation) {
                onVariation(null)
            }
            return
        }
        const variation = floorplan.variations.find((y) => y.id == x)
        if (variation) {
            // e.stopPropagation()
            if (onVariation) {
                onVariation(variation)
            }
            setVariation(variation)
        }
    }

    function handleDetails() {
        window.dispatchEvent(new CustomEvent(CustomEventType.ShowInfo))
    }

    function nextVariation() {
        let idx = floorplan.variations.findIndex((x) => x.id == variation.id)
        if (idx != -1) {
            idx = (idx + 1) % floorplan.variations.length
            setVariation(floorplan.variations[idx])
        }
    }

    function prevVariation() {
        let idx = floorplan.variations.findIndex((x) => x.id == variation.id)
        if (idx != -1) {
            if (idx > 0) {
                idx = idx - 1
            } else {
                idx = floorplan.variations.length - 1
            }
            setVariation(floorplan.variations[idx])
        }
    }

    function getUnits(unitMap) {
        if (!unitMap) {
            return null
        }

        const list = []
        // Sort by state
        for (let i = 0; i < app.meta.availabilityStates.length; i += 1) {
            const stateName = app.meta.availabilityStates[i].name
            if (stateName in unitMap) {
                list.push({ element: <div className="unit-list-title"><span>{stateName}</span></div> })
                // Get all units
                for (let j = 0; j < unitMap[stateName].length; j += 1) {
                    const x: UnitData = unitMap[stateName][j]
                    let name = transformUnitName(app, x)
                    let overridePrice = ''
                    if (x.id in overrideMap) {
                        let o = overrideMap[x.id]
                        if (o.min == o.max) {
                            overridePrice = `- ${fnc.toMoney(overrideMap[x.id].min, true)}`
                        } else if (o.min < o.max) {
                            overridePrice = `- ${fnc.toMoney(overrideMap[x.id].min, true)} - ${fnc.toMoney(overrideMap[x.id].max, true)}`

                        }
                    } else if (Object.keys(overrideMap).length > 0) {
                        overridePrice = ` - ${fnc.toMoney(variation.price, true)}`
                    }

                    // Find
                    list.push({
                        className: (stateName === Availability.Unavailable || stateName === Availability.Sold) ? 'disabled' : '',
                        text: `${name} ${x.exposure ? `(${x.exposure ? x.exposure.toUpperCase() : 'N/A'}) ` : ''}${overridePrice}`,
                        value: x.name,
                    })
                }
            }
        }
        return list
    }

    function getPricing() {
        let minPrice = 1000000000000
        let maxPrice = 0
        if (props.variation) {
            if (variation.price) {
                minPrice = variation.price
                maxPrice = variation.price
            }
            for (let j = 0; j < variation.unitPriceOverrides.length; j += 1) {
                const o = variation.unitPriceOverrides[j]
                if (o.price) {
                    minPrice = Math.min(minPrice, o.price)
                    maxPrice = Math.max(maxPrice, o.price)
                }
            }
        } else {
            for (let i = 0; i < floorplan.variations.length; i += 1) {
                const v = floorplan.variations[i]
                if (v.price) {
                    minPrice = Math.min(minPrice, v.price)
                    maxPrice = Math.max(maxPrice, v.price)
                }
                for (let j = 0; j < v.unitPriceOverrides.length; j += 1) {
                    const o = v.unitPriceOverrides[j]
                    if (o.price) {
                        minPrice = Math.min(minPrice, o.price)
                        maxPrice = Math.max(maxPrice, o.price)
                    }
                }
            }
        }

        // If its greater there was no price configured
        if (minPrice <= maxPrice) {//} && userPermissions(UserRole.Pricing)) {
            return <div className="row">
                <div className="plan-tile-detail with-icon">
                    <span>
                        {minPrice == maxPrice && fnc.toMoney(variation.price, false)}
                        {minPrice < maxPrice && `${fnc.toMoney(minPrice, false)} - ${fnc.toMoney(maxPrice, false)}`}
                    </span>
                </div>
            </div>
        }
        return null
    }

    function getMoveInDate() {
        if (focusUnit) {
            const unitData = app.maps.unitName[focusUnit]
            if(unitData && unitData.moveInDate) {
                let moveInDate = fnc.dateFriendly(fnc.parseLocalDate(unitData.moveInDate))
                return <div className="row">
                    <div className="plan-tile-detail with-icon">
                        <span>
                            Move In Date: {moveInDate}
                        </span>
                    </div>
                </div>
            }
        }
    }

    function getShare() {
        if (!share) {
            return null
        }
        return <React.Fragment>
            <ShareButton
                app={app}
                alt
                noBg
                onlyEmail
                floorplan={floorplan}
                variation={variation} />
            {!salescenter && <ShareButton
                app={app}
                alt
                noBg
                floorplan={floorplan}
                variation={variation} />}
        </React.Fragment>
    }

    let attrs = `noselect ${minimal ? ' disabled' : ''}${className ? ` ${className}` : ''}`

    // Show 1st variation
    if (floorplan.variations.length > 0) {
        let style = {}
        if (tileStyle == ListStyle.Grid) {
            style = {
                opacity: loaded ? 1 : 0,
                // transform: `translateY(${loaded ? 0 : '0px'}`,
                // position: !loaded ? 'absolute' : 'relative',
                // left: !loaded ? '100%' : 'auto',
            }
            if (loaded) {
                attrs = `${attrs} animate__animated animate__fadeInZoom animate__fastest`
            }
        }
        // Sqft
        let min = 100000
        let max = 0
        let minTotal = 100000
        let maxTotal = 0
        if (props.variation && _variation != null) {
            min = variation.sqft
            max = variation.sqft
            minTotal = variation.sqft + variation.balconySqft
            maxTotal = variation.sqft + variation.balconySqft
        } else {
            for (let i = 0; i < floorplan.variations.length; i += 1) {
                const v = floorplan.variations[i]
                min = Math.min(v.sqft, min)
                max = Math.max(v.sqft, max)

                const total = v.sqft + (v.balconySqft ? v.balconySqft : 0)
                minTotal = Math.min(total, minTotal)
                maxTotal = Math.max(total, maxTotal)
            }
        }
        const interiorSqft = min === max ? `${min}` : `${min} - ${max}`
        const totalSqft = minTotal === maxTotal ? `${minTotal}` : `${minTotal}-${maxTotal}`

        // Garage
        let garageMin = 100000
        let garageMax = 0
        if (props.variation && _variation != null) {
            garageMin = variation.garage
            garageMax = variation.garage
        } else {
            for (let i = 0; i < floorplan.variations.length; i += 1) {
                const v = floorplan.variations[i]
                garageMin = Math.min(v.garage, garageMin)
                garageMax = Math.max(v.garage, garageMax)
            }
        }
        const garage = garageMin === garageMax ? `${garageMin}` : `${garageMin} - ${garageMax}`

        // Filter ranges
        const newRanges = {}
        const floorSet = new Set()
        const exposureSet = new Set()
        if (filters) {
            for (let i = 0; floorplan.units && i < floorplan.units.length; i += 1) {
                const unit = allUnits[floorplan.units[i].ix]
                if (!unit) {
                    continue
                }
                if (unit.floor) {
                    floorSet.add(unit.floor)
                }
                if (unit.exposure) {
                    exposureSet.add(unit.exposure[0].toUpperCase())
                }
            }
            newRanges[UnitFilter.Floor] = Array.from(floorSet)
            newRanges[UnitFilter.Exposure] = Array.from(exposureSet)
        }

        if (tileStyle === ListStyle.List) {
            switch (theme) {
                default:
                    return <tr key={floorplan.id} className={`tile item-list-item ${attrs}`} onClick={handleFloorplan} style={style}>
                        {favourite && <td data-field="favourite">
                            <FavouriteButton
                                app={app}
                                alt
                                noBg
                                floorplan={floorplan}
                                variation={variation} />
                        </td>}
                        <td data-field="name" className="plan-tile-detail">
                            <span>{floorplan.name}</span>
                        </td>
                        {listInterior && <React.Fragment>
                            <td data-field="sqft" className="plan-tile-detail">
                                {variation.sqft && <span>{interiorSqft}</span>}
                            </td>
                            <td data-field="totalSqft" className="plan-tile-detail">
                                {variation.balconySqft && <span>{totalSqft}</span>}
                            </td>
                        </React.Fragment>}
                        {!listInterior && <React.Fragment>
                            <td data-field="sqft" className="plan-tile-detail">
                                {variation.sqft && <span>{interiorSqft}</span>}
                            </td>
                        </React.Fragment>}
                        <td data-field="beds" className="plan-tile-detail with-icon">
                            {variation.beds && <span>{getFractionString(variation.beds)}{variation.den ? ' + D' : ''}</span>}
                        </td>
                        <td data-field="baths" className="plan-tile-detail with-icon">
                            {variation.baths && <span>{getFractionString(variation.baths)}</span>}
                        </td>
                        <td data-field="units" className="plan-tile-detail">
                            {(units && units.count > 0) ? <React.Fragment>
                                {filteredUnits && !focusUnit && <DropdownMenu
                                    className={`unit-list${onFocusUnit ? '' : ' disabled'}`}
                                    text={`Available ${unitDescriptor} (${filteredUnits.count})`}
                                    contentAnimation="fadeIn"
                                    value={query[FloorplanFilter.Unit]}
                                    buttonClass="alt"
                                    listLabel={unitDescriptor}
                                    items={getUnits(filteredUnits ? filteredUnits.map : null)} />}
                                {!filteredUnits && <DropdownMenu
                                    className={`unit-list${onFocusUnit ? '' : ' disabled'}`}
                                    // text={focusUnit ? `Unit ${focusUnit}` : `Available Units (${units.availableCount})`}
                                    text={`Available ${unitDescriptor} (${units.availableCount})`}
                                    onChange={handleFocusUnit}
                                    value={query[FloorplanFilter.Unit]}
                                    contentAnimation="fadeIn"
                                    buttonClass="alt"
                                    listLabel={unitDescriptor}
                                    items={getUnits(units ? units.map : null)} />}
                            </React.Fragment>
                                :
                                <span>N/A</span>}
                        </td>

                        <td data-field="buttons" className="plan-tile-buttons">
                            <Button icon="fas fa-vector-square" tertiary>Plan</Button>
                        </td>
                    </tr>
            }
        }
        switch (theme) {
            default:
                return <div key={floorplan.id} className={`tile plan-tile ${attrs}`} style={style} onClick={handleFloorplan} {...otherProps}>
                    <div className="row">
                        <div className="plan-tile-title">
                            <h3>{floorplan.name}</h3>
                            {/* {onFloorplanMedia && <DropdownMenu selected={currentFloorplan} items={variation.floorplans.map((x, ix) => ({text: x.name, value: ix}))} onChange={onFloorplanMedia}/>} */}
                            <div className="plan-tile-options">
                                {favourite
                                    && <FavouriteButton
                                        app={app}
                                        alt
                                        noBg
                                        floorplan={floorplan}
                                        variation={variation} />}
                                {getShare()}
                            </div>
                        </div>
                    </div>
                    {getPricing()}
                    {getMoveInDate()}
                    <div className="row">
                        <div className="plan-tile-detail with-icon">
                            {variation.sqft && size == ScreenSize.Mobile && <span>
                                <Icon noBg icon="fas fa-vector-square" />
                                {!variation.balconySqft && `${interiorSqft} sf`}
                                {variation.balconySqft && `Int./Total: ${interiorSqft}/${totalSqft} sf`}
                            </span>}
                            {variation.sqft && size != ScreenSize.Mobile && <span>
                                <Icon noBg icon="fas fa-vector-square" />

                                {variation.balconySqft && <React.Fragment>
                                    Interior: {interiorSqft} sf
                                    <Icon className="circle" noBg icon="fas fa-circle" />
                                    Total: {totalSqft} sf
                                </React.Fragment>}
                                {!variation.balconySqft && <React.Fragment>
                                    Total: {interiorSqft} sf
                                </React.Fragment>}
                            </span>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="plan-tile-detail with-icon">
                            {variation.beds && <span><Icon noBg icon="fas fa-bed" />{getFractionString(variation.beds)}{variation.beds > 1 ? 'Beds' : 'Bed'}{variation.den ? ' + D' : ''}</span>}
                            {variation.baths && <Icon noBg className="circle" icon="fas fa-circle" />}
                            {variation.baths && <span><Icon noBg icon="fas fa-bath" />{getFractionString(variation.baths)}{variation.baths > 1 ? 'Baths' : 'Bath'}</span>}
                            {variation.garage && <Icon noBg className="circle" icon="fas fa-circle" />}
                            {variation.garage && <span><Icon noBg icon="fas fa-car" />{props.variation ? transformGarage(app, variation.garage) : garage}</span>}
                        </div>
                    </div>
                    {!editing && ((units && units.count > 0) || (showVariations && floorplan.variations.length > 1)) && <div className="row units-row" style={{ marginTop: '5px' }}>
                        {units && units.count > 0 && <div className="plan-tile-detail">
                            {filteredUnits && <DropdownMenu
                                className={`unit-list${onFocusUnit ? '' : ' disabled'}`}
                                // text={focusUnit ? `Unit ${focusUnit}` : `Filtered Units (${filteredUnits.count})`}
                                text={`Filtered ${unitDescriptor} (${filteredUnits.count})`}
                                contentAnimation="fadeIn"
                                value={focusUnit}
                                onChange={handleFocusUnit}
                                buttonClass="alt"
                                listLabel={unitDescriptor}
                                items={getUnits(filteredUnits ? filteredUnits.map : null)} />}
                            {!filteredUnits && <DropdownMenu
                                className={`unit-list${onFocusUnit ? '' : ' disabled'}`}
                                // text={focusUnit ? `Unit ${focusUnit}` : `Available Units (${units.availableCount})`}
                                text={`Available ${unitDescriptor} (${units.availableCount})`}
                                value={focusUnit}
                                onChange={handleFocusUnit}
                                contentAnimation="fadeIn"
                                buttonClass="alt"
                                listLabel={unitDescriptor}
                                items={getUnits(units ? units.map : null)} />}
                            {/* {filters && <FilterOptions key="filters" queryType={QueryType.Floorplans} filters={[UnitFilter.Floor, UnitFilter.Exposure]} ranges={newRanges} forceTile/> } */}
                        </div>}
                        {showVariations && floorplan.variations.length > 1 && <div className="plan-tile-detail">
                            <div className="row">
                                {<Toggle value={_variation?.id} items={floorplan.variations.map((x, ix) => ({ text: x.name.toReadable(), value: x.id }))} onChange={handleVariation} toggleOff />}
                                {/* {!mobileView && <Toggle value={_variation?.id} items={floorplan.variations.map((x, ix) => ({ text: x.name.toReadable(), value: x.id }))} onChange={handleVariation} toggleOff />} */}
                                {/* {mobileView && floorplan.variations.map((x, ix) => <Button key={ix} className={_variation?.id == x.id ? 'selected' : ''} onClick={() => handleVariation(x.id)}>{x.name.toReadable()}</Button>)} */}
                            </div>
                        </div>}
                    </div>}
                    {!minimal && media && <div className="row">
                        <div className="plan-tile-preview" style={admin ? { cursor: 'zoom-in' } : null}>
                            <Media app={app} mediaId={media.id} thumb thumbSize={512} onLoad={handleLoaded} fadeIn hoverShow={admin} />
                            {!props.variation && showFeaturedMedia && floorplan.variations.length > 1 && <IconButton noBg className="left-arrow" icon="fas fa-chevron-left" onClick={(e) => { e.stopPropagation(); prevVariation() }} />}
                            {!props.variation && showFeaturedMedia && floorplan.variations.length > 1 && <IconButton noBg className="right-arrow" icon="fas fa-chevron-right" onClick={(e) => { e.stopPropagation(); nextVariation() }} />}
                        </div>
                    </div>}
                    {!minimal && !editing && <div className="row button-row" style={{ flexDirection: 'column' }}>
                        {!props.variation && showFeaturedMedia && floorplan.variations.length > 1 && <div className="row variation-selector">
                            {/* <span>{variation.name}</span> */}
                            <div className="row">
                                {floorplan.variations.map((x, ix) => <IconButton key={ix} className={_variation?.id == x.id ? 'selected' : ''} icon="fas fa-circle" onClick={(e) => {
                                    e.stopPropagation();
                                    if (onVariation) {
                                        onVariation(x);
                                    }
                                    setVariation(x)
                                }} />)}
                            </div>
                        </div>}
                        {showFeaturedMedia && variation.media.length > 1 && <div className="row variation-selector">
                            {variation.media.map((x, ix) => <IconButton key={ix} className={featuredMediaIndex == ix ? 'selected' : ''} icon="fas fa-circle" onClick={(e) => { e.stopPropagation(); setFeaturedMediaIndex(ix) }} />)}
                        </div>}

                        <div className="plan-tile-buttons">
                            {variation.modelhomeId && <Button alt icon="fas fa-walking" onClick={handleModelhome}>Tour</Button>}
                            {variation.spinId && <Button alt icon="fas fa-home" onClick={handleSpin}>Spin</Button>}
                            <Button icon="fas fa-vector-square" alt link={!compare ? floorplanUrl : null}>Plan</Button>
                            {/*<FavouriteButton
                                app={app}
                                alt
                                text
                                floorplan={floorplan}
                                variation={variation}/>*/}
                        </div>
                    </div>}
                </div>
        }
    }

    return <div key={floorplan.id} className="tile plan-tile" onClick={handleFloorplan}>
        <h3>{floorplan.name}</h3>
        <h2>No Variations</h2>
    </div>
}

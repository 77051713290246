import React, { useEffect, useState, useRef } from 'react'
import { getMediaUrl } from 'helpers/config'
import { AppData, MediaData, SpinData, SpinQuality } from 'app/types'
import { Media } from 'components'
import { SpinControl } from './index.ts'
import { Toggle } from 'components/Core/Toggle'

interface SpinPreviewProps {
    app: AppData,
    spin: SpinData,
    media: MediaData,
}

function SpinPreview(props: SpinPreviewProps) {
    const { app, spin, media, fullscreen } = props
    const path = `${getMediaUrl(app)}/`

    const [index, setIndex] = useState(0)
    const [updateKey, setUpdateKey] = useState(null)
    const [quality, setQuality] = useState('low')

    const spinControl = useRef()

    useEffect(() => {
        spinControl.current = new SpinControl()

        // Set dummy values
        const viewState = { quality: SpinQuality.High, rotation: 0 }
        const view = { position: [0, 0, 0] }
        spinControl.current.setView(view, viewState, setUpdateKey)
    }, [])

    useEffect(() => {
        // Calculate index from rotation
        let rotation = spinControl.current.rotation
        while (rotation > 100) {
            rotation -= 100
        }
        while (rotation < 0) {
            rotation += 100
        }
        const newIndex = Math.floor(media.fileCount * (100 - rotation / 100))
        setIndex(newIndex)
    }, [updateKey])

    function handleMouseDown(e) {
        spinControl.current.handleDown({ event: e })
    }

    function handleMouseUp(e) {
        spinControl.current.handleUp({ event: e })
    }

    function handleMouseMove(e) {
        spinControl.current.handleMove({ event: e })
    }

    function getImage(i: number, show: boolean) {
        const paddedIndex = String(i).padStart(3, '0')
        const finalQuality = fullscreen ? quality : 'low'
        const url = `${path}${media.link}/${finalQuality}/${media.link}_${finalQuality}_${paddedIndex}.jpg`
        let style = {}
        if (!show) {
            style.visibility = 'hidden'
        }
        return <Media key={i} url={url} style={style} />
    }

    function getImages() {
        const images = []
        for (let i = 0; i < media.fileCount; i += 1) {
            images.push(getImage(i, i == index))
        }

        /*const span = 10
        for (let i = index - span; i < index + span; i += 1) {
            const fixedIndex = i
            if (i < 0) {
                fixedIndex += media.fileCount
            } else if (i > media.fileCount) {
                fixedIndex -= media.fileCount
            }
            images.push(getImage(fixedIndex, fixedIndex == index))
        }*/
        return images
    }
    return <div className="spin-preview noselect" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>
        {getImages()}
        {/* <img key={url} src={url} /> */}
        {fullscreen && <div className="bottom-left">
            <Toggle items={['high', 'med', 'low'].map((x) => ({ value: x, text: x }))} value={quality} onChange={setQuality} />
        </div>}
    </div>
}

export { SpinPreview as default }
import React, { useState, useEffect } from 'react'
import { Button, CloseButton, DropdownMenu } from 'components'
import * as fnc from 'helpers/fnc'
import { DatePicker } from 'rc-datepicker'
import 'rc-datepicker/lib/style.css'
import moment from 'moment'
import { CustomEventType } from 'app/types'

interface DateSelector {
    selected?: { [key: string]: string },
    range?: [date],
    onChange?: { [key: string]: string },
    className?: string,
    pickText?: string,
    collapse?: boolean,
    buttonClass?: string,
    clear: boolean,
}
export function DateSelector(props: DateRangeProps) {
    const { pickText, collapse, clear } = props

    const [date, setDate] = useState(null)

    function handleChange(x) {
        if(x)  {
            const newDate = moment(x).startOf('day').toDate()
            setDate(newDate)
            if (props.onChange) {
                props.onChange(newDate)
            }
            window.dispatchEvent(new CustomEvent(CustomEventType.CloseAllMenus))
        } else {
            setDate(null)
            if (props.onChange) {
                props.onChange(null)
            }
        }
    }

    function handleToday() {
        setDate(new Date())
    }

    useEffect(() => {
        if (props.selected != null) {
            setDate(fnc.parseLocalDate(props.selected))
        }
    }, [])
    const pickElem = <div className="date-picker-wrapper" onClick={(e: Event) => e.stopPropagation()}>
        <div className="date-picker">
            {!collapse && <h4>{date ? fnc.dateFriendly(date) : (pickText ? pickText : 'Pick a Date')}</h4>}
            <DatePicker
                onChange={handleChange}
                value={date}
                minDate={props.range && props.range.min}
                maxDate={props.range && props.range.max} />
            {clear && <Button onClick={() => handleChange(null)}>Clear</Button>}
        </div>
    </div>

    if (collapse) {
        const buttonClass = props.buttonClass != null ? props.buttonClass : 'button-tertiary'
        return <DropdownMenu text={date ? fnc.dateFriendly(date) : (pickText ? pickText : 'Pick a Date')} className="date-picker-dropdown" buttonClass={buttonClass} items={[{
            element: pickElem,
        }]} />
    } else {
        return pickElem
    }
}
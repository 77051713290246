import React, { useState, Suspense, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'

import { Split, Spinner, MEssage } from 'components'
import { applyQuery, applyFixedQuery, navigateAsync, toggleFullscreen, toggleShowBuilding } from 'actions/appActions'
import { setFocusUnit } from 'actions/spinActions'

import { FloorplanList } from 'views/FloorplanPage/FloorplanList'
import { FloorplanView } from 'views/FloorplanPage/FloorplanView'
import { RootState, PageType, QueryType, UnitFilter, FloorplanFilter, ScreenProperties } from 'app/types'
import { transformUnitBuildingKey } from 'app/transformers'
import { setQueryHelper } from 'helpers/query'
import * as fnc from 'helpers/fnc'
import { ModelhomeView } from 'views/ModelhomePage/ModelhomeView'

const Spin = React.lazy(() => import('components/Spin/Spin'))

interface BuildingPageProps {
    app: AppData,
    onlyFavourites: boolean,
    dataLink: string,
    splitIndex: number,
    breadCrumbs?: boolean,
    pageType: PageType,
}
export function BuildingPage(props: BuildingPageProps) {
    const { app, dataLink, extraLink, splitIndex, pageType } = props

    const dispatch = useAppDispatch()
    const [filteredUnits, setFilteredUnits] = useState(null)
    const [filteredBuildings, setFilteredBuildings] = useState(null)
    const [allPlans, setAllPlans] = useState(null)

    const spinLink = 'site'
    const viewLink = 'units'
    const spin = app.spins.find((x) => x.link == spinLink)
    const view = spin ? spin.views.find((x) => x.link == viewLink) : null
    const sIdx = splitIndex != null ? splitIndex : 0
    const hasSpin = splitIndex == null && spin != null && view != null

    const spinState = useAppSelector((state: RootState) => spinLink in state.spin.spinState[sIdx] ? state.spin.spinState[sIdx][spinLink] : null)
    const viewState = useAppSelector((state: RootState) => spinState && spinState.currentView in state.spin.viewState[sIdx] ? state.spin.viewState[sIdx][spinState.currentView] : null)

    const showBuilding = useAppSelector((state: RootState) => state.app.showBuilding)
    const fullscreen = useAppSelector((state: RootState) => state.app.fullscreen)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const query = useAppSelector((state: RootState) => state.app.queries[QueryType.Floorplans])

    const [showModelhome, setShowModelhome] = useState(null)
    function handleModelhome(x) {
        setShowModelhome(x)
    }
    /*useEffect(() => {
        window.addEventListener(CustomEventType.FocusUnit, handleWindowFocus)
        return () => {
            window.removeEventListener(CustomEventType.FocusUnit, handleWindowFocus)
        }
    })*/

    async function handleWindowFocus(e) {
        const { floorplanId, focusUnit } = e.detail
        if (spinState) {
            // If we're not viewing a floorplan, go there
            await dispatch(navigateAsync({ app: app, pageType: pageType != null ? pageType : PageType.Building, options: { floorplanId } }))
            dispatch(setFocusUnit({ spinLink, viewLink: spinState.currentView, focusUnit }))
        }
    }

    async function handleFocusUnit(floorplanId: string, focusUnit: UnitData) {
        if (spinState) {
            // If we're not viewing a floorplan, go there
            await dispatch(navigateAsync({ app: app, pageType: pageType != null ? pageType : PageType.Building, options: { floorplanId } }))
            dispatch(setFocusUnit({ spinLink, viewLink: spinState.currentView, focusUnit }))
        }
    }

    function handleFiltered(plans) {
        setAllPlans(plans)
    }

    useEffect(() => {
        if (!allPlans) {
            return
        }
        // Get filterd units from filtered plans
        const newFilteredUnits = new Set()
        const newAllUnits = new Set()
        const newFilteredBuildings = new Set()
        allPlans.forEach((x) => {
            for (let i = 0; i < x.units.length; i += 1) {
                const unit = app.maps.unit[x.units[i].id]
                if (!unit) {
                    continue
                }
                const buildingKey = transformUnitBuildingKey(app, unit)
                if (buildingKey != null) {
                    newFilteredBuildings.add(buildingKey)
                }


                newAllUnits.add(unit.name)
                if (query[UnitFilter.Floor]) {
                    const floors = new Set()
                    if (unit.floor.includes('-')) {
                        const split = unit.floor.split('-').map((x) => parseInt(x))
                        for (let i = split[0]; i < split[1]; i += 1) {
                            floors.add(i)
                        }
                    } else {
                        floors.add(parseInt(unit.floor))
                    }
                    if (floors.intersection(new Set(query[UnitFilter.Floor])).size == 0) {
                        continue
                    }
                }

                if (query[UnitFilter.Exposure]
                    && !query[UnitFilter.Exposure].find(
                        (x: string) => unit.exposure.includes(x.toLowerCase()),
                    )) {
                    continue
                }

                if (query[FloorplanFilter.Search]) {
                    const searchTerm = query[FloorplanFilter.Search] ? query[FloorplanFilter.Search].simplify() : null
                    const unitTerm = unit.name.simplify()
                    if (unitTerm !== searchTerm) {
                        continue
                    }
                }

                if (viewState && viewState.source) {
                    // Get unit source
                    let building = transformUnitBuildingKey(app, unit)
                    if (building != viewState.source.link) {
                        continue
                    }
                }

                newFilteredUnits.add(unit.name)
            }
        })
        if (newFilteredUnits.size != newAllUnits.size) {
            setFilteredUnits(newFilteredUnits)
        } else {
            setFilteredUnits(null)
        }

        if (newFilteredBuildings.size > 0) {
            setFilteredBuildings(newFilteredBuildings)
        } else {
            setFilteredBuildings(null)
        }

        // Update query
        if (viewState && viewState.source) {
            dispatch(applyFixedQuery({ query: { [FloorplanFilter.Building]: [viewState.source.link] }, type: QueryType.Floorplans }))
        }

        return () => {
            dispatch(applyFixedQuery({ query: {}, type: QueryType.Floorplans }))
        }
    }, [allPlans, viewState && viewState.source])

    function handleFullscreen(x) {
        if (screen.fullscreenSupport) {
            fnc.toggleFullscreen(x)
        } else {
            dispatch(toggleFullscreen())
        }
    }

    function handleShowBuilding() {
        dispatch(toggleShowBuilding())
    }


    let focusUnit = null
    if (showBuilding && viewState) {
        focusUnit = viewState.focusUnit
    }

    let spinElement = null
    if (showBuilding && splitIndex == null) {
        // Get focused units via floorplan link
        let focusSet = new Set()
        if (dataLink) {
            const floorplan = app.maps.floorplanLink[dataLink]
            if (floorplan) {
                floorplan.units.forEach((x) => {
                    focusSet.add(x.name)
                    const buildingKey = transformUnitBuildingKey(app, x)
                    if (buildingKey != null) {
                        focusSet.add(buildingKey)
                    }
                })
            }
            // If at building root, add buildings
        } else if (viewState && viewState.source == null) {
            if (filteredBuildings) {
                focusSet = focusSet.union(filteredBuildings)
            }
            if (filteredUnits) {
                focusSet = focusSet.union(filteredUnits)
            }
        }

        // Include building code if relevant
        spinElement = <Suspense fallback={<Spinner overlay invert />}>
            <Spin
                app={app}
                spinLink={spinLink}
                viewLink={viewLink}
                splitIndex={splitIndex}
                focusSet={focusSet}
                splitView={true}
                filteredUnits={filteredUnits}
                pageType={pageType != null ? pageType : PageType.Building}
                onFullscreen={handleFullscreen} />
        </Suspense>

        if (fullscreen) {
            return <div className="" style={{ width: '100%', height: '100%' }}>
                {spinElement}
            </div>
        }
    }

    let floorplanElement = null
    if (showModelhome) {
        floorplanElement = <ModelhomeView app={app} dataLink={showModelhome} splitIndex={splitIndex} onClose={() => setShowModelhome(null)} fullscreen={!showBuilding} onFullscreen={handleShowBuilding} />
    } else if (props.dataLink) {
        floorplanElement = <FloorplanView key={dataLink} focusUnit={focusUnit} withBuilding={hasSpin} onFilter={handleFiltered} onFocusUnit={handleFocusUnit} onModelhome={handleModelhome} {...props} />
    } else {
        floorplanElement = <FloorplanList key={0} withBuilding={hasSpin} onFilter={handleFiltered} onFocusUnit={handleFocusUnit} onModelhome={handleModelhome} {...props} />
    }

    return <Split id="spin-split">
        {spinElement}
        {floorplanElement}
    </Split>

    return floorplanElement
}

import React, { useEffect, useState, useRef } from 'react'
import { IconButton, Button } from 'components'
import { rootCertificates } from 'tls'
import { CustomEventType } from 'app/types'
import { useAppSelector, useLocalStorage } from 'app/hooks'

interface ScrollHelperProps {
    scrollElem: JSX.Element
    pageLink: string,
    onScrollInitialize: (scroll: number) => void
}
const AWAY_THRESHOLD = 200
export function ScrollHelper(props: ScrollHelperProps) {
    const { scrollElem, pageLink, onScrollInitialize } = props
    const [direction, setDirection] = useState(-1)
    const [initialized, setInitialized] = useState(false)
    const [scroll, setScroll] = useLocalStorage(`scroll-${pageLink}`, 0)
    const screen = useAppSelector((state: RootState) => state.app.screen)

    const prevScroll = useRef(0)
    const prevDif = useRef(0)

    // Delay intiailization
    useEffect(() => {
        setInitialized(false)
        const timeout = setTimeout(() => {
            setInitialized(true)
        }, 500)
        return () => {
            clearTimeout(timeout)
        }
    }, [])

    useEffect(() => {
        if (!scrollElem) {
            return
        }
        // Set initial scroll if cached
        // Check for hash anchor
        if (window.location.hash) {
            // Wait a moment for dynamic content to render
            const timeout = setTimeout(() => {
                let finalScroll = scroll
                const elem = document.getElementById(window.location.hash.substring(1))
                if (elem) {
                    finalScroll = elem.offsetTop - 100
                }

                if (onScrollInitialize) {
                    onScrollInitialize(finalScroll)
                }
            }, 100)
            return () => {
                clearTimeout(timeout)
            }
        } else if (onScrollInitialize) {
            onScrollInitialize(scroll)
        }
    }, [scrollElem, pageLink])


    useEffect(() => {
        if (!scrollElem) {
            return
        }
        const elem = scrollElem
        elem.addEventListener('scroll', handleScroll)
        return () => {
            elem.removeEventListener('scroll', handleScroll)
            // if (scrollElem) {
            // scrollElem.removeEventListener('scroll', handleScroll)
            // }
        }
    }, [scrollElem && scrollElem, direction])

    function scrollUp() {
        scrollElem.scrollTo({ top: 0 })//, behavior: 'smooth' })
    }

    function scrollDown() {
        scrollElem.scrollTo({ top: scrollElem.scrollTop + scrollElem.clientHeight, behavior: 'smooth' })
    }

    function handleScroll(e) {
        const newDir = scrollElem.scrollTop > 10 ? 1 : -1
        if (newDir !== direction) {
            setDirection(newDir)
        }

        if (prevScroll.current != null) {
            if (scrollElem.scrollTop >= AWAY_THRESHOLD && prevScroll.current < AWAY_THRESHOLD) {
                window.dispatchEvent(new CustomEvent(CustomEventType.ScrollAway, { detail: false }))
            } else if (scrollElem.scrollTop < AWAY_THRESHOLD && prevScroll.current >= AWAY_THRESHOLD) {
                window.dispatchEvent(new CustomEvent(CustomEventType.ScrollAway, { detail: true }))
            }

            /*if (scrollElem.scrollTop < prevScroll.current && prevDif.current > 0) {
                window.dispatchEvent(new CustomEvent(CustomEventType.MenuTutorial))
            }*/
        }

        prevDif.current = scrollElem.scrollTop - prevScroll.current
        prevScroll.current = scrollElem.scrollTop
        setScroll(scrollElem.scrollTop)
    }

    if (!initialized || !scrollElem || scrollElem.offsetHeight >= (scrollElem.scrollHeight - 200)) {
        return null
    }

    const icon = 'fas fa-arrow-down'
    let style = {}
    if (screen.isMobile) {
        style.left = `calc(${document.body.clientWidth / 2}px / var(--zoom)`
    }
    return <div className={`scroll-helper fadeIn${direction == 1 ? ' flip' : ''}`}>
        <IconButton tertiary icon={icon} onClick={direction === 1 ? scrollUp : scrollDown} style={style} />
    </div>
}
import React, { useState, useEffect } from 'react'
import { AnalyticsEvent, AppData, CustomContentType, CustomEventType, MediaType, PageType, PromptOptions, ThumbSize } from 'app/types'
import { Media, SlideShow, WaterMark, Button } from 'components'
import { IntersectionHelper } from 'views/DynamicPage/IntersectionHelper'
import { Link } from 'react-router-dom'
import { showPrompt } from 'actions/appActions'
import { useAppDispatch } from 'app/hooks'
import { CustomContent } from './CustomContent'
import { Provinces } from 'app/constants'

export const KingsleySquareContentData = {
    'about': [
        {
            type: CustomContentType.Heading,
            media: [{galleryLink: 'splash'}]
        },
        {
            type: CustomContentType.ProjectInfo,
            media: [{galleryLink: 'gallery2', index: 0}],
        }
    ]
}
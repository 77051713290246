import React, { useState, useEffect } from 'react'
import { AnalyticsEvent, AppData, CustomContentType, CustomEventType, MediaType, PageType, PromptOptions, ThumbSize } from 'app/types'
import { Media, SlideShow, WaterMark, Button } from 'components'
import { Link } from 'react-router-dom'
import { showPrompt } from 'actions/appActions'
import { useAppDispatch } from 'app/hooks'
import { CustomContent } from './CustomContent'
import { Provinces } from 'app/constants'

export const WhitbyGroveContentData = {
    'about': [
        {
            type: CustomContentType.Header,
            media: [{ galleryLink: 'gallery' }],
            alt: true,
        },
        {
            type: CustomContentType.ProjectInfo,
            title: 'Status Update',
            body: 'Now Selling',
            links: [{ label: 'Request Info', link: "#request", icon: "fas fa-paper-plane" }],
            media: [{ galleryLink: 'highlight', index: 0 }]
        }
    ],
}
import React, { useEffect, useState, useRef } from 'react'
import { Vector3, Color3 } from '@babylonjs/core'
import { SpinIcon } from './SpinIcon'

import '@babylonjs/loaders/OBJ'

import { getElementIcon, registerMeshInput } from './babylon'
import { SpinData, SpinViewData, SpinElementData, AppData, SpinElementType } from 'app/types'

interface SpinElementProps {
    app: AppData,
    id: string,
    spin: React.RefObject,
    view: React.RefObject
    element: SpinElementData,
    media: Dict,
    admin: boolean,
}

export function SpinElement(props: SpinElementProps) {
    const {
        app,
        element,
        spin,
        view,
        media,
        admin,
    } = props

    const {
        spinElementTypeId,
        link,
        size,
        radius,
        position,
        rotation,
    } = element

    if (!position) {
        return null
    }

    const [mesh, setMesh] = useState(null)
    const [material, setMaterial] = useState(null)
    const [hover, setHover] = useState(false)
    const meshAlt = useRef()

    useEffect(() => {
        return () => {
            meshAlt.current.dispose()
            meshAlt.current = null
        }
    }, [])

    useEffect(() => {
        if (!mesh || !material) {
            return
        }

        if (!mesh.initialized) {
            // mesh.hover = false
            mesh.hover = true
            mesh.elementId = link
            mesh.initialized = true
            mesh.directHover = false
            if (radius == 0 && (size[0] == 0 || size[1] == 0 || size[2] == 0)) {
                mesh.isPickable = false
                mesh.visibility = 0
            } else {
                mesh.isPickable = true
            }

            if (admin) {
                material.alpha = 0.5
                material.diffuseColor = Color3.Red()
                material.emissiveColor = Color3.Red()
            } else {
                switch(spinElementTypeId) {
                    case SpinElementType.View:
                        material.alpha = 1
                        material.diffuseColor = Color3.White()
                        material.emissiveColor = Color3.White()
                        break
                }
            }
        }
        meshAlt.current = mesh


        const cleanUp = registerMeshInput(mesh,
            (_) => {
                hoverMesh(true, true)
            },
            (_) => {
                hoverMesh(false, true)
            },
        )

        return () => {
            cleanUp()
        }
    }, [mesh, material])

    useEffect(() => {
        if (!mesh) {
            return
        }

        if (radius > 0) {
            mesh.scaling = new Vector3(radius, radius, radius)
        } else if (size) {
            mesh.scaling = new Vector3(size[0], size[1], size[2])
            mesh.rotation = new Vector3(rotation[0], rotation[1], rotation[2])
        }
        mesh.position = new Vector3(position[0], position[1], position[2])
    }, [mesh, element])

    function hoverMesh(hover, direct = false) {
        mesh.directHover = direct
        setHover(hover)
    }

    function getIcon() {
        if (!mesh || !element.iconDiffuseMediaId) {
            return null
        }
        const icon = getElementIcon(element, mesh, media)
        return <SpinIcon
            app={app}
            key="icon"
            id={link}
            scale={view.current.scale}
            hover={hover && mesh.directHover}
            onHover={(hover) => hoverMesh(hover)}
            {...icon}/>
    }
    function getMesh() {
        if (radius > 0) {
            return <sphere key="sphere" ref={setMesh} size={1} position={new Vector3(position[0], position[1], position[2])} renderingGroupId={3} isPickable={true} >
                <standardMaterial name='def' alpha={0} ref={setMaterial}/>
            </sphere>
        }
        return <box key="box" ref={setMesh} size={1} position={new Vector3(position[0], position[1], position[2])} renderingGroupId={3} isPickable={true} >
            <standardMaterial name='def' alpha={0} ref={setMaterial}/>
        </box>
    }

    return <React.Fragment>
        {getMesh()}
        {getIcon()}
    </React.Fragment>
}

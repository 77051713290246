import { logger } from 'helpers/logger'
import { getMapsApiKey, getReCaptchaSiteKey, getSentryDSN } from 'helpers/config'
import * as fnc from 'helpers/fnc'


let mapsInitialized = false
window.initMaps = (x) => {
    mapsInitialized = true
}
export function loadMapScripts() {
    return fnc.loadScript('googleMaps', `https://maps.googleapis.com/maps/api/js?key=${getMapsApiKey()}&libraries=places,geometry&callback=initMaps`)
        .then(() => {
            return new Promise((resolve, reject) => {
                // Need this because it seems to take a moment to register
                const tryMaps = (tries = 5) => {
                    try {
                        if (window.google && window.google.maps && mapsInitialized) {
                            resolve()
                        } else {
                            throw new Error('Missing window.google.maps')
                        }
                    } catch (e) {
                        if (tries > 0) {
                            setTimeout(() => {
                                tryMaps(tries - 1)
                            }, 1000)
                        } else {
                            reject('Failed to load maps', e)
                        }
                    }
                }
                tryMaps()
            })
        })
}
export function loadBabylonJSScripts() {
    return Promise.all([
        fnc.loadScript('babylonjs-A', 'https://cdn.babylonjs.com/babylon.js'),
        fnc.loadScript('babylonjs-B', 'https://cdn.babylonjs.com/loaders/babylonjs.loaders.min.js'),
        fnc.loadScript('babylonjs-C', 'https://code.jquery.com/pep/0.4.3/pep.js'),
    ])
}

let gapiLoaded = false
export function loadOAuthScripts() {
    return new Promise((resolve, reject) => {
        fnc.loadScript('oauth', 'https://apis.google.com/js/platform.js')
            .then(() => {
                // Need this because it seems to take a moment to register
                const tryGAPI = (tries = 5) => {
                    try {
                        if (gapi) {
                            if (!gapiLoaded) {
                                gapiLoaded = true
                                gapi.load('auth2', resolve)
                            }
                        } else {
                            throw new Error('Missing gapi')
                        }
                    } catch (e) {
                        if (tries > 0) {
                            setTimeout(() => {
                                tryGAPI(tries - 1)
                            }, 100)
                        }
                    }
                }
                tryGAPI()
            })
            .catch(reject)
    })
}

export function loadGoogleIdentityScripts() {
    return new Promise((resolve, reject) => {
        fnc.loadScript('googleIdentity', 'https://accounts.google.com/gsi/client')
            .then(() => {
                const tryGoogle = (tries = 5) => {
                    try {
                        if (google) {
                            resolve()
                        } else {
                            throw new Error('Missing google identity')
                        }
                    } catch (e) {
                        if (tries > 0) {
                            setTimeout(() => {
                                tryGoogle(tries - 1)
                            }, 100)
                        }
                    }
                }
                resolve()
            })
            .catch(reject)
    })
}


function hotjarKey(key, hotjarId) { return key = `hotjar_${key}:${hotjarId}` }
export function loadHotjar(k, hotjarId) {
    const key = hotjarKey(k, hotjarId)
    logger.info(`Try Init Hotjar ${key} - prev: ${window.hotjarKey}`)
    const scriptKey = `${key}_script`
    // Prevent double load
    if (window.hotjarKey == key) {
        logger.info(`Hotjar ${key} already set up`)
        return
    }

    // If exists, clear it
    if (window.hotjarId != null) {
        const prevKey = `${window.hotjarId}_script`
        const existing = document.getElementById(prevKey)
        if (existing) {
            logger.info(`Remove previous hotjar ${prevKey}`)
            existing.remove()
        } else {
            logger.error(`Failed to remove previous hotjar ${prevKey}`)
        }

        // Suspected important variables (?)
        try {
            h.hj = null
            h._hjSettings = null
            h.hjSiteSettings = null
            h.hjBootstrap = null
            h.hjBootstrapCalled = null
            h.hjLazyModules = null
        } catch (e) {
            logger.error("Failed to clear hotjar variables", e)
        }

        window.hotjarKey = null
        window.hotjarId = null
    }

    logger.info(`Initialize Hot Jar ${key}`)
    window.hotjarKey = key
    window.hotjarId = hotjarId
    window.hj = window.hj || function () { (window.hj.q = window.hj.q || []).push(arguments) }
    window._hjSettings = { hjid: hotjarId, hjsv: 6 }
    const src = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`
    return fnc.loadScript(scriptKey, src)
        .catch(logger.error)
}

export function getHotjarUserId() {
    try {
        return window.hj.globals.get('userId')
    } catch (e) {
    }
    return null
}

// function gtag() {
// window.dataLayer.push(arguments)
// }

function googleTagId(key) { return `google_tag_${key}` }

export function loadGoogleTag(k, tagIds) {
    // Initialize
    if (!window.googleTagId) {
        window.googleTagId = []
    }

    const key = googleTagId(k)
    // logger.info(`Try Init Google Tags ${key} - prev: ${window.googleTagKey}`)
    const scriptKey = `${key}_script`
    // Prevent double load
    if (window.googleTagKey == key) {
        logger.info(`Google Tag ${key} already set up`)
        return
    }

    // Remove previous
    if (window.googleTagKey != null && window.googleTagKey != key) {
        const prevKey = `${window.googleTagId}_script`
        const existing = document.getElementById(prevKey)
        if (existing) {
            logger.info(`Remove previous google tags ${prevKey}`)
            existing.remove()
        } else {
            logger.error(`Failed to remove previous google tags ${prevKey}`)
        }
        window.dataLayer = null
        window.googleTagKey = []
        window.googleTagId = nul
    }

    window.googleTagId = tagIds
    logger.info(`Initialize Google Tags ${key} for Ids ${tagIds.join(', ')}`)
    window.googleTagKey = key
    return fnc.loadScript(scriptKey, `https://www.googletagmanager.com/gtag/js?id=${tagIds[0]}`)
        .then((x) => {
            window.dataLayer = window.dataLayer || []

            function gtag() { dataLayer.push(arguments); }

            gtag('js', new Date())
            tagIds.forEach((x) => {
                gtag('config', x)
            })
        })
        .catch((e) => {
            logger.error(`GTag initialization failed: ${e}`)
        })
}

export function googleTagReportConversion(key, tagId, conversionId) {
    key = googleTagId(key)
    if (window.googleTagKey != key || !window.googleTagId || !window.googleTagId.includes(tagId)) {
        return
    }
    logger.info(`Record Google Conversion ${key}/${conversionId}`)
    var callback = function () {
        if (typeof (url) != 'undefined') {
            window.location = url
        }
    }
    try {
        function gtag() { dataLayer.push(arguments) }

        gtag('event', 'conversion', {
            'send_to': `${tagId}/${conversionId}`,
            'event_callback': callback,
        })
    } catch (e) {
        logger.error(e)
    }
    return false
}


function facebookPixelKey(key, pixelId) { return `facebook_pixel_${key}:${pixelId}` }

export function loadFacebookPixel(k, pixelId) {
    const key = facebookPixelKey(k, pixelId)
    // logger.info(`Try Init Facebook Pixel ${key} - prev : ${window.facebookPixelKey}`)
    const scriptKey = `${key}_script`
    if (window.facebookPixelKey == key) {
        // logger.info(`Facebook Pixel ${key} already set up`)
        return
    }

    // Strip out previous version
    if (window.facebookPixelKey != null) {
        const prevKey = `${window.facebookPixelKey}_script`
        const existing = document.getElementById(prevKey)
        if (existing) {
            logger.info(`Remove previous facebook pixel ${prevKey}`)
            existing.remove()
        } else {
            logger.error(`Failed to remove previous facebook pixel ${prevKey}`)
        }

        // Remove all scripts containing pixelId
        const scripts = [...document.getElementsByTagName('script')]
        scripts.forEach((x) => {
            if (x.src.indexOf(`signals/config/${window.facebookPixelId}`) > 0) {
                x.remove()
            }
        })

        window.fbq = null
        window._fbq = null
        window.facebookPixelKey = null
        window.facebookPixelId = null
    }

    const src = 'https://connect.facebook.net/en_US/fbevents.js'

    let n = window.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!window._fbq) {
        window._fbq = n
    }
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []

    logger.info(`Intialize Facebook Pixel ${key}`)
    window.facebookPixelKey = key
    window.facebookPixelId = pixelId

    return fnc.loadScript(scriptKey, src)
        .then((x) => {
            fbq('init', pixelId)
            fbq('track', 'PageView')

            /*const pixelSrc = `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`
            const img = document.createElement("img")
            img.id = `${key}_img`
            img.src = pixelSrc
            img.height = 1
            img.width = 1
            document.body.appendChild(img)*/
        })
        .catch(logger.error)
}

export function facebookPixelRecordEvent(eventName, eventData = {}) {
    if (window.facebookPixelId == null || !window.fbq) {
        return
    }
    const key = window.facebookPixelKey

    logger.info(`Record Facebook Pixel Event ${key}/${eventName} - ${JSON.stringify(eventData)}`)

    try {
        fbq('trackCustom', eventName, eventData)
        // window.fbq('track', eventName, eventData)
    } catch (e) {
        logger.error('Failed to track Facebook Pixel event')
    }
}

function hubspotTrackingKey(key, hubspotId) { return `hubspot_${key}:${hubspotId}` }
export function loadHubspotTracking(k, hubspotId) {
    logger.info("HUBSPOT: Enable hubspot tracking")
    const key = hubspotTrackingKey(k, hubspotId)
    // logger.info(`Try Init Facebook Pixel ${key} - prev : ${window.facebookPixelKey}`)
    const scriptKey = `${key}_script`
    if (window.hubspotTrackingKey == key) {
        // logger.info(`Facebook Pixel ${key} already set up`)
        return
    }

    // Strip out previous version
    if (window.hubspotTrackingKey != null) {
        const prevKey = `${window.hubspotTrackingKey}_script`
        const existing = document.getElementById(prevKey)
        if (existing) {
            logger.info(`Remove previous hubspot ${prevKey}`)
            existing.remove()
        } else {
            logger.error(`Failed to remove previous hubspot ${prevKey}`)
        }

        // Remove all scripts containing pixelId
        /*const scripts = [...document.getElementsByTagName('script')]
        scripts.forEach((x) => {
            if (x.src.indexOf(`signals/config/${window.facebookPixelId}`) > 0) {
                x.remove()
            }
        })*/

        window.hubspotTrackingKey = null
        window.hubspotTrackingId = null
    }

    const src = `https://js.hs-scripts.com/${hubspotId}.js`

    logger.info(`Intialize Hubspot Tracking ${key}`)
    window.hubspotTrackingKey = key
    window.hubspotTrackingId = hubspotId

    return fnc.loadScript(scriptKey, src)
        .then((x) => {
            return x
        })
        .catch(logger.error)
}

export function hubspotIdentifyUser(email = null, analytics = {}) {
    var _hsq = window._hsq = window._hsq || [];
    let idData = {
        email: email,
        // id: fnc.getCookie('hubspotutk'),
        // utk: fnc.getCookie('hubspotutk'),
        // hubspotutk: fnc.getCookie('hubspotutk'),
        ...analytics
    }
    // Remove all null values
    idData = Object.fromEntries(Object.entries(idData).filter(([k, v]) => v != null))

    _hsq.push(['identify', idData])
    _hsq.push(['trackPageView']);
}

export function hubspotTrackPageView() {
    try {
        if (window._hsq) {
            window._hsq.push(['setPath', window.location.pathname]);
            window._hsq.push(['trackPageView']);
        }
    } catch (e) {
        logger.error("HUBSPOT: Failed to track page view")
    }
}

export function getFacebookTrackingId() {
    try {
        const queryString = window.location.search
        const parameters = new URLSearchParams(queryString)
        return parameters.get('fbclid')
    } catch (e) {
    }
    return null
}

export function getGoogleTrackingId() {
    try {
        const queryString = window.location.search
        const parameters = new URLSearchParams(queryString)
        return parameters.get('gclid')
    } catch (e) {
    }
    return null
}


export function loadReCaptcha() {
    if (!getReCaptchaSiteKey()) {
        logger.info("ReCaptcha not available")
        return new Promise((resolve, reject) => resolve())
    }
    return fnc.loadScript("recaptcha", `https://www.google.com/recaptcha/api.js?render=${getReCaptchaSiteKey()}`)
        .then((x) => {
            logger.info("ReCaptcha Loaded")
            return x
        })
        .catch((x) => {
            logger.info("ReCaptcha not available")
            return null
        })
    // <script src="https://www.google.com/recaptcha/api.js?render=%REACT_APP_GOOGLE_SITEKEY%"></script>
}

// As promise
export function executeReCaptcha() {
    return new Promise((resolve, reject) => {
        if (!getReCaptchaSiteKey() || window.grecaptcha == null) {
            reject("ReCaptcha not available")
            return
        }
        try {
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute(getReCaptchaSiteKey(), { action: 'homepage' }).then(function (token) {
                    if (!token) {
                        reject("ReCaptcha failed")
                        return
                    }
                    resolve(token)
                }).catch(reject)

            })
        } catch (e) {
            reject(e)
        }
    })
}

import * as Sentry from "@sentry/react";
export function initializeSentry() {
    logger.info("Initialize Sentry", getSentryDSN())
    Sentry.init({
        dsn: getSentryDSN(),
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        //   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    });
}
import React, { Suspense} from 'react'
import {
} from 'actions/appActions'
import {
    Map, CloseButton, Spinner,
} from 'components'
import { SlideShow } from 'components/SlideShow'
import { useAppSelector } from 'app/hooks'
import { MarkerType, MediaType } from 'app/types'
import { ModelhomeView } from 'views/ModelhomePage/ModelhomeView'
import { organizationMapStyles } from 'app/transformers'
const Spin = React.lazy(() => import('components/Spin/Spin'))

interface MapDialogProps {
    app: AppData,
    onClose?: () => void,
    animation?: string,
    coordinates?: string,
    title?: string,
    subtitle?: string
}

export function MapDialog(props: MapDialogProps) {
    const {
        app,
        onClose,
        animation,
        coordinates,
        title,
        subtitle,
    } = props

    const screen = useAppSelector((state: RootState) => state.app.screen)
    const organization = useAppSelector((state: RootState) => state.app.organization)

    function handleClose() {
        if (onClose) {
            onClose()
        }
    }

    const styles = organizationMapStyles(organization)
    const [lat, lng] = coordinates.split(',').map((x) => parseFloat(x))
    const markers = [{
        id: 'map-dialog',
        type: MarkerType.Neighbourhood,
        position: { lat, lng },
        // focusCallback: onProjectFocus,
        // selectCallback: onProjectSelect,
        // element: element,
        // favourite: favourite,
    }]
    const focusIds = ['map-dialog']

    return <div className={`lightbox ${animation} full`}>
        <CloseButton large onClick={handleClose} />
        <div className="title">
            <h3>{title}</h3>
            {subtitle && <h4>{subtitle}</h4>}
        </div>
        <div className="map-wrapper" onMouseDown={(e) => e.stopPropagation()}>
            <Map id={coordinates} markers={markers} focusIds={focusIds} defaultZoom={17} styles={styles} invert options={{ mapTypeControl: false, streetViewControl: true }}/>
        </div>
    </div>
}

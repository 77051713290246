import React, { useState, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
    RootState,
    ScreenSize,
    PageType,
    QueryType,
    BuilderData,
    ProjectFilter,
    ProjectStatus,
    FloorplanFilter,
    CustomEventType,
} from 'app/types'
import {
    setQuery,
    resetQuery,
    navigateAsync,
    setPath,
    setStandaloneApp,
    clearCurrent,
} from 'actions/appActions'
import {
    Tile,
    Button,
    TileList,
    Media,
    Input,
    ScrollHelper,
    SlideShow,
} from 'components'
import { Footer } from './Footer'
import { logger } from 'helpers/logger'
import { ProjectTile } from './ProjectTile'
import { BookingTile } from './BookingTile'
import * as fnc from 'helpers/fnc'
import { organizationContent } from 'app/transformers'

function SummaryGroup(props) {
    return <div className="summary-group">
        <div className="group-content">
            {props.children}
        </div>
    </div>
}

export default function SummaryView() {
    const media = useAppSelector((state: RootState) => state.app.media)
    const config = useAppSelector((state: RootState) => state.app.config)
    const { apps, builders, projectStatuses } = config
    const query = useAppSelector((state: RootState) => state.app.queries[QueryType.Projects])
    const dispatch = useAppDispatch()
    const screen = useAppSelector((state: RootState) => state.app.screen)
    let rowLimit = 4
    switch (screen.size) {
        case ScreenSize.Desktop:
            rowLimit = 4
            break
        case ScreenSize.Tablet:
            rowLimit = 3
            break
        case ScreenSize.Mobile:
            rowLimit = 2
            break
    }
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const standaloneOrg = useAppSelector((state: RootState) => state.app.standaloneOrg)
    const initialized = useAppSelector((state: RootState) => state.app.initialized)
    const [splashMedia, setSplashMedia] = useState(null)
    const [tagline, setTagline] = useState(null)
    const [builderCount, setBuilderCount] = useState(1)
    const [scrollElem, setScrollElem] = useState()

    useEffect(() => {
        // If organization only has on app, redirect to app page
        if (standaloneOrg && organization.link != 'thepropertylinq') {
            const app = config.apps.find((x) => x.meta.id == organization.apps[0])
            if (app) {
                dispatch(navigateAsync({ app, options: { path: { baseRoute: null }, replaceHistory: true } }))
            } else {
                logger.error('Missing app ' + organization.apps[0])
            }
        } else {
            dispatch(setPath({ baseRoute: PageType.Developments }))
            dispatch(setStandaloneApp(false))
            // Clear map cache
            localStorage.setItem('map-all', null)
        }
        dispatch(clearCurrent())
    }, [])

    

    useEffect(() => {
        const homegyde = config.organizations.find((x) => x.link == 'homegyde')
        if (splashMedia == null) {
            if (organization != null) {
                if (organization.splashGalleryId) {
                    const gallery = organization.galleries.find((x) => x.id == organization.splashGalleryId)
                    if (gallery) {
                        // Randomize media
                        let mediaIds = fnc.shuffle(gallery.media.map((x) => x.mediaId))
                        setSplashMedia(<SlideShow organization={organization} media={mediaIds} zoomable={false} autoScroll={3000} cover showIndex={false} />)
                    }
                } else {
                    setSplashMedia(<Media organization={organization} className="splash-media" fadeIn mediaId={organization.splashMediaId} />)
                }
            } else if (homegyde) {
                setSplashMedia(<Media organization={organization} className="splash-media" fadeIn mediaId={homegyde.splashMediaId} />)
            } else {
                const mediaSet = new Set()
                let test = 10
                while (mediaSet.size < apps.length && mediaSet.size < 4 && test > 0) {
                    const randomIndex = Math.floor(Math.random() * apps.length)
                    const appMedia = apps[randomIndex].meta.mediaId
                    if (appMedia in media) {
                        mediaSet.add(appMedia)
                    }
                    test -= 1
                }
                let mediaSize = 100
                if (mediaSet.size > 0) {
                    mediaSize = Math.floor(100 * mediaSize / mediaSet.size) / 100
                    const splashElement = Array.from(mediaSet).map((x, ix) => <div key={ix} className="splash-media" style={{ minWidth: `${mediaSize}%` }}><Media mediaId={x} /></div>)
                    setSplashMedia(splashElement)
                }
            }
        }
        if (tagline == null) {
            if (organization != null) {
                setTagline([organization.tagline, organization.subtagline])
            } else if (homegyde) {
                setTagline([homegyde.tagline, homegyde.subtagline])
            } else {
                setTagline(['Insert tagline', 'Insert subtagline'])
            }
        }
    }, [apps])

    function goToApp(app: AppMetaData) {
        // Set focus
        localStorage.setItem('focusApp', app.id)
        goToSearch(null, app.id)
    }

    function goToAll() {
        dispatch(resetQuery({ type: QueryType.Projects }))
        goToSearch()
    }

    function goToSearch(e, focusId = null) {
        localStorage.setItem('focusApp', focusId)
        dispatch(navigateAsync({ pageType: '' }))
            .catch(logger.error)
    }
    function filterBy(newQuery: { [key: string]: string }) {
        // Search submit without term, don't apply query
        if (newQuery[ProjectFilter.Search] != null && newQuery[ProjectFilter.Search].length == 0) {
            goToSearch()
            return
        }
        // const finalQuery = { ...query, ...newQuery}

        // Clear query first
        dispatch(resetQuery({ type: QueryType.Projects }))
        dispatch(setQuery({ query: newQuery, type: QueryType.Projects }))
        goToSearch()
    }

    function getSplash() {
        // let tagLine = 'Welcome to Home Gyde'
        // let tagLine = 'Exclusive access to new homes'
        let taglineElement = null
        let subtagElement
        if (tagline != null) {
            if (tagline[0].length > 50) {
                taglineElement = <h2 className="noselect">{tagline[0]}</h2>
            } else {
                taglineElement = <h1 className="noselect">{tagline[0]}</h1>
            }
            // taglineElement = <h2>{tagline[0]}<sup>TM</sup></h2>

            subtagElement = tagline[1] ? <h4>{tagline[1]}</h4> : null
        }
        // if (organization != null) {
        // tagLine = `Exclusive access to new homes from ${organization.name}`
        // tagLine = `Welcome to ${organization.name}`
        return <div className="row splash">
            {splashMedia}
            {taglineElement}
            {subtagElement}
            <div className="search">
                {/* <Input large submit submitText="Find a Home" placeholder="Enter a city, postal code or region" onSubmit={(x) => filterBy({[FloorplanFilter.Search]: x})}/> */}
                {/* <h3>Ready to find your dream home? Let's get started!</h3> */}
                <Button tertiary large onClick={goToAll} icon="fas fa-map-marked">Browse Communities</Button>
            </div>
        </div>
    }

    if (!config.filterRanges) {
        return null
    }

    function getFeaturedGroup() {
        const projectTiles = []
        if (apps) {
            // const featuredApps = apps.filter((x) => x.meta.altAppLink == null || x.meta.projectStatusId == ProjectStatus.ForSale)
            const featuredApps = [...apps].filter((x) => !x.meta.maintenanceMode).sort((a, b) => {
                const x = a.meta.altAppLink == null
                const y = b.meta.altAppLink == null
                return (x === y) ? 0 : x ? -1 : 1;
            }).slice(0, rowLimit)

            for (let i = 0; i < featuredApps.length; i += 1) {
                const app = featuredApps[i]
                projectTiles.push(<ProjectTile
                    key={i}
                    app={app}
                    minimal
                    book />)
            }
        }
        if (projectTiles.length > 0) {
            return <SummaryGroup>
                <h3 className="noselect">Featured Developments</h3>
                <TileList columns={4}>
                    {projectTiles}
                </TileList>
                <Button tertiary large onClick={goToAll}>See All Projects</Button>
            </SummaryGroup>
        }
        return null
    }

    function getUpcomingGroup() {
        const upcomingTiles = []
        if (apps) {
            const featuredApps = apps.filter((x) => x.meta.projectStatusId == ProjectStatus.UpcomingLaunch)
            for (let i = 0; i < Math.min(featuredApps.length, rowLimit); i += 1) {
                const app = featuredApps[i]
                const logo = (app.mediaId in media) ? media[app.mediaId] : null
                upcomingTiles.push(<ProjectTile
                    key={i}
                    app={app}
                    minimal
                    book />)
            }
        }
        if (upcomingTiles.length > 0) {
            // Add booking tile
            // upcomingTiles.push(<BookingTile title="Reserve your spot in line!"/>)


            return <SummaryGroup>
                <h3 className="noselect">Upcoming Launches</h3>
                <TileList columns={4}>
                    {upcomingTiles}
                </TileList>
                <Button tertiary large onClick={() => filterBy({ [ProjectFilter.ProjectStatus]: [ProjectStatus.UpcomingLaunch] })}>See All Upcoming Launches</Button>
            </SummaryGroup>
        }
        return null
    }

    function getTypesGroup() {
        // Filter tiles
        const types = config.filterRanges[ProjectFilter.ProjectType].slice().sort((a, b) => a.id - b.id)
        const typeTiles = []
        for (let i = 0; i < types.length; i += 1) {
            const type = types[i]
            const filter = {}
            filter[ProjectFilter.ProjectType] = [type.id]
            typeTiles.push(<Tile
                key={type.name}
                title={type.name}
                fadeIn
                aspect
                onClick={() => filterBy(filter)} />)
        }
        if (typeTiles.length > 0) {
            return <div className="summary-group">
                <div className="group-content">
                    <h3 className="noselect">Looking for a specific type of home?</h3>
                    <TileList columns={4}>
                        {typeTiles}
                    </TileList>
                </div>
            </div>
        }
        return null
    }

    function getBuildersGroup() {
        // Builder tiles
        const builderTiles = []
        let featuredBuilders: BuilderData[]
        if (builders) {
            featuredBuilders = builders

            for (let i = 0; i < Math.min(featuredBuilders.length, builderCount * rowLimit); i += 1) {
                const builder = featuredBuilders[i]
                const logo = (builder.mediaId in media) ? media[builder.mediaId] : null
                const filter = {}
                filter[ProjectFilter.Builder] = [builder.id]
                builderTiles.push(<Tile
                    className="project-tile"
                    key={`${i}${builder.id}`}
                    media={logo}
                    mediaOptions={{builder}}
                    // title={builder.name}
                    square
                    fadeIn
                    onClick={(e) => filterBy(filter)} />)
            }
        }
        if (builderTiles.length > 0) {
            return <SummaryGroup>
                <h3 className="noselect">Featured Builders</h3>
                <TileList columns={4}>
                    {builderTiles}
                </TileList>
                {builderCount * rowLimit < featuredBuilders.length
                    && <Button tertiary large onClick={() => setBuilderCount(builderCount + 1)}>See More</Button>}
            </SummaryGroup>
        }
        return null
    }

    function getBedsGroup() {
        // Beds tiles
        const beds = [...config.filterRanges[FloorplanFilter.Beds]]
        const bedsTiles = []
        for (let i = 0; beds && i < beds.length; i += 1) {
            const bed = beds[i]
            const filter = {}
            filter[FloorplanFilter.Beds] = [bed]
            bedsTiles.push(<Tile
                key={i}
                title={`${bed} Bed`}
                fadeIn
                aspect
                onClick={() => filterBy(filter)} />)
        }
        if (bedsTiles.length > 0) {
            return <SummaryGroup>
                <h3 className="noselect">Search by feature</h3>
                <TileList columns={4}>
                    {bedsTiles}
                </TileList>
            </SummaryGroup>
        }
        return null
    }

    function getBudgetsGroup() {
        // Budget tiles
        const budgets = [
            {
                min: 0,
                max: 499999,
                text: '< $500k',
            },
            {
                min: 0,
                max: 749999,
                text: '< $750k',
            },
            {
                min: 0,
                max: 999999,
                text: '< $1mil',
            },
        ]
        const budgetTiles = []
        for (let i = 0; i < budgets.length; i += 1) {
            if (budgets.max < config.filterRanges[FloorplanFilter.Price].max) {
                const budget = budgets[i]
                const filter = {}
                filter[FloorplanFilter.Price] = { min: budget.min, max: budget.max }
                budgetTiles.push(<Tile
                    key={budget.text}
                    title={budget.text}
                    fadeIn
                    aspect
                    onClick={() => filterBy(filter)} />)
            }
        }
        if (budgetTiles.length > 0) {
            return <SummaryGroup>
                <h3 className="noselect">Whats your budget?</h3>
                <TileList columns={4}>
                    {budgetTiles}
                </TileList>
            </SummaryGroup>
        }
        return null
    }

    if (organization && organization.link != 'homegyde') {
        // TODO, entry for organization home
        const Content = organizationContent(organization)
        if (Content) {
            return <div className="content scrollable" ref={setScrollElem}>
                <Content link="home" organization={organization} scrollElem={scrollElem}/>
                <ScrollHelper scrollElem={scrollElem}/>
                <Footer />
            </div>
        }
    }

    return <div className="content scrollable" ref={setScrollElem}>
        {getSplash()}
        <div id="summary-view" className="content-wrapper">
            {getFeaturedGroup()}
            {getUpcomingGroup()}
            {getTypesGroup()}
            {getBuildersGroup()}
            {getBedsGroup()}
            {getBudgetsGroup()}
        </div>
        <Footer />
        {/* <ScrollHelper scrollElem={scrollElem} /> */}
    </div>
}

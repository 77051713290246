import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
    RootState,
    PromptType,
    PageType,
    Theme,
    CustomEventType,
    UnitFilter,
    QueryType,
    Analytics,
    FloorplanFilter,
} from 'app/types'
import {
    SideBar,
    IconButton,
    FavouriteButton,
    Button,
    Media,
    CompareButton,
} from 'components'
import {
    showPrompt,
    navigateAsync,
    navigateBackAsync,
    recordAnalytics,
} from 'actions/appActions'
import { getMediaLink } from 'helpers/media'
import { logger } from 'helpers/logger'
import { FloorplanContentView } from '../AppPage/FloorplanContentView'
import { NotFound } from '../AppPage/NotFound'
import { on } from 'process'

interface FloorplanViewProps {
    app: AppData,
    dataLink: string,
    splitIndex: number,
    focusUnit: string,
    altMap: () => void,
    onFilter: () => void,
    onFocusUnit: () => void,
    additionalQuery: Dict,
    onModelhome: () => void,
    onVariation: () => void,
    withBuilding: boolean,
    withSitemap: boolean,
}

export function FloorplanView(props: FloorplanViewProps) {
    const { app, dataLink, extraLink, splitIndex, onlyFavourites, focusUnit, altMap, onFilter, onFocusUnit, additionalQuery, onModelhome, withSitemap, withBuilding, onVariation, ...otherProps } = props
    const dispatch = useAppDispatch()
    const { meta, units, floorplans } = app

    // const onlyFavourites = useAppSelector((state:RootState) => state.app.onlyFavourites)
    const floorplan = floorplans.find((x: string) => x.link === dataLink)
    const [_variation, setVariation] = useState(null)//floorplan && floorplan.variations ? floorplan.variations[0] : null)
    const variation = _variation || (floorplan && floorplan.variations ? floorplan.variations[0] : null)
    const query = useAppSelector((state: RootState) => state.app.queries[QueryType.Floorplans])
    const idle = useAppSelector((state: RootState) => state.app.idle)
    const historyCount = useAppSelector((state: RootState) => {
        if (splitIndex != null) {
            return state.app.navigation[splitIndex].split('/').length
        }
        return null
    })

    if (floorplan == null || floorplan.variations.length == 0) {
        return <NotFound />
    }

    // Legacy media
    const additionalMedia = useAppSelector(
        (state: RootState) => floorplan.media.map((x: string) => state.app.media[x]),
    )
    const variationMedia = useAppSelector((state: RootState) => state.app.media[variation.mediaId])

    /*const compareHistory = splitIndex != null
        ? useAppSelector((state:RootState) => state.app.compareHistory[splitIndex])
        : []*/
    const compare = useAppSelector((state: RootState) => state.app.compare)
    const theme = useAppSelector((state: RootState) => state.app.config.theme)

    let floorplanUrl = null
    if (variationMedia) {
        floorplanUrl = getMediaLink(variationMedia.link, { appLink: meta.link })
    }

    // Record analytics
    useEffect(() => {
        dispatch(recordAnalytics({
            type: Analytics.Floorplans,
            data: {
                app: app,
                floorplanId: floorplan.id,
                floorplanVariationId: variation.id,
                favourite: 0,
            },
        })).catch(logger.error)
    }, [])

    useEffect(() => {
        if (idle) {
            return
        }
        let recordTimeout = null
        let sinceLast = Date.now()
        const recordDuration = (x) => {
            recordTimeout = setTimeout(() => {
                dispatch(recordAnalytics({
                    type: Analytics.Floorplans,
                    data: {
                        app: app,
                        floorplanId: floorplan.id,
                        floorplanVariationId: variation.id,
                        favourite: 0,
                        duration: x * 1000,
                    },
                })).catch(logger.error)
                sinceLast = Date.now()
                recordDuration(x * 2)
            }, x * 1000)
        }
        recordDuration(10)
        return () => {
            clearTimeout(recordTimeout)
            let duration = Date.now() - sinceLast
            if (duration > 0) {
                dispatch(recordAnalytics({
                    type: Analytics.Floorplans,
                    data: {
                        app: app,
                        floorplanId: floorplan.id,
                        floorplanVariationId: variation.id,
                        favourite: 0,
                        duration: duration,
                    },
                })).catch(logger.error)
            }
        }
    }, [idle])

    useEffect(() => {
        if (onFilter) {
            onFilter([floorplan])
        }
    }, [query, dataLink])

    useEffect(() => {
        // Check if a query if applied that should switch to a different variation
        const bedSet = new Set()
        const bathSet = new Set()
        const garageSet = new Set()
        floorplan.variations.forEach((x) => {
            if (x.bed != null) {
                bedSet.add(x.bed)
            }
            if (x.bath != null) {
                bathSet.add(x.bath)
            }
            if (x.garage != null) {
                garageSet.add(x.garage)
            }
        })
        if ((bedSet.size > 0 && query[FloorplanFilter.Bed] != null)
            || (bathSet.size > 0 && query[FloorplanFilter.Bath] != null)
            || (garageSet.size > 0 && query[FloorplanFilter.Garage] != null)) {
            let variations = new Set()
            floorplan.variations.forEach((x) => {
                if (bedSet.size > 0 && query[FloorplanFilter.Bed] != null && !query[FloorplanFilter.Bed].map((x) => x?.toString()).includes(x.bed.toString())) {
                    return false
                }
                if (bathSet.size > 0 && query[FloorplanFilter.Bath] != null && !query[FloorplanFilter.Bath].map((x) => x?.toString()).includes(x.bath.toString())) {
                    return false
                }
                if (garageSet.size > 0 && query[FloorplanFilter.Garage] != null && !query[FloorplanFilter.Garage].map((x) => x?.toString()).includes(x.garage.toString())) {
                    return false
                }
                variations.add(x)
            })
            if (variations.size > 0) {
                setVariation([...variations][0])
            }
        }

    }, [])

    useEffect(() => {
        if (onVariation) {
            onVariation(floorplan, _variation)
        }
    }, [_variation])

    function handleMedia(mediaId: string) {
        dispatch(showPrompt({ type: PromptType.Lightbox, app: app, media: [mediaId] }))
            .catch(logger.error)
    }

    function handleModelhome() {
        dispatch(navigateAsync({
            app: app,
            pageType: PageType.Modelhome,
            options: { modelhomeId: variation.modelhomeId, splitIndex },
        })).catch(logger.error)
    }

    function handleBack() {
        dispatch(navigateBackAsync({ splitIndex }))
            .catch(logger.error)
    }

    function handleShare() {
        dispatch(showPrompt({
            type: PromptType.Share,
            title: floorplan.name,
            description: 'Take a look at this floorplan from Invent Sales Center',
            media: floorplanUrl,
            state: compare,
        })).catch(logger.error)
    }

    function handleVariation(variation) {
        if (typeof variation === 'string') {
            setVariation(floorplan.variations.find((x) => x.id == variation))
        } else {
            setVariation(variation)
        }
    }

    const options = []

    switch (theme) {
        default:
            // TODO: Exclude filter ranges that don't match units
            const newRanges = {}
            const floorSet = new Set()
            const exposureSet = new Set()
            for (let i = 0; floorplan.units && i < floorplan.units.length; i += 1) {
                const unit = units[floorplan.units[i].ix]
                if (unit.floor) {
                    floorSet.add(unit.floor)
                }
                if (unit.exposure) {
                    exposureSet.add(unit.exposure[0].toUpperCase())
                }
            }
            newRanges[UnitFilter.Floor] = Array.from(floorSet)
            newRanges[UnitFilter.Exposure] = Array.from(exposureSet)

            // options.push(<FavouriteButton key="fav" floorplan={floorplan} variation={variation}/>)
            if (splitIndex == null) {
                // Flip order to prevent compare button from overlapping title
                options.push(<CompareButton tertiary />)
            }
            if (variation.modelhomeId) {
                options.push(<Button key="tour" className="tour-button" large tertiary onClick={handleModelhome}>View Tour</Button>)
            }

            return <FloorplanContentView
                key={dataLink}
                app={app}
                floorplan={floorplan}
                variation={_variation}
                legacyFloorplanMedia={variationMedia}
                options={options}
                onBack={(splitIndex != null && historyCount > 1) ? handleBack : null}
                breadCrumbs={props.breadCrumbs}
                splitIndex={splitIndex}
                onlyFavourites={onlyFavourites}
                focusUnit={focusUnit}
                altMap={altMap}
                onVariation={handleVariation}
                onFocusUnit={onFocusUnit}
                onModelhome={onModelhome}
                additionalQuery={additionalQuery}
                withBuilding={withBuilding}
                withSitemap={withSitemap}
                {...otherProps} />
    }
}

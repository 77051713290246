import React from 'react'
import { FloorplanList } from './FloorplanList'

interface FavouritesPageProps {
    app: AppData,
}

export function FavouritesPage(props: FavouritesPageProps) {
    const { app } = props
    return <FloorplanList app={app} onlyFavourites/>
}

import React from 'react'
import { RootState, ScreenSize, ScreenOrientation } from 'app/types'
import { useAppSelector } from 'app/hooks'

interface TileListProps {
    columns: number,
    children: JSX.Element[],
    className: string,
    splitIndex: number,
}

const defaultProps = {
    columns: 4,
}

export function TileList(props: TileListProps) {
    const {
        columns,
        children,
        className,
        splitIndex,
    } = { ...defaultProps, ...props }

    const screen = useAppSelector((state: RootState) => state.app.screen)
    const compare = useAppSelector((state: RootState) => state.app.compare)
    const links = useAppSelector((state: RootState) => state.app.links)

    // Transform columns based on screen size (i.e. 4 becomes 2 etc)
    let finalColumns = columns
    if ((screen.size > ScreenSize.Desktop && screen.orientation == ScreenOrientation.Landscape)
        || (screen.size == ScreenSize.Desktop && screen.orientation == ScreenOrientation.Portrait)) {
        if (finalColumns == 4) {
            finalColumns = 3
        }
    } else if ((screen.size > ScreenSize.Desktop
        && screen.orientation === ScreenOrientation.Portrait) || links.appLink == 'compare') {
        if (finalColumns == 4) {
            finalColumns = screen.size == ScreenSize.Tablet ? 3 : 2
        }
        // if (columns % 2 === 0) {
        // finalColumns /= 2
        // } else {
        // finalColumns = (finalColumns + 1) / 2
        // }
    }
    if (splitIndex != null) {
        finalColumns -= 1
    }
    return <div className={`tile-list by-${finalColumns}${className != null ? ` ${className}` : ''}`}>
        {children}
    </div>
}

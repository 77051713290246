import React from 'react'

interface SplitProps {
    id: string,
    children: JSX.Element[],
}

export function Split(props: SplitProps) {
    const full = props.children.filter((x) => x != null).length == 1
    return <div className="split-wrapper" id={props.id}>
        {props.children.map((x, ix) => x ? <div key={ix} className="split" style={full ? { width: '100%', borderRight: 0 } : {}}>{x}</div> : null)}
    </div>
}

import React, { useRef, useState, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
    navigateAsync,
} from 'actions/appActions'
import {
    Message,
    OptionBar,
    IconButton,
    FilterOptions,
    TileList,
} from 'components'
import {
    RootState,
    AppData,
    QueryType,
    ListStyle,
    PageType,
    ScreenSize,
    ScreenOrientation,
    ProjectFilter,
    FloorplanFilter,
} from 'app/types'
import * as fnc from 'helpers/fnc'
import { ProjectTile } from 'views/HomePage/ProjectTile'
import { NoFavourites } from 'views/HomePage/NoFavourites'
import { FloorplanTile } from 'views/FloorplanPage/FloorplanTile'
import { logger } from 'helpers/logger'
import { filterFloorplan } from 'helpers/query'

function TileCarousel(props: { items: FloorplanData[], map: () => void, splitIndex: number }) {
    const { items, map, splitIndex } = props
    const { size, orientation } = useAppSelector((state: RootState) => state.app.screen)
    const [scrollElem, setScrollElem] = useState(null)
    const [scrollLimit, setScrollLimit] = useState(0)
    let columns = 4
    if (size > ScreenSize.Desktop) {
        if (orientation == ScreenOrientation.Landscape) {
            columns = splitIndex == null ? 3 : 2
        } else {
            columns = splitIndex == null ? (size == ScreenSize.Tablet ? 3 : 2) : 1
        }
    } else {
        if (orientation == ScreenOrientation.Landscape) {
            columns = splitIndex == null ? 4 : 2
        } else {
            columns = splitIndex == null ? 3 : 1
        }
    }
    const pageCount = Math.ceil(items.length / columns)

    useEffect(() => {
        handleScroll(scrollElem)
    }, [scrollElem])

    function next() {
        /*if (index >= pageCount) {
            setIndex(0)
        } else {
            setIndex(index + 1)
        }*/
        if (scrollElem) {
            scrollElem.scrollTo({ left: scrollElem.scrollLeft + scrollElem.clientWidth, behavior: 'smooth' })
        }
    }

    function prev() {
        /*if (index == 0) {
            setIndex(pageCount)
        } else {
            setIndex(index - 1)
        }*/
        if (scrollElem) {
            scrollElem.scrollTo({ left: scrollElem.scrollLeft - scrollElem.clientWidth, behavior: 'smooth' })
        }
    }

    function handleScroll(target: JSX.Element) {
        const threshold = 50
        if (!target) {
            return
        }
        try {
            if (target.scrollLeft < threshold) {
                if (scrollLimit !== -1) {
                    setScrollLimit(-1)
                }
            } else if (target.scrollWidth - (target.scrollLeft + target.offsetWidth) < threshold) {
                if (scrollLimit !== 1) {
                    setScrollLimit(1)
                }
            } else if (scrollLimit !== 0) {
                setScrollLimit(0)
            }
        } catch (err) {
            // logger.error("OOPS", err)

        }
    }
    return <div className={`row tile-list by-${columns} tile-carousel`}>
        <div className="tile-carousel-item-wrapper scrollable hide-scroll" ref={setScrollElem} onScroll={(e) => handleScroll(e.target)}>
            {items.map(map)}
        </div>
        {pageCount > 1 && <div className="row arrow-buttons">
            <IconButton tertiary className={`prev${scrollLimit == -1 ? ' disabled' : ''}`} icon="fas fa-chevron-left" onClick={scrollLimit != -1 ? prev : null} />
            <IconButton tertiary className={`next${scrollLimit == 1 ? ' disabled' : ''}`} icon="fas fa-chevron-right" onClick={scrollLimit != 1 ? next : null} />
        </div>}
        {/* {items.slice(index * columns, index * columns + columns).map(map)} */}
    </div>
}

interface FavouriteListProps {
    apps: AppData[],
    onFilter: () => void,
    onProject: () => void,
    splitIndex,
    options?: JSX.Element[],
}

export function FavouriteList(props: FavouriteListProps) {
    const {
        apps,
        items,
        onlyFavourites,
        onFilter,
        splitIndex,
        onProject,
        options,
        breadCrumbs,
        ...otherProps
    } = {
        ...props,
    }
    const dispatch = useAppDispatch()

    const config = useAppSelector((state: RootTState) => state.app.config)
    const { /*apps, favouriteApps,*/ filterRanges } = config
    const query = useAppSelector((state: RootState) => state.app.queries[QueryType.Floorplans])

    const favourites = useAppSelector((state: RootState) => state.user.favourites)
    const favouriteCount = Object.keys(favourites).length
    const compareMode = useAppSelector((state: RootState) => state.app.compareMode)
    const mobileMode = useAppSelector((state: RootState) => state.app.mobileMode)
    const standaloneOrg = useAppSelector((state: RootState) => state.app.standaloneOrg)
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)

    const organization = useAppSelector((state: RootState) => state.app.organization)
    const [filteredItems, setFilteredItems] = useState(null)
    const [filters, setFilters] = useState([])
    const barRef = useRef()

    const [compiledFavourites, setCompiledFavourites] = useState([])

    useEffect(() => {
        let newFavourites = items.filter((x) => {
            return x.meta.id in favourites
        }).map((x) => {
            return {
                ...x,
                floorplans: x.floorplans.filter((y) => {
                    if (!(y.id in favourites[x.meta.id])) {
                        return false
                    }

                    return filterFloorplan(x, y, query)
                }),
            }
        })
        setCompiledFavourites(newFavourites.filter((x) => x.floorplans.length > 0))
    }, [items, favourites, query])

    function handleProject(x: AppData) {
        // If alt link, navigate away
        if (x.meta.altAppLink != null && x.meta.altAppLink.length > 0) {
            window.open(x.meta.altAppLink, '_blank').focus()
            return
        }

        if (splitIndex != null) {
            dispatch(navigateAsync({ app: x, options: { useCompareMode: true, splitIndex: splitIndex } }))
        } else if (standaloneApp) {
            dispatch(navigateAsync({ app: x, pageType: '', options: { splitIndex: splitIndex, path: { baseRoute: PageType.Home} } }))
        } else {
            dispatch(navigateAsync({ app: x, pageType: '', options: { splitIndex: splitIndex, path: { baseRoute: PageType.Developments } } }))
            // dispatch(navigateAsync({ app: x, pageType: ''}))
        }
        // handleProjectFocus(x, false)
    }

    function handleFloorplan(app: AppData, floorplanId: string) {
        dispatch(navigateAsync({
            app: app,
            pageType: PageType.Floorplan,
            options: { floorplanId, splitIndex },
        })).catch(logger.error)
    }

    function handleBack() {
        dispatch(navigateAsync({ pageType: '', options: { path: { baseRoute: PageType.Developments } } }))
    }

    function projectMap(x: AppMetaData, listStyle: ListStyle, focus = false, ref = null) {
        if (!x || !x.meta) {
            return null
        }
        return <ProjectTile
            forwardRef={ref}
            key={x.meta.id}
            className={focus ? 'focus animate__animated animate__headShake' : ''}
            data-key={x.meta.id}
            app={x}
            media={x.meta.mediaId}
            square
            fadeIn
            tileStyle={listStyle}
            onClick={handleProject}
            onlyFavourites={onlyFavourites}
            splitIndex={splitIndex}
            minimal />
    }

    function getBefore() {
        return null
        // return <React.Fragment>
        // <EmailFavouritesButton />
        // </React.Fragment>
    }

    function getAfter() {
        // if (onlyFavourites && splitIndex == null) {
        // return <ClearFavouritesButton />
        // }
        return null
    }

    function getMissing() {
        if (onlyFavourites) {
            return <NoFavourites />
        }
        return <Message info="No projects available" />
    }

    function getFavouritesCarousel() {
        if (compiledFavourites.length == 0) {
            return <Message info="No favourites matching filters" />
        }
        return compiledFavourites.map((x, ix) => {
            return <div className="row favourite-row alt" key={x.meta.id}>
                <div className="row item-list tile-list" key={x.meta.id}>
                    <table>
                        <thead>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            <ProjectTile
                                key={x.meta.id}
                                data-key={x.meta.id}
                                app={x}
                                media={x.meta.mediaId}
                                square
                                fadeIn
                                tileStyle={ListStyle.List}
                                onClick={handleProject}
                                onlyFavourites={true}
                                splitIndex={splitIndex}
                                minimal />
                        </tbody>
                    </table>
                </div>
                {x.floorplans.length == 0 && <Message info="No matching floorplans" />}
                {!standaloneOrg && x.floorplans.length > 0 && <TileCarousel items={x.floorplans}
                    splitIndex={splitIndex}
                    map={(y, iy) => {
                        return <FloorplanTile
                            app={x}
                            favourite
                            key={y.id}
                            // key={y.id}
                            tileStyle={ListStyle.Grid}
                            floorplan={y}
                            onFloorplan={(z) => handleFloorplan(x, z)} />
                    }} />}
                {standaloneOrg && x.floorplans.length > 0 && <TileList splitIndex={splitIndex}>
                    {x.floorplans.map((y, iy) => {
                        return <FloorplanTile
                            app={x}
                            favourite
                            key={y.id}
                            // key={y.id}
                            tileStyle={ListStyle.Grid}
                            floorplan={y}
                            onFloorplan={(z) => handleFloorplan(x, z)} />
                    })}
                </TileList>}
            </div>
        })
    }

    let leftOptions = <FilterOptions
        app={app}
        filters={[
            FloorplanFilter.Beds,
            FloorplanFilter.Sqft,
            FloorplanFilter.Price,
        ]}
        queryType={QueryType.Floorplans}
        ranges={filterRanges}
        // heightRef={barRef}
        splitIndex={splitIndex}
        expandedAsDropdown
        listSelected>
    </FilterOptions>

    // Project list with map
    // If split index or mobile, switch to Toggle view
    return <React.Fragment>
        <OptionBar
            app={null}
            splitIndex={splitIndex}
            titleText="My Favourites"
            onlyFavourites={true}
            leftOptions={leftOptions}
            // leftOptions={[splitIndex != 1 ? <BackButton key="back" hint="Browse Communities" onClick={handleBack} /> : null]}
            rightOptions={getBefore()} />
        <div className="content scrollable">
            <div className="content-wrapper">
                {getFavouritesCarousel()}
                {/* {getFavouritesCarousel()} */}
                {/* {getFavouritesCarouselAlt()} */}
                {/* {getFavouritesCarouselAlt()} */}
                {/* {getFavourites()} */}
                {/* {getFavourites()} */}
            </div>
        </div>
    </React.Fragment>
}

import React, { useState } from 'react'
import {
    CloseButton,
    Button,
} from 'components'
import { BookingTile } from 'views/HomePage/BookingTile'
import { ContentForm } from 'views/AppPage/ContentForm'
import { AnalyticsEvent, AppData, ErrorMessage, PromptOptions } from 'app/types'
import { analyticsEvent, showPrompt, submitBooking } from 'actions/appActions'
import { useAppDispatch } from 'app/hooks'
import { packageFormData } from 'helpers/requestHandler'
import { logger } from 'helpers/logger'

interface BookingDialogProps {
    app: AppData,
    organization: OrganizationData,
    title: string,
    subtitle: string,
    error: string,
    message: string,
    onClose?: () => void,
    onConfirm: () => void,
    animation?: string,
    analyticsEventTypeId: AnalyticsEvent,
    submitMessage: string,
    fields: Dict,
    formOptions: Dict,
    promptOptions: Dict,
    tags: string[],
}

export function FormDialog(props: BookingDialogProps) {
    const {
        app,
        organization,
        title,
        subtitle,
        fields,
        formOptions,
        promptOptions,
        message,
        onClose,
        onConfirm,
        animation,
        analyticsEventTypeId,
        submitMessage,
        tags,
    } = { submitMessage: 'Submit', ...props }

    const dispatch = useAppDispatch()
    const [submitRef, setSubmitRef] = useState(null)
    const [formFilled, setFormFilled] = useState(0)
    const [error, setError] = useState(null)
    const [loaded, setLoaded] = useState(false)

    function handleConfirm() {
        if (onConfirm) {
            onConfirm()
        }
    }

    function handleClose() {
        onClose()
    }


    function submitForm(formData) {
        const { finalFormData, promptOptions, finalFormOptions } = packageFormData({ ...formData, tags: tags ? (Array.isArray(tags) ? [...tags] : tags) : [] }, { ...formOptions, fields }, analyticsEventTypeId)
        /*const finalFormData = { ...formData }
        if (finalFormData.address) {
            finalFormData.address = `${finalFormData.address}, ${finalFormData.city} ${finalFormData.province}, ${finalFormData.postalcode}`
        } else if (finalFormData.city) {
            finalFormData.address = finalFormData.city
        } else {
            finalFormData.address = ''
        }

        if (formData.firstname && formData.lastname) {
            finalFormData.name = `${formData.firstname} ${formData.lastname}`
        }
        finalFormData.optIn = finalFormData.consent == true

        finalFormData.message = []

        const commentKey = Object.keys(finalFormData).find((key) => key.includes('comment'))
        finalFormData.message.push(finalFormData[commentKey])

        const consent = fields.find((x) => x.id == 'consent')
        if (consent && !consent.required) {
            finalFormData.message.push(`Opt In To Communication: ${finalFormData.consent ? 'Yes' : 'No'}`)
        }
        finalFormData.message.push(`Request Type: ${AnalyticsEvent[analyticsEventTypeId].toReadable()}`)*/

        if (finalFormData.analyticsEventTypeId > 0) {
            dispatch(analyticsEvent(analyticsEventTypeId))
        }
        return Promise.all([
            dispatch(showPrompt(promptOptions)),
            dispatch(submitBooking({ app, data: finalFormData, options: finalFormOptions })),
        ])
            .then(([a, b]) => {
                try {
                    if (!b.payload.success) {
                        throw b.payload.message
                    } else {
                        // handleRequestInfo()
                        return true
                    }
                } catch (e) {
                    throw e
                }
                return false
            }).catch((e) => {
                logger.error("Booking error", e)
                throw ErrorMessage.BookingError
            })
    }
    const errors = [error, props.error].filter((x) => x)

    return <div className={`dialog ${animation}`} onClick={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
        <div className="title">
            <h3>{title}</h3>
            {subtitle && <h4>{subtitle}</h4>}
            {errors.length > 0 && <h5 className="error">{errors.join(', ')}</h5>}
        </div>
        <div className="dialog-wrapper">
            {message && <span className="message"><ReactMarkdown>{message}</ReactMarkdown></span>}
            <div className="row">
                <ContentForm onSubmit={submitForm} onLoad={setLoaded} onError={setError} submitRef={submitRef} {...formOptions} fields={fields} onFormFilled={setFormFilled} preventNav={false} />
            </div>
            <Button tertiary icon="far fa-paper-plane" className={!loaded ? 'disabled' : ''} forwardRef={setSubmitRef}>{submitMessage}</Button>
            <CloseButton large onClick={handleClose} />
        </div>
    </div>
}

import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import { authHeader } from 'helpers/authHeader'
import { getApiUrl } from 'helpers/config'
import * as fnc from 'helpers/fnc'
import { AnalyticsEvent, Dict, FormFieldType, PromptOptions, SegmentOptions, UrlOption } from 'app/types'

const axiosInstance = axios.create({
    baseURL: getApiUrl(),
    headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader(),
        'Access-Control-Allow-Origin': '*',
    },
})

export function getAxiosInstance(): AxiosInstance {
    return axiosInstance
}

export function getOptionString(options: UrlOption): string {
    if (Object.values(options).length > 0) {
        return `?${Object.keys(options).filter((x) => options[x] != null).map((x) => {
            if (typeof options[x] === 'boolean') {
                return `${x}=${options[x] ? 1 : 0}`
            }
            if (Array.isArray(options[x])) {
                const optionGroup: string[] = options[x]
                return `${x}=${optionGroup.join(',')}`
            }

            let optionString: string = options[x]
            // url encode it
            optionString = encodeURIComponent(optionString)
            return `${x}=${optionString}`
        }).join('&')}`
    }
    return ''
}
export function getSegmentOptions(options: SegmentOptions) {
    const { app, builder, organization, ...otherOptions } = options
    const finalOptions = { ...otherOptions }
    if (app) {
        finalOptions.appId = app.meta.id
    }
    if (builder) {
        finalOptions.builderId = builder.id
    }
    if (organization) {
        finalOptions.organizationId = organization.id
    }
    return getOptionString(finalOptions)
}

export function handleError<Type>(error: AxiosError<Type>) {
    if (error.response) {
        // Request made and server responded
        throw error.response.data
    } else if (error.request) {
        // The request was made but no response was received
        throw JSON.stringify(error.request)
    } else if (error.message) {
        // Something happened in setting up the request that triggered an Error
        throw error.message
    } else {
        throw error
    }
}

export function parseData(obj) {
    const keys = Object.keys(obj)
    for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i]
        let prop = obj[key] // eslint-disable-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, max-len
        /* if (key.includes('date')) {
            prop = new Date(prop)
        } */
        if (prop != null) {
            if (key.includes('type')) {
                // prop = parseInt(prop, 10)
            } else if (Array.isArray(prop)) {
                for (let j = 0; j < prop.length; j += 1) {
                    if (typeof prop[j] === 'object') {
                        prop[j] = parseData(prop[j])
                    }
                }
            } else if (typeof prop === 'object') {
                prop = parseData(prop)
            }
        }

        const newKey = key.toCamelCase()
        if (newKey !== key) {
            obj[newKey] = prop // eslint-disable-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, max-len
            delete obj[key]
        }
    }
    return obj
}

export function handleResponse<Type>(response: AxiosResponse<Type>): Type {
    let data = response.data
    if (!response.statusText || response.statusText !== 'OK' || (response.data && typeof response.data == 'string' && response.data.includes('Connection error'))) {
        /* if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            location.reload(true)
        } */
        throw (data && data.message) || (response.statusText ? response.statusText : response)
    }

    if (data != null) {
        if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i += 1) {
                if (typeof data[i] === 'object' && data[i] !== null) {
                    data[i] = parseData(data[i])
                }
            }
        } else if (typeof data === 'object') {
            parseData(data)
        }
    }

    return data
}

const standardFields = new Set(['firstname', 'lastname', 'name', 'email', 'phone', 'postalcode', 'zip', 'address', 'optIn', 'consent', 'message'])
export function packageFormData(data: Dict, form: FormData, footer?: string = null) {
    const finalFormData = { ...data }
    const { fields, analyticsEventTypeId } = form
    if (data.firstname) {
        finalFormData.name = `${data.firstname} ${data.lastname}`
    }
    // delete finalFormData.firstname
    // delete finalFormData.lastname
    if (finalFormData.address) {
        finalFormData.address = [finalFormData.address, finalFormData.city, finalFormData.province, finalFormData.postalcode].filter((x) => x != null).join(',')
    }

    const fieldMap = fnc.objIdMap(fields)
    finalFormData.optIn = finalFormData.consent == true
    const extras = []
    Object.keys(finalFormData).filter((x) => x in fieldMap && fieldMap[x].extra && finalFormData[x]).forEach((x) => {
        extras.push(`${fieldMap[x].extra ? fieldMap[x].extra : x.toTitleCase()}: ${finalFormData[x]}`)
    })
    finalFormData.message = [finalFormData.message]
    if (extras.length > 0) {
        if (footer) {
            finalFormData.message.push(footer)
        }
        finalFormData.message = finalFormData.message.concat(extras)
    }

    // Get other fields
    const otherFields = fields.filter((x) => x.other || !standardFields.has(x.link || x.id))
    if (otherFields.length > 0) {
        otherFields.forEach((x) => {
            finalFormData.message.push(`${x.label}: ${finalFormData[x.link || x.id]}`)
        })
    }

    const commentKey = Object.keys(finalFormData).find((key) => key.includes('comment'))
    if (commentKey) {
        finalFormData.message.push(`Comment: ${finalFormData[commentKey]}`)
    }

    if (fieldMap.consent && !fieldMap.consent.required) {
        finalFormData.message.push(`Opt In To Communication: ${finalFormData.consent ? 'Yes' : 'No'}`)
    }

    if (analyticsEventTypeId) {
        finalFormData.message.push(`Request Type: ${AnalyticsEvent[analyticsEventTypeId].toReadable().toTitleCase()}`)
        finalFormData.analyticsEventTypeId = analyticsEventTypeId
    }
    const promptOptions = finalFormData.analyticsEventTypeId == AnalyticsEvent.RequestInformation ? PromptOptions.SubmitBooking : PromptOptions.SubmitForm

    const finalFormOptions = { ...form }
    finalFormOptions.fields = fields.map((x) => {
        let dbType = ''
        switch (x.formFieldTypeId) {
            case FormFieldType.Consent:
            case FormFieldType.Checkbox:
                dbType = 'boolean'
                break
            default:
                dbType = 'string'
                break
        }
        return [x.link || x.id, dbType, x.isHubspot == true]
    })

    return { finalFormData, promptOptions, finalFormOptions }
}
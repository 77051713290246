
import React, { useEffect, useState, useRef } from 'react'
import { CustomEventType, PageType, PromptOptions, PromptType, UpgradeSpaceData, ThumbSize, MediaType, Visibility, UpgradeTier } from 'app/types'
import { useAppDispatch, useAppSelector, useIsVisible } from 'app/hooks'
import { Message, Button, BackButton, Spinner, Split, Media, Icon, IconButton, TourIFrame, SlideShow, CloseButton, FlipNumbers, Input } from 'components'
import { FloorplanPage } from 'views/FloorplanPage'
import * as fnc from 'helpers/fnc'
import { setFocusUnit } from 'actions/spinActions'
import { showPrompt } from 'actions/appActions'
import { selectionKey, getConfig, globalChangesKey, getGlobalChanges } from './upgrades'
import { UpgradeProduct } from './UpgradeProduct'
import { UpgradeTour } from './UpgradeTour'
import { UpgradePackage } from './UpgradePackage'
import { UpgradeOption } from './UpgradeOption'
import { Document } from 'react-pdf'
import { getMediaLinkSelector } from 'app/hooks'
import { UpgradeCollage } from './UpgradeCollage'
import { logger } from 'helpers/logger'

const PRODUCT_COLLAPSE_LIMIT = 4

function orderSort(a, b) {
    return a.order - b.order
}

interface UpgradeGroupProps {
    hasBefore: boolean,
    hasAfter: boolean,
    app,
    className,
    group,
    viewRef,
    maps: Dict,
    onPackage: () => void,
    onPackageReset: () => void,
    onProduct: () => void,
    onVariation: () => void,
    onProductValue: () => void,
    onInfo: () => void,
    selections: Dict,
    customizations: Dict,
    spec: Dict,
    focus: boolean,
    style: Dict
    mobileView: boolean,
    onChange: () => void,
    showPricing: boolean,
    admin: boolean,
    disableChanges: boolean,
    showGlobalPrompt: boolean,
}

export function UpgradeGroup(props: UpgradeGroupProps) {
    const {
        hasBefore,
        hasAfter,
        app,
        className,
        group,
        viewRef,
        maps,
        onPackage,
        onPackageReset,
        onProduct,
        onVariation,
        onProductValue,
        onInfo,
        selections,
        customizations,
        focus,
        style,
        mobileView,
        onChange,
        showPricing,
        admin,
        independent,
        disableChanges,
        spec,
        showGlobalPrompt
    } = { showGlobalPrompt: true, ...props }
    const { floorplanVariation } = spec
    const dispatch = useAppDispatch()
    const [animating, setAnimating] = useState(1)
    const [editOption, setEditOption] = useState(null)
    const [editComponent, setEditComponent] = useState(null)
    const [editPackage, setEditPackage] = useState(null)
    const [focusOption, setFocusOption] = useState(false)
    const [scrollRef, setScrollRef] = useState(null)
    const [mediaRef, setMediaRef] = useState(null)
    const [groupHeight, setGroupHeight] = useState(0)
    const [optionRef, setOptionRef] = useState(null)
    const [titleRef, setTitleRef] = useState(null)
    const [featureMedia, setFeatureMedia] = useState(null)
    const [collage, setCollage] = useState(false)
    const media = useAppSelector((state: RootState) => state.app.media)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const fullscreen = useAppSelector((state: RootState) => state.app.fullscreen)
    const getMediaLink = getMediaLinkSelector()

    const groupRef = useRef()
    const isVisible = useIsVisible(groupRef)
    const animTimeout = useRef()
    const lastOverride = useRef(null)
    const hiddenPackage = useRef(false)

    const groupStyle = { ...style }
    if (viewRef.current) {
        groupStyle.minHeight = `${viewRef.current.clientHeight - (hasBefore && (hasAfter) ? 200 : (hasBefore != (hasAfter) ? 125 : 0))}px`
    }
    if (!focus) {
        groupStyle.opacity = 0.5
    } else {
        groupStyle.opacity = 1
    }

    let groupMedia = []
    let hasFloorplanMedia = false
    if (floorplanVariation) {
        const floorplan = group.floorplans.find((x) => x.floorplanVariationId == floorplanVariation.id)
        if (floorplan && floorplan.media.length > 0) {
            groupMedia = [...floorplan.media].sort(orderSort).map((x) => x.mediaId)
            hasFloorplanMedia = true
        } else {
            groupMedia = group.media
        }
    } else {
        groupMedia = group.media
        // Check if gorup media is floorplan
        if (groupMedia.find((x) => media[x].link.includes('floorplan'))) {
            hasFloorplanMedia = true
        }
    }
    let groupMediaType = MediaType.Image
    if (groupMedia.length > 0) {
        groupMediaType = media[groupMedia[0]].mediaTypeId
    }

    useEffect(() => {
        // If group was updated and we're editing a package, find the new one
        if (editPackage && maps.package) {
            const pckge = maps.package[editPackage.id]
            // setEditComponent(pckge)
            setEditPackage(pckge)
        }

    }, [group])

    useEffect(() => {
        // If no group media, find first feature media
        if (groupMedia.length == 0 && group.options.length > 0) {
            let newFeature = null
            const featureOption = [...group.options].sort(orderSort).find((x) => {
                // Check selections
                const option = maps.option[x.upgradeOptionId]
                if (option.adminOnly && !admin) {
                    return
                }
                const variation = option.variations[0]
                const component = variation.components[0]
                const key = selectionKey(group, option, variation, component)
                if (key in selections && selections[key].length > 0) {
                    if (selections[key][0].package) {
                        const pckge = selections[key][0].package
                        if (pckge.featureMediaId) {
                            newFeature = pckge.featureMediaId
                            return true
                        }
                    } else if (selections[key][0].product) {
                        const { product } = selections[key][0]
                        if (product.featureMediaId) {
                            newFeature = product.featureMediaId
                            return true
                        }
                    }
                }
            })
            if (newFeature) {
                setFeatureMedia(newFeature)
            } else {
                // No new feature, instead gather images from options
                let optionMedia = {}
                group.options.forEach((x) => {
                    const option = maps.option[x.upgradeOptionId]
                    if (option.adminOnly && !admin) {
                        return
                    }
                    const variation = option.variations[0]
                    const component = variation.components[0]
                    component.products.forEach((y) => {
                        if (y.upgradeProductId != null) {
                            const prod = maps.product[y.upgradeProductId]
                            if (!prod) {
                                logger.error("Missing product", y)
                            }
                            prod?.media.forEach((z) => {
                                const m = media[z]
                                if (m) {
                                    optionMedia[z] = m
                                }
                            })
                        }
                    })
                })
                if (Object.keys(optionMedia).length > 0) {
                    setFeatureMedia(Object.values(optionMedia))
                    setCollage(true)
                }
            }
        }
    }, [groupMedia, group])

    useEffect(() => {
        if (!optionRef || !titleRef) {
            return
        }
        const calcHeight = () => {
            const newHeight = `${optionRef.clientHeight - titleRef.clientHeight}px`
            setGroupHeight(newHeight)
        }
        calcHeight()
        const timeout = setTimeout(() => {
            calcHeight()
        }, 100)
        return () => [
            clearTimeout(timeout)
        ]
    }, [optionRef, titleRef, screen?.orientation, fullscreen])

    useEffect(() => {
        if (!scrollRef) {
            return
        }
        window.addEventListener(CustomEventType.FocusUpgradeOption, handleWindowFocus)
        return () => {
            window.removeEventListener(CustomEventType.FocusUpgradeOption, handleWindowFocus)
        }
    }, [scrollRef, editOption, editPackage])

    useEffect(() => {
        // Re-assign editOption and editComponent after save
        if (editOption && maps.optionGroupOptions) {
            const options = maps.optionGroupOptions[group.id]
            const newOption = options?.find((x) => x?.id == editOption.id)
            if (newOption && newOption.id == editOption.id) {
                setEditOption(newOption)
            }
        }
    }, [group, maps])

    useEffect(() => {
        const clear = () => {
            lastOverride.current = null
        }
        window.addEventListener(CustomEventType.ClearSelection, clear)
        return () => {
            window.removeEventListener(CustomEventType.ClearSelection, clear)
        }
    }, [])

    function handleWindowFocus(e) {
        const { option, variation, component, pckge } = e.detail
        if (!focus) {
            return
        }

        // Make sure option is part of this group
        if (!(e.detail.group && e.detail.group.id == group.id) || !option || !maps.groupOptions[group.id].includes(option.id)) {
            return
        }
        if (option && option.collapse && false) {
            selectOption(option, null, false)
        } else {
            if (editPackage) {
                let packageOption = maps.packageOption[editPackage?.id]
                if (!pckge || pckge.id != packageOption.id) {
                    setEditPackage(null)
                }
            }
            if (editOption && (!component || option.id != editOption.id)) {
                setEditOption(false)
            }
            let elem = null
            if (component) {
                elem = document.querySelector(`.upgrade-option-group[data-id='${group.id}'] .upgrade-component[data-id='${component.id}']`)
            } else {
                elem = document.querySelector(`.upgrade-option-group[data-id='${group.id}'] .upgrade-option[data-id='${option.id}']`)
            }
            if (elem) {
                if (component) {
                    elem.parentNode.parentNode.scrollTo({
                        top: elem.parentNode.offsetTop + (variation.components.length > 1 ? elem.offsetTop : 0) - (editOption ? 60 : 0),
                        behavior: 'smooth',
                    })
                } else {
                    // If nested add buffer for topbar
                    elem.parentNode.scrollTo({
                        top: elem.offsetTop - (editPackage ? 60 : 0),
                        behavior: 'smooth',
                    })
                }
                fnc.shake(elem, 200)
            }
        }
    }

    function checkGlobal(option) {
        return new Promise((resolve, reject) => {
            if (!showGlobalPrompt) {
                resolve()
                return
            }
            // If product is part of package and exists across multiple groups, don't show
            if (option.global && lastOverride.current != option.id) {
                // Determine which groups this option exists in
                let message = 'Warning, changing this option will change it across all rooms. Are you sure you want to make this change?'
                if (option.id in maps.optionInGroup) {
                    let groups = Object.keys(maps.optionInGroup[option.id]).map((x) => maps.optionGroups[x].name)
                    if (groups.length > 1) {
                        // Join groups by comma, with last item preceded by 'and'
                        // groups = groups.join('\n*  ').replace(/,([^,]*)$/, ' and$1')
                        groups = `\n* ${groups.join('\n*  ')}\n`
                        message = `Please note: Changing this option will affect the following areas:${groups}\n Are you sure you want to apply this change to all of these areas?`
                    }
                }

                const shouldPrompt = getGlobalChanges(app) != 'true'
                if (shouldPrompt) {
                    dispatch(showPrompt({ type: PromptType.Confirm, title: 'Global changes', message, dismissKey: globalChangesKey(app) }))
                        .then((x) => {
                            if (x.payload) {
                                lastOverride.current = option.id
                                resolve()
                            }
                        })
                } else {
                    lastOverride.current = option.id
                    resolve()
                }
            } else {
                resolve()
            }
        })
    }

    function handleProduct(group, option, variation, component, product, remove = false, globalCheck = true) {
        const apply = () => {
            if (onProduct) {
                // If product has feature media, show it
                if (product.featureMediaId) {
                    setFeatureMedia(product.featureMediaId)
                }
                onProduct(group, option, variation, component, product, editPackage)
            }
        }
        const key = selectionKey(group, option, variation, component)
        if (!(key in selections) || remove || variation.multiSelect || selections[key][0]?.product.id != product.id) {
            if (globalCheck) {
                checkGlobal(option).then(apply)
            } else {
                apply()
            }
        }
    }

    function handlePackage(group, option, variation, component, pckge) {
        const apply = () => {
            if (onPackage) {
                // If product has feature media, show it
                if (pckge.featureMediaId) {
                    setFeatureMedia(pckge.featureMediaId)
                }
                onPackage(group, option, variation, component, pckge)
            }
        }
        const key = selectionKey(group, option, variation, component)
        const existing = key in selections ? selections[key][0] : null
        if (!existing || existing[0]?.pckge?.id != pckge?.id) {
            checkGlobal(option).then(apply)
        }
    }

    function clearEditOption() {
        clearTimeout(animTimeout.current)
        if (editPackage && !hiddenPackage.current && !editOption) {
            setAnimating(-1)
        } else {
            setAnimating(-2)
        }
        animTimeout.current = setTimeout(() => {
            if ((editPackage && !editOption) || hiddenPackage.current) {
                setEditPackage(null)
                hiddenPackage.current = false
            }
            setEditOption(null)
            setEditComponent(null)
            setAnimating(0)
        }, 250)
    }

    function selectOption(x, y, focus = true) {
        if (!focus) {
            return
        }
        if (editOption && x.id == editOption.id) {
            return
        }

        if (editOption != null && !x.id in maps.optionPackage) {
            // setEditPackage(null)
            setEditOption(x)
            setEditComponent(y)
            setFocusOption(focus)
        } else {
            clearTimeout(animTimeout.current)
            setAnimating(2)
            // setEditPackage(null)
            setEditOption(x)
            setEditComponent(y)
            setFocusOption(focus)
            animTimeout.current = setTimeout(() => {
                setAnimating(0)
            }, 250)
        }
    }

    function selectPackage(pckge, option, focus = true) {
        if (!focus) {
            return
        }

        hiddenPackage.current = false
        if (editPackage != null) {
            setEditPackage(pckge)
        } else {
            clearTimeout(animTimeout.current)
            setAnimating(1)
            setEditPackage(pckge)
            animTimeout.current = setTimeout(() => {
                setAnimating(0)
            }, 250)
        }
    }

    function tourSelect(x, y, focus = true, hidden = false) {
        // See if this option matches package option
        let packageId = null
        let packageOption = false
        if (x.id in maps.optionInPackage) {
            const anyPckgeId = Object.keys(maps.optionInPackage[x.id])[0]
            // Get the source package for this packageId
            const o = maps.packageOption[anyPckgeId]
            // Get the currently selected package for this option
            const v = o.variations[0]
            const c = v.components[0]
            const sel = selections[selectionKey(group, o, v, c)]
            if (sel) {
                packageId = sel[0].product.upgradePackageId
            }
        } else if (y == null && x.id in maps.optionPackage) {
            // Get selection for this option
            const sel = Object.values(selections).find((y) => y.find((z) => z.option.id == x.id))
            if (sel && sel.length > 0) {
                if (sel[0].product.upgradePackageId) {
                    packageId = sel[0].product.upgradePackageId
                }
            }
        }
        if (packageId) {
            const pckge = maps.package[packageId]
            selectPackage(pckge, x, focus)
            hiddenPackage.current = hidden
            if (x.id in maps.optionInPackage) {
                selectOption(x, y, focus)
            }
            // selectPackage(, x, focus)
        } else {
            if (!editOption || editOption.id != x.id) {
                selectPackage(null, null, focus)
                selectOption(x, y, focus)
            }
        }
    }

    function handleCustomOption() {
        // const customOptions = Object.values(selections).filter((x) => x.find((y) => y.custom))
        onProduct(
            group,
            { id: group.id, custom: group.id },
            { id: group.id },
            { id: fnc.uniqueId(10), configureQuantity: true },
            { id: fnc.uniqueId(10) },
        )
        /*const config = getConfig(maps, buildingType, floorplan, floorplanVariation, option, optionVariation, component, product)
        if (selected && (config && config.quantity && quantity != config.quantity || (!(config && config.quantity) && quantity > 1)) || note != null) {
            dispatch(showPrompt({ type: PromptType.Confirm, title: 'Remove Configured Selection?', message: 'Customizations will be lost' }))
                .then((x) => {
                    if (x.payload) {
                        onProduct(option, optionVariation, component, product)
                    }
                })
        } else {
            onProduct(option, optionVariation, component, product)
        }*/
    }
    function getOption(x, ix, depth = 0, options = null, isEdit = false) {
        const option = maps.option[x.upgradeOptionId]
        if (!option || !option.variations || option.variations.length == 0) {
            return null
        }
        let pckge = null
        if (x.upgradePackageId) {
            pckge = maps.package[x.upgradePackageId]
        }

        return <UpgradeOption
            {...props}
            key={x.upgradeOptionId}
            option={option}
            optionElem={x}
            pckge={pckge}
            idx={ix}
            depth={depth}
            options={options}
            isEdit={isEdit}
            editPackage={editPackage}
            editOption={editOption}
            editComponent={editComponent}
            clearEditOption={clearEditOption}
            selections={selections}
            customizations={customizations}
            getSelectedProducts={getSelectedProducts}
            selectPackage={selectPackage}
            selectOption={selectOption}
            onProduct={handleProduct}
            onPackage={handlePackage}
            onPackageReset={onPackageReset}
            disableChanges={disableChanges} />
    }

    function getPackage(_pckge) {
        // Hack for edit package
        const pckge = maps.package[_pckge.id]
        // Return list of drillable options for package
        let optionTier = {}
        let tierReached = UpgradeTier.Upgrade
        const getLowestTier = (x) => {
            const prod = maps.product[x.upgradeProductId]
            const opt = maps.option[x.upgradeOptionId]
            const key = `${opt.id}_${prod.id}`
            let lowestTier = 10000
            if (key in maps.productPackage) {
                let packagePrefix = ''
                maps.productPackage[key].forEach((x) => {
                    const sourcePackage = maps.package[x]
                    lowestTier = Math.min(lowestTier, sourcePackage.upgradeTierId)
                })
            }
            return lowestTier
        }
        const options = [...pckge.products].filter((x) => {
            // Filter out options if explicilitly filtered
            if (maps.option[x.upgradeOptionId].hidden) {
                return false
            }
            return !(group.id in maps.groupPackageOptionFilter && pckge.id in maps.groupPackageOptionFilter[group.id])
                || x.upgradeOptionId in maps.groupPackageOptionFilter[group.id][pckge.id]
        }).sort(orderSort).sort((a, b) => {
            // Second sort, if package includes same products as standard, put them at the end after a header
            let tierA = 0
            let tierB = 0
            if (!(a.upgradeOptionId in optionTier)) {
                tierA = optionTier[a.upgradeOptionId] = getLowestTier(a)
            } else {
                tierA = optionTier[a.upgradeOptionId]
            }
            if (!(b.upgradeOptionId in optionTier)) {
                tierB = optionTier[b.upgradeOptionId] = getLowestTier(b)
            } else {
                tierB = optionTier[b.upgradeOptionId]
            }
            return tierB - tierA
        }).map((x, ix) => {
            let tier = optionTier[x.upgradeOptionId]
            if (tier < tierReached && tier < pckge.upgradeTierId) {
                tierReached = tier
                return <React.Fragment>
                    <span className="tier-divider">{UpgradeTier[tier].toReadable()}</span>
                    {getOption(x, ix, 0, pckge.products)}
                </React.Fragment>
            }
            return getOption(x, ix, 0, pckge.products)
        })
        return <React.Fragment>
            <div className="option-title nested" onClick={clearEditOption}>
                <BackButton onClick={clearEditOption} />
                <span>{`${pckge.name.capitalize()}`}</span>
            </div>
            {options}
        </React.Fragment>
    }

    function getSelectedProducts(option, optionVariation, component) {
        const key = selectionKey(group, option, optionVariation, component)
        let selectedProducts = {}
        if (key in selections) {
            if (Array.isArray(selections[key])) {
                selectedProducts = selections[key].reduce((acc, x) => ({ ...acc, [x.product.id]: x }), {})
            } else {
                selectedProducts = { [selections[key].product.id]: selections[key] }
            }
        } else {
            // Check for defaults packages
            const packages = group.options.filter((x) => x.upgradePackageId != null).map((x) => maps.package[x.upgradePackageId])
            packages.forEach((x) => {
                const packageProducts = x.products?.filter((y) => y.upgradeOptionId == option.id)
                packageProducts?.forEach((y) => {
                    selectedProducts[y.upgradeProductId] = maps.product[y.upgradeProductId]
                })
            })
        }
        return selectedProducts
    }

    function getCustomOptions() {
        const customComponents = []

        const addSelection = (x) => {
            if (x.option.custom == group.id) {
                customComponents.push(x)
            }
        }

        Object.values(selections).forEach((x) => {
            x.forEach((y) => {
                if (y.option) {
                    addSelection(y)
                }
            })
        })

        if (!admin && customComponents.length == 0) {
            return
        }

        return <div className="row upgrade-option">
            {!disableChanges && <div className="option-title">
                <span>Custom Option</span>
                {admin && <Button onClick={handleCustomOption} icon="fas fa-plus" tertiary>Option</Button>}
            </div>}
            <div className="row upgrade-component">
                {customComponents.map((x) => {
                    const { option, variation, component, product } = x
                    return <UpgradeProduct
                        app={app}
                        group={group}
                        maps={maps}
                        spec={{ ...spec, option, optionVariation: variation, component, product }}
                        customizations={customizations}
                        selection={getSelectedProducts(option, variation, component)}
                        onProduct={handleProduct}
                        onProductValue={onProductValue}
                        admin={admin}
                        showPricing={true}
                        disableChanges={disableChanges} />
                })}
            </div>
        </div>
    }

    function getOptions() {
        let animationA = ''
        let animationB = ''
        switch (animating) {
            case -2:
                animationA = 'animate__animated animate__fadeOutLeft animate__fastest'
                break
            case 2:
                animationA = 'animate__animated animate__fadeInLeft animate__fastest'
                break
            case -1:
                animationA = animationB = 'animate__animated animate__fadeOutLeft animate__fastest'
                break
            case 1:
                animationA = animationB = 'animate__animated animate__fadeInLeft animate__fastest'
                break
            case 0:
            default:
                break
        }
        const groupStyle = {}
        if (groupHeight) {
            groupStyle.height = groupHeight
        }

        let floorplanOptions = []
        if (floorplanVariation && floorplanVariation.id in maps.floorplanVariationUpgradeOption && maps.floorplanVariationUpgradeOption[floorplanVariation.id].length > 0) {
            floorplanOptions = maps.floorplanVariationUpgradeOption[floorplanVariation.id].reduce((acc, x) => ({ ...acc, [x.upgradeOptionId]: x }), {})
        } else {
            floorplanOptions = Object.keys(maps.variationOptions).reduce((acc, x) => ({ ...acc, [x]: x }), {})
        }
        const options = [...group.options].filter((x) => {
            const opt = maps.option[x.upgradeOptionId]
            return x.upgradeOptionId in floorplanOptions && x.visibilityId != Visibility.Hidden && x.visibilityId != Visibility.Filter && !(opt.adminOnly && !admin)
        }).sort(orderSort)

        let visibleOptions = true
        if (disableChanges) {
            visibleOptions = options.find((x) => {
                const opt = maps.option[x.upgradeOptionId]
                return opt.variations.find((y) => {
                    return y.components.find((z) => {
                        const key = selectionKey(group, opt, y, z)
                        return key in selections
                    })
                })
            }) != null
        }

        // Get additional options
        return <div className="row" style={groupStyle}>
            <div className={`upgrade-group-options scrollable show-scroll scroll-secondary animate__animated animate_fadeInLeft animate__fastest`} ref={setScrollRef}>
                {options.map((x, ix) => {
                    return getOption(x, ix, 0, group.options)
                })}
                {!visibleOptions && <div className="row upgrade-option">
                    <div className="option-title">
                        <span>No options selected</span>
                    </div>
                </div>}
                {getCustomOptions()}
            </div>
            {editPackage && !hiddenPackage.current && <div className={`upgrade-group-options edit-options scrollable show-scroll scroll-secondary ${animationB}`} key={editPackage.id}>
                {getPackage(editPackage)}
            </div>}
            {editOption && <div className={`upgrade-group-options edit-options scrollable show-scroll scroll-secondary ${animationA}`} key={editOption.id}>
                {getOption({ upgradeOptionId: editOption.id, upgradePackageId: editPackage ? editPackage.id : null }, 0, 1, null, true)}
            </div>}
        </div>
    }

    function showMedia(x, idx) {
        if (featureMedia) {
            dispatch(showPrompt({ type: PromptType.Lightbox, media: [featureMedia], app }))
        } else {
            dispatch(showPrompt({ type: PromptType.Lightbox, media: groupMedia, startIndex: idx, app }))
        }
    }

    function getPreview() {
        if (mobileView && !focus) {
            return
        }

        if (featureMedia) {
            if (collage) {
                return <UpgradeCollage app={app} media={featureMedia} onClick={showMedia} />
            }

            return <div className={`row upgrade-option-group-preview animate__animated animate__fadeIn ${hasFloorplanMedia ? ' floorplan-media' : ''}`}>
                <SlideShow app={app} media={[featureMedia]} zoomable={false} showIndex={false} showDots={true} onClick={showMedia} mediaOptions={{ thumb: true, thumbSize: ThumbSize.ExtraLarge }} />
                {group && group.media.length > 0 && <CloseButton onClick={() => setFeatureMedia(null)} />}
            </div>
        }
        if (group && groupMedia.length > 0) {
            const sampleMedia = media[groupMedia[0]]
            switch (sampleMedia.mediaTypeId) {
                case MediaType.Image:
                    return <div className={`row upgrade-option-group-preview animate__animated animate__fadeIn ${hasFloorplanMedia ? ' floorplan-media' : ''}`}>
                        <SlideShow app={app} media={groupMedia} zoomable={false} autoScroll={hasFloorplanMedia ? 0 : 8000} showIndex={false} showDots={true} onClick={showMedia} mediaOptions={{ thumb: true, thumbSize: ThumbSize.ExtraLarge }} />
                    </div>
                case MediaType.Tour:
                    return <div className={`row upgrade-option-group-preview animate__animated animate__fadeIn tour-media`} onWheel={(e) => e.preventDefault()} onScroll={(e) => e.preventDefault()} ref={setMediaRef}>
                        <UpgradeTour
                            app={app}
                            maps={maps}
                            media={sampleMedia}
                            group={group}
                            selections={selections}
                            editPackage={editPackage}
                            editOption={editOption}
                            editComponent={editComponent}
                            focusOption={focusOption}
                            onSelect={tourSelect}
                            onProduct={handleProduct}
                            onPackage={handlePackage}
                            viewRef={groupRef}
                            focus={focus} />
                    </div>
                    return
                case MediaType.File:
                    if (!sampleMedia?.link.includes('pdf')) {
                        return null
                    }
                    const mediaLink = getMediaLink(sampleMedia.id, { app })
                    // Pdf viewer
                    return <iframe src={mediaLink} />
                // return <Document file={mediaLink} />
            }
        }
        return null
    }

    const optionStyle = {}
    if (mobileView) {
        optionStyle.minHeight = (groupMediaType == MediaType.Tour) ? '50%' : '66.66%'
    }

    let groupName = group.name
    if (group.id in maps.variationOptionGroups && maps.variationOptionGroups[group.id].name) {
        groupName = maps.variationOptionGroups[group.id].name
    }

    return <div className={`upgrade-option-group upgrade-focus-group${focus ? ' focus' : ''}${className ? ` ${className}` : ''}`} id={group.id} data-id={group.id} style={groupStyle} ref={groupRef}>
        <div className="option-column" style={optionStyle} ref={setOptionRef}>
            <div className="upgrade-option-group-title" ref={setTitleRef}>
                <h3>{groupName}</h3>
            </div>
            {isVisible && getOptions()}

        </div>

        <div className="preview-column">
            {isVisible && getPreview()}
        </div>
    </div >
}

import React, { useEffect, useState, useRef } from 'react'

import { SpinQuality } from 'app/types'
import { logger } from 'helpers/logger'
import { useScene } from 'react-babylonjs'
import { Vector3, Color3 } from '@babylonjs/core'
import '@babylonjs/loaders/OBJ'
import { getMediaUrl, getSpinUrl } from 'helpers/config'
import { setQuality, setPower, setSpinError } from 'actions/spinActions'
import { SpinConstants } from './SpinConstants'
import { AssetLoadHelper } from './babylon'

interface SpinPlaneProps {
    app: AppData,
    spin: SpinData,
    spinState: SpinStateData
    view: React.RefObj,
    viewState: SpinViewStateData,
    rotation: number,
    scale: number,
    camera,
    cameraSettings,
    dispatch,
    onLoaded,
    onIndex,
    splitIndex: number,
    media: Dict,
}

export function SpinPlane(props: SpinPlaneProps) {
    const { app, camera, cameraSettings, spin, spinState, view, viewState, rotation, scale, dispatch, onLoaded, onIndex, splitIndex, media } = props
    const [textures, setTextures] = useState({})
    const [index, setIndex] = useState(null)
    const loader = useRef()
    const texturesAlt = useRef([])
    const spinMedia = media[view.current.mediaId]
    const totalImages = spinMedia.fileCount
    const meshes = useRef([null, null])

    // st view = useAppSelector((state:RootState) => state.spin.spins[spinLink].views[viewLink])
    const scene = useScene()

    function clearTextures() {
        // Clear textures
        setTextures({})
        Object.keys(texturesAlt.current).forEach((x) => {
            texturesAlt.current[x].dispose()
        })
        texturesAlt.current = {}
    }


    useEffect(() => {
        if (!viewState) {
            return
        }

        return clearTextures
    }, [viewState && viewState.quality])

    useEffect(() => {
        meshes.current.forEach((x) => {
            setupMesh(x)
        })
    }, [scale])

    function setupMesh(x) {
        if (!x) {
            return
        }
        if (!x.initialized) {
            x.isPickable = false
            x.initialized = true
        }
        x.scaling = new Vector3(scale * viewState.aspect, scale, scale)
        // x.scaling = new Vector3(scale, scale / viewState.aspect, scale)
    }

    function handlePlane(i, x) {
        meshes.current[i] = x
        setupMesh(x)
    }

    function getTexturePath(i, quality) {
        const qualityString = SpinQuality[quality].toLowerCase()
        const spinPath = `${getMediaUrl(app)}/${spinMedia.link}/${qualityString}/`
        const name = `${spinMedia.link}_${qualityString}_${(i).toString().padStart(3, '0')}.jpg`
        return { url: `${spinPath}${name}`, name }
    }


    function loadComplete(x) {
        loader.current = null
        // Merge with state textures
        const newTextures = { ...x.textures }
        Object.keys(newTextures).forEach((y) => newTextures[y] = newTextures[y].texture)
        texturesAlt.current = { ...texturesAlt.current, ...newTextures }
        setTextures(texturesAlt.current)
        const current = viewState.currentPower
        const next = (current == null) ? viewState.maxPower : viewState.currentPower - 1
        const progress = current == null ? 0.01 : ((viewState.maxPower - current + 1) / (viewState.maxPower - viewState.minPower + 1))

        if (viewState.currentQuality == SpinQuality.None) {
            dispatch(setQuality({ spinLink: spin.current.link, viewLink: view.current.link, quality: SpinQuality.Low, splitIndex }))
        }
        // setTimeout(() => {
        if (current == null) {
            dispatch(setPower({ spinLink: spin.current.link, viewLink: view.current.link, power: viewState.maxPower, splitIndex }))
        } else if (current - 1 >= viewState.minPower) {
            dispatch(setPower({ spinLink: spin.current.link, viewLink: view.current.link, power: viewState.currentPower - 1, splitIndex }))
        }
        // }, 0)

        onLoaded(progress, view.current.link)
    }

    function loadTextures() {
        loader.current = new AssetLoadHelper(view, viewState, scene)
        // Load textures
        if (viewState.currentPower == null) {
            // Load still
            let { url, name } = getTexturePath(viewState.defaultIndex, viewState.quality)
            loader.current.queueTexture(url, name, false, false, viewState.defaultIndex)
        } else {
            // Load quality setting
            const span = Math.pow(2, viewState.currentPower)
            for (let i = 0; i < totalImages; i += span) {
                if (i in textures) {
                    continue
                }
                let { url, name } = getTexturePath(i, viewState.quality)
                loader.current.queueTexture(url, name, false, false, i)
            }
        }

        loader.current.run((x) => {
        }).then(loadComplete).catch((e) => {
            logger.error(e)
            dispatch(setSpinError({ id: spin.current.link, error: e, splitIndex }))
        })
        return loader
    }

    useEffect(() => {
        if (spinState.currentView != view.current.link) {
            return
        }

        if (viewState.currentPower >= viewState.minPower || viewState.currentPower == null) {
            loadTextures()
        }

        return () => {
            if (loader.current) {
                loader.current.quit()
            }
        }
    }, [view.current, viewState.currentPower])

    useEffect(() => {
        if (index == null) {
            setIndex(viewState.defaultIndex)
            onIndex(viewState.defaultIndex)
        }

    }, [textures])

    useEffect(() => {
        // Update rotation

        let seek = (Math.abs(rotation) % 100) / 100
        if (!view.current.reverse) {
            seek = 1 - seek
        }

        if (rotation < 0) {
            seek = 1 - seek
        }

        // Get the root level index
        const i = Math.round(seek * totalImages) % 256

        // Check for existence, if not, divide by 2 and get next level
        let level = 1
        let qualityLevel = 1
        let idx = i
        if (viewState.currentPower == null) {
            idx = viewState.defaultIndex
        } else {
            while (!textures[idx] && level < totalImages) {
                // while (qualityLevel < view.quality || (!view.textures[idx] && !view.texturesB[idx] && level < view.totalImages)) {
                // qualityLevel += 1
                level *= 2
                // Round I to next granularity
                // i.e. first by 1, 2, 4, 8, 16, 32, 64, 128
                idx = Math.floor(i / level) * level
            }

            // if (!view.textures[idx] && !view.texturesB[idx]) {
            // Brute force backup
            if (!textures[idx]) {
                /*for (let j = 0; j < totalImages; j++) {
                    if (textures[j]) {
                        idx = j
                        break
                    }
                }*/
            }
        }
        if (textures[idx]) {
            setIndex(idx)
            onIndex(idx)
        }
    }, [rotation])

    if (view.current == null || viewState == null) {
        return null
    }

    if (index == null || !(index in textures)) {
        return null
    }

    const texture = textures[index]
    const planeRotation = new Vector3(Math.PI * 0.5 - cameraSettings.beta, Math.PI * 1.5 - cameraSettings.alpha, 0)
    return <React.Fragment>
        <plane key="back" size={1} name="plane" ref={(x) => handlePlane(0, x)} renderingGroupId={SpinConstants.RENDERING_GROUP_SPIN_BASE} position={cameraSettings.target} rotation={planeRotation}>
            <standardMaterial name='test' emissiveColor={new Color3(1, 1, 1)} specularColor={new Color3(0, 0, 0)} alpha={1}>
                <texture key={`a-${index}`} fromInstance={texture} assignTo="diffuseTexture" />
            </standardMaterial>
        </plane>
        <plane key="front" size={1} name="plane2" ref={(x) => handlePlane(1, x)} renderingGroupId={SpinConstants.RENDERING_GROUP_SPIN_OVERLAY} position={cameraSettings.target} rotation={planeRotation}>
            <standardMaterial name='test2' emissiveColor={new Color3(1, 1, 1)} specularColor={new Color3(0, 0, 0)} alpha={0.8}>
                <texture key={`b-${index}`} fromInstance={texture} assignTo="diffuseTexture" />
            </standardMaterial>
        </plane>
        {/*<plane name="plane" ref={(x) => handlePlane(1, x)} renderingGroupId={3}>
            <standardMaterial name='test' emissiveColor={new Color3(1, 1, 1)} alpha={0.8} specularColor={new Color3(0, 0, 0)}>
                <texture key={index} fromInstance={texture} assignTo="diffuseTexture" />
            </standardMaterial>
        </plane>*/}
    </React.Fragment>
}

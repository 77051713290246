import { PageType, FieldType, AnalyticsEvent, UpgradeOrderStatus, FormFieldType } from './types'

export const pageOrder = [
    PageType.ScreenSaver,
    PageType.Home,
    PageType.Info,
    PageType.Launch,
    PageType.Floorplan,
    PageType.Building,
    PageType.Gallery,
    PageType.Modelhome,
    PageType.Amenities,
    PageType.Sitemap,
    PageType.Upgrades,
    PageType.Neighbourhood,
    PageType.Custom,
    PageType.External,
]

export const icons = {
    info: 'fas fa-info',
    floorplan: 'fas fa-vector-square',
    gallery: 'fas fa-images',
    tour: 'fas fa-walking',
    home: 'fas fa-home',
    neighbourhood: 'fas fa-directions',
    amenities: 'fas fa-dumbbell',
    map: 'fas fa-map',
    swatches: 'fas fa-swatchbook',
    plus: 'fas fa-plus',
    minus: 'fas fa-minus',
    times: 'fas fa-times',
    expand: 'fas fa-expand',
    expandAlt: 'fas fa-expand-alt',
    compress: 'fas fa-compress',
    compressAlt: 'fas fa-compress-alt',
    paperPlane: 'fas fa-paper-plane',
    trash: 'fas fa-trash',
    trashAlt: 'fas fa-trash-alt',
    share: 'fas fa-share',
    shareAlt: 'fas fa-share-alt',
    pen: 'fas fa-pen',
    link: 'fas fa-link',
    check: 'fas fa-check',
    filter: 'fas fa-filter',
    quote: 'fas fa-quote-right',
    clone: 'fas fa-clone',
    heart: 'fas fa-heart',
    stickyNote: 'fas fa-sticky-note',
    ellipsisH: 'fas fa-ellipsis-h',
    ellipsisV: 'fas fa-ellipsis-v',
    globe: 'fas fa-globe',
    calendar: 'fas fa-calendar',
    bed: 'fas fa-bed',
    bath: 'fas fa-bath',
    units: 'fas fa-ruler-vertical',
    calculate: 'fas fa-calculator',
    angle: 'fas fa-angle-right',
    user: 'fas fa-user',
    userCircle: 'fas fa-user-circle',
    map: 'fas fa-map-marker-alt',
    envelope: 'fas fa-envelope',
    hamburger: 'fas fa-bars',
    signOut: 'fas fa-sign-out-alt',
    search: 'fas fa-search',
    undo: 'fas fa-undo',
    play: 'fas fa-play',
    pause: 'fas fa-pause',
    circle: 'far fa-circle',
    volumeMute: 'fas fa-volume-mute',
    volumeDown: 'fas fa-volume-down',
    volumeUp: 'fas fa-volume-up',
    chevronLeft: 'fas fa-chevron-left',
    chevronRight: 'fas fa-chevron-right',
    chevronUp: 'fas fa-chevron-up',
    chevronDown: 'fas fa-chevron-down',
    arrowLeft: 'fas fa-arrow-left',
    arrowRight: 'fas fa-arrow-right',
    arrowUp: 'fas fa-arrow-up',
    arrowDown: 'fas fa-arrow-down',
    alignLeft: 'fas fa-align-left',
    alignRight: 'fas fa-align-right',
    alignCenter: 'fas fa-align-center',
    alignJustify: 'fas fa-align-justify',
    download: 'fas fa-download',
    upload: 'fas fa-upload',
    redoAlt: 'fas fa-redo-alt',
    export: 'fas fa-file-export',
    exclamationCircle: 'fas fa-exclamation-circle',
    copy: 'fas fa-copy',
    searchPlus: 'fas fa-search-plus',
    searchMinus: 'fas fa-search-minus',
    infoCircle: 'fas fa-info-circle',

}

export const pageIcons = {
    '': icons.info,
    [PageType.ScreenSaver]: icons.info,
    [PageType.Info]: icons.info,
    [PageType.Floorplan]: icons.floorplan,
    [PageType.Gallery]: icons.gallery,
    [PageType.Modelhome]: icons.tour,
    [PageType.Neighbourhood]: icons.neighbourhood,
    // [PageType.Building]: 'fas fa-building',
    [PageType.Building]: icons.floorplan,
    [PageType.Amenities]: icons.amenities,
    [PageType.Sitemap]: icons.map,
    [PageType.Upgrades]: icons.swatches,
    [PageType.Custom]: icons.plus,
    [PageType.External]: icons.link,
    [PageType.Launch]: icons.check
}

// TODO, in database?
export const customIcons = {
    amenities: 'fas fa-dumbbell',
    gettingaround: 'fas fa-walking', //'fas fa-route', //'fas-fa-biking'
    thingstodo: 'fas fa-utensils',
    featuresandfinishes: 'fas fa-list-alt',
    team: 'fas fa-user-friends',
}

export const acceptedBaseRoutes = ['search', 'developments', 'favourites']

export const contactText = 'Request Info'

export const referralSources = ['Agent', 'Referral', 'Signage', 'Facebook', 'Instagram', 'Google', 'Youtube', 'Other']
export const Provinces = ['ON', 'NS', 'NL', 'PE', 'NB', 'QC', 'MB', 'SK', 'AB', 'BC', 'YT', 'NT', 'NU']
export const States = ['AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UM', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY']
export const LocationFields = (editData: Dict) => ({
    name: {
        type: FieldType.String,
        label: 'Name',
        label: 'name',
    },
    country: {
        type: FieldType.Option,
        label: 'Country Code',
        options: ['CA', 'US'],
    },
    numberAndStreet: {
        type: FieldType.String,
        label: 'Street and Number',
        name: 'address-line-1',
    },
    unit: {
        type: FieldType.String,
        label: 'Unit',
        name: 'address-line-2',
    },
    city: {
        type: FieldType.String,
        label: 'City',
        name: 'address-line-3',
    },
    provinceState: {
        type: FieldType.Option,
        label: 'Province / State',
        options: editData['country'] === 'CA' ? Provinces : States,
    },
    postalZipCode: {
        type: FieldType.String,
        label: 'Postal / Zip Code',
        name: 'postal-code',
    },
    note: {
        type: FieldType.String,
        label: 'Note',
        label: 'note',
    },
    latLng: {
        type: FieldType.String,
        label: 'Latitude and Longitude',
    },
})

export const LocationDefault = {
    name: '',
    numberAndStreet: '',
    unit: '',
    city: '',
    provinceState: '',
    country: 'CA',
    postalZipCode: '',
    latLng: '',
    note: '',
}

export const LOREM_IPSUM = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'

export const tutorial = [
    { pageType: PageType.Info, title: 'View project information' },
    { pageType: PageType.Sitemap, title: 'View floor plans here' },
    { pageType: PageType.Modelhome, title: 'Virtual tours are here' },
    { link: 'register', title: 'Register here' },
]

export const pageDescription = {
    [PageType.Sitemap]: 'View floor plans',
    [PageType.Floorplan]: 'View floor plans',
    [PageType.Modelhome]: "Our virtual tours allow you to take a detailed walkthrough of our semi-detached homes from the comfort of your home.",
    [PageType.Gallery]: 'View gallery',
}

export const faqStyles = {
    rowContentPaddingBottom: '10px',
}

export const upgradeOrderStatusIcons = {
    [UpgradeOrderStatus.Ready]: 'far fa-circle',
    [UpgradeOrderStatus.Pending]: 'fas fa-envelope',
    [UpgradeOrderStatus.Progress]: 'fas fa-tasks',
    [UpgradeOrderStatus.Restricted]: 'fas fa-lock',
    [UpgradeOrderStatus.Complete]: 'fas fa-check',
    [UpgradeOrderStatus.Signed]: 'fas fa-signature',
}

export const requestInfoFields = (user, infoTarget) => {
    return [
        {
            id: 'name',
            label: 'Name',
            required: true,
            initial: user ? user.name : null,
            formFieldTypeId: FormFieldType.Text,
        },
        {
            id: 'phone',
            label: 'Phone',
            required: true,
            initial: user ? user.phone : null,
            formFieldTypeId: FormFieldType.Phone,
            inputProps: { phone: true },
            isHubspot: true,
        },
        {
            id: 'email',
            label: 'Email',
            required: true,
            initial: user ? user.email : null,
            formFieldTypeId: FormFieldType.Email,
            fullWidth: true,
            isHubspot: true,
        },
        {
            id: 'inquiry',
            label: 'Inquiry',
            initial: `Please send me information about ${infoTarget}`,
            required: true,
            inputProps: { textarea: true },
            cache: false,
            formFieldTypeId: FormFieldType.Text,
            isHubspot: true
        },
    ]
}

export const message = {
    exclusiveUnlock: 'Thanks for registering! You now have access to new pages on the menu.',
    suspiciousActivityForm: 'We have detected suspicious activity. Please try again later.',
}
import * as fnc from 'helpers/fnc'
/**
 * CSV download scripts https://github.com/react-csv/react-csv
 * Author https://github.com/abdennour
 */

/**
 * Simple safari detection based on user agent test
 */
const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const isJsons = ((array) => Array.isArray(array) && array.every(
    row => (typeof row === 'object' && !(row instanceof Array))
))

const isArrays = ((array) => Array.isArray(array) && array.every(
    row => Array.isArray(row)
))

const jsonsHeaders = ((array) => Array.from(
    array.map(json => Object.keys(json))
        .reduce((a, b) => new Set([...a, ...b]), [])
))

const jsons2arrays = (jsons, headers) => {
    headers = headers || jsonsHeaders(jsons)

    // allow headers to have custom labels, defaulting to having the header data key be the label
    let headerLabels = headers
    let headerKeys = headers
    if (isJsons(headers)) {
        headerLabels = headers.map((header) => header.label)
        headerKeys = headers.map((header) => header.key)
    }

    const data = jsons.map((object) => headerKeys.map((header) => getHeaderValue(header, object)))
    return [headerLabels, ...data]
}

const getHeaderValue = (property, obj) => {
    const foundValue = property
        .replace(/\[([^\]]+)]/g, ".$1")
        .split(".")
        .reduce(function (o, p, i, arr) {
            // if at any point the nested keys passed do not exist, splice the array so it doesnt keep reducing
            const value = o[p]
            if (value === undefined || value === null) {
                arr.splice(1)
            } else {
                return value
            }
        }, obj)
    // if at any point the nested keys passed do not exist then looks for key `property` in object obj
    return (foundValue === undefined) ? ((property in obj) ? obj[property] : '') : foundValue
}

const elementOrEmpty = (element) =>
    (typeof element === 'undefined' || element === null) ? '' : element

const joiner = ((data, separator = ',', enclosingCharacter = '"') => {
    return data
        .filter(e => e)
        .map(
            row => row
                .map((element) => elementOrEmpty(element))
                .map(column => `${enclosingCharacter}${column}${enclosingCharacter}`)
                .join(separator)
        )
        .join(`\n`)
})

const arrays2csv = ((data, headers, separator, enclosingCharacter) =>
    joiner(headers ? [headers, ...data] : data, separator, enclosingCharacter)
)

const jsons2csv = ((data, headers, separator, enclosingCharacter) =>
    joiner(jsons2arrays(data, headers), separator, enclosingCharacter)
)

const string2csv = ((data, headers, separator, enclosingCharacter) =>
    (headers) ? `${headers.join(separator)}\n${data}` : data.replace(/"/g, '""')
)

const toCSV = (data, headers, separator, enclosingCharacter) => {
    if (isJsons(data)) return jsons2csv(data, headers, separator, enclosingCharacter)
    if (isArrays(data)) return arrays2csv(data, headers, separator, enclosingCharacter)
    if (typeof data === 'string') return string2csv(data, headers, separator)
    throw new TypeError(`Data should be a "String", "Array of arrays" OR "Array of objects" `)
}

const buildURI = ((data, uFEFF, headers, separator, enclosingCharacter) => {
    const csv = toCSV(data, headers, separator, enclosingCharacter)
    const type = isSafari() ? 'application/csv' : 'text/csv'
    const blob = new Blob([uFEFF ? '\uFEFF' : '', csv], { type })
    const dataURI = `data:${type}charset=utf-8,${uFEFF ? '\uFEFF' : ''}${csv}`

    const URL = window.URL || window.webkitURL

    return (typeof URL.createObjectURL === 'undefined')
        ? dataURI
        : URL.createObjectURL(blob)
})

export interface CSVProps {
    data: string[][],
    headers: string[],
    target: string,
    separator: string,
    filename: string,
    uFEFF: bool,
    enclosingCharacter: string
}

const defaultProps = {
    separator: ',',
    filename: 'data.csv',
    uFEFF: true,
    asyncOnClick: false,
    enclosingCharacter: '"',
}

export function downloadCSV(props: CSVProps) {
    const { filename, data, headers, separator, enclosingCharacter, uFEFF, target, specs, replace } = {
        ...defaultProps,
        ...props,
    }
    const url = buildURI(data, uFEFF, headers, separator, enclosingCharacter)
    fnc.downloadURI(url, filename)
}

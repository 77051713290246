import * as fnc from 'helpers/fnc'
import { logger } from 'helpers/logger'

export function parseKML(kml) {

    const icons = {}
    let elements = []

    function asArray(x) {
        if (!x) {
            return []
        }
        if (Array.isArray(x)) {
            return x
        } else {
            return [x]
        }
    }
    asArray(kml.stylemap).forEach((x) => {
        try {
            icons[x.Attributes.id] = {}
        } catch (e) {
            logger.error("Error parsing KML stylemap ", e)

        }
    })
    asArray(kml.style).forEach((x) => {
        try {
            const tokens = x.Attributes.id.split('-')
            const variant = tokens.pop()
            const key = tokens.join('-')
            if (key in icons) {
                icons[key][variant] = { icon: x.iconstyle, label: x.labelstyle }
            }
        } catch (e) {
            logger.error("Error parsing KML style ", e)
        }
    })
    let index = 0
    asArray(kml.folder).forEach((x, ix) => {
        try {
            const elem = { title: x.name && typeof (x.name) == 'string' ? x.name.toLowerCase().toTitleCase() : ix }
            const marks = Array.isArray(x.placemark) ? x.placemark : [x.placemark]
            elem.elements = marks.map((y, iy) => {
                index += 1
                const coords = y.point.coordinates.replace('\n', '').trim().split(',')
                return {
                    id: index,
                    title: y.name && typeof (y.name) == 'string' ? y.name.toLowerCase().toTitleCase() : iy,
                    locations: [{ latLng: `${coords[1]},${coords[0]}` }],
                    style: y.styleurl
                }
            })
            elements.push(elem)
        } catch (e) {
            logger.error("Error parsing KML folder ", e)
        }
    })

    return { title: kml.name, body: kml.description, elements, icons }
}
import React from 'react'
import { navigateBackAsync } from 'actions/appActions'
import { CustomEventType } from 'app/types'
import { useAppDispatch } from 'app/hooks'
import { logger } from 'helpers/logger'
import { IconButton } from './IconButton'
import { Button } from './Button'
import { icons } from 'app/constants'

interface BackButtonProps {
    sticky?:boolean,
    fixed?:boolean,
    onClick?: () => void,
    splitIndex?: number,
    showHint?: boolean,
    className?: string,
    children?: JSX.Element,
    alt?: boolean,
    excludeAppPath: boolean,
    hint?: string,
}
export function BackButton(props:BackButtonProps) {
    const dispatch = useAppDispatch()
    const {
        sticky,
        fixed,
        onClick,
        splitIndex,
        className,
        alt,
        appLink,
        excludeAppPath,
        hint,
        ...otherProps
    } = props

    function handleClick(e:Event) {
        if (e) {
            e.stopPropagation()
        }
        if (onClick) {
            onClick()
        } else {
            dispatch(navigateBackAsync({ appLink: appLink, excludeAppPath: excludeAppPath, splitIndex: splitIndex })).catch((err:Error) => { logger.error(err) })
        }
        window.dispatchEvent(new Event(CustomEventType.CloseAllMenus))
    }

    const attrs = [sticky ? 'sticky' : null, fixed ? 'fixed' : null].filter((x) => x != null).map((x) => ` ${x}`).join()

    let buttonElem = null
    if (hint) {
        buttonElem = <Button
            className={`back-button${attrs}${className ? ` ${className}` : ''}`}
            alt={alt}
            icon={icons.chevronLeft}
            // noBg
            onClick={handleClick}
            {...otherProps}>
            {hint}
            {props.children}
        </Button>
    } else {
        buttonElem = <IconButton
            className={`back-button${attrs}${className ? ` ${className}` : ''}`}
            alt={alt}
            icon={icons.chevronLeft}
            noBg
            onClick={handleClick}
            {...otherProps}>
            {hint && <span className="hint">{hint}</span>}
            {props.children}
        </IconButton>
    }

    if (sticky) {
        return <div className="top-left fadeIn" style={{ height: '100%', pointerEvents: 'none' }}>
            {buttonElem}
        </div>
    }

    return buttonElem
}

import {
    createSlice,
    createAsyncThunk,
} from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import {
    UserState,
    ErrorMessage,
    UserData,
    AnalyticsSource,
    Analytics,
    UpgradeOrderRecordData,
    UserOrderRecordData,
} from 'app/types'
import {
    loadState,
    saveState,
} from 'app/state'
import {
    postUserLogin,
    postGoogleLogin,
    postUserRegister,
    getUser,
    postPasswordReset,
    postActivateAccount,
    postPasswordForgot,
    postWelcomeEmail,
    postActivationEmail,
    getTemporaryToken,
    getPINToken,
    postFavourite,
    deleteFavourite,
    postRequestPIN,
    postPINLogin,
    postUpgradeOrderRecord,
    getUserOrderRecords,
    postUserOrderRecord,
    deleteUserOrderRecord,
    postUserRegisterPin,
    getPINTokenRegister,
    postUserLogout,
    postReCaptchaScore,
} from 'services/userServices'
import { logger } from 'helpers/logger'
import {
    resolvePending,
    recordAnalytics,
    linkUserAnalytics,
} from './appActions'
import * as fnc from 'helpers/fnc'
import { getJWTKey } from 'helpers/authHeader'
import { retryOperation } from './actionHelpers'
import { hubspotIdentifyUser } from 'helpers/scripts'

/**
 * State
 */
const initialState: UserState = {
    loggedIn: false,
    token: null,
    data: {},
    favourites: {},
    guestGroup: null,
    humanConfidence: null,
    confidenceRefresh: null,
    ...loadState('user', true, true),
}
localStorage.setItem(getJWTKey(), initialState.token)

// Parse url

function saveUserState(state: UserState) {
    localStorage.setItem(getJWTKey(), state.token)
    const stateSlice = {
        token: state.token,
        favourites: state.favourites,
        guestGroup: state.guestGroup,
        humanConfidence: state.humanConfidence,
    }
    saveState('user', stateSlice)
}

function getFlatFavourites(state: UserStae) {
    const favourites = state.favourites
    let flatFavourites = []
    Object.keys(favourites).forEach((x) => {
        flatFavourites = flatFavourites.concat(Object.values(favourites[x]))
    })
    return flatFavourites
}

/**
 * Async actions
 */
export const loginUser = createAsyncThunk<
    { token: string },
    { email: string, password: string, googleToken: string }
>(
    'user/loginUser',
    async ({ email, password, googleToken }, { dispatch, getState, rejectWithValue }) => {
        // await new Promise((resolve) => setTimeout(resolve, 1000))
        const tryLogin = () => {
            if (googleToken) {
                return postGoogleLogin(googleToken, {
                    groups: getState().user.guestGroup ? getState().user.guestGroup : null,
                    favourites: getFlatFavourites(getState().user),
                    organizationId: getState().app.organization?.id,
                    appId: getState().app.projectCurrent[0]?.meta.id,
                }).then((x) => {
                    if (x.identifyHubspot && email) {
                        hubspotIdentifyUser(email, getState().app.analytics)
                    }
                    dispatch(resolvePending(x))
                    return x
                })
            } else {
                return postUserLogin(email, password, {
                    organizationId: getState().app.organization ? getState().app.organization.id : null,
                    appId: getState().app.projectCurrent[0]?.meta.id,
                }).then((x) => {
                    if (x.identifyHubspot && email) {
                        hubspotIdentifyUser(email, getState().app.analytics)
                    }
                    dispatch(resolvePending(x))
                    return x
                })
            }
        }
        return retryOperation(tryLogin)
            .catch((x) => {
                dispatch(resolvePending(x))
                return rejectWithValue(x)
            })
    },
)

export const logoutUser = createAsyncThunk<void, void>(
    'user/logoutUser',
    async (_, { getState, dispatch, rejectWithValue }) => {
        const tryLogout = () => {
            return postUserLogout({
                organizationId: getState().app.organization ? getState().app.organization.id : null,
                appId: getState().app.projectCurrent[0]?.meta.id
            })
        }
        return retryOperation(tryLogout)
            .catch((x) => {
                return rejectWithValue(x)
            })
    },
)

export const loginPIN = createAsyncThunk<
    { token: string },
    { pin: string, email: string, phone: string },
>(
    'user/loginPIN',
    async ({ pin, email, phone }, { dispatch, getState, rejectWithValue }) => {
        const tryLogin = () => {
            return getPINToken(pin, email, phone, {
                appId: getState().app.projectCurrent[0]?.meta.id,
                organizationId: getState().app.organization ? getState().app.organization.id : null
            })
                .then((x) => {
                    if (x.token) {
                        return postPINLogin(x.token)
                    }
                    throw x
                }).then(x => {
                    if (x.identifyHubspot && email) {
                        hubspotIdentifyUser(email, getState().app.analytics)
                    }
                    dispatch(resolvePending(x))
                    return x
                })
        }
        return retryOperation(tryLogin)
            .catch((x) => {
                dispatch(resolvePending(x))
                return rejectWithValue(x)
            })
    }
)

export const registerPIN = createAsyncThunk<
    { token: string },
    { pin: string, email: string, phone: string },
>(
    'user/registerPIN',
    async ({ pin, email, phone }, { dispatch, getState, rejectWithValue }) => {
        const tryLogin = () => {
            return getPINTokenRegister(pin, email, phone, { appId: getState().app.projectCurrent[0]?.meta.id, organizationId: getState().app.organization ? getState().app.organization.id : null })
                .then((x) => {
                    dispatch(resolvePending(x))
                    return x
                })
        }
        return retryOperation(tryLogin)
            .catch((x) => {
                if (x.identifyHubspot && email) {
                    hubspotIdentifyUser(email, getState().app.analytics)
                }
                dispatch(resolvePending(x))
                return rejectWithValue(x)
            })
    }
)

export const registerUser = createAsyncThunk<
    { token: string },
    { email: string, name: string, phone: string, password: string, welcomeEmail: boolean }
>(
    'user/registerUser',
    async ({ email, name, phone, password, activationEmail }, { dispatch, getState, rejectWithValue }) => {
        await new Promise((resolve) => setTimeout(resolve, 1000))

        const tryRegister = () => {
            return postUserRegister(email, name, phone, password, activationEmail, {
                altOrganizationId: getState().app.organization ? getState().app.organization.id : null,
                groups: getState().user.guestGroup ? getState().user.guestGroup : null,
                favourites: getFlatFavourites(getState().user),
                appId: getState().app.projectCurrent[0]?.meta.id,
            }).then((x) => {
                if (x.identifyHubspot && email) {
                    hubspotIdentifyUser(email, getState().app.analytics)
                }
                dispatch(resolvePending(x))
                return x
            })

        }
        return retryOperation(tryRegister)
            .catch((x) => {
                dispatch(resolvePending(x))
                return rejectWithValue(x)
            })
    },
)

export const registerUserPin = createAsyncThunk<
    { token: string },
    { email: string, name: string, phone: string, additional: string[] },
>(
    'user/registerUserPin',
    async (data, { dispatch, getState, rejectWithValue }) => {
        await new Promise((resolve) => setTimeout(resolve, 1000))

        const tryRegister = () => {
            return postUserRegisterPin({
                ...data,
                altOrganizationId: getState().app.organization ? getState().app.organization.id : null,
                groups: getState().user.guestGroup ? getState().user.guestGroup : null,
                favourites: getFlatFavourites(getState().user),
            }).then((x) => {
                if (x.identifyHubspot && email) {
                    hubspotIdentifyUser(email, getState().app.analytics)
                }
                dispatch(resolvePending(x))
                return x
            })

        }
        return retryOperation(tryRegister)
            .catch((x) => {
                dispatch(resolvePending(x))
                return rejectWithValue(x)
            })
    },
)

export const retrieveUser = createAsyncThunk<{ user: UserData }>(
    'user/retrieveUser',
    async (temporary, { dispatch, rejectWithValue, getState }) => {
        if (temporary) {
            const tryUser = () => {
                return getUser(getState().user.temporary)
                    .then((x) => {
                        dispatch(resolvePending(true))
                        return x
                    })
            }
            return retryOperation(tryUser)
                .catch(rejectWithValue)
        } else {
            return getUser()
        }
    },
)

export const resetPassword = createAsyncThunk<
    Dict,
    { oldPassword: string, newPassword: string, temporary: boolean },
>(
    'user/resetPassword',
    async ({ oldPassword, newPassword, temporary }, { dispatch, rejectWithValue, getState }) => {
        const tryReset = () => {
            return postPasswordReset(oldPassword, newPassword, getState().app.organization ? getState().app.organization.id : null, temporary ? getState().user.temporary : null)
                .then((x) => {
                    dispatch(resolvePending(x))
                    return x
                })
        }
        return retryOperation(tryReset)
            .catch((x) => {
                dispatch(resolvePending(x))
                return rejectWithValue(x)
            })
    }
)

export const activateAccount = createAsyncThunk<
    Dict,
>(
    'user/activate-account',
    async (x, { dispatch, rejectWithValue, getState }) => {
        const tryActivate = () => {
            return postActivateAccount(getState().user.temporary)
                .then((x) => {
                    dispatch(resolvePending(x))
                    return x
                })
        }
        return retryOperation(tryActivate)
            .catch((x) => {
                dispatch(resolvePending(x))
                return rejectWithValue(x)
            })
    }
)

export const forgotPassword = createAsyncThunk<
    string,
    string,
>(
    'user/forgotPassword',
    async (email, { dispatch, getState, rejectWithValue }) => {
        const tryForget = () => {
            return postPasswordForgot(email, getState().app.organization ? getState().app.organization.id : null)
                .then((x) => {
                    dispatch(resolvePending(x))
                    return x
                })
        }
        return retryOperation(tryForget)
            .catch((x) => {
                dispatch(resolvePending(x))
                return rejectWithValue(x)
            })
    }
)

export const resendActivationEmail = createAsyncThunk<
    string,
    string,
>(
    'user/resendActivationEmail',
    async (email, { dispatch, rejectWithValue, getState }) => {
        const tryResend = () => {
            return postActivationEmail(email, getState().app.organization ? getState().app.organization.id : null)
                .then((x) => {
                    dispatch(resolvePending(x))
                    return x
                })
        }
        return retryOperation(tryResend)
            .catch((x) => {
                dispatch(resolvePending(x))
                return rejectWithValue(x)
            })
    }
)
export const resendWelcomeEmail = createAsyncThunk<
    string,
    string,
>(
    'user/resendWelcomeEmail',
    async (email, { dispatch, rejectWithValue }) => {
        const tryResend = () => {
            return postWelcomeEmail(email)
                .then((x) => {
                    dispatch(resolvePending(x))
                    return x
                })
        }
        return retryOperation(tryResend)
            .catch((x) => {
                dispatch(resolvePending(x))
                return rejectWithValue(x)
            })
    }
)

export const retrieveTemporaryToken = createAsyncThunk<
    string,
    string,
>(
    'user/retrieveTemporaryToken',
    async ({ link, logOut }, { dispatch, rejectWithValue }) => {
        if (logOut) {
            dispatch(logoutUser())
        }
        return getTemporaryToken(link).catch(rejectWithValue)
    },
)

export const requestPIN = createAsyncThunk<
    string,
    { email: string, phone: string },
>(
    'user/requestPIN',
    async ({ email, phone }, { dispatch, getState, rejectWithValue }) => {
        return postRequestPIN(email, phone, '', getState().app.organization ? getState().app.organization.id : null)
            .then((x) => {
                dispatch(resolvePending(x))
                return x
            })
            .catch((x) => {
                dispatch(resolvePending(x))
                return rejectWithValue(x)
            })
    },
)
/**
 * Toggle an item as favourited
 */
export const toggleFavourite = createAsyncThunk<
    { appId: string, floorplandId: string, variationId: string },
    { appId: string, floorplandId: string, variationId: string },
    { state: RootState }
>(
    'user/toggleFavourite',
    async ({ app, floorplanId, variationId }, { dispatch, getState, rejectWithValue }) => {
        const tryFavourite = async () => {
            let newFavourite = false
            if (getState().user.loggedIn) {
                await postFavourite(app, floorplanId, variationId)
                    .then(x => {
                        if (!x) {
                            throw x
                        }
                        newFavourite = x.new
                        return true
                    })
            } else {
                newFavourite = !(floorplanId in getState().app.favourites)
            }

            if (newFavourite) {
                // Save favourite for user
                dispatch(recordAnalytics({
                    type: Analytics.Floorplans,
                    data: {
                        app: app,
                        floorplanId,
                        floorplanVariationId: variationId,
                        favourite: 1
                    },
                }))
            }
            return newFavourite
        }
        return retryOperation(tryFavourite)
            .catch(rejectWithValue)
    }
)

/**
 * Clear all selected favourites
 */
export const clearFavourites = createAsyncThunk<void, void>(
    'user/clearFavourites',
    async (x, { dispatch, getState, rejectWithValue }) => {
        // Delay
        await new Promise((resolve) => setTimeout(resolve, 1000))
        // TODO, if user, database clear
        if (getState().user.loggedIn) {
            const tryDelete = () => {
                return deleteFavourite()
                    .then((x) => dispatch(resolvePending(x.success == 1)))
            }
            return retryOperation(tryDelete)
                .catch(rejectWithValue)
        } else {
            return dispatch(resolvePending(true))
        }
    },
)


/**
 * Order records
 */
export const retrieveUserOrders = createAsyncThunk<
    UserOrderRecordData[],
    string,
    { state: RootState }
>(
    'user/retrieveUserOrderRecords',
    async (app, { dispatch, getState, rejectWithValue }) => {
        const tryOrder = () => getUserOrderRecords(app.meta.id)
        return retryOperation(tryOrder).catch(rejectWithValue)
    },
)

export const saveUserOrderRecord = createAsyncThunk<
    UserOrderRecord | userOrderRecord[],
    UserOrderRecord | UserOrderRecordData[],
    { state: RootState }
>(
    'user/saveUserOrderRecord',
    async (record, { dispatch, getState, rejectWithValue }) => {
        const tryOrder = () => postUserOrderRecord(record)
        return retryOperation(tryOrder).catch(rejectWithValue)
    },
)

export const removeUserOrderRecord = createAsyncThunk<
    void,
    UserOrderRecord,
    { state: RootState }
>(
    'user/removeUserOrderRecord',
    async (record, { dispatch, getState, rejectWithValue }) => {
        const tryOrder = () => deleteUserOrderRecord(record)
        return retryOperation(tryOrder).catch(rejectWithValue)
    },
)

export const saveUpgradeOrderRecord = createAsyncThunk<
    UpgradeOrderRecordData,
    UpgradeOrderRecordData,
    { state: RootState }
>(
    'user/saveUpgradeOrderRecord',
    async (record, { dispatch, getState, rejectWithValue }) => {
        const tryOrder = () => postUpgradeOrderRecord(record, getState().user.temporary ? getState().user.temporary : null)
        return retryOperation(tryOrder).catch(rejectWithValue)
    },
)

export const retrieveReCaptchaScore = createAsyncThunk<
    string,
    void,
    { state: RootState }
>(
    'user/retrieveReCaptchaScore',
    async (x, { dispatch, getState, rejectWithValue }) => {
        const tryScore = () => postReCaptchaScore(x)
        return retryOperation(tryScore).catch(rejectWithValue)
    },
)

/**
 * Slice + reducers
 */
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logoutUserOld(state: UserState) {
            state.loggedIn = false
            state.token = null
            state.data = null
            state.favourites = {}
            saveUserState(state)
        },
        updateUser(state: UserState, action) {
            state.data = { ...state.data, ...action.payload }
            saveUserState(state)
        },
        loadUser(state: UserState, action) {
            state.token = action.payload.token
            state.loggedIn = true
            const {
                orders,
                favourites,
                ...data
            } = action.payload.user
            state.data = data
        },
        setHumanConfidence(state: UserState, action: PayloadAction<number>) {
            state.humanConfidence = action.payload
            saveUserState(state)
        },
        refreshHumanConfidence(state: UserState) {
            state.confidenceRefresh = Date.now()
        }
    },
    extraReducers: (builder) => {
        // Clear token on login
        builder.addCase(loginUser.pending, (state: UserState) => {
            state.token = null
        })
        builder.addCase(loginPIN.pending, (state: UserState) => {
            state.token = null
        })
        // Login successful, save state
        builder.addCase(
            loginUser.fulfilled,
            (state: UserState, action: PayloadAction<{ token: string }>) => {
                state.token = action.payload.token
                saveUserState(state)
            },
        )
        builder.addCase(
            loginPIN.fulfilled,
            (state: UserState, action: PayloadAction<{ token: string }>) => {
                state.token = action.payload.token
                saveUserState(state)
            },
        )
        builder.addCase(
            registerPIN.fulfilled,
            (state: UserState, action: PayloadAction<{ token: string }>) => {
                state.token = action.payload.token
                saveUserState(state)
            },
        )
        builder.addCase(
            logoutUser.fulfilled,
            (state: UserState) => {
                state.loggedIn = false
                state.token = null
                state.data = null
                state.favourites = {}
                saveUserState(state)
            },
        )
        // Login failed, clear cred
        builder.addCase(loginUser.rejected, (state: UserState) => {
            state.token = null
        })
        builder.addCase(loginPIN.rejected, (state: UserState) => {
            state.token = null
        })
        // Start tetrieve authorized user
        builder.addCase(retrieveUser.pending, (state: UserState) => {
            // state.loggedIn = false
            // state.data = null
            // state.error = null
        })
        // Got user, save state
        builder.addCase(
            retrieveUser.fulfilled,
            (state: UserState, action: PayloadAction<{ user: UserData }>) => {
                state.loggedIn = true
                const {
                    orders,
                    favourites,
                    ...data
                } = action.payload.user
                state.data = data

                // Aggregate favourites
                state.favourites = favourites.reduce((acc, x) => {
                    if (!(x.appId in acc)) {
                        acc[x.appId] = {}
                    }
                    acc[x.appId][x.floorplanId] = x
                    return acc
                }, {})

                state.orders = orders
            },
        )
        // Failed to get user, clear login state
        builder.addCase(
            retrieveUser.rejected,
            (state: UserState, action: PayloadAction<{ message: string }>) => {
                state.loggedIn = false
                state.token = null
                state.data = null
                saveUserState(state)
                if (action.payload) {
                    logger.error(action.payload.message)
                    state.error = action.payload.message
                } else {
                    state.error = ErrorMessage.UserRetrieveError
                }
            },

        )
        /*builder.addCase(resetPassword.pending, (state: UserState, action: PayloadAction<{message: string}>) => {
            if (action.meta.arg.temporary) {
                // Logout
                state.loggedIn = false
                state.token = null
                state.data = null
            }
        })*/
        builder.addCase(resetPassword.rejected, (state: UserState, action: PayloadAction<{ message: string }>) => {
            if (action.payload) {
                logger.error(action.payload.message)
            }
            // state.error = ErrorMessage.FailedToReset
            state.temporary = null
        })
        builder.addCase(resetPassword.fulfilled, (state: UserState, action: PayloadAction<{ message: string }>) => {
            state.temporary = null
        })
        builder.addCase(activateAccount.rejected, (state: UserState, action: PayloadAction<{ message: string }>) => {
            if (action.payload) {
                logger.error(action.payload.message)
            }
            state.error = ErrorMessage.ActivateError
            state.temporary = null
        })
        builder.addCase(activateAccount.fulfilled, (state: UserState, action: PayloadAction<{ message: string }>) => {
            state.temporary = null
        })
        builder.addCase(retrieveTemporaryToken.fulfilled, (state: UserState, action: PayloadAction<string>) => {
            if (action.payload && action.payload.token) {
                state.temporary = state.token = action.payload.token
                saveUserState(state)
            } else {
                state.error = ErrorMessage.DataError
            }
        })
        builder.addCase(retrieveTemporaryToken.rejected, (state: UserState, action: PayloadAction<string>) => {
            if (action.payload) {
                logger.error(action.payload.message)
                state.error = action.payload.message
            } else {
                state.error = ErrorMessage.DataError
            }
        })
        builder.addCase(toggleFavourite.pending, (state: UserState, action) => {
            const { app, floorplanId, variationId } = action.meta.arg
            const appId = app.meta.id
            if (appId in state.favourites && floorplanId in state.favourites[appId]) {
                delete state.favourites[appId][floorplanId]
                if (Object.keys(state.favourites[appId]).length == 0) {
                    delete state.favourites[appId]
                }
            } else {
                if (!(appId in state.favourites)) {
                    state.favourites[appId] = {}
                }
                state.favourites[appId][floorplanId] = { appId, floorplanId, variationId }
            }

            state.favourites = { ...state.favourites }
        })
        builder.addCase(toggleFavourite.fulfilled, (state: AppState,
            action: PayloadAction<boolean>) => {
            const { app, floorplanId, variationId } = action.meta.arg
            const appId = app.meta.id
            // Correction phase of toggle favourites after assumed success
            let correction = false
            // True means new favourite, False to delete
            if (action.payload && !(floorplanId in state.favourites[appId])) {
                if (!(appId in state.favourites)) {
                    state.favourites[appId] = {}
                }
                state.favourites[appId][floorplanId] = action.payload
                correction = true
            } else if (!action.payload && appId in state.favourites && floorplanId in state.favourites[appId]) {
                delete state.favourites[appId][floorplanId]
                if (Object.keys(state.favourites[appId]).length == 0) {
                    delete state.favourites[appId]
                }
                correction = true
            }

            if (correction) {
                state.favourites = { ...state.favourites }
            }
        })
        // Clear favourites and apply to saved state
        builder.addCase(clearFavourites.fulfilled, (state: UserState) => {
            state.favourites = {}
            saveUserState(state)
        })
    },
})

export const {
    // logoutUser,
    updateUser,
    loadUser,
    setHumanConfidence,
    refreshHumanConfidence,
} = userSlice.actions
export default userSlice.reducer

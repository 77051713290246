import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactDOMServer from 'react-dom/server'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { PageType } from 'app/types'
import { IconButton } from 'components'
import { navigateAsync } from 'actions/appActions'

interface FavouriteNavButtonProps {
    noBg: boolean,
    inPrompt: boolean,
}

let favouriteAnimationTimeout = null
export function FavouriteNavButton(props: FavouriteNavButtonProps) {
    const { noBg, inPrompt } = props
    const location = useLocation()
    const dispatch = useAppDispatch()
    const viewingFavourites = useAppSelector((state: RootState) => state.app.navigation[0] == 'favourites') || location.pathname.includes('favourites')//state.app.history.length > 0 && state.app.history[state.app.history.length - 1] != null && state.app.history[state.app.history.length - 1].includes('favourites'))
    const favourites = useAppSelector((state: RootState) => state.user.favourites)
    const favouriteIcon = useAppSelector((state: RootState) => state.app.current ? state.app.current.meta.favouriteIcon : 'far fa-heart')
    const prompt = useAppSelector((state: RootState) => state.app.prompt)
    const project = useAppSelector((state: RootState) => state.app.projectCurrent[0])
    const config = useAppSelector((state: RootState) => state.app.config)
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)

    const [prevFavourites, setPrevFavourites] = useState(null)
    const [showFavouriteAnimation, setShowFavouriteAnimation] = useState(0)
    const [favouriteCount, setFavouriteCount] = useState(0)

    function goToSaved() {
        if (inPrompt && viewingFavourites) {
            dispatch(navigateAsync({ pageType: PageType.Floorplan }))
                .catch((e: Error) => logger.error(e))
        } else {
            if (standaloneApp) {
                dispatch(navigateAsync({ pageType: PageType.Favourite, app: project }))
                    .catch((e: Error) => logger.error(e))
            } else {
                dispatch(navigateAsync({ pageType: PageType.Favourite, options: { root: true } }))
                    .catch((e: Error) => logger.error(e))
            }
        }
    }

    useEffect(() => {
        let count = 0

        const organizationMap = config.organizations.reduce((acc, x) => ({ ...acc, [x.id]: x }), {})
        const appMap = config.apps.reduce((acc, x) => ({ ...acc, [x.meta.id]: x }), {})

        Object.keys(favourites).filter((x) => {
            // Only get favourites from valid organizations
            const app = appMap[x]
            if (standaloneApp && project) {
                return x == project.meta.id
            }
            return app != null
        }).forEach((x) => {
            count += Object.keys(favourites[x]).length
        })
        setFavouriteCount(count)
    }, [favourites, standaloneApp, project])

    useEffect(() => {
        if (prevFavourites != null && favourites > prevFavourites && (!current || inPrompt)) {
            if (showFavouriteAnimation > 0) {
                setShowFavouriteAnimation(0)
            }
            setTimeout(() => {
                setShowFavouriteAnimation(1)
                clearTimeout(favouriteAnimationTimeout)
                favouriteAnimationTimeout = setTimeout(() => {
                    setShowFavouriteAnimation(2)
                    favouriteAnimationTimeout = setTimeout(() => {
                        setShowFavouriteAnimation(0)
                    }, 1000)
                }, 3000)
            }, 0)
        }

        if (favourites !== prevFavourites) {
            setPrevFavourites(favourites)
        }
        return () => {
            setShowFavouriteAnimation(0)
            clearTimeout(favouriteAnimationTimeout)
        }
    }, [favourites])
    function getFavouriteAnimation() {
        let animation = ''
        if (showFavouriteAnimation === 1) {
            animation = 'animate__bounceInLeft50'
        } else if (showFavouriteAnimation === 2) {
            animation = 'animate__bounceOut'
        }
        return <div className={`favourite-animation noselect animate__animated ${animation}`}><span>Saved to favourites!</span></div>
    }

    return <IconButton
        noBg={noBg}
        className={`favourite-nav-button ${viewingFavourites ? ' selected favourite' : ''}`}
        icon={`${favourites > 0 && viewingFavourites ? 'fas' : 'far'} ${favouriteIcon}`}
        onClick={goToSaved}>
        {favouriteCount > 0 && <div className="badge favourite">
            <span>{favouriteCount}</span>
        </div>}
        {showFavouriteAnimation > 0 && getFavouriteAnimation()}
    </IconButton>
}
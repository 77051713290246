import React from 'react'
import { Message } from 'components'
import {
    AnalyticsEvent,
    AppData, CustomContentType, FloorplanData, FloorplanFilter, PageType, ProjectType, UnitData, UnitFilter, UpgradeTier, UserRole,
} from './types'
import { Provinces } from './constants'

import { DynamicContentView } from 'content/DynamicContentView'

// const TheSummitContent = React.lazy(() => import('content/TheSummitContent'))
// const TheWoodsContent = React.lazy(() => import('content/TheWoodsContent'))
// const KingsleySquareContent = React.lazy(() => import('content/KingsleySquareContent'))
const ThePropertyLinqContent = React.lazy(() => import('content/ThePropertyLinqContent'))
// const GatesOfMeafordContent = React.lazy(() => import('content/GatesOfMeafordContent'))
// const LeonaCondominiumsContent = React.lazy(() => import('content/LeonaCondominiumsContent'))
// const CopperCreekContent = React.lazy(() => import('content/CopperCreekContent'))

import {
    GatesOfMeafordContentData,
} from 'content/data_GatesOfMeaford'
import {
    CopperCreekContentData,
} from 'content/data_CopperCreek'
import {
    LeonaCondominiumsContentData,
} from 'content/data_LeonaCondominiums'
import {
    KingsleySquareContentData,
} from 'content/data_KingsleySquare'
import {
    ThePropertyLinqContentData,
} from 'content/data_ThePropertyLinq'
import {
    TheSummitContentData,
} from 'content/data_TheSummit'
import {
    TheWoodsContentData,
} from 'content/data_TheWoods'
import {
    WhitbyGroveContentData,
} from 'content/data_WhitbyGrove'
import { logger } from 'helpers/logger'
import { getLegacyMediaId } from 'helpers/media'
import { CustomContent } from './CustomContent'
import { sources } from 'webpack'



// App specific exception transformers
const thewoodsRegex = /([A-Z])([0-9])-([0-9])([LR])/
const sheppardRegex = /([0-9]+)([A-Z])/
const kingsleysquareRegex = /^([A-Z])([0-9]+)([0-9][0-9])$/

export function transformUnitName(app: AppData, unit: UnitData) {
    let { name } = unit
    if (!app) {
        return name
    }
    // TODO, database conditional
    switch (app.meta.link) {
        case 'test':
        case 'casavilla':
        case 'thewoods':
            const match = thewoodsRegex.exec(name)
            if (match != null && match.length >= 5) {
                const [all, building, phase, plan, unit] = match
                // name = `Phase ${phase} - Building ${building} - Unit ${parseInt(unit)}`
                name = `Building ${building} - Unit ${plan}${unit}`
            }
            break
        case 'thesummit':
        case 'upgradetest':
            name = name.toUpperCase()
            break
        case 'summit':
            name = name.split('_').pop()
        default:
            break
    }
    return name
}

export function transformFloorplanVariationName(app: AppData, floorplan: FloorplanData) {
    let { name } = floorplan
    // TODO, database conditional
    switch (app.meta.link) {
        case 'thesummit':
        case 'upgradetest':
            const split = name.split('-')
            if (split.length == 2) {
                name = split[1].replace(' ', '').toLowerCase()
                // return split[1].toLowerCase()
            } else {
                name = split[0].replace(' ', '').toLowerCase()
                // return split[0].toLowerCase()
            }
            break
        default:
            break
    }
    return name
}
export function transformFloorplanName(app: AppData, floorplan: FloorplanData) {
    let { name } = floorplan
    if (!app) {
        return name
    }
    // TODO, database conditional
    switch (app.meta.link) {
        case 'thesummit':
        case 'upgradetest':
            // return name
            // name = name.split('-')[0].toLowerCase()
            name = name.replace(' ', '').toLowerCase()
            break
        default:
            break
    }
    return name
}

export function transformUnitBuildingKey(app: AppData, unit: UnitData) {
    // TODO, database conditional
    const { name } = unit
    if (!app) {
        return name
    }
    switch (app.meta.link) {
        case 'test':
        case 'casavilla':
        case 'thewoods':
            const match = thewoodsRegex.exec(name)
            if (match != null && match.length >= 5) {
                const [all, building, phase, plan, unit] = match
                // name = `Phase ${phase} - Building ${building} - Unit ${parseInt(unit)}`
                return `${building}${phase}`
            }
            break
        default:
            break
    }
    return null
}

export function transformUnitNumber(app: AppData, unit: UnitData, idx: number) {
    const { name } = unit
    if (!app) {
        return idx
    }
    switch (app.meta.link) {
        case 'test':
        case 'casavilla':
        case 'thewoods': {
            const match = thewoodsRegex.exec(name)
            if (match != null && match.length >= 5) {
                const [all, building, phase, plan, unit] = match

                let slot = 0
                switch (plan.toNumeric()) {
                    case 1:
                    case 2:
                    case 3:
                    case 6:
                    case 7:
                        slot = 0
                        break
                    case 4:
                    case 5:
                        slot = 2
                        break
                }
                // let val = parseInt(unit)
                const val = unit == 'L' ? 1 : 2
                return slot + val
            }
            return idx
        }
        case '105sheppard': {
            const match = sheppardRegex.exec(name)
            if (match != null && match.length >= 3) {
                const [all, floor, unitCode] = match
                return unitCode.charCodeAt(0)
            }
            return idx
        }
        case 'kingsleysquare': {
            const match = kingsleysquareRegex.exec(name)
            if (match != null && match.length >= 4) {
                const [all, building, floor, unit] = match
                return unit.toNumeric()
            }
            return idx
        }
        default:
            const key = unit.name.toNumeric()
            if (key != null) {
                return key
            }
            return idx
    }
}

export function transformGarage(app: AppData, garage: number) {
    // If fractional element, add '+ atv'
    let finalGarage = parseFloat(garage)
    if (!finalGarage) {
        return garage
    }
    if (finalGarage % 1 != 0) {
        return Math.floor(finalGarage).toString() + ' + atv'
    }
    return finalGarage.toString()
}

export function sortUnitNumber(app: AppData, unitA: UnitData, unitB: UnitData) {
    if (!app) {
        return 0
    }
    switch (app.meta.link) {
        case 'test':
        case 'casavilla':
        case 'thewoods': {
            const matchA = thewoodsRegex.exec(unitA.name)
            const matchB = thewoodsRegex.exec(unitB.name)
            if (matchA != null && matchA.length >= 5 && matchB != null && matchB.length >= 5) {
                const [allA, buildingA, phaseA, planA, unitA] = matchA
                const [allB, buildingB, phaseB, planB, unitB] = matchB
                if (buildingA == buildingB) {
                    const valA = unitA == 'L' ? 1 : 2
                    const valB = unitB == 'L' ? 1 : 2
                    // const valA = parseInt(unitA)
                    // const valB = parseInt(unitB)

                    return valA - valB
                }
                return buildingA.localeCompare(buildingB)
            }
            return 0
        }
        default:
            return unitA.name.toNumeric() - unitB.name.toNumeric()
    }
}

export function sortPlan(app: AppData, planA: FloorplanData, planB: FloorplanData) {
    if (!app) {
        return 0
    }
    switch (app.meta.link) {
        case 'test':
        case 'casavilla':
        case 'thewoods': {
            const unitA = planA.units.length > 0 ? planA.units[0] : null
            const unitB = planB.units.length > 0 ? planB.units[0] : null
            if (unitA && unitB) {
                const matchA = thewoodsRegex.exec(unitA.name)
                const matchB = thewoodsRegex.exec(unitB.name)
                if (matchA != null && matchA.length >= 5 && matchB != null && matchB.length >= 5) {
                    const [allA, buildingA, phaseA, planA, unitA] = matchA
                    const [allB, buildingB, phaseB, planB, unitB] = matchB
                    return parseInt(planA) - parseInt(planB)
                }
            }
            /* const regex = /([A-Z])([0-9])-([0-9])([LR])/
            const matchA = regex.exec(unitA.name)
            const matchB = regex.exec(unitB.name)
            if (matchA != null && matchA.length >= 5 && matchB != null && matchB.length >= 5) {
                const [allA, buildingA, phaseA, planA, unitA] = matchA
                const [allB, buildingB, phaseB, planB, unitB] = matchB
                if (buildingA == buildingB) {
                    const valA = unitA == 'L' ? 1 : 2
                    const valB = unitB == 'L' ? 1 : 2

                    return valA - valB
                }
                return buildingA.localeCompare(buildingB)
            }
            return 0 */
            return planA.name.localeCompare(planB.name)
        }
        default:
            return planA.name.localeCompare(planB.name)
    }
}

export function pageRoles(app: AppData, page: PageData) {
    switch (app.meta.link) {
        case 'test':
        case 'casavilla':
        case 'thewoods':
            if (page.pageType == PageType.External && page.name == 'Worksheet') {
                return [UserRole.Worksheet]
            }
            break
        default:
            return []
            break
    }
    return []
}

export function utilityRoutes() {
    return ['activate-account', 'reset-password', 'favourites', 'about-us', 'privacy-policy']
}

export function organizationRoutes(organization) {
    let finalPages = []
    if (organization?.pages && organization.pages.length > 0) {
        finalPages = [...organization.pages]
    }
    switch (organization?.link) {
        case 'thepropertylinq':
            let pageSet = new Set(finalPages.map((x) => x.link))
            const arr = [
                { name: 'Home', link: 'home', showInMenu: true },
                { name: 'Developments', link: 'developments', showInMenu: true },
                { name: 'About us', link: 'aboutus', showInMenu: true },
                { name: 'Discover Georgian Bay', link: 'discover', showInMenu: true },
                { name: 'Partnerships', link: 'partnerships', showInMenu: false },
                { name: 'Contact', link: 'contact', showInMenu: false },
                // { name: 'Events'georgian-communities-lunch-learn-event', 'thankyou']
            ]
            arr.forEach((x) => {
                if(!pageSet.has(x.link)) {
                    finalPages.push(x)
                    pageSet.add(x.link)
                }
            })
            break
        default:
            break
    }
    return finalPages
}

export function organizationContent(organization: OrganizationData) {
    return DynamicContentView

    switch (organization?.link) {
        case 'thepropertylinq':
            return ThePropertyLinqContent
        default:
            return null
    }
}

export function projectStaticContent(app: AppData) {
    const content = {
        gatesofmeaford: GatesOfMeafordContentData,
        coppercreek: CopperCreekContentData,
        leonacondominiums: LeonaCondominiumsContentData,
        kingsleysquare: KingsleySquareContentData,
        summit: TheSummitContentData,
        thewoods: TheWoodsContentData,
        whitbygrove: WhitbyGroveContentData,
    }
    if (content[app?.meta.link]) {
        return content[app.meta.link]
    }
    return null
}

export function organizationStaticContent(organization: OrganizationData) {
    const content = {
        thepropertylinq: ThePropertyLinqContentData,
    }
    if (content[organization?.link]) {
        return content[organization.link]
    }
    return null
}

export function projectContent(app: AppData) {
    return DynamicContentView

    switch (app?.meta.link) {
        case 'test':
        case 'thewoods':
        case 'casavilla':
            return TheWoodsContent
        case 'kingsleysquare':
            return KingsleySquareContent
        case 'gatesofmeaford':
            return GatesOfMeafordContent
        case 'leonacondominiums':
            return LeonaCondominiumsContent
        case 'coppercreek':
            return CopperCreekContent
        case 'summit':
            return TheSummitContent
        default:
            return null
    }
}

export function projectFilters(app: AppData) {
    switch (app.meta.link) {
        case 'gatesofmeaford':
            return [
                { filter: FloorplanFilter.Beds },
                { filter: FloorplanFilter.Baths },
                { filter: FloorplanFilter.BuildingType, expanded: true },
                { filter: FloorplanFilter.Favourites, expanded: true },
                { filter: FloorplanFilter.Sqft, expanded: true },
            ]
        case 'coppercreek':
            return [
                { filter: FloorplanFilter.Beds },
                { filter: FloorplanFilter.Baths },
                { filter: FloorplanFilter.Garage },
                { filter: FloorplanFilter.Series },
                { filter: FloorplanFilter.Favourites, expanded: true },
                { filter: FloorplanFilter.Sqft, expanded: true },
                { filter: FloorplanFilter.Unit, expanded: true },
                { filter: FloorplanFilter.Availability, expanded: true },
            ]

        case 'summit':
            return [
                { filter: FloorplanFilter.Beds },
                { filter: FloorplanFilter.Baths },
                { filter: FloorplanFilter.Garage },
                { filter: FloorplanFilter.Series },
                { filter: FloorplanFilter.Favourites, expanded: true },
                { filter: FloorplanFilter.Sqft, expanded: true },
                { filter: FloorplanFilter.Unit, expanded: true },
            ]
        default:
            return [
                { filter: FloorplanFilter.Beds },
                { filter: FloorplanFilter.Baths },
                { filter: FloorplanFilter.Favourites, expanded: true },
                { filter: UnitFilter.Floor, expanded: true },
                { filter: UnitFilter.Exposure, expanded: true },
                { filter: FloorplanFilter.Sqft, expanded: true },
            ]
    }
}

export function upgradeSummaryCSV(app: AppData, csvData, withPricing = true) {
    let headers = []
    let data = []
    switch (app.meta.link) {
        case 'upgradetest':
        case 'thesummit':
        default:
            if (withPricing) {
                headers = ['Room', 'Package', 'Item', 'Description', 'Quantity', 'Price', 'Note']
            } else {
                headers = ['Room', 'Package', 'Item', 'Description', 'Quantity', 'Note']
            }
            data = csvData.filter((x) => {
                if (x.option == 'total') {
                    return false
                }
                return true
            }).map((x) => {
                const {
                    location, option, variation, component, product, subProduct, override, price, priceUnit, quantity, note, ix,
                } = x

                let item = ''
                let pckge = ''
                let finalQuantity = ''
                let finalNote = ''
                let finalDescription = ''
                if (ix == 0) {
                    finalNote = note
                    if (override && override.quantity) {
                        finalQuantity = `${quantity}**`
                    } else if (quantity) {
                        finalQuantity = `${quantity}`
                    }
                }

                if (x.package) {
                    pckge = x.package.name
                }

                if (option.custom) {
                    item = 'Custom Option'
                    finalDescription = note
                } else if (option != 'total') {
                    finalDescription = subProduct ? subProduct.description : (product ? product.description : '')

                    item = option.name
                    if (option && variation && option.variations?.length > 1) {
                        item = `${item} - ${variation.name}`
                    }
                    if (variation && variation.components && variation.components.length > 1) {
                        item = `${item} - ${component.name}`
                    }
                    if (subProduct) {
                        item = `${item} - ${subProduct.name}`
                    } else if (product) {
                        item = `${item} - ${product.name}`
                    }
                }

                if (withPricing) {
                    return [
                        location,
                        pckge,
                        item,
                        finalDescription,
                        finalQuantity,
                        price,
                        finalNote,
                    ]
                } else {
                    return [
                        location,
                        pckge,
                        item,
                        finalDescription,
                        finalQuantity,
                        finalNote,
                    ]
                }
            })
    }

    return { headers, data }
}

export function upgradeSummaryPDF(app: AppData, csvData, withPricing = true) {
    // Convert csv data into grouped pdf data
    const data = {}
    let groupOrder = {}
    switch (app.meta.link) {
        default:
            csvData.filter((x) => {
                if (x.option == 'total') {
                    return false
                }
                return true
            }).forEach((x) => {
                const {
                    location, group, option, variation, component, product, subProduct, override, price, priceUnit, quantity, note, ix, customized
                } = x
                let pckge = x.package

                const key = group ? group.id : 'overall'
                if (group) {
                    groupOrder[key] = group.order
                } else {
                    groupOrder[key] = -1
                }
                if (!(key in data)) {
                    data[key] = { name: group ? group.name : 'Overall', options: [] }
                }

                let item = ''
                let finalQuantity = ''
                let productName = product ? product.name : ''
                let finalNote = ''
                let finalDescription = ''
                let finalPrice = price
                let finalPriceUnit = priceUnit
                let sub = false
                if (ix == 0) {
                    finalNote = note ? note : ''
                    if (override && override.quantity) {
                        finalQuantity = `${quantity}**`
                    } else if (quantity) {
                        finalQuantity = `${quantity}`
                    }
                }

                if (option.custom) {
                    item = 'Custom Option'
                    finalDescription = note
                } else if (option != 'total') {

                    // If package is standard, don't add
                    if (pckge?.upgradeTierId == UpgradeTier.Standard) {
                        // return
                    }
                    // finalDescription = subProduct ? subProduct.description : product?.description

                    item = typeof option == 'string' ? option : option.name
                    if (pckge) {
                        item = `(${pckge.name}) ${item}`
                        if (!customized) {
                            finalPrice = 0
                            finalPriceUnit = 0
                        }
                        sub = true
                    }
                    if (option && variation && option.variations?.length > 1) {
                        item = `${item} - ${variation.name}`
                    }
                    if (variation && variation.components && variation.components.length > 1) {
                        item = `${item} - ${component.name}`
                    }
                    if (subProduct) {
                        sub = true
                        item = ''
                        productName = subProduct.name
                        // item = `${item} - ${subProduct.name}`
                    } else if (product) {
                        // item = `${item} - ${product.name}`
                    }
                }

                if (withPricing) {
                    data[key].options.push({
                        location,
                        option: item,
                        product: productName,
                        sku: product ? product.sku : '',
                        price: finalPrice,
                        priceUnit: finalPriceUnit,
                        quantity: finalQuantity,
                        note: finalNote,
                        sub,
                    })
                } else {
                    data[key].options.push({
                        location,
                        option: item,
                        product: productName,
                        sku: product ? product.sku : '',
                        price: null,
                        priceUnit: null,
                        quantity: finalQuantity,
                        note: finalNote,
                        sub,
                    })
                }
            })
    }

    let finalData = Object.keys(data).sort((a, b) => groupOrder[a] - groupOrder[b]).map((key) => {
        return data[key]
    })

    return finalData
}

export function organizationMapStyles(organization) {
    switch (organization.link) {
        case 'homegyde':
        case 'thepropertylinq':
        default:
            return [
                {
                    featureType: 'administrative',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                        {
                            color: '#ffffff',
                        },
                    ],
                },
                {
                    featureType: 'administrative',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            gamma: '0.00',
                        },
                        {
                            weight: '0.01',
                        },
                        {
                            visibility: 'on',
                        },
                        {
                            color: '#8c8c8c',
                        },
                    ],
                },
                {
                    featureType: 'administrative.neighborhood',
                    elementType: 'labels.text',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'administrative.neighborhood',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#898989',
                        },
                    ],
                },
                {
                    featureType: 'administrative.neighborhood',
                    elementType: 'labels.text.stroke',
                    stylers: [
                        {
                            color: '#ffffff',
                        },
                        {
                            weight: '4.00',
                        },
                    ],
                },
                {
                    featureType: 'landscape',
                    elementType: 'all',
                    stylers: [
                        {
                            color: '#ffffff',
                        },
                    ],
                },
                {
                    featureType: 'landscape.man_made',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            visibility: 'simplified',
                        },
                        {
                            color: '#ffffff',
                        },
                    ],
                },
                {
                    featureType: 'landscape.natural',
                    elementType: 'geometry',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'landscape.natural',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            color: '#8d8d8d',
                        },
                    ],
                },
                {
                    featureType: 'landscape.natural.terrain',
                    elementType: 'geometry.stroke',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'poi',
                    elementType: 'all',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'poi.park',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#cef8d5',
                        },
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                        {
                            color: '#60b36c',
                        },
                    ],
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels.text.stroke',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                        {
                            color: '#ffffff',
                        },
                    ],
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels.icon',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'all',
                    stylers: [
                        {
                            saturation: '-100',
                        },
                        {
                            lightness: '32',
                        },
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#f3f3f3',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.stroke',
                    stylers: [
                        {
                            color: '#e1e1e1',
                        },
                    ],
                },
                {
                    featureType: 'road',
                    elementType: 'labels.text',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                    ],
                },
                {
                    featureType: 'road.highway',
                    elementType: 'all',
                    stylers: [
                        {
                            visibility: 'simplified',
                        },
                    ],
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                        {
                            lightness: '63',
                        },
                    ],
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#f3f3f3',
                        },
                    ],
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry.stroke',
                    stylers: [
                        {
                            color: '#e1e1e1',
                        },
                    ],
                },
                {
                    featureType: 'road.highway',
                    elementType: 'labels.text',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'road.highway',
                    elementType: 'labels.icon',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'road.arterial',
                    elementType: 'labels.icon',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'transit',
                    elementType: 'all',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'transit.station',
                    elementType: 'all',
                    stylers: [
                        {
                            visibility: 'off',
                        },
                    ],
                },
                {
                    featureType: 'water',
                    elementType: 'all',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                        {
                            color: '#eeeeee',
                        },
                    ],
                },
                {
                    featureType: 'water',
                    elementType: 'geometry.fill',
                    stylers: [
                        {
                            color: '#cce4ff',
                        },
                    ],
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [
                        {
                            visibility: 'on',
                        },
                        {
                            color: '#6095a5',
                        },
                    ],
                },
            ]
            break
    }
    return []
}

export function getAppConfig(app: AppData, config: Config) {
    let formConfig = null
    let eventConfig = null
    let unitConfig = null
    switch (app.meta.link) {
        default:
            break
        case 'gatesofmeaford':
            formConfig = [
                {
                    key: 'firstname',
                    label: 'FIRST NAME',
                    required: true,
                },
                {
                    key: 'lastname',
                    label: 'LAST NAME',
                    required: true,
                },
                {
                    key: 'email',
                    label: 'EMAIL',
                    required: true,
                },
                {
                    key: 'phone',
                    label: 'PHONE',
                    required: true,
                    inputProps: { phone: true },
                },
                {
                    key: 'address',
                    label: 'ADDRESS',
                    required: false,
                },
                {
                    key: 'city',
                    label: 'CITY',
                    required: true,
                },
                {
                    key: 'province',
                    label: 'PROVINCE',
                    enum: Provinces,
                    select: 'Select Province',
                    required: false,
                },
                {
                    key: 'postalcode',
                    label: 'POSTAL CODE',
                    required: false,
                },
                {
                    key: 'hometype',
                    label: 'HOME TYPE INTERESTED IN',
                    select: 'Select Home Type',
                    enum: app.meta.projectTypes.map((x) => config.projectTypes[x].name),
                    required: false,
                },
                {
                    key: 'sqft',
                    label: 'SQUARE FOOTAGE INTERESTED IN',
                    enum: ['1000+', '1500+', '2000+', '2500+'],
                    select: 'Select Square Footage',
                    required: false,
                },
                {
                    key: 'bedrooms',
                    label: 'NUMBER OF BEDROOMS INTERESTED IN',
                    enum: ['1+', '2+', '3+', '4+'],
                    select: 'Select Bedrooms',
                    required: false,
                },
                {
                    key: 'bathrooms',
                    label: 'NUMBER OF BATHROOMS INTERESTED IN',
                    enum: ['1+', '2+', '3+', '4+'],
                    select: 'Select Bathrooms',
                    required: false,
                },
            ]
            // eventConfigPizza = {
            //     type: CustomContentType.EventCard,
            //     title: 'March Break Event!',
            //     subtitle: 'March 9th and 10th from 11 - 6 pm',
            //     location: {
            //         name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
            //         numberAndStreet: 'Highway 26 & Gun Club Road',
            //         city: 'Collingwood',
            //         provinceState: 'ON',
            //         postalZipCode: 'L9Y 5C6',
            //         altC: true
            //     },
            //     // body: "Experience our homes through VR and check out our detached, semi-detached and bungaloft homes starting from $649,000.\n\nOur sales office is located at Highway 26 & Gun Club Road, Collingwood, ON.\n\nContact us today, we're happy to help!",
            //     body: "Enjoy:\n\n* Free pizza!\n* Bouncy house for the kids\n* Our virtual reality experience\n\nWe will be hosting a March Break Event from 11 a.m. – 6 p.m. each day.\n\nWith a theme of 'Dream Home', your kids will get the chance to jump on our bouncy house and create their own dream home drawing in our creativity zone.\n\nGrown-ups looking for some dream home fun? You can try our VR Headset and get a tour of our gorgeous Gates of Meaford Properties.\n\nWe’ll also be offering free pizza, in partnership with an incredible local pizzeria!\n\nWe’d love to see you and your family there!",
            //     links: [{
            //         label: 'Register ',
            //         icon: 'fas fa-paper-plane',
            //         link: '#request',
            //         formOptions: {
            //             fields: ['name', 'phone', 'email'],
            //             analyticsEventTypeId: AnalyticsEvent.EventRegistration,
            //             title: 'March Break Event',
            //             submitMessage: 'Register',
            //             tags: ['marchbreak']
            //         }
            //     }],
            // }
            eventConfig = {
                type: CustomContentType.ProjectInfo,
                title: 'Now Selling!',
                // title: 'Bungalow Launch Event on Victoria Day Weekend!',
                // subtitle: 'May 18 + 19 from 12pm-3pm',
                location: {
                    name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                    address: {
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                body: "Experience our homes through VR and check out our detached, semi-detached and bungaloft homes starting from $649,000.\n\nOur sales office is located at 11352 ON-26, Collingwood, ON.\n\nContact us today, we're happy to help!",
                media: [{ galleryLink: 'highlight', index: 0 }],
                // body: "Register for our FREE event this Victoria Day long weekend! Enjoy a complimentary BBQ lunch and get VIP access to exclusive incentives when you purchase a new bungalow or bungaloft model.",
                // links: [{ label: 'Register Now', link: 'bungalow-launch-event' }],
                links: [{ label: 'Request Information', link: '#request' }],
            }
            unitConfig = {
                // types: ['Detached', 'Semi-Detached'],
                // units: [4, 14],
                // sqft: ['2000 - 2500 sf', '1540 sf'],
                // beds: ['4', '2 1/2'],
                // baths: ['2 1/2', '3 1/2'],
                phase: 1,
                statsByBuildingType: true,
            }
            break
        case 'coppercreek':
            formConfig = [
                {
                    key: 'firstname',
                    label: 'FIRST NAME',
                    required: true,
                },
                {
                    key: 'lastname',
                    label: 'LAST NAME',
                    required: true,
                },
                {
                    key: 'email',
                    label: 'EMAIL',
                    required: true,
                },
                {
                    key: 'phone',
                    label: 'PHONE',
                    required: true,
                    inputProps: { phone: true },
                },
                {
                    key: 'address',
                    label: 'ADDRESS',
                    required: false,
                },
                {
                    key: 'city',
                    label: 'CITY',
                    required: true,
                },
                {
                    key: 'province',
                    label: 'PROVINCE',
                    enum: Provinces,
                    select: 'Select Province',
                    required: false,
                },
                {
                    key: 'postalcode',
                    label: 'POSTAL CODE',
                    required: false,
                },
                {
                    key: 'hometype',
                    label: 'HOME TYPE INTERESTED IN',
                    select: 'Select Home Type',
                    enum: app.meta.projectTypes.map((x) => config.projectTypes[x].name),
                    required: false,
                },
                {
                    key: 'sqft',
                    label: 'SQUARE FOOTAGE INTERESTED IN',
                    enum: ['1000+', '1500+', '2000+', '2500+'],
                    select: 'Select Square Footage',
                    required: false,
                },
                {
                    key: 'bedrooms',
                    label: 'NUMBER OF BEDROOMS INTERESTED IN',
                    enum: ['1+', '2+', '3+', '4+'],
                    select: 'Select Bedrooms',
                    required: false,
                },
                {
                    key: 'bathrooms',
                    label: 'NUMBER OF BATHROOMS INTERESTED IN',
                    enum: ['1+', '2+', '3+', '4+'],
                    select: 'Select Bathrooms',
                    required: false,
                },
            ]
            eventConfig = {
                type: CustomContentType.ProjectInfo,
                title: 'Register Now',
                /*subtitle: 'April 2024',
                location: {
                    name: 'Copper Creek Presentation Center',
                    address: {
                        numberAndStreet: '138 S. Park Square #101',
                        city: 'Fruita',
                        provinceState: 'CO',
                        postalZipCode: '81521',
                    },
                },*/
                // body: "Introducing Korima, an upcoming project featuring new detached homes with high-quality finishes in a neighborhood with a heart. With large lots, scenic walking trails alongside a serene creek, and a dedicated dog park, our community offers the perfect blend of luxury and nature. Mark your calendars for late April 2024 and register now for updates on this exciting project!",
                // body: "Register to join our VIP list.\n\nBe the first to see the new plans, finishes and early pricing.\n\nThe first round of lots will be released, exclusively to the VIP list by June 22, 2024.\n\nCopper Creek Builders is offering a broad selection of their award-winning home plans for you to choose from.",
                // body: "Register to join our VIP list.\n\nBe the first to see the new plans, finishes and early pricing.\n\nCopper Creek Builders is offering a broad selection of their award-winning home plans for you to choose from.",
                body: "Register to unlock the interactive site map, floorplans, virtual tours & interior design details.",
                links: [{ label: 'Register', link: 'register' }],
            }
            unitConfig = {
                // types: ['Detached', 'Semi-Detached'],
                // units: [4, 14],
                // sqft: ['2000 - 2500 sf', '1540 sf'],
                // beds: ['4', '2 1/2'],
                // baths: ['2 1/2', '3 1/2'],
                phase: 1,
                statsByBuildingType: true,
            }
            break

    }

    if (app.dynamicContent) {
        const dbEventConfig = app.dynamicContent.find((x) => x.dynamicContentTypeId == CustomContentType.ProjectInfo)
        if (dbEventConfig) {
            eventConfig = dbEventConfig
        }
    }
    return { formConfig, eventConfig, unitConfig }
}

export function productFieldName(app, x) {
    switch (app.meta.link) {
        default:
            switch (x) {
                case 'builderCode':
                    return 'type'
                case 'cost':
                    return 'Unit Cost'
                case 'price':
                    return 'Unit Price'
                default:
                    return x
            }
            break
    }
}

export function getUnitDescriptor(app) {
    if (app.meta.projectTypes.includes(ProjectType.Condo)) {
        return 'Unit'
    }
    return 'Lot'
}

export function analyticsFieldValue(_value, fields, x, maps, config) {
    const { key, map, valueMap } = fields
    let value = _value
    let displayValue = value
    let filterValue = value
    if (map && Array.isArray(map) && map.length == 2) {
        const keyA = map[0]
        const keyB = map[1]
        const mapAValue = maps[keyA][x[keyA]]
        if (mapAValue && keyB in mapAValue && mapAValue[keyB] in maps[keyB] && maps[keyB][mapAValue[keyB]]) {
            const mapBValue = maps[keyB][mapAValue[keyB]]
            if (mapBValue) {
                if (valueMap) {
                    value = valueMap(mapBValue, config)
                } else {
                    value = mapBValue[key]
                }
            }
        }
        displayValue = filterValue = value
    } else if (map && map in maps && x[map] in maps[map] && maps[map][x[map]]) {
        value = displayValue = maps[map][x[map]][key]
        filterValue = maps[map][x[map]]?.id
        if (valueMap) {
            displayValue = maps[map][x[map]]
            displayValue = valueMap({ ...x, ...displayValue }, config)
        }
    } else if (key in maps && x[key] in maps[key] && 'label' in maps[key][x[key]]) {
        value = displayValue = filterValue = maps[key][x[key]].label
    } else if (fields.enum && x[key] in fields.enum) {
        value = displayValue = filterValue = fields.enum[x[key]]?.toReadable()
    } else if (valueMap) {
        value = displayValue = filterValue = valueMap(x, config)
    }
    return { value, displayValue, filterValue }
}

export function mapAnalyticsSource(x, config) {
    try {
        const org = null//config.organizations.find((y) => y.id == x.organizationId)
        // TODO, something not hardcoded
        const orgName = org?.name == 'The Property LinQ' ? 'TPL' : org?.name
        if (x.source.includes("@")) {
            return 'Website'
            // Get domain of email
            // const domain = x.source.split('@')[1].split('.')[0]
            // return `${domain.toReadable()} Website`
        } else if (x.source == 'Salescenter' || x.utmSource == 'salescenter') {
            if (org) {
                return `${orgName} Sales Center`
            } else {
                return 'Sales Center'
            }
        } else if (x.source == 'Modelhome' || x.utmSource == 'modelhome') {
            if (org) {
                return `${orgName} Model Home`
            } else {
                return 'Model Home'
            }
        } else if (x.source == 'Online') {
            if (org) {
                return `${orgName} Website`
            } else {
                return 'Website'
            }
            // console.log("")
            // return 'Website'
        }
    } catch (e) {
        logger.error(e)
        return 'N/A'
    }
    return x.source

}

export function mapAnalyticsSubmitter(x) {
    if (x.email.includes('inventdev')) {
        return `InventDev`//-${x.email}`
    } else if (x.admin) {
        return `Salesperson`//-${x.email}`
    } else if (x) {
        return `Customer`//-${x.email}`
    } else {
        return 'Anonymous'
    }
}
import React, { useEffect, useState, useRef } from 'react'
import {
    Media,
    IconButton,
    Tile,
    DraggableTile,
    Input,
    Icon,
    Checkbox,
} from 'components'
import {
    ThumbSize,
    MediaType,
    BuilderData,
} from 'app/types'
import { getMediaUrl } from 'helpers/config'
import * as fnc from 'helpers/fnc'
import { Toggle } from './Core/Toggle'
import { usePreviewMedia } from 'app/hooks'

interface MediaTileProps {
    app: AppData,
    builder: BuilderData
    organization: OrganizationData,
    media: MediaData,
    onClick: () => void,
    selected: boolean,
    scrollTo: boolean
    isDialog: boolean,
    listRef: React.RefObject,
    draggable: boolean,
    onReorder: () => void,
    order: number,
    name: string,
    showInfo: boolean,
    onEditName: () => void,
    selectIcon: boolean,
    aspect: boolean,
    square: boolean,
    toggleValue: float | boolean,
    onToggle: () => void,
    toggleLabel: string,
}

export function MediaTile(props: MediaTileProps) {
    const {
        app,
        builder,
        organization,
        media,
        selected,
        onClick,
        scrollTo,
        isDialog,
        listRef,
        draggable,
        onReorder,
        order,
        hoverShow,
        name,
        onEditName,
        showInfo,
        children,
        selectIcon,
        aspect,
        square,
        toggleLabel,
        toggleValue,
        onToggle,
        ...mediaProps
    } = { isDialog: true, showInfo: true, selectIcon: true, ...props }

    const [loaded, setLoaded] = useState(false)
    const [dragging, setDragging] = useState(false)
    const [editingName, setEditingName] = useState(false)
    const tileRef = useRef()
    const asList = media?.mediaTypeId == MediaType.File
    const previewMedia = usePreviewMedia()

    // Not for this app
    /*if (app && (!media.appId || media.appId != app.meta.id)
        || (!app && (media.appId != null && media.appId != 'homegyde'))) {
        return null
    }*/

    useEffect(() => {
        if (!media) {
            return
        }
        if (media.mediaTypeId != MediaType.Image) {
            setLoaded(true)
        }
    }, [])

    useEffect(() => {
        if (!media) {
            return
        }
        if (scrollTo && tileRef.current && listRef.current) {
            const offsetTop = tileRef.current.offsetTop
            listRef.current.scrollTo({ y: `${offsetTop - 20}px` })

        }
    }, [tileRef && tileRef.current, listRef && listRef.current])

    if (!media) {
        return null
    }
    
    function handleEditName(x) {
        onEditName(x)
        setTimeout(() => {
            setEditingName(false)
        }, 10)
    }

    function getName() {
        if (name || onEditName) {
            if (editingName) {
                return <div className="media-name">
                    <Input value={name} autoFocus onSubmit={handleEditName} onBlur={() => setEditingName(false)} />
                </div>
            }
            return <div className="media-name" style={onEditName ? { cursor: 'pointer' } : null} onClick={(e) => e.stopPropagation()}>
                {onToggle && getToggle()}
                <span onMouseDown={(e) => e.stopPropagation()} onMouseUp={onEditName ? (e) => { e.stopPropagation(); setEditingName(true) } : null}> {name ? name : 'Add Name'}</span>
                {onEditName && <IconButton noBg icon="fas fa-pen" onMouseDown={(e) => e.stopPropagation()} onMouseUp={(e) => { e.stopPropagation(); setEditingName(true) }} />}
            </div >
        }
        return null
    }

    function handleToggle(x) {
        if (onToggle) {
            onToggle(x)
        }
    }

    function getToggle() {
        if (onToggle) {
            return <div className="media-toggle top-left" onClick={(e) => e.stopPropagation()}>
                <Checkbox value={toggleValue} onChange={handleToggle}/>
            </div>
        }
    }

    function getInfo() {
        return <div className="media-info noselect">
            <span>{media.name}</span>
        </div>
    }

    let mediaElem = null
    let tileOnClick = null
    const finalMedia = media//previewMedia(app, media)
    switch (media.mediaTypeId) {
        case MediaType.EmailResource:
        case MediaType.Image:
        case MediaType.Video:
        case MediaType.Tour:
            if (draggable) {
                mediaElem = <Media fadeIn key={`${media.id}${media.dateModified}`} app={app} builder={builder} organization={organization} mediaId={finalMedia.id} thumb={true} thumbSize={ThumbSize.Medium} fallback onLoad={() => setLoaded(true)} hoverShow={hoverShow && !dragging} {...mediaProps} />
            } else {
                mediaElem = <Media fadeIn key={`${media.id}${media.dateModified}`} app={app} builder={builder} organization={organization} mediaId={finalMedia.id} thumb={true} thumbSize={ThumbSize.Medium} fallback onClick={(e) => onClick ? onClick(media, e) : null} onLoad={() => setLoaded(true)} hoverShow={hoverShow} {...mediaProps} />
            }
            break
        case MediaType.Spin:
            const paddedIndex = String(0).padStart(3, '0')
            const quality = 'low'
            const path = `${getMediaUrl(app)}/`
            const url = `${path}${media.link}/${quality}/${media.link}_${quality}_${paddedIndex}.jpg`
            mediaElem = <Media fadeIn url={url} fallback onClick={(e) => onClick(media, e)} onLoad={() => setLoaded(true)} thumb={true} thumbSize={ThumbSize.Medium} hoverShow={hoverShow} />
            break
        default:
            mediaElem = <h3 className="noselect">{media.link}</h3>
            tileOnClick = (e) => onClick(media, e)
            break
    }

    if (draggable) {
        return <DraggableTile className={`media-tile${selected ? ' selected' : ''}`} style={{ opacity: loaded ? 1 : 0 }} forwardRef={tileRef} onReorder={onReorder} onClick={(e) => onClick ? onClick(media, e) : null} onDrag={setDragging} order={order} square>
            {mediaElem}
            {getInfo()}
            {getName()}
            {getToggle()}
        </DraggableTile>
    }
    let className = `media-tile${selected ? ' selected' : ''}${asList ? ' as-list' : ''}`

    if (asList) {
        return <tr className={className} onClick={tileOnClick}>
            <td>{media.name}</td>
            <td>
                <span>{fnc.dateFriendly(new Date(media.dateModified), { days: true, time: true })} </span>
            </td>
            <td>
                <span>{fnc.dateFriendly(new Date(media.dateCreated), { days: true, time: true })} </span>
            </td>
            {selected && selectIcon && isDialog && <td>
                <IconButton noBg icon="fas fa-check" onClick={(e) => { e.stopPropagation(); onClick(media, e, true) }} />
            </td>}
        </tr>
    }

    return <Tile className={className} style={{ opacity: loaded ? 1 : 0 }} forwardRef={tileRef} square={!aspect} aspect={aspect} onClick={tileOnClick}>
        {selected && selectIcon && isDialog && <IconButton icon="fas fa-check" onClick={(e) => { e.stopPropagation(); onClick(media, e, true) }} />}
        {mediaElem}
        {getInfo()}
        {getName()}
        {props.children}
    </Tile>
}
import React from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'app/hooks'
import { CustomEventType, RootState } from 'app/types'
import { Media, IconButton, Button } from 'components'
import ReactMarkdown from 'react-markdown'
import * as fnc from 'helpers/fnc'
import { organizationContent, organizationRoutes, projectContent } from 'app/transformers'
import { LocationLink } from './LocationLink'

export function Footer() {
    const rootPath = useAppSelector((state: RootState) => state.app.rootPath)
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const config = useAppSelector((state: RootState) => state.app.config)
    const project = useAppSelector((state: RootState) => state.app.projectCurrent[0])
    const media = useAppSelector((state: RootState) => state.app.media)
    let prefix = ''
    let footerLocation = null
    if (organization && organization.locations?.length > 0) {
        footerLocation = organization.locations[0]
    } else if (project && project.meta.officeLocation) {
        footerLocation = project.meta.officeLocation
    }

    function getFooterGraphic() {
        let mediaId = null
        if (organization != null && organization.logoNotextMediaId in media) {
            mediaId = organization.logoNotextMediaId
        }
        if (!mediaId && (organization == null || organization.link == 'homegyde') && 'homegydelogonotext' in media) {
            mediaId = media.homegydelogonotext.id
        }

        if (mediaId) {
            return <div className="footer-graphic">
                <Media mediaId={mediaId} />
            </div>
        }
        return null
    }

    if (rootPath.length > 1) {
        prefix += fnc.trimTrailingSlash(rootPath)
    }

    let logoText = null
    if (organization) {
        logoText = <h1>{organization.name}</h1>
    } else {
        logoText = <h1 style={{ fontFamily: 'Poiret One' }}>Home Gyde</h1>
    }

    function handleRequestInfo() {
        window.dispatchEvent(new CustomEvent(CustomEventType.RequestInfo, { detail: { app: project } }))
    }

    /*let linkPrefix = ''
    if (organization && organization.link != 'homegyde') {
        linkPrefix = `/${organization.link}`
    }*/
    // const routes = organizationRoutes(organization, config)
    let FooterContent = organizationContent(organization, config)
    if (!FooterContent) {
        // FooterContent = projectContent(project)
    }
    let footerPage = null
    if (project) {
        footerPage = project.pages.find(page => page.link == 'footer')
    } else if (organization) {
        footerPage = organization.pages.find(page => page.link == 'footer')
    }

    return <div className="row footer">
        <div className="column">
            <div className="row footer-info">
                {/*footerLocation && <div className="column col-3">
                    <h5>COME VISIT US</h5>
                    <div className="row">
                        <h3><ReactMarkdown>{footerLocation.name ? footerLocation.name.toMarkdown() : null}</ReactMarkdown></h3>
                    </div>
                    <div className="row">
                        <div className="column address">
                            {/* <span>{organizationLocation.numberAndStreet}</span> /}
                            <LocationLink location={footerLocation} alt />
                        </div>
                    </div>
                </div>*/}
                {FooterContent && <FooterContent app={project} organization={organization} page={footerPage ? footerPage : { pageId: null }} link="footer" prefix={prefix} />}
                {organization && (organization.twitterLink || organization.facebookLink || organization.instagramLink || organization.youtubeLink) && <div className="column col-3">
                    <h5>{`${organization.name.toUpperCase()} ON SOCIAL MEDIA`}</h5>
                    <div className="row">
                        <h3>Connect With Us</h3>
                    </div>
                    <div className="row social-links">
                        {organization.twitterLink && <a href={organization.twitterLink} target="_blank" rel="noopener noreferrer"><IconButton tertiary icon="fab fa-twitter" /></a>}
                        {organization.facebookLink && <a href={organization.facebookLink} target="_blank" rel="noopener noreferrer"><IconButton tertiary icon="fab fa-facebook-f" /></a>}
                        {organization.instagramLink && <a href={organization.instagramLink} target="_blank" rel="noopener noreferrer"><IconButton tertiary icon="fab fa-instagram" /></a>}
                        {organization.linkedinLink && <a href={organization.linkedinLink} target="_blank" rel="noopener noreferrer"><IconButton tertiary icon="fab fa-linkedin" /></a>}
                        {organization.youtubeLink && <a href={organization.youtubeLink} target="_blank" rel="noopener noreferrer"><IconButton tertiary icon="fab fa-youtube" /></a>}
                    </div>
                </div>}
            </div>
            <div className="row copywrite">
                <div className="column">
                    <span>&copy; 2023 {organization ? organization.name : 'Home Gyde'} <sup>TM</sup></span>
                    <Link to={`${prefix}/privacy-policy`}><span>Privacy Policy</span></Link>
                </div>
                <a href="https://inventdev.com"><span>Design + Build by Invent Dev Inc.</span></a>
            </div>
        </div>
    </div>
}
import React, { useState, useEffect } from 'react'
import { Dict, AppMetaData, Config, CustomEventType, ProjectType, ScreenOrientation } from 'app/types'
import { Icon } from 'components'
import { LocationLink } from './LocationLink'
import { logger } from 'helpers/logger'
import * as fnc from 'helpers/fnc'
import { useAppSelector } from 'app/hooks'
import { projectSubtitle } from 'helpers/project'

function getFractionString(val: number) {
    if (val % 1 !== 0) {
        return <React.Fragment>{Math.floor(val)} <sup>1</sup>/<sub>2</sub></React.Fragment>
    }
    return <React.Fragment> {val.toString()} </React.Fragment>
}

// TODO: enums
const iconMap = {
    'beds': 'fas fa-bed',
    'baths': 'fas fa-bath',
    'units': 'fas fa-ruler-vertical',
    'sqft': 'fas fa-vector-square',
    'projectTypes': 'fas fa-building',
    'estCompletion': 'fas fa-calendar',
}

interface ProjectDetailsProps {
    app: AppData,
    config: Config,
    minimal?: boolean,
    onVisit?: () => void,
    onLocation?: () => void,
    salescenter?: boolean,
}

export function ProjectDetails(props: ProjectDetailsProps) {
    const { app, config, minimal, onVisit, onLocation, salescenter, splitIndex, screen } = props
    const [details, setDetails] = useState(null)

    if (!config) {
        logger.error('Project details missing config')
        return null
    }

    useEffect(() => {
        const builder = config.maps.builder[app.meta.builderId]
        const projectTypeNames = app.meta.projectTypes.map((x) => config.projectTypes[x].name)

        function getUnitString() {
            let unitString = app.meta.stats.units > 0 ? `${app.meta.stats.units} Units` : ''
            if (app.meta.stats.phases > 0) {
                unitString += ` x ${app.meta.stats.phases} Phases`
            }
            if (app.meta.stats.floors > 0) {
                unitString += ` | ${app.meta.stats.floors} Storeys`
            }
            return unitString
        }

        const newDetails = {
            location: {
                title: 'Location',
                data: <LocationLink location={app.meta.location} minimal={minimal} noClick={true} onClick={onLocation ? () => onLocation(app.meta) : null} />
            },
            statusUpdate: {
                data: null,
            },
            builder: {
                title: 'Builder',
                data: null,
            },
            price: {
                title: 'Pricing',
                data: null,
            },
            sqft: {
                // title: 'Square Footage',
                data: null,
            },
            beds: {
                title: 'Details',
                data: null,
            },
            baths: {
                // title: 'Baths',
                data: null,
            },
            garage: {
                title: 'Garage',
                data: null,
            },
            projectStatus: {
                title: 'Project Status',
                data: app.meta.projectStatusId ? config.projectStatuses[app.meta.projectStatusId].name : null
            },
            projectTypes: {
                title: 'Project Type',
                data: projectTypeNames.join(', ')
            },
            units: {
                data: app.meta.stats.units && app.meta.stats.units > 0 ? <span>{getUnitString()}</span> : null
            },
            estCompletion: {
                data: app.meta.estCompletion && app.meta.estCompletion.length > 0 ? <span>Est.Compl. {app.meta.estCompletion}</span> : null
            },
            salesOffice: {
                data: null,
            }
        }
        const formatList = (x, ix) => <React.Fragment>
            {ix > 0 && minimal ? '-' : ' - '}
            {getFractionString(x)}
        </React.Fragment>

        const formatMinMax = (x, unit = '', suffix = '') => {
            const min = Math.min.apply(null, x)
            const max = Math.max.apply(null, x)
            if (min == max) {
                return <span>{unit}{min} {suffix}</span>
            }
            return <span>{unit}{min}{minimal && config.theme != 'thepropertylinq' ? '-' : ' - '}{unit}{max} {suffix}</span>
        }

        if (app.meta.stats.sqft && app.meta.stats.sqft.length > 0) {
            newDetails.sqft.data = formatMinMax(app.meta.stats.sqft, '', config.theme != 'thepropertylinq' ? 'sf' : '')
        }

        if (app.meta.pricing) {
            newDetails.price.data = <span className="pricing">{app.meta.pricing}</span>
        } else if ((app.meta.stats.price && app.meta.stats.price.length > 0)) {
            const min = Math.min.apply(null, app.meta.stats.price)
            newDetails.price.data = <span className="pricing">From the ${fnc.numberWithCommas(Math.floor(min / 100000) * 100000)}'s</span>
            // details.price.data = formatMinMax(stats.price, '$')
        } else if (!minimal) {
            newDetails.price.data = <span className="pricing">Pricing not yet announced</span>
        }

        [
            ['beds', config.theme != 'thepropertylinq' ? 'Bed' : '', 'Bedrooms'],
            ['baths', config.theme != 'thepropertylinq' ? 'Bath' : '', 'Bathrooms'],
            ['garage', '', 'Garages']
        ].forEach((x) => {
            const [key, suffix, suffixB] = x
            if (app.meta.stats[key] && app.meta.stats[key].length > 0) {
                newDetails[key].data = formatMinMax(app.meta.stats[key], '', minimal ? suffix : suffixB)
                // details[x].data = filters[x].map(formatList)
            }
        })

        // Builder
        if (builder != null) {
            if (minimal) {
                let subtitle = projectSubtitle(app, config)
                subtitle = `${subtitle} by ${builder.name}`
                newDetails.builder.data = <span>{subtitle}</span>
            } else {
                if (builder.site) {
                    newDetails.builder.data = <a className="link" href={builder.site} target="_blank"><span>{builder.name}</span></a>
                } else {
                    newDetails.builder.data = <span>{builder.name}</span>
                }
            }
        }

        // Sales Office
        if (!salescenter && app.meta.organizations && app.meta.organizations.length > 0 && config) {
            newDetails.salesOffice.data = app.meta.organizations.map((x) => config.maps.organization[x])
                .filter((x) => {
                    return x != null && x.locations && x.locations.length > 0
                })
                .map((x, ix) => {
                    return <span className="sales-office" key={ix}> {x.name}{x.locations.map((y, iy) => <LocationLink key={iy} location={y} minimal={false} />)}</span>
                    // return <span key={ix}> { x.name }</span>
                })
        }

        if (app.meta.statusUpdate && app.meta.statusUpdate.length > 0) {

            const pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
            const regex = new RegExp(pattern)

            let update = app.meta.statusUpdate
            const matches = app.meta.statusUpdate.match(regex)
            const urls = []
            if (matches) {
                matches.forEach((x) => {
                    update = update.replace(x, '{url}')
                    urls.push(x)
                })
            }
            // Zip
            var tokens = update.split('{url}').reduce((acc, x, ix) => ([...acc, x, <a href={urls[ix]}>{urls[ix]}</a>]), [])
            newDetails.statusUpdate.data = <span className="status-update">{tokens}</span>
        }
        setDetails(newDetails)
    }, [])

    function getGrid() {
        const detailList = ['projectTypes', 'beds', 'units', 'baths', 'estCompletion', 'sqft', 'garage']
        const indexMap = detailList.reduce((acc, x, ix) => ({ ...acc, [x]: ix }), {})
        const set = new Set(detailList)
        return <div className="project-detail-grid">
            {Object.keys(details)
                .filter((x) => details[x].data && set.has(x))
                .sort((a, b) => indexMap[a] - indexMap[b])
                .map((x, ix) => {
                    if (!x in iconMap) {
                        return <span key={ix} className="project-detail">{details[x].data}</span>
                    }
                    return <span key={ix} className="project-detail">
                        <Icon noBg icon={iconMap[x]} />
                        {details[x].data}
                    </span>
                })}
        </div>
    }

    if (details == null) {
        return null
    }

    // Render
    if (minimal) {
        const mapDetail = (x) => {
            if (details[x].data != null) {
                return <span className="project-detail" data-detail={x}>{details[x].data}</span>
            }
            return null
        }
        const group = [mapDetail('beds'), mapDetail('baths'), mapDetail('sqft')].filter((x) => x != null).map((x, ix) => {
            if (ix > 0) {
                return <React.Fragment key={ix}>
                    {<Icon noBg icon="fas fa-circle" />}
                    {x}
                </React.Fragment>
            }
            return <React.Fragment key={ix}>{x}</React.Fragment>
        })
        switch (config.theme) {
            case 'thepropertylinq':
                return <div className="row project-details">
                    <table>
                        <thead>
                            <tr>
                                <th>SF</th>
                                <th>BED</th>
                                <th>BATH</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{details.sqft.data}</td>
                                <td>{details.beds.data}</td>
                                <td>{details.baths.data}</td>
                            </tr>
                        </tbody>
                    </table>
                    {mapDetail('builder')}
                </div>
            default:
                return <div className="row project-details">
                    {mapDetail('location')}
                    {mapDetail('price')}
                    {mapDetail('builder')}
                    {group.length > 0 && <span className="row project-detail-group horizontal">{group}</span>}
                    {/* {mapDetail('projectStatus')} */}
                </div>
        }
    }

    return <div className="row project-details">
        {details.statusUpdate && <div className="project-detail">
            {details.statusUpdate.data}
        </div>}
        <div className="project-detail">
            {details.price.data}
        </div>
        <div className="project-table">
            <table>
                <tbody>
                    {details.location.data && <tr>
                        <th>Site Location:</th>
                        <td>{details.location.data}</td>
                    </tr>}
                    {details.builder.data && <tr>
                        <th>Builder:</th>
                        <td>{details.builder.data}</td>
                    </tr>}
                    {details.salesOffice.data && details.salesOffice.data.length > 0 && <tr>
                        <th>Sales Office:</th>
                        <td>{details.salesOffice.data}</td>
                    </tr>}
                </tbody>
            </table>
        </div>
        {getGrid()}
    </div>
}